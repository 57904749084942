<template>
  <div class="desp">
    <div class="desp-header">
      <div class="label">Company Details</div>
      <div class="opts">
        <el-button
          v-if="
            (detail.applymentStatus === 'REVISING' ||
              detail.applymentStatus === 'CORRECTED' ||
              detail.applymentStatus === 'ILLEGAL' ||
              detail.applymentStatus === 'AUDIT_FAIL' ||
              detail.applymentStatus === 'REFUND_APPLY_DENY' ||
              detail.applymentStatus === 'REFUND_APPLY_REJECT' ||
              detail.applymentStatus === 'ACRA_REJECTED') &&
            isApplymentAuth('company:coordinate')
          "
          @click="openEdit()"
          ref=""
        >
          Edit
        </el-button>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Financial Year End (e.g. 31 December)</div>
      <div class="desp-item">
        <!-- <div class="desp-item-self">{{ detail.financialYearEnd }}</div> -->
        <div class="desp-item-self" v-if="detail.financialYearEnd && detail.financialYearEnd.includes('/')">
          <span class="mr20">Month {{ detail.financialYearEnd.split("/")[0] }}</span>
          <span>Date {{ detail.financialYearEnd.split("/")[1] }}</span>
        </div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Financial Year Period</div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.financialYearPeriod }}</div>
      </div>
    </div>
    <!-- 弹窗 -->
    <edit-modal v-if="editVisible" ref="editModal" :businessType="detail.businessType" />
  </div>
</template>

<script>
import EditModal from "./modal/Edit.vue";
export default {
  components: {
    EditModal,
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          financialYearEnd: "empty",
          financialYearPeriod: "empty",
          businessType: "",
        };
      },
    },
  },
  data() {
    return {
      dataListLoading: false,
      editVisible: false,
    };
  },
  methods: {
    async openEdit() {
      this.editVisible = true;
      await this.$nextTick();
      this.$refs.editModal.init(this.detail);
    },
  },
};
</script>
