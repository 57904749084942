var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "800px",
      "title": "Confirm Receipt",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "250px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "",
      "prop": "status"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [[_c('el-radio', {
    attrs: {
      "label": "COMPLETE"
    }
  }, [_vm._v("Fully received")]), _c('el-radio', {
    attrs: {
      "label": "PARTLY_PAID"
    }
  }, [_vm._v("Partially received")])]], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Pay Type",
      "prop": "payType"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.payType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "payType", $$v);
      },
      expression: "dataForm.payType"
    }
  }, [[_c('el-radio', {
    attrs: {
      "label": "ALIPAY"
    }
  }, [_vm._v("Alipay")]), _c('el-radio', {
    attrs: {
      "label": "BANK_TRANSFER"
    }
  }, [_vm._v("Bank Transfer")])]], 2)], 1), _vm.dataForm.status == 'COMPLETE' ? _c('el-form-item', {
    staticClass: "is-required",
    attrs: {
      "prop": "isInvoiceRevised"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('span', [_vm._v("Revision Invoice Information")]), _c('el-popover', {
    staticClass: "vam",
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("Was the information of the invoice (except the price amount) subsequently revised after the system issurance?")]), _c('span', {
    staticClass: "blackD8 fs16 ml4 el-icon-question",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1), _c('el-radio-group', {
    model: {
      value: _vm.dataForm.isInvoiceRevised,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "isInvoiceRevised", $$v);
      },
      expression: "dataForm.isInvoiceRevised"
    }
  }, [[_c('el-radio', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("Yes")]), _c('el-radio', {
    attrs: {
      "label": 0
    }
  }, [_vm._v("No")])]], 2)], 1) : _vm._e(), _vm.dataForm.isInvoiceRevised ? _c('el-form-item', {
    staticClass: "is-required",
    attrs: {
      "prop": "revisedInvoiceFile"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('span', [_vm._v("Upload Revised Invoice")]), _c('el-popover', {
    staticClass: "vam",
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("To upload the revised invoice")]), _c('span', {
    staticClass: "blackD8 fs16 ml4 el-icon-question",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1), _c('el-file-uploader', {
    model: {
      value: _vm.dataForm.revisedInvoiceFile,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "revisedInvoiceFile", $$v);
      },
      expression: "dataForm.revisedInvoiceFile"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "Remark",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Remark",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }