<template>
  <div class="sys-user-toggle-wrapper">
    <el-dropdown @command="handleCommand" trigger="click" placement="bottom-start" :disabled="!canSelect">
      <span class="cursor-pointer el-dropdown-link">
        {{ formatName(this.currVal) }}
        <i v-if="canSelect" class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :disabled="hasUndefinedOption" :command="{ id: -1 }">unassigned</el-dropdown-item>
        <el-dropdown-item :disabled="hasSelOption(user)" :command="user" v-for="user in userListFilter" :key="user.id">
          {{ formatName(user) }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    label: {
      type: String,
    },
    user: {
      type: Object,
    },
    ownershipRole: {
      type: String,
    },
    userList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      // userList: [],
      currVal: {},
    };
  },
  mounted() {
    // this.getSysUserList();
    this.currVal = this.user;
  },
  watch: {
    user(newVal) {
      this.currVal = newVal;
    },
  },
  computed: {
    ...mapState("user", {
      currUserId: "id",
    }),
    canSelect() {
      if (this.isAuth("company:adjust")) {
        return true;
      } else {
        if (
          (this.ownershipRole === "coordinator" && this.isAuth("company:coordinate")) ||
          (this.ownershipRole === "checker" && this.isAuth("company:check")) ||
          (this.ownershipRole === "auditor" && this.isAuth("company:audit"))
        ) {
          if (!this.currVal || this.currVal.id === -1) {
            return true;
          } else {
            return this.currVal.id === this.currUserId;
          }
        } else {
          return false;
        }
      }
    },
    hasUndefinedOption() {
      return !this.currVal || this.currVal.id === -1;
    },
    userListFilter() {
      let userListRes = [];
      this.userList.forEach(element => {
        let childList = element.roleList.filter(
          item =>
            item.name === "SUPER" ||
            (item.name === "COORDINATOR" && this.ownershipRole === "coordinator") ||
            (item.name === "CHECKER" && this.ownershipRole === "checker") ||
            (item.name === "REVIEWER" && this.ownershipRole === "auditor"),
        );
        if (childList.length != 0) {
          userListRes.push(element);
        }
      });
      return userListRes;
    },
  },
  methods: {
    ...mapActions("applyment", ["doClaimOwnership", "doTransferOwnership", "doAdjustOwnership"]),
    // async getSysUserList() {
    //   const { data } = await this.$http.get(`/admin/sysUser/getList`);
    //   this.userList = data;
    // },
    formatName(user) {
      return (user && (user.nickname || user.username)) || "unassigned";
    },
    hasSelOption(user) {
      if (this.isAuth("company:adjust")) {
        return false;
      } else {
        if (!this.currVal || this.currVal.id === -1) {
          return this.currUserId !== user.id;
        } else {
          return this.currVal && this.currVal.id === user.id;
        }
      }
    },
    handleCommand(command) {
      if (this.isAuth("company:adjust")) {
        //调整
        this.doAdjustOwnership({
          ownershipRole: this.ownershipRole,
          toUser: command,
        }).then(() => {
          this.$message.success("success");
        });
      } else if (!this.currVal) {
        //认领
        this.doClaimOwnership({
          ownershipRole: this.ownershipRole,
          toUser: command,
        }).then(() => {
          this.$message.success("success");
        });
      } else {
        //转让
        this.doTransferOwnership({
          ownershipRole: this.ownershipRole,
          toUser: command,
        }).then(() => {
          this.$message.success("success");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sys-user-toggle-wrapper {
  .label {
    margin-right: 10px;
  }
}
</style>
