import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import common from "./modules/common";
import menu from "./modules/menu";
import user from "./modules/user";
import role from "./modules/role";
import authority from "./modules/authority";
import constant from "./modules/constant";
import applyment from "./modules/applyment";
import finance from "./modules/finance";

Vue.use(Vuex);
Vue.config.devtools = process.env.NODE_ENV === "development";

export default new Vuex.Store({
  modules: {
    common,
    menu,
    user,
    role,
    authority,
    constant,
    applyment,
    finance,
  },
  mutations: {},
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(val) {
        return {
          // 存储布局设置
          common: {
            sidebarFold: val.common.sidebarFold,
          },
          // 当前激活的角色
          authority: {
            activeRoleName: val.authority.activeRoleName,
          },
        };
      },
    }),
  ],
});
