var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1400px",
      "title": "Status Track",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "border": "",
      "data": _vm.dataList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "supplementNo",
      "header-align": "center",
      "align": "center",
      "label": "Quotation (V) No.",
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "businessNo",
      "header-align": "center",
      "align": "center",
      "label": "Linked Transaction No.",
      "width": "110"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "billingParty",
      "header-align": "center",
      "align": "center",
      "label": "Billing Party",
      "min-width": "110"
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Exchange Rate",
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? _c('p', [_vm._v(_vm._s(scope.row.paymentDetail.exchangeRate))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "amount",
      "header-align": "center",
      "align": "center",
      "label": "Amount",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? _c('span', [_vm._v(_vm._s(_vm._f("thousands")(scope.row.paymentDetail.amount, scope.row.paymentDetail.currencyUnit)))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "thirdPartyFee",
      "header-align": "center",
      "align": "center",
      "label": "Third party fee",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? _c('span', [_vm._v(_vm._s(_vm._f("thousands")(scope.row.paymentDetail.thirdPartyFee, scope.row.paymentDetail.currencyUnit)))]) : _vm._e(), scope.row.noteForThirdParty ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.noteForThirdParty))]), _c('span', {
          staticClass: "ml8 fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "otherFee",
      "header-align": "center",
      "align": "center",
      "label": "Other Fee",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? _c('span', [_vm._v(_vm._s(_vm._f("thousands")(scope.row.paymentDetail.otherFee, scope.row.paymentDetail.currencyUnit)))]) : _vm._e(), scope.row.noteForOther ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.noteForOther))]), _c('span', {
          staticClass: "ml8 fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "createAt",
      "header-align": "center",
      "align": "center",
      "label": "Handling Time",
      "formatter": _vm.localDateFormat,
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "supplementStatus",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "formatter": _vm.supplementStatusFormat,
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "remark",
      "header-align": "center",
      "align": "center",
      "label": "Description / Remark",
      "min-width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "userInfo",
      "header-align": "center",
      "align": "center",
      "label": "Handler",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(scope.row.userInfo.nickname))])];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.size,
      "total": _vm.total,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }