<template>
  <div class="company-applyment-module">
    <!-- 状态 -->
    <el-affix
      :class="{ 'applyment-page-header-top': affixed, 'applyment-page-header-shrink': sidebarFold, 'applyment-page-header-unfold': !sidebarFold }"
      @change="handleAffixChange"
      :offset="104"
    >
      <el-card>
        <div class="applyment-page-header">
          <div>
            <div class="status">
              {{ statusFormatter(engagementDetail.status) }}
              <span class="fs12" v-if="engagementDetail.reopenStatus != null">
                (Reopen Status: {{ reopenStatusFormatter(engagementDetail.reopenStatus) }})
              </span>
            </div>
          </div>
          <div class="page-header-heading-right">
            <div class="opts">
              <template v-if="isAuth('engagement:charge') && isHasCurrUserId">
                <!-- 状态操作日志 -->
                <el-button type="text" icon="el-icon-tickets" @click="openStatusHistoryModal">History Track</el-button>
                <!-- 保存修改的内容 -->
                <el-button v-if="engagementDetail.status == 'OPEN'" type="primary" size="medium" @click="modifyHandle">Modify Save</el-button>
                <!-- 发起重新开启修正申请 -->
                <el-button
                  v-if="engagementDetail.status == 'CLOSE' && engagementDetail.reopenStatus != 0"
                  type="primary"
                  size="medium"
                  @click="reOpenHandle"
                >
                  Reopen
                </el-button>
                <!-- 关闭 -->
                <el-button v-if="engagementDetail.status == 'OPEN'" type="primary" size="medium" @click="closeHandle">Close</el-button>
              </template>
              <!-- 评审开启修正功能的原因 （仅限超级管理员） -->
              <el-button
                v-if="activeRoleName == 'SUPER' && engagementDetail.status == 'REOPEN' && engagementDetail.reopenStatus == 0"
                type="primary"
                size="medium"
                @click="reOpenAuditHandle"
              >
                Reopen Review
              </el-button>
            </div>
          </div>
        </div>
      </el-card>

      <div :class="{ mt26: !affixed, engagementFixed: affixed }">
        <el-card>
          <div class="desp">
            <div class="desp-header" v-if="!affixed">
              <div class="label">Engagement</div>
            </div>
            <el-row :gutter="10">
              <el-col :span="8">
                <div class="desp-item">
                  <div class="wd140 desp-item-label">Client Code</div>
                  <div class="desp-item-content">
                    {{ engagementDetail.clientCode }}
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="desp-item">
                  <div class="wd140 desp-item-label">Entity Type</div>
                  <div class="desp-item-content">
                    {{ companyTypeFormat(engagementDetail.companyType) }}
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="desp-item">
                  <div class="wd140 desp-item-label">Entity Country</div>
                  <div class="desp-item-content">
                    {{ engagementDetail.companyCountry }}
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="desp-item">
                  <div class="wd140 desp-item-label">UEN</div>
                  <div class="desp-item-content">
                    {{ engagementDetail.uen }}
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="desp-item">
                  <div class="wd140 desp-item-label">Entity Name</div>
                  <div class="desp-item-content">
                    {{ engagementDetail.companyName }}
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="desp-item">
                  <div class="wd140 desp-item-label">Covered Period</div>
                  <div class="desp-item-content">
                    <span>{{ engagementDetail.coveredPeriodStart }}</span>
                    <span class="pl5 pr5" v-if="engagementDetail.coveredPeriodEnd">~</span>
                    <span>{{ engagementDetail.coveredPeriodEnd }}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
    </el-affix>

    <div class="step mt46">
      <el-card>
        <div class="desp">
          <div class="desp-header">
            <div class="label">Linked Transactions</div>
          </div>
          <div>
            <div class="pt10 pb26" v-if="isAuth('engagement:linked')">
              <el-button v-if="engagementDetail.status == 'OPEN'" @click="editLinkedTransaction">Edit Linked Transactions</el-button>
            </div>
            <el-table :data="engagementDetail.relatedUserTransactions" style="width: 100%">
              <el-table-column prop="transactionNo" label="Transaction No." header-align="center" align="center"></el-table-column>
              <el-table-column prop="payee" label="Owner" header-align="center" align="center"></el-table-column>
              <el-table-column prop="relatedTransaction" label="Related Transaction" header-align="center" align="center">
                <template slot-scope="scope">{{ scope.row.relatedTransaction | thousands }}</template>
              </el-table-column>
              <el-table-column prop="relatedTurnover" label="Related Turnover" header-align="center" align="center">
                <template slot-scope="scope">{{ scope.row.relatedTurnover | thousands }}</template>
              </el-table-column>
              <el-table-column label="In-charge" header-align="center" align="center">
                <template slot-scope="scope">
                  <p class="mt0 mb0" v-for="(item, idx) in scope.row.inChargeUsers" :key="`inChargeUsers${idx}${item.id}`">
                    <span>{{ item.nickname }}</span>
                    <span>({{ item.username }})</span>
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-card>
    </div>

    <el-form class="feeLetterForm" :model="dataForm" ref="dataForm" label-position="left">
      <div class="step mt46" ref="engagementScroll">
        <el-card>
          <div class="desp">
            <div class="desp-header">
              <div class="label">Engagement Control</div>
            </div>
            <div class="pt10 pb26" v-if="isAuth('engagement:charge') && isHasCurrUserId">
              <el-button v-if="engagementDetail.status == 'OPEN'" @click="editEngagementControlModal">Edit Engagement Control</el-button>
              <el-button
                type="danger"
                plain
                class="ml16"
                v-if="engagementDetail.status == 'OPEN' && dataForm.controlDataList.length > 0"
                @click="clearEngagementControl"
              >
                Clear Engagement Control
              </el-button>
            </div>
            <div class="dateModule">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item
                    label="FY Period:"
                    prop="fyPeriod"
                    ref="fyPeriod"
                    :rules="{
                      required: true,
                      message: 'Please enter',
                      trigger: 'submit',
                    }"
                  >
                    <span class="lh38">{{ dataForm.fyPeriod }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="FY end:"
                    prop="fyEnd"
                    ref="fyEnd"
                    :rules="{
                      required: true,
                      message: 'Please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-date-picker
                      v-model="dataForm.fyEnd"
                      type="date"
                      :format="`dd [${monthList[parseInt(dataForm.fyEnd.split('-')[0]) - 1]}]`"
                      value-format="MM-dd"
                      v-if="dataForm.fyEnd"
                      :clearable="false"
                      :readonly="true"
                      disabled
                      class="datePicker"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-form-item
                prop="controlDataList"
                ref="controlDataList"
                :rules="{
                  type: 'array',
                  required: true,
                  message: 'Please add',
                  trigger: 'submit',
                }"
              >
                <engagement-control :controlDataList="dataForm.controlDataList"></engagement-control>
              </el-form-item>
            </div>
          </div>
        </el-card>
      </div>
    </el-form>

    <div class="step mt40">
      <el-card>
        <div class="desp">
          <div class="desp-header">
            <div class="label">Service Type</div>
          </div>
          <div>
            <div class="pt10 pb26" v-if="isAuth('engagement:charge') && isHasCurrUserId">
              <el-button v-if="engagementDetail.status == 'OPEN'" @click="editServiceType">Edit Service Type</el-button>
            </div>
            <el-table :data="serviceTypeDataList" style="width: 100%">
              <el-table-column prop="serviceType" header-align="center" align="center" label="Service Type"></el-table-column>
              <el-table-column prop="serviceDetails" header-align="center" align="center" label="Service Details">
                <template slot-scope="scope">
                  <span>{{ scope.row.serviceDetails.filter(item => item).join(", ") }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="relatedTransaction" label="Related Transaction" header-align="center" align="center">
                <template slot-scope="scope">
                  {{ scope.row.relatedTransaction | thousands }}
                </template>
              </el-table-column>
              <el-table-column prop="relatedTurnover" label="Related Turnover" header-align="center" align="center">
                <template slot-scope="scope">
                  {{ scope.row.relatedTurnover | thousands }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-card>
    </div>

    <!-- Linked Transactions 和 Service Type 里的 Related Transaction(加总) 、 Related Turnover(加总) -->
    <transaction-and-turnover-difference
      :relatedUserTransactions="engagementDetail.relatedUserTransactions"
      :serviceTypeDataList="serviceTypeDataList"
    ></transaction-and-turnover-difference>

    <!-- 状态操作日志弹框 -->
    <status-history ref="statusHistoryModal"></status-history>
    <!-- 发起重新开启修正申请 - 弹框 -->
    <re-open ref="reOpenModal" :engagementId="engagementId" :engagementDetail="engagementDetail" @reOpenFeedback="getDetail"></re-open>
    <!-- 评审开启修正功能的原因 （仅限超级管理员） -->
    <re-open-audit
      ref="reOpenAuditModal"
      :engagementId="engagementId"
      :engagementDetail="engagementDetail"
      @reOpenAuditFeedback="getDetail"
    ></re-open-audit>
    <!-- Linked Transactions - 弹框 -->
    <linked-transactions-modal
      :engagementId="engagementId"
      :controlDataList="dataForm.controlDataList"
      ref="linkedTransactionsModal"
      @linkedTransactionsFeedback="getDetail"
    ></linked-transactions-modal>
    <!-- 约定 - 弹框 -->
    <engagement-control-modal
      :engagementDetail="engagementDetail"
      ref="engagementControlModal"
      @engagementControlFeedback="engagementControlFeedback"
    ></engagement-control-modal>
    <!-- Service Type - 弹框 -->
    <service-type-modal ref="serviceTypeModal" @serviceTypeFeedback="serviceTypeFeedback"></service-type-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StatusHistory from "./modal/StatusHistory.vue";
import ReOpen from "./modal/ReOpen.vue";
import ReOpenAudit from "./modal/ReOpenAudit.vue";
import EngagementControlModal from "./modal/EngagementControl.vue";
import LinkedTransactionsModal from "./modal/LinkedTransactionsModal.vue";
import ServiceTypeModal from "./modal/ServiceTypeModal.vue";
import EngagementControl from "./components/EngagementControl.vue";
import TransactionAndTurnoverDifference from "./components/TransactionAndTurnoverDifference.vue";
import { validateLocation, fmoney } from "@/utils";
export default {
  components: {
    StatusHistory,
    ReOpen,
    ReOpenAudit,
    EngagementControlModal,
    LinkedTransactionsModal,
    ServiceTypeModal,
    EngagementControl,
    TransactionAndTurnoverDifference,
  },
  data() {
    return {
      affixed: false,
      engagementId: null,
      dataForm: {
        fyPeriod: "",
        fyEnd: "",
        controlDataList: [],
      },
      serviceTypeDataList: [],
      engagementDetail: {
        status: "",
        reopenStatus: null, // 重新开启审核的状态: 待审核 0, 通过 1, 未通过 2
        clientCode: "",
        uen: "",
        companyName: "",
        companyCountry: "",
        companyType: "",
        relatedUserTransactions: [],
        coveredPeriodStart: "",
        coveredPeriodEnd: "",
      },
      monthList: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    };
  },
  computed: {
    ...mapState("constant", ["serviceTypeList", "engagementCompanyTypeList", "engagementStatusList", "reopenStatusList"]),
    ...mapState("authority", ["activeRoleName"]),
    ...mapState("user", {
      currUserId: "id",
    }),
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
    },
    isHasCurrUserId() {
      const relatedUserTransactions = this.engagementDetail.relatedUserTransactions;
      const inChargeUserIdList = [];
      relatedUserTransactions.forEach(item => {
        item?.inChargeUsers.forEach(ele => {
          inChargeUserIdList.push(ele.id);
        });
      });
      return inChargeUserIdList.includes(this.currUserId);
    },
  },
  filters: {
    thousands(val) {
      if (val == "") {
        return "";
      } else if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  methods: {
    handleAffixChange(fixed) {
      this.affixed = fixed;
    },
    // status格式化
    statusFormatter(cellValue) {
      const one = this.engagementStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    // 重新打开请求状态
    reopenStatusFormatter(cellValue) {
      const one = this.reopenStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    // 申请单状态格式化
    applymentStatusFormat(v) {
      const one = this.applymentStatusList.find(i => i.value === v);
      return one?.title;
    },
    // Company Type格式化
    companyTypeFormat(v) {
      const one = this.engagementCompanyTypeList.find(i => i.value === v);
      return one?.title;
    },
    // Service Type table格式化
    serviceTypeFormat() {
      const list = this.dataForm.controlDataList;
      if (list && list.length == 0) return [];
      const engagementServiceAndTransactions = this.engagementDetail.engagementServiceAndTransactions;
      let arrRes = [];
      this.serviceTypeList.forEach(ele => {
        const nubItem = engagementServiceAndTransactions[ele.title];
        let arr = {
          serviceType: ele.title,
          serviceDetails: [],
          relatedTransaction: nubItem?.relatedTransaction,
          relatedTurnover: nubItem?.relatedTurnover,
        };
        list.forEach(item => {
          if (item.linkedServiceType.includes(ele.value)) {
            arr.serviceDetails.push(item.controlDesp);
          }
        });
        if (arr.serviceDetails.length > 0) {
          arrRes.push(arr);
        }
      });
      return arrRes;
    },
    // 详情
    getDetail() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/admin/engagementManage/${this.engagementId}/detail`, {
            params: {
              id: this.engagementId,
            },
          })
          .then(({ code, data, msg }) => {
            if (code === 200) {
              // 数组转对象
              const engagementServiceAndTransactionsList = data.engagementServiceAndTransactions || [];
              const engagementServiceAndTransactionsData = {};
              engagementServiceAndTransactionsList.forEach(element => {
                engagementServiceAndTransactionsData[element.serviceType] = {
                  relatedTransaction: element.relatedTransaction,
                  relatedTurnover: element.relatedTurnover,
                };
              });

              // 回显
              this.engagementDetail = {
                reopenAuditRemark: data.reopenAuditRemark,
                reopenReason: data.reopenReason,
                status: data.status,
                reopenStatus: data.reopenStatus,
                clientCode: data.clientCode || "",
                uen: data.uen || "",
                companyName: data.companyName || "",
                companyCountry: data.companyCountry || "",
                companyType: data.companyType || "",
                relatedUserTransactions: data.relatedUserTransactions || [],
                coveredPeriodStart: data.coveredPeriodStart || "",
                coveredPeriodEnd: data.coveredPeriodEnd || "",
                engagementServiceAndTransactions: engagementServiceAndTransactionsData,
              };

              // Engagement Control
              this.dataForm = {
                fyPeriod: data.fyPeriod || "",
                fyEnd: data.fyEnd || "",
                controlDataList: data.controlDataList || [],
              };

              // Service Type
              this.serviceTypeDataList = this.serviceTypeFormat();

              resolve();
            } else {
              this.$message.error(msg);
              reject(msg);
            }
          });
      });
    },
    // 状态操作日志
    openStatusHistoryModal() {
      this.$refs.statusHistoryModal.init(this.engagementId);
    },
    // Engagement Control - 保存
    engagementControlSaveHttp() {
      return new Promise((resolve, reject) => {
        this.$http.post(`/admin/engagementManage/${this.engagementId}/update`, this.dataForm).then(({ code, msg }) => {
          if (code === 200) {
            resolve();
          } else {
            this.$message.error(msg);
            reject(msg);
          }
        });
      });
    },
    // Service Type - 保存
    serviceTypeSaveHttp() {
      return new Promise((resolve, reject) => {
        this.$http
          .post("/admin/engagementServiceAndTransaction/saveRelatedTransactions", {
            engagementId: this.engagementId,
            dataList: this.serviceTypeDataList,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              resolve();
            } else {
              this.$message.error(msg);
              reject(msg);
            }
          });
      });
    },
    // 保存修改的内容
    async modifyHandle() {
      console.log(this.dataForm);
      this.$refs["dataForm"].clearValidate();
      await this.engagementControlSaveHttp();
      await this.serviceTypeSaveHttp();
      this.$message.success("success");
    },
    // 发起开启修正功能的申请
    reOpenHandle() {
      this.$refs.reOpenModal.init();
    },
    // 对开启修正申请进行评审
    reOpenAuditHandle() {
      this.$refs.reOpenAuditModal.init();
    },
    // 关闭
    closeHandle() {
      this.$refs["dataForm"].validate(async (valid, object) => {
        if (valid) {
          await this.engagementControlSaveHttp();
          await this.serviceTypeSaveHttp();
          this.closeCheckBefore();
        } else {
          validateLocation(object, this);
          return false;
        }
      });
    },
    // 关闭前的检查
    closeCheckBefore() {
      this.$confirm(
        "<p>- Work completed?</p><p>- Deliverables delivered to the client?</p><p>- Gov filing completed?</p><p>- Related doc are all uploaded to the sever?</p>",
        "Please check whether below actions are properly done before closure:",
        {
          customClass: "closureCheckCustom",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        },
      )
        .then(async () => {
          let controlDataList = this.dataForm.controlDataList;
          let checkWork = controlDataList.find(item => item.status == "NOT_STARTED" || item.status == "IN_PROGRESS");
          console.log(checkWork);
          if (checkWork) {
            this.$message({
              message: "Sorry, cannot close due to some unfinished work",
              type: "warning",
              duration: 1500,
              onClose: () => {
                // 滚动到Engagement Control
                this.$refs["engagementScroll"].scrollIntoView({
                  block: "start",
                  behavior: "smooth",
                });
              },
            });
            return;
          }
          const { code, msg } = await this.$http.post(`/admin/engagementManage/${this.engagementId}/close`, {});
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => this.getDetail(),
            });
          } else {
            this.$message.error(msg);
          }
        })
        .catch(() => {});
    },
    // 打开约定弹框
    editEngagementControlModal() {
      this.$refs.engagementControlModal.init(JSON.parse(JSON.stringify(this.dataForm)));
    },
    engagementControlFeedback(obj) {
      this.dataForm = obj;
      this.serviceTypeDataList = this.serviceTypeFormat();
    },
    // 清除Engagement Control
    async clearEngagementControl() {
      await this.$confirm("Clear Engagement Control. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      this.dataForm = {
        fyPeriod: "",
        fyEnd: "",
        controlDataList: [],
      };
    },
    // 编辑 - Linked Transactions
    editLinkedTransaction() {
      const relatedUserTransactions = this.engagementDetail.relatedUserTransactions;
      const relatedUserTransactionsCopy = JSON.parse(JSON.stringify(relatedUserTransactions));
      this.$refs.linkedTransactionsModal.init(relatedUserTransactionsCopy);
    },
    // 编辑 - Service Type
    editServiceType() {
      const serviceTypeDataList = JSON.parse(JSON.stringify(this.serviceTypeDataList));
      this.$refs.serviceTypeModal.init(serviceTypeDataList);
    },
    serviceTypeFeedback(list) {
      this.serviceTypeDataList = list;
    },
  },
  activated() {
    this.engagementId = this.$route.params.id;
    this.getDetail();
  },
  deactivated() {
    this.$refs["dataForm"].clearValidate();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.company-applyment-module {
  .engagementFixed {
    .el-card.is-always-shadow {
      border-top: none;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    }

    ::v-deep .el-card__body {
      padding: 10px 20px;
    }
  }

  .applyment-page-header {
    background-color: #fff;
    // padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .page-header-heading-left {
      display: none;
    }

    .page-header-heading-content {
      width: 620px;
      padding-top: 12px;
    }

    .page-header-heading-right {
      .opts {
        display: inline-block;
      }
    }

    .status {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
      color: #12a3ff;
      .fs12 {
        font-size: 12px;
      }
    }

    .status::before {
      display: inline-block;
      content: "";
      background: #12a3ff;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      vertical-align: middle;
      margin-right: 4px;
      margin-top: -4px;
    }
  }
  .applyment-page-header-top {
    .affix {
      z-index: 1030 !important;
    }
    .page-header-heading-left {
      display: inline-block;
    }
    .page-header-heading-content {
      display: none;
    }
    .status-label {
      display: none;
    }
  }

  .applyment-page-header-shrink {
    .affix {
      left: $sidebar-width !important;
      width: calc(100% - #{$sidebar-width} - 26px) !important;
    }
  }

  .applyment-page-header-unfold {
    .affix {
      left: $sidebar-full-width !important;
      width: calc(100% - #{$sidebar-full-width} - 26px) !important;
    }
  }

  .steps-bar-wrapper {
    position: fixed;
    right: 6px;
    top: 38vh;
    z-index: 1000;
  }

  .desp {
    color: rgb(53, 55, 68);

    .desp-header {
      font-size: 18px;
      padding: 10px 5px 10px;
      border-bottom: 1px solid #ebeef0;
      margin-bottom: 15px;
      color: #011a45;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
    }

    .desp-items-group {
      & + .desp-items-group {
        margin-top: 24px;
      }
    }

    .desp-items-group-title {
      padding: 1px 14px;
      font-size: 16px;
      margin-bottom: 10px;
      width: fit-content;
      border-left: 3px solid #eee;
      color: #011a45;
      font-weight: bold;
    }

    .desp-items-group-title-icon {
      padding: 1px 14px;
      font-size: 16px;
      margin-bottom: 10px;
      width: fit-content;
      color: #011a45;
      font-weight: bold;
    }

    .desp-items-group-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      color: #0060ff;
      // border-left: 3px solid #eee;
      background: #eceff9;
      margin-bottom: 15px;

      .title {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .desp-item {
      overflow: hidden;
    }

    .desp-item-label {
      width: 166px;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #667288;
      line-height: 40px;
      padding: 0 12px 0 0;
      box-sizing: border-box;
    }

    .desp-item-label:after {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }

    .desp-item-content {
      margin-left: 100px;
      line-height: 40px;
      position: relative;
      font-size: 14px;
      color: #011a45;
    }

    .desp-item-self {
      width: 100%;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0;
      box-sizing: border-box;
    }

    .desp-item-form {
      width: 100%;
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0;
      box-sizing: border-box;
    }
  }

  .dateModule {
    ::v-deep .el-form-item__error {
      padding-top: 0;
      left: 12px;
    }
  }
}

.border-type {
  display: inline-block;
  padding: 10px 20px;
  line-height: 1em;
  border-radius: 4px;
}

.pass-button {
  border: 1px solid $--color-success;
  background: #e0fff6;
  color: $--color-success;
}

.no-pass-button {
  border: 1px solid $--color-danger;
  background: #ffe4e4;
  color: $--color-danger;
}

.revised-button {
  border: 1px solid $--color-warning;
  background: #fff0e8;
  color: $--color-warning;
}

.no-checking-button {
  border: 1px solid $navbar--color-dark;
  background: transparent;
  color: $navbar--color-dark;
}

.notRequire {
  ::v-deep .el-textarea__inner {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input__count {
    line-height: 1em;
  }
}

.datePicker {
  line-height: 38px;
  ::v-deep .el-input__inner {
    border: none;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    background-color: transparent !important;
    cursor: text;
  }

  ::v-deep .el-input__prefix {
    display: none !important;
    ::v-deep .el-input__icon {
      display: none !important;
      width: 0 !important;
    }
  }

  ::v-deep .el-input__suffix {
    display: none !important;
  }
}
</style>

<style lang="scss">
.closureCheckCustom {
  .el-message-box__header {
    .el-message-box__title {
      font-size: 16px;
      width: 320px;
      line-height: 22px;
    }
  }

  .el-message-box__content {
    .el-message-box__status {
      display: none;
    }
    .el-message-box__message {
      padding-left: 0;
    }
  }
}
</style>
