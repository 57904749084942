var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Please tick before submission:",
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "before-close": _vm.beforeClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-position": "top"
    }
  }, [_vm._l(_vm.dataForm.qaList, function (item, idx) {
    return _c('div', {
      key: item.title
    }, [_c('el-form-item', {
      ref: `qaList.${idx}.value`,
      refInFor: true,
      attrs: {
        "label": item.title,
        "prop": `qaList.${idx}.value`,
        "rules": {
          required: true,
          message: 'please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-radio-group', {
      model: {
        value: _vm.dataForm.qaList[idx].value,
        callback: function ($$v) {
          _vm.$set(_vm.dataForm.qaList[idx], "value", $$v);
        },
        expression: "dataForm.qaList[idx].value"
      }
    }, _vm._l(_vm.completedCheckOptions, function (child) {
      return _c('el-radio', {
        key: `${item.param}${child.value}`,
        attrs: {
          "label": child.value
        }
      }, [_vm._v(_vm._s(child.title))]);
    }), 1)], 1)], 1);
  }), _c('el-form-item', {
    attrs: {
      "label": "Remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "please enter",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1)], 2), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.beforeClose
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }