<template>
  <div class="plan-detail-module" v-if="goodsListType">
    <div class="plan-base-wrapper">
      <div class="title">{{ detail.planName }}</div>
      <div class="price">
        <span>{{ detail.price | thousands }}</span>
        <span class="ml6 tdlt" v-if="detail.price != detail.originalPrice">({{ detail.originalPrice | thousands }})</span>
      </div>
      <div class="pt10 apply">
        <p class="fs12 for-people">Exclusive for:</p>
        <p class="fs12 summary">{{ detail.summary }}</p>
      </div>
    </div>
    <div class="pl20 mr30 plan-detail-wrapper">
      <div class="title">Services Include:</div>
      <div class="detail">
        <ul>
          <li v-for="module in detail.modules" :key="module.id">
            <!-- <svg-icon class="item-icon" name="dot"></svg-icon> -->
            <span class="el-icon-check item-icon"></span>
            <span class="item-name">{{ module.name }}</span>
            <span class="item-price">({{ module.price | thousands }})</span>
            <el-popover placement="bottom" trigger="hover" v-if="module.summary">
              <span class="fs12">{{ module.summary }}</span>
              <span slot="reference" class="fs13 question-icon el-icon-question"></span>
            </el-popover>
          </li>
        </ul>
      </div>
    </div>
    <div class="mr30 plan-detail-wrapper">
      <div class="pt40 detail">
        <ul>
          <li>
            <span class="el-icon-check item-icon"></span>
            <span class="item-name">Third party fee:</span>
          </li>
          <li v-for="module in detail.thirdpartys" :key="module.id">
            <span class="item-name">- {{ module.noteForThirdParty }}</span>
            <span class="item-price">({{ module.thirdPartyFee | thousands }})</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="plan-detail-wrapper">
      <div class="pt40 detail">
        <ul>
          <li>
            <span class="el-icon-check item-icon"></span>
            <span class="item-name">Other Fee:</span>
          </li>
          <li v-for="module in detail.otherFees" :key="module.id">
            <span class="item-name">- {{ module.noteForOther }}</span>
            <span class="item-price">({{ module.otherFee | thousands }})</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { fmoney, accountAdd } from "@/utils";
export default {
  props: {
    businessType: {
      type: String,
    },
    goodsList: {
      type: Array,
      default: function () {
        return [
          {
            productType: "plan",
            productId: "2233",
            planDetail: {
              name: "",
              country: "",
              summary: "",
              priceSg: "",
              moduleList: [
                {
                  country: "",
                  name: "",
                  summary: "",
                  priceSg: "",
                },
              ],
            },
            moduleDetail: {
              country: "",
              name: "",
              summary: "",
              priceSg: "",
            },
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  computed: {
    goodsListType() {
      const goodsList = this.goodsList;
      if (goodsList && goodsList.length > 0) {
        return goodsList[0].productType === "plan" ? 1 : 2;
      } else {
        return undefined;
      }
    },
    detail() {
      const goodsList = this.goodsList;
      if (this.goodsListType === 1) {
        const planDetail = goodsList[0].planDetail;
        const modules = planDetail.moduleList;
        return {
          planName: planDetail.name,
          summary: planDetail.summary,
          price: planDetail.price,
          originalPrice: this.sumPrice(modules, "price", "thirdPartyFee", "otherFee"),
          modules: modules,
          thirdpartys: this.mergeArr(JSON.parse(JSON.stringify(modules)), "noteForThirdParty", "thirdPartyFee"),
          otherFees: this.mergeArr(JSON.parse(JSON.stringify(modules)), "noteForOther", "otherFee"),
        };
      } else {
        const modules = goodsList.map(i => i.moduleDetail);
        let planName = "";
        if (this.businessType == "COMPANY_INCORPORATION") {
          planName = "Customize - Incorporation";
        } else if (this.businessType == "SERVICE_TRANSFER") {
          planName = "Customize - Service Transfer";
        }
        return {
          planName,
          summary: "Customers who wish to choose services flexibly.",
          price: this.sumPrice(modules, "price", "thirdPartyFee", "otherFee"),
          originalPrice: this.sumPrice(modules, "price", "thirdPartyFee", "otherFee"),
          modules: modules,
          thirdpartys: this.mergeArr(JSON.parse(JSON.stringify(modules)), "noteForThirdParty", "thirdPartyFee"),
          otherFees: this.mergeArr(JSON.parse(JSON.stringify(modules)), "noteForOther", "otherFee"),
        };
      }
    },
  },
  methods: {
    sumPrice(dataList, field = "price", second = "thirdPartyFee", other = "otherFee") {
      let sum = 0;
      dataList.map(i => {
        const thirdPartyFee = i[second] && parseFloat(i[second]) ? parseFloat(i[second]) : 0;
        const otherFee = i[other] && parseFloat(i[other]) ? parseFloat(i[other]) : 0;
        const thirdPartyFeeAndOtherFee = accountAdd(thirdPartyFee, otherFee);
        const price = i[field] && parseFloat(i[field]) ? parseFloat(i[field]) : 0;
        const thirdPartyFeeAndOtherFeeAndPrice = accountAdd(thirdPartyFeeAndOtherFee, price);
        sum = accountAdd(thirdPartyFeeAndOtherFeeAndPrice, sum);
      });
      return sum;
    },
    mergeArr(arr, key, nub) {
      let newArr = [];
      arr.forEach(item => {
        if (item[key]) {
          let dataItem = item;
          if (newArr.length > 0) {
            let filterValue = newArr.filter(v => {
              return v[key] == dataItem[key];
            });
            if (filterValue.length > 0) {
              newArr.forEach(n => {
                if (n[key] == filterValue[0][key]) {
                  let unit01 = filterValue[0][nub] && parseFloat(filterValue[0][nub]) ? parseFloat(filterValue[0][nub]) : 0;
                  let unit02 = dataItem[nub] && parseFloat(dataItem[nub]) ? parseFloat(dataItem[nub]) : 0;
                  n[nub] = accountAdd(unit01, unit02);
                }
              });
            } else {
              newArr.push(dataItem);
            }
          } else {
            newArr.push(dataItem);
          }
        }
      });
      return newArr;
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-detail-module {
  // width: 1200px;
  display: flex;
  // justify-content: space-between;
  padding: 20px;
  border-radius: 4px;
  background: #fff5f0;
}
.plan-base-wrapper {
  max-width: 500px;
  color: #011a45;
  padding-right: 20px;
  border-right: 1px solid #ede8e3;

  .title {
    font-size: 16px;
    font-weight: bolder;
    line-height: 23px;
  }

  .price {
    color: #ff7a37;
    font-size: 20px;
    font-weight: bolder;
    .origin {
      font-weight: normal;
      font-size: 14px;
      color: grey;
      text-decoration: line-through;
    }
    .tdlt {
      text-decoration: line-through;
      color: #bbc6d8;
      font-size: 16px;
    }
  }

  .for-people {
    font-weight: bold;
    line-height: 1em;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
  }

  .summary {
    line-height: 18px;
    margin: 0px;
    padding: 0px;
  }
}

.plan-detail-wrapper {
  .title {
    font-weight: bold;
    color: #011a45;
    font-size: 16px;
  }
  .title-small {
    font-size: 12px;
    color: #011a45;
  }
  .detail {
    // width: 500px;
    display: flex;
    justify-content: space-between;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      // font-size: 16px;
      display: inline-block;
      line-height: 20px;
      .item-icon {
        vertical-align: middle;
        color: #02cc90;
        margin-top: 3px;
        margin-right: 6px;
        font-weight: bold;
      }
      .item-name {
        vertical-align: middle;
        font-size: 12px;
        color: #011a45;
      }
      .item-price {
        vertical-align: middle;
        white-space: nowrap;
        padding-left: 10px;
        color: #011a45;
        font-size: 12px;
      }
      .question-icon {
        vertical-align: middle;
        color: #bbc6d8;
        margin-top: 2px;
        margin-left: 4px;
      }
    }
  }
}
</style>
