var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', {
    staticClass: "mod-user"
  }, [_c('el-form', {
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getDataList(1);
      }
    }
  }, [_c('div', {
    staticClass: "clearfix"
  }, [_c('div', {
    staticClass: "fl pb20"
  }, [_vm.isAuth('quotationV:save') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addOrUpdateHandle();
      }
    }
  }, [_vm._v("Add Quotation (V)")]) : _vm._e()], 1), _c('div', {
    staticClass: "fr"
  }, [_c('el-form-item', [_c('el-input', {
    staticClass: "input-with-select-lg",
    staticStyle: {
      "width": "500px"
    },
    attrs: {
      "placeholder": _vm.kwPlaceHolder
    },
    model: {
      value: _vm.dataForm.keyword,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "keyword", $$v);
      },
      expression: "dataForm.keyword"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend",
      "placeholder": "please select"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.kwType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "kwType", $$v);
      },
      expression: "dataForm.kwType"
    }
  }, _vm._l(_vm.kwTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1), _c('el-form-item', [_c('el-input', {
    directives: [{
      name: "minusPoint2",
      rawName: "v-minusPoint2"
    }],
    staticClass: "wd250",
    attrs: {
      "placeholder": "Amount",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.amount,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "amount", $$v);
      },
      expression: "dataForm.amount"
    }
  }, [_c('el-select', {
    staticClass: "wd110",
    attrs: {
      "slot": "prepend",
      "placeholder": "Currency",
      "clearable": "",
      "filterable": ""
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.currency,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "currency", $$v);
      },
      expression: "dataForm.currency"
    }
  }, _vm._l(_vm.currencyList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "multiple": "",
      "clearable": "",
      "placeholder": "Status"
    },
    model: {
      value: _vm.dataForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, _vm._l(_vm.supplementStatusList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "clearable": "",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.getBusinessNoRemote,
      "loading": _vm.businessNoLoading,
      "placeholder": "Transaction No."
    },
    on: {
      "clear": function ($event) {
        return _vm.getBusinessNoRemote('');
      },
      "focus": function ($event) {
        return _vm.getBusinessNoRemote('');
      }
    },
    model: {
      value: _vm.dataForm.businessNo,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "businessNo", $$v);
      },
      expression: "dataForm.businessNo"
    }
  }, _vm._l(_vm.businessNoList, function (item, index) {
    return _c('el-option', {
      key: `${item.businessNo}${index}`,
      attrs: {
        "label": `${item.businessNo}-${item.companyName}`,
        "value": item.businessNo
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "icon": "el-icon-search",
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.getDataList(1);
      }
    }
  }, [_vm._v("Search")])], 1)], 1)])]), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-height": "640",
      "data": _vm.dataList,
      "default-sort": {
        prop: 'createTime',
        order: 'descending'
      }
    },
    on: {
      "sort-change": _vm.tableSortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "supplementNo",
      "header-align": "center",
      "align": "center",
      "label": "Quotation (V) No.",
      "sortable": "custom",
      "width": "175",
      "fixed": "left"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "businessNo",
      "header-align": "center",
      "align": "center",
      "label": "Linked Transaction No.",
      "width": "186"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "billingParty",
      "header-align": "center",
      "align": "center",
      "label": "Billing Party",
      "min-width": "110"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "address",
      "header-align": "center",
      "align": "center",
      "label": "Address",
      "min-width": "130"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "country",
      "header-align": "center",
      "align": "center",
      "label": "Country",
      "min-width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "postalCode",
      "header-align": "center",
      "align": "center",
      "label": "Code",
      "min-width": "110"
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Currency",
      "min-width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? _c('p', {
          staticClass: "enbr"
        }, [_vm._v(_vm._s(_vm.currencyFormat(scope.row.paymentDetail.currency)))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Exchange Rate",
      "min-width": "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? [scope.row.paymentDetail.currency != 'SGD' ? _c('p', [_vm._v(" " + _vm._s(scope.row.paymentDetail.exchangeRate) + " ")]) : _vm._e()] : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Amount",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? _c('p', [_vm._v(_vm._s(_vm._f("thousands")(scope.row.paymentDetail.amount, scope.row.paymentDetail.currencyUnit)))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "applyReason",
      "header-align": "center",
      "align": "center",
      "label": "Description",
      "min-width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "thirdPartyFee",
      "header-align": "center",
      "align": "center",
      "label": "Third party fee",
      "min-width": "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? _c('p', [_vm._v(_vm._s(_vm._f("thousands")(scope.row.paymentDetail.thirdPartyFee, scope.row.paymentDetail.currencyUnit)))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "noteForThirdParty",
      "header-align": "center",
      "align": "center",
      "label": "Note For Third Party",
      "min-width": "170"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "otherFee",
      "header-align": "center",
      "align": "center",
      "label": "Other Fee",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.paymentDetail ? _c('p', [_vm._v(_vm._s(_vm._f("thousands")(scope.row.paymentDetail.otherFee, scope.row.paymentDetail.currencyUnit)))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "noteForOther",
      "header-align": "center",
      "align": "center",
      "label": "Note For Other Fee",
      "min-width": "160"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "attachmentUrls",
      "header-align": "center",
      "align": "center",
      "label": "Attachments",
      "min-width": "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.attachmentUrls, function (item) {
          return _c('p', {
            key: item.url
          }, [_c('a', {
            attrs: {
              "href": item.url,
              "target": "_target"
            }
          }, [_vm._v(_vm._s(item.name))])]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "createTime",
      "header-align": "center",
      "align": "center",
      "label": "Create Time",
      "sortable": "custom",
      "min-width": "130"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "min-width": "144"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "fxRow"
        }, [_c('p', {
          staticClass: "mr4"
        }, [_vm._v(_vm._s(_vm.statusFormat(scope.row.status)))]), _c('el-link', {
          attrs: {
            "type": "primary",
            "underline": false
          },
          on: {
            "click": function ($event) {
              return _vm.openStatusHistoryModal(scope.row.id);
            }
          }
        }, [_vm._v("(Track)")])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "customerOrder",
      "header-align": "center",
      "align": "center",
      "width": "120",
      "label": "Order Status",
      "formatter": _vm.orderStatusFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "width": "120",
      "label": "Action",
      "fixed": "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.isAuth('quotationV:update') && (scope.row.status == 'PENDING' || scope.row.status == 'REJECTED') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.addOrUpdateHandle(scope.row);
            }
          }
        }, [_vm._v(" Edit ")]) : _vm._e(), _vm.isAuth('quotationV:audit') && scope.row.status == 'PENDING' ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.reviewHandle(scope.row);
            }
          }
        }, [_vm._v(" Review ")]) : _vm._e(), _vm.isAuth('quotationV:delete') && (scope.row.status == 'PENDING' || scope.row.status == 'REJECTED') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteHandle(scope.row);
            }
          }
        }, [_vm._v(" Delete ")]) : _vm._e(), _vm.isAuth('quotationV:forfeit') && scope.row.status == 'PASSED' && scope.row.customerOrder && (scope.row.customerOrder.status == 'UNPAID' || scope.row.customerOrder.status == 'PARTLY_PAID') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.statusUpdateHandle(scope.row, 'Forfeit');
            }
          }
        }, [_vm._v(" Forfeit ")]) : _vm._e(), _vm.isAuth('quotationV:reActive') && scope.row.status == 'FORFEITED' && scope.row.customerOrder && (scope.row.customerOrder.status == 'UNPAID' || scope.row.customerOrder.status == 'PARTLY_PAID' || scope.row.customerOrder.status == 'FORFEITED') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.statusUpdateHandle(scope.row, 'Re-active');
            }
          }
        }, [_vm._v(" Re-active ")]) : _vm._e()];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.pageSize,
      "total": _vm.totalCount,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  }), _c('add-or-update', {
    ref: "addOrUpdate",
    on: {
      "refreshDataList": _vm.getDataList
    }
  }), _c('status-history', {
    ref: "statusHistoryModal"
  }), _c('supplement-review', {
    ref: "supplementReviewModal",
    on: {
      "refreshStatus": _vm.getDataList
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }