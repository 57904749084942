<template>
  <el-dialog width="900px" :title="!dataForm.id ? 'Add User' : 'Edit User'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="120px">
      <el-form-item label="Nickname" prop="nickname">
        <el-input v-model="dataForm.nickname" placeholder="Nick name" disabled></el-input>
      </el-form-item>
      <el-form-item label="Roles" size="mini" prop="roleIdList">
        <el-checkbox-group v-model="dataForm.roleIdList">
          <el-checkbox v-for="role in roleList" :key="role.id" :label="role.id">{{ role.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isEmail, isMobile } from "@/utils/validate";
import { mapState } from "vuex";
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("Please provide your password"));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("Please confirm your password"));
      } else if (this.dataForm.password !== value) {
        callback(new Error("Passwords do not match"));
      } else {
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("Please provide a valid email address"));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value) {
        if (this.dataForm.prefix) {
          callback();
        } else {
          callback(new Error("Please select the prefix of the mobile phone number"));
        }
      } else {
        if (this.dataForm.prefix) {
          callback(new Error("Please enter your mobile phone number"));
        } else {
          callback();
        }
      }
    };
    return {
      visible: false,
      roleList: [],
      employersList: [],
      dataForm: {
        id: 0,
        email: "",
        password: "",
        comfirmPassword: "",
        nickname: "",
        prefix: "",
        phone: "",
        roleIdList: [],
        enabled: true,
        remark: "",

        staffNo: "",
        englishName: "",
        localName: "",
        position: "",
        employers: [],
        employStart: "",
        employEnd: "",
      },
      dataRule: {
        email: [{ validator: validateEmail, trigger: "blur" }],
        password: [{ validator: validatePassword, trigger: "blur" }],
        comfirmPassword: [{ validator: validateComfirmPassword, trigger: "blur" }],
        nickname: [{ required: true, message: "Nick name is required", trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],

        staffNo: [{ required: true, message: "Staff No. is required", trigger: "blur" }],
        position: [{ required: true, message: "Position is required", trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["prefixOptions"]),
  },
  methods: {
    async init(id, eList) {
      this.submitControl = true;
      this.employersList = eList;
      const { code, data } = await this.$http.get("/admin/sysRole/getList");
      this.roleList = code === 200 ? data : [];
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      if (id) {
        this.dataForm.id = id;
        const { code, data } = await this.$http.get(`/admin/sysUser/${this.dataForm.id}/detail`);
        if (code === 200) {
          let phoneCopy = data.phone && data.phone.includes("-") ? data.phone.split("-") : ["", ""];
          this.dataForm.email = data.email;
          this.dataForm.password = "";
          this.dataForm.nickname = data.nickname;
          this.dataForm.prefix = phoneCopy[0];
          this.dataForm.phone = phoneCopy[1];
          this.dataForm.roleIdList = data.roleList.map(i => i.id);
          this.dataForm.enabled = data.enabled;
          this.dataForm.remark = data.remark;

          this.dataForm.staffNo = data.staffNo;
          this.dataForm.englishName = data.englishName;
          this.dataForm.localName = data.localName;
          this.dataForm.position = data.position;
          this.dataForm.employers = data.employers;
          this.dataForm.employStart = data.employStart;
          this.dataForm.employEnd = data.employEnd;
        }
      } else {
        this.dataForm = {
          id: 0,
          email: "",
          password: "",
          comfirmPassword: "",
          nickname: "",
          prefix: "",
          phone: "",
          roleIdList: [],
          enabled: true,
          remark: "",

          staffNo: "",
          englishName: "",
          localName: "",
          position: "",
          employers: [],
          employStart: "",
          employEnd: "",
        };
      }
    },
    // 表单提交
    async formSubmitRequest(dataForm) {
      let phoneCopy = dataForm.prefix + "-" + dataForm.phone;
      const payload = {
        email: dataForm.email,
        password: dataForm.password || undefined,
        nickname: dataForm.nickname,
        phone: phoneCopy,
        enabled: dataForm.enabled,
        remark: dataForm.remark,
        roleIdList: dataForm.roleIdList,

        staffNo: dataForm.staffNo,
        englishName: dataForm.englishName,
        localName: dataForm.localName,
        position: dataForm.position,
        employers: dataForm.employers,
        employStart: dataForm.employStart,
        employEnd: dataForm.employEnd,
      };
      if (dataForm.id) {
        return await this.$http.post(`/admin/sysUser/${dataForm.id}/update`, payload);
      } else {
        return await this.$http.post(`/admin/sysUser/create`, payload);
      }
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.formSubmitRequest(this.dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
