import E from "wangeditor";

const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E;

/**
 * 转义HTML标签的方法
 * @param  {String} str 需要转义的HTML字符串
 * @return {String}     转义后的字符串
 */
var funEncodeHTML = function (str) {
  if (typeof str == "string") {
    return str.replace(/<|&|>/g, function (matches) {
      return {
        "<": "&lt;",
        ">": "&gt;",
        "&": "&amp;",
      }[matches];
    });
  }

  return "";
};

/**
 * 反转义HTML标签的方法
 * @param  {String} str 需要反转义的字符串
 * @return {String}     反转义后的字符串
 */
var funDecodeHTML = function (str) {
  if (typeof str == "string") {
    return str.replace(/&lt;|&gt;|&amp;/g, function (matches) {
      return {
        "&lt;": "<",
        "&gt;": ">",
        "&amp;": "&",
      }[matches];
    });
  }

  return "";
};

export default class GetSource extends BtnMenu {
  constructor(editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = E.$(
      `<div class="w-e-menu" data-title="源码">
      <div class="w-e-up-btn">
        <i class="w-e-icon-terminal"></i>
      </div>
    </div>`,
    );
    super($elem, editor);
  }
  showSource() {
    this.isHTML = !this.isHTML;
    let source = this.editor.txt.html();
    if (this.isHTML) {
      source = funEncodeHTML(source);
    } else {
      source = funDecodeHTML(this.editor.txt.text());
    }
    this.editor.txt.html(source);
  }
  clickHandler() {
    this.showSource();
    this.tryChangeActive();
  }
  tryChangeActive() {
    if (this.isHTML) {
      this.active();
    } else {
      this.unActive();
    }
  }
}
