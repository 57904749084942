<template>
  <el-dialog title="Reopen Audit" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="138px">
      <el-form-item label="Reopen Reason" v-if="engagementDetail.reopenStatus == 0 && engagementDetail.reopenReason">
        <span>{{ engagementDetail.reopenReason }}</span>
      </el-form-item>
      <el-form-item label="Status" prop="reopenStatus">
        <el-radio-group v-model="dataForm.reopenStatus">
          <el-radio :label="2">Rejected</el-radio>
          <el-radio :label="1">Pass</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    engagementId: {
      type: [String, null],
      require: true,
    },
    engagementDetail: {
      type: Object,
      default: function () {
        return {
          reopenStatus: null,
          reopenReason: null,
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        reopenStatus: 1,
        remark: "",
      },
      dataRule: {
        reopenStatus: [{ required: true, message: "please select", trigger: "submit" }],
      },
      submitControl: true,
    };
  },
  methods: {
    async init() {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.$http.post(`/admin/engagementManage/${this.engagementId}/reopenAudit`, this.dataForm).then(({ code, msg }) => {
            if (code === 200) {
              this.visible = false;
              this.submitControl = true;
              this.$emit("reOpenAuditFeedback");
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
  },
};
</script>
