var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1200px",
      "title": "Associated Record",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "border": "",
      "data": _vm.dataList
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Linked Transaction No.",
      "prop": "linkedApplymentNo",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Company",
      "prop": "companyName",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Submit Time",
      "prop": "commitTime",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Time of application completion",
      "prop": "finishTime",
      "align": "center"
    }
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.size,
      "total": _vm.total,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }