// 只能输入正整数(包括0)或小数，小数只保留4位
export default {
  bind(el, binding, vnode) {
    el.keyupHandler = event => {
      const currentValue = event.target.value;
      let newValue = "";
      // 移除非数字字符和多余的小数点
      for (let i = 0; i < currentValue.length; i++) {
        if (currentValue[i].match(/[0-9.]/)) {
          if (currentValue[i] === ".") {
            if (newValue.indexOf(".") === -1) {
              newValue += ".";
            }
          } else {
            newValue += currentValue[i];
          }
        }
      }

      // 如果第一位就是小数点，就设为空字符串
      const len1 = newValue.substr(0, 1);
      if (len1 === ".") {
        newValue = "";
      }

      // 如果第一位是0，第二位不是小数点，就用第二位的数字把第一位的0替换掉
      const len2 = newValue.substr(1, 1);
      if (newValue.length > 1 && len1 == 0 && len2 != ".") {
        newValue = newValue.substr(1, 1);
      }

      // 如果小数点后面超过4位，就只保留4位
      const pointIndex = newValue.indexOf(".");
      if (pointIndex != -1) {
        newValue = newValue.substr(0, pointIndex + 5);
      }

      event.target.value = newValue;
      vnode.context.inputValue = newValue;

      // 更新v-model
      if (vnode.componentInstance) {
        // 如果是自定义组件就触发自定义组件的input事件
        vnode.componentInstance.$emit("input", event.target.value);
      } else {
        // 如果是原生组件就触发原生组件的input事件
        el.dispatchEvent(new Event("input"));
      }
    };

    // 添加监听键盘输入
    el.addEventListener("keyup", el.keyupHandler);
  },
  unbind(el) {
    // 解除键盘监听
    el.removeEventListener("keyup", el.keyupHandler);
  },
};
