<template>
  <div class="mb5 fluid-width quotationWrap">
    <div class="shadeWrap">
      <!-- 表格 -->
      <div class="billingTitle">
        <el-row :gutter="5" class="pt20 pb20 fxRow borderTop">
          <el-col :span="2">
            <p class="fs12 fwb text-center">Types</p>
          </el-col>
          <el-col :span="3">
            <p class="fs12 fwb text-center">Type Details</p>
          </el-col>
          <el-col :span="3">
            <p class="fs12 fwb text-center">Linked Transaction</p>
          </el-col>
          <el-col :span="3">
            <p class="fs12 fwb text-center">Covered Period</p>
          </el-col>
          <el-col :span="2">
            <p class="fs12 fwb wbbw text-center">Deadline</p>
          </el-col>
          <el-col :span="2">
            <p class="fs12 fwb text-center">Status</p>
          </el-col>
          <el-col :span="3">
            <p class="fs12 fwb text-center">Linked Service Type</p>
          </el-col>
          <el-col :span="3">
            <p class="fs12 fwb text-center">Closure Checklist</p>
          </el-col>
          <el-col :span="3">
            <p class="fs12 fwb text-center">Remark</p>
          </el-col>
        </el-row>
      </div>
      <template v-if="controlDataList.length > 0">
        <div class="billingContent" v-for="(child, idx) in controlDataList" :key="`child${idx}`">
          <el-row :gutter="5" class="pt20 pb20 fxRow borderTop">
            <el-col :span="2">
              <p class="fs12 text-center">{{ child.controlType }}</p>
            </el-col>
            <el-col :span="3">
              <p class="fs12 wbbw text-center">{{ child.controlDesp }}</p>
            </el-col>
            <el-col :span="3">
              <p class="fs12 wbbw text-center">{{ child.linkedTransactionNo ? child.linkedTransactionNo.join(", ") : "" }}</p>
            </el-col>
            <el-col :span="3" class="flex align-center justify-center">
              <p class="fs12 wbbw text-center wd110">{{ child.coveredStart }}</p>
              <span class="ml2 mr2">~</span>
              <p class="fs12 wbbw text-center wd110">{{ child.coveredEnd }}</p>
            </el-col>
            <el-col :span="2">
              <p class="fs12 wbbw text-center">{{ child.deadline }}</p>
            </el-col>
            <el-col :span="2">
              <p class="fs12 wbbw text-center">{{ engagementControlStatusFormat(child.status) }}</p>
            </el-col>
            <el-col :span="3">
              <p class="fs12 wbbw text-center">{{ serviceTypeFormat(child.linkedServiceType) }}</p>
            </el-col>
            <el-col :span="3">
              <p class="fs12 wbbw text-center" v-for="(uniterm, xv) in child.closureChecklist" :key="`list${idx}${xv}`">
                {{ uniterm.title }}
                <span>{{ completedCheckFormat(uniterm.value) }}</span>
              </p>
            </el-col>
            <el-col :span="3">
              <p class="fs12 wbbw text-center">{{ child.remark }}</p>
            </el-col>
          </el-row>
        </div>
      </template>
    </div>
    <!-- 为空时的占位 -->
    <template v-if="controlDataList.length == 0">
      <div class="noData">
        <p>No Data</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    controlDataList: {
      type: Array,
      require: true,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("constant", ["serviceTypeList", "engagementControlStatusList", "completedCheckOptions"]),
  },
  methods: {
    serviceTypeFormat(list) {
      let one = this.serviceTypeList.map(i => (list.includes(i.value) ? i.title : null));
      let two = one.filter(j => j);
      return two.join(", ");
    },
    engagementControlStatusFormat(v) {
      const one = this.engagementControlStatusList.find(i => i.value === v);
      return one?.title;
    },
    completedCheckFormat(v) {
      const one = this.completedCheckOptions.find(i => i.value === v);
      return one?.title;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.billingTo {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.quotationWrap {
  ::v-deep .el-form-item {
    margin-bottom: 0px;
  }

  ::v-deep .el-form-item__content {
    line-height: 30px;
  }

  ::v-deep .el-form-item__error {
    padding-top: 1px !important;
    left: 50%;
    margin-left: -40px;
  }

  ::v-deep .el-input__inner {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 20px;
    border: none;
    height: 30px;
    line-height: 30px;
    background: #fff5f0;
    text-align: center;
  }

  .amountInput {
    ::v-deep .el-input__inner {
      padding-right: 10px;
    }
  }

  .text-left {
    ::v-deep .el-input__inner {
      text-align: left;
    }
  }

  ::v-deep .el-input__prefix {
    display: none;
  }

  ::v-deep .el-input__suffix {
    right: 0px;
  }

  ::v-deep .el-input__icon {
    line-height: 30px;
  }

  ::v-deep .el-radio__label {
    font-size: 12px;
  }
}

.blackD8 {
  color: $navbar--color-dark;
}

.orange {
  color: $--color-warning;
  font-size: 20px;
  font-weight: bolder;
}

.blue {
  color: $--color-primary;
}

.red {
  color: $--color-danger;
}

p {
  line-height: 16px;
  margin: 0;
  color: #011a45;
}

.shadeWrap {
  width: 100%;
  padding: 0 5px;
  border-left: 1px solid #e4e7ed;
  border-right: 1px solid #e4e7ed;
  background-color: #fff5f0;
  margin-top: 6px;
}

.noData {
  width: 100%;
  height: 60px;
  background: #fff;
  border-bottom: 1px solid #e4e7ed;

  p {
    width: 100%;
    text-align: center;
    line-height: 60px;
    color: #909399;
    font-size: 14px;
  }
}

.comboLeft {
  padding-right: 60px;
}

.comboRight {
  padding-left: 60px;
  border-left: 1px solid #e4e7ed;
}

.messageTitle {
  border-radius: 2px;
  font-size: 18px;
  line-height: 1em;
  font-weight: bold;
}

.mustTitle {
  border-radius: 2px;
  font-size: 16px;
  line-height: 1em;
  font-weight: 600;
}

.mustTitle:before {
  content: "*";
  margin-right: 4px;
  color: #f56c6c;
}

.singleTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 10px;
}

.singleText {
  font-size: 12px;
  line-height: 22px;
  margin-right: 30px;
  word-wrap: break-word;
  word-break: break-all;
}

.borderTop {
  border-top: 1px solid #e4e7ed;
}

.borderBottom {
  border-bottom: 1px solid #e4e7ed;
}
</style>
