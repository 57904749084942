<template>
  <el-dialog title="Batch Confirm Receipt" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="140px">
      <el-form-item label="" prop="status">
        <el-radio-group v-model="dataForm.status">
          <template>
            <el-radio label="COMPLETE">Fully received</el-radio>
            <el-radio label="PARTLY_PAID">Partially received</el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Pay Type" prop="payType">
        <el-radio-group v-model="dataForm.payType">
          <template>
            <el-radio label="ALIPAY">Alipay</el-radio>
            <el-radio label="BANK_TRANSFER">Bank Transfer</el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        status: "",
        payType: "",
      },
      dataRule: {
        status: [{ required: true, message: "please select", trigger: "submit" }],
        payType: [{ required: true, message: "please select", trigger: "submit" }],
      },
      orderIdsList: [],
      submitControl: true,
    };
  },
  methods: {
    ...mapActions("finance", ["doBatchReceipt"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.orderIdsList = data;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doBatchReceipt({ orderIds: this.orderIdsList, status: this.dataForm.status, payType: this.dataForm.payType })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
