var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "site-wrapper site-page--not-found"
  }, [_c('div', {
    staticClass: "site-content__wrapper"
  }, [_c('div', {
    staticClass: "site-content"
  }, [_c('h2', {
    staticClass: "not-found-title"
  }, [_vm._v("400")]), _vm._m(0), _c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Previous Page")]), _c('el-button', {
    staticClass: "not-found-btn-gohome",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'home'
        });
      }
    }
  }, [_vm._v("Back Home")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "not-found-desc"
  }, [_vm._v(" Oops! "), _c('em', [_vm._v("Page not found")]), _vm._v(" ... ")]);

}]

export { render, staticRenderFns }