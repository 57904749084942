<template>
  <div class="page-container">
    <generate ref="generateModalRef" :generateData="generateData" @updateGenerateData="updateGenerateData">
      <el-button type="primary" @click="defaultHandle" v-if="isAuth('budget:default')">
        Default
        <el-popover placement="bottom" trigger="hover">
          <span class="fs12">Reset all data and restore the default data</span>
          <span slot="reference" class="fs14 ml4 el-icon-warning"></span>
        </el-popover>
      </el-button>
      <el-button @click="updateHandle" class="mr30" v-if="isAuth('budget:update')">
        Update
        <el-popover placement="bottom" trigger="hover">
          <span class="fs12">Update added or deleted data</span>
          <span slot="reference" class="fs14 ml4 el-icon-warning"></span>
        </el-popover>
      </el-button>
      <el-button type="primary" plain icon="el-icon-download" :disabled="totalCount == 0" @click="handleExportExcel" v-if="isAuth('budget:export')">
        Export Excel
      </el-button>
      <el-button
        type="info"
        ref="saveEnteredRef"
        :plain="focusInputList.length == 0"
        :disabled="dataList.length == 0"
        @click="saveHandle($event)"
        v-if="isAuth('budget:save')"
      >
        Save Entered
        <el-popover placement="bottom" trigger="hover">
          <span class="fs14">Save the data entered in the table</span>
          <span slot="reference" class="fs14 ml4 el-icon-warning"></span>
        </el-popover>
      </el-button>
    </generate>

    <div>
      <el-table
        max-height="750"
        :data="dataList"
        v-loading="dataListLoading"
        style="width: 100%"
        class="tableDomClass"
        :row-class-name="tableRowDisable"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column header-align="center" align="center" label="Introduced By" min-width="300">
          <template slot-scope="scope">
            <template v-if="scope.row.commissionList">
              <div v-for="item in scope.row.commissionList" :key="`commissionList${item.id}${item.name}`">
                {{ item.name }} - {{ multiply(item.commissionRate) }}
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="clientCode" header-align="center" align="center" label="Client Code" min-width="120"></el-table-column>
        <el-table-column prop="companyName" header-align="center" align="center" label="Entity Name" min-width="200"></el-table-column>
        <el-table-column header-align="center" align="center" label="Covered Period" width="130">
          <template slot-scope="scope">
            <div>{{ scope.row.coveredPeriodStart }}</div>
            <div>{{ scope.row.coveredPeriodEnd }}</div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Service Type" min-width="300">
          <template slot-scope="scope">
            <div class="enbr" v-if="scope.row.serviceTypeList">{{ scope.row.serviceTypeList.join(", ") }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="actualTransaction" header-align="center" align="center" min-width="120">
          <template slot="header">
            <div class="mediumBlue">
              Transaction
              <br />
              (Actual)
            </div>
          </template>
          <template slot-scope="scope" v-if="scope.row.status">
            <div>{{ thousands(scope.row.actualTransaction) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="actualTurnover" header-align="center" align="center" min-width="120">
          <template slot="header">
            <div class="mediumBlue">
              Turnover
              <br />
              (Actual)
            </div>
          </template>
          <template slot-scope="scope" v-if="scope.row.status">
            <div>{{ thousands(scope.row.actualTurnover) }}</div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" min-width="140">
          <template slot="header">
            <div class="mediumBlue">
              Turnover
              <br />
              (Actual Adj.)
            </div>
          </template>
          <template slot-scope="scope" v-if="scope.row.status">
            <template v-if="isAuth('budget:enter')">
              <div class="fxRow lh36 borderNone" :class="{ borderBold: focusInputList.includes(`${scope.row.id}Actual`) }">
                <el-input
                  v-model="scope.row.actualAdjustTurnover"
                  v-minusPoint2
                  clearable
                  placeholder="0.00"
                  class="fluid-width"
                  @input="actualAdjustTurnoverInput(scope.row)"
                  @focus="actualAdjustTurnoverFocus(scope.row)"
                >
                  <template slot="prefix">S$</template>
                </el-input>
              </div>
            </template>
            <div v-else>{{ thousands(scope.row.actualAdjustTurnover) }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="budgetAutoTurnover" header-align="center" align="center" min-width="130">
          <template slot="header">
            <div class="darkBlue">
              Turnover
              <el-popover placement="bottom" trigger="hover">
                <span class="fs12">
                  (Turnover (actual)+Turnover (Actual adj.)) * days of budget period / days of covered period = Turnover (Auto Budget)
                </span>
                <span slot="reference" class="pearl-blue fs16 ml4 el-icon-warning"></span>
              </el-popover>
              <br />
              (Auto Budget)
            </div>
          </template>
          <template slot-scope="scope" v-if="scope.row.status">
            <div>{{ thousands(scope.row.budgetAutoTurnover) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="budgetAutoCommissionTurnover" header-align="center" align="center" min-width="126">
          <template slot="header">
            <div class="darkBlue">
              Commission
              <br />
              (Auto Budget)
            </div>
          </template>
          <template slot-scope="scope" v-if="scope.row.status">
            <div>{{ thousands(scope.row.budgetAutoCommissionTurnover) }}</div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" min-width="140">
          <template slot="header">
            <div class="darkBlue">
              Turnover
              <br />
              (Budget Adj.)
            </div>
          </template>
          <template slot-scope="scope" v-if="scope.row.status">
            <template v-if="isAuth('budget:enter')">
              <div class="fxRow lh36 borderNone" :class="{ borderBold: focusInputList.includes(`${scope.row.id}Budget`) }">
                <el-input
                  v-model="scope.row.budgeAdjustTurnover"
                  v-minusPoint2
                  clearable
                  placeholder="0.00"
                  class="fluid-width"
                  @input="budgeAdjustTurnoverInput(scope.row)"
                  @focus="budgeAdjustTurnoverFocus(scope.row)"
                >
                  <template slot="prefix">S$</template>
                </el-input>
              </div>
            </template>
            <div v-else>{{ thousands(scope.row.budgeAdjustTurnover) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="budgeAdjustedTurnover" header-align="center" align="center" min-width="150">
          <template slot="header">
            <div class="darkBlue">
              Turnover
              <el-popover placement="bottom" trigger="hover">
                <span class="fs12">Turnover (Auto Budget) - Commission (Auto Budget) + Turnover (Budget Adj.) = Turnover (Adjusted Budget)</span>
                <span slot="reference" class="pearl-blue fs16 ml4 el-icon-warning"></span>
              </el-popover>
              <br />
              (Adjusted Budget)
            </div>
          </template>
          <template slot-scope="scope" v-if="scope.row.status">
            <div>{{ thousands(scope.row.budgeAdjustedTurnover) }}</div>
          </template>
        </el-table-column>

        <el-table-column header-align="center" align="center" width="110" label="Action" fixed="right">
          <template slot="header">
            <div>
              Action
              <el-popover placement="bottom" trigger="hover">
                <span class="fs12">"Disabled" does not participate in the calculation</span>
                <span slot="reference" class="pearl-blue fs16 el-icon-warning"></span>
              </el-popover>
            </div>
          </template>
          <template slot-scope="scope">
            <!-- 启用 -->
            <template v-if="isAuth('budget:enable')">
              <el-button type="text" class="fwb" @click="enableHandle(scope.row)" v-if="!scope.row.status">Enable</el-button>
            </template>
            <!-- 禁用 -->
            <template v-if="isAuth('budget:disable')">
              <el-button type="text" class="fwb ml0" @click="disableHandle(scope.row)" v-if="scope.row.status">Disable</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { open, fmoney, accountAdd, sub, multiply, div } from "@/utils";
import axiosInstance from "@/utils/http";
import Generate from "./components/Generate";
import { mapState } from "vuex";
export default {
  data() {
    return {
      focusInputList: [], // Turnover (Actual Adj.) 、Turnover (Budget Adj.) 输入框获得焦点列表
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataList: [],
      generateData: {
        introducedByOption: 1,
        commissionId: null,
        clientCodeOption: 1,
        clientCodeList: [],
        companyNameList: [],
        coveredPeriodStart: "",
        coveredPeriodEnd: "",
        serviceTypeList: [],
        budgetPeriod: 90,
        coveredPeriodDeadlineStart: "",
        coveredPeriodDeadlineEnd: "",
      }, // 生成(Generate)数据
      subtotalInitData: {}, // 当前页小计初始数据
      totalData: {}, // 总计数据(接口获取)
      totalBackupsData: {}, // 总计数据(totalData备份)
      totalLiveUpdateData: {}, // 总计数据(实时更新)
    };
  },
  components: { Generate },
  computed: {
    ...mapState("constant", ["clientCodeStatusList", "serviceTypeList", "budgetPeriodList", "introducedByArr", "entityTypes"]),
  },
  methods: {
    // 千分位
    thousands(val) {
      if (val === "") {
        return "";
      } else if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
    // 百分比
    multiply(val) {
      return multiply(val, 100) + "%";
    },
    // 格式化
    introducedByFormat(val) {
      const one = this.introducedByArr.find(i => i.value === val);
      return one?.title;
    },
    serviceTypeFormat(val) {
      const one = this.serviceTypeList.find(i => i.title === val);
      return one?.title;
    },
    // 设置禁用状态样式
    tableRowDisable({ row, rowIndex }) {
      if (!row.status) {
        return "disableRow";
      } else {
        return "";
      }
    },
    // 重置Generate
    initGenerateData(data) {
      this.generateData = {
        introducedByOption: data?.introducedByOption || 1,
        commissionId: data?.commissionId || null,
        clientCodeOption: data?.clientCodeOption || 1,
        clientCodeList: data?.clientCodeList || [],
        companyNameList: data?.companyNameList || [],
        coveredPeriodStart: data?.coveredPeriodStart || "",
        coveredPeriodEnd: data?.coveredPeriodEnd || "",
        serviceTypeOption: data?.serviceTypeOption || 1,
        serviceTypeList: data?.serviceTypeList || [],
        budgetPeriod: data?.budgetPeriod || 90,
        coveredPeriodDeadlineStart: data?.coveredPeriodDeadlineStart || "",
        coveredPeriodDeadlineEnd: data?.coveredPeriodDeadlineEnd || "",
      };
    },
    // 获取Generate回显
    getGenerateData() {
      return new Promise((resolve, reject) => {
        this.$http.get("/admin/budgetFilter/getFilter").then(({ code, data, msg }) => {
          if (code === 200) {
            if (data) {
              this.initGenerateData(data);
              resolve();
            } else {
              reject();
            }
          } else {
            this.$message.error(msg);
            reject();
          }
        });
      });
    },
    // 保存Generate
    saveGenerateData() {
      return new Promise((resolve, reject) => {
        this.$http.post("/admin/budgetFilter/save", this.generateData).then(({ code, msg }) => {
          if (code === 200) {
            resolve();
          } else {
            this.$message.error(msg);
            reject();
          }
        });
      });
    },
    // Generate组件触发
    async updateGenerateData(data) {
      this.generateData = data;
      await this.saveGenerateData();
      this.getDataList(1);
    },
    // 获取总计数据
    getTotalData() {
      return new Promise((resolve, reject) => {
        this.$http.get("/admin/budget/getBudgetAcc").then(({ code, data, msg }) => {
          if (code === 200) {
            this.totalData = data || {};
            // 重置总计数据(备份)
            this.totalBackupsData = { ...this.totalData };
            resolve();
          } else {
            this.$message.error(msg);
            reject();
          }
        });
      });
    },
    // 获取分页数据
    getDataList(current) {
      return new Promise((resolve, reject) => {
        this.dataListLoading = true;
        if (current) {
          this.current = current;
        }
        const params = {
          current: this.current,
          size: this.pageSize,
          "orders[0].asc": false,
          "orders[0].column": "t2.client_code",
          "orders[1].column": "t1.id",
          ...this.generateData,
        };
        this.$http
          .get("/admin/budget/paging", {
            params,
          })
          .then(({ code, data, msg }) => {
            if (code === 200) {
              const resList = data.records;
              this.dataList = resList;
              this.totalCount = data.total;
              // 使用总计数据(备份)更新总计数据
              this.totalData = { ...this.totalBackupsData };
              // 设置当前页小计初始化数据
              this.setSubtotalInitData(resList);
              // 重置保存按钮高亮
              this.focusInputList = [];
              resolve();
            } else {
              this.dataList = [];
              this.totalCount = 0;
              this.$message.error(msg);
              reject();
            }
            this.dataListLoading = false;
          });
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 恢复默认数据
    defaultHttp() {
      return new Promise((resolve, reject) => {
        this.$http.post("/admin/budget/generate", {}).then(({ code, msg }) => {
          if (code === 200) {
            resolve();
          } else {
            this.$message.error(msg);
            reject();
          }
        });
      });
    },
    async defaultHandle() {
      await this.$confirm("This resets all data and restores the default data. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      // 恢复默认数据
      await this.defaultHttp();
      // 重置并保存默认Generate条件
      this.initGenerateData();
      await this.saveGenerateData();
      // 获取合计数据
      await this.getTotalData();
      // 获取当前分页数据
      await this.getDataList(1);

      this.$message.success("success");
    },
    // 更新(之前在表格中输入的数据不变)
    updateRequest() {
      return new Promise((resolve, reject) => {
        this.$http.post("/admin/budget/pull", {}).then(({ code, msg }) => {
          if (code === 200) {
            resolve();
          } else {
            this.$message.error(msg);
            reject();
          }
        });
      });
    },
    async updateHandle() {
      // 保存
      await this.saveRequest();
      // 更新
      await this.updateRequest();
      // 获取合计数据
      await this.getTotalData();
      // 获取当前分页数据
      await this.getDataList(1);

      this.$message.success("success");
    },
    // 导出Excel
    async handleExportExcel() {
      await this.$confirm(`Export excel. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const downloadPath = `${axiosInstance.defaults.baseURL}/admin/budgetXlsx/download`;
      open(downloadPath);
    },
    // 保存当前页数据
    saveRequest() {
      return new Promise((resolve, reject) => {
        this.$http
          .post("/admin/budget/batchSave", {
            dataList: this.dataList,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              resolve();
            } else {
              this.$message.error(msg);
              reject();
            }
          });
      });
    },
    async saveHandle(e) {
      await this.saveRequest();
      // 重置保存按钮高亮
      this.focusInputList = [];
      this.$nextTick(() => {
        e.target.parentNode.blur();
      });
      // 更新总计数据(备份)
      this.totalBackupsData = { ...this.totalLiveUpdateData };

      this.$message.success("success");
    },
    // 启用或禁用
    enableOrDisableRequest(id, status) {
      return new Promise((resolve, reject) => {
        this.$http.post(`/admin/budget/${id}/${status}`, {}).then(({ code, msg }) => {
          if (code === 200) {
            resolve();
          } else {
            this.$message.error(msg);
            reject();
          }
        });
      });
    },
    async disableHandle(row) {
      await this.$confirm('"Disable" will clear the entered data. Continue?', "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      await this.enableOrDisableRequest(row.id, "disable");
      row.status = 0;
      this.$message.success("success");

      this.$nextTick(() => {
        // 更新总计数据(备份)
        this.totalBackupsData = { ...this.totalLiveUpdateData };
      });
    },
    async enableHandle(row) {
      await this.enableOrDisableRequest(row.id, "enable");
      row.status = 1;
      this.$message.success("success");

      this.$nextTick(() => {
        // 更新总计数据(备份)
        this.totalBackupsData = { ...this.totalLiveUpdateData };
      });
    },
    // Turnover (Actual Adj.) - 获得焦点
    actualAdjustTurnoverFocus(row) {
      const customKey = `${row.id}Actual`;
      if (!this.focusInputList.includes(customKey)) {
        this.focusInputList.push(customKey);
      }
    },
    // Turnover (Budget Adj.) - 获得焦点
    budgeAdjustTurnoverFocus(row) {
      const customKey = `${row.id}Budget`;
      if (!this.focusInputList.includes(customKey)) {
        this.focusInputList.push(customKey);
      }
    },
    // Turnover (Actual Adj.) - 手动输入
    actualAdjustTurnoverInput(row) {
      // Turnover (Auto Budget) = (Turnover (actual)+Turnover (Actual adj.)) * days of budget period / days of covered period
      const turnoverSum = accountAdd(row.actualTurnover, row.actualAdjustTurnover);
      const turnoverBudgetMul = multiply(turnoverSum, row.budgetPeriod);
      const turnoverBudgetCoveredDiv = div(turnoverBudgetMul, row.coveredPeriod);
      row.budgetAutoTurnover = turnoverBudgetCoveredDiv === Infinity ? 0 : turnoverBudgetCoveredDiv;

      // Commission (Auto Budget) = Turnover (Auto Budget) under commission required * commission rate(多个渠道商百分比的和)
      const commissionArr = row?.commissionList?.map(item => item.commissionRate);
      const commissionSum = commissionArr && commissionArr.length > 0 ? accountAdd(...commissionArr) : 0;
      row.budgetAutoCommissionTurnover = multiply(row.budgetAutoTurnover, commissionSum);

      // Turnover (Adjusted Budget) = Turnover (Auto Budget) - Commission (Auto Budget) + Turnover (Budget Adj.)
      const turnoverCommissionSub = sub(row.budgetAutoTurnover, row.budgetAutoCommissionTurnover);
      const turnoverCommissionSum = accountAdd(turnoverCommissionSub, row.budgeAdjustTurnover);
      row.budgeAdjustedTurnover = turnoverCommissionSum;
    },
    // Turnover (Budget Adj.) - 手动输入
    budgeAdjustTurnoverInput(row) {
      // Turnover (Adjusted Budget) = Turnover (Auto Budget) - Commission (Auto Budget) + Turnover (Budget Adj.)
      const turnoverCommissionSub = sub(row.budgetAutoTurnover, row.budgetAutoCommissionTurnover);
      const turnoverCommissionSum = accountAdd(turnoverCommissionSub, row.budgeAdjustTurnover);
      row.budgeAdjustedTurnover = turnoverCommissionSum;
    },
    // 计算当前页小计
    subtotalDataCalculate(list) {
      const arr = JSON.parse(JSON.stringify(list));
      const subtotalTogether = {
        actualTransaction: 0,
        actualTurnover: 0,
        actualAdjustTurnover: 0,
        budgetAutoTurnover: 0,
        budgetAutoCommissionTurnover: 0,
        budgeAdjustTurnover: 0,
        budgeAdjustedTurnover: 0,
      };
      arr.forEach(element => {
        if (element.status) {
          subtotalTogether.actualTransaction = accountAdd(subtotalTogether.actualTransaction, element.actualTransaction);
          subtotalTogether.actualTurnover = accountAdd(subtotalTogether.actualTurnover, element.actualTurnover);
          subtotalTogether.actualAdjustTurnover = accountAdd(subtotalTogether.actualAdjustTurnover, element.actualAdjustTurnover);
          subtotalTogether.budgetAutoTurnover = accountAdd(subtotalTogether.budgetAutoTurnover, element.budgetAutoTurnover);
          subtotalTogether.budgetAutoCommissionTurnover = accountAdd(subtotalTogether.budgetAutoCommissionTurnover, element.budgetAutoCommissionTurnover);
          subtotalTogether.budgeAdjustTurnover = accountAdd(subtotalTogether.budgeAdjustTurnover, element.budgeAdjustTurnover);
          subtotalTogether.budgeAdjustedTurnover = accountAdd(subtotalTogether.budgeAdjustedTurnover, element.budgeAdjustedTurnover);
        }
      });
      return subtotalTogether;
    },
    // 设置当前页小计初始数据
    setSubtotalInitData(list) {
      this.subtotalInitData = this.subtotalDataCalculate(list);
    },
    // 重新计算当前页合计数据 = 合计数据 - 当前页小计初始数据 + 当前页小计最新数据
    updateTotalData(totalItemNub, subInitNub, subNewNub) {
      const totalSubInitSub = sub(totalItemNub, subInitNub);
      const subNewSum = accountAdd(totalSubInitSub, subNewNub);
      return subNewSum;
    },
    // 设置加总行
    getSummaries({ columns, data }) {
      if (data.length == 0) return [];
      // 合计数组预设空值
      const newTotalList = [];
      columns.forEach(item => {
        newTotalList.push("");
      });
      // 合计中，生成条件部分
      const generateData = this.generateData;
      newTotalList[0] = this.introducedByFormat(generateData.introducedByOption);
      newTotalList[1] = generateData.clientCodeOption == 1 ? "All" : "Specific Code";
      newTotalList[2] = generateData?.companyNameList?.join(", ");
      newTotalList[3] = `${generateData.coveredPeriodStart || ""} ${generateData.coveredPeriodEnd || ""}`;
      newTotalList[4] = generateData?.serviceTypeOption == 1 ? "All" : "Specific Type";
      // 合计中，数据部分
      // 合计数据
      const totalData = { ...this.totalData };
      // 当前页小计初始数据
      const subtotalInitData = this.subtotalInitData;
      // 当前页小计最新数据
      const newSubtotalData = this.subtotalDataCalculate(data);

      const actualTransactionNub = this.updateTotalData(
        totalData.actualTransaction,
        subtotalInitData.actualTransaction,
        newSubtotalData.actualTransaction,
      );
      newTotalList[5] = this.thousands(actualTransactionNub);

      const actualTurnoverNub = this.updateTotalData(totalData.actualTurnover, subtotalInitData.actualTurnover, newSubtotalData.actualTurnover);
      newTotalList[6] = this.thousands(actualTurnoverNub);

      const actualAdjustTurnoverNub = this.updateTotalData(
        totalData.actualAdjustTurnover,
        subtotalInitData.actualAdjustTurnover,
        newSubtotalData.actualAdjustTurnover,
      );
      newTotalList[7] = this.thousands(actualAdjustTurnoverNub);

      const budgetAutoTurnoverNub = this.updateTotalData(
        totalData.budgetAutoTurnover,
        subtotalInitData.budgetAutoTurnover,
        newSubtotalData.budgetAutoTurnover,
      );
      newTotalList[8] = this.thousands(budgetAutoTurnoverNub);

      const budgetAutoCommissionTurnoverNub = this.updateTotalData(
        totalData.budgetAutoCommissionTurnover,
        subtotalInitData.budgetAutoCommissionTurnover,
        newSubtotalData.budgetAutoCommissionTurnover,
      );
      newTotalList[9] = this.thousands(budgetAutoCommissionTurnoverNub);

      const budgeAdjustTurnoverNub = this.updateTotalData(
        totalData.budgeAdjustTurnover,
        subtotalInitData.budgeAdjustTurnover,
        newSubtotalData.budgeAdjustTurnover,
      );
      newTotalList[10] = this.thousands(budgeAdjustTurnoverNub);

      const budgeAdjustedTurnoverNub = this.updateTotalData(
        totalData.budgeAdjustedTurnover,
        subtotalInitData.budgeAdjustedTurnover,
        newSubtotalData.budgeAdjustedTurnover,
      );
      newTotalList[11] = this.thousands(budgeAdjustedTurnoverNub);

      // 更新总计数据(实时更新)
      this.totalLiveUpdateData = {
        actualTransaction: actualTransactionNub,
        actualTurnover: actualTurnoverNub,
        actualAdjustTurnover: actualAdjustTurnoverNub,
        budgetAutoTurnover: budgetAutoTurnoverNub,
        budgetAutoCommissionTurnover: budgetAutoCommissionTurnoverNub,
        budgeAdjustTurnover: budgeAdjustTurnoverNub,
        budgeAdjustedTurnover: budgeAdjustedTurnoverNub,
      };

      return newTotalList;
    },
  },
  async activated() {
    await this.getGenerateData();
    await this.getTotalData();
    this.getDataList();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.mediumBlue {
  color: $table--font-color-medium-blue;
}

.darkBlue {
  color: $table--font-color-dark-blue;
}

.borderNone {
  ::v-deep .el-input__inner {
    border: 1px solid #ebeef5 !important;
  }
}

.borderBold {
  ::v-deep .el-input__inner {
    border: 1px solid #909399 !important;
  }
}

.tableDomClass {
  ::v-deep .disableRow {
    background-color: #dcdfe6;
    cursor: not-allowed;

    .el-input__inner {
      border: 1px solid #dcdfe6 !important;
      background-color: #dcdfe6 !important;
    }
  }

  ::v-deep .disableRow:hover {
    background-color: #dcdfe6 !important;
  }

  ::v-deep .disableRow:hover td {
    background-color: #dcdfe6 !important;
  }

  ::v-deep .disableRow.hover-row {
    background-color: #dcdfe6 !important;
  }

  ::v-deep .disableRow.hover-row td {
    background-color: #dcdfe6 !important;
  }

  ::v-deep tr:hover {
    background: #ffffff !important;
  }

  ::v-deep tr:hover td {
    background: #ffffff !important;
  }

  ::v-deep .hover-row {
    background: #ffffff !important;
  }

  ::v-deep .hover-row td {
    background: #ffffff !important;
  }

  ::v-deep .el-table__footer-wrapper {
    .el-table__cell {
      .cell {
        display: inline-block;
        /* 这两个在技术上是一样的, 为了兼容了浏览器两个都加上 */
        overflow-wrap: break-word !important;
        word-wrap: break-word !important;

        -ms-word-break: break-all !important;
        /* 这个的使用在web-kit中有些危险，他可能会阶段所有东西 */
        word-break: break-all !important;
        /* Instead use this non-standard one: */
        word-break: break-word !important;

        /* 如果浏览器支持的话增加一个连接符(Blink不支持) */
        hyphens: auto !important;
      }
    }
  }

  // ::v-deep .el-table__footer-wrapper {
  //   .el-table_1_column_7,
  //   .el-table_1_column_8,
  //   .el-table_1_column_9 {
  //     color: $table--font-color-medium-blue;
  //   }

  //   .el-table_1_column_10,
  //   .el-table_1_column_11,
  //   .el-table_1_column_12,
  //   .el-table_1_column_13 {
  //     color: $table--font-color-dark-blue;
  //   }
  // }
}
</style>
