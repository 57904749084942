import router from "@/router";
import { removeHiddenMenus, getFullPath, getAllActiveMenu } from "@/utils/menu";

export default {
  namespaced: true,
  state: {
    // 侧边菜单
    menuList: [],
    // 路由
    dynamicMenuRoutes: [],
    menuActiveName: "",
    headName: null,
    // 主入口标签页
    mainTabs: [],
    mainTabsActiveName: "",
  },
  getters: {
    breadcrumbs: ({ menuList, menuActiveName }) => {
      if (menuList && menuList.length > 1) {
        return getFullPath(menuList, menuActiveName, "menuId");
      }
      return [];
    },
    sidebarMenuList: ({ menuList }) => {
      return removeHiddenMenus(menuList);
    },
    activeMenu: ({ menuList, menuActiveName }) => {
      return getAllActiveMenu(menuList, menuActiveName);
    },
  },
  mutations: {
    updateMenuList(state, list) {
      state.menuList = list;
    },
    updateDynamicMenuRoutes(state, list) {
      state.dynamicMenuRoutes = list;
    },
    updateMeta(state, val) {
      state.headName = val.headName;
    },
    updateMenuActiveName(state, name) {
      state.menuActiveName = name;
    },
    updateMainTabs(state, tabs) {
      state.mainTabs = tabs;
    },
    updateMainTabsActiveName(state, name) {
      state.mainTabsActiveName = name;
    },
    removeTab(state, tabName) {
      state.mainTabs = state.mainTabs.filter(item => item.name !== tabName);
      if (state.mainTabs.length >= 1) {
        // 当前选中tab被删除
        if (tabName === state.mainTabsActiveName) {
          var tab = state.mainTabs[state.mainTabs.length - 1];
          router.push({ name: tab.name, query: tab.query, params: tab.params }, () => {
            state.mainTabsActiveName = tab.name;
          });
        }
      } else {
        state.menuActiveName = "";
        router.push({ name: "home" });
      }
    },
    closeCurrentTab(state) {
      this.commit("menu/removeTab", state.mainTabsActiveName);
    },
  },
};
