var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', [!_vm.noContent ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addOrUpdateHandle();
      }
    }
  }, [_vm._v("New Protocol")]) : _vm._e()], 1), _c('div', {
    staticClass: "el-list protocols-wrapper"
  }, [_c('el-row', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    attrs: {
      "gutter": 30
    }
  }, [_vm.noContent ? _c('el-col', [_c('el-empty', {
    attrs: {
      "description": " "
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addOrUpdateHandle();
      }
    }
  }, [_vm._v("New Protocol")])], 1)], 1) : _vm._e(), !_vm.noContent ? _vm._l(_vm.dataList, function (item) {
    return _c('el-col', {
      key: item.id,
      attrs: {
        "span": 8
      }
    }, [_c('el-card', {
      staticClass: "mt26 relative",
      attrs: {
        "shadow": "nerver",
        "body-style": {
          padding: '0px'
        }
      }
    }, [_c('div', {
      staticClass: "module-item"
    }, [_c('div', {
      staticClass: "module-desc"
    }, [_c('p', {
      staticClass: "item-wrap"
    }, [_c('span', {
      staticClass: "item-label"
    }, [_vm._v("Type:")]), _c('span', {
      staticClass: "item-content"
    }, [_vm._v(_vm._s(_vm.protocolTypeFormat(item.type)))])]), _c('p', {
      staticClass: "item-wrap"
    }, [_c('span', {
      staticClass: "item-label"
    }, [_vm._v("Update:")]), _c('span', {
      staticClass: "item-content"
    }, [_vm._v(_vm._s(item.updateTime))])])])]), item.type == 'NOMINEE_DIRECTOR_AGREEMENT' || item.type == 'SERVICE_AGREEMENT' || item.type == 'ENGAGEMENT_LETTER' ? _c('ul', {
      staticClass: "el-card-actions"
    }, [_c('li', {
      staticStyle: {
        "width": "100%"
      },
      on: {
        "click": function ($event) {
          return _vm.addOrUpdateHandle(item.id);
        }
      }
    }, [_c('svg-icon', {
      staticClass: "fs16",
      attrs: {
        "name": "edit"
      }
    })], 1)]) : _c('ul', {
      staticClass: "el-card-actions"
    }, [_c('li', {
      staticStyle: {
        "width": "50%"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteHandle(item.id);
        }
      }
    }, [_c('svg-icon', {
      staticClass: "fs16",
      attrs: {
        "name": "delete"
      }
    })], 1), _c('li', {
      staticStyle: {
        "width": "50%"
      },
      on: {
        "click": function ($event) {
          return _vm.addOrUpdateHandle(item.id);
        }
      }
    }, [_c('svg-icon', {
      staticClass: "fs16",
      attrs: {
        "name": "edit"
      }
    })], 1)])])], 1);
  }) : _vm._e()], 2)], 1), _vm.addOrUpdateVisible ? _c('add-or-update', {
    ref: "addOrUpdate",
    attrs: {
      "protocolTypes": _vm.protocolTypes
    },
    on: {
      "refreshDataList": _vm.getDataList
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }