import { Decimal } from "decimal.js";

/**
 * 获取uuid
 */
export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(16);
  });
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = "id", pid = "parentId") {
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]["children"]) {
        temp[data[k][pid]]["children"] = [];
      }
      if (!temp[data[k][pid]]["_level"]) {
        temp[data[k][pid]]["_level"] = 1;
      }
      data[k]["_level"] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]]["children"].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }
  return res;
}

/**
 * date format
 * @param {*} date
 * @param {*} fmt
 */
export function dateFormat(date, fmt = "yyyy-MM-dd hh:mm:ss") {
  //author: meizz
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
  return fmt;
}

/**
 * 倒计时（比如验证码）
 * @param {*} seconds
 */
export function countDown(seconds) {
  let sec = seconds;
  return cb => {
    // 先触发一遍
    cb(sec);
    // 再执行递归
    recursiveFoo(sec);

    function recursiveFoo(sec) {
      setTimeout(() => {
        cb(sec);
        sec--;
        if (sec >= 0) {
          recursiveFoo(sec);
        }
      }, 1000);
    }
  };
}

/**
 * 将图片转换为 base64
 * @param {String} url
 * @param {*} callback
 * @param {String} outputFormat
 */
export function convertImgToBase64(url, callback, outputFormat) {
  let canvas = document.createElement("CANVAS");
  let ctx = canvas.getContext("2d");
  let img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    let dataURL = canvas.toDataURL(outputFormat || "image/png");
    callback.call(this, dataURL);
    // Clean up
    canvas = null;
  };
  img.src = url;
}

/**
 * 打开新页面
 * @param {String} url 地址
 */
export function open(url) {
  var a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.setAttribute("id", "a-link-temp");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(document.getElementById("a-link-temp"));
}

/**
 * 计算字节大小
 * @param {String} a 原字节数, b 保存小数位
 */
export function formatBytes(a, b = 2) {
  if (0 == a) return "0 Bytes";
  var c = 1024,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(b)) + " " + e[f];
}

// 千分位, 价格补.00; s 为数字, n 为保留几个小数点
export function fmoney(s, n) {
  n = n >= 0 && n <= 20 ? n : 2;
  s = toFixedDecimal(parseFloat((s + "").replace(/[^\d.-]/g, "")), n) + "";
  let l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  r = r == null ? "" : "." + r;
  let t = "";
  if (l[l.length - 1] === "-") {
    //负数不需要分隔号,
    for (let i = 0; i < l.length; i++) {
      if (l[i] === "-") {
        t += l[i] + "";
        continue;
      }
      //不是数组的倒数第二个元素才加"," ["0", "4", "5", "-"]
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length - 1 ? "," : "");
      //i + 1 != l.length会变成-,540.00,因为在5时元素位置2+1为3非数组长度
      //t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
    }
  } else {
    for (let i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
    }
  }
  return t.split("").reverse().join("") + r;
}

// 表单验证快速定位
export function validateLocation(object, that) {
  for (let i in object) {
    let dom = that.$refs[i];
    //这里是针对遍历的情况（多个输入框），取值为数组
    if (Object.prototype.toString.call(dom) !== "[object Object]") {
      dom = dom[0];
    }
    //第一种方法（包含动画效果）
    dom.$el.scrollIntoView({
      //滚动到指定节点
      block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
      behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
    });
    break;
  }
}

// 加法
export function accountAdd(...args) {
  let result = new Decimal(args[0] || 0);
  for (let i = 1; i < args.length; i++) {
    result = result.add(new Decimal(args[i] || 0));
  }
  return result.toNumber();
}

// 减法
export function sub(...args) {
  let result = new Decimal(args[0] || 0);
  for (let i = 1; i < args.length; i++) {
    result = result.sub(new Decimal(args[i] || 0));
  }
  return result.toNumber();
}

// 乘法
export function multiply(...args) {
  let result = new Decimal(args[0] || 0);
  for (let i = 1; i < args.length; i++) {
    result = result.mul(new Decimal(args[i] || 0));
  }
  return result.toNumber();
}

// 除法
export function div(...args) {
  let result = new Decimal(args[0] || 0);
  for (let i = 1; i < args.length; i++) {
    result = result.div(new Decimal(args[i] || 0));
  }
  return result.toNumber();
}

// 解决四舍五入小数toFixed精度丢失
// val 代表值; places 需要精确到几位，默认是2位
export function toFixedDecimal(val, places = 2) {
  const valNew = new Decimal(val || 0);
  const valRes = valNew.toFixed(places);
  return valRes;
}

// 获取当前时间的前三个月的时间
export function get3MonthBefor() {
  let resultDate, year, month, date;
  let currDate = new Date();
  year = currDate.getFullYear();
  month = currDate.getMonth() + 1;
  date = currDate.getDate();
  switch (month) {
    case 1:
    case 2:
    case 3:
      month += 9;
      year--;
      break;
    default:
      month -= 3;
      break;
  }
  month = month < 10 ? "0" + month : month;
  date = date < 10 ? "0" + date : date;
  resultDate = year + "-" + month + "-" + date;
  return resultDate;
}

// 获取当前日期
export function getNowFormatDate() {
  const date = new Date();
  const seperator = "-";
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  const currentdate = date.getFullYear() + seperator + month + seperator + strDate;
  return currentdate;
}
