<template>
  <el-dialog title="Please tick before submission:" :close-on-click-modal="false" :visible.sync="visible" :before-close="beforeClose">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-position="top">
      <div v-for="(item, idx) in dataForm.qaList" :key="item.title">
        <el-form-item
          :label="item.title"
          :prop="`qaList.${idx}.value`"
          :ref="`qaList.${idx}.value`"
          :rules="{
            required: true,
            message: 'please select',
            trigger: 'submit',
          }"
        >
          <el-radio-group v-model="dataForm.qaList[idx].value">
            <el-radio :label="child.value" v-for="child in completedCheckOptions" :key="`${item.param}${child.value}`">{{ child.title }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="Remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="please enter"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="beforeClose">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {},
    };
  },
  computed: {
    ...mapState("constant", ["completedCheckOptions"]),
  },
  methods: {
    async init(idx) {
      this.dataForm = {
        idx,
        qaList: [
          {
            title: "Q1.Work completed?",
            value: "",
          },
          {
            title: "Q2.Deliverables delivered to the client?",
            value: "",
          },
          {
            title: "Q3.Gov filing completed?",
            value: "",
          },
          {
            title: "Q4.Related doc are all uploaded to the sever?",
            value: "",
          },
        ],
        remark: null,
      };
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单关闭前
    beforeClose() {
      this.$emit("completedCheckFeedback", this.dataForm, "close");
      this.visible = false;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          this.$emit("completedCheckFeedback", this.dataForm, "submit");
          this.visible = false;
        }
      });
    },
  },
};
</script>
