import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "./plugins/element.js";
import "./assets/css/common.css";
import "./assets/scss/index.scss";

import "./plugins/cookie.js";
import "./plugins/http.js";
import "./plugins/auth.js";
import "./plugins/svg.js";
import "./plugins/event.js";
import "./plugins/editor.js";
import "./plugins/uploader.js";
import "./plugins/affix.js";
import "./plugins/form.js";
import "./plugins/enter.js";
import "./plugins/echarts.js";

import "@/directive/inputLimit";

import moment from 'moment';
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

export default new Vue({
  store,
  router,
  render: h => h(App),
}).$mount("#app");
