import Vue from "vue";
import VueStore from "@/store";

export function isAuth(key) {
  const { permissionList } = VueStore.getters["authority/activeRoleAuthority"];
  return permissionList.indexOf(key) !== -1 || false;
}

Vue.prototype.isAuth = isAuth;

//针对某个申请单鉴权，除了校验权限，还需校验关系
export function isApplymentAuth(key) {
  const applymentOwnersip = VueStore.getters["applyment/getOwnershipList"];
  let km = {};
  if (key.includes("company")) {
    km = {
      "company:coordinate": applymentOwnersip.coordinator,
      "company:check": applymentOwnersip.checker,
      "company:audit": applymentOwnersip.auditor,
    };
  } else {
    km = {
      "quotation:coordinate": applymentOwnersip.coordinator,
      "quotation:check": applymentOwnersip.checker,
      "quotation:audit": applymentOwnersip.auditor,
    };
  }
  const applymentUserid = km[key]?.id;
  const currUserId = VueStore.state.user.id;
  return isAuth(key) && currUserId === applymentUserid;
}

Vue.prototype.isApplymentAuth = isApplymentAuth;
