<template>
  <el-dialog width="840px" class="module-selector" title="Select Service" :visible.sync="visible">
    <template v-if="modules && modules.length > 0">
      <div class="module-selector-box">
        <module-item v-for="(module, index) in modules" :key="module.id" selectable v-model="modules[index]" />
      </div>
      <div class="text-center mt20 mb15">
        <el-button type="primary" @click="finishHandler()">OK</el-button>
      </div>
    </template>
    <el-empty v-else description=" "></el-empty>
  </el-dialog>
</template>

<script>
import ModuleItem from "./module-item";
export default {
  components: { ModuleItem },
  props: {
    moduleList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      modules: this.moduleList,
    };
  },
  watch: {
    moduleList(newValue) {
      this.modules = newValue;
    },
  },
  methods: {
    init() {
      this.visible = true;
    },
    finishHandler() {
      this.$emit(
        "selectFinish",
        this.modules.filter(item => item.checked),
      );
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.module-selector-box {
  width: 790px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & .pl-module-item {
    width: 380px;
    cursor: pointer;
  }
}
</style>
