// 只能输入正整数(包括0)
export default {
  bind(el, binding, vnode) {
    el.keyupHandler = event => {
      const currentValue = event.target.value;
      let newValue = "";

      // 移除非数字字符和多余的0
      for (let i = 0; i < currentValue.length; i++) {
        if (currentValue[i].match(/[0-9]/)) {
          newValue += currentValue[i];
        }
      }

      // 如果第一位数字为0，就用第二位数字替换第一位的0
      const len1 = newValue.substr(0, 1);
      if (newValue.length > 1 && len1 == 0) {
        newValue = newValue.substr(1, 1);
      }

      event.target.value = newValue;
      vnode.context.inputValue = newValue;

      // 更新v-model
      if (vnode.componentInstance) {
        // 如果是自定义组件就触发自定义组件的input事件
        vnode.componentInstance.$emit("input", event.target.value);
      } else {
        // 如果是原生组件就触发原生组件的input事件
        el.dispatchEvent(new Event("input"));
      }
    };

    // 添加监听键盘输入
    el.addEventListener("keyup", el.keyupHandler);
  },
  unbind(el) {
    // 解除键盘监听
    el.removeEventListener("keyup", el.keyupHandler);
  }
};
