<template>
  <div class="flex align-center justify-center login-wrap">
    <div class="flex align-center justify-center firm-bg-img">
      <router-link to="/home">
        <img src="@/assets/img/logo.png" alt="" class="logo" />
      </router-link>
      <img src="@/assets/img/firm.png" alt="" />
    </div>
    <div class="flex align-center justify-center ms-login">
      <div class="from-wrap">
        <div class="ms-title">
          <span class="black45 fwb">Reset password</span>
        </div>
        <el-form :model="accountForm" :rules="accountRule" ref="accountFormRef" @submit.native="accountFormSubmit" hide-required-asterisk>
          <el-form-item label="" prop="password">
            <el-input v-model="accountForm.password" type="password" auto-complete="new-password" placeholder="Password" show-password></el-input>
          </el-form-item>
          <el-form-item label="" prop="comfirmPassword" style="margin-bottom: 50px">
            <el-input v-model="accountForm.comfirmPassword" type="password" placeholder="Repeat password" show-password></el-input>
          </el-form-item>
          <el-button :disabled="accountBtnDisabled" @click="accountFormSubmit" class="login-btn" native-type="submit" type="primary">Submit</el-button>
        </el-form>
      </div>
    </div>
    <div class="footBox">
      <p class="fs12">
        Copyright© 2021 星中(广州)商务咨询有限公司
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021165700号</a>
      </p>
    </div>
  </div>
</template>
<script>
import { clearLoginInfo } from "@/utils/auth";
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!/\S/.test(value)) {
        callback(new Error("Please provide your password"));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (!/\S/.test(value)) {
        callback(new Error("Please confirm your password"));
      } else if (this.accountForm.password !== value) {
        callback(new Error("Passwords do not match"));
      } else {
        callback();
      }
    };
    return {
      codeNo: null,
      accountForm: {
        password: "",
        comfirmPassword: "",
      },
      accountRule: {
        password: [{ validator: validatePassword, trigger: "blur" }],
        comfirmPassword: [{ validator: validateComfirmPassword, trigger: "blur" }],
      },
      accountBtnDisabled: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.codeNo = vm.$route.query.code || null;
      if (!vm.codeNo) {
        vm.$router.push({ name: "home" });
      }
    });
  },
  methods: {
    accountFormSubmit(e) {
      e.preventDefault();
      this.$refs["accountFormRef"].validate(async valid => {
        if (valid) {
          this.accountBtnDisabled = true;
          let { code, msg } = await this.$http.post(`/finishSysResetPwd?code=${this.codeNo}`, { newPwd: this.accountForm.password });
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                clearLoginInfo();
                this.$router.push({ name: "login" });
              },
            });
          } else {
            this.$notify({
              title: "Login Fail",
              message: msg,
              type: "error",
              position: "top-right",
              offset: 100,
            });
            this.accountBtnDisabled = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  min-height: 800px;
  background: #f1f5fc;
  position: relative;

  .firm-bg-img {
    width: 60%;
    height: 100%;
    background: #f1f5fc;
    position: relative;

    .logo {
      position: absolute;
      top: 26px;
      left: 30px;
      z-index: 100;
      height: 50px;
    }
  }

  .ms-login {
    width: 40%;
    height: 100%;
    background: #fafcff;
    box-shadow: 0px 21px 40px 0px rgba(208, 229, 255, 0.26);
    .from-wrap {
      width: 430px;
      margin: -60px auto 0px;
      .ms-title {
        width: 100%;
        text-align: center;
        padding-bottom: 60px;
        font-size: 22px;
      }
    }
    ::v-deep .el-input__inner {
      background: #ffffff;
      border-color: #e1ecff;
      border-radius: 6px;
      height: 50px;
      line-height: 50px;
    }
  }

  .login-btn {
    text-align: center;
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
  }

  .footBox {
    position: absolute;
    bottom: 14px;
    left: 0;
    width: 60%;
    text-align: center;
    line-height: 0em;
    color: #667288;
    a {
      color: #667288;
    }
  }
}
</style>
