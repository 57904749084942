var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Edit Company Info",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-position": "top"
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "lg": 16,
      "xl": 13
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Company Type",
      "prop": "companyType"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "clearable": "",
      "placeholder": "company type"
    },
    on: {
      "change": _vm.typeChange
    },
    model: {
      value: _vm.dataForm.companyType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "companyType", $$v);
      },
      expression: "dataForm.companyType"
    }
  }, _vm._l(_vm.companyTypeList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 18,
      "xl": 15
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Company Name (Option 1)",
      "prop": "proposedCompanyName1"
    }
  }, [_c('el-input', {
    staticClass: "input-with-select-lg",
    attrs: {
      "placeholder": "Proposed Company Name (Option 1)",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.proposedCompanyName1,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "proposedCompanyName1", $$v);
      },
      expression: "dataForm.proposedCompanyName1"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "append"
    },
    slot: "append",
    model: {
      value: _vm.dataForm.proposedCompanyType1,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "proposedCompanyType1", $$v);
      },
      expression: "dataForm.proposedCompanyType1"
    }
  }, _vm._l(_vm.companyTypeSuffixList[_vm.dataForm.companyType], function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 18,
      "xl": 15
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Company Name (Option 2)",
      "prop": "proposedCompanyName2"
    }
  }, [_c('el-input', {
    staticClass: "input-with-select-lg",
    attrs: {
      "placeholder": "Proposed Company Name (Option 2)",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.proposedCompanyName2,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "proposedCompanyName2", $$v);
      },
      expression: "dataForm.proposedCompanyName2"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "append"
    },
    slot: "append",
    model: {
      value: _vm.dataForm.proposedCompanyType2,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "proposedCompanyType2", $$v);
      },
      expression: "dataForm.proposedCompanyType2"
    }
  }, _vm._l(_vm.companyTypeSuffixList[_vm.dataForm.companyType], function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 18,
      "xl": 15
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Company Name (Option 3)",
      "prop": "proposedCompanyName3"
    }
  }, [_c('el-input', {
    staticClass: "input-with-select-lg",
    attrs: {
      "placeholder": "Proposed Company Name (Option 3)",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.proposedCompanyName3,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "proposedCompanyName3", $$v);
      },
      expression: "dataForm.proposedCompanyName3"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "append"
    },
    slot: "append",
    model: {
      value: _vm.dataForm.proposedCompanyType3,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "proposedCompanyType3", $$v);
      },
      expression: "dataForm.proposedCompanyType3"
    }
  }, _vm._l(_vm.companyTypeSuffixList[_vm.dataForm.companyType], function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 16,
      "xl": 13
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Primary Activity",
      "prop": "primaryActivity"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "clearable": "",
      "placeholder": "primary activity",
      "popper-class": "tradeWrap",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.primaryActivity,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "primaryActivity", $$v);
      },
      expression: "dataForm.primaryActivity"
    }
  }, _vm._l(_vm.activityList, function (item) {
    return _c('el-option', {
      key: 'Primary' + item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Primary User-Described Activity Description",
      "prop": "primaryActivityDescription"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "primary activity descption",
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "maxlength": "78",
      "show-word-limit": "",
      "resize": "none",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.primaryActivityDescription,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "primaryActivityDescription", $$v);
      },
      expression: "dataForm.primaryActivityDescription"
    }
  })], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 16,
      "xl": 13
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Secondary Activity",
      "prop": "secondaryActivity"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "clearable": "",
      "placeholder": "secondary activity",
      "popper-class": "tradeWrap",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.secondaryActivity,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "secondaryActivity", $$v);
      },
      expression: "dataForm.secondaryActivity"
    }
  }, _vm._l(_vm.activityList, function (item) {
    return _c('el-option', {
      key: 'Secondary' + item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Secondary User-Described Activity Description",
      "prop": "secondaryActivityDescription"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "secondary activity descption",
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "maxlength": "78",
      "show-word-limit": "",
      "resize": "none",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.secondaryActivityDescription,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "secondaryActivityDescription", $$v);
      },
      expression: "dataForm.secondaryActivityDescription"
    }
  })], 1), _vm.isNeedNomineeDirector ? _c('el-form-item', {
    attrs: {
      "label": "Nominee Director Service",
      "prop": "hasNomineeDirector"
    }
  }, [_c('el-checkbox', {
    staticClass: "checkboxCustom",
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.dataForm.hasNomineeDirector,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "hasNomineeDirector", $$v);
      },
      expression: "dataForm.hasNomineeDirector"
    }
  }, [_vm._v(" We consent to appoint one Nominee Director recommended by EStar Business Services Pte. Ltd. (\"EStar\"), and we understand that any legal impact caused by the Nominee Director's action has nothing to do with EStar. ")])], 1) : _vm._e()], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }