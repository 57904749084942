<template>
  <div class="page-container">
    <div class="mod-menu">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <el-button v-if="isAuth('sys:menu:save')" type="primary" @click="addOrUpdateHandle()">Add Menu</el-button>
        </el-form-item>
      </el-form>

      <el-card shadow="nerver">
        <el-table :data="dataList" row-key="id" style="width: 100%">
          <el-table-column prop="name" header-align="center" min-width="150" label="Name"></el-table-column>
          <el-table-column prop="parentName" header-align="center" align="center" width="120" label="Parent"></el-table-column>
          <el-table-column header-align="center" align="center" label="Icon">
            <template slot-scope="scope">
              <i :class="scope.row.icon"></i>
              <i class="el-icon">
                <svg-icon :name="scope.row.icon || ''"></svg-icon>
              </i>
            </template>
          </el-table-column>
          <el-table-column prop="type" header-align="center" align="center" label="Type">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type === 0" size="small">CATALOG</el-tag>
              <el-tag v-else-if="scope.row.type === 1" size="small" type="success">MENU</el-tag>
              <el-tag v-else-if="scope.row.type === 2" size="small" type="info">BUTTON</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="orderNum" header-align="center" align="center" label="Sort"></el-table-column>
          <el-table-column
            prop="url"
            header-align="center"
            align="center"
            min-width="150"
            :show-overflow-tooltip="true"
            label="Menu URL"
          ></el-table-column>
          <el-table-column
            prop="perms"
            header-align="center"
            align="center"
            width="150"
            :show-overflow-tooltip="true"
            label="Permissons"
          ></el-table-column>
          <el-table-column prop="hidden" header-align="center" align="center" width="100" :formatter="hiddenFormat" label="Hidden"></el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" width="180" label="Action">
            <template slot-scope="scope">
              <el-button v-if="isAuth('sys:menu:update')" type="text" class="fwb" @click="addOrUpdateHandle(scope.row.id)">Edit</el-button>
              <el-button v-if="isAuth('sys:menu:delete')" type="text" class="fwb" @click="deleteHandle(scope.row.id)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
  </div>
</template>

<script>
import AddOrUpdate from "./AddOrUpdate";
import { treeDataTranslate } from "@/utils";
export default {
  meta: {},
  data() {
    return {
      dataForm: {},
      dataList: [],
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    /* 格式化 */
    hiddenFormat(row, column, cellValue) {
      return cellValue === 1 ? "Yes" : "";
    },
    /* 格式化 */
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      const { data } = await this.$http.get("/admin/sysMenu/getList");
      this.dataList = treeDataTranslate(data, "id");
      this.dataListLoading = false;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    async deleteHandle(id) {
      await this.$confirm(`Delete this menu. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const { code, msg } = await this.$http.post(`/admin/sysMenu/${id}/delete`);
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => this.getDataList(),
        });
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>
