<template>
  <div class="risk-assessment-wrap">
    <p class="risk-assessment-title">Risk Assessment of Customer/Client</p>
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-position="top">
      <div v-for="(item, index) in riskList" :key="'rating' + index" class="mb80">
        <p v-html="item.title" class="ratingTitle"></p>
        <p v-html="item.brief" class="ratingBrief"></p>
        <!-- 问题 -->
        <el-table border :data="item.list" header-cell-class-name="table-header" class="risk-table" v-if="item.type == 'table'">
          <el-table-column label="Question" header-align="center" align="left">
            <template slot-scope="scope">
              <p v-html="scope.row.title"></p>
            </template>
          </el-table-column>
          <el-table-column label="Operation" align="center" width="334px">
            <template slot-scope="scope">
              <el-form-item
                :prop="scope.row.model"
                :ref="scope.row.model"
                :rules="{
                  required: true,
                  message: 'Please select',
                  trigger: 'submit',
                }"
              >
                <el-radio-group v-model="dataForm[scope.row.model]" v-if="scope.row.type == 'radio'">
                  <el-radio v-for="(son, idx) in scope.row.options" :key="item.title + idx" :label="son.value">{{ son.title }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <!-- 最终定级 -->
        <div v-if="item.type == 'result'">
          <div v-for="alone in item.list" :key="alone.title">
            <p v-html="alone.title" class="resultTitle"></p>
            <el-form-item
              :prop="alone.model"
              :ref="alone.model"
              :rules="{
                required: true,
                message: 'Please select',
                trigger: 'submit',
              }"
              v-if="alone.type == 'radio'"
            >
              <el-radio-group v-model="dataForm[alone.model]">
                <el-radio v-for="(son, idx) in alone.options" :key="item.title + idx" :label="son.value">{{ son.title }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :prop="alone.model"
              :ref="alone.model"
              :rules="{
                required: true,
                message: 'Please enter',
                trigger: 'submit',
              }"
              v-if="alone.type == 'textarea'"
            >
              <el-input type="textarea" :rows="4" v-model="dataForm[alone.model]" :oninput="isEn"></el-input>
            </el-form-item>
            <el-form-item v-if="alone.model === 'cause'">
              <p class="resultTitle">attachments 附件</p>
              <el-multiple-file-uploader v-model="attachmentUrls">
                <el-button size="medium">upload 上传</el-button>
              </el-multiple-file-uploader>
            </el-form-item>
          </div>
        </div>
        <p v-html="item.hint" class="ratingHint"></p>
        <p v-for="subset in item.replenish" :key="subset" v-html="subset" class="ratingReplenish"></p>
      </div>
    </el-form>
    <span slot="footer" class="flex align-center justify-center dialog-footer">
      <el-button @click="dataFormSave" class="mr12">Save</el-button>
      <el-button type="primary" @click="dataFormSubmit">Submit</el-button>
    </span>
  </div>
</template>

<script>
import { validateLocation } from "@/utils";
import RiskJson from "./risk.json";
import { mapState, mapActions } from "vuex";
export default {
  layout: "blank",
  data() {
    return {
      riskList: RiskJson,
      applymentId: "",
      dataForm: {},
      attachmentUrls: [],
    };
  },
  computed: {
    ...mapState("constant", ["nationalityList"]),
  },
  created() {
    this.applymentId = this.$route.params.id;
    this.getDetailHttp();
  },
  methods: {
    ...mapActions("applyment", ["doSaveRevising"]),
    // 获取风险评估明细
    getDetailHttp() {
      this.$http
        .get("/admin/companyApplymentRiskAssessment/detail", {
          params: {
            applymentId: this.applymentId,
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.dataForm = data && data.content ? JSON.parse(JSON.stringify(data.content)) : {};
            this.attachmentUrls = data && data.attachmentUrls ? JSON.parse(JSON.stringify(data.attachmentUrls)) : [];
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 提交或保存接口
    riskAssessmentHttp(isFinishedType) {
      this.$http
        .post("/admin/companyApplymentRiskAssessment/save", {
          applymentId: this.applymentId,
          content: this.dataForm,
          attachmentUrls: this.attachmentUrls,
          isFinished: isFinishedType,
        })
        .then(({ code, msg }) => {
          if (code === 200) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 提交风险评估表
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid, object) => {
        if (valid) {
          if (this.attachmentUrls.length === 0) {
            this.$message.warning("Please upload attachments");
            return false;
          }
          this.riskAssessmentHttp(1);
        } else {
          validateLocation(object, this);
          return false;
        }
      });
    },
    // 保存风险评估表
    dataFormSave() {
      this.riskAssessmentHttp(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.risk-assessment-wrap {
  width: 1100px;
  margin: 0px auto;
  padding: 20px 0px 60px;

  .risk-assessment-title {
    width: 100%;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .ratingTitle {
    font-size: 18px;
    font-weight: bold;
  }

  .ratingBrief {
    font-size: 14px;
  }

  .ratingHint {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
  }

  .ratingReplenish {
    line-height: 18px;
    margin: 0 0 8px 0;
    font-size: 12px;
  }

  .resultTitle {
    line-height: 18px;
    margin: 0 0 10px 0;
    font-weight: bold;
  }

  .risk-table {
    ::v-deep .el-form-item__error {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
