<template>
  <el-dialog
    :title="!dataForm.id ? 'Add Beneficial Owner' : 'Edit Beneficial Owner'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="1100px"
    append-to-body
  >
    <el-form :model="dataForm" ref="dataForm" label-position="top">
      <el-tabs v-model="dataForm.memberType" stretch>
        <el-tab-pane label="Company" name="CORPORATE">
          <template v-if="dataForm.memberType == 'CORPORATE'">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Name (same as business certificate)"
                  prop="fullName"
                  :rules="{ required: true, message: 'Please enter', trigger: 'submit' }"
                >
                  <el-input v-model="dataForm.fullName" placeholder="Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Country" prop="country" :rules="{ required: true, message: 'Please select', trigger: 'submit' }">
                  <el-select style="width: 100%" v-model="dataForm.country" placeholder="Select Country">
                    <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Business No." prop="businessLicenseNo" :rules="{ required: true, message: 'Please enter', trigger: 'submit' }">
                  <el-input v-model="dataForm.businessLicenseNo" placeholder="Business No." :oninput="isEn"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Mobile"
                  prop="phone.code"
                  :rules="[
                    {
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please enter',
                      trigger: 'submit',
                    },
                    { validator: validatePhone, trigger: 'submit' },
                  ]"
                >
                  <el-input v-model="dataForm.phone.code" class="input-with-select-xs" oninput="value=value.replace(/[^\d.]/g, '')">
                    <el-select v-model="dataForm.phone.prefix" slot="prepend">
                      <el-option v-for="single in prefixOptions" :key="single.title" :value="single.value">
                        {{ single.value + " " + single.title }}
                      </el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Email"
                  prop="email"
                  :rules="[
                    {
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please enter',
                      trigger: 'submit',
                    },
                    { type: 'email', message: 'Please provide a valid email address', trigger: 'submit' },
                  ]"
                >
                  <el-input v-model="dataForm.email" placeholder="Email"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Registered Address"
                  prop="registeredOfficeAddress"
                  :rules="{ required: true, message: 'Please enter', trigger: 'submit' }"
                >
                  <el-input v-model="dataForm.registeredOfficeAddress" placeholder="Company Registered Address"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Operation Address"
                  prop="operationAddress"
                  :rules="{
                    required:
                      businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' || businessType == 'COMPANY_INCORPORATION'
                        ? false
                        : true,
                    message: 'Please enter',
                    trigger: 'submit',
                  }"
                >
                  <el-input v-model="dataForm.operationAddress" placeholder="Company Operation Address"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Incorporation Date"
                  prop="incorporationDate"
                  :rules="{
                    required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                    message: 'Please select',
                    trigger: 'submit',
                  }"
                  class="fluid-width"
                >
                  <el-date-picker
                    v-model="dataForm.incorporationDate"
                    type="date"
                    placeholder="Incorporation Date"
                    value-format="yyyy-MM-dd"
                    class="fluid-width"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- representative (主体代表) -->
            <div v-if="dataForm.representativeInfo">
              <p class="representativeWrap">Representative</p>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="Name (same as IC)"
                    prop="representativeInfo.fullName"
                    :rules="{
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please enter',
                      trigger: 'submit',
                    }"
                  >
                    <el-input v-model="dataForm.representativeInfo.fullName" placeholder="Name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Nationality"
                    prop="representativeInfo.nationality"
                    :rules="{
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-select style="width: 100%" v-model="dataForm.representativeInfo.nationality" placeholder="Select Nationality" filterable>
                      <el-option
                        v-for="item in nationalityList"
                        :key="`representativeInfoNationality${item.value}`"
                        :label="item.title"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="Identification Type"
                    prop="representativeInfo.identificationType"
                    :rules="{
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-select style="width: 100%" v-model="dataForm.representativeInfo.identificationType" placeholder="Select identificationType">
                      <el-option
                        v-for="item in identificationTypes"
                        :key="`representativeInfoIdCardType${item.value}`"
                        :label="item.title"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Identification No."
                    prop="representativeInfo.identificationNo"
                    :rules="{
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please enter',
                      trigger: 'submit',
                    }"
                  >
                    <el-input v-model="dataForm.representativeInfo.identificationNo" placeholder="Identification No." :oninput="isEn"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="Mobile No."
                    prop="representativeInfo.phone.code"
                    :rules="[
                      {
                        required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                        message: 'Please enter',
                        trigger: 'submit',
                      },
                      { validator: validateRepresentativePhone, trigger: 'submit' },
                    ]"
                  >
                    <el-input v-model="dataForm.representativeInfo.phone.code" class="input-with-select-xs" oninput="value=value.replace(/[^\d.]/g, '')">
                      <el-select v-model="dataForm.representativeInfo.phone.prefix" slot="prepend">
                        <el-option v-for="single in prefixOptions" :key="`representativeInfoPrefix${single.title}`" :value="single.value">
                          {{ single.value + " " + single.title }}
                        </el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Email"
                    prop="representativeInfo.email"
                    :rules="[
                      {
                        required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                        message: 'Please enter',
                        trigger: 'submit',
                      },
                      { type: 'email', message: 'Please provide a valid email address', trigger: 'submit' },
                    ]"
                  >
                    <el-input v-model="dataForm.representativeInfo.email" placeholder="Email"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="Residential address"
                    prop="representativeInfo.residentialAddress"
                    :rules="{
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please enter',
                      trigger: 'submit',
                    }"
                  >
                    <el-input v-model="dataForm.representativeInfo.residentialAddress" placeholder="Residential address"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Birth Date"
                    prop="representativeInfo.birthDate"
                    :rules="[
                      {
                        required: false,
                        message: 'Please select',
                        trigger: 'submit',
                      },
                      { validator: checkAge, trigger: 'submit' },
                    ]"
                    class="fluid-width"
                  >
                    <el-date-picker
                      v-model="dataForm.representativeInfo.birthDate"
                      type="date"
                      placeholder="Birth Date"
                      value-format="yyyy-MM-dd"
                      class="fluid-width"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane label="Individual" name="INDIVIDUAL">
          <template v-if="dataForm.memberType == 'INDIVIDUAL'">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Name (same as IC)" prop="fullName" :rules="{ required: true, message: 'Please enter', trigger: 'submit' }">
                  <el-input v-model="dataForm.fullName" placeholder="Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Nationality" prop="nationality" :rules="{ required: true, message: 'Please select', trigger: 'submit' }">
                  <el-select style="width: 100%" v-model="dataForm.nationality" placeholder="Select Nationality">
                    <el-option v-for="item in nationalityList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Identification Type"
                  prop="identificationType"
                  :rules="{ required: true, message: 'Please select', trigger: 'submit' }"
                >
                  <el-select style="width: 100%" v-model="dataForm.identificationType" placeholder="Select identificationType">
                    <el-option v-for="item in identificationTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Identification No." prop="identificationNo" :rules="{ required: true, message: 'Please enter', trigger: 'submit' }">
                  <el-input v-model="dataForm.identificationNo" placeholder="Identification No." :oninput="isEn"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Mobile"
                  prop="phone.code"
                  :rules="[
                    {
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please enter',
                      trigger: 'submit',
                    },
                    { validator: validatePhone, trigger: 'submit' },
                  ]"
                >
                  <!-- <el-input v-model="dataForm.phone" placeholder="Mobile" :oninput="isEn"></el-input> -->
                  <el-input v-model="dataForm.phone.code" class="input-with-select-xs" oninput="value=value.replace(/[^\d.]/g, '')">
                    <el-select v-model="dataForm.phone.prefix" slot="prepend">
                      <el-option v-for="single in prefixOptions" :key="single.title" :value="single.value">
                        {{ single.value + " " + single.title }}
                      </el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Email"
                  prop="email"
                  :rules="[
                    {
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please enter',
                      trigger: 'submit',
                    },
                    { type: 'email', message: 'Please provide a valid email address', trigger: 'submit' },
                  ]"
                >
                  <el-input v-model="dataForm.email" placeholder="Email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Residential address"
                  prop="residentialAddress"
                  :rules="{ required: true, message: 'Please enter', trigger: 'submit' }"
                >
                  <el-input v-model="dataForm.residentialAddress" placeholder="Residential address"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Birth Date"
                  prop="birthDate"
                  :rules="[
                    {
                      required: businessType == 'COMPREHENSIVE_CUSTOMIZED' || businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
                      message: 'Please select',
                      trigger: 'submit',
                    },
                    { validator: checkAge, trigger: 'submit' },
                  ]"
                  class="fluid-width"
                >
                  <el-date-picker
                    v-model="dataForm.birthDate"
                    type="date"
                    placeholder="Birth Date"
                    value-format="yyyy-MM-dd"
                    class="fluid-width"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Does the individual hold share interest of any entity in PRC inland?"
                  prop="isHoldShareOfPrcInland"
                  :rules="{ required: true, message: 'Please select', trigger: 'submit' }"
                >
                  <el-select style="width: 100%" v-model="dataForm.isHoldShareOfPrcInland" placeholder="Please select">
                    <el-option label="Yes" :value="1"></el-option>
                    <el-option label="No" :value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="dataForm.isHoldShareOfPrcInland">
                <el-form-item
                  label="Please provide the name of RPC entity (1 entity is required)"
                  prop="holdShareOfPrcEntity"
                  :rules="{
                    required: true,
                    message: 'Please enter',
                    trigger: 'submit',
                  }"
                  class="fluid-width"
                >
                  <el-input v-model="dataForm.holdShareOfPrcEntity" placeholder="Please enter"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    businessType: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      roleList: [],
      dataForm: {
        id: 0,
        applymentId: undefined,
        memberType: "CORPORATE",
        fullName: "",
        country: "",
        nationality: "",
        identificationType: "",
        identificationNo: "",
        businessLicenseNo: "",
        email: "",
        phone: {
          prefix: "",
          code: "",
        },
        registeredOfficeAddress: "",
        operationAddress: "",
        residentialAddress: "",
        incorporationDate: "",
        birthDate: "",
        isHoldShareOfPrcInland: 0,
        holdShareOfPrcEntity: "",
        // representative (主体代表)
        representativeInfo: {
          fullName: null,
          nationality: null,
          identificationType: null,
          identificationNo: null,
          email: null,
          phone: {
            prefix: "",
            code: "",
          },
          residentialAddress: null,
          birthDate: null,
        },
      },
      initialDataForm: {},
      introduction: {
        idx: null,
        paramName: null,
      },
    };
  },
  mounted() {
    this.initialDataForm = JSON.parse(JSON.stringify(this.dataForm));
  },
  computed: {
    ...mapState("constant", ["identificationTypes", "countryList", "nationalityList", "currencyOptions", "prefixOptions"]),
    nativeCountryList() {
      return this.countryList.filter(i => i.native);
    },
  },
  methods: {
    // form表单自定义校验
    // 验证手机
    validatePhone(rule, value, callback) {
      if (value) {
        if (this.dataForm.phone.prefix) {
          callback();
        } else {
          callback(new Error("Please select the prefix of the mobile phone number"));
        }
      } else {
        if (this.dataForm.phone.prefix) {
          // callback(new Error("Please enter your mobile phone number"));
          callback();
        } else {
          callback();
        }
      }
    },
    validateRepresentativePhone(rule, value, callback) {
      if (value) {
        if (this.dataForm.representativeInfo.phone.prefix) {
          callback();
        } else {
          callback(new Error("Please select the prefix of the mobile phone number"));
        }
      } else {
        if (this.dataForm.representativeInfo.phone.prefix) {
          // callback(new Error("Please enter your mobile phone number"));
          callback();
        } else {
          callback();
        }
      }
    },
    // 验证是否满成年
    checkAge(rule, value, callback) {
      if (!value) {
        callback();
      } else {
        let birthDaySecond = new Date(value).getTime();
        //获取当前时间
        let atNowDate = new Date().getTime();
        let age = (atNowDate - birthDaySecond) / (365 * 24 * 3600 * 1000);
        if (age < 18) {
          callback(new Error("Must be at least 18 years old"));
        } else {
          callback();
        }
      }
    },
    // 初始化
    async init(idx, paramName, data) {
      this.introduction = {
        idx: idx,
        paramName: paramName,
      };
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      if (idx == "add") {
        this.dataForm = JSON.parse(JSON.stringify(this.initialDataForm));
      } else {
        const copy = JSON.parse(JSON.stringify(data));
        const copy02 = JSON.parse(JSON.stringify(data));
        // 国家 或 国籍
        console.log(data);
        if (copy.memberType == "CORPORATE") {
          copy.country = copy02.nationality;
          copy.nationality = "";
        } else {
          copy.country = "";
        }

        // representative (主体代表)
        if (copy.memberType == "CORPORATE") {
          if (copy.representativeInfo) {
            // Mobile No.
            const phoneStr = copy.representativeInfo.phone;
            const phoneSplit = phoneStr.split("-");
            const prefixNub = phoneSplit && phoneSplit[0] ? phoneSplit[0] : "";
            const codeNub = phoneSplit && phoneSplit[1] ? phoneSplit[1] : "";
            copy.representativeInfo.phone = {
              prefix: prefixNub,
              code: codeNub,
            };
          } else {
            copy.representativeInfo = {
              fullName: null,
              nationality: null,
              identificationType: null,
              identificationNo: null,
              email: null,
              phone: {
                prefix: "",
                code: "",
              },
              residentialAddress: null,
              birthDate: null,
            };
          }
        } else {
          copy.representativeInfo = {
            fullName: null,
            nationality: null,
            identificationType: null,
            identificationNo: null,
            email: null,
            phone: {
              prefix: "",
              code: "",
            },
            residentialAddress: null,
            birthDate: null,
          };
        }

        // Mobile
        let phoneCopy = copy.phone;
        let prefixCopy = phoneCopy.split("-") && phoneCopy.split("-")[0] ? phoneCopy.split("-")[0] : "";
        let codeCopy = phoneCopy.split("-") && phoneCopy.split("-")[1] ? phoneCopy.split("-")[1] : "";
        copy.phone = {
          prefix: prefixCopy,
          code: codeCopy,
        };
        this.dataForm = copy;
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
          // 数据处理
          if (dataFormCopy.memberType == "CORPORATE") {
            dataFormCopy.nationality = dataFormCopy.country;
          }

          // representative (主体代表)
          // Mobile No.
          const phoneItem = dataFormCopy.representativeInfo.phone;
          dataFormCopy.representativeInfo.phone = phoneItem.prefix + "-" + phoneItem.code;

          // Mobile
          let phoneCopy = dataFormCopy.phone;
          dataFormCopy.phone = phoneCopy.prefix + "-" + phoneCopy.code;
          this.$emit("change", dataFormCopy, this.introduction);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shares-info-table {
  table-layout: fixed;
  width: 100%;

  tr > th {
    padding: 12px 0;
    border-bottom: 1px solid #f0f5fa;
  }
  tbody > tr > td {
    padding: 12px 5px;
  }
}

.representativeWrap {
  font-size: 14px;
  color: #0060ff;
  font-weight: bolder;
  padding: 10px 15px;
  background: #eceff9;
  margin-bottom: 20px;
  border-radius: 4px;
}
</style>
