<template>
  <div class="variables-bar">
    <div class="btn-list">
      <div v-for="(item, idx) in variables" :key="idx" class="item-wrapper">
        <div class="btn" :title="item.tip" @click="() => handleItemClick(item)">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variables: {
      type: Array,
    },
  },
  methods: {
    handleItemClick(item) {
      this.$emit("insert", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.variables-bar {
  border-radius: 2px;
  text-align: center;
  .btn-list {
    margin-top: 40px;
  }
  .btn {
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    line-height: 32px;
    height: 32px;
    border: 1px solid #eee;
    text-align: center;
    margin-bottom: 10px;
    color: #1890ff;
  }
}
</style>
