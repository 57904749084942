<template>
  <div class="new-plan">
    <add-or-update />
  </div>
</template>
<script>
import AddOrUpdate from "./_partial";
export default {
  meta: {
    headName: "New Plan",
  },
  components: {
    AddOrUpdate,
  },
};
</script>
