<template>
  <el-dialog title="ACRA Feedback" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="rules" ref="dataForm" autocomplete="off" label-width="246px">
      <el-form-item label="Status" prop="applymentStatus">
        <el-radio-group v-model="dataForm.applymentStatus">
          <el-radio label="ACRA_REJECTED">Rejected</el-radio>
          <el-radio label="FINISH">Pass</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
      <!-- 未通过 -->
      <el-form-item label="Attachments" prop="attachmentUrls" v-if="dataForm.applymentStatus == 'ACRA_REJECTED'">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
      <!-- 通过 -->
      <div v-if="dataForm.applymentStatus == 'FINISH'">
        <el-form-item label="Article of Association">
          <el-multiple-file-uploader v-model="dataForm.articlesOfIncorporationUrls"></el-multiple-file-uploader>
        </el-form-item>
        <el-form-item label="Biz/ACRA File">
          <el-multiple-file-uploader v-model="dataForm.businessDocumentsUrls"></el-multiple-file-uploader>
        </el-form-item>
        <el-form-item label="Other Documents" prop="otherDocumentsUrls">
          <el-multiple-file-uploader v-model="dataForm.otherDocumentsUrls"></el-multiple-file-uploader>
        </el-form-item>
        <el-form-item label="Biz Reg. No. (UEN)" prop="businessLicenseNumber">
          <el-input v-model="dataForm.businessLicenseNumber" placeholder="Business License Number" :oninput="isEn"></el-input>
        </el-form-item>
        <el-form-item label="Company/Fund Manager Name" prop="companyName">
          <el-input v-model="dataForm.companyName" placeholder="Company/Fund Manager Name" :oninput="isEn"></el-input>
        </el-form-item>
        <el-form-item label="Incorporation/Transfer Time">
          <el-date-picker
            v-model="dataForm.incorporateTime"
            type="date"
            placeholder="Incorporation/Transfer Time"
            value-format="yyyy-MM-dd 00:00:00"
            class="fluid-width"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="Supplemental/Renewal Time">
          <el-date-picker
            v-model="dataForm.supplementalTime"
            type="date"
            placeholder="Supplemental/Renewal Time"
            value-format="yyyy-MM-dd 00:00:00"
            class="fluid-width"
          ></el-date-picker>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" plain @click="dataFormSubmit(true)">Save</el-button>
      <el-button type="primary" @click="dataFormSubmit(false)" class="ml20">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          statusDetail: {
            applymentStatus: "",
            remark: "",
            attachmentUrls: [],
            businessLicenseNumber: "",
            companyName: "",
            incorporateTime: "",
            articlesOfIncorporationUrls: [],
            businessDocumentsUrls: [],
            otherDocumentsUrls: [],
            supplementalTime: "",
          },
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        applymentStatus: "FINISH",
        remark: "",
        attachmentUrls: [],
        businessLicenseNumber: "",
        companyName: "",
        incorporateTime: "",
        articlesOfIncorporationUrls: [],
        businessDocumentsUrls: [],
        otherDocumentsUrls: [],
        supplementalTime: "",
      },
      rules: {
        // articlesOfIncorporationUrls: [{ required: true, message: "Please Upload", trigger: "submit" }],
        // businessDocumentsUrls: [{ required: true, message: "Please Upload", trigger: "submit" }],
        businessLicenseNumber: [{ required: true, message: "Please Enter", trigger: "submit" }],
        companyName: [{ required: true, message: "Please Enter", trigger: "submit" }],
        // incorporateTime: [{ required: true, message: "Please Select", trigger: "submit" }],
      },
      submitControl: true, // 防止重复提交
    };
  },
  computed: {
    ...mapState("constant", ["stepStatusList"]),
    unselectableStatus() {
      return this.stepStatusList.find(i => i.unselectable);
    },
  },
  methods: {
    ...mapActions("applyment", ["doSaveProcedures", "doAcraFeedback"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      let statusDetail = this.detail.statusDetail;
      this.dataForm = {
        applymentStatus:
          statusDetail.applymentStatus == "ACRA_REJECTED" || statusDetail.applymentStatus == "FINISH" ? statusDetail.applymentStatus : "FINISH",
        remark: statusDetail.remark || "",
        attachmentUrls: statusDetail.attachmentUrls || [],
        businessLicenseNumber: statusDetail.businessLicenseNumber || "",
        companyName: statusDetail.companyName || "",
        incorporateTime: statusDetail.incorporateTime || "",
        articlesOfIncorporationUrls: statusDetail.articlesOfIncorporationUrls || [],
        businessDocumentsUrls: statusDetail.businessDocumentsUrls || [],
        otherDocumentsUrls: statusDetail.otherDocumentsUrls || [],
        supplementalTime: statusDetail.supplementalTime || "",
      };
    },
    // 表单提交
    dataFormSubmit(type) {
      let dataFormCopy = this.dataForm;
      let params = {
        applymentStatus: dataFormCopy.applymentStatus,
        remark: dataFormCopy.remark,
        onlyTemporarilySaved: type,
      };
      if (dataFormCopy.applymentStatus == "FINISH") {
        params.businessLicenseNumber = dataFormCopy.businessLicenseNumber;
        params.companyName = dataFormCopy.companyName;
        params.incorporateTime = dataFormCopy.incorporateTime;
        params.articlesOfIncorporationUrls = dataFormCopy.articlesOfIncorporationUrls;
        params.businessDocumentsUrls = dataFormCopy.businessDocumentsUrls;
        params.otherDocumentsUrls = dataFormCopy.otherDocumentsUrls;
        params.supplementalTime = dataFormCopy.supplementalTime;
      } else {
        params.attachmentUrls = dataFormCopy.attachmentUrls;
      }

      if (type) {
        if (this.submitControl) {
          this.submitControl = false;
          this.doAcraFeedback(params)
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      } else {
        this.$refs["dataForm"].validate(async valid => {
          if (valid) {
            this.$confirm("Really ok?", "Warning", {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }).then(async () => {
              if (this.submitControl) {
                this.submitControl = false;
                this.doAcraFeedback(params)
                  .then(() => {
                    this.$message({
                      message: "success",
                      type: "success",
                      duration: 1500,
                      onClose: () => {
                        this.visible = false;
                        this.submitControl = true;
                      },
                    });
                  })
                  .catch(msg => {
                    this.$message.error(msg);
                    this.submitControl = true;
                  });
              }
            });
          }
        });
      }
    },
  },
};
</script>
