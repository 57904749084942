<template>
  <div>
    <el-form :inline="true" :model="dataForm" ref="dataForm">
      <div style="display: inline-block">
        <el-form-item
          prop="introducedByOption"
          ref="introducedByOption"
          :rules="{
            required: true,
            message: 'please select',
            trigger: 'submit',
          }"
        >
          <el-select v-model="dataForm.introducedByOption" clearable placeholder="Introduced By" class="controlLeft" @change="introducedByChange">
            <el-option v-for="item in introducedByArr" :label="item.title" :value="item.value" :key="item.title"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="commissionId"
          ref="commissionId"
          :rules="{
            required: true,
            message: 'please select',
            trigger: 'submit',
          }"
          class="fml10"
          v-if="dataForm.introducedByOption == 4"
        >
          <el-select v-model="dataForm.commissionId" value-key="id" filterable clearable placeholder="Commission" class="controlRight">
            <el-option
              v-for="item in commissionList"
              :key="item.id"
              :label="`${item.name} - ${multiply(item.commissionRate)}`"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div style="display: inline-block">
        <el-form-item
          prop="clientCodeOption"
          ref="clientCodeOption"
          :rules="{
            required: true,
            message: 'please select',
            trigger: 'submit',
          }"
        >
          <el-select v-model="dataForm.clientCodeOption" clearable placeholder="Client Code" class="controlLeft" @change="clientCodeOptionChange">
            <el-option label="All" :value="1"></el-option>
            <el-option label="Specific Code" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="clientCodeList"
          ref="clientCodeList"
          :rules="{
            required: true,
            type: 'array',
            message: 'please select',
            trigger: 'submit',
          }"
          class="fml10"
          v-if="dataForm.clientCodeOption == 2"
        >
          <el-select
            v-model="dataForm.clientCodeList"
            collapse-tags
            multiple
            clearable
            filterable
            remote
            :remote-method="getClientCodeRemote"
            @clear="getClientCodeRemote('')"
            @focus="getClientCodeRemote('')"
            :loading="clientCodeLoading"
            placeholder="Specific Code"
            class="controlRight"
          >
            <el-option
              v-for="(item, sy) in clientCodeOptions"
              :label="item.clientCode"
              :key="`${item.clientCode}${sy}`"
              :value="item.clientCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <el-form-item>
        <el-select v-model="dataForm.companyNameList" collapse-tags multiple clearable placeholder="Entity Name" class="wd320">
          <el-option v-for="(item, idx) in accountNameList" :key="`${idx}${item}`" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker-range
          :startDate="dataForm.coveredPeriodStart"
          :endDate="dataForm.coveredPeriodEnd"
          startPlaceholder="Covered (Start Date)"
          endPlaceholder="Covered (End Date)"
          @startSelectChange="
            val => {
              dataForm.coveredPeriodStart = val;
            }
          "
          @endSelectChange="
            val => {
              dataForm.coveredPeriodEnd = val;
            }
          "
        ></el-date-picker-range>
      </el-form-item>
      <!-- 收入日期筛选，以engagement中Covered Period的截止日期为筛选点 -->
      <el-form-item>
        <el-date-picker-range
          :startDate="dataForm.coveredPeriodDeadlineStart"
          :endDate="dataForm.coveredPeriodDeadlineEnd"
          startPlaceholder="Deadline (Start Date)"
          endPlaceholder="Deadline (End Date)"
          @startSelectChange="
            val => {
              dataForm.coveredPeriodDeadlineStart = val;
            }
          "
          @endSelectChange="
            val => {
              dataForm.coveredPeriodDeadlineEnd = val;
            }
          "
        ></el-date-picker-range>
      </el-form-item>

      <div style="display: inline-block">
        <el-form-item
          prop="serviceTypeOption"
          ref="serviceTypeOption"
          :rules="{
            required: true,
            message: 'please select',
            trigger: 'submit',
          }"
        >
          <el-select v-model="dataForm.serviceTypeOption" clearable placeholder="Service Type" class="controlLeft" @change="serviceTypeOptionChange">
            <el-option label="All" :value="1"></el-option>
            <el-option label="Specific Type" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="serviceTypeList"
          ref="serviceTypeList"
          :rules="{
            required: true,
            type: 'array',
            message: 'please select',
            trigger: 'submit',
          }"
          class="fml10"
          v-if="dataForm.serviceTypeOption == 2"
        >
          <el-select v-model="dataForm.serviceTypeList" collapse-tags multiple clearable placeholder="Specific Type" class="controlRight">
            <el-option v-for="(item, idx) in serviceTypeList" :key="`${idx}${item.title}`" :label="item.title" :value="item.title"></el-option>
          </el-select>
        </el-form-item>
      </div>

      <el-form-item
        prop="budgetPeriod"
        ref="budgetPeriod"
        :rules="{
          required: true,
          message: 'please select',
          trigger: 'submit',
        }"
      >
        <el-select v-model="dataForm.budgetPeriod" clearable placeholder="Budget Period">
          <el-option v-for="item in budgetPeriodList" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="dataFormSubmit" class="mr30" v-if="isAuth('budget:generate')">
          Generate
          <el-popover placement="bottom" trigger="hover">
            <span class="fs12">Regenerate the data based on the filter and overwrite the previous data</span>
            <span slot="reference" class="fs14 ml4 el-icon-warning"></span>
          </el-popover>
        </el-button>
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ElDatePickerRange from "@/components/ElDatePickerRange";
import { multiply } from "@/utils";
import { mapState } from "vuex";
export default {
  props: {
    generateData: {
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      dataForm: {},
      commissionList: [],
      accountNameList: [],
      clientCodeLoading: false,
      clientCodeOptions: [],
    };
  },
  components: {
    ElDatePickerRange,
  },
  watch: {
    generateData(data) {
      console.log(data);
      this.dataForm = { ...data };
    },
  },
  computed: {
    ...mapState("constant", ["clientCodeStatusList", "serviceTypeList", "budgetPeriodList", "introducedByArr", "entityTypes"]),
    multiply() {
      return val => {
        return multiply(val, 100) + "%";
      };
    },
  },
  methods: {
    // 获取Account Name列表
    async getAccountNameList() {
      const { data } = await this.$http.get("/admin/stInvoiceInfoManage/getList");
      const list = [];
      data.forEach(item => {
        if (!list.includes(item.title)) {
          list.push(item.title);
        }
      });
      this.accountNameList = list;
    },
    // 获取commissionList列表
    async getCommissionList() {
      const { data } = await this.$http.get("/admin/stCommission/getList");
      this.commissionList = data;
    },
    // 获取client code列表
    async getClientCodeRemote(query) {
      this.clientCodeLoading = true;
      const { code, data } = await this.$http.get("/admin/clientCodeInfoMa/getList", {
        params: {
          keywords: query,
        },
      });
      this.clientCodeLoading = false;
      if (code === 200) {
        this.clientCodeOptions = data;
      } else {
        this.clientCodeOptions = [];
      }
    },

    // Introduced By - Change
    introducedByChange() {
      if (this.dataForm.introducedByOption != 4) {
        this.$set(this.dataForm, "commissionId", null);
      }
    },
    // Client Code - Change
    clientCodeOptionChange() {
      if (this.dataForm.clientCodeOption == 1) {
        this.$set(this.dataForm, "clientCode", []);
      }
    },
    // Service Type - Change
    serviceTypeOptionChange() {
      if (this.dataForm.serviceTypeOption == 1) {
        this.$set(this.dataForm, "serviceTypeList", []);
      }
    },
    // 保存筛选条件
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.$emit("updateGenerateData", { ...this.dataForm });
        }
      });
    },
  },
  activated() {
    this.getCommissionList();
    this.getAccountNameList();
  },
};
</script>

<style lang="scss" scoped>
.fml10 {
  margin-left: -10px;
}

.controlLeft {
  ::v-deep input {
    // border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ::v-deep .el-input__inner:focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input__inner.is-focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input:focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }

  ::v-deep .el-input.is-focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }
}

.controlRight {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  ::v-deep input {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
