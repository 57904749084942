<template>
  <div class="page-container">
    <div>
      <el-button v-if="!noContent" type="primary" @click="addOrUpdateHandle()">Add Bank Account</el-button>
    </div>
    <div class="el-list protocols-wrapper">
      <el-row v-loading="dataListLoading" :gutter="30">
        <el-col v-if="noContent">
          <el-empty description=" ">
            <el-button type="primary" @click="addOrUpdateHandle()">Add Bank Account</el-button>
          </el-empty>
        </el-col>
        <template v-if="!noContent">
          <el-col :span="6" v-for="item in dataList" :key="item.id">
            <el-card shadow="nerver" :body-style="{ padding: '0px' }" class="mt26 relative">
              <div class="module-item">
                <div class="module-desc">
                  <p class="item-wrap">
                    <span class="item-label">Entity type:</span>
                    <span class="item-content">
                      {{ entityTypes.find(el => el.value == item.type).title ? entityTypes.find(el => el.value == item.type).title : "" }}
                    </span>
                  </p>
                  <p class="item-wrap">
                    <span class="item-label">Bank Name:</span>
                    <span class="item-content">{{ item.bankName }}</span>
                  </p>
                  <p class="item-wrap">
                    <span class="item-label">Update:</span>
                    <span class="item-content">{{ item.updateTime }}</span>
                  </p>
                  <p class="item-wrap">
                    <span class="item-label">Account Number:</span>
                    <span class="item-content">{{ item.accountNumber }}</span>
                  </p>
                </div>
              </div>
              <ul class="el-card-actions">
                <li style="width: 50%" @click="deleteHandle(item.id)">
                  <svg-icon name="delete" class="fs16"></svg-icon>
                </li>
                <li style="width: 50%" @click="addOrUpdateHandle(item.id)">
                  <svg-icon name="edit" class="fs16"></svg-icon>
                </li>
              </ul>
            </el-card>
          </el-col>
        </template>
      </el-row>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./AddOrUpdate";
import { mapState } from "vuex";
export default {
  components: {
    AddOrUpdate,
  },
  data() {
    return {
      dataForm: {},
      dataList: [],
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  computed: {
    ...mapState("constant", ["entityTypes"]),
    noContent() {
      return this.dataList.length === 0;
    },
  },
  activated() {
    this.getDataList();
  },
  methods: {
    /* 格式化 */
    /* 格式化 */
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      const { data } = await this.$http.get("/admin/stBankAccountManage/getList");
      this.dataList = data;
      this.dataListLoading = false;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    async deleteHandle(id) {
      await this.$confirm(`Delete this Bank Account. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const { code, msg } = await this.$http.post(`/admin/stBankAccountManage/${id}/delete`);
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => this.getDataList(),
        });
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.protocols-wrapper {
  .relative {
    border: none;
    background: #f9fafd;
    .module-item {
      padding: 18px 20px 18px;
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-wrap: nowrap;
      border: 2px dashed #e9ecf1;
      border-bottom: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .module-desc {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
        font-size: 16px;

        .item-label {
          display: inline-block;
          width: 120px;
          color: #7987a0;
          font-size: 14px;
          margin-right: 10px;
        }
        .item-wrap {
          line-height: 38px;
          margin: 0px;
        }
        .item-content {
          font-size: 14px;
          font-weight: bold;
          color: #011a45;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          display: inline-block;
          width: calc(100% - 130px);
          vertical-align: middle;
        }
        .date {
          margin: 24px 0 10px;
          font-size: 14px;
        }
      }
    }
    .el-card-actions {
      border: 2px dashed #e9ecf1;
      border-top: 2px solid #e9ecf1;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: transparent;
    }

    .el-card-actions li:not(:last-child) {
      border-right: 2px solid #e9ecf1;
    }
  }

  .relative:hover {
    background: #e8f0ff;
    .module-item {
      border-color: #e8f0ff;
    }
    .el-card-actions {
      border-color: #e8f0ff;
      border-top: 2px solid #d8e3ff;
    }
    .el-card-actions li:not(:last-child) {
      border-right: 2px solid #d8e3ff;
    }
  }
}
</style>
