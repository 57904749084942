<template>
  <el-dialog width="700px" title="Finish Review" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="130px">
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status" @change="statusChange">
          <span v-for="item in clientCodeStatusList" :key="`review${item.title}`">
            <el-radio :label="item.value" v-if="item.value == 1 || item.value == 2" class="mr40">{{ item.title }}</el-radio>
          </span>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Introduced By" prop="introducedBy" v-if="dataForm.status == 1">
        <el-radio-group v-model="dataForm.introducedBy" @change="introducedByChange">
          <el-radio v-for="item in introducedByList" :key="`introducedBy${item.title}`" :label="item.value">{{ item.title }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="commission" prop="commissionList" v-if="dataForm.status == 1 && dataForm.introducedBy == 1">
        <el-select style="width: 100%" v-model="dataForm.commissionList" value-key="id" filterable clearable multiple placeholder="Please Select">
          <el-option
            v-for="item in commissionRequiredList"
            :key="item.id"
            :label="`${item.name} - ${multiply(item.commissionRate)}`"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Remark" prop="auditRemark">
        <el-input v-model="dataForm.auditRemark" type="textarea" :rows="5" :autosize="{ minRows: 4 }" resize="none" placeholder="Remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" v-if="isAuth('sys:client:audit')" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { multiply } from "@/utils";
import { mapState } from "vuex";
export default {
  props: {
    commissionRequiredList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      client: {},
      dataForm: {
        status: 1,
        introducedBy: null,
        commissionList: [],
        auditRemark: "",
      },
      submitControl: true,
      dataRule: {
        status: [{ required: true, message: "please select", trigger: "submit" }],
        introducedBy: [{ required: true, message: "please select", trigger: "submit" }],
        commissionList: [{ type: "array", required: true, message: "please select", trigger: "submit" }],
      },
    };
  },
  computed: {
    ...mapState("constant", ["clientCodeStatusList", "introducedByList"]),
    multiply() {
      return val => {
        return multiply(val, 100) + "%";
      };
    },
  },
  methods: {
    async init(row) {
      this.client = row;
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // status - Change
    statusChange() {
      if (this.dataForm.status == 2) {
        this.$set(this.dataForm, "introducedBy", null);
        this.$set(this.dataForm, "commissionList", []);
      }
    },
    // introduced By - Change
    introducedByChange() {
      if (!this.dataForm.introducedBy) {
        this.$set(this.dataForm, "commissionList", []);
      }
    },
    // 表单提交
    dataFormSubmit() {
      const dataForm = this.dataForm;
      const client = this.client;
      if (dataForm.status == 1) {
        if (!client.clientCode || !client.uens || !client.uens.length || !client.country) {
          this.$message.warning("Information for client code (e.g. UEN and/or Country) is not complete, please fill before reviewing.");
          return;
        }
      }
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.$http.post(`/admin/clientCodeInfoManage/${this.client.id}/audit`, this.dataForm).then(({ code, msg }) => {
            if (code === 200) {
              this.visible = false;
              this.submitControl = true;
              this.$emit("refreshStatus");
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
  },
};
</script>
