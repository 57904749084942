var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1200px",
      "title": !_vm.dataForm.id ? 'New Template' : 'Edit Template',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "126px"
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "lg": 16,
      "xl": 13
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Template Type",
      "prop": "type"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select type"
    },
    model: {
      value: _vm.dataForm.type,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "type", $$v);
      },
      expression: "dataForm.type"
    }
  }, _vm._l(_vm.templateTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Trigger"
    }
  }, [_c('span', {
    staticClass: "pl16"
  }, [_vm._v(_vm._s(_vm.triggerPitch))])]), _c('el-form-item', {
    attrs: {
      "label": "Subject",
      "prop": "subject"
    }
  }, [_c('el-input', {
    attrs: {
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.subject,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "subject", $$v);
      },
      expression: "dataForm.subject"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Subject(CN)",
      "prop": "subjectCn"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.dataForm.subjectCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "subjectCn", $$v);
      },
      expression: "dataForm.subjectCn"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Body",
      "prop": "body"
    }
  }, [_c('div', {
    staticClass: "email-template-editor-wrapper"
  }, [_c('el-editor', {
    ref: "editor1",
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.body,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "body", $$v);
      },
      expression: "dataForm.body"
    }
  }), _vm.currentVariable ? _c('variables-bar', {
    staticClass: "bar",
    attrs: {
      "variables": _vm.currentVariable
    },
    on: {
      "insert": _vm.handleVariableInsert
    }
  }) : _vm._e()], 1)]), _c('el-form-item', {
    attrs: {
      "label": "Body(CN)",
      "prop": "bodyCn"
    }
  }, [_c('div', {
    staticClass: "email-template-editor-wrapper"
  }, [_c('el-editor', {
    ref: "editor2",
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.bodyCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "bodyCn", $$v);
      },
      expression: "dataForm.bodyCn"
    }
  }), _vm.currentVariable ? _c('variables-bar', {
    staticClass: "bar",
    attrs: {
      "variables": _vm.currentVariable
    },
    on: {
      "insert": _vm.handleVariableInsert2
    }
  }) : _vm._e()], 1)]), _c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "status"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": 0
    }
  }, [_vm._v("Disable")]), _c('el-radio', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("Enable")])], 1)], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }