<template>
  <el-dialog title="Batch Confirm Refund" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="60px">
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio label="REFUND_INFO_NOT_ENOUGH">Refund Failed due to Insufficient Info</el-radio>
          <el-radio label="REFUNDED">Refunded</el-radio>
          <el-radio label="REFUND_PENDING">Pending Refund</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        status: "REFUNDED",
      },
      orderIdsList: [],
      submitControl: true,
    };
  },
  methods: {
    ...mapActions("finance", ["doBatchRefund"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.orderIdsList = data;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doBatchRefund({ orderIds: this.orderIdsList, refundStatus: this.dataForm.status })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>