import { createInstance } from "./http";

const instance = createInstance();

var Uploader = function () {};

const uploader = new Uploader();

uploader.upload = function (file, type = "files") {
  const formData = new FormData();
  formData.append("file", file);
  return this.rawUpload(formData, type);
};

uploader.rawUpload = function (formData, type = "files") {
  return new Promise((resolve, reject) => {
    instance
      .post(`/fileUpload/${type}/upload`, formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then(response => {
        const res = response.data;
        if (res.code === 200) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default uploader;
