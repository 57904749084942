<template>
  <el-dialog :title="isAdd ? 'Add EP Applicant' : 'Edit EP Applicant'" :close-on-click-modal="false" :visible.sync="visible" width="1100px" append-to-body>
    <el-form :model="dataForm" ref="dataForm" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Name (same as IC)" prop="fullName" :rules="{ required: true, message: 'Please enter', trigger: 'submit' }">
            <el-input v-model="dataForm.fullName" placeholder="Name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Nationality" prop="nationality" :rules="{ required: true, message: 'Please select', trigger: 'submit' }">
            <el-select style="width: 100%" v-model="dataForm.nationality" placeholder="Select Nationality">
              <el-option v-for="item in nationalityList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Identification Type" prop="identificationType" :rules="{ required: true, message: 'Please select', trigger: 'submit' }">
            <el-select style="width: 100%" v-model="dataForm.identificationType" placeholder="Select identificationType">
              <el-option v-for="item in identificationTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Identification No." prop="identificationNo" :rules="{ required: true, message: 'Please enter', trigger: 'submit' }">
            <el-input v-model="dataForm.identificationNo" placeholder="Identification No." :oninput="isEn"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="Mobile"
            prop="phone.code"
            :rules="[
              {
                required: true,
                message: 'Please enter',
                trigger: 'submit',
              },
              { validator: validatePhone, trigger: 'submit' },
            ]"
          >
            <el-input v-model="dataForm.phone.code" class="input-with-select-xs" oninput="value=value.replace(/[^\d.]/g, '')">
              <el-select v-model="dataForm.phone.prefix" slot="prepend">
                <el-option v-for="single in prefixOptions" :key="single.title" :value="single.value">
                  {{ single.value + " " + single.title }}
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Email"
            prop="email"
            :rules="[
              {
                required: true,
                message: 'Please enter',
                trigger: 'submit',
              },
              { type: 'email', message: 'Please provide a valid email address', trigger: 'submit' },
            ]"
          >
            <el-input v-model="dataForm.email" placeholder="Email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Residential address" prop="residentialAddress" :rules="{ required: true, message: 'Please enter', trigger: 'submit' }">
            <el-input v-model="dataForm.residentialAddress" placeholder="Residential address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Birth Date"
            prop="birthDate"
            :rules="[
              {
                required: true,
                message: 'Please select',
                trigger: 'submit',
              },
              { validator: checkAge, trigger: 'submit' },
            ]"
            class="fluid-width"
          >
            <el-date-picker
              v-model="dataForm.birthDate"
              type="date"
              placeholder="Birth Date"
              value-format="yyyy-MM-dd"
              class="fluid-width"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="Does the individual hold share interest of any entity in PRC inland?"
            prop="isHoldShareOfPrcInland"
            :rules="{ required: true, message: 'Please select', trigger: 'submit' }"
          >
            <el-select style="width: 100%" v-model="dataForm.isHoldShareOfPrcInland" placeholder="Please select">
              <el-option label="Yes" :value="1"></el-option>
              <el-option label="No" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="dataForm.isHoldShareOfPrcInland">
          <el-form-item
            label="Please provide the name of RPC entity (1 entity is required)"
            prop="holdShareOfPrcEntity"
            :rules="{
              required: true,
              message: 'Please enter',
              trigger: 'submit',
            }"
            class="fluid-width"
          >
            <el-input v-model="dataForm.holdShareOfPrcEntity" placeholder="Please enter"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        fullName: "",
        nationality: "",
        identificationType: "",
        identificationNo: "",
        email: "",
        phone: {
          prefix: "",
          code: "",
        },
        residentialAddress: "",
        birthDate: "",
        isHoldShareOfPrcInland: 0,
        holdShareOfPrcEntity: "",
        applicantPersonalInfoUrls: [],
      },
      initialDataForm: {},
      isAdd: true,
    };
  },
  mounted() {
    this.initialDataForm = JSON.parse(JSON.stringify(this.dataForm));
  },
  computed: {
    ...mapState("constant", ["identificationTypes", "countryList", "nationalityList", "currencyOptions", "prefixOptions"]),
  },
  methods: {
    // form表单自定义校验
    // 验证手机
    validatePhone(rule, value, callback) {
      if (value) {
        if (this.dataForm.phone.prefix) {
          callback();
        } else {
          callback(new Error("Please select the prefix of the mobile phone number"));
        }
      } else {
        if (this.dataForm.phone.prefix) {
          // callback(new Error("Please enter your mobile phone number"));
          callback();
        } else {
          callback();
        }
      }
    },
    // 验证是否满成年
    checkAge(rule, value, callback) {
      if (!value) {
        callback();
      } else {
        let birthDaySecond = new Date(value).getTime();
        //获取当前时间
        let atNowDate = new Date().getTime();
        let age = (atNowDate - birthDaySecond) / (365 * 24 * 3600 * 1000);
        if (age < 18) {
          callback(new Error("Must be at least 18 years old"));
        } else {
          callback();
        }
      }
    },
    // 初始化
    async init(data) {
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      if (data) {
        this.isAdd = false;
        const copy = JSON.parse(JSON.stringify(data));
        // Mobile
        let phoneCopy = copy.phone;
        let prefixCopy = phoneCopy.split("-") && phoneCopy.split("-")[0] ? phoneCopy.split("-")[0] : "";
        let codeCopy = phoneCopy.split("-") && phoneCopy.split("-")[1] ? phoneCopy.split("-")[1] : "";
        copy.phone = {
          prefix: prefixCopy,
          code: codeCopy,
        };
        this.dataForm = copy;
      } else {
        this.isAdd = true;
        this.dataForm = JSON.parse(JSON.stringify(this.initialDataForm));
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
          // Mobile
          let phoneCopy = dataFormCopy.phone;
          dataFormCopy.phone = phoneCopy.prefix + "-" + phoneCopy.code;
          this.$emit("change", dataFormCopy);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shares-info-table {
  table-layout: fixed;
  width: 100%;

  tr > th {
    padding: 12px 0;
    border-bottom: 1px solid #f0f5fa;
  }
  tbody > tr > td {
    padding: 12px 5px;
  }
}

.representativeWrap {
  font-size: 14px;
  color: #0060ff;
  font-weight: bolder;
  padding: 10px 15px;
  background: #eceff9;
  margin-bottom: 20px;
  border-radius: 4px;
}
</style>
