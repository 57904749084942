<template>
  <el-dialog width="1200px" :title="!dataForm.id ? 'New Template' : 'Edit Template'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="126px">
      <el-row>
        <el-col :lg="16" :xl="13">
          <el-form-item label="Template Type" prop="type">
            <el-select style="width: 100%" v-model="dataForm.type" placeholder="Select type">
              <el-option v-for="item in templateTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Trigger">
        <span class="pl16">{{ triggerPitch }}</span>
      </el-form-item>
      <el-form-item label="Subject" prop="subject">
        <el-input v-model="dataForm.subject" :oninput="isEn" />
      </el-form-item>
      <el-form-item label="Subject(CN)" prop="subjectCn">
        <el-input v-model="dataForm.subjectCn" />
      </el-form-item>
      <el-form-item label="Body" prop="body">
        <div class="email-template-editor-wrapper">
          <el-editor :height="400" class="editor" v-model="dataForm.body" ref="editor1" />
          <variables-bar v-if="currentVariable" :variables="currentVariable" @insert="handleVariableInsert" class="bar" />
        </div>
      </el-form-item>
      <el-form-item label="Body(CN)" prop="bodyCn">
        <div class="email-template-editor-wrapper">
          <el-editor :height="400" class="editor" v-model="dataForm.bodyCn" ref="editor2" />
          <variables-bar v-if="currentVariable" class="bar" :variables="currentVariable" @insert="handleVariableInsert2" />
        </div>
      </el-form-item>
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">Disable</el-radio>
          <el-radio :label="1">Enable</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import VariablesBar from "./components/VariablesBar";
export default {
  components: {
    VariablesBar,
  },
  props: {
    templateTypes: {
      type: Array,
    },
    triggerTypes: {
      type: Array,
    }
  },
  data() {
    return {
      visible: false,
      dataForm: {
        type: "",
        subject: undefined,
        subjectCn: undefined,
        body: undefined,
        bodyCn: undefined,
        status: 1,
      },
      dataRule: {
        type: [{ required: true, message: "template type is required", trigger: "blur" }],
        subject: [{ required: true, message: "subject is required", trigger: "blur" }],
        subjectCn: [{ required: true, message: "subject(CN) is required", trigger: "blur" }],
        body: [{ required: true, message: "body is required", trigger: "blur" }],
        bodyCn: [{ required: true, message: "body(CN) is required", trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  computed: {
    currentVariable() {
      if (this.dataForm.type) {
        const one = this.templateTypes.find(i => i.value === this.dataForm.type);
        return one?.variables;
      }
      return undefined;
    },
    triggerPitch() {
      if (this.dataForm.type) {
        const one = this.triggerTypes.find(i => i.value === this.dataForm.type);
        return one?.title;
      }
      return undefined;
    }
  },
  methods: {
    init(id) {
      this.submitControl = true;
      this.visible = true;
      this.$nextTick().then(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          this.getDetail(id);
        } else {
          this.dataForm.id = undefined;
          this.dataForm.content = "";
        }
      });
    },
    //编辑器插入变量
    handleVariableInsert(item) {
      this.$refs.editor1.insertHtml(`<em class="var">[${item.title}]</em>`);
    },
    handleVariableInsert2(item) {
      this.$refs.editor2.insertHtml(`<em class="var">[${item.title}]</em>`);
    },
    //详情
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/emailTemplateManage/${id}/detail`);
      this.dataForm = { ...data };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.$http.post(
            this.dataForm.id ? `/admin/emailTemplateManage/${this.dataForm.id}/update` : `/admin/emailTemplateManage/create`,
            this.dataForm,
          );
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.email-template-editor-wrapper {
  display: flex;
  justify-content: space-between;
  .editor {
    flex: 1;
  }
  .bar {
    width: 80px;
    margin-left: 20px;
  }
}
</style>
