<template>
  <div class="mb30 min-hg460 pd26 bg-white border-radius-10">
    <div class="flex flex-direction-row align-center justify-between">
      <h3 class="home-title" v-if="clientType == 'acceptedData'">Accepted Client</h3>
      <h3 class="home-title" v-if="clientType == 'pendingData'">Pending Modified Client</h3>
      <h3 class="home-title" v-if="clientType == 'potentialData'">Potential Client</h3>
      <h3 class="home-title" v-if="clientType == 'lostData'">Lost Client</h3>
    </div>
    <div class="mt14 flex justify-center wd340 capsule-wrap">
      <div
        class="wd80 capsule-item"
        :class="{ 'capsule-active': clientType == 'acceptedData' }"
        @click="
          clientType = 'acceptedData';
          setClientAmount(clientAmountObj.acceptedData);
        "
      >
        Accepted
      </div>
      <div
        class="wd130 capsule-item"
        :class="{ 'capsule-active': clientType == 'pendingData' }"
        @click="
          clientType = 'pendingData';
          setClientAmount(clientAmountObj.pendingData);
        "
      >
        Pending Modified
      </div>
      <div
        class="wd70 capsule-item"
        :class="{ 'capsule-active': clientType == 'potentialData' }"
        @click="
          clientType = 'potentialData';
          setClientAmount(clientAmountObj.potentialData);
        "
      >
        Potential
      </div>
      <div
        class="wd60 capsule-item"
        :class="{ 'capsule-active': clientType == 'lostData' }"
        @click="
          clientType = 'lostData';
          setClientAmount(clientAmountObj.lostData);
        "
      >
        Lost
      </div>
    </div>
    <div id="clientChunk" class="mt20 hg300"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clientType: "acceptedData",
      clientAmountObj: {},
    };
  },
  methods: {
    // 设置Accepted Client、Pending Modified Client、Potential Client、Lost Client的饼状图
    setClientAmount(obj) {
      const myChart = this.$echarts.init(document.getElementById("clientChunk"));
      const option = {
        color: ["#0072ff", "#ff7e69", "#DCDFE6"],
        title: {
          // text: "Referer of a Website",
          subtext: `Total: ${Number(obj.sgCnt || 0) + Number(obj.hkCnt || 0) + Number(obj.othersCnt || 0)} `,
          subtextStyle: {
            fontSize: 14,
            color: "#7987a0",
            fontWeight: "bold",
          },
          left: "right",
          top: "-10",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "right",
          top: 25,
          formatter: function (name) {
            let cnt = 0;
            if (name == "Singapore") {
              cnt = Number(obj.sgCnt || 0);
            } else if (name == "Hong Kong") {
              cnt = Number(obj.hkCnt || 0);
            } else {
              cnt = Number(obj.othersCnt || 0);
            }
            return `${name} ${cnt}`;
          },
        },
        series: [
          {
            type: "pie",
            radius: "70%",
            label: {
              show: true,
              position: "inside",
              formatter: "{d}%",
            },
            top: "10%",
            data: [
              { value: Number(obj.sgCnt || 0), name: "Singapore" },
              { value: Number(obj.hkCnt || 0), name: "Hong Kong" },
              { value: Number(obj.othersCnt || 0), name: "Others" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 获取Accepted Client、Pending Modified Client、Potential Client、Lost Client的数据
    getClientAmount() {
      this.$http.get("/admin/statistics/getClientCodeCountryDistribution", {}).then(({ code, data, msg }) => {
        if (code === 200) {
          this.clientAmountObj = data || {};
          this.clientType == "acceptedData";
          this.setClientAmount(this.clientAmountObj.acceptedData);
        } else {
          this.$message.error(msg);
        }
      });
    },
  },
  activated() {
    // 获取Accepted Client、Pending Modified Client、Potential Client、Lost Client的数据
    this.getClientAmount();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.min-hg460 {
  min-height: 460px;
}

.home-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: $form-item-font-color;
}

.capsule-wrap {
  width: 210px;
  border-radius: 26px;
  overflow: hidden;
  .capsule-item {
    width: 70px;
    text-align: center;
    height: 30px;
    line-height: 28px;
    background-color: #ebeefd;
    color: #7987a0;
    font-size: 12px;
    cursor: pointer;
  }

  .capsule-active {
    background-color: #0072ff !important;
    color: #fff !important;
  }
}
</style>
