import Vue from "vue";
import positiveInteger from "./src/positiveInteger";
import positivePoint2 from "./src/positivePoint2";
import positivePoint4 from "./src/positivePoint4";
import minusPoint2 from "./src/minusPoint2";

// 只能输入正整数(包括0)
Vue.directive("positiveInteger", positiveInteger);
// 只能输入正整数(包括0)或小数，小数只保留2位
Vue.directive("positivePoint2", positivePoint2);
// 只能输入正整数(包括0)或小数，小数只保留4位
Vue.directive("positivePoint4", positivePoint4);
// 只能输入正负数字(包括0)或正负小数，小数只保留2位
Vue.directive("minusPoint2", minusPoint2);
