<template>
  <div class="plan-detail-module">
    <div class="plan-base-wrapper">
      <div class="title" v-if="businessType == 'COMPREHENSIVE_CUSTOMIZED'">Comprehensive Corporate Services (customized)</div>
      <div class="title" v-if="businessType == 'FUND_ADMIN_CUSTOMIZED'">Fund Admin Services (customized)</div>
      <div class="price">
        <span>{{ quotationPart.subTotalPrice | thousands }}</span>
        <span class="ml6 tdlt">({{ quotationPart.subTotalDesp }})</span>
      </div>
    </div>
    <div class="pl20 mr30 plan-detail-wrapper">
      <div class="title">Services Include:</div>
      <div class="detail">
        <ul>
          <li v-for="module in quotationPart.itemList" :key="`serve${module.id}`">
            <span class="el-icon-check item-icon"></span>
            <span class="item-name">{{ module.title }}</span>
            <span class="item-price">({{ sub(module.amount, module.thirdPartyFee, module.otherFee) | thousands }})</span>
            <el-popover placement="bottom" trigger="hover" v-if="module.startDate && module.endDate">
              <span class="fs12">{{ module.startDate }} ~ {{ module.endDate }}</span>
              <span slot="reference" class="fs13 question-icon el-icon-question"></span>
            </el-popover>
          </li>
        </ul>
      </div>
    </div>
    <div class="mr30 plan-detail-wrapper">
      <div class="pt40 detail">
        <ul>
          <li>
            <span class="el-icon-check item-icon"></span>
            <span class="item-name">Third party fee:</span>
          </li>
          <li v-for="module in thirdpartysList" :key="`tpf${module.id}`">
            <span class="item-name">- {{ module.noteForThirdParty }}</span>
            <span class="item-price">({{ module.thirdPartyFee | thousands }})</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="plan-detail-wrapper">
      <div class="pt40 detail">
        <ul>
          <li>
            <span class="el-icon-check item-icon"></span>
            <span class="item-name">Other Fee:</span>
          </li>
          <li v-for="module in otherFeeList" :key="`tpf${module.id}`">
            <span class="item-name">- {{ module.noteForOther }}</span>
            <span class="item-price">({{ module.otherFee | thousands }})</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { fmoney, accountAdd, sub } from "@/utils";
export default {
  props: {
    businessType: {
      type: String,
    },
    quotationPart: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  computed: {
    thirdpartysList() {
      return this.mergeArr(JSON.parse(JSON.stringify(this.quotationPart.itemList)), "noteForThirdParty", "thirdPartyFee");
    },
    otherFeeList() {
      return this.mergeArr(JSON.parse(JSON.stringify(this.quotationPart.itemList)), "noteForOther", "otherFee");
    },
    sub() {
      return (...arg) => {
        return sub(...arg);
      };
    },
  },
  methods: {
    mergeArr(arr, key, nub) {
      let newArr = [];
      arr.forEach(item => {
        if (item[key]) {
          let dataItem = item;
          if (newArr.length > 0) {
            let filterValue = newArr.filter(v => {
              return v[key] == dataItem[key];
            });
            if (filterValue.length > 0) {
              newArr.forEach(n => {
                if (n[key] == filterValue[0][key]) {
                  let unit01 = filterValue[0][nub] && parseFloat(filterValue[0][nub]) ? parseFloat(filterValue[0][nub]) : 0;
                  let unit02 = dataItem[nub] && parseFloat(dataItem[nub]) ? parseFloat(dataItem[nub]) : 0;
                  n[nub] = accountAdd(unit01, unit02);
                }
              });
            } else {
              newArr.push(dataItem);
            }
          } else {
            newArr.push(dataItem);
          }
        }
      });
      return newArr;
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-detail-module {
  // width: 1200px;
  display: flex;
  // justify-content: space-between;
  padding: 20px;
  border-radius: 4px;
  background: #fff5f0;
}
.plan-base-wrapper {
  max-width: 500px;
  color: #011a45;
  padding-right: 20px;
  border-right: 1px solid #ede8e3;

  .title {
    font-size: 16px;
    font-weight: bolder;
    line-height: 23px;
  }

  .price {
    color: #ff7a37;
    font-size: 20px;
    font-weight: bolder;
    .origin {
      font-weight: normal;
      font-size: 14px;
      color: grey;
      text-decoration: line-through;
    }
    .tdlt {
      //   text-decoration: line-through;
      color: #bbc6d8;
      font-size: 16px;
    }
  }

  .for-people {
    font-weight: bold;
    line-height: 1em;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
  }

  .summary {
    line-height: 18px;
    margin: 0px;
    padding: 0px;
  }
}

.plan-detail-wrapper {
  .title {
    font-weight: bold;
    color: #011a45;
    font-size: 16px;
  }
  .title-small {
    font-size: 12px;
    color: #011a45;
  }
  .detail {
    // width: 500px;
    display: flex;
    justify-content: space-between;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      // font-size: 16px;
      display: inline-block;
      line-height: 20px;
      .item-icon {
        vertical-align: middle;
        color: #02cc90;
        margin-top: 3px;
        margin-right: 6px;
        font-weight: bold;
      }
      .item-name {
        vertical-align: middle;
        font-size: 12px;
        color: #011a45;
      }
      .item-price {
        vertical-align: middle;
        white-space: nowrap;
        padding-left: 10px;
        color: #011a45;
        font-size: 12px;
      }
      .question-icon {
        vertical-align: middle;
        color: #bbc6d8;
        margin-top: 2px;
        margin-left: 4px;
      }
    }
  }
}
</style>
