var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', {
    staticClass: "mod-dict"
  }, [_c('el-form', {
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    }
  }, [_c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addOrUpdateHandle();
      }
    }
  }, [_vm._v("新增字典")])], 1)], 1), _c('el-card', {
    attrs: {
      "shadow": "nerver"
    }
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.dataList,
      "row-key": "id"
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "name",
      "header-align": "center",
      "width": "240",
      "label": "名称"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "parentName",
      "header-align": "center",
      "align": "center",
      "width": "240",
      "label": "上级目录"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "type",
      "header-align": "center",
      "align": "center",
      "width": "150",
      "label": "类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.type === 0 ? _c('el-tag', {
          attrs: {
            "size": "small"
          }
        }, [_vm._v("目录")]) : scope.row.type === 1 ? _c('el-tag', {
          attrs: {
            "size": "small",
            "type": "success"
          }
        }, [_vm._v("字典")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "orderNum",
      "header-align": "center",
      "align": "center",
      "width": "150",
      "label": "排序号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "dictCode",
      "header-align": "center",
      "align": "center",
      "width": "220",
      "show-overflow-tooltip": true,
      "label": "字典码"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "dictValue",
      "header-align": "center",
      "align": "center",
      "width": "220",
      "label": "字典值"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "remark",
      "header-align": "center",
      "align": "center",
      "label": "备注"
    }
  }), _c('el-table-column', {
    attrs: {
      "fixed": "right",
      "header-align": "center",
      "align": "center",
      "width": "250",
      "label": "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function ($event) {
              return _vm.addOrUpdateHandle(scope.row.id);
            }
          }
        }, [_vm._v("修改")]), _c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteHandle(scope.row.id);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1)], 1), _vm.addOrUpdateVisible ? _c('add-or-update', {
    ref: "addOrUpdate",
    on: {
      "refreshDataList": _vm.getDataList
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }