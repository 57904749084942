<template>
  <div class="page-container">
    <div>
      <el-form class="el-form-filter" :inline="true" :model="dataForm" @keyup.enter.native="getDataList(1)">
        <el-form-item>
          <el-input
            v-model="dataForm.keyword"
            :placeholder="kwPlaceHolder"
            class="wd500 input-with-select-lg"
            :class="{ clientCodeBlankActive: dataForm.noClientCode }"
            :oninput="isEn"
          >
            <el-select v-model="dataForm.kwType" slot="prepend" placeholder="please select" @change="kwTypeChange">
              <el-option v-for="item in kwTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.country" multiple clearable placeholder="Country of Company">
            <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.coordinatorIds" placeholder="select coordinator" multiple clearable>
            <el-option label="unassigned" :value="-1"></el-option>
            <el-option v-for="item in userList" :key="item.value" :label="item.nickname || item.username" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.checkerIds" placeholder="select checker" multiple clearable>
            <el-option label="unassigned" :value="-1"></el-option>
            <el-option v-for="item in userList" :key="item.value" :label="item.nickname || item.username" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.auditorIds" placeholder="select reviewer" multiple clearable>
            <el-option label="unassigned" :value="-1"></el-option>
            <el-option v-for="item in userList" :key="item.value" :label="item.nickname || item.username" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            value-format="timestamp"
            v-model="tradeTimeRange"
            type="daterange"
            range-separator="~"
            start-placeholder="Start Time"
            end-placeholder="End Time"
            style="width: 384px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select-all
            :optionList="applymentStatusScreenList"
            placeholder="Status"
            @elSelectAllChange="
              list => {
                dataForm.applymentStatus = list;
              }
            "
          ></el-select-all>
        </el-form-item>
        <el-form-item>
          <el-select v-model="linkedTransaction" multiple clearable placeholder="Linked Transaction">
            <el-option v-for="item in linkedTransactionList" :key="item.title" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.businessGenre" clearable placeholder="Entity Type">
            <el-option v-for="item in entityTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.riskRating" multiple clearable placeholder="Risk Rating">
            <el-option label="Blank" value="null"></el-option>
            <el-option v-for="item in riskRatingTypes" :key="item.title" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 批量操作 -->
    <div class="mt20">
      <!-- 批量复核 -->
      <el-button
        v-if="isAuth('company:audit')"
        type="primary"
        plain
        size="small"
        icon="el-icon-thumb"
        @click="handleBatchAudit"
        :disabled="dataListSelections.length <= 0"
      >
        Reviewer Finish Approve
      </el-button>
      <!-- 批量退款复核 -->
      <el-button
        v-if="isAuth('company:audit')"
        type="primary"
        plain
        size="small"
        icon="el-icon-thumb"
        @click="handleBatchRefundAudit"
        :disabled="dataListSelections.length <= 0"
      >
        Reviewer Refund Approve
      </el-button>
      <!-- 导出 excel -->
      <el-button
        v-if="isAuth('company:audit')"
        type="primary"
        plain
        size="small"
        icon="el-icon-download"
        @click="handleExportExcel"
        :disabled="dataListSelections.length <= 0"
      >
        Export Excel
      </el-button>
    </div>
    <!-- 列表 -->
    <div class="mt20">
      <el-table
        max-height="600"
        :data="dataList"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        @sort-change="tableSortChange"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        style="width: 100%"
      >
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column fixed="left" prop="applymentNo" align="center" header-align="center" label="Transaction No." min-width="160" />
        <el-table-column prop="createTime" header-align="center" align="center" label="Create Time" width="260" sortable="custom" />
        <el-table-column prop="commitTime" header-align="center" align="center" label="Submit Time" width="260" sortable="custom" />
        <el-table-column prop="terminatedTime" header-align="center" align="center" label="Terminated Time" width="260" sortable="custom" />
        <el-table-column prop="clientCodeList" header-align="center" align="center" label="Client Code" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.clientCodeList && scope.row.clientCodeList.length > 0">{{ scope.row.clientCodeList.join(", ") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="riskAssessmentData.rating"
          header-align="center"
          align="center"
          label="Risk Rating"
          width="102"
          :formatter="riskRatingFormat"
        ></el-table-column>
        <el-table-column prop="applymentStatus" header-align="center" align="center" label="Status" width="200" :formatter="statusFormat" />
        <el-table-column header-align="center" align="center" label="Linked Transaction" width="180" :formatter="linkedTransactionFormat" />
        <el-table-column prop="businessGenre" header-align="center" align="center" label="Entity Type" width="180" :formatter="entityTypeFormat" />
        <el-table-column prop="applicantName" header-align="center" align="center" label="Applicant Name" width="160" />
        <el-table-column prop="applicantEmail" header-align="center" align="center" label="Applicant Email" width="200" />
        <el-table-column prop="country" header-align="center" align="center" label="Country of Company" width="180" />
        <el-table-column
          prop="proposedCompanyName1"
          header-align="center"
          align="center"
          label="Proposed Company Name"
          width="400"
          :formatter="companyNameFormat"
        />
        <el-table-column header-align="center" align="center" label="Billing Party" width="280">
          <template slot-scope="scope">
            <template v-if="scope.row.orderInfoList">
              <div v-for="item in scope.row.orderInfoList" :key="`orderInfoList${item.id}`">{{ item.billingParty }} ({{ item.businessNo }})</div>
            </template>
            <template v-else>
              <template v-if="scope.row.orderInfo && scope.row.orderInfo.billingParty">
                <div>{{ scope.row.orderInfo.billingParty }} ({{ scope.row.orderInfo.businessNo }})</div>
              </template>
            </template>
            <div v-for="item in scope.row.supplementOrderInfoList" :key="`supplementOrderInfoList${item.id}`">
              {{ item.billingParty }} ({{ item.supplementNo }})
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Fund(s) Name" width="400">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.fundInfoList" :key="`fund${item.id}`">{{ index != 0 ? "、" : "" }} {{ item.fundName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="coordinator" header-align="center" align="center" label="Coordinator" width="200" :formatter="auditorFormat" />
        <el-table-column prop="checker" header-align="center" align="center" label="Checker" width="200" :formatter="auditorFormat" />
        <el-table-column prop="auditor" header-align="center" align="center" label="Reviewer" width="200" :formatter="auditorFormat" />
        <el-table-column fixed="right" header-align="center" align="center" width="180" label="Action">
          <template slot-scope="scope">
            <el-button @click="detailHandler(scope.row.id)" type="text" class="fwb">Detail</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <!-- 批量复核 - 弹框 -->
    <batch-audit ref="batchAuditModal" @refreshDataList="getDataList"></batch-audit>
  </div>
</template>
<script>
import { open } from "@/utils";
import axiosInstance from "@/utils/http";
import { mapActions, mapState } from "vuex";
import BatchAudit from "./modal/BatchAudit.vue";
import ElSelectAll from "@/components/ElSelectAll";
export default {
  components: {
    BatchAudit,
    ElSelectAll,
  },
  data() {
    return {
      dataList: [],
      current: 1,
      size: 10,
      total: 0,
      dataListLoading: false,
      dataListSelections: [],
      kwTypes: [
        {
          title: "Transaction No.",
          value: "applymentNo",
          placeholder: "Transaction No.",
        },
        {
          title: "Applicant",
          value: "applicant",
          placeholder: "Applicant Name Or Applicant Email",
        },
        {
          title: "Company Name",
          value: "companyName",
          placeholder: "Company Name",
        },
        {
          title: "Billing Party",
          value: "billingParty",
          placeholder: "Billing Party",
        },
        {
          title: "Fund Name",
          value: "fundsName",
          placeholder: "Fund Name",
        },
        {
          title: "Client Code",
          value: "clientCode",
          placeholder: "Client Code",
        },
        {
          title: "Client Code Blank",
          value: "clientCodeBlank",
          placeholder: "",
        },
      ],
      tradeTimeRange: null,
      linkedTransactionList: [
        { title: "Renewal", value: 1 },
        { title: "Supplemental", value: 2 },
        { title: "Be Renewed", value: 3 },
        { title: "Have Supplements", value: 4 },
      ],
      linkedTransaction: [],
      dataForm: {
        kwType: "applymentNo",
        keyword: "",
        applymentNo: "",
        applicant: "",
        companyName: "",
        fundsName: "",
        coordinatorIds: "",
        checkerIds: "",
        auditorIds: "",
        startTime: null,
        endTime: null,
        applymentStatus: "",
        businessGenre: "",
        noClientCode: null,
        "orders[0].asc": false,
        "orders[0].column": "id",
      },
      userList: [],
    };
  },
  activated() {
    this.getDataList();
    this.getSysUserList();
  },
  computed: {
    ...mapState("constant", ["applymentStatusList", "countryList", "entityTypes", "riskRatingTypes"]),
    kwPlaceHolder() {
      return this.kwTypes.find(i => i.value === this.dataForm.kwType).placeholder;
    },
    applymentStatusScreenList() {
      return this.applymentStatusList.filter(item => item.value != "DRAFT");
    },
  },
  watch: {
    tradeTimeRange(newValue, oldValue) {
      if (newValue) {
        this.dataForm.startTime = newValue[0];
        this.dataForm.endTime = newValue[1];
      } else {
        this.dataForm.startTime = undefined;
        this.dataForm.endTime = undefined;
      }
    },
  },
  methods: {
    // kwType下拉框 - change
    kwTypeChange() {
      if (this.dataForm.kwType == "clientCodeBlank") {
        this.dataForm.keyword = "";
        this.dataForm.noClientCode = true;
      } else {
        this.dataForm.noClientCode = null;
      }
    },
    // table - 排序
    tableSortChange(column) {
      if (column.prop == "createTime") {
        this.dataForm["orders[0].column"] = "id";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "commitTime") {
        this.dataForm["orders[0].column"] = "t1.commit_time";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "terminatedTime") {
        this.dataForm["orders[0].column"] = "t1.terminated_time";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    /* 格式化 */
    companyNameFormat(row, column, cellValue) {
      if (row.companyName) {
        const { companyName } = row;
        return (
          <div>
            <div>{companyName}</div>
          </div>
        );
      } else if (row.proposedCompanyName) {
        const { proposedCompanyName } = row;
        return (
          <div>
            <div>{proposedCompanyName}</div>
          </div>
        );
      } else {
        const { proposedCompanyName1, proposedCompanyName2, proposedCompanyName3 } = row;
        return (
          <div>
            <div>{proposedCompanyName1}</div>
            <div>{proposedCompanyName2}</div>
            <div>{proposedCompanyName3}</div>
          </div>
        );
      }
    },
    statusFormat(row, column, cellValue) {
      const one = this.applymentStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    linkedTransactionFormat(row) {
      return (
        <div>
          <p>{row.hasRenew ? "Renewal" : ""}</p>
          <p>{row.hasLaterService ? "Supplemental" : ""}</p>
          <p>{row.isRenew ? "Be Renewed" : ""}</p>
          <p>{row.isLaterService ? "Have Supplements" : ""}</p>
        </div>
      );
    },
    entityTypeFormat(row, column, cellValue) {
      const one = this.entityTypes.find(i => i.value === cellValue);
      return one?.title;
    },
    riskRatingFormat(row, column, cellValue) {
      const one = this.riskRatingTypes.find(i => i.value == cellValue);
      return one?.title;
    },
    auditorFormat(row, column, cellValue) {
      return cellValue && (cellValue.nickname || cellValue.username);
    },
    /* 格式化 */
    //用户列表
    async getSysUserList() {
      const { data } = await this.$http.get(`/admin/sysUser/getList`);
      this.userList = data;
    },
    //申请单状态
    handleApplymentStatusChange() {
      this.getDataList(1);
    },
    handleCountryChange() {
      this.getDataList(1);
    },
    //详情
    detailHandler(id) {
      this.$router.push(`/companyApplyment/${id}`);
    },
    // 导出 Excel
    handleExportExcel() {
      const ids = this.dataListSelections.map(item => item.id).join(",");
      open(`${axiosInstance.defaults.baseURL}/admin/companyApplymentXlsxManage/download?applymentIds=${ids}`);
    },
    // 批量复核
    handleBatchAudit() {
      let applymentIds = [];
      if (this.dataListSelections.length != 0) {
        this.dataListSelections.forEach(item => {
          if (item.applymentStatus == "AUDITING") {
            applymentIds.push(item.id);
          }
        });
        if (applymentIds.length == 0) {
          this.$message.warning("You can't operate it until the filter of Applyment Status is 'Reviewing'.");
          return false;
        }
      } else {
        this.$message.warning("You are not selected yet");
        return false;
      }
      this.$refs.batchAuditModal.init(applymentIds, "Finish");
    },
    // 批量退款复核
    handleBatchRefundAudit() {
      let applymentIds = [];
      if (this.dataListSelections.length != 0) {
        this.dataListSelections.forEach(item => {
          if (item.applymentStatus === "REFUND_APPLY_AGREED") {
            applymentIds.push(item.id);
          }
        });
        if (applymentIds.length == 0) {
          this.$message.warning("You can't operate it until the filter of Applyment Status is 'Refund Checking Passed'.");
          return false;
        }
      } else {
        this.$message.warning("You are not selected yet");
        return false;
      }
      this.$refs.batchAuditModal.init(applymentIds, "Refund");
    },
    // 获取数据列表
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }

      let linkedTransaction = {};
      if (this.linkedTransaction.includes(1)) linkedTransaction.isRenew = true;
      if (this.linkedTransaction.includes(2)) linkedTransaction.isLaterService = true;
      if (this.linkedTransaction.includes(3)) linkedTransaction.hasRenew = true;
      if (this.linkedTransaction.includes(4)) linkedTransaction.hasLaterService = true;

      const { code, data } = await this.$http.get("/admin/companyApplymentManage/paging", {
        params: {
          current: this.current,
          size: this.size,
          // "orders[0].asc": false,
          // "orders[0].column": "id",
          ...this.dataForm,
          ...linkedTransaction,
          sourceFlag: 1,
          [this.dataForm.kwType]: this.dataForm.keyword,
        },
      });
      if (code === 200) {
        this.dataList = data.records;
        this.total = data.total;
      } else {
        this.dataList = [];
        this.total = 0;
      }
      this.dataListLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.company-name-tag {
  display: inline-flex;
  flex-direction: column;
}

.clientCodeBlankActive {
  width: 200px !important;
  border-right: 1px solid #dcdfe6;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  ::v-deep .el-input-group__prepend + .el-input__inner {
    display: none;
  }
}
</style>
