var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-popover', {
    attrs: {
      "popper-class": "popover-modal",
      "placement": "right",
      "width": "400",
      "trigger": "click"
    },
    on: {
      "show": _vm.init
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('div', {
    staticClass: "module-detail-box"
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "label-width": "100px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "模块名称",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": "",
      "placeholder": ""
    },
    model: {
      value: _vm.data.name,
      callback: function ($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1), _vm.checkVisiable('storage') ? _c('el-form-item', {
    attrs: {
      "label": "存储",
      "prop": "storage"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number",
      "placeholder": ""
    },
    model: {
      value: _vm.dataForm.storage,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "storage", $$v);
      },
      expression: "dataForm.storage"
    }
  })], 1) : _vm._e(), _vm.checkVisiable('bandwidth') ? _c('el-form-item', {
    attrs: {
      "label": "流量",
      "prop": "bandwidth"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number",
      "placeholder": ""
    },
    model: {
      value: _vm.dataForm.bandwidth,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "bandwidth", $$v);
      },
      expression: "dataForm.bandwidth"
    }
  })], 1) : _vm._e(), _vm.checkVisiable('quantity') ? _c('el-form-item', {
    attrs: {
      "label": "数量",
      "prop": "quantity"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number",
      "placeholder": ""
    },
    model: {
      value: _vm.dataForm.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "quantity", $$v);
      },
      expression: "dataForm.quantity"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "footer text-right"
  }, [_c('el-button', {
    attrs: {
      "size": "mini",
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("确认")]), _c('el-button', {
    attrs: {
      "size": "mini"
    },
    on: {
      "click": _vm.closePopover
    }
  }, [_vm._v("取消")])], 1)], 1)], 1), _c('template', {
    slot: "reference"
  }, [_vm._t("default")], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }