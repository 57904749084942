var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "plan-detail-module"
  }, [_c('div', {
    staticClass: "plan-base-wrapper"
  }, [_vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('div', {
    staticClass: "title"
  }, [_vm._v("Comprehensive Corporate Services (customized)")]) : _vm._e(), _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('div', {
    staticClass: "title"
  }, [_vm._v("Fund Admin Services (customized)")]) : _vm._e(), _c('div', {
    staticClass: "price"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("thousands")(_vm.quotationPart.subTotalPrice)))]), _c('span', {
    staticClass: "ml6 tdlt"
  }, [_vm._v("(" + _vm._s(_vm.quotationPart.subTotalDesp) + ")")])])]), _c('div', {
    staticClass: "pl20 mr30 plan-detail-wrapper"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Services Include:")]), _c('div', {
    staticClass: "detail"
  }, [_c('ul', _vm._l(_vm.quotationPart.itemList, function (module) {
    return _c('li', {
      key: `serve${module.id}`
    }, [_c('span', {
      staticClass: "el-icon-check item-icon"
    }), _c('span', {
      staticClass: "item-name"
    }, [_vm._v(_vm._s(module.title))]), _c('span', {
      staticClass: "item-price"
    }, [_vm._v("(" + _vm._s(_vm._f("thousands")(_vm.sub(module.amount, module.thirdPartyFee, module.otherFee))) + ")")]), module.startDate && module.endDate ? _c('el-popover', {
      attrs: {
        "placement": "bottom",
        "trigger": "hover"
      }
    }, [_c('span', {
      staticClass: "fs12"
    }, [_vm._v(_vm._s(module.startDate) + " ~ " + _vm._s(module.endDate))]), _c('span', {
      staticClass: "fs13 question-icon el-icon-question",
      attrs: {
        "slot": "reference"
      },
      slot: "reference"
    })]) : _vm._e()], 1);
  }), 0)])]), _c('div', {
    staticClass: "mr30 plan-detail-wrapper"
  }, [_c('div', {
    staticClass: "pt40 detail"
  }, [_c('ul', [_vm._m(0), _vm._l(_vm.thirdpartysList, function (module) {
    return _c('li', {
      key: `tpf${module.id}`
    }, [_c('span', {
      staticClass: "item-name"
    }, [_vm._v("- " + _vm._s(module.noteForThirdParty))]), _c('span', {
      staticClass: "item-price"
    }, [_vm._v("(" + _vm._s(_vm._f("thousands")(module.thirdPartyFee)) + ")")])]);
  })], 2)])]), _c('div', {
    staticClass: "plan-detail-wrapper"
  }, [_c('div', {
    staticClass: "pt40 detail"
  }, [_c('ul', [_vm._m(1), _vm._l(_vm.otherFeeList, function (module) {
    return _c('li', {
      key: `tpf${module.id}`
    }, [_c('span', {
      staticClass: "item-name"
    }, [_vm._v("- " + _vm._s(module.noteForOther))]), _c('span', {
      staticClass: "item-price"
    }, [_vm._v("(" + _vm._s(_vm._f("thousands")(module.otherFee)) + ")")])]);
  })], 2)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('span', {
    staticClass: "el-icon-check item-icon"
  }), _c('span', {
    staticClass: "item-name"
  }, [_vm._v("Third party fee:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('span', {
    staticClass: "el-icon-check item-icon"
  }), _c('span', {
    staticClass: "item-name"
  }, [_vm._v("Other Fee:")])]);

}]

export { render, staticRenderFns }