var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1100px",
      "title": !_vm.dataForm.id ? 'New Protocol' : 'Edit Protocol',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "122px"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.dataFormSubmit();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Protocol Type",
      "prop": "type"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "450px"
    },
    attrs: {
      "placeholder": "Select type"
    },
    model: {
      value: _vm.dataForm.type,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "type", $$v);
      },
      expression: "dataForm.type"
    }
  }, _vm._l(_vm.protocolTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Content",
      "prop": "content"
    }
  }, [_c('el-editor', {
    attrs: {
      "height": 500,
      "type": "pure"
    },
    model: {
      value: _vm.dataForm.content,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "content", $$v);
      },
      expression: "dataForm.content"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "File",
      "prop": "payNowUen"
    }
  }, [_c('el-file-uploader', {
    model: {
      value: _vm.dataForm.fileUrl,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fileUrl", $$v);
      },
      expression: "dataForm.fileUrl"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Content(CN)",
      "prop": "contentCn"
    }
  }, [_c('el-editor', {
    attrs: {
      "height": 500,
      "type": "pure"
    },
    model: {
      value: _vm.dataForm.contentCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "contentCn", $$v);
      },
      expression: "dataForm.contentCn"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "File(CN)",
      "prop": "payNowUen"
    }
  }, [_c('el-file-uploader', {
    model: {
      value: _vm.dataForm.fileUrlCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fileUrlCn", $$v);
      },
      expression: "dataForm.fileUrlCn"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }