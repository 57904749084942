<template>
  <div class="desp">
    <div class="desp-header">Confirmation:</div>
    <div class="desp-items-group">
      <div class="desp-items-group-title-icon">
        <svg-icon name="applicant" class="jump-icon"></svg-icon>
        Applicant information
      </div>
      <div class="desp-items-wrapper">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Applicant Name</div>
              <div class="desp-item-content" v-if="detail.companyApplyment">{{ detail.companyApplyment.applicantName }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Mobile</div>
              <div class="desp-item-content" v-if="detail.companyApplyment">{{ detail.companyApplyment.applicantPhone }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Email</div>
              <div class="desp-item-content" v-if="detail.companyApplyment">{{ detail.companyApplyment.applicantEmail }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Residential Address</div>
              <div class="desp-item-content" v-if="detail.companyApplyment">{{ detail.companyApplyment.residentialAddress }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="desp-item">
              <div class="desp-item-label">Applicant role</div>
              <div class="desp-item-content" v-if="detail.companyApplyment">
                {{ applicantPositionFormat(detail.companyApplyment.applicantPosition) }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title-icon">
        <svg-icon name="country" class="jump-icon"></svg-icon>
        Location of the proposed company
      </div>
      <div class="desp-item">
        <div class="desp-item-self" v-if="detail.companyApplyment">{{ detail.companyApplyment.country }}</div>
      </div>
    </div>
    <!-- 套餐 - 普通订单 -->
    <div class="desp-items-group" v-if="detail.orderType == 'generic'">
      <div class="desp-items-group-title-icon">
        <svg-icon name="planAndServices" class="jump-icon"></svg-icon>
        Choice of service(s)
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="mt10">
            <!-- 定制化服务 -->
            <customized-detail
              :businessType="detail.businessType"
              :quotationPart="detail.quotationPart"
              v-if="detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType == 'FUND_ADMIN_CUSTOMIZED'"
            ></customized-detail>
            <!-- 公司设立、服务转移 -->
            <plan-detail :businessType="detail.businessType" :goodsList="detail.goodsList" v-else />
          </div>
        </div>
      </div>
    </div>
    <!-- 套餐 - 补款单 -->
    <div class="desp-items-group" v-if="detail.orderType == 'amends'">
      <div class="desp-items-group-title">Supplemental</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="mt10">
            <el-table
              :header-cell-style="{ color: '#011A45', background: '#fff5f0 !important' }"
              :row-style="{ background: '#fff5f0' }"
              :data="amendsList"
              class="amendsTable"
            >
              <el-table-column align="center" label="Amount">
                <template slot-scope="scope">
                  <span class="price">{{ scope.row.priceSg | thousands }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="Third party fee">
                <template slot-scope="scope">
                  <span class="price">{{ scope.row.thirdPartyFee | thousands }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="description" label="Description (For External Disclosure)"></el-table-column>

              <el-table-column align="center" label="Other Fee">
                <template slot-scope="scope">
                  <span class="price">{{ scope.row.otherFee | thousands }}</span>
                  <el-popover placement="bottom" trigger="hover" v-if="scope.row.otherRemark">
                    <span class="fs12">{{ scope.row.otherRemark }}</span>
                    <span slot="reference" class="ml8 fs14 blackD8 el-icon-question"></span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column align="center" label="Fee Letter">
                <template slot-scope="scope">
                  <a :href="scope.row.feeLetter.url" target="_blank" class="fs12 blue mr10" v-if="scope.row.feeLetter">
                    {{ scope.row.feeLetter.name }}
                  </a>
                </template>
              </el-table-column>
              <el-table-column align="center" label="Supplemental Invoice">
                <template>
                  <span class="to-link" @click="invoiceLink('amends')" v-if="detail.orderNo">Invoice</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="status" label="Status" :formatter="statusFormat"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 套餐 - Quotation (V) -->
    <div class="desp-items-group" v-if="detail.orderType == 'supplement'">
      <div class="desp-items-group-title">Quotation (V)</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="mt10">
            <el-table
              :header-cell-style="{ color: '#011A45', background: '#fff5f0 !important', height: '62px' }"
              :row-style="{ background: '#fff5f0' }"
              :data="quotationVList"
              class="amendsTable"
            >
              <el-table-column prop="supplementNo" header-align="center" align="center" label="Quotation (V) No."></el-table-column>
              <el-table-column prop="businessNo" header-align="center" align="center" label="Linked Transaction No." min-width="90"></el-table-column>
              <el-table-column prop="billingParty" header-align="center" align="center" label="Billing Party"></el-table-column>
              <el-table-column header-align="center" align="center" label="Exchange Rate" min-width="80">
                <template slot-scope="scope">
                  <template v-if="scope.row.paymentDetail">
                    <p v-if="scope.row.paymentDetail.currency != 'SGD'">{{ scope.row.paymentDetail.exchangeRate }}</p>
                  </template>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" label="Amount">
                <template slot-scope="scope">
                  <span class="price" v-if="scope.row.paymentDetail">
                    {{ scope.row.paymentDetail.amount | thousands(scope.row.paymentDetail.currencyUnit) }}
                  </span>
                  <el-popover placement="bottom" trigger="hover" v-if="scope.row.applyReason">
                    <span class="fs12">{{ scope.row.applyReason }}</span>
                    <span slot="reference" class="fs14 blackD8 el-icon-question"></span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" label="Third party fee">
                <template slot-scope="scope">
                  <span class="price" v-if="scope.row.paymentDetail">
                    {{ scope.row.paymentDetail.thirdPartyFee | thousands(scope.row.paymentDetail.currencyUnit) }}
                  </span>
                  <el-popover placement="bottom" trigger="hover" v-if="scope.row.noteForThirdParty">
                    <span class="fs12">{{ scope.row.noteForThirdParty }}</span>
                    <span slot="reference" class="fs14 blackD8 el-icon-question"></span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" label="Other Fee">
                <template slot-scope="scope">
                  <span class="price" v-if="scope.row.paymentDetail">
                    {{ scope.row.paymentDetail.otherFee | thousands(scope.row.paymentDetail.currencyUnit) }}
                  </span>
                  <el-popover placement="bottom" trigger="hover" v-if="scope.row.noteForOther">
                    <span class="fs12">{{ scope.row.noteForOther }}</span>
                    <span slot="reference" class="fs14 blackD8 el-icon-question"></span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" label="Attachments" min-width="96">
                <template slot-scope="scope">
                  <p v-for="item in scope.row.attachmentUrls" :key="item.url">
                    <a :href="item.url" target="_target">{{ item.name }}</a>
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" header-align="center" align="center" label="Create Time"></el-table-column>
              <el-table-column align="center" label="Generic Invoice">
                <template>
                  <span class="to-link" @click="invoiceLink('quotationV')" v-if="detail.orderNo">Invoice</span>
                </template>
              </el-table-column>
              <el-table-column prop="status" header-align="center" align="center" label="Status" :formatter="supplementStatusFormat"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- 订单 -->
    <div class="desp-items-group">
      <div class="desp-items-group-title">Order</div>
      <div class="desp-items-wrapper">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Transaction No.</div>
              <div class="desp-item-content" v-if="detail.companyApplyment">{{ detail.companyApplyment.applymentNo }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Invoice No.</div>
              <div class="desp-item-content">{{ detail.orderNo }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Create Time</div>
              <div class="desp-item-content" v-if="detail.companyApplyment">{{ detail.companyApplyment.createTime }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Coordinator</div>
              <div class="desp-item-content" v-if="detail.companyApplyment && detail.companyApplyment.coordinator">
                {{ detail.companyApplyment.coordinator.nickname }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Checker</div>
              <div class="desp-item-content" v-if="detail.companyApplyment && detail.companyApplyment.checker">
                {{ detail.companyApplyment.checker.nickname }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Reviewer</div>
              <div class="desp-item-content" v-if="detail.companyApplyment && detail.companyApplyment.auditor">
                {{ detail.companyApplyment.auditor.nickname }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Pay Type</div>
              <div class="desp-item-content">{{ payTypesFormat(detail.payType) }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Transfer Remark</div>
              <div class="desp-item-content">{{ detail.transferVerifyRemark }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 发票 - 普通订单 -->
    <div class="desp-items-group" v-if="detail.orderType == 'generic'">
      <div class="desp-items-group-title">Generic Invoice</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span
            class="mr40 to-link"
            @click="invoiceLink('unpaid')"
            v-if="
              detail.companyApplyment &&
              detail.companyApplyment.applymentStatus != 'DRAFT' &&
              detail.companyApplyment.applymentStatus != 'INQUIRING' &&
              detail.companyApplyment.applymentStatus != 'QUOTING' &&
              detail.companyApplyment.applymentStatus != 'QUOTATION_AUDITING' &&
              detail.companyApplyment.applymentStatus != 'QUOTATION_AUDIT_PASS' &&
              detail.companyApplyment.applymentStatus != 'QUOTATION_AUDIT_FAIL' &&
              detail.orderNo
            "
          >
            Invoice
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
          <span
            class="to-link"
            @click="invoiceLink('refund')"
            v-if="
              detail.refundDetail &&
              detail.refundDetail.refundNo &&
              detail.companyApplyment &&
              (detail.companyApplyment.applymentStatus == 'REFUND_APPLY_PASS' || detail.companyApplyment.applymentStatus == 'CANCELED')
            "
          >
            Debit Invoice
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
        </div>
      </div>
    </div>

    <!-- 上传的发票 -->
    <div class="desp-items-group" v-if="detail.status == 'COMPLETE' && detail.isInvoiceRevised">
      <div class="desp-items-group-title">Revised Invoice</div>
      <div class="desp-item">
        <div class="desp-item-self" v-if="detail.revisedInvoiceFile">
          <a class="to-link" :href="detail.revisedInvoiceFile.url" target="_target">
            {{ detail.revisedInvoiceFile.name }}
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fmoney } from "@/utils";
import PlanDetail from "@/components/PlanDetail";
import CustomizedDetail from "@/components/PlanDetail/customized";
import { mapState } from "vuex";
export default {
  components: {
    PlanDetail,
    CustomizedDetail,
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          companyApplyment: {
            applymentStatus: null,
            applicantName: null,
            applicantPhone: null,
            applicantEmail: null,
            applicantPosition: null,
            country: null,
            coordinator: {
              nickname: null,
            },
            checker: {
              nickname: null,
            },
            auditor: {
              nickname: null,
            },
          },
          goodsList: [],
          orderNo: null,
          payType: null,
        };
      },
    },
    amendsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      dataListLoading: false,
    };
  },
  filters: {
    thousands(val, symbol = "S$") {
      if (val || val == 0) {
        return `${symbol}${fmoney(val, 2)}`;
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapState("constant", [
      "applymentStatusList",
      "orderStatusList",
      "refundStatusList",
      "payTypes",
      "applicantPositionOptions",
      "supplementStatusList",
    ]),
    quotationVList() {
      if (!this.detail.supplement) return [];
      return [this.detail.supplement];
    },
  },
  methods: {
    // 格式化
    statusFormat(row, column, cellValue) {
      const one = this.orderStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    // 申请单角色格式化
    applicantPositionFormat(v) {
      const one = this.applicantPositionOptions.find(i => i.value === v);
      return one?.title;
    },
    //支付类型格式化
    payTypesFormat(v) {
      const one = this.payTypes.find(i => i.value === v);
      return one?.title;
    },
    // 状态格式化
    supplementStatusFormat(row, column, cellValue) {
      const one = this.supplementStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    // 发票跳转
    invoiceLink(type) {
      let idRes = null;
      if (type == "unpaid") {
        idRes = this.detail.orderNo || null;
      } else if (type == "refund") {
        idRes = this.detail.refundDetail && this.detail.refundDetail.refundNo ? this.detail.refundDetail.refundNo : null;
      } else if (type == "amends") {
        idRes = this.detail.orderNo || null;
      } else if (type == "quotationV") {
        idRes = this.detail.orderNo || null;
      }
      let newpage = `${process.env.VUE_APP_URL_INVOICE}/${type}?invoiceNo=${idRes}&lang=en`;
      window.open(newpage, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.blackD8 {
  color: #bbc6d8;
}

.jump-icon {
  vertical-align: middle;
  font-size: 20px;
  margin-right: 6px;
  margin-top: -4px;
}
.to-link {
  cursor: pointer;
  color: #{$--color-primary};
}

.price {
  color: #ff7a37;
  font-size: 16px;
  font-weight: bolder;
}

.amendsTable {
  tr:hover {
    background: #fff5f0 !important;
  }
  .el-table__body tr:hover > td {
    background-color: #fff5f0 !important;
  }
}
</style>
