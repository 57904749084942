<template>
  <el-dialog width="1200px" title="Select Nominee Director" :close-on-click-modal="false" :visible.sync="visible" append-to-body>
    <div>
      <el-table :data="nomineeDirectorList" header-cell-class-name="table-header" @current-change="handleCurrentChange">
        <el-table-column width="60px" align="center" header-align="center">
          <template slot-scope="scope">
            <el-radio :label="scope.row" v-model="currRow" style="margin-left: 10px">{{ "" }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="Name" prop="fullName" align="center"></el-table-column>
        <el-table-column label="Nationality" prop="nationality" align="center"></el-table-column>
        <el-table-column label="Phone" prop="phone" align="center"></el-table-column>
        <el-table-column label="Email" prop="email" align="center"></el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      visible: false,
      nomineeDirectorList: [],
      currRow: undefined,
      id: undefined,
      applymentId: undefined,
    };
  },
  computed: {
    ...mapState("constant", ["nationalityList"]),
  },
  methods: {
    ...mapActions("applyment", ["doSaveRevising"]),
    async init(id) {
      this.visible = true;
      await this.$nextTick();
      this.initDirectorList();
      if (id) {
        this.id = id;
      }
    },
    handleCurrentChange(row) {
      this.currRow = row;
    },
    // 获取名义董事列表
    async initDirectorList() {
      const { data } = await this.$http.get("/admin/stNomineeDirectorManage/getList");
      this.nomineeDirectorList = data;
    },
    dataFormSubmit() {
      this.$emit("change", {
        id: this.id,
        nomineeDirectorId: this.currRow.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
