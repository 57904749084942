<template>
  <div>
    <el-dialog title="Initiate Refund Application" :close-on-click-modal="false" :visible.sync="visible" width="1200px">
      <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="150px">
        <el-form-item label="Choice of service(s) ">
          <span class="item-text">Customized</span>
        </el-form-item>
        <el-form-item label="Country">
          <span class="item-text">{{ detail.country }}</span>
        </el-form-item>
        <el-form-item label="Application No">
          <span class="item-text">{{ detail.applymentNo }}</span>
        </el-form-item>
        <el-form-item label="Applicant">
          <span class="item-text">{{ detail.applicantName }}</span>
        </el-form-item>
        <el-form-item label="Select refund order">
          <el-select v-model="dataForm.refundInfoList" value-key="orderId" multiple placeholder="Please select" class="fluid-width">
            <el-option v-for="(item, index) in refundOrderList" :key="`refundOrderList${index}`" :label="item.subTotalDesp" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-table ref="multipleTable" :data="dataForm.refundInfoList" :key="Math.random()" v-if="dataForm.refundInfoList.length > 0">
            <el-table-column label="Description" prop="subTotalDesp" header-align="center" align="center"></el-table-column>
            <el-table-column label="Order Amount" prop="subTotalPrice" header-align="center" align="center">
              <template slot-scope="scope">
                <p class="fs14">{{ scope.row.subTotalPrice | thousands }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Acra Fee" prop="refundDetail.acraFee" header-align="center" align="center">
              <template slot-scope="scope">
                <p class="fs14 price">{{ scope.row.refundDetail.acraFee | thousands }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Handling Fee" prop="refundDetail.handlingFee" header-align="center" align="center">
              <template slot-scope="scope">
                <p class="fs14 price">{{ scope.row.refundDetail.handlingFee | thousands }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Remark" prop="remark" header-align="center" align="center"></el-table-column>
            <el-table-column label="attachments" header-align="center" align="center">
              <template slot-scope="scope">
                <p v-for="(item, idx) in scope.row.attachmentUrls" :key="`attachmentUrls${idx}`" class="fs12 m0">
                  <a :href="item.url" target="_blank">{{ item.name }}</a>
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Action" header-align="center" align="center" width="100px">
              <template slot-scope="scope">
                <el-button type="text" @click="editBrief(scope.row, scope.$index)">Edit</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">Cancel</el-button>
        <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
      </span>
    </el-dialog>

    <!-- 添加退款金额 -->
    <el-dialog title="Fill In The Refund Amount" :close-on-click-modal="false" :visible.sync="visibleEdit">
      <el-form :model="editForm" ref="editForm" autocomplete="off" label-width="150px">
        <el-form-item label="Acra Fee" prop="refundDetail.acraFee">
          <el-input v-model="editForm.refundDetail.acraFee" type="number" placeholder="Acra Fee"></el-input>
        </el-form-item>
        <el-form-item label="Handling Fee" prop="refundDetail.handlingFee">
          <el-input v-model="editForm.refundDetail.handlingFee" type="number" placeholder="Handling Fee"></el-input>
        </el-form-item>
        <el-form-item label="Remark" prop="remark">
          <el-input
            v-model="editForm.remark"
            type="textarea"
            :rows="5"
            :autosize="{ minRows: 4 }"
            resize="none"
            placeholder="Remark"
            :oninput="isEn"
          ></el-input>
        </el-form-item>
        <el-form-item label="attachments" prop="attachmentUrls">
          <el-multiple-file-uploader v-model="editForm.attachmentUrls"></el-multiple-file-uploader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editFormSubmit()">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fmoney } from "@/utils";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          id: null,
          quotationList: [],
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      refundOrderList: [],
      dataForm: {
        refundInfoList: [
          // {
          //   orderId: null,
          //   subTotalDesp: "",
          //   subTotalPrice: "",
          //   attachmentUrls: [],
          //   remark: "",
          //   refundDetail: {
          //     acraFee: null,
          //     handlingFee: null,
          //   },
          // },
        ],
      },
      submitControl: true, // 防止重复提交
      visibleEdit: false,
      editForm: {
        idx: null,
        refundDetail: {
          acraFee: null,
          handlingFee: null,
        },
        remark: "",
        attachmentUrls: [],
      },
    };
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapState("constant", ["stepStatusList"]),
    unselectableStatus() {
      return this.stepStatusList.find(i => i.unselectable);
    },
  },
  methods: {
    ...mapActions("applyment", ["doApplyRefundList"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.refundOrderList = [];
      const detail = JSON.parse(JSON.stringify(this.detail));
      if (detail.quotationList) {
        const quotationList = detail.quotationList;
        for (let i in quotationList) {
          const quotationObj = quotationList[i];
          for (let j in quotationObj.partList) {
            const partObj = quotationObj.partList[j];
            if (partObj.orderStatus == "COMPLETE") {
              const orderObj = partObj.invoiceList.find(item => item.invoiceType == 1 && item.orderId);
              this.refundOrderList.push({
                orderId: orderObj.orderId,
                subTotalDesp: partObj.subTotalDesp,
                subTotalPrice: parseFloat(partObj.subTotalPrice),
                refundDetail: {
                  acraFee: null,
                  handlingFee: null,
                },
                remark: "",
                attachmentUrls: [],
              });
            }
          }
        }
      }
    },
    // 编辑
    editBrief(row, index) {
      this.visibleEdit = true;
      const rowCopy = JSON.parse(JSON.stringify(row));
      this.editForm = {
        idx: index,
        refundDetail: rowCopy.refundDetail,
        remark: rowCopy.remark,
        attachmentUrls: rowCopy.attachmentUrls,
      };
    },
    // ok
    editFormSubmit() {
      let dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
      let editFormCopy = JSON.parse(JSON.stringify(this.editForm));
      dataFormCopy.refundInfoList[editFormCopy.idx].refundDetail = editFormCopy.refundDetail;
      dataFormCopy.refundInfoList[editFormCopy.idx].remark = editFormCopy.remark;
      dataFormCopy.refundInfoList[editFormCopy.idx].attachmentUrls = editFormCopy.attachmentUrls;
      this.$set(this.dataForm, "refundInfoList", dataFormCopy.refundInfoList);
      this.$nextTick(() => {
        this.visibleEdit = false;
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          // 数据处理
          let refundInfoListCopy = JSON.parse(JSON.stringify(this.dataForm.refundInfoList));
          for (let i in refundInfoListCopy) {
            delete refundInfoListCopy[i].subTotalPrice;
          }
          this.doApplyRefundList({
            applymentId: this.detail.id,
            refundInfoList: refundInfoListCopy,
          })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.item-text {
  line-height: 40px;
  color: #41464b;
}
.price {
  color: #ff8653;
}
</style>
