<template>
  <el-dialog title="Add Promotion Code" :close-on-click-modal="false" :visible.sync="visible" width="600px">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="140px">
      <el-form-item label="Promotion Code" prop="code">
        <el-input v-model="dataForm.code" placeholder="Promotion Code" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Status" prop="enabled">
        <el-radio-group v-model="dataForm.enabled">
          <el-radio :label="0">Disable</el-radio>
          <el-radio :label="1">Enable</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input v-model="dataForm.remark" type="textarea" :rows="5" :autosize="{ minRows: 4 }" resize="none" placeholder="Remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        code: "",
        enabled: 1,
        remark: "",
      },
      dataRule: {
        code: [{ required: true, message: "Please enter", trigger: "submit" }],
        enabled: [{ required: true, message: "Please select", trigger: "submit" }],
        // remark: [{ required: true, message: "Please enter", trigger: "submit" }],
      },
      submitControl: true,
    };
  },
  methods: {
    async init(id) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.$http.post("/admin/promotionManage/create", this.dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
