<template>
  <el-dialog :title="type == 'check' ? 'Refund Check' : 'Refund Review'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="140px">
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">Rejected</el-radio>
          <el-radio :label="1">Pass</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
      <el-form-item label="attachments" prop="attachmentUrls">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        status: 1,
        remark: "",
        attachmentUrls: [],
      },
      type: "",
      dataRule: {},
      submitControl: true, // 防止重复提交
    };
  },
  computed: {
    ...mapState("constant", ["stepStatusList"]),
    unselectableStatus() {
      return this.stepStatusList.find(i => i.unselectable);
    },
  },
  methods: {
    ...mapActions("applyment", ["doSaveProcedures", "doRefundCheck", "doRefundAudit"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.type = data;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          let httpRes = null;
          let dataFormCopy = this.dataForm;
          if (this.type == "check") {
            let applymentStatus = dataFormCopy.status ? "REFUND_APPLY_AGREED" : "REFUND_APPLY_DENY";
            httpRes = this.doRefundCheck({ applymentStatus: applymentStatus, remark: dataFormCopy.remark, attachmentUrls: dataFormCopy.attachmentUrls });
          } else {
            let applymentStatus = dataFormCopy.status ? "REFUND_APPLY_PASS" : "REFUND_APPLY_REJECT";
            httpRes = this.doRefundAudit({ applymentStatus: applymentStatus, remark: dataFormCopy.remark, attachmentUrls: dataFormCopy.attachmentUrls });
          }

          httpRes
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
