<template>
  <svg :class="'icon-svg icon-svg__' + name" aria-hidden="true">
    <use :xlink:href="icon"></use>
  </svg>
</template>

<script>
export default {
  name: "icon-svg",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      return `#icon-${this.name}`;
    },
  },
};
</script>

<style>
.icon-svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}
</style>
