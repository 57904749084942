<template>
  <el-dialog append-to-body width="870px" title="Upload Files" :close-on-click-modal="false" :visible.sync="visible">
    <div class="upload-container">
      <template v-if="fileList.length === 0">
        <div class="upload-button">
          <div>
            <svg-icon class="upload-icon" name="upload" />
            <div class="upload-text">click to add files</div>
          </div>
        </div>
        <input
          type="file"
          ref="fileupload1"
          multiple
          :accept="accept"
          style="opacity: 0; width: 100%; height: 100%; position: absolute; left: 0; top: 0"
          @change="onFileChange"
        />
      </template>
      <template v-else>
        <div class="file-preview" v-loading="uploading">
          <el-table :data="fileList" v-loading="dataListLoading" style="width: 100%">
            <el-table-column prop="name" width="300" show-overflow-tooltip label="File Name"></el-table-column>
            <el-table-column prop="size" label="Size" align="center" :formatter="fileSizeFormat"></el-table-column>
            <el-table-column fixed="right" align="center" min-width="200" label="Action">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.$index)">Remove</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="add-file-btn">
          <div>
            <svg-icon class="upload-more-icon" name="upload" />
            <div class="upload-text">add more files</div>
          </div>
          <input
            type="file"
            ref="fileupload2"
            multiple
            :accept="accept"
            style="opacity: 0; width: 100%; height: 100%; position: absolute; left: 0; top: 0"
            @change="onFileChange"
          />
        </div>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button style="margin-left: 20px" type="primary" @click="dataFormSubmit()" :disabled="uploading || !checked">Upload</el-button>
    </span>
    <div class="pb20"></div>
  </el-dialog>
</template>

<script>
import { formatBytes } from "@/utils";
import uploader from "@/utils/uploader";
export default {
  props: {
    accept: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      dataListLoading: false,
      uploading: false,
      checked: false,
      visible: false,
      fileList: [],
    };
  },
  methods: {
    fileSizeFormat(column, row, v) {
      if (v) {
        return formatBytes(v);
      } else {
        return "-";
      }
    },
    init(data) {
      if (data) {
        this.fileList = data;
      } else {
        this.fileList = [];
      }
      //将原生 FileList 清空
      if (this.$refs.fileupload1) {
        this.$refs.fileupload1.value = null;
      }
      if (this.$refs.fileupload2) {
        this.$refs.fileupload2.value = null;
      }
      this.visible = true;
      this.checked = false;
    },
    onFileChange(e) {
      this.checked = false;
      let atFiles = e.currentTarget.files;
      for (let i in atFiles) {
        if (atFiles[i].size > 5 * 1024 * 1024) {
          this.$message.error("fail, maximum file size is 5M");
          this.preview = false;
          return;
        }
      }
      this.fileList = [...this.fileList, ...atFiles];
      this.checked = true;
    },
    deleteRow(index) {
      const one = this.fileList[index];
      this.fileList = this.fileList.filter(i => i !== one);
      this.$emit("uploadFinish", this.fileList);
    },
    // 表单提交
    async dataFormSubmit() {
      const oldFiles = this.fileList.filter(i => i.url);
      const uploadFiles = this.fileList.filter(i => !i.url);
      if (uploadFiles.length === 0) {
        return;
      }
      this.uploading = true;
      const formData = new FormData();
      uploadFiles.forEach(file => {
        formData.append("file", file);
      });
      uploader
        .rawUpload(formData)
        .then(res => {
          this.$message({
            message: "upload success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.$emit("uploadFinish", [...oldFiles, ...res.data.urls]);
            },
          });
          this.uploading = false;
          this.checked = false;
        })
        .catch(err => {
          this.$message.error(err);
          this.uploading = false;
          this.checked = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-container {
  margin: 0 auto;
  background-color: #fff;
  width: 95%;
  min-height: 235.5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  input[type="file"] {
    cursor: pointer;
  }
}
.upload-container {
  .file-preview {
    display: flex;
  }
}
.upload-button {
  border: 1px dashed #d9d9d9 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  .upload-icon {
    font-size: 67px;
    color: #c0c4cc;
    line-height: 50px;
    text-align: center;
    margin: 0 auto 16px;
    display: block;
  }
  .upload-text {
    color: #606266;
    font-size: 14px;
    text-align: center;
  }
}
.upload-more-icon {
  font-size: 30px;
  color: #c0c4cc;
  line-height: 30px;
  text-align: center;
  margin: 0 auto 5px;
  display: block;
}
.file-preview {
  width: 100%;
}
.add-file-btn {
  display: block;
  position: relative;
  text-align: center;
  margin-top: 20px;

  .add-file-txt {
    margin-left: 20px;
  }
}
</style>
