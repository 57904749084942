var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "assets-file-picker"
  }, [_c('div', {
    staticClass: "file-preview"
  }, [_vm.value && _vm.value.name ? _c('div', {
    staticClass: "left mr20"
  }, [_c('div', {
    staticClass: "preview-file"
  }, [_c('a', {
    attrs: {
      "href": _vm.value.url,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.value.name))])])]) : _vm._e(), _c('div', {
    staticClass: "right",
    on: {
      "click": function ($event) {
        return _vm.openUpload();
      }
    }
  }, [_vm._t("default", function () {
    return [_c('el-button', [_vm._v("Upload")])];
  })], 2)]), _c('input', {
    staticClass: "hidden",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.value
    }
  }), _vm.uploadVisible ? _c('upload', {
    ref: "Upload",
    on: {
      "uploadFinish": _vm.handleFinish
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }