<template>
  <div class="pd26 mb30 min-hg460 bg-white border-radius-10" v-if="isAuth('finance:financial')">
    <h3 class="flex flex-direction-row align-center justify-between home-title">
      <span>Total Unpaid</span>
      <span>Total Turnover</span>
    </h3>
    <div class="mt10">
      <div class="flex flex-direction-row align-center justify-between home-title">
        <p class="text-left aurantia symbol-price">
          S$
          <span class="total-price" v-if="atUnpaidAmountType == 1">{{ unpaidMonthObj.transactionSum | thousands }}</span>
          <span class="total-price" v-if="atUnpaidAmountType == 2">{{ unpaidQuarterlyObj.transactionSum | thousands }}</span>
          <span class="total-price" v-if="atUnpaidAmountType == 0">{{ unpaidYearObj.transactionSum | thousands }}</span>
        </p>
        <p class="text-right cyan symbol-price">
          S$
          <span class="total-price" v-if="atUnpaidAmountType == 1">{{ unpaidMonthObj.turnoverSum | thousands }}</span>
          <span class="total-price" v-if="atUnpaidAmountType == 2">{{ unpaidQuarterlyObj.turnoverSum | thousands }}</span>
          <span class="total-price" v-if="atUnpaidAmountType == 0">{{ unpaidYearObj.turnoverSum | thousands }}</span>
        </p>
      </div>
      <div class="pt14 pb20 flex justify-between align-center">
        <div class="flex justify-center capsule-wrap">
          <div class="capsule-item" :class="atUnpaidAmountType == 1 ? 'capsule-active' : ''" @click="(atUnpaidAmountType = 1), getMonthSum()">Month</div>
          <div class="capsule-item" :class="atUnpaidAmountType == 2 ? 'capsule-active' : ''" @click="(atUnpaidAmountType = 2), getQuarterlySum()">
            Quarterly
          </div>
          <div class="capsule-item" :class="atUnpaidAmountType == 0 ? 'capsule-active' : ''" @click="(atUnpaidAmountType = 0), getYearSum()">Year</div>
        </div>
        <div>
          <!-- 月 -->
          <div>
            <el-date-picker
              v-model="unpaidAccordingMonth"
              type="month"
              value-format="yyyy-MM-dd"
              @change="accordingMonthChange"
              v-if="atUnpaidAmountType == 1"
              size="small"
              class="wd120"
            ></el-date-picker>
          </div>
          <!-- 年 -->
          <div>
            <el-date-picker
              v-model="unpaidAccordingYear"
              type="year"
              value-format="yyyy-MM-dd"
              @change="accordingYearChange"
              v-if="atUnpaidAmountType == 0 || atUnpaidAmountType == 2"
              size="small"
              class="wd120"
            ></el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="fluid-width hg284" id="unpaid-amount"></div>
  </div>
</template>

<script>
import { fmoney, accountAdd } from "@/utils";
export default {
  data() {
    return {
      // Acc. Unpaid
      atUnpaidAmountType: 1, // 1 按月， 0 按年，2 按季
      unpaidAccordingMonth: "", // 月份
      unpaidAccordingYear: "", // 年份
      unpaidMonthObj: {
        transactionSum: 0,
        turnoverSum: 0,
        xList: [],
        transactionList: [],
        turnoverList: [],
        accUnpaidList: [],
      },
      unpaidQuarterlyObj: {
        transactionSum: 0,
        turnoverSum: 0,
        xList: [],
        transactionList: [],
        turnoverList: [],
        accUnpaidList: [],
      },
      unpaidYearObj: {
        transactionSum: 0,
        turnoverSum: 0,
        xList: [],
        transactionList: [],
        turnoverList: [],
        accUnpaidList: [],
      },
    };
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  methods: {
    // 按年的交易额
    getYearSum() {
      const params = {
        startTime: this.unpaidAccordingYear,
        endTime: this.$moment(this.unpaidAccordingYear).endOf("year").format("YYYY-MM-DD"),
        orderStatus: "UNPAID",
      };

      this.$http
        .get("/admin/statistics/getAmountListPerMonth", {
          params,
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            let transactionSum = 0;
            let turnoverSum = 0;
            let xList = [];
            let transactionList = [];
            let turnoverList = [];
            let accUnpaidList = [];
            data.forEach(item => {
              transactionSum = accountAdd(transactionSum, item.tpriceSg);
              turnoverSum = accountAdd(turnoverSum, item.tturnover);
              xList.push(item.currMonth);
              transactionList.push(parseFloat(item.tpriceSg));
              turnoverList.push(parseFloat(item.tturnover));
              accUnpaidList.push(parseFloat(item.accPriceSg));
            });
            const dataItem = {
              transactionSum,
              turnoverSum,
              xList,
              transactionList,
              turnoverList,
              accUnpaidList,
            };

            this.unpaidYearObj = dataItem;
            // 按月或按年的交易额 - 折线图
            this.setTransactionAmount(dataItem);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 按月的交易额
    getMonthSum() {
      const params = {
        startTime: this.unpaidAccordingMonth,
        endTime: this.$moment(this.unpaidAccordingMonth).endOf("month").format("YYYY-MM-DD"),
        orderStatus: "UNPAID",
      };

      this.$http
        .get("/admin/statistics/getAmountListPerDay", {
          params,
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            let transactionSum = 0;
            let turnoverSum = 0;
            let xList = [];
            let transactionList = [];
            let turnoverList = [];
            let accUnpaidList = [];
            data.forEach(item => {
              transactionSum = accountAdd(transactionSum, item.tpriceSg);
              turnoverSum = accountAdd(turnoverSum, item.tturnover);
              xList.push(item.currDay);
              transactionList.push(parseFloat(item.tpriceSg));
              turnoverList.push(parseFloat(item.tturnover));
              accUnpaidList.push(parseFloat(item.accPriceSg));
            });
            const dataItem = {
              transactionSum,
              turnoverSum,
              xList,
              transactionList,
              turnoverList,
              accUnpaidList,
            };

            this.unpaidMonthObj = dataItem;
            // 按月或按年的交易额 - 折线图
            this.setTransactionAmount(dataItem);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 按季的交易额
    getQuarterlySum() {
      const params = {
        startTime: this.unpaidAccordingYear,
        endTime: this.$moment(this.unpaidAccordingYear).endOf("year").format("YYYY-MM-DD"),
        orderStatus: "UNPAID",
      };

      this.$http
        .get("/admin/statistics/getAmountListPerQuarter", {
          params,
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            let transactionSum = 0;
            let turnoverSum = 0;
            let xList = [];
            let transactionList = [];
            let turnoverList = [];
            let accUnpaidList = [];
            data.forEach(item => {
              transactionSum = accountAdd(transactionSum, item.tpriceSg);
              turnoverSum = accountAdd(turnoverSum, item.tturnover);
              xList.push(item.currQuarter);
              transactionList.push(parseFloat(item.tpriceSg));
              turnoverList.push(parseFloat(item.tturnover));
              accUnpaidList.push(parseFloat(item.accPriceSg));
            });
            const dataItem = {
              transactionSum,
              turnoverSum,
              xList,
              transactionList,
              turnoverList,
              accUnpaidList,
            };

            this.unpaidQuarterlyObj = dataItem;
            // 按月或按年的交易额 - 折线图
            this.setTransactionAmount(dataItem);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 按月或按年的交易额 - 折线图
    setTransactionAmount(obj) {
      let myChart = this.$echarts.init(document.getElementById("unpaid-amount"));
      let option = {
        color: ["#ff7e69", "#52D4FF", "#67C23A"],
        tooltip: {
          trigger: "axis",
          // formatter: function (params) {
          //   params = params[0];
          //   return `S$${fmoney(params.data, 2)}<br />${params.axisValue}`;
          // },
        },
        legend: {
          data: ["Unpaid", "Turnover", "Acc. Unpaid"],
        },
        grid: {
          // left: "12%",
          // right: 0,
          top: 40,
          bottom: 20,
        },
        xAxis: {
          type: "category",
          data: obj.xList,
          axisLine: {
            lineStyle: {
              color: "#939DB0",
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          // axisLabel: {
          //   formatter: "{value}(S$)",
          // },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#939DB0",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: ["#EEF0F9"],
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: "Unpaid",
            data: obj.transactionList,
            type: "line",
          },
          {
            name: "Turnover",
            data: obj.turnoverList,
            type: "line",
          },
          {
            name: "Acc. Unpaid",
            data: obj.accUnpaidList,
            type: "line",
          },
        ],
      };
      myChart.setOption(option);
    },
    // 选择月份 - 日历
    accordingMonthChange(e) {
      // 按月的交易额
      this.getMonthSum();
    },
    // 选择年 - 日历
    accordingYearChange(e) {
      if (this.atUnpaidAmountType == 0) {
        // 按年的交易额
        this.getYearSum();
      } else if (this.atUnpaidAmountType == 2) {
        // 按季的交易额
        this.getQuarterlySum();
      }
    },
  },
  activated() {
    // 敏感数据 - 财务权限
    if (this.isAuth("finance:financial")) {
      this.atUnpaidAmountType = 1;
      this.unpaidAccordingMonth = this.$moment().startOf("month").format("YYYY-MM-DD");
      this.unpaidAccordingYear = this.$moment().startOf("year").format("YYYY-MM-DD");
      // 按月的交易额
      this.getMonthSum();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.min-hg460 {
  min-height: 460px;
}

.capsule-wrap {
  width: 210px;
  border-radius: 26px;
  overflow: hidden;
  .capsule-item {
    width: 70px;
    text-align: center;
    height: 30px;
    line-height: 28px;
    background-color: #ebeefd;
    color: #7987a0;
    font-size: 12px;
    cursor: pointer;
  }

  .capsule-active {
    background-color: #0072ff !important;
    color: #fff !important;
  }
}

.aurantia {
  color: #ff7e69 !important;
}

.cyan {
  color: #52d4ff !important;
}

.home-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: $form-item-font-color;
}

.home-subhead {
  margin: 0;
  padding: 0;
  margin-top: 6px;
  font-size: 14px;
  font-weight: bold;
  color: $form-item-font-color;
  line-height: 20px;
}

.symbol-price {
  font-size: 14px;
  font-weight: bold;
  color: $--color-primary;
  line-height: 1em;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  word-break: break-all;
  width: 50%;

  .total-price {
    font-size: 20px;
    font-weight: bold;
    line-height: 1em;
  }
}
</style>
