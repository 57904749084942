<template>
  <el-dialog title="Initiate Refund Application" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="150px">
      <el-form-item label="Choice of service(s) ">
        <span class="item-text">{{ planObj.planName }}</span>
      </el-form-item>
      <el-form-item label="Country">
        <span class="item-text">{{ detail.country }}</span>
      </el-form-item>
      <el-form-item label="Application No">
        <span class="item-text">{{ detail.applymentNo }}</span>
      </el-form-item>
      <el-form-item label="Applicant">
        <span class="item-text">{{ detail.applicantName }}</span>
      </el-form-item>
      <el-form-item label="Order Amount">
        <span class="item-text price">{{ planObj.originalPrice | thousands }}</span>
      </el-form-item>
      <el-form-item label="Acra Fee" prop="refundDetail.acraFee">
        <el-input v-model="dataForm.refundDetail.acraFee" type="number" placeholder="Acra Fee"></el-input>
      </el-form-item>
      <el-form-item label="Handling Fee" prop="refundDetail.handlingFee">
        <el-input v-model="dataForm.refundDetail.handlingFee" type="number" placeholder="Handling Fee"></el-input>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
      <el-form-item label="attachments" prop="attachmentUrls">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { fmoney, accountAdd } from "@/utils";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          goodsList: [
            {
              productType: "plan",
              productId: "2233",
              planDetail: {
                name: "",
                country: "",
                summary: "",
                priceSg: "",
                moduleList: [
                  {
                    country: "",
                    name: "",
                    summary: "",
                    priceSg: "",
                  },
                ],
              },
              moduleDetail: {
                country: "",
                name: "",
                summary: "",
                priceSg: "",
              },
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        refundDetail: {
          acraFee: null,
          handlingFee: null,
        },
        remark: "",
        attachmentUrls: [],
      },
      dataRule: {},
      submitControl: true, // 防止重复提交
    };
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapState("constant", ["stepStatusList"]),
    unselectableStatus() {
      return this.stepStatusList.find(i => i.unselectable);
    },
    goodsListType() {
      const goodsList = this.detail.goodsList;
      if (goodsList && goodsList.length > 0) {
        return goodsList[0].productType === "plan" ? 1 : 2;
      } else {
        return undefined;
      }
    },
    planObj() {
      if (this.detail.goodsList) {
        const goodsList = this.detail.goodsList;
        if (this.goodsListType === 1) {
          const planDetail = goodsList[0].planDetail;
          const modules = planDetail.moduleList;
          return {
            planName: planDetail.name,
            summary: planDetail.summary,
            price: planDetail.price,
            originalPrice: planDetail.price,
            modules: modules,
          };
        } else {
          const modules = goodsList.map(i => i.moduleDetail);
          return {
            planName: "Custom",
            price: this.customSumPrice(modules, "price", "thirdPartyFee", "otherFee"),
            originalPrice: this.customSumPrice(modules, "price", "thirdPartyFee", "otherFee"),
            modules: modules,
          };
        }
      } else {
        return {};
      }
    },
  },
  methods: {
    ...mapActions("applyment", ["doSaveProcedures", "doApplyRefund"]),
    sumPrice(dataList, field = "price") {
      return dataList.map(i => +i[field]).reduce((a, b) => a + b, 0);
    },
    customSumPrice(dataList, field = "price", second, other) {
      let sum = 0;
      dataList.map(i => {
        const price = i[field] && parseFloat(i[field]) ? parseFloat(i[field]) : 0;
        const thirdPartyFee = i[second] && parseFloat(i[second]) ? parseFloat(i[second]) : 0;
        const otherFee = i[other] && parseFloat(i[other]) ? parseFloat(i[other]) : 0;
        const thirdPartyFeeAndOtherFee = accountAdd(thirdPartyFee, otherFee);
        const thirdPartyFeeAndOtherFeeAndPrice = accountAdd(thirdPartyFeeAndOtherFee, price);
        sum = accountAdd(sum, thirdPartyFeeAndOtherFeeAndPrice);
      });
      return sum;
    },
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doApplyRefund({
            applymentId: this.detail.id,
            ...this.dataForm,
          })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.item-text {
  line-height: 40px;
  color: #41464b;
}
.price {
  color: #ff8653;
}
</style>
