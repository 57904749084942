<template>
  <el-dialog width="900px" :title="!dataForm.id ? 'Add Nominee Director' : 'Edit Nominee Director'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="143px">
      <el-form-item label="Name" prop="fullName">
        <el-input v-model="dataForm.fullName" placeholder="Name" :oninput="isEn"></el-input>
      </el-form-item>
      <el-row>
        <el-col :lg="13" :span="9">
          <el-form-item label="Nationality" prop="nationality">
            <el-select style="width: 100%" v-model="dataForm.nationality" placeholder="Select Nationality">
              <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="13" :span="9">
          <el-form-item label="IdentificationType" prop="identificationType">
            <el-select style="width: 100%" v-model="dataForm.identificationType" placeholder="Select identificationType">
              <el-option v-for="item in identificationTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="ID Number" prop="identificationNo">
        <el-input v-model="dataForm.identificationNo" placeholder="identificationNo" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Address" prop="residentialAddress">
        <el-input v-model="dataForm.residentialAddress" placeholder="Residential Address" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-model="dataForm.email" placeholder="email" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Phone" prop="phone">
        <el-input v-model="dataForm.phone" placeholder="Phone">
          <el-select v-model="dataForm.prefix" slot="prepend" class="wd90">
            <el-option v-for="single in prefixOptions" :key="single.title" :value="single.value">
              {{ single.value + " " + single.title }}
            </el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item label="Birth Date" prop="birthDate">
        <el-date-picker v-model="dataForm.birthDate" type="date" placeholder="Birth Date" value-format="yyyy-MM-dd" class="fluid-width"></el-date-picker>
      </el-form-item>
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">Disable</el-radio>
          <el-radio :label="1">Enable</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isEmail, isMobile } from "@/utils/validate";
import { mapState } from "vuex";
export default {
  data() {
    let checkAge = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        let birthDaySecond = new Date(value).getTime();
        //获取当前时间
        let atNowDate = new Date().getTime();
        let age = (atNowDate - birthDaySecond) / (365 * 24 * 3600 * 1000);
        if (age < 18) {
          callback(new Error("Must be at least 18 years old"));
        } else {
          callback();
        }
      }
    };
    let checkEmail = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!isEmail(value)) {
          callback(new Error("Please provide a valid email address"));
        } else {
          callback();
        }
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value) {
        if (this.dataForm.prefix) {
          callback();
        } else {
          callback(new Error("Please select the prefix of the mobile phone number"));
        }
      } else {
        if (this.dataForm.prefix) {
          callback(new Error("Please enter your mobile phone number"));
        } else {
          callback();
        }
      }
    };
    return {
      visible: false,
      dataForm: {
        id: 0,
        nationality: "",
        fullName: "",
        email: "",
        identificationType: "",
        identificationNo: "",
        residentialAddress: "",
        prefix: "",
        phone: "",
        birthDate: "",
        status: 1,
      },
      dataRule: {
        fullName: [{ required: true, message: "Name is required", trigger: "blur" }],
        nationality: [{ required: true, message: "country is required", trigger: "blur" }],
        email: [{ validator: checkEmail, trigger: "blur" }],
        birthDate: [{ validator: checkAge, trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["countryList", "identificationTypes", "prefixOptions"]),
  },
  methods: {
    init(id) {
      this.submitControl = true;
      this.visible = true;
      this.$nextTick().then(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          this.getDetail(id);
        } else {
          this.dataForm.id = undefined;
          this.dataForm.content = "";
        }
      });
    },
    //详情
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/stNomineeDirectorManage/${id}/detail`);
      let dataCopy = JSON.parse(JSON.stringify(data));
      let phoneCopy = dataCopy.phone && dataCopy.phone.includes("-") ? dataCopy.phone.split("-") : ["", ""];
      dataCopy.prefix = phoneCopy[0];
      dataCopy.phone = phoneCopy[1];
      this.dataForm = { ...dataCopy };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          let phoneCopy = this.dataForm.prefix + "-" + this.dataForm.phone;
          let dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
          dataFormCopy.phone = phoneCopy;
          delete dataFormCopy.prefix;
          const { code, msg } = await this.$http.post(
            this.dataForm.id ? `/admin/stNomineeDirectorManage/${this.dataForm.id}/update` : `/admin/stNomineeDirectorManage/create`,
            dataFormCopy,
          );
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
