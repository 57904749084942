<template>
  <el-dialog title="Finish Review" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="110px">
      <el-form-item label="Status" prop="applymentStatus">
        <el-radio-group v-model="dataForm.applymentStatus">
          <el-radio label="QUOTATION_AUDIT_FAIL">Rejected</el-radio>
          <el-radio label="QUOTATION_AUDIT_PASS">Pass</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
      <el-form-item label="attachments" prop="attachmentUrls">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        applymentStatus: "QUOTATION_AUDIT_PASS",
        remark: "",
        attachmentUrls: [],
      },
      dataRule: {},
      submitControl: true,
      type: "",
    };
  },
  computed: {},
  methods: {
    ...mapActions("applyment", ["auditQuotation"]),
    async init() {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.auditQuotation(this.dataForm)
            .then(() => {
              this.visible = false;
              this.submitControl = true;
            })
            .catch(msg => {
              this.$message.error(msg);
            });
        }
      });
    },
  },
};
</script>
