var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb5 fluid-width quotationWrap"
  }, [_c('div', {
    staticClass: "shadeWrap"
  }, [_c('div', {
    staticClass: "billingTitle"
  }, [_c('el-row', {
    staticClass: "pt20 pb20 fxRow borderTop",
    attrs: {
      "gutter": 5
    }
  }, [_c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Types")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Type Details")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Linked Transaction")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Covered Period")])]), _c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_c('p', {
    staticClass: "fs12 fwb wbbw text-center"
  }, [_vm._v("Deadline")])]), _c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Status")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Linked Service Type")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Closure Checklist")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Remark")])])], 1)], 1), _vm.controlDataList.length > 0 ? _vm._l(_vm.controlDataList, function (child, idx) {
    return _c('div', {
      key: `child${idx}`,
      staticClass: "billingContent"
    }, [_c('el-row', {
      staticClass: "pt20 pb20 fxRow borderTop",
      attrs: {
        "gutter": 5
      }
    }, [_c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 text-center"
    }, [_vm._v(_vm._s(child.controlType))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 wbbw text-center"
    }, [_vm._v(_vm._s(child.controlDesp))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 wbbw text-center"
    }, [_vm._v(_vm._s(child.linkedTransactionNo ? child.linkedTransactionNo.join(", ") : ""))])]), _c('el-col', {
      staticClass: "flex align-center justify-center",
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 wbbw text-center wd110"
    }, [_vm._v(_vm._s(child.coveredStart))]), _c('span', {
      staticClass: "ml2 mr2"
    }, [_vm._v("~")]), _c('p', {
      staticClass: "fs12 wbbw text-center wd110"
    }, [_vm._v(_vm._s(child.coveredEnd))])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 wbbw text-center"
    }, [_vm._v(_vm._s(child.deadline))])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 wbbw text-center"
    }, [_vm._v(_vm._s(_vm.engagementControlStatusFormat(child.status)))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 wbbw text-center"
    }, [_vm._v(_vm._s(_vm.serviceTypeFormat(child.linkedServiceType)))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, _vm._l(child.closureChecklist, function (uniterm, xv) {
      return _c('p', {
        key: `list${idx}${xv}`,
        staticClass: "fs12 wbbw text-center"
      }, [_vm._v(" " + _vm._s(uniterm.title) + " "), _c('span', [_vm._v(_vm._s(_vm.completedCheckFormat(uniterm.value)))])]);
    }), 0), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 wbbw text-center"
    }, [_vm._v(_vm._s(child.remark))])])], 1)], 1);
  }) : _vm._e()], 2), _vm.controlDataList.length == 0 ? [_vm._m(0)] : _vm._e()], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "noData"
  }, [_c('p', [_vm._v("No Data")])]);

}]

export { render, staticRenderFns }