<template>
  <el-dialog width="1400px" title="Status Track" :close-on-click-modal="false" :visible.sync="visible">
    <div>
      <el-form class="el-form-filter" :inline="true" :model="dataForm" @submit.native.prevent="getDataList(1)">
        <el-form-item>
          <el-date-picker
            value-format="timestamp"
            v-model="tradeTimeRange"
            type="daterange"
            range-separator="~"
            start-placeholder="Start Date"
            end-placeholder="Ending Date"
            @change="getDataList(1)"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="mt20">
      <el-table border :data="dataList" v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="clientCode" header-align="center" align="center" label="Client code" width="100"></el-table-column>
        <el-table-column prop="country" header-align="center" align="center" label="Country"></el-table-column>
        <el-table-column prop="status" header-align="center" align="center" label="Status" :formatter="statusFormat"></el-table-column>
        <el-table-column prop="createAt" header-align="center" align="center" label="Handling Time" width="130" :formatter="localDateFormat" />
        <el-table-column header-align="center" align="center" label="Handler">
          <template slot-scope="scope">
            <span>{{ scope.row.userInfo.nickname }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="introducedBy"
          header-align="center"
          align="center"
          label="Introduced By"
          width="126"
          :formatter="introducedByFormat"
        ></el-table-column>
        <el-table-column header-align="center" align="center" label="Commission" min-width="130">
          <template slot-scope="scope">
            <p v-for="item in scope.row.commissionList" :key="`commission${item.id}`" class="mt0 mb0">
              {{ item.name }} - {{ multiply(item.commissionRate) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="contact" header-align="center" align="center" label="Contact Info" min-width="120"></el-table-column>
        <el-table-column prop="introducer" header-align="center" align="center" label="Introducer" min-width="120"></el-table-column>
        <el-table-column prop="remark" header-align="center" align="center" label="Description / Remark" min-width="120" />
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { dateFormat, multiply } from "@/utils";
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataList: [],
      current: 1,
      size: 10,
      total: 0,
      dataListLoading: false,
      dataForm: {
        clientId: null,
        startTime: "",
        endTime: "",
      },
      tradeTimeRange: null,
    };
  },
  computed: {
    ...mapState("constant", ["clientCodeStatusList", "introducedByList"]),
    multiply() {
      return val => {
        return multiply(val, 100) + "%";
      };
    },
  },
  watch: {
    tradeTimeRange(newValue, oldValue) {
      if (newValue) {
        this.dataForm.startTime = newValue[0] / 1000;
        this.dataForm.endTime = newValue[1] / 1000;
      } else {
        this.dataForm.startTime = undefined;
        this.dataForm.endTime = undefined;
      }
    },
  },
  methods: {
    // 日期格式化
    localDateFormat(row) {
      if (row.createAt) {
        return dateFormat(new Date(row.createAt * 1000));
      }
      return "";
    },
    // 状态格式化
    statusFormat(row, column, cellValue) {
      const one = this.clientCodeStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    introducedByFormat(row, column, cellValue) {
      const one = this.introducedByList.find(i => i.value === cellValue);
      return one?.title;
    },
    async init(id) {
      this.visible = true;
      await this.$nextTick();
      this.dataForm = {
        clientId: id,
        startTime: "",
        endTime: "",
      };
      this.getDataList(1);
    },
    // 每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 获取分页
    getDataList(current) {
      setTimeout(async () => {
        this.dataListLoading = true;
        if (current) {
          this.current = current;
        }
        const { code, data } = await this.$http.get("/admin/clientCodeInfoLog/paging", {
          params: {
            current: this.current,
            size: this.size,
            ...this.dataForm,
          },
        });
        if (code === 200) {
          this.dataList = data.records;
          this.total = data.total;
        } else {
          this.dataList = [];
          this.total = 0;
        }
        this.dataListLoading = false;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
