var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', {
    staticClass: "mod-user"
  }, [_c('el-form', {
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getDataList(1);
      }
    }
  }, [_c('div', {
    staticClass: "pb20"
  }, [_vm.isAuth('sys:client:save') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addOrUpdateHandle();
      }
    }
  }, [_vm._v("Add Client code")]) : _vm._e()], 1), _c('div', [_c('el-form-item', [_c('el-select', {
    attrs: {
      "clearable": "",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.getClientCodeRemote,
      "loading": _vm.clientCodeLoading,
      "placeholder": "Client code"
    },
    on: {
      "clear": function ($event) {
        return _vm.getClientCodeRemote('');
      },
      "focus": function ($event) {
        return _vm.getClientCodeRemote('');
      }
    },
    model: {
      value: _vm.dataForm.clientCode,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "clientCode", $$v);
      },
      expression: "dataForm.clientCode"
    }
  }, _vm._l(_vm.clientCodeList, function (item, sy) {
    return _c('el-option', {
      key: `${item.clientCode}${sy}`,
      attrs: {
        "label": item.clientCode,
        "value": item.clientCode
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "clearable": "",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.getUenRemote,
      "loading": _vm.uenLoading,
      "placeholder": "UEN"
    },
    on: {
      "clear": function ($event) {
        return _vm.getUenRemote('');
      },
      "focus": function ($event) {
        return _vm.getUenRemote('');
      }
    },
    model: {
      value: _vm.dataForm.uen,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "uen", $$v);
      },
      expression: "dataForm.uen"
    }
  }, _vm._l(_vm.uenList, function (item, index) {
    return _c('el-option', {
      key: `${item.uen}${index}`,
      attrs: {
        "label": item.uen,
        "value": item.uen
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "clearable": "",
      "filterable": "",
      "placeholder": "Country"
    },
    model: {
      value: _vm.dataForm.country,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "country", $$v);
      },
      expression: "dataForm.country"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-date-picker-range', {
    attrs: {
      "startDate": _vm.dataForm.createTimeStart,
      "endDate": _vm.dataForm.createTimeEnd,
      "startPlaceholder": "Create (Start Date)",
      "endPlaceholder": "Create (End Date)"
    },
    on: {
      "startSelectChange": function (val) {
        _vm.dataForm.createTimeStart = val;
      },
      "endSelectChange": function (val) {
        _vm.dataForm.createTimeEnd = val;
      }
    }
  })], 1), _c('el-form-item', [_c('el-date-picker-range', {
    attrs: {
      "startDate": _vm.dataForm.passTimeStart,
      "endDate": _vm.dataForm.passTimeEnd,
      "startPlaceholder": "Pass (Start Date)",
      "endPlaceholder": "Pass (End Date)"
    },
    on: {
      "startSelectChange": function (val) {
        _vm.dataForm.passTimeStart = val;
      },
      "endSelectChange": function (val) {
        _vm.dataForm.passTimeEnd = val;
      }
    }
  })], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "multiple": "",
      "clearable": "",
      "placeholder": "Status"
    },
    model: {
      value: _vm.dataForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, _vm._l(_vm.clientCodeStatusList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('div', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('el-form-item', [_c('el-select', {
    staticClass: "controlLeft",
    attrs: {
      "clearable": "",
      "placeholder": "Introduced By"
    },
    on: {
      "change": _vm.introducedByChange
    },
    model: {
      value: _vm.dataForm.introducedBy,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "introducedBy", $$v);
      },
      expression: "dataForm.introducedBy"
    }
  }, _vm._l(_vm.introducedByList, function (item) {
    return _c('el-option', {
      key: item.title,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _vm.dataForm.introducedBy ? _c('el-form-item', {
    staticClass: "fml10"
  }, [_c('el-select-all', {
    staticClass: "controlRight",
    attrs: {
      "width": 220,
      "optionList": _vm.commissionRequiredArr,
      "placeholder": "Commission"
    },
    on: {
      "elSelectAllChange": function (list) {
        _vm.dataForm.commission = list;
      }
    }
  })], 1) : _vm._e()], 1), _c('el-form-item', [_c('el-input', {
    attrs: {
      "placeholder": "Contact Info",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.contact,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "contact", $$v);
      },
      expression: "dataForm.contact"
    }
  })], 1), _c('el-form-item', [_c('el-input', {
    attrs: {
      "placeholder": "Introducer",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.introducer,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "introducer", $$v);
      },
      expression: "dataForm.introducer"
    }
  })], 1), _c('el-form-item', [_c('el-input', {
    attrs: {
      "placeholder": "Description",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "icon": "el-icon-search",
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.getDataList(1);
      }
    }
  }, [_vm._v("Search")])], 1)], 1)]), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-height": "640",
      "data": _vm.dataList,
      "default-sort": {
        prop: 'createTime',
        order: 'descending'
      }
    },
    on: {
      "sort-change": _vm.tableSortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "clientCode",
      "header-align": "center",
      "align": "center",
      "label": "Client Code",
      "width": "125",
      "fixed": "left",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "uens",
      "header-align": "center",
      "align": "center",
      "label": "UEN",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.uens, function (item, index) {
          return _c('span', {
            key: `uens${item.uen}`
          }, [_vm._v(_vm._s(index > 0 ? ", " : "") + _vm._s(item.uen))]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "country",
      "header-align": "center",
      "align": "center",
      "label": "Country",
      "min-width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "createTime",
      "header-align": "center",
      "align": "center",
      "label": "Create Time",
      "min-width": "130",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "passTime",
      "header-align": "center",
      "align": "center",
      "label": "Pass Time",
      "min-width": "120",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "min-width": "126"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "lh20 mt0 mb0"
        }, [_vm._v(_vm._s(_vm.statusFormat(scope.row.status)))]), _c('el-link', {
          attrs: {
            "type": "primary",
            "underline": false
          },
          on: {
            "click": function ($event) {
              return _vm.openStatusHistoryModal(scope.row.id);
            }
          }
        }, [_vm._v("(Track)")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "introducedBy",
      "header-align": "center",
      "align": "center",
      "label": "Introduced By",
      "min-width": "120",
      "formatter": _vm.introducedByFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Commission",
      "min-width": "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.commissionList, function (item) {
          return _c('p', {
            key: `commission${item.id}`,
            staticClass: "mt0 mb0"
          }, [_vm._v(" " + _vm._s(item.name) + " - " + _vm._s(_vm.multiply(item.commissionRate)) + " "), item.remark ? _c('el-popover', {
            attrs: {
              "placement": "bottom",
              "trigger": "hover"
            }
          }, [_c('div', {
            staticClass: "fs12 lh20 enbr",
            staticStyle: {
              "max-width": "300px"
            }
          }, [_vm._v(_vm._s(item.remark))]), _c('span', {
            staticClass: "fs14 lightGray el-icon-info",
            attrs: {
              "slot": "reference"
            },
            slot: "reference"
          })]) : _vm._e()], 1);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "contact",
      "header-align": "center",
      "align": "center",
      "label": "Contact Info",
      "min-width": "200"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "introducer",
      "header-align": "center",
      "align": "center",
      "label": "Introducer",
      "min-width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "remark",
      "header-align": "center",
      "align": "center",
      "label": "Description",
      "min-width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "width": "120",
      "fixed": "right",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.isAuth('sys:client:update') && (scope.row.status == 0 || scope.row.status == 2) ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.addOrUpdateHandle(scope.row);
            }
          }
        }, [_vm._v(" Edit ")]) : _vm._e(), _vm.isAuth('sys:client:audit') && (scope.row.status == 0 || scope.row.status == 2) ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.reviewHandle(scope.row);
            }
          }
        }, [_vm._v(" Review ")]) : _vm._e(), _vm.isAuth('sys:client:delete') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteHandle(scope.row);
            }
          }
        }, [_vm._v("Delete")]) : _vm._e(), _vm.isAuth('sys:client:revise') && scope.row.status == 1 ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.statusUpdateHandle(scope.row, 0, 'Revise');
            }
          }
        }, [_vm._v(" Revise ")]) : _vm._e(), _vm.isAuth('sys:client:inactive') && scope.row.status != 3 ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.statusUpdateHandle(scope.row, 3, 'Inactive');
            }
          }
        }, [_vm._v(" Inactive ")]) : _vm._e(), _vm.isAuth('sys:client:reActive') && scope.row.status == 3 ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.statusUpdateHandle(scope.row, 0, 'Re-active');
            }
          }
        }, [_vm._v(" Re-active ")]) : _vm._e()];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.pageSize,
      "total": _vm.totalCount,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  }), _c('add-or-update', {
    ref: "addOrUpdate",
    on: {
      "refreshDataList": _vm.getDataList
    }
  }), _c('status-history', {
    ref: "statusHistoryModal"
  }), _c('client-review', {
    ref: "clientReviewModal",
    attrs: {
      "commissionRequiredList": _vm.commissionRequiredList
    },
    on: {
      "refreshStatus": _vm.getDataList
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }