export default {
  data() {
    return {};
  },
  methods: {
    // 删除单条数据
    singleDataDelete() {
      const totalPage = Math.ceil((this.totalCount - 1) / this.pageSize);
      this.current = this.current > totalPage ? totalPage : this.current;
      this.current = this.current < 1 ? 1 : this.current;
    },
    // 删除单条或多条数据
    singleOrMultipleDataDelete(id) {
      const totalPage =
        id == "multiple"
          ? Math.ceil((this.totalCount - this.dataListSelections.length) / this.pageSize)
          : Math.ceil((this.totalCount - 1) / this.pageSize);
      this.current = this.current > totalPage ? totalPage : this.current;
      this.current = this.current < 1 ? 1 : this.current;
    },
  },
};
