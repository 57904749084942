var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-body fxRow"
  }, [_vm._m(0), _c('div', {
    staticClass: "ms-login fxRow"
  }, [_c('div', {
    staticClass: "from-wrap"
  }, [_c('div', {
    staticClass: "fxRow brand-name"
  }, [_vm._v("Company Management")]), _c('div', {
    staticClass: "fxRow"
  }, [_c('el-button', {
    staticClass: "login-btn",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.gotoLoginPage();
      }
    }
  }, [_vm._v("Sign in")])], 1)])]), _vm._m(1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "firm-bg-img fxRow"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("@/assets/img/logo.png")
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/img/firm.png")
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footBox"
  }, [_c('p', {
    staticClass: "fs12",
    staticStyle: {
      "font-family": "'微软雅黑'"
    }
  }, [_vm._v(" Copyright© 2021 星中(广州)商务咨询有限公司 "), _c('a', {
    attrs: {
      "href": "https://beian.miit.gov.cn/"
    }
  }, [_vm._v("粤ICP备2021165700号")])])]);

}]

export { render, staticRenderFns }