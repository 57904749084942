export default {
  namespaced: true,
  state: {
    riskRatingTypes: [
      {
        title: "Low",
        value: 1,
      },
      {
        title: "Medium",
        value: 2,
      },
      {
        title: "High",
        value: 3,
      },
    ],
    applymentStatusList: [
      {
        //待提交
        title: "Draft",
        value: "DRAFT",
      },
      {
        // 咨询中
        title: "Inquiring",
        value: "INQUIRING",
      },
      {
        // 报价中
        title: "Quoting",
        value: "QUOTING",
      },
      {
        // 报价已提交（待审核）
        title: "Quoting reviewing",
        value: "QUOTATION_AUDITING",
      },
      {
        // 报价完成
        title: "Quotation proposed",
        value: "QUOTATION_AUDIT_PASS",
      },
      {
        // 报价审批失败
        title: "Quotation rejected",
        value: "QUOTATION_AUDIT_FAIL",
      },
      {
        //已提交
        title: "Submitted",
        value: "SUBMITTED",
      },
      {
        //初筛中（一级审核）
        title: "Checking",
        value: "CHECKING",
      },
      {
        //初筛不合格（一级审核）
        title: "Checking Failed",
        value: "ILLEGAL",
      },
      {
        //驳回（资料填写不完整）（业务）
        title: "Rejected",
        value: "REJECTED",
      },
      {
        //已修正（资料重填）（用户）
        title: "Corrected",
        value: "CORRECTED",
      },
      {
        //修订中（业务）
        title: "Revising",
        value: "REVISING",
      },
      {
        //已修订完成（业务）
        title: "Revise Completed",
        value: "REVISE_FINISH",
      },
      {
        //复核中（复核）
        title: "Reviewing",
        value: "AUDITING",
      },
      {
        //复核失败（复核）
        title: "Review Failed",
        value: "AUDIT_FAIL",
      },
      {
        //复核成功（复核）
        title: "Review Passed",
        value: "AUDIT_SUCCESS",
      },
      {
        //申请退款中（业务）
        title: "Refund Applied",
        value: "REFUND_APPLYING",
      },
      {
        //申请退款拒绝（一级审核）
        title: "Refund Checking Rejected",
        value: "REFUND_APPLY_DENY",
      },
      {
        //申请退款已确认（一级审核）
        title: "Refund Checking Passed",
        value: "REFUND_APPLY_AGREED",
      },
      {
        //申请退款驳回（复核）
        title: "Refund Review Rejected",
        value: "REFUND_APPLY_REJECT",
      },
      {
        //申请退款通过（复核）
        title: "Refund Review Passed",
        value: "REFUND_APPLY_PASS",
      },
      {
        //退款处理中（若退款单状态为REFUND_INFO_NOT_ENOUGH，则需要补充退款信息）（财务，由业务根据退款状态进行响应）
        title: "Refund Money Processing",
        value: "REFUND_HANDING",
      },
      {
        //已取消（退款成功后申请单取消）
        title: "Refund Completed & Order Canceled",
        value: "CANCELED",
      },
      {
        //更新申请中 (ACRA 和/或 其他)（业务）
        title: "Processing to ACRA and/or other",
        value: "ACRA_APPLYING",
      },
      {
        //ACRA 已驳回（业务）
        title: "Rejected by ACRA and/or other",
        value: "ACRA_REJECTED",
      },
      {
        //已完成（业务)
        title: "Order Completed",
        value: "FINISH",
      },
      {
        //申请终止交易中（业务）
        title: "Suspend Transaction Applying",
        value: "SUSPEND_TRANSACTION_APPLYING",
      },
      {
        //申请终止交易驳回（复核）
        title: "Suspend Transaction Apply Reject",
        value: "SUSPEND_TRANSACTION_APPLY_REJECT",
      },
      {
        //已终止（退款成功后申请单取消）
        title: "Terminated",
        value: "TERMINATED",
      },
      {
        //已作废
        title: "Forfeited",
        value: "FORFEITED",
      },
    ],
    orderStatusList: [
      {
        //待付款
        title: "Unpaid",
        value: "UNPAID",
      },
      {
        //部分付款
        title: "Partially Paid",
        value: "PARTLY_PAID",
      },
      {
        //已完成
        title: "Paid",
        value: "COMPLETE",
      },
      {
        //已关闭
        title: "Closed",
        value: "CLOSED",
      },
      {
        //已作废
        title: "Forfeited",
        value: "FORFEITED",
      },
    ],
    refundStatusList: [
      {
        //退款中
        title: "Refunding",
        value: "REFUNDING",
      },
      {
        //退款成功
        title: "Refunded",
        value: "REFUNDED",
      },
      {
        //退款信息无异议，待退款
        title: "Pending Refund",
        value: "REFUND_PENDING",
      },
      {
        //退款失败，退款信息不全，待进一步提供退款资料
        title: "Refund Failed due to Insufficient Info",
        value: "REFUND_INFO_NOT_ENOUGH",
      },
      {
        //退款信息已补充（业务）
        title: "Refund Info Updated",
        value: "REFUND_INFO_UPDATED",
      },
    ],
    amendsStatusList: [
      {
        //待付款
        title: "Unpaid",
        value: 1,
      },
      {
        //已完成
        title: "Paid",
        value: 2,
      },
      {
        //已关闭
        title: "Closed",
        value: -1,
      },
    ],
    // 续约状态
    renewStatusList: [
      // 续约中
      {
        title: "Renewing",
        value: "RENEWING",
      },
      // 已续约
      {
        title: "Renewed",
        value: "RENEWED",
      },
    ],
    orderTypeList: [
      {
        // 一般的
        title: "Ordinary",
        value: "generic",
      },
      {
        // 补款单
        title: "Supplemental",
        value: "amends",
      },
      {
        // Quotation (V)
        title: "Quotation (V)",
        value: "supplement",
      },
    ],
    payTypes: [
      {
        //支付宝
        title: "Alipay",
        value: "ALIPAY",
      },
      {
        //银行转账
        title: "Bank Transfer",
        value: "BANK_TRANSFER",
      },
    ],
    entityTypes: [
      {
        title: "Corporate related",
        value: "COMPANY",
      },
      {
        title: "Fund related",
        value: "FUND",
      },
    ],
    businessTypes: [
      {
        title: "Company Incorporation",
        value: "COMPANY_INCORPORATION",
      },
      {
        title: "Corporate Service Transfer – Company Secretarial related services",
        value: "SERVICE_TRANSFER",
      },
      {
        title: "Comprehensive Corporate Services – Customized (Exclusive of Incorporation)",
        value: "COMPREHENSIVE_CUSTOMIZED",
      },
      // {
      //   title: "VCC Incorporation",
      //   value: "VCC_INCORPORATION",
      // },
      {
        title: "Fund Admin Services – Customized (Exclusive of Incorporation)",
        value: "FUND_ADMIN_CUSTOMIZED",
      },
    ],
    businessStepObj: {
      COMPANY_INCORPORATION: {
        companyName: "Step1",
        kycQuestionnaire: "Step2",
        companyDetails: "Step3",
        upload: "Step4",
      },
      SERVICE_TRANSFER: {
        kycQuestionnaire: "Step1",
        upload: "Step2",
      },
      COMPREHENSIVE_CUSTOMIZED: {
        kycQuestionnaire: "Step1",
        upload: "Step2",
      },
      FUND_ADMIN_CUSTOMIZED: {
        kycQuestionnaire: "Step1",
        upload: "Step2",
      },
    },
    countryList: [
      { title: "Singapore", value: "Singapore", native: true },
      { title: "China", value: "China" },
      { title: "Hong Kong", value: "Hong Kong" },
      { title: "Afghanistan", value: "Afghanistan" },
      { title: "Albania", value: "Albania" },
      { title: "Algeria", value: "Algeria" },
      { title: "American Samoa", value: "American Samoa" },
      { title: "Andorra", value: "Andorra" },
      { title: "Angola", value: "Angola" },
      { title: "Anguilla", value: "Anguilla" },
      { title: "Antarctica", value: "Antarctica" },
      { title: "Antigua and Barbuda", value: "Antigua and Barbuda" },
      { title: "Argentina", value: "Argentina" },
      { title: "Armenia", value: "Armenia" },
      { title: "Aruba", value: "Aruba" },
      { title: "Australia", value: "Australia" },
      { title: "Austria", value: "Austria" },
      { title: "Azerbaijan", value: "Azerbaijan" },
      { title: "Bahamas", value: "Bahamas" },
      { title: "Bahrain", value: "Bahrain" },
      { title: "Bangladesh", value: "Bangladesh" },
      { title: "Barbados", value: "Barbados" },
      { title: "Belarus", value: "Belarus" },
      { title: "Belgium", value: "Belgium" },
      { title: "Belize", value: "Belize" },
      { title: "Benin", value: "Benin" },
      { title: "Bermuda", value: "Bermuda" },
      { title: "Bhutan", value: "Bhutan" },
      { title: "Bolivia", value: "Bolivia" },
      { title: "Bonaire Sint Eustatius And Saba", value: "Bonaire Sint Eustatius And Saba" },
      { title: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
      { title: "Botswana", value: "Botswana" },
      { title: "Bouvet Island", value: "Bouvet Island" },
      { title: "Brazil", value: "Brazil" },
      { title: "British Indian Ocean Territory", value: "British Indian Ocean Territory" },
      { title: "Brunei Darussalam", value: "Brunei Darussalam" },
      { title: "Bulgaria", value: "Bulgaria" },
      { title: "Burkina Faso", value: "Burkina Faso" },
      { title: "Burundi", value: "Burundi" },
      { title: "Cabo Verde", value: "Cabo Verde" },
      { title: "Cambodia", value: "Cambodia" },
      { title: "Cameroon", value: "Cameroon" },
      { title: "Canada", value: "Canada" },
      { title: "Cayman Islands", value: "Cayman Islands" },
      { title: "Central African Republic", value: "Central African Republic" },
      { title: "Chad", value: "Chad" },
      { title: "Chile", value: "Chile" },
      { title: "Christmas Island", value: "Christmas Island" },
      { title: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
      { title: "Colombia", value: "Colombia" },
      { title: "Comoros", value: "Comoros" },
      { title: "Congo", value: "Congo" },
      { title: "Congo, The Democratic Republic of The", value: "Congo, The Democratic Republic of The" },
      { title: "Cook Islands", value: "Cook Islands" },
      { title: "Costa Rica", value: "Costa Rica" },
      { title: "Cote D'Ivoire", value: "Cote D'Ivoire" },
      { title: "Croatia", value: "Croatia" },
      { title: "Cuba", value: "Cuba" },
      { title: "Curacao", value: "Curacao" },
      { title: "Cyprus", value: "Cyprus" },
      { title: "Czechia", value: "Czechia" },
      { title: "Denmark", value: "Denmark" },
      { title: "Djibouti", value: "Djibouti" },
      { title: "Dominica", value: "Dominica" },
      { title: "Dominican Republic", value: "Dominican Republic" },
      { title: "Ecuador", value: "Ecuador" },
      { title: "Egypt", value: "Egypt" },
      { title: "El Salvador", value: "El Salvador" },
      { title: "Equatorial Guinea", value: "Equatorial Guinea" },
      { title: "Eritrea", value: "Eritrea" },
      { title: "Estonia", value: "Estonia" },
      { title: "Eswatini", value: "Eswatini" },
      { title: "Ethiopia", value: "Ethiopia" },
      { title: "Falkland Islands (Malvinas)", value: "Falkland Islands (Malvinas)" },
      { title: "Faroe Islands", value: "Faroe Islands" },
      { title: "Fiji", value: "Fiji" },
      { title: "Finland", value: "Finland" },
      { title: "France", value: "France" },
      { title: "French Guiana", value: "French Guiana" },
      { title: "French Polynesia", value: "French Polynesia" },
      { title: "French Southern Territories", value: "French Southern Territories" },
      { title: "Gabon", value: "Gabon" },
      { title: "Gambia", value: "Gambia" },
      { title: "Georgia", value: "Georgia" },
      { title: "Germany", value: "Germany" },
      { title: "Ghana", value: "Ghana" },
      { title: "Gibraltar", value: "Gibraltar" },
      { title: "Greece", value: "Greece" },
      { title: "Greenland", value: "Greenland" },
      { title: "Grenada", value: "Grenada" },
      { title: "Guadeloupe", value: "Guadeloupe" },
      { title: "Guam", value: "Guam" },
      { title: "Guatemala", value: "Guatemala" },
      { title: "Guernsey", value: "Guernsey" },
      { title: "Guinea", value: "Guinea" },
      { title: "Guinea-Bissau", value: "Guinea-Bissau" },
      { title: "Guyana", value: "Guyana" },
      { title: "Haiti", value: "Haiti" },
      { title: "Heard Island And Mcdonald Islands", value: "Heard Island And Mcdonald Islands" },
      { title: "Holy See", value: "Holy See" },
      { title: "Honduras", value: "Honduras" },
      { title: "Hungary", value: "Hungary" },
      { title: "Iceland", value: "Iceland" },
      { title: "India", value: "India" },
      { title: "Indonesia", value: "Indonesia" },
      { title: "Iran, Islamic Republic of", value: "Iran, Islamic Republic of" },
      { title: "Iraq", value: "Iraq" },
      { title: "Ireland", value: "Ireland" },
      { title: "Isle of Man", value: "Isle of Man" },
      { title: "Israel", value: "Israel" },
      { title: "Italy", value: "Italy" },
      { title: "Jamaica", value: "Jamaica" },
      { title: "Japan", value: "Japan" },
      { title: "Jordan", value: "Jordan" },
      { title: "Kazakhstan", value: "Kazakhstan" },
      { title: "Kenya", value: "Kenya" },
      { title: "Kiribati", value: "Kiribati" },
      { title: "Korea, Democratic People's Republic of", value: "Korea, Democratic People's Republic of" },
      { title: "Korea, Republic of", value: "Korea, Republic of" },
      { title: "Kuwait", value: "Kuwait" },
      { title: "Kyrgyzstan", value: "Kyrgyzstan" },
      { title: "Lao People's Democratic Republic", value: "Lao People's Democratic Republic" },
      { title: "Latvia", value: "Latvia" },
      { title: "Lebanon", value: "Lebanon" },
      { title: "Lesotho", value: "Lesotho" },
      { title: "Liberia", value: "Liberia" },
      { title: "Libya", value: "Libya" },
      { title: "Liechtenstein", value: "Liechtenstein" },
      { title: "Lithuania", value: "Lithuania" },
      { title: "Luxembourg", value: "Luxembourg" },
      { title: "Macao", value: "Macao" },
      { title: "Madagascar", value: "Madagascar" },
      { title: "Malawi", value: "Malawi" },
      { title: "Malaysia", value: "Malaysia" },
      { title: "Maldives", value: "Maldives" },
      { title: "Mali", value: "Mali" },
      { title: "Malta", value: "Malta" },
      { title: "Marshall Islands", value: "Marshall Islands" },
      { title: "Martinique", value: "Martinique" },
      { title: "Mauritania", value: "Mauritania" },
      { title: "Mauritius", value: "Mauritius" },
      { title: "Mayotte", value: "Mayotte" },
      { title: "Mexico", value: "Mexico" },
      { title: "Micronesia", value: "Micronesia" },
      { title: "Moldova", value: "Moldova" },
      { title: "Monaco", value: "Monaco" },
      { title: "Mongolia", value: "Mongolia" },
      { title: "Montenegro", value: "Montenegro" },
      { title: "Montserrat", value: "Montserrat" },
      { title: "Morocco", value: "Morocco" },
      { title: "Mozambique", value: "Mozambique" },
      { title: "Myanmar", value: "Myanmar" },
      { title: "Namibia", value: "Namibia" },
      { title: "Nauru", value: "Nauru" },
      { title: "Nepal", value: "Nepal" },
      { title: "Netherlands", value: "Netherlands" },
      { title: "New Caledonia", value: "New Caledonia" },
      { title: "New Zealand", value: "New Zealand" },
      { title: "Nicaragua", value: "Nicaragua" },
      { title: "Niger", value: "Niger" },
      { title: "Nigeria", value: "Nigeria" },
      { title: "Niue", value: "Niue" },
      { title: "Norfolk Island", value: "Norfolk Island" },
      { title: "North Macedonia", value: "North Macedonia" },
      { title: "Northern Mariana Islands", value: "Northern Mariana Islands" },
      { title: "Norway", value: "Norway" },
      { title: "Oman", value: "Oman" },
      { title: "Pakistan", value: "Pakistan" },
      { title: "Palau", value: "Palau" },
      { title: "Palestine State", value: "Palestine State" },
      { title: "Panama", value: "Panama" },
      { title: "Papua New Guinea", value: "Papua New Guinea" },
      { title: "Paraguay", value: "Paraguay" },
      { title: "Peru", value: "Peru" },
      { title: "Philippines", value: "Philippines" },
      { title: "Pitcairn", value: "Pitcairn" },
      { title: "Poland", value: "Poland" },
      { title: "Portugal", value: "Portugal" },
      { title: "Puerto Rico", value: "Puerto Rico" },
      { title: "Qatar", value: "Qatar" },
      { title: "Reunion", value: "Reunion" },
      { title: "Romania", value: "Romania" },
      { title: "Russian Federation", value: "Russian Federation" },
      { title: "Rwanda", value: "Rwanda" },
      { title: "Saint Barthelemy", value: "Saint Barthelemy" },
      { title: "Saint Helena Ascension and Tristan Da Cunha", value: "Saint Helena Ascension and Tristan Da Cunha" },
      { title: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
      { title: "Saint Lucia", value: "Saint Lucia" },
      { title: "Saint Marino (French Part)", value: "Saint Marino (French Part)" },
      { title: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
      { title: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
      { title: "Samoa", value: "Samoa" },
      { title: "San Marino", value: "San Marino" },
      { title: "Sao Tome and Principe", value: "Sao Tome and Principe" },
      { title: "Saudi Arabia", value: "Saudi Arabia" },
      { title: "Senegal", value: "Senegal" },
      { title: "Serbia", value: "Serbia" },
      { title: "Seychelles", value: "Seychelles" },
      { title: "Sierra Leone", value: "Sierra Leone" },
      { title: "Sint Maarten (Dutch Part)", value: "Sint Maarten (Dutch Part)" },
      { title: "Slovakia", value: "Slovakia" },
      { title: "Slovenia", value: "Slovenia" },
      { title: "Solomon Islands", value: "Solomon Islands" },
      { title: "Somalia", value: "Somalia" },
      { title: "South Africa", value: "South Africa" },
      { title: "South Korea", value: "South Korea" },
      { title: "South Georgia and The Souch Sandwich Islands", value: "South Georgia and The Souch Sandwich Islands" },
      { title: "South Sudan", value: "South Sudan" },
      { title: "Spain", value: "Spain" },
      { title: "Sri Lanka", value: "Sri Lanka" },
      { title: "Sudan", value: "Sudan" },
      { title: "Suriname", value: "Suriname" },
      { title: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
      { title: "Sweden", value: "Sweden" },
      { title: "Switzerland", value: "Switzerland" },
      { title: "Syrian Arab Republic", value: "Syrian Arab Republic" },
      { title: "Taiwan", value: "Taiwan" },
      { title: "Tajikistan", value: "Tajikistan" },
      { title: "Tanzania, United Republic of", value: "Tanzania, United Republic of" },
      { title: "Thailand", value: "Thailand" },
      { title: "Timor-Leste", value: "Timor-Leste" },
      { title: "Togo", value: "Togo" },
      { title: "Tokelau", value: "Tokelau" },
      { title: "Tonga", value: "Tonga" },
      { title: "Trinidad and Tobago", value: "Trinidad and Tobago" },
      { title: "Tunisia", value: "Tunisia" },
      { title: "Turkey", value: "Turkey" },
      { title: "Turkmenistan", value: "Turkmenistan" },
      { title: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
      { title: "Tuvalu", value: "Tuvalu" },
      { title: "Uganda", value: "Uganda" },
      { title: "Ukraine", value: "Ukraine" },
      { title: "United Arab Emirates", value: "United Arab Emirates" },
      {
        title: "United Kingdom of Great Britain and Northern Ireland",
        value: "United Kingdom of Great Britain and Northern Ireland",
      },
      { title: "United States Minor Outlying Islands", value: "United States Minor Outlying Islands" },
      { title: "United States of America", value: "United States of America" },
      { title: "Uruguay", value: "Uruguay" },
      { title: "Uzbekistan", value: "Uzbekistan" },
      { title: "Vanuatu", value: "Vanuatu" },
      { title: "Venezuela Bolivarian Republic of", value: "Venezuela Bolivarian Republic of" },
      { title: "Vietnam", value: "Vietnam" },
      { title: "Virgin Islands, British", value: "Virgin Islands, British" },
      { title: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
      { title: "Wallis and Futuna", value: "Wallis and Futuna" },
      { title: "Western Sahara", value: "Western Sahara" },
      { title: "Yemen", value: "Yemen" },
      { title: "Zambia", value: "Zambia" },
      { title: "Zimbabwe", value: "Zimbabwe" },
    ],
    nationalityList: [
      { title: "Singaporean", value: "Singaporean" },
      { title: "Chinese", value: "Chinese" },
      { title: "Chinese/Hong Kong Sar", value: "Chinese/Hong Kong Sar" },
      { title: "Chinese/Macau Sar", value: "Chinese/Macau Sar" },
      { title: "Afghan", value: "Afghan" },
      { title: "Albanian", value: "Albanian" },
      { title: "Algerian", value: "Algerian" },
      { title: "American", value: "American" },
      { title: "Andorran", value: "Andorran" },
      { title: "Angolan", value: "Angolan" },
      { title: "Antiguan", value: "Antiguan" },
      { title: "Argentinian", value: "Argentinian" },
      { title: "Armenian", value: "Armenian" },
      { title: "Australian", value: "Australian" },
      { title: "Austrian", value: "Austrian" },
      { title: "Azerbaijani", value: "Azerbaijani" },
      { title: "Bahamian", value: "Bahamian" },
      { title: "Bahraini", value: "Bahraini" },
      { title: "Bangladeshi", value: "Bangladeshi" },
      { title: "Barbados", value: "Barbados" },
      { title: "Belarussian", value: "Belarussian" },
      { title: "Belgian", value: "Belgian" },
      { title: "Belizeah", value: "Belizeah" },
      { title: "Beninese", value: "Beninese" },
      { title: "Bhutanese", value: "Bhutanese" },
      { title: "Bolivian", value: "Bolivian" },
      { title: "Bosnia", value: "Bosnia" },
      { title: "Batswana", value: "Batswana" },
      { title: "Brazilian", value: "Brazilian" },
      { title: "British", value: "British" },
      { title: "British National Overseas", value: "British National Overseas" },
      { title: "British Overseas Citizen", value: "British Overseas Citizen" },
      { title: "British Overseas Territories Citizen", value: "British Overseas Territories Citizen" },
      { title: "British Protected Person", value: "British Protected Person" },
      { title: "British Subject", value: "British Subject" },
      { title: "Bruneian", value: "Bruneian" },
      { title: "Bulgarian", value: "Bulgarian" },
      { title: "Burkinabe", value: "Burkinabe" },
      { title: "Burundian", value: "Burundian" },
      { title: "Cambodian", value: "Cambodian" },
      { title: "Cameroonian", value: "Cameroonian" },
      { title: "Canadian", value: "Canadian" },
      { title: "Cape Verdean", value: "Cape Verdean" },
      { title: "Cayman Islands", value: "Cayman Islands" },
      { title: "Central African Republic", value: "Central African Republic" },
      { title: "Chadian", value: "Chadian" },
      { title: "Chilean", value: "Chilean" },
      { title: "Chinese/ Taiwanese", value: "Chinese/ Taiwanese" },
      { title: "Colombia", value: "Colombia" },
      { title: "Comoran", value: "Comoran" },
      { title: "Congolese", value: "Congolese" },
      { title: "Costa Rican", value: "Costa Rican" },
      { title: "Croatian", value: "Croatian" },
      { title: "Cuban", value: "Cuban" },
      { title: "Cyprot", value: "Cyprot" },
      { title: "Czech", value: "Czech" },
      { title: "D.P.R.Korean", value: "D.P.R.Korean" },
      { title: "Danish", value: "Danish" },
      { title: "Democratic Republic of the Congo", value: "Democratic Republic of the Congo" },
      { title: "Djiboutian", value: "Djiboutian" },
      { title: "Dominican", value: "Dominican" },
      { title: "Dominican (Republic)", value: "Dominican (Republic)" },
      { title: "East Timorese", value: "East Timorese" },
      { title: "Ecuadorian", value: "Ecuadorian" },
      { title: "Egyptian", value: "Egyptian" },
      { title: "Equatorial Guinea", value: "Equatorial Guinea" },
      { title: "Eritrean", value: "Eritrean" },
      { title: "Estonian", value: "Estonian" },
      { title: "Ethiopian", value: "Ethiopian" },
      { title: "Fijian", value: "Fijian" },
      { title: "Filipino", value: "Filipino" },
      { title: "Finnish", value: "Finnish" },
      { title: "French", value: "French" },
      { title: "Gabon", value: "Gabon" },
      { title: "Gambian", value: "Gambian" },
      { title: "Georgian", value: "Georgian" },
      { title: "German", value: "German" },
      { title: "Ghanaian", value: "Ghanaian" },
      { title: "Greek", value: "Greek" },
      { title: "Grenadian", value: "Grenadian" },
      { title: "Guatemalan", value: "Guatemalan" },
      { title: "Guinean", value: "Guinean" },
      { title: "Guinean (Bissau)", value: "Guinean (Bissau)" },
      { title: "Guyanese", value: "Guyanese" },
      { title: "Haitian", value: "Haitian" },
      { title: "Honduran", value: "Honduran" },
      { title: "Hungarian", value: "Hungarian" },
      { title: "Icelander", value: "Icelander" },
      { title: "Indian", value: "Indian" },
      { title: "Indonesian", value: "Indonesian" },
      { title: "Iranian", value: "Iranian" },
      { title: "Iraqi", value: "Iraqi" },
      { title: "Irish", value: "Irish" },
      { title: "Israeli", value: "Israeli" },
      { title: "Italian", value: "Italian" },
      { title: "Ivory Coast", value: "Ivory Coast" },
      { title: "Jamaican", value: "Jamaican" },
      { title: "Japanese", value: "Japanese" },
      { title: "Jordanian", value: "Jordanian" },
      { title: "Kazakhstani", value: "Kazakhstani" },
      { title: "Kenyan", value: "Kenyan" },
      { title: "Kiribati", value: "Kiribati" },
      { title: "Korean, South", value: "Korean, South" },
      { title: "Kosovar", value: "Kosovar" },
      { title: "Kuwaiti", value: "Kuwaiti" },
      { title: "Kyrgyzstan", value: "Kyrgyzstan" },
      { title: "Laotian", value: "Laotian" },
      { title: "Latvian", value: "Latvian" },
      { title: "Lebanese", value: "Lebanese" },
      { title: "Lesotho", value: "Lesotho" },
      { title: "Liberian", value: "Liberian" },
      { title: "Libyan", value: "Libyan" },
      { title: "Liechtensteiner", value: "Liechtensteiner" },
      { title: "Lithuanian", value: "Lithuanian" },
      { title: "Luxembourger", value: "Luxembourger" },
      { title: "Macedonian", value: "Macedonian" },
      { title: "Malagasy", value: "Malagasy" },
      { title: "Malawian", value: "Malawian" },
      { title: "Malaysian", value: "Malaysian" },
      { title: "Maldivian", value: "Maldivian" },
      { title: "Malian", value: "Malian" },
      { title: "Maltese", value: "Maltese" },
      { title: "Marshallese", value: "Marshallese" },
      { title: "Mauritanean", value: "Mauritanean" },
      { title: "Mauritian", value: "Mauritian" },
      { title: "Mexican", value: "Mexican" },
      { title: "Micronesian", value: "Micronesian" },
      { title: "Moldavian", value: "Moldavian" },
      { title: "Monacan", value: "Monacan" },
      { title: "Mongolian", value: "Mongolian" },
      { title: "Montenegrin", value: "Montenegrin" },
      { title: "Moroccan", value: "Moroccan" },
      { title: "Mozambican", value: "Mozambican" },
      { title: "Myanmar", value: "Myanmar" },
      { title: "Namibian", value: "Namibian" },
      { title: "Nauruan", value: "Nauruan" },
      { title: "Nepalese", value: "Nepalese" },
      { title: "Netherlands", value: "Netherlands" },
      { title: "New Zealander", value: "New Zealander" },
      { title: "Ni-Vanuatu", value: "Ni-Vanuatu" },
      { title: "Nicaraguan", value: "Nicaraguan" },
      { title: "Niger", value: "Niger" },
      { title: "Nigerian", value: "Nigerian" },
      { title: "Norwegian", value: "Norwegian" },
      { title: "Omani", value: "Omani" },
      { title: "Pakistani", value: "Pakistani" },
      { title: "Palauan", value: "Palauan" },
      { title: "Palestinian", value: "Palestinian" },
      { title: "Panamanian", value: "Panamanian" },
      { title: "Papua New Guinean", value: "Papua New Guinean" },
      { title: "Paraguayan", value: "Paraguayan" },
      { title: "Peruvian", value: "Peruvian" },
      { title: "Philippine", value: "Philippine" },
      { title: "Polish", value: "Polish" },
      { title: "Portuguese", value: "Portuguese" },
      { title: "Qatari", value: "Qatari" },
      { title: "Romanian", value: "Romanian" },
      { title: "Russian", value: "Russian" },
      { title: "Rwandan", value: "Rwandan" },
      { title: "Salvadorian", value: "Salvadorian" },
      { title: "Sammarinese", value: "Sammarinese" },
      { title: "Samoan", value: "Samoan" },
      { title: "Sao Tomean", value: "Sao Tomean" },
      { title: "Saudi Arabian", value: "Saudi Arabian" },
      { title: "Senegalese", value: "Senegalese" },
      { title: "Serbian", value: "Serbian" },
      { title: "Seychellois", value: "Seychellois" },
      { title: "Sierra Leone", value: "Sierra Leone" },
      { title: "Slovak", value: "Slovak" },
      { title: "Slovenian", value: "Slovenian" },
      { title: "Solomon Islander", value: "Solomon Islander" },
      { title: "Somali", value: "Somali" },
      { title: "South African", value: "South African" },
      { title: "Spanish", value: "Spanish" },
      { title: "Sri Lankan", value: "Sri Lankan" },
      { title: "ST. Lucia", value: "ST. Lucia" },
      { title: "ST. Vincentian", value: "ST. Vincentian" },
      { title: "Stateless", value: "Stateless" },
      { title: "Sudanese", value: "Sudanese" },
      { title: "Surinamer", value: "Surinamer" },
      { title: "Swazi", value: "Swazi" },
      { title: "Swedish", value: "Swedish" },
      { title: "Swiss", value: "Swiss" },
      { title: "Syrian", value: "Syrian" },
      { title: "Tajikistani", value: "Tajikistani" },
      { title: "Tanzanian", value: "Tanzanian" },
      { title: "Thai", value: "Thai" },
      { title: "Togolese", value: "Togolese" },
      { title: "Tongan", value: "Tongan" },
      { title: "Trinidadian & Tobagonian", value: "Trinidadian & Tobagonian" },
      { title: "Tunisian", value: "Tunisian" },
      { title: "Turk", value: "Turk" },
      { title: "Turkmen", value: "Turkmen" },
      { title: "Tuvalu", value: "Tuvalu" },
      { title: "Ugandan", value: "Ugandan" },
      { title: "Ukrainian", value: "Ukrainian" },
      { title: "United Arab Emirates", value: "United Arab Emirates" },
      { title: "Uruguayan", value: "Uruguayan" },
      { title: "Uzbekistan", value: "Uzbekistan" },
      { title: "Vatican City State (Holy See)", value: "Vatican City State (Holy See)" },
      { title: "Venezuelan", value: "Venezuelan" },
      { title: "Vietnamese", value: "Vietnamese" },
      { title: "Yemeni", value: "Yemeni" },
      { title: "Zambian", value: "Zambian" },
      { title: "Zimbabwean", value: "Zimbabwean" },
    ],
    identificationTypes: [
      {
        title: "NRIC (Citizen)",
        value: "NRIC (Citizen)",
      },
      {
        title: "NRIC (Permanent Resident)",
        value: "NRIC (Permanent Resident)",
      },
      {
        title: "FIN",
        value: "FIN",
      },
      {
        title: "Passport/Others",
        value: "Passport/Others",
      },
    ],
    companyMemberTypes: [
      {
        title: "Individual",
        value: "INDIVIDUAL",
      },
      {
        title: "Company",
        value: "CORPORATE",
      },
    ],
    positionHelds: [
      {
        title: "Shareholder",
        value: "SHAREHOLDER",
        selectable: ["CORPORATE", "INDIVIDUAL"],
      },
      {
        title: "Director",
        value: "DIRECTOR",
        selectable: ["INDIVIDUAL"],
      },
      {
        title: "Shareholder+Director",
        value: "DIRECTOR_AND_SHAREHOLDER",
        selectable: ["INDIVIDUAL"],
      },
      {
        title: "Nominee Director",
        value: "NOMINEE_DIRECTOR",
        selectable: [],
      },
    ],
    typeOfShares: [
      {
        title: "Ordinary",
        value: "Ordinary",
      },
      {
        title: "Preference",
        value: "Preference",
      },
      {
        title: "Others",
        value: "Others",
      },
    ],
    stepStatusList: [
      {
        title: "Rejected",
        value: 0,
      },
      {
        title: "Pass",
        value: 1,
      },
      {
        title: "Revised",
        value: 2,
        unselectable: true,
      },
      {
        title: "No Checking",
        value: 3,
        unselectable: true,
      },
    ],
    companyTypeList: [
      {
        title: "Exempt Private Company Limited by Shares",
        value: "Exempt Private Company Limited by Shares",
      },
      {
        title: "Private Company Limited by Shares",
        value: "Private Company Limited by Shares",
      },
      // {
      //   title: "Public Company Limited By Gurantee",
      //   value: "Public Company Limited By Gurantee",
      // },
      // {
      //   title: "Public Company Limited By Shares",
      //   value: "Public Company Limited By Shares",
      // },
      // {
      //   title: "Unlimited Exempt Private Company",
      //   value: "Unlimited Exempt Private Company",
      // },
      // {
      //   title: "Unlimited Private Company",
      //   value: "Unlimited Private Company",
      // },
      // {
      //   title: "Unlimited Public Company",
      //   value: "Unlimited Public Company",
      // },
    ],
    companyTypeSuffixList: {
      "Exempt Private Company Limited by Shares": [
        "PRIVATE LIMITED",
        "PRIVATE LTD.",
        "PTE. LIMITED",
        "PTE. LTD.",
        "SDN. BERHAD",
        "SDN. BHD.",
        "SENDIRIAN BERHAD",
        "SENDIRIAN BHD.",
        "(PRIVATE LIMITED)",
        "(PRIVATE LTD.)",
        "(PTE. LIMITED)",
        "(PTE. LTD.)",
        "(PTE.) LIMITED",
        "(PTE.) LTD.",
        "(SDN. BERHAD)",
        "(SDN. BHD.)",
        "(SDN.) BERHAD",
        "(SDN.) BHD.",
        "(SENDIRIAN BERHAD)",
        "(SENDIRIAN BHD.)",
        "(SENDIRIAN) BERHAD",
        "(SENDIRIAN) BHD.",
      ],

      "Private Company Limited by Shares": [
        "PRIVATE LIMITED",
        "PRIVATE LTD.",
        "PTE. LIMITED",
        "PTE. LTD.",
        "SDN. BERHAD",
        "SDN. BHD.",
        "SENDIRIAN BERHAD",
        "SENDIRIAN BHD.",
        "(PRIVATE LIMITED)",
        "(PRIVATE LTD.)",
        "(PTE. LIMITED)",
        "(PTE. LTD.)",
        "(PTE.) LIMITED",
        "(PTE.) LTD.",
        "LAW CORPORATION",
        "LLC",
        "PAC",
        "(LAW CORPORATION)",
        "(LLC)",
        "(PAC)",
        "(SDN. BERHAD)",
        "(SDN. BHD.)",
        "(SDN.) BERHAD",
        "(SDN.) BHD.",
        "(SENDIRIAN BERHAD)",
        "(SENDIRIAN BHD.)",
        "(SENDIRIAN) BERHAD",
        "(SENDIRIAN) BHD.",
      ],

      "Public Company Limited By Gurantee": ["BERHAD", "BHD.", "LIMITED", "LTD.", "(BERHAD)", "(BHD.)", "(LIMITED)", "(LTD.)"],
      "Public Company Limited By Shares": ["BERHAD", "BHD.", "LIMITED", "LTD.", "(BERHAD)", "(BHD.)", "(LIMITED)", "(LTD.)"],

      "Unlimited Exempt Private Company": ["PRIVATE", "PTE.", "SDN.", "SENDIRIAN", "(PRIVATE)", "(PTE.)", "(SDN.)", "(SENDIRIAN)"],
      "Unlimited Private Company": ["PRIVATE", "PTE.", "SDN.", "SENDIRIAN", "(PRIVATE)", "(PTE.)", "(SDN.)", "(SENDIRIAN)"],

      "Unlimited Public Company": ["UNLIMITED", "(UNLIMITED)"],
    },
    financialYearPeriods: [
      {
        title: "12 months",
        value: "12 months",
      },
      {
        title: "52-week accounting period",
        value: "52-week accounting period",
      },
    ],
    workingHoursOptions: [
      {
        title: "At least 5 hours during ordinary business hours on each business day",
        value: "at least 5",
      },
      {
        title: "At least 3 hours but less than 5 hours during ordinary business hours on each business day",
        value: "3 to 5",
      },
    ],
    customizedConstitutionOptions: [
      {
        title: "Standard constitution",
        value: 0,
      },
      {
        title: "Customized constitution (customized constitution reviewed by legal is required to be uploaded in the next step under this option)",
        value: 1,
      },
    ],
    authoritiesOptions: [
      {
        title: "Accounting & Corporate Regulatory Authority - ACRA",
        value: "ACRA",
      },
      {
        title: "Board of Architects, Singapore - BOA",
        value: "BOA",
      },
      {
        title: "Majlis Ugama Islam Singapura - MUIS",
        value: "MUIS",
      },
      {
        title: "Ministry of Culture, Community and Youth - Charities Unit",
        value: "Charities",
      },
      {
        title: "Minlaw (legal services regulatory authority) - Minlaw",
        value: "Minlaw",
      },
      {
        title: "Monetary Authority of Singapore (Banking Department) - MAS_Bank",
        value: "MAS_Bank",
      },
      {
        title: "Council for Estate Agencies - CEA",
        value: "CEA",
      },
      {
        title: "Enterprise Singapore - CTA",
        value: "CTA",
      },
      {
        title: "Minsitry of Defence - Diso - Mindef",
        value: "Mindef",
      },
      {
        title: "Minsitry of education - private schools section - MOE",
        value: "MOE",
      },
      {
        title: "Professional Engineers Board - Professional Engineers Board",
        value: "Professional",
      },
      {
        title: "Singapore Tourism Board - STB",
        value: "STB",
      },
      {
        title: "Enterprise Singapore - RCA",
        value: "RCA",
      },
      {
        title: "Ministry of foreign affairs - MF",
        value: "MFA",
      },
    ],
    currencyOptions: [
      {
        value: "SGD",
        title: "SGD Singapore, Dollars",
      },
      {
        value: "USD",
        title: "USD United States of America,Dollars",
      },
      {
        value: "CNY",
        title: "CNY China, Yuan Renminbi",
      },
      {
        value: "HKD",
        title: "HKD Hong Kong, Dollars",
      },
      {
        value: "AFN",
        title: "AFN AFGHANISTAN, AFGHANI",
      },
      {
        value: "ALL",
        title: "ALL ALBANIA, LEK",
      },
      {
        value: "DZD",
        title: "DZD ALGERIA, DINARS",
      },
      {
        value: "AOA",
        title: "AOA ANGOLA, KWANZA",
      },
      {
        value: "ARS",
        title: "ARS ARGENTINA, PESOS",
      },
      {
        value: "AMD",
        title: "AMD ARENIA, DRAMS",
      },
      {
        value: "AWG",
        title: "AWG ARUBAN, FLORIN",
      },
      {
        value: "AUD",
        title: "AUD AUSTRALIA, DOLLARS",
      },
      {
        value: "AZN",
        title: "AZN AZERBAIJAN, MANAT",
      },
      {
        value: "BSD",
        title: "BSD BAHAMAS, DOLLARS",
      },
      {
        value: "BHD",
        title: "BHD BAHRAIN, DINARS",
      },
      {
        value: "BDT",
        title: "BDT BANGLADESH, TAKA",
      },
      {
        value: "BBD",
        title: "BBD BARBADOS, DOLLARS",
      },
      {
        value: "BYN",
        title: "BYN BELARUS, RUBLES",
      },
      {
        value: "BYR",
        title: "BYR BELARUSIAN, RUBEL",
      },
      {
        value: "BZD",
        title: "BZD BELIZE, DOLLARS",
      },
      {
        value: "BMD",
        title: "BMD BERMUDA, DOLLARS",
      },
      {
        value: "BTN",
        title: "BTN BHUTAN, NGULTRUM",
      },
      {
        value: "VEF",
        title: "VEF BOLÍVAR, FUERTE",
      },
      {
        value: "BOV",
        title: "BOV BOLIVIAN, MVDOL",
      },
      {
        value: "BOB",
        title: "BOB BOLIVIANO, ",
      },
      {
        value: "XBA",
        title: "XBA BOND MARKETS UNITS, EUROPEAN COMPOSITE UNIT",
      },
      {
        value: "BAM",
        title: "BAM BOSNIA AND HERZEGOVINA, CONVERTIBLE MARK",
      },
      {
        value: "BWP",
        title: "BWP BOTSWANA, PULAS",
      },
      {
        value: "BRL",
        title: "BRL BRAZIL, REAL",
      },
      {
        value: "BND",
        title: "BND BRUNEI DARUSSALAM, DOLLARS",
      },
      {
        value: "BGN",
        title: "BGN BULGARIA, LEV",
      },
      {
        value: "BIF",
        title: "BIF BURUNDI, FRANCS",
      },
      {
        value: "KHR",
        title: "KHR CAMBODIA, RIELS",
      },
      {
        value: "CAD",
        title: "CAD CANADA, DOLLARS",
      },
      {
        value: "CVE",
        title: "CVE CAPE VERDE, ESCUDOS",
      },
      {
        value: "KYD",
        title: "KYD CAYMAN ISLANDS, DOLLARS",
      },
      {
        value: "GHS",
        title: "GHS CEDI",
      },
      {
        value: "CLP",
        title: "CLP CHILE, PESOS",
      },
      {
        value: "COP",
        title: "COP COLOMBIA, PESOS",
      },
      {
        value: "XAF",
        title: "XAF COMMUNAUTE FINANCIERE AFRICAINE BEATS, FRANCS",
      },
      {
        value: "XOF",
        title: "XOF COMMUNAUTE FINANCIERE AFRICAINE FRANCS BCEAO, FRANCS",
      },
      {
        value: "KMF",
        title: "KMF COMOROS, FRANCS",
      },
      {
        value: "XPF",
        title: "XPF COMPTOIRS FRANCAIS DU PACIFIQUE, FRANCS",
      },
      {
        value: "CDF",
        title: "CDF CONGOLESE, FRANCS",
      },
      {
        value: "NIO",
        title: "NIO CÓRDOBA, ORO",
      },
      {
        value: "CRC",
        title: "CRC COSTA RICA, COLONES",
      },
      {
        value: "HRK",
        title: "HRK CROATIA, KUNA",
      },
      {
        value: "CUP",
        title: "CUP CUBA, PESOS",
      },
      {
        value: "CYP",
        title: "CYP CYPRUS, POUNDS",
      },
      {
        value: "CZK",
        title: "CZK CZECHIA, KORUNA",
      },
      {
        value: "DKK",
        title: "DKK DANISH, KRONE",
      },
      {
        value: "DJF",
        title: "DJF DJIBOUTI, FRANCS",
      },
      {
        value: "DOP",
        title: "DOP DOMINICAN REPUBLIC, PESOS",
      },
      {
        value: "XCD",
        title: "XCD EAST CARIBBEAN, DOLLARS",
      },
      {
        value: "EGP",
        title: "EGP EGYPT, POUNDS",
      },
      {
        value: "SVC",
        title: "SVC EL SALVADOR, COLONES",
      },
      {
        value: "ERN",
        title: "ERN ERITREA, NAKFA",
      },
      {
        value: "EEK",
        title: "EEK ESTONIA, KROONI",
      },
      {
        value: "ETB",
        title: "ETB ETHIOPIA, BIRR",
      },
      {
        value: "EUR",
        title: "EUR EURO MEMBER COUNTRIES, EURO",
      },
      {
        value: "XBB",
        title: "XBB EUROPEAN MONETARY UNIT, ",
      },
      {
        value: "XBC",
        title: "XBC EUROPEAN UNIT OF ACCOUNT, ",
      },
      {
        value: "XBD",
        title: "XBD EUROPEAN UNIT OF ACCOUNT, ",
      },
      {
        value: "FKP",
        title: "FKP FALKLAND ISLANDS(MALVINAS), POUNDS",
      },
      {
        value: "FJD",
        title: "FJD FIJI, DOLLARS",
      },
      {
        value: "GMD",
        title: "GMD GAMBIA, DALASI",
      },
      {
        value: "GEL",
        title: "GEL GEORGIA, LARI",
      },
      {
        value: "GHC",
        title: "GHC GHANA, CEDIS",
      },
      {
        value: "GIP",
        title: "GIP GIBRALTAR, POUNDS",
      },
      {
        value: "XAU",
        title: "XAU GOLD, OUNCES",
      },
      {
        value: "GTQ",
        title: "GTQ GUATEALA, QUETZALES",
      },
      {
        value: "GGP",
        title: "GGP GUERNSEY, POUNDS",
      },
      {
        value: "GNF",
        title: "GNF GUINEA, FRANCS",
      },
      {
        value: "GYD",
        title: "GYD GUYANA, DOLLARS",
      },
      {
        value: "HTG",
        title: "HTG HAITI, GOURDES",
      },
      {
        value: "HNL",
        title: "HNL HONDURAS, LEMPIRAS",
      },
      {
        value: "HUF",
        title: "HUF HUNGARY, FORINT",
      },
      {
        value: "ISK",
        title: "ISK ICELANDIC, KRONA",
      },
      {
        value: "INR",
        title: "INR INDIA, REPEES",
      },
      {
        value: "IDR",
        title: "IDR INDONESIA, RUPIAHS",
      },
      {
        value: "XDR",
        title: "XDR INTERNATIONAL MONETARY FUND(IMF), SPECIAL DRAWING RIGHTS",
      },
      {
        value: "IRR",
        title: "IRR IRAN, RIALS",
      },
      {
        value: "IQD",
        title: "IQD IRAQ, DINARS",
      },
      {
        value: "IMP",
        title: "IMP ISLE OF MAN, POUNDS",
      },
      {
        value: "ILS",
        title: "ILS ISRAEL, NEW SHEKELS",
      },
      {
        value: "JMD",
        title: "JMD JAMAICA, DOLLARS",
      },
      {
        value: "JPY",
        title: "JPY JAPAN, YEN",
      },
      {
        value: "JEP",
        title: "JEP JERSEY, POUNDS",
      },
      {
        value: "JOD",
        title: "JOD JORDAN, DINARS",
      },
      {
        value: "KZT",
        title: "KZT KAZAKHSTAN, TENGE",
      },
      {
        value: "KES",
        title: "KES KENYA, SHILLINGS",
      },
      {
        value: "KRI",
        title: "KRI KIRIBATI, DOLLAR",
      },
      {
        value: "KPW",
        title: "KPW KOREA(NORTH), WON",
      },
      {
        value: "KRW",
        title: "KRW KOREA(SOUTH), WON",
      },
      {
        value: "KWD",
        title: "KWD KUWAIT, DINARS",
      },
      {
        value: "KGS",
        title: "KGS KYRGYZSTAN, SOMS",
      },
      {
        value: "LAK",
        title: "LAK LAOS, KIPS",
      },
      {
        value: "LVL",
        title: "LVL LATVIAN, LATS",
      },
      {
        value: "LBP",
        title: "LBP LEBANON, POUNDS",
      },
      {
        value: "LSL",
        title: "LSL LESOTHO, LOTI",
      },
      {
        value: "LRD",
        title: "LRD LIBERIA, DOLLARS",
      },
      {
        value: "LYD",
        title: "LYD LIBYA, DINARS",
      },
      {
        value: "LTL",
        title: "LTL LITHUANIAN, LITAS",
      },
      {
        value: "MOP",
        title: "MOP MACAU, PATACAS",
      },
      {
        value: "MKD",
        title: "MKD MACEDONIA, DENARS",
      },
      {
        value: "MGA",
        title: "MGA MADAGASCAR, ARIARY",
      },
      {
        value: "MWK",
        title: "MWK MALAWI, KWACHAS",
      },
      {
        value: "MYR",
        title: "MYR MALAYSIA, RINGGIT",
      },
      {
        value: "MVR",
        title: "MVR MALDIVES (MALDIVE ISLANDS), RUFIYAA",
      },
      {
        value: "MTL",
        title: "MTL MALTA, LIRI",
      },
      {
        value: "MRO",
        title: "MRO MAURITANIA, OUGUIYAS",
      },
      {
        value: "MRU",
        title: "MRU MAURITANIA, OUGUIYAS",
      },
      {
        value: "MUR",
        title: "MUR MAURITIUS, RUPEE",
      },
      {
        value: "MXV",
        title: "MXV MEXICAN, UNIDAD DE INVERSION(UDI)",
      },
      {
        value: "MXN",
        title: "MXN MEXICO, MEXICAN PESO",
      },
      {
        value: "MDL",
        title: "MDL MOLDOVA, LEU",
      },
      {
        value: "MNT",
        title: "MNT MONGOLIA, TUGRIKS",
      },
      {
        value: "MAD",
        title: "MAD MOROCCO, DIRHAMS",
      },
      {
        value: "MZN",
        title: "MZN MOZAMBIQUE, METICALS",
      },
      {
        value: "MMK",
        title: "MMK MYANMAR(BURMA), KYATS",
      },
      {
        value: "NAD",
        title: "NAD NAMIBIA, DOLLARS",
      },
      {
        value: "NPR",
        title: "NPR NEPAL, NEPALESE RUPEE",
      },
      {
        value: "ANG",
        title: "ANG NETHERLANDS ANTILLES, GUILDERS",
      },
      {
        value: "NZD",
        title: "NZD NEW ZEALAND, DOLLARS",
      },
      {
        value: "NGN",
        title: "NGN NIGERIA, NAIRAS",
      },
      {
        value: "XXX",
        title: "XXX NO CURRENCY, ",
      },
      {
        value: "NOK",
        title: "NOK NORWEGIAN, KRONE",
      },
      {
        value: "OMR",
        title: "OMR OMAN, RIALS",
      },
      {
        value: "OTH",
        title: "OTH OTHERS",
      },
      {
        value: "PKR",
        title: "PKR PAKISTAN, RUPEES",
      },
      {
        value: "XPD",
        title: "XPD PALLADIUM, OUNCES",
      },
      {
        value: "PAB",
        title: "PAB PANAMA, BALBOA",
      },
      {
        value: "PGK",
        title: "PGK PAPUA NEW GUINEA, KINA",
      },
      {
        value: "PYG",
        title: "PYG PARAGUAY, GUARANÍ",
      },
      {
        value: "PEN",
        title: "PEN PERU, NUEVO SOLES",
      },
      {
        value: "CUC",
        title: "CUC PESO, CONVERTIBLE",
      },
      {
        value: "PHP",
        title: "PHP PHILIPPINES, PESOS",
      },
      {
        value: "XPT",
        title: "XPT PLATINUM, OUNCES",
      },
      {
        value: "PLN",
        title: "PLN POLISH, ZLOTY",
      },
      {
        value: "QAR",
        title: "QAR QATAR, RIALS",
      },
      {
        value: "RON",
        title: "RON ROMANIA, LEU",
      },
      {
        value: "RUB",
        title: "RUB RUSSIA, REBELS",
      },
      {
        value: "RWF",
        title: "RWF RWANDA, FRANCS",
      },
      {
        value: "SHP",
        title: "SHP SAINT HELENA, POUNDS",
      },
      {
        value: "WST",
        title: "WST SAMOA, TALA",
      },
      {
        value: "STD",
        title: "STD SAO TOME AND PRINCIPE, ",
      },
      {
        value: "STN",
        title: "STN SAO TOME AND PRINCIPE, DOBRAS",
      },
      {
        value: "SAR",
        title: "SAR SAUDI ARABIA, RIYALS",
      },
      {
        value: "SPL",
        title: "SPL SEBORGA, LUIGINI",
      },
      {
        value: "RSD",
        title: "RSD SERBIA, DINARS",
      },
      {
        value: "SCR",
        title: "SCR SEYCHELLES, RUPEES",
      },
      {
        value: "SLL",
        title: "SLL SIERRA LEONE, LEONES",
      },
      {
        value: "XAG",
        title: "XAG SILVER, OUNCES",
      },
      {
        value: "SKK",
        title: "SKK SLOVAKIA, KORUNY",
      },
      {
        value: "SBD",
        title: "SBD SOLOMON ISLANDS, DOLLARS",
      },
      {
        value: "SOS",
        title: "SOS SOMALIA, SHILLINGS",
      },
      {
        value: "SLS",
        title: "SLS SOMALILAND, SHILLING",
      },
      {
        value: "ZAR",
        title: "ZAR SOUTH AFRICA, RAND",
      },
      {
        value: "SSP",
        title: "SSP SOUTH SUDANESE, POUND",
      },
      {
        value: "LKR",
        title: "LKR SRI LANKA, RUPEES",
      },
      {
        value: "SDD",
        title: "SDD SUDAN, POUND",
      },
      {
        value: "SDG",
        title: "SDG SUDANESE, POUND",
      },
      {
        value: "SRD",
        title: "SRD SURINAME, DOLLARS",
      },
      {
        value: "SZL",
        title: "SZL SWAZI, LILANGENI",
      },
      {
        value: "SEK",
        title: "SEK SWEDEN, KRONOR",
      },
      {
        value: "CHF",
        title: "CHF SWITZERLAND, FRANCS",
      },
      {
        value: "SYP",
        title: "SYP SYRIAN, POUND",
      },
      {
        value: "TWD",
        title: "TWD TAIWAN, NEW DOLLAR",
      },
      {
        value: "TJS",
        title: "TJS TAJIKISTAN, SOMONI",
      },
      {
        value: "TZS",
        title: "TZS TANZANIA, SHILLINGS",
      },
      {
        value: "THB",
        title: "THB THAILAND, BAHT",
      },
      {
        value: "TOP",
        title: "TOP TONGA, PA'ANGA",
      },
      {
        value: "PRB",
        title: "PRB TRANSNISTRIAN, RUBLE",
      },
      {
        value: "TTD",
        title: "TTD TRINIDAD AND TOBAGO, DOLLARS",
      },
      {
        value: "TND",
        title: "TND TUNISIA, DINARS",
      },
      {
        value: "TRY",
        title: "TRY TURKISH, LIRA",
      },
      {
        value: "TMT",
        title: "TMT TURKMENISTAN, NEW MANAT",
      },
      {
        value: "TMM",
        title: "TMM TURKMENISTAN, MANATS",
      },
      {
        value: "TVD",
        title: "TVD TUVALU, TUVALUAN DOLLARS",
      },
      {
        value: "UGX",
        title: "UGX UGANDA, SHILLINGS",
      },
      {
        value: "XFU",
        title: "XFU UIC, FRANC",
      },
      {
        value: "UAH",
        title: "UAH UKRAINE, HRYWNJA",
      },
      {
        value: "COU",
        title: "COU UNIDAD, DE VALOR REAL",
      },
      {
        value: "CLF",
        title: "CLF UNIDADES, DE FOMENTO",
      },
      {
        value: "AED",
        title: "AED UNITED ARAB EMIRATES, DIRHAMS",
      },
      {
        value: "GBP",
        title: "GBP UNITED KINGDOM, POUNDS",
      },
      {
        value: "USN",
        title: "USN UNITED STATES DOLLAR(NEXT DAY), FUNDS CODE",
      },
      {
        value: "USS",
        title: "USS UNITED STATES DOLLAR(SAME DAY), FUNDS CODE",
      },
      {
        value: "UYU",
        title: "UYU URUGUAY, PESOS",
      },
      {
        value: "UYW",
        title: "UYW URUGUAY, UNIDAD PREVISONAL",
      },
      {
        value: "UYI",
        title: "UYI URUGUAY PESO EN UNIDADES INDEXADSA (URUIURUI) (FUNDS CODE)",
      },
      {
        value: "UZS",
        title: "UZS UZBEKISTAN, SUMS",
      },
      {
        value: "VUV",
        title: "VUV VANUATU, VATU",
      },
      {
        value: "VEB",
        title: "VEB VENEZUELA, BOLIVARES",
      },
      {
        value: "VES",
        title: "VES VENEZUELA(BOLIVARIAN REPUBLIC OF), BOLIVAR SOBERANO",
      },
      {
        value: "VND",
        title: "VND VIETNAM, DONG",
      },
      {
        value: "CHE",
        title: "CHE WIR, EURO",
      },
      {
        value: "CHW",
        title: "CHW WIR, FRANC",
      },
      {
        value: "YER",
        title: "YER YEMEN, RIALS",
      },
      {
        value: "ZMK",
        title: "ZMK ZAMBIA, KWACHA",
      },
    ],
    prefixOptions: [
      {
        value: "65",
        title: "Singapore",
      },
      {
        value: "86",
        title: "China",
      },
      {
        value: "852",
        title: "Chinese/Hong Kong Sar",
      },
      {
        value: "853",
        title: "Chinese/Macau Sar",
      },
      {
        value: "886",
        title: "Chinese/Taiwan",
      },
      {
        value: "93",
        title: "Afghanistan",
      },
      {
        value: "355",
        title: "Albania",
      },
      {
        value: "213",
        title: "Algera",
      },
      {
        value: "376",
        title: "Andorra",
      },
      {
        value: "244",
        title: "Angola",
      },
      {
        value: "1264",
        title: "Anguilla",
      },
      {
        value: "247",
        title: "Ascension",
      },
      {
        value: "1268",
        title: "Antigua and Barbuda",
      },
      {
        value: "54",
        title: "Argentina",
      },
      {
        value: "374",
        title: "Armenia",
      },
      {
        value: "297",
        title: "Aruba",
      },
      {
        value: "61",
        title: "Australia",
      },
      {
        value: "43",
        title: "Austria",
      },
      {
        value: "994",
        title: "Azerbaijan",
      },
      {
        value: "1242",
        title: "Bahamas",
      },
      {
        value: "973",
        title: "Bahrain",
      },
      {
        value: "880",
        title: "Bangladesh",
      },
      {
        value: "1246",
        title: "Barbados",
      },
      {
        value: "375",
        title: "Belarus",
      },
      {
        value: "32",
        title: "Belgium",
      },
      {
        value: "501",
        title: "Belize",
      },
      {
        value: "229",
        title: "Benin",
      },
      {
        value: "1441",
        title: "Bermuda",
      },
      {
        value: "975",
        title: "Bhutan",
      },
      {
        value: "591",
        title: "Bolivia",
      },
      {
        value: "387",
        title: "Bosnia and Herzegovina",
      },
      {
        value: "267",
        title: "Botwana",
      },
      {
        value: "55",
        title: "Brazill",
      },
      {
        value: "673",
        title: "Brunei",
      },
      {
        value: "359",
        title: "Bulgaria",
      },
      {
        value: "226",
        title: "Burkina Faso",
      },
      {
        value: "257",
        title: "Burundi",
      },
      {
        value: "855",
        title: "Cambodia",
      },
      {
        value: "237",
        title: "Cameroon",
      },
      {
        value: "1",
        title: "Canada",
      },
      {
        value: "238",
        title: "Cape Verde",
      },
      {
        value: "1345",
        title: "Cayman Islands",
      },
      {
        value: "236",
        title: "Central African Republic",
      },
      {
        value: "235",
        title: "Chad",
      },
      {
        value: "56",
        title: "Chile",
      },
      {
        value: "57",
        title: "Colombia",
      },
      {
        value: "269",
        title: "Comoros",
      },
      {
        value: "242",
        title: "Republic of the Congo",
      },
      {
        value: "243",
        title: "Democratic Republic of the Congo",
      },
      {
        value: "682",
        title: "Cook Islands",
      },
      {
        value: "506",
        title: "Costa Rica",
      },
      {
        value: "225",
        title: "Cote divoire",
      },
      {
        value: "385",
        title: "Croatia",
      },
      {
        value: "53",
        title: "Cuba",
      },
      {
        value: "357",
        title: "Cyprus",
      },
      {
        value: "420",
        title: "Czech Republic",
      },
      {
        value: "45",
        title: "Denmark",
      },
      {
        value: "253",
        title: "Djibouti",
      },
      {
        value: "1767",
        title: "Dominica",
      },
      {
        value: "1809",
        title: "Dominican Republic",
      },
      {
        value: "593",
        title: "Ecuador",
      },
      {
        value: "20",
        title: "Egypt",
      },
      {
        value: "503",
        title: "EISalvador",
      },
      {
        value: "372",
        title: "Estonia",
      },
      {
        value: "251",
        title: "Ethiopia",
      },
      {
        value: "298",
        title: "Faroe Islands",
      },
      {
        value: "679",
        title: "Fiji",
      },
      {
        value: "358",
        title: "Finland",
      },
      {
        value: "33",
        title: "France",
      },
      {
        value: "594",
        title: "French Guiana",
      },
      {
        value: "689",
        title: "French Polynesia",
      },
      {
        value: "241",
        title: "Gabon",
      },
      {
        value: "220",
        title: "Gambia",
      },
      {
        value: "995",
        title: "Georgia",
      },
      {
        value: "94",
        title: "Germany",
      },
      {
        value: "233",
        title: "Ghana",
      },
      {
        value: "350",
        title: "Gibraltar",
      },
      {
        value: "30",
        title: "Greece",
      },
      {
        value: "299",
        title: "Greenland",
      },
      {
        value: "1473",
        title: "Grenada",
      },
      {
        value: "590",
        title: "Guadeloupe",
      },
      {
        value: "1671",
        title: "Guam",
      },
      {
        value: "502",
        title: "Guatemala",
      },
      {
        value: "240",
        title: "Equatorial Guinea",
      },
      {
        value: "245",
        title: "Guinea-Bissau",
      },
      {
        value: "44",
        title: "Guernsey",
      },
      {
        value: "224",
        title: "Guinea",
      },
      {
        value: "592",
        title: "Guyana",
      },
      {
        value: "509",
        title: "Haiti",
      },
      {
        value: "504",
        title: "Honduras",
      },
      {
        value: "95",
        title: "Myanmar",
      },
      {
        value: "36",
        title: "Hungary",
      },
      {
        value: "354",
        title: "Iceland",
      },
      {
        value: "91",
        title: "Indea",
      },
      {
        value: "62",
        title: "Indonesia",
      },
      {
        value: "98",
        title: "Iran",
      },
      {
        value: "964",
        title: "Iraq",
      },
      {
        value: "353",
        title: "Ireland",
      },
      {
        value: "44",
        title: "Isle of Man",
      },
      {
        value: "972",
        title: "Israel",
      },
      {
        value: "93",
        title: "Italy",
      },
      {
        value: "1876",
        title: "Jamaica",
      },
      {
        value: "81",
        title: "Japan",
      },
      {
        value: "44",
        title: "Jersey",
      },
      {
        value: "962",
        title: "Jordan",
      },
      {
        value: "7",
        title: "Kazeakhstan",
      },
      {
        value: "254",
        title: "Kenya",
      },
      {
        value: "383",
        title: "Kosovo",
      },
      {
        value: "965",
        title: "Kuwait",
      },
      {
        value: "996",
        title: "Kyrgyzstan",
      },
      {
        value: "856",
        title: "Laos",
      },
      {
        value: "371",
        title: "Latvia",
      },
      {
        value: "961",
        title: "Lebanon",
      },
      {
        value: "266",
        title: "Lesotho",
      },
      {
        value: "231",
        title: "Liberia",
      },
      {
        value: "218",
        title: "Libya",
      },
      {
        value: "423",
        title: "Liechtenstein",
      },
      {
        value: "370",
        title: "Lithuania",
      },
      {
        value: "352",
        title: "Luxembourg",
      },
      {
        value: "389",
        title: "Macedonia",
      },
      {
        value: "261",
        title: "Madagascar",
      },
      {
        value: "265",
        title: "Malawi",
      },
      {
        value: "60",
        title: "Malaysia",
      },
      {
        value: "960",
        title: "Maldives",
      },
      {
        value: "223",
        title: "Mali",
      },
      {
        value: "356",
        title: "Malta",
      },
      {
        value: "596",
        title: "Martinique",
      },
      {
        value: "222",
        title: "Mauritania",
      },
      {
        value: "230",
        title: "Mauritius",
      },
      {
        value: "262",
        title: "Mayotte",
      },
      {
        value: "52",
        title: "Mexico",
      },
      {
        value: "373",
        title: "Moldova",
      },
      {
        value: "377",
        title: "Monaco",
      },
      {
        value: "976",
        title: "Mongolia",
      },
      {
        value: "382",
        title: "Montenegro",
      },
      {
        value: "1664",
        title: "Montserrat",
      },
      {
        value: "212",
        title: "Morocco",
      },
      {
        value: "258",
        title: "Mozambique",
      },
      {
        value: "264",
        title: "Namibia",
      },
      {
        value: "977",
        title: "Nepal",
      },
      {
        value: "31",
        title: "Netherlands",
      },
      {
        value: "599",
        title: "Netherlands Antillse",
      },
      {
        value: "687",
        title: "New Caledonia",
      },
      {
        value: "64",
        title: "NewZealand",
      },
      {
        value: "505",
        title: "Nicaragua",
      },
      {
        value: "227",
        title: "Niger",
      },
      {
        value: "234",
        title: "Nigeria",
      },
      {
        value: "47",
        title: "Norway",
      },
      {
        value: "968",
        title: "Oman",
      },
      {
        value: "92",
        title: "Pakistan",
      },
      {
        value: "970",
        title: "Palestinian",
      },
      {
        value: "507",
        title: "Panama",
      },
      {
        value: "675",
        title: "Papua New Guinea",
      },
      {
        value: "595",
        title: "Paraguay",
      },
      {
        value: "51",
        title: "Peru",
      },
      {
        value: "63",
        title: "Philippines",
      },
      {
        value: "48",
        title: "Poland",
      },
      {
        value: "351",
        title: "Portugal",
      },
      {
        value: "1",
        title: "PuertoRico",
      },
      {
        value: "974",
        title: "Qotar",
      },
      {
        value: "262",
        title: "Reunion",
      },
      {
        value: "40",
        title: "Romania",
      },
      {
        value: "7",
        title: "Russia",
      },
      {
        value: "250",
        title: "Rwanda",
      },
      {
        value: "684",
        title: "Samoa Eastern",
      },
      {
        value: "685",
        title: "Samoa Western",
      },
      {
        value: "378",
        title: "San Marino",
      },
      {
        value: "239",
        title: "Sao Tome and Principe",
      },
      {
        value: "966",
        title: "Saudi Arabia",
      },
      {
        value: "221",
        title: "Senegal",
      },
      {
        value: "381",
        title: "Serbia",
      },
      {
        value: "248",
        title: "Seychelles",
      },
      {
        value: "232",
        title: "Sierra Leone",
      },
      {
        value: "421",
        title: "Slovakia",
      },
      {
        value: "386",
        title: "Slovenia",
      },
      {
        value: "27",
        title: "South Africa",
      },
      {
        value: "82",
        title: "Korea",
      },
      {
        value: "34",
        title: "Spain",
      },
      {
        value: "94",
        title: "SriLanka",
      },
      {
        value: "1869",
        title: "St Kitts and Nevis",
      },
      {
        value: "1758",
        title: "St.Lucia",
      },
      {
        value: "1784",
        title: "St.Vincent",
      },
      {
        value: "249",
        title: "Sudan",
      },
      {
        value: "597",
        title: "Suriname",
      },
      {
        value: "268",
        title: "Swaziland",
      },
      {
        value: "46",
        title: "Sweden",
      },
      {
        value: "41",
        title: "Switzerland",
      },
      {
        value: "963",
        title: "Syria",
      },
      {
        value: "992",
        title: "Tajikistan",
      },
      {
        value: "255",
        title: "Tanzania",
      },
      {
        value: "66",
        title: "Thailand",
      },
      {
        value: "670",
        title: "Timor Leste",
      },
      {
        value: "228",
        title: "Togo",
      },
      {
        value: "676",
        title: "Tonga",
      },
      {
        value: "1868",
        title: "Trinidad and Tobago",
      },
      {
        value: "216",
        title: "Tunisia",
      },
      {
        value: "90",
        title: "Turkey",
      },
      {
        value: "993",
        title: "Turkmenistan",
      },
      {
        value: "1649",
        title: "Turks and Caicos Islands",
      },
      {
        value: "256",
        title: "Uganda",
      },
      {
        value: "380",
        title: "Ukraine",
      },
      {
        value: "971",
        title: "United Arab Emirates",
      },
      {
        value: "44",
        title: "United Kingdom",
      },
      {
        value: "1",
        title: "USA",
      },
      {
        value: "598",
        title: "Uruguay",
      },
      {
        value: "998",
        title: "Uzbekistan",
      },
      {
        value: "678",
        title: "Vanuatu",
      },
      {
        value: "58",
        title: "Venezuela",
      },
      {
        value: "84",
        title: "Vietnam",
      },
      {
        value: "1340",
        title: "Virgin Islands",
      },
      {
        value: "967",
        title: "Yemen",
      },
      {
        value: "260",
        title: "Zambia",
      },
      {
        value: "263",
        title: "Zimbabwe",
      },
    ],
    ownershipRoleOptions: [
      {
        value: "coordinator",
        title: "Coordinator",
      },
      {
        value: "checker",
        title: "Checker",
      },
      {
        value: "auditor",
        title: "Reviewer",
      },
    ],
    ownershipTypeOptions: [
      {
        value: "CLAIM",
        title: "self-claimed",
      },
      {
        value: "TRANSFER",
        title: "transferred",
      },
      {
        value: "ADJUST",
        title: "assigned",
      },
    ],
    applicantPositionOptions: [
      { value: "DIRECTOR", title: "Director of the proposed company/fund" },
      { value: "SHAREHOLDER", title: "Direct shareholder of the proposed company/fund" },
      { value: "INDIRECT_SHAREHOLDER", title: "Intermediary/ultimate shareholder of the proposed company/fund" },
      { value: "OTHER", title: "Other" },
    ],
    thirdPartyFeeEnOptions: ["Fees paid to ACRA", "Nominee director fee (1 person / 1 year)", "Other third party fee", "N/A"],
    thirdPartyFeeZhOptions: ["支付给ACRA的费用", "名义董事费(1年/1名)", "其他第三方费用", "不适用"],
    otherFeeEnOptions: ["Deposit", "N/A"],
    otherFeeZhOptions: ["押金", "不适用"],
    fundTypes: [
      {
        value: "Non-umbrella VCC",
        title: "Non-umbrella VCC",
      },
      {
        value: "Umbrella VCC",
        title: "Umbrella VCC",
      },
      {
        value: "Sub-fund",
        title: "Sub-fund",
      },
      {
        value: "Others",
        title: "Others",
      },
    ],
    clientCodeStatusList: [
      {
        //已提交
        title: "Submitted",
        value: 0,
      },
      {
        //复核成功
        title: "Review Passed",
        value: 1,
      },
      {
        //复核失败
        title: "Review Failed",
        value: 2,
      },
      {
        //禁用(客户丢了、不再用了)
        title: "Inactive",
        value: 3,
      },
    ],
    introducedByList: [
      {
        title: "Commission Required",
        value: 1,
      },
      {
        title: "No Commission",
        value: 0,
      },
    ],
    engagementCompanyTypeList: [
      { title: "Company", value: "COMPANY" },
      { title: "Fund", value: "FUND" },
      { title: "Others", value: "OTHERS" },
    ],
    engagementStatusList: [
      { title: "Open", value: "OPEN" },
      { title: "Closed", value: "CLOSE" },
      { title: "Reopen Submitted", value: "REOPEN" },
    ],
    serviceTypeList: [
      { title: "Secretary", value: "SECRETARY" },
      { title: "Accounting", value: "ACCOUNTING" },
      { title: "Report", value: "REPORT" },
      { title: "Tax", value: "TAX" },
      { title: "Fund Admin", value: "FUND_ADMIN" },
      { title: "Treasury", value: "TREASURY" },
      { title: "Others", value: "OTHERS" },
    ],
    engagementControlStatusList: [
      { title: "Not started", value: "NOT_STARTED" },
      { title: "In progress", value: "IN_PROGRESS" },
      { title: "Completed", value: "COMPLETED" },
      { title: "N/A", value: "NONE" },
    ],
    monthOptions: [
      {
        title: "Jan",
        value: "01",
      },
      {
        title: "Feb",
        value: "02",
      },
      {
        title: "Mar",
        value: "03",
      },
      {
        title: "Apr",
        value: "04",
      },
      {
        title: "May",
        value: "05",
      },
      {
        title: "Jun",
        value: "06",
      },
      {
        title: "Jul",
        value: "07",
      },
      {
        title: "Aug",
        value: "08",
      },
      {
        title: "Sep",
        value: "09",
      },
      {
        title: "Oct",
        value: "10",
      },
      {
        title: "Nov",
        value: "11",
      },
      {
        title: "Dec",
        value: "12",
      },
    ],
    completedCheckOptions: [
      {
        title: "Yes",
        value: "YES",
      },
      {
        title: "No",
        value: "NO",
      },
      {
        title: "N/A",
        value: "NONE",
      },
    ],
    reopenStatusList: [
      {
        // 重启审核请求已提交
        title: "Reopen Submitted",
        value: 0,
      },
      {
        // 重启审核请求通过
        title: "Reopen Passed",
        value: 1,
      },
      {
        // 重启审核请求未通过
        title: "Reopen Failed",
        value: 2,
      },
    ],
    controlTypeList: ["AGM/EGM", "Report Filing", "CIT filing", "GST filing", "Other tax filing", "Secretary", "Account", "Advisory", "HR", "Others"],
    supplementStatusList: [
      {
        // 待处理
        title: "Submitted",
        value: "PENDING",
      },
      {
        // 驳回
        title: "Rejected",
        value: "REJECTED",
      },
      {
        // 通过
        title: "Passed",
        value: "PASSED",
      },
      {
        // 禁用
        title: "Forfeited",
        value: "FORFEITED",
      },
    ],
    creditStatusList: [
      {
        // 待审核
        title: "Submitted",
        value: "PENDING",
      },
      {
        // 通过
        title: "Passed",
        value: "PASSED",
      },
      {
        // 驳回
        title: "Rejected",
        value: "REJECTED",
      },
    ],
    budgetPeriodList: [
      {
        title: "3 months – 90 days",
        value: 90,
      },
      {
        title: "6 months – 180 days",
        value: 180,
      },
      {
        title: "9 months – 270 days",
        value: 270,
      },
      {
        title: "12 months – 365 days",
        value: 365,
      },
    ],
    introducedByArr: [
      {
        title: "All",
        value: 1,
      },
      {
        title: "No commission",
        value: 2,
      },
      {
        title: "Commission required - All",
        value: 3,
      },
      {
        title: "Commission required",
        value: 4,
      },
    ],
    currencyList: [
      {
        value: "SGD",
        title: "SGD Singapore, Dollars",
        symbol: "S$",
      },
      {
        value: "USD",
        title: "USD United States of America, Dollars",
        symbol: "US$",
      },
    ],
  },
};
