var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Fee Letter")]), (_vm.detail.applymentStatus === 'INQUIRING' || _vm.detail.applymentStatus === 'QUOTING' || _vm.detail.applymentStatus === 'QUOTATION_AUDIT_FAIL') && _vm.isApplymentAuth('quotation:coordinate') ? _c('el-button', {
    on: {
      "click": _vm.dataFormSave
    }
  }, [_vm._v(" Save ")]) : _vm._e()], 1), _c('el-form', {
    ref: "dataForm",
    staticClass: "feeLetterForm",
    attrs: {
      "model": _vm.dataForm,
      "label-position": "top"
    }
  }, [_c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("1. About This Fee Letter 关于本收费协议")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "pb30"
  }, [_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.1 We thank you for giving us the opportunity to provide our services. This letter explains our service scope and fee information that apply when you use the comprehensive corporate services provided by us. 感谢您选择我们的服务。本协议解释了您使用我们提供的综合企业服务时适用的服务范围和费用信息。 ")]) : _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.1 We thank you for giving us the opportunity to provide our services. This letter explains our service scope and fee information that apply when you use the fund admin services and other add-on services provided by us. 感谢您选择我们的服务。本协议解释了您使用我们提供的基金托管及其他附加服务时适用的服务范围和费用信息。 ")]) : _vm._e()]), _c('div', {
    staticClass: "pb30"
  }, [_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.2 Unless otherwise mentioned in this letter, all terms and conditions are also subject to the Service Agreement that will be acknowledged by you when you use our comprehensive corporate services. 除本协议另有说明外，所有条款和条件均受您在使用我们的综合企业服务时确认的《服务协议》的约束。 ")]) : _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.2 Unless otherwise mentioned in this letter, all terms and conditions are also subject to the Service Agreement that will be acknowledged by you when you use our fund admin services and other add-on services. 除本协议另有说明外，所有条款和条件均受您在使用我们的基金托管及其他附加服务时确认的《服务协议》的约束。 ")]) : _vm._e()]), _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "newTransactionNumber",
    attrs: {
      "label": `1.3 This letter is subject to engagement letter with transaction number of [${_vm.dataForm.newTransactionNumber}], and will replace all previous terms and conditions relating to the transaction(s) with the transaction number of [${_vm.dataForm.transactionNumber}].
              本协议基于交易代码为[${_vm.dataForm.newTransactionNumber}]的业务约定书，本协议将取代所有先前与交易代码为[${_vm.dataForm.transactionNumber}]的交易相关的条款和条件。`,
      "prop": "newTransactionNumber",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "基于交易代码"
    },
    model: {
      value: _vm.dataForm.newTransactionNumber,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "newTransactionNumber", $$v);
      },
      expression: "dataForm.newTransactionNumber"
    }
  })], 1), _c('el-form-item', {
    ref: "transactionNumber",
    attrs: {
      "prop": "transactionNumber",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "先前交易代码"
    },
    model: {
      value: _vm.dataForm.transactionNumber,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "transactionNumber", $$v);
      },
      expression: "dataForm.transactionNumber"
    }
  })], 1)], 1), _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "companyName",
    attrs: {
      "label": `1.4 By the "we", "us", “our”, "ours" we mean ${_vm.dataForm.companyName}, including its affiliates and its assigned
              service provider(s). 协议中提及的“我方”、“我们”、“我方的”、“我们的”是指 ${_vm.dataForm.companyName}
              (${_vm.dataForm.companyNameCn})，并包括其附属公司及其指定的服务提供商。`,
      "prop": "companyName",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "选择公司"
    },
    on: {
      "change": function ($event) {
        _vm.dataForm.companyNameCn = _vm.companyList.find(function (item) {
          return item.title == _vm.dataForm.companyName;
        }).titleCn || '';
      }
    },
    model: {
      value: _vm.dataForm.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "companyName", $$v);
      },
      expression: "dataForm.companyName"
    }
  }, _vm._l(_vm.companyList, function (com) {
    return _c('el-option', {
      key: com.id,
      attrs: {
        "label": com.title,
        "value": com.title
      }
    });
  }), 1)], 1)], 1), _c('div', {
    staticClass: "pb30"
  }, [_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.5 This letter is by and between the Company to be incorporated/transferred/customized per the request(s) through our Electronic Services (hereinafter called “the Company”), and us. Each shall be referred to as a “Party” and collectively referred to as the “Parties”. 本协议的签署方为: 通过我们的电子服务系统申请成立/转让/定制化的公司（以下简称“贵公司”）和我方签署。以下的单指某方简称“一方”，以下的统称简称“各方”。 ")]) : _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('div', [_c('el-form-item', {
    ref: "fundNameList",
    attrs: {
      "label": `1.5 This letter is by and between the fund(s) named [${_vm.dataForm.fundNameList}] per the request(s) through our Electronic Services
              (hereinafter called “the Fund(s)”), and us. Each shall be referred to as a “Party” and collectively referred to as the “Parties”.
              本协议的签署方为:
              通过我们的电子服务系统提交申请的基金[${_vm.dataForm.fundNameList}](以下简称“贵基金”)和我方签署。以下的单指某方简称“一方”，以下的统称简称“各方”。`,
      "prop": "fundNameList",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticClass: "wd500",
    attrs: {
      "multiple": "",
      "placeholder": "选择基金"
    },
    model: {
      value: _vm.dataForm.fundNameList,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fundNameList", $$v);
      },
      expression: "dataForm.fundNameList"
    }
  }, _vm._l(_vm.detail.fundInfoList, function (fun) {
    return _c('el-option', {
      key: fun.id,
      attrs: {
        "label": fun.fundName,
        "value": fun.fundName
      }
    });
  }), 1)], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "pb30"
  }, [_c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.6 This letter shall be governed by and construed in accordance with the laws of the Republic of Singapore. The parties shall submit to the exclusive jurisdiction of the courts of Singapore. 本协议的司法管辖以及司法解释适用新加坡共和国法律。各方应服从新加坡法院的仲裁及管辖。 ")])])])])]), _c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('div', [_vm.detail.businessGenre == 'COMPANY' ? _c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("2. Introduction 引言")]) : _vm.detail.businessGenre == 'FUND' ? _c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("2. General Terms and Conditions 一般条款和条件")]) : _vm._e(), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm.detail.businessGenre == 'COMPANY' ? _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "introduction",
    attrs: {
      "label": "2.1 Based on our previous communication with you, we understand that: ",
      "prop": "introduction",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.introduction,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "introduction", $$v);
      },
      expression: "dataForm.introduction"
    }
  })], 1), _c('el-form-item', {
    ref: "introductionCn",
    attrs: {
      "label": "2.1 根据之前的沟通，我们理解：",
      "prop": "introductionCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.introductionCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "introductionCn", $$v);
      },
      expression: "dataForm.introductionCn"
    }
  })], 1)], 1) : _vm.detail.businessGenre == 'FUND' ? _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "introduction",
    attrs: {
      "label": `WHEREAS, the Fund(s) to be managed by ${_vm.detail.questionnaire.proposedCompanyName} (the “Fund Manager”), and the Fund(s) desires to appoint us  Administrator of the Fund(s). NOW, THEREFORE, in consideration of the mutual promises and agreements contained herein, the parties hereby agree as follows:`,
      "prop": "introduction",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.introduction,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "introduction", $$v);
      },
      expression: "dataForm.introduction"
    }
  })], 1), _c('el-form-item', {
    ref: "introductionCn",
    attrs: {
      "label": `鉴于贵基金由${_vm.detail.questionnaire.proposedCompanyName}(以下简称“贵基金管理公司”)管理，以及贵基金希望任命我方为基金托管人。 因此，现依据以下包含的相互承诺和协议，各方特此同意如下：`,
      "prop": "introductionCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.introductionCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "introductionCn", $$v);
      },
      expression: "dataForm.introductionCn"
    }
  })], 1)], 1) : _vm._e()])])])]), _c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("3. Service Scope and Fees 服务范围以及报价")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "principal",
    attrs: {
      "label": `3.1 Based on our understanding of the ${_vm.detail.businessGenre == 'COMPANY' ? 'Company' : 'Fund(s)'} as set out in ${_vm.detail.businessGenre == 'COMPANY' ? 'section 2' : 'Clause 1, 2 and 3.1.1'} of this letter, we will assign [${_vm.dataForm.principal}] as the person-in-charge for the provision of services specified as below:`,
      "prop": "principal",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人(EN)"
    },
    model: {
      value: _vm.dataForm.principal,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "principal", $$v);
      },
      expression: "dataForm.principal"
    }
  })], 1), _c('el-form-item', {
    ref: "serviceScopeAndFeesUrl.url",
    attrs: {
      "prop": "serviceScopeAndFeesUrl.url",
      "rules": {
        required: true,
        message: 'Please upload',
        trigger: 'submit'
      }
    }
  }, [_c('el-image-uploader', {
    model: {
      value: _vm.dataForm.serviceScopeAndFeesUrl,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "serviceScopeAndFeesUrl", $$v);
      },
      expression: "dataForm.serviceScopeAndFeesUrl"
    }
  }), _c('el-input', {
    staticStyle: {
      "display": "none"
    },
    model: {
      value: _vm.dataForm.serviceScopeAndFeesUrl.url,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.serviceScopeAndFeesUrl, "url", $$v);
      },
      expression: "dataForm.serviceScopeAndFeesUrl.url"
    }
  })], 1), _c('el-form-item', {
    ref: "principalCn",
    attrs: {
      "label": `3.1 鉴于本协议${_vm.detail.businessGenre == 'COMPANY' ? '第2条' : '第1条、第2条和第3.1.1条'}提及的我们对贵${_vm.detail.businessGenre == 'COMPANY' ? '公司' : '基金'}的理解，我们将安排[${_vm.dataForm.principalCn}]为项目的负责人，我们对贵${_vm.detail.businessGenre == 'COMPANY' ? '公司' : '基金'}的收费报价如下:`,
      "prop": "principalCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人(CN)"
    },
    model: {
      value: _vm.dataForm.principalCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "principalCn", $$v);
      },
      expression: "dataForm.principalCn"
    }
  })], 1), _c('el-form-item', {
    ref: "serviceScopeAndFeesCnUrl.url",
    attrs: {
      "prop": "serviceScopeAndFeesCnUrl.url",
      "rules": {
        required: true,
        message: 'Please upload',
        trigger: 'submit'
      }
    }
  }, [_c('el-image-uploader', {
    model: {
      value: _vm.dataForm.serviceScopeAndFeesCnUrl,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "serviceScopeAndFeesCnUrl", $$v);
      },
      expression: "dataForm.serviceScopeAndFeesCnUrl"
    }
  }), _c('el-input', {
    staticStyle: {
      "display": "none"
    },
    model: {
      value: _vm.dataForm.serviceScopeAndFeesCnUrl.url,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.serviceScopeAndFeesCnUrl, "url", $$v);
      },
      expression: "dataForm.serviceScopeAndFeesCnUrl.url"
    }
  })], 1)], 1), _c('div', {
    staticClass: "pb30"
  }, [_vm.detail.businessGenre == 'COMPANY' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" Our above quotation is based on our standard fees, our understanding of the Company's business and long-term needs, and the discretionary discounts given to future long-term relationship. 我们的上述报价是基于我们的标准费用1 、我们了解的公司情况及长期需求、以及未来长期合作关系给予的酌情折扣得出。 ")]) : _vm._e(), _vm.detail.businessGenre == 'FUND' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" Our above quotation is based on our standard fees, our understanding of the Fund(s)'s business and long-term needs, and the discretionary discounts given to future long-term relationship. 我们的上述报价是基于我们的标准费用1 、我们了解的贵基金的运营情况及长期需求、以及未来长期合作关系给予的酌情折扣得出。 ")]) : _vm._e()]), _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "notes",
    attrs: {
      "label": "Notes:",
      "prop": "notes",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.notes,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "notes", $$v);
      },
      expression: "dataForm.notes"
    }
  })], 1), _c('el-form-item', {
    ref: "notesCn",
    attrs: {
      "label": "说明:",
      "prop": "notesCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.notesCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "notesCn", $$v);
      },
      expression: "dataForm.notesCn"
    }
  })], 1)], 1)])])]), _c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("4. Fee Schedule 收费安排")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "feeDate",
    attrs: {
      "label": `4.1 Our fee set out in section 3 shall be billed on [${_vm.dataForm.feeDate}] basis and is payable in advance. 
              The ${_vm.detail.businessGenre == 'COMPANY' ? 'Company' : 'Fund(s)'} shall make fully payment within 14 days from the date of our invoice. If you agree to our above-mentioned fee quotation, 
              our fee schedule is as follows: 
              我们的收费安排为预收收费。其中，年度服务费用按年预收；季度服务费用按季度预收；按需收费的服务事项按开具的发票金额预收。
              贵${_vm.detail.businessGenre == 'COMPANY' ? '公司' : '基金'}应自收到发票后的14日内完成付款。若您同意我们的上述收费报价，相关的收费（未包括按需收费的服务事项）具体安排如下:`,
      "prop": "feeDate",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "Monthly/Quaterly/Yearly"
    },
    model: {
      value: _vm.dataForm.feeDate,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "feeDate", $$v);
      },
      expression: "dataForm.feeDate"
    }
  })], 1), _c('el-form-item', {
    ref: "feeScheduleList",
    staticClass: "pt10",
    attrs: {
      "prop": "feeScheduleList",
      "rules": {
        type: 'array',
        required: true,
        message: 'Please add',
        trigger: 'submit'
      }
    }
  }, [(_vm.detail.applymentStatus === 'INQUIRING' || _vm.detail.applymentStatus === 'QUOTING' || _vm.detail.applymentStatus === 'QUOTATION_AUDIT_FAIL') && _vm.isApplymentAuth('quotation:coordinate') ? _c('el-button', {
    on: {
      "click": _vm.editOfferIndexModal
    }
  }, [_vm._v(" Edit Quotation ")]) : _vm._e(), _c('div', {
    staticClass: "pt10"
  }, [_c('quotation-detail', {
    attrs: {
      "quotationList": _vm.detail.quotationList,
      "applymentStatus": _vm.detail.applymentStatus
    }
  })], 1), _c('el-checkbox-group', {
    staticStyle: {
      "display": "none"
    },
    model: {
      value: _vm.dataForm.feeScheduleList,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "feeScheduleList", $$v);
      },
      expression: "dataForm.feeScheduleList"
    }
  }, [_vm._v(" " + _vm._s(_vm.dataForm.feeScheduleList = _vm.detail.quotationList) + " ")])], 1)], 1)])])]), _c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("5. Ending 结语")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "pb30"
  }, [_c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 5.1 We adhere to the work philosophy of \"professional, concentrate, convenience\", and is committed to providing professional services with quality and efficiency. We target to form a good reputation through our professional services provided, and we welcome more entrepreneurs who are interested in overseas development to join our platform to “Develop Together, and Grow Together”. 我们秉承“专业、专注、专心”的工作理念，将持续为企业提供便捷高效的专业服务。我们希望通过我们专业的服务形成良好的口碑、并结交更多有志于海外发展的企业家，共同发展、共同进步。 ")])]), _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "functionary",
    attrs: {
      "label": `5.2 If you have further questions, please contact: [${_vm.dataForm.functionary}] [${_vm.dataForm.email}]`,
      "prop": "functionary",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人(EN)"
    },
    model: {
      value: _vm.dataForm.functionary,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "functionary", $$v);
      },
      expression: "dataForm.functionary"
    }
  })], 1), _c('el-form-item', {
    ref: "email",
    attrs: {
      "prop": "email",
      "rules": [{
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        type: 'email',
        message: 'Please provide a valid email address',
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人电邮地址"
    },
    model: {
      value: _vm.dataForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "email", $$v);
      },
      expression: "dataForm.email"
    }
  })], 1), _c('el-form-item', {
    ref: "functionaryCn",
    attrs: {
      "label": `5.2 若您有进一步的疑问，请联系: [${_vm.dataForm.functionaryCn}] [${_vm.dataForm.email}]`,
      "prop": "functionaryCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人(CN)"
    },
    model: {
      value: _vm.dataForm.functionaryCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "functionaryCn", $$v);
      },
      expression: "dataForm.functionaryCn"
    }
  })], 1)], 1), _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "appendixUrl.url",
    attrs: {
      "label": "Appendix 1 Authorized Persons 附录1 授权名单",
      "prop": "appendixUrl.url",
      "rules": {
        required: true,
        message: 'Please upload',
        trigger: 'submit'
      }
    }
  }, [_c('el-image-uploader', {
    model: {
      value: _vm.dataForm.appendixUrl,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "appendixUrl", $$v);
      },
      expression: "dataForm.appendixUrl"
    }
  }), _c('el-input', {
    staticStyle: {
      "display": "none"
    },
    model: {
      value: _vm.dataForm.appendixUrl.url,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.appendixUrl, "url", $$v);
      },
      expression: "dataForm.appendixUrl.url"
    }
  })], 1)], 1) : _vm._e()])])])]), _c('offer-index', {
    ref: "offerIndexModal",
    attrs: {
      "detail": _vm.detail,
      "moduleServiceList": _vm.moduleServiceList
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }