var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_vm._v("Share Capital Details")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "header-cell-style": {
        color: '#011A45'
      },
      "border": "",
      "data": _vm.companyMembers
    }
  }, [_c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "fullName",
      "label": "Name Of Shareholders"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "typeOfShares",
      "label": "Type Of Shares",
      "width": "180",
      "formatter": _vm.typeOfSharesFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "quantityOfSharesAllotted",
      "label": "No of shares allotted"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "amountOfPaidUpShareCapital",
      "label": "Amount Of Registered Share Capital"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "amountOfPaidInShareCapital",
      "label": "Paid-in capital"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "sharesHoldInTrust",
      "label": "Shares held in trust",
      "formatter": _vm.sharesHoldInTrustFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "nameOfTheTrust",
      "label": "Name of the trust"
    }
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }