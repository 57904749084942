<template>
  <div class="company-applyment-module">
    <!-- 状态 -->
    <el-affix
      :class="{ 'applyment-page-header-top': affixed, 'applyment-page-header-shrink': sidebarFold, 'applyment-page-header-unfold': !sidebarFold }"
      @change="handleAffixChange"
      :offset="104"
    >
      <el-card>
        <div class="applyment-page-header">
          <div>
            <div class="status">
              {{ applymentStatusFormat(detail.applymentStatus) }}
              <span v-if="detail.applymentStatus == 'REFUND_HANDING'" class="fs12">({{ refundStatusFormat(detail.orderInfo.orderRefund.status) }})</span>
              <span v-if="detail.amends" class="fs12">(Supplemental: {{ amendsStatusFormat(detail.amends) }})</span>
            </div>
            <div class="page-header-heading-left">
              <div class="heading-title">Transaction No.: {{ detail.applymentNo }}</div>
            </div>
            <div class="page-header-heading-content">
              <el-descriptions :column="4" direction="vertical">
                <el-descriptions-item label="Transaction No.">{{ detail.applymentNo }}</el-descriptions-item>
                <!-- <el-descriptions-item label="Checker">
                  <user-toggle :user="detail.checker" ownershipRole="checker" />
                </el-descriptions-item> -->
                <el-descriptions-item label="Reviewer">
                  <user-toggle :user="detail.auditor" ownershipRole="auditor" />
                </el-descriptions-item>
                <el-descriptions-item label="Coordinator">
                  <user-toggle :user="detail.coordinator" ownershipRole="coordinator" />
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div class="page-header-heading-right">
            <div class="opts">
              <!-- 认领日志 -->
              <el-button type="text" icon="el-icon-tickets" @click="openOwnershipHistoryModal">Ownership History</el-button>
              <!-- 状态操作日志 -->
              <el-button type="text" icon="el-icon-tickets" @click="openStatusHistoryModal">Status Track</el-button>
              <!-- 提交报价单 -->
              <el-button
                v-if="detail.applymentStatus === 'QUOTING' && isApplymentAuth('quotation:coordinate')"
                type="primary"
                size="medium"
                @click="handleReviseFinish"
              >
                Revision Submit
              </el-button>
              <!-- 复核 -->
              <el-button
                v-if="detail.applymentStatus === 'QUOTATION_AUDITING' && isApplymentAuth('quotation:audit')"
                type="primary"
                size="medium"
                @click="handleAuditFinish"
              >
                Finish Review
              </el-button>
            </div>
          </div>
        </div>
      </el-card>
    </el-affix>

    <!-- 基础信息 -->
    <div class="step mt50">
      <el-card shadow="hover">
        <base-info :detail="detail" ref="detailDom" />
      </el-card>
    </div>

    <!-- 线上Fee Letter -->
    <div id="Step2" class="step mt40">
      <el-card>
        <fee-letter
          ref="feeLetterDom"
          :detail="detail"
          :moduleServiceList="moduleServiceList"
          :feeLetterDetail="feeLetterDetail"
          :companyList="companyList"
          @reviseFinishConfirm="reviseFinishConfirm"
        ></fee-letter>
      </el-card>
    </div>

    <!-- 弹窗 -->
    <step-modal v-if="stepModalVisible" ref="stepModal"></step-modal>
    <!-- 认领日志弹框 -->
    <ownership-history :applymentId="detail.id" ref="ownershipModal"></ownership-history>
    <!-- 状态操作日志弹框 -->
    <status-history :applymentId="detail.id" ref="statusHistoryModal"></status-history>
    <!-- 复核 - 弹框 -->
    <audit-modal ref="auditModal"></audit-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserToggle from "../components/UserToggle";
import BaseInfo from "../modules/baseInfo";
import FeeLetter from "../modules/feeLetter";
import StepModal from "./modal/Step.vue";
import OwnershipHistory from "./modal/OwnershipHistory.vue";
import StatusHistory from "./modal/StatusHistory.vue";
import AuditModal from "./modal/Audit.vue";

export default {
  components: {
    BaseInfo,
    FeeLetter,
    StepModal,
    UserToggle,
    StatusHistory,
    OwnershipHistory,
    AuditModal,
  },
  data() {
    return {
      stepModalVisible: false,
      affixed: false,
      moduleServiceList: [],
      companyList: [],
      feeLetterDetail: null,
    };
  },
  computed: {
    ...mapState("constant", ["applymentStatusList", "stepStatusList", "refundStatusList", "amendsStatusList", "businessStepObj"]),
    ...mapState("applyment", ["detail"]),
    ...mapState("authority", ["activeRoleName"]),
    ...mapState({
      userAt: state => {
        return { id: state.user.id, nickname: state.user.name, username: state.user.name };
      },
    }),
    stepsTree() {
      const procedures = this.detail.procedures;
      if (!procedures) {
        return {};
      }
      const r = procedures.reduce((xs, z) => {
        xs[z.step] = z;
        return xs;
      }, {});
      return r;
    },
    orderStatus() {
      const { orderInfo } = this.detail;
      return orderInfo?.status;
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
    },
  },
  watch: {
    activeRoleName(newVal) {
      // 权限认领弹框
      this.getTheTask();
    },
  },
  activated() {
    this.id = this.$route.params.id;
    this.getDetail();
    // 获取公司列表
    this.getCompanyList();
  },
  mounted() {},
  methods: {
    ...mapActions("applyment", ["initApplyment", "commitQuotation", "doClaimOwnership"]),
    handleAffixChange(fixed) {
      this.affixed = fixed;
    },
    //申请单状态格式化
    applymentStatusFormat(v) {
      const one = this.applymentStatusList.find(i => i.value === v);
      return one?.title;
    },
    //退款信息补充格式化
    refundStatusFormat(v) {
      const one = this.refundStatusList.find(i => i.value === v);
      return one?.title;
    },
    // 补款单状态格式化
    amendsStatusFormat(v) {
      const one = this.amendsStatusList.find(i => i.value === v);
      return one?.title;
    },
    //详情
    async getDetail() {
      await this.initApplyment(this.id);
      this.$refs.detailDom.init();
      // 权限认领弹框
      this.getTheTask();
      // 获取收费协议详情
      this.getFeeLetterDetail();
      // 获取服务列表
      this.getModuleList();
    },
    //完成复核
    handleAuditFinish() {
      this.$refs.auditModal.init();
    },
    // 提交报价单 - 确认提示
    reviseFinishConfirm() {
      this.$confirm(`Commit this quotation. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        this.commitQuotation({
          applymentId: this.detail.id,
        })
          .then(() => {
            this.$message.success("success");
          })
          .catch(msg => {
            this.$message.error(msg);
          });
      });
    },
    //提交报价单
    handleReviseFinish() {
      this.$refs.feeLetterDom.dataFormSubmit("submit");
    },
    // 认领日志
    openOwnershipHistoryModal() {
      this.$refs.ownershipModal.init();
    },
    // 状态操作日志
    openStatusHistoryModal() {
      this.$refs.statusHistoryModal.init();
    },
    // 认领
    claim(type) {
      return this.doClaimOwnership({
        ownershipRole: type,
        toUser: this.userAt,
      }).then(() => {
        this.$message.success(`${type == "auditor" ? "reviewer" : type} success`);
      });
    },
    // 权限认领弹框
    async getTheTask() {
      if (
        !this.isAuth("quotation:adjust") &&
        ((this.isAuth("quotation:coordinate") && (!this.detail.coordinator || (this.detail.coordinator && this.detail.coordinator.id === -1))) ||
          // (this.isAuth("quotation:check") && (!this.detail.checker || (this.detail.checker && this.detail.checker.id === -1))) ||
          (this.isAuth("quotation:audit") && (!this.detail.auditor || (this.detail.auditor && this.detail.auditor.id === -1))))
      ) {
        let roleName = "";
        if (this.isAuth("quotation:coordinate") && (!this.detail.coordinator || (this.detail.coordinator && this.detail.coordinator.id === -1))) {
          roleName = "Coordinator";
        }
        // else if (this.isAuth("quotation:check") && (!this.detail.checker || (this.detail.checker && this.detail.checker.id === -1))) {
        //   roleName = "Checker";
        // }
        else if (this.isAuth("quotation:audit") && (!this.detail.auditor || (this.detail.auditor && this.detail.auditor.id === -1))) {
          roleName = "Reviewer";
        }
        await this.$confirm("Whether to pick up the work task of the application?", `Tasks Of ${roleName}`, {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "info",
        });
        if (this.isAuth("quotation:coordinate") && (!this.detail.coordinator || (this.detail.coordinator && this.detail.coordinator.id === -1))) {
          await this.claim("coordinator");
        }
        // if (this.isAuth("quotation:check") && (!this.detail.checker || (this.detail.checker && this.detail.checker.id === -1))) {
        //   await this.claim("checker");
        // }
        if (this.isAuth("quotation:audit") && (!this.detail.auditor || (this.detail.auditor && this.detail.auditor.id === -1))) {
          await this.claim("auditor");
        }
      }
    },
    // 获取服务列表
    getModuleList() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/admin/moduleManage/getList", {
            params: {
              businessType: this.detail.businessType,
            },
          })
          .then(({ code, data, msg }) => {
            if (code === 200) {
              const resData = data || [];
              let newArr = [];
              resData.forEach(item => {
                newArr.push({
                  value: `${item.name} ${item.nameCn}`,
                });
              });
              this.moduleServiceList = newArr;
              resolve();
            } else {
              this.$message.error(msg);
              reject(msg);
            }
          });
      });
    },
    // 获取公司列表
    getCompanyList() {
      return new Promise((resolve, reject) => {
        this.$http.get("/admin/stInvoiceInfoManage/getList", {}).then(({ code, data, msg }) => {
          if (code === 200) {
            this.companyList = data;
            resolve();
          } else {
            this.$message.error(msg);
            reject(msg);
          }
        });
      });
    },
    // 获取收费协议详情
    getFeeLetterDetail() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/admin/customerFeeLetterManage/getDetailByApplymentId", {
            params: {
              applymentId: this.detail.id,
            },
          })
          .then(({ code, data, msg }) => {
            if (code === 200) {
              this.feeLetterDetail = data && data.content ? JSON.parse(data.content) : {};
              this.$nextTick(() => {
                this.$refs.feeLetterDom.init();
              });
              resolve();
            } else {
              this.$message.error(msg);
              reject(msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/variables.scss";

.company-applyment-module {
  .applyment-page-header {
    background-color: #fff;
    // padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .page-header-heading-left {
      display: none;
    }

    .page-header-heading-content {
      width: 620px;
      padding-top: 12px;
    }

    .page-header-heading-right {
      .opts {
        display: inline-block;
      }
    }

    .status {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
      color: #12a3ff;
      .fs12 {
        font-size: 12px;
      }
    }

    .status::before {
      display: inline-block;
      content: "";
      background: #12a3ff;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      vertical-align: middle;
      margin-right: 4px;
      margin-top: -4px;
    }
  }
  .applyment-page-header-top {
    .affix {
      z-index: 1030 !important;
    }
    .page-header-heading-left {
      display: inline-block;
    }
    .page-header-heading-content {
      display: none;
    }
    .status-label {
      display: none;
    }
  }

  .applyment-page-header-shrink {
    .affix {
      left: $sidebar-width !important;
      width: calc(100% - #{$sidebar-width} - 26px) !important;
    }
  }

  .applyment-page-header-unfold {
    .affix {
      left: $sidebar-full-width !important;
      width: calc(100% - #{$sidebar-full-width} - 26px) !important;
    }
  }

  .steps-bar-wrapper {
    position: fixed;
    right: 6px;
    top: 38vh;
    z-index: 1000;
  }

  .desp {
    color: rgb(53, 55, 68);

    .desp-header {
      font-size: 18px;
      padding: 10px 5px 10px;
      border-bottom: 1px solid #ebeef0;
      margin-bottom: 15px;
      color: #011a45;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
    }

    .desp-items-group {
      & + .desp-items-group {
        margin-top: 24px;
      }
    }

    .desp-items-group-title {
      padding: 1px 14px;
      font-size: 16px;
      margin-bottom: 10px;
      width: fit-content;
      border-left: 3px solid #eee;
      color: #011a45;
      font-weight: bold;
    }

    .desp-items-group-title-icon {
      padding: 1px 14px;
      font-size: 16px;
      margin-bottom: 10px;
      width: fit-content;
      color: #011a45;
      font-weight: bold;
    }

    .desp-items-group-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      color: #0060ff;
      // border-left: 3px solid #eee;
      background: #eceff9;
      margin-bottom: 15px;

      .title {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .desp-item {
      overflow: hidden;
    }

    .desp-item-label {
      width: 166px;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #667288;
      line-height: 40px;
      padding: 0 12px 0 0;
      box-sizing: border-box;
    }

    .desp-item-label:after {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }

    .desp-item-content {
      margin-left: 100px;
      line-height: 40px;
      position: relative;
      font-size: 14px;
      color: #011a45;
    }

    .desp-item-self {
      width: 100%;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0;
      box-sizing: border-box;
    }

    .desp-item-form {
      width: 100%;
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0;
      box-sizing: border-box;
    }
  }
}

.border-type {
  display: inline-block;
  padding: 10px 20px;
  line-height: 1em;
  border-radius: 4px;
}

.pass-button {
  border: 1px solid $--color-success;
  background: #e0fff6;
  color: $--color-success;
}

.no-pass-button {
  border: 1px solid $--color-danger;
  background: #ffe4e4;
  color: $--color-danger;
}

.revised-button {
  border: 1px solid $--color-warning;
  background: #fff0e8;
  color: $--color-warning;
}

.no-checking-button {
  border: 1px solid $navbar--color-dark;
  background: transparent;
  color: $navbar--color-dark;
}
</style>
