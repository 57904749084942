var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "variables-bar"
  }, [_c('div', {
    staticClass: "btn-list"
  }, _vm._l(_vm.variables, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "item-wrapper"
    }, [_c('div', {
      staticClass: "btn",
      attrs: {
        "title": item.tip
      },
      on: {
        "click": function () {
          return _vm.handleItemClick(item);
        }
      }
    }, [_vm._v(_vm._s(item.title))])]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }