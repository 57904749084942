<template>
  <el-dialog width="1400px" title="Status Track" :close-on-click-modal="false" :visible.sync="visible">
    <el-table border :data="dataList" v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="supplementNo" header-align="center" align="center" label="Quotation (V) No." width="100"></el-table-column>
      <el-table-column prop="businessNo" header-align="center" align="center" label="Linked Transaction No." width="110"></el-table-column>
      <el-table-column prop="billingParty" header-align="center" align="center" label="Billing Party" min-width="110"></el-table-column>
      <el-table-column header-align="center" align="center" label="Exchange Rate" width="100">
        <template slot-scope="scope">
          <p v-if="scope.row.paymentDetail">{{ scope.row.paymentDetail.exchangeRate }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="amount" header-align="center" align="center" label="Amount" min-width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.paymentDetail">{{ scope.row.paymentDetail.amount | thousands(scope.row.paymentDetail.currencyUnit) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="thirdPartyFee" header-align="center" align="center" label="Third party fee" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.paymentDetail">{{ scope.row.paymentDetail.thirdPartyFee | thousands(scope.row.paymentDetail.currencyUnit) }}</span>
          <el-popover placement="bottom" trigger="hover" v-if="scope.row.noteForThirdParty">
            <span class="fs12">{{ scope.row.noteForThirdParty }}</span>
            <span slot="reference" class="ml8 fs14 blackD8 el-icon-question"></span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="otherFee" header-align="center" align="center" label="Other Fee" min-width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.paymentDetail">{{ scope.row.paymentDetail.otherFee | thousands(scope.row.paymentDetail.currencyUnit) }}</span>
          <el-popover placement="bottom" trigger="hover" v-if="scope.row.noteForOther">
            <span class="fs12">{{ scope.row.noteForOther }}</span>
            <span slot="reference" class="ml8 fs14 blackD8 el-icon-question"></span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="createAt" header-align="center" align="center" label="Handling Time" :formatter="localDateFormat" width="100" />
      <el-table-column
        prop="supplementStatus"
        header-align="center"
        align="center"
        label="Status"
        :formatter="supplementStatusFormat"
        width="100"
      ></el-table-column>
      <el-table-column prop="remark" header-align="center" align="center" label="Description / Remark" min-width="120" />
      <el-table-column prop="userInfo" header-align="center" align="center" label="Handler" min-width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.userInfo.nickname }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="current"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="size"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </el-dialog>
</template>

<script>
import { dateFormat, fmoney } from "@/utils";
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataList: [],
      current: 1,
      size: 10,
      total: 0,
      dataListLoading: false,
      dataForm: {
        supplementId: null,
      },
    };
  },
  computed: {
    ...mapState("constant", ["supplementStatusList"]),
  },
  filters: {
    thousands(val, symbol) {
      if (val || val == 0) {
        return `${symbol}${fmoney(val, 2)}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    // 日期格式化
    localDateFormat(row) {
      if (row.createAt) {
        return dateFormat(new Date(row.createAt * 1000));
      }
      return "";
    },
    // 状态格式化
    supplementStatusFormat(row, column, cellValue) {
      const one = this.supplementStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    async init(id) {
      this.visible = true;
      await this.$nextTick();
      this.dataForm = {
        supplementId: id,
        startTime: "",
        endTime: "",
      };
      this.getDataList(1);
    },
    // 每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 获取分页
    getDataList(current) {
      setTimeout(async () => {
        this.dataListLoading = true;
        if (current) {
          this.current = current;
        }
        const { code, data } = await this.$http.get("/admin/businessSupplementLog/paging", {
          params: {
            current: this.current,
            size: this.size,
            "orders[0].asc": false,
            "orders[0].column": "id",
            ...this.dataForm,
          },
        });
        if (code === 200) {
          this.dataList = data.records;
          this.total = data.total;
        } else {
          this.dataList = [];
          this.total = 0;
        }
        this.dataListLoading = false;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.blackD8 {
  color: #bbc6d8;
}
</style>
