var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": !_vm.dataForm.id ? 'Add shareholder/director' : 'Edit shareholder/director',
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "width": "1280px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "label-position": "top"
    }
  }, [_c('el-tabs', {
    attrs: {
      "stretch": ""
    },
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.dataForm.memberType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "memberType", $$v);
      },
      expression: "dataForm.memberType"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": "Company",
      "name": "CORPORATE"
    }
  }, [_vm.dataForm.memberType == 'CORPORATE' ? [_c('el-form-item', {
    attrs: {
      "label": "",
      "prop": "positionHeld",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.positionHeld,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "positionHeld", $$v);
      },
      expression: "dataForm.positionHeld"
    }
  }, _vm._l(_vm.selectablePositionHelds, function (item) {
    return _c('el-radio', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name (same as business certificate)",
      "prop": "fullName",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Name"
    },
    model: {
      value: _vm.dataForm.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fullName", $$v);
      },
      expression: "dataForm.fullName"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Country",
      "prop": "country",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select Country",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.country,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "country", $$v);
      },
      expression: "dataForm.country"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Business No.",
      "prop": "businessLicenseNo",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Business No.",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.businessLicenseNo,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "businessLicenseNo", $$v);
      },
      expression: "dataForm.businessLicenseNo"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Mobile No.",
      "prop": "phone.code",
      "rules": [{
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        validator: _vm.validatePhone,
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    staticClass: "input-with-select-xs",
    attrs: {
      "oninput": "value=value.replace(/[^\\d.]/g, '')"
    },
    model: {
      value: _vm.dataForm.phone.code,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.phone, "code", $$v);
      },
      expression: "dataForm.phone.code"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.phone.prefix,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.phone, "prefix", $$v);
      },
      expression: "dataForm.phone.prefix"
    }
  }, _vm._l(_vm.prefixOptions, function (single) {
    return _c('el-option', {
      key: single.title,
      attrs: {
        "value": single.value
      }
    }, [_vm._v(" " + _vm._s(single.value + " " + single.title) + " ")]);
  }), 1)], 1)], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Email",
      "prop": "email",
      "rules": [{
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        type: 'email',
        message: 'Please provide a valid email address',
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Email"
    },
    model: {
      value: _vm.dataForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "email", $$v);
      },
      expression: "dataForm.email"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Registered Address",
      "prop": "registeredOfficeAddress",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Company Registered Address"
    },
    model: {
      value: _vm.dataForm.registeredOfficeAddress,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "registeredOfficeAddress", $$v);
      },
      expression: "dataForm.registeredOfficeAddress"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Operation Address",
      "prop": "operationAddress",
      "rules": {
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' || _vm.businessType == 'COMPANY_INCORPORATION' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Company Operation Address"
    },
    model: {
      value: _vm.dataForm.operationAddress,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "operationAddress", $$v);
      },
      expression: "dataForm.operationAddress"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    staticClass: "fluid-width",
    attrs: {
      "label": "Incorporation Date",
      "prop": "incorporationDate",
      "rules": {
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-date-picker', {
    staticClass: "fluid-width",
    attrs: {
      "type": "date",
      "placeholder": "Incorporation Date",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.dataForm.incorporationDate,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "incorporationDate", $$v);
      },
      expression: "dataForm.incorporationDate"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Is the shareholder a Nominee Shareholder",
      "prop": "isNominatedShareholder",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.isNominatedShareholder,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "isNominatedShareholder", $$v);
      },
      expression: "dataForm.isNominatedShareholder"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Yes",
      "value": 1
    }
  }), _c('el-option', {
    attrs: {
      "label": "No",
      "value": 0
    }
  })], 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Related fund(s)",
      "prop": "fundsInfo",
      "rules": {
        required: true,
        type: 'array',
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "multiple": "",
      "filterable": "",
      "value-key": "fundName",
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.fundsInfo,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fundsInfo", $$v);
      },
      expression: "dataForm.fundsInfo"
    }
  }, _vm._l(_vm.fundInfoList, function (item, idx) {
    return _c('el-option', {
      key: `companyKey${idx}`,
      attrs: {
        "label": item.fundName,
        "value": item
      }
    });
  }), 1)], 1)], 1)], 1)] : _vm._e()], 2), _c('el-tab-pane', {
    attrs: {
      "label": "Individual",
      "name": "INDIVIDUAL"
    }
  }, [_vm.dataForm.memberType == 'INDIVIDUAL' ? [_c('el-form-item', {
    attrs: {
      "label": "",
      "prop": "positionHeld",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-radio-group', {
    on: {
      "input": _vm.positionHeldSelect
    },
    model: {
      value: _vm.dataForm.positionHeld,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "positionHeld", $$v);
      },
      expression: "dataForm.positionHeld"
    }
  }, _vm._l(_vm.selectablePositionHelds, function (item) {
    return _c('el-radio', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name (same as IC)",
      "prop": "fullName",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Name"
    },
    model: {
      value: _vm.dataForm.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fullName", $$v);
      },
      expression: "dataForm.fullName"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Nationality",
      "prop": "nationality",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select Nationality",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.nationality,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "nationality", $$v);
      },
      expression: "dataForm.nationality"
    }
  }, _vm._l(_vm.nationalityList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Identification Type",
      "prop": "identificationType",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select identificationType"
    },
    model: {
      value: _vm.dataForm.identificationType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "identificationType", $$v);
      },
      expression: "dataForm.identificationType"
    }
  }, _vm._l(_vm.identificationTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Identification No.",
      "prop": "identificationNo",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Identification No.",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.identificationNo,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "identificationNo", $$v);
      },
      expression: "dataForm.identificationNo"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Mobile No.",
      "prop": "phone.code",
      "rules": [{
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        validator: _vm.validatePhone,
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    staticClass: "input-with-select-xs",
    attrs: {
      "oninput": "value=value.replace(/[^\\d.]/g, '')"
    },
    model: {
      value: _vm.dataForm.phone.code,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.phone, "code", $$v);
      },
      expression: "dataForm.phone.code"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.phone.prefix,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.phone, "prefix", $$v);
      },
      expression: "dataForm.phone.prefix"
    }
  }, _vm._l(_vm.prefixOptions, function (single) {
    return _c('el-option', {
      key: single.title,
      attrs: {
        "value": single.value
      }
    }, [_vm._v(" " + _vm._s(single.value + " " + single.title) + " ")]);
  }), 1)], 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Email",
      "prop": "email",
      "rules": [{
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        type: 'email',
        message: 'Please provide a valid email address',
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Email"
    },
    model: {
      value: _vm.dataForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "email", $$v);
      },
      expression: "dataForm.email"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Residential address",
      "prop": "residentialAddress",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Residential address"
    },
    model: {
      value: _vm.dataForm.residentialAddress,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "residentialAddress", $$v);
      },
      expression: "dataForm.residentialAddress"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    staticClass: "fluid-width",
    attrs: {
      "label": "Birth Date",
      "prop": "birthDate",
      "rules": [{
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please select',
        trigger: 'submit'
      }, {
        validator: _vm.checkAge,
        trigger: 'submit'
      }]
    }
  }, [_c('el-date-picker', {
    staticClass: "fluid-width",
    attrs: {
      "type": "date",
      "placeholder": "Birth Date",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.dataForm.birthDate,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "birthDate", $$v);
      },
      expression: "dataForm.birthDate"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_vm.dataForm.positionHeld === 'SHAREHOLDER' || _vm.dataForm.positionHeld === 'DIRECTOR_AND_SHAREHOLDER' ? _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Is the shareholder a Nominee Shareholder",
      "prop": "isNominatedShareholder",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.isNominatedShareholder,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "isNominatedShareholder", $$v);
      },
      expression: "dataForm.isNominatedShareholder"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Yes",
      "value": 1
    }
  }), _c('el-option', {
    attrs: {
      "label": "No",
      "value": 0
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.dataForm.positionHeld === 'DIRECTOR' || _vm.dataForm.positionHeld === 'DIRECTOR_AND_SHAREHOLDER' ? _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Is the director a Nominee Director",
      "prop": "isNominatedDirector",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.isNominatedDirector,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "isNominatedDirector", $$v);
      },
      expression: "dataForm.isNominatedDirector"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Yes",
      "value": 1
    }
  }), _c('el-option', {
    attrs: {
      "label": "No",
      "value": 0
    }
  })], 1)], 1)], 1) : _vm._e(), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Does the individual hold share interest of any entity in PRC inland?",
      "prop": "isHoldShareOfPrcInland",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.isHoldShareOfPrcInland,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "isHoldShareOfPrcInland", $$v);
      },
      expression: "dataForm.isHoldShareOfPrcInland"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Yes",
      "value": 1
    }
  }), _c('el-option', {
    attrs: {
      "label": "No",
      "value": 0
    }
  })], 1)], 1)], 1), _vm.dataForm.isHoldShareOfPrcInland ? _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    staticClass: "fluid-width",
    attrs: {
      "label": "Please provide the name of RPC entity (1 entity is required)",
      "prop": "holdShareOfPrcEntity",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Please enter"
    },
    model: {
      value: _vm.dataForm.holdShareOfPrcEntity,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "holdShareOfPrcEntity", $$v);
      },
      expression: "dataForm.holdShareOfPrcEntity"
    }
  })], 1)], 1) : _vm._e(), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Related fund(s)",
      "prop": "fundsInfo",
      "rules": {
        required: true,
        type: 'array',
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "multiple": "",
      "filterable": "",
      "value-key": "fundName",
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.fundsInfo,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fundsInfo", $$v);
      },
      expression: "dataForm.fundsInfo"
    }
  }, _vm._l(_vm.fundInfoList, function (item, idx) {
    return _c('el-option', {
      key: `IndividualKey${idx}`,
      attrs: {
        "label": item.fundName,
        "value": item
      }
    });
  }), 1)], 1)], 1)], 1)] : _vm._e()], 2)], 1), _vm.dataForm.memberType == 'CORPORATE' && _vm.dataForm.positionHeld === 'SHAREHOLDER' ? [_vm.dataForm.representativeInfo ? _c('div', [_c('p', {
    staticClass: "representativeWrap"
  }, [_vm._v("Representative")]), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name (same as IC)",
      "prop": "representativeInfo.fullName",
      "rules": {
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Name"
    },
    model: {
      value: _vm.dataForm.representativeInfo.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo, "fullName", $$v);
      },
      expression: "dataForm.representativeInfo.fullName"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Nationality",
      "prop": "representativeInfo.nationality",
      "rules": {
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select Nationality",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.representativeInfo.nationality,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo, "nationality", $$v);
      },
      expression: "dataForm.representativeInfo.nationality"
    }
  }, _vm._l(_vm.nationalityList, function (item) {
    return _c('el-option', {
      key: `representativeInfoNationality${item.value}`,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Identification Type",
      "prop": "representativeInfo.identificationType",
      "rules": {
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select identificationType"
    },
    model: {
      value: _vm.dataForm.representativeInfo.identificationType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo, "identificationType", $$v);
      },
      expression: "dataForm.representativeInfo.identificationType"
    }
  }, _vm._l(_vm.identificationTypes, function (item) {
    return _c('el-option', {
      key: `representativeInfoIdCardType${item.value}`,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Identification No.",
      "prop": "representativeInfo.identificationNo",
      "rules": {
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Identification No.",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.representativeInfo.identificationNo,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo, "identificationNo", $$v);
      },
      expression: "dataForm.representativeInfo.identificationNo"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Mobile No.",
      "prop": "representativeInfo.phone.code",
      "rules": [{
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        validator: _vm.validateRepresentativePhone,
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    staticClass: "input-with-select-xs",
    attrs: {
      "oninput": "value=value.replace(/[^\\d.]/g, '')"
    },
    model: {
      value: _vm.dataForm.representativeInfo.phone.code,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo.phone, "code", $$v);
      },
      expression: "dataForm.representativeInfo.phone.code"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.representativeInfo.phone.prefix,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo.phone, "prefix", $$v);
      },
      expression: "dataForm.representativeInfo.phone.prefix"
    }
  }, _vm._l(_vm.prefixOptions, function (single) {
    return _c('el-option', {
      key: `representativeInfoPrefix${single.title}`,
      attrs: {
        "value": single.value
      }
    }, [_vm._v(" " + _vm._s(single.value + " " + single.title) + " ")]);
  }), 1)], 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Email",
      "prop": "representativeInfo.email",
      "rules": [{
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        type: 'email',
        message: 'Please provide a valid email address',
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Email"
    },
    model: {
      value: _vm.dataForm.representativeInfo.email,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo, "email", $$v);
      },
      expression: "dataForm.representativeInfo.email"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Residential address",
      "prop": "representativeInfo.residentialAddress",
      "rules": {
        required: _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? false : true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Residential address"
    },
    model: {
      value: _vm.dataForm.representativeInfo.residentialAddress,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo, "residentialAddress", $$v);
      },
      expression: "dataForm.representativeInfo.residentialAddress"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    staticClass: "fluid-width",
    attrs: {
      "label": "Birth Date",
      "prop": "representativeInfo.birthDate",
      "rules": [{
        required: false,
        message: 'Please select',
        trigger: 'submit'
      }, {
        validator: _vm.checkAge,
        trigger: 'submit'
      }]
    }
  }, [_c('el-date-picker', {
    staticClass: "fluid-width",
    attrs: {
      "type": "date",
      "placeholder": "Birth Date",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.dataForm.representativeInfo.birthDate,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.representativeInfo, "birthDate", $$v);
      },
      expression: "dataForm.representativeInfo.birthDate"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()] : _vm._e(), !(_vm.dataForm.memberType === 'INDIVIDUAL' && _vm.dataForm.positionHeld === 'DIRECTOR') ? [_c('el-form-item', {
    attrs: {
      "prop": "sharesInfo",
      "rules": {
        required: true,
        type: 'array',
        message: 'Please add',
        trigger: 'submit'
      }
    }
  }, [_c('table', {
    staticClass: "shares-info-table"
  }, [_c('tr', {
    staticClass: "lh20"
  }, [_c('th', {
    attrs: {
      "width": "140ppx"
    }
  }, [_vm._v("Related fund(s)")]), _c('th', {
    attrs: {
      "width": "140ppx"
    }
  }, [_vm._v("Type of Shares")]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("No of shares allotted")]), _c('th', {
    staticClass: "enbr"
  }, [_vm._v("Amount Of Registered Share Capital")]), _c('th', [_vm._v("Paid-in capital")]), _c('th', {
    attrs: {
      "width": "140px"
    }
  }, [_vm._v("Shares held in trust")]), _c('th', [_vm._v("Name of the trust")]), _c('th', {
    attrs: {
      "width": "80px"
    }
  }, [_vm._v("Opetation")])]), _c('tbody', _vm._l(_vm.dataForm.sharesInfo, function (item, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', {
      attrs: {
        "align": "center"
      }
    }, [_c('el-form-item', {
      staticClass: "mb0",
      attrs: {
        "prop": 'sharesInfo.' + idx + '.fundsName',
        "rules": {
          required: true,
          message: 'Please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-select', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "placeholder": "Select"
      },
      model: {
        value: item.fundsName,
        callback: function ($$v) {
          _vm.$set(item, "fundsName", $$v);
        },
        expression: "item.fundsName"
      }
    }, _vm._l(_vm.fundInfoList, function (child, sy) {
      return _c('el-option', {
        key: `sharesInfo${sy}`,
        attrs: {
          "label": child.fundName,
          "value": child.fundName
        }
      });
    }), 1)], 1)], 1), _c('td', {
      attrs: {
        "align": "center"
      }
    }, [_c('el-form-item', {
      staticClass: "mb0",
      attrs: {
        "prop": 'sharesInfo.' + idx + '.typeOfShares',
        "rules": {
          required: true,
          message: 'Please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-select', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "placeholder": "Select"
      },
      model: {
        value: item.typeOfShares,
        callback: function ($$v) {
          _vm.$set(item, "typeOfShares", $$v);
        },
        expression: "item.typeOfShares"
      }
    }, _vm._l(_vm.typeOfShares, function (item) {
      return _c('el-option', {
        key: item.value,
        attrs: {
          "label": item.title,
          "value": item.value
        }
      });
    }), 1)], 1)], 1), _c('td', {
      attrs: {
        "align": "center"
      }
    }, [_c('el-form-item', {
      staticClass: "mb0",
      attrs: {
        "prop": 'sharesInfo.' + idx + '.quantityOfSharesAllotted',
        "rules": {
          required: true,
          message: 'Please enter',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      directives: [{
        name: "positiveInteger",
        rawName: "v-positiveInteger"
      }],
      model: {
        value: item.quantityOfSharesAllotted,
        callback: function ($$v) {
          _vm.$set(item, "quantityOfSharesAllotted", $$v);
        },
        expression: "item.quantityOfSharesAllotted"
      }
    })], 1)], 1), _c('td', [_c('el-input', {
      directives: [{
        name: "positivePoint2",
        rawName: "v-positivePoint2"
      }],
      staticClass: "input-with-select-xs",
      model: {
        value: item.amountOfPaidUpShareCapital.amount,
        callback: function ($$v) {
          _vm.$set(item.amountOfPaidUpShareCapital, "amount", $$v);
        },
        expression: "item.amountOfPaidUpShareCapital.amount"
      }
    }, [_c('el-select', {
      attrs: {
        "slot": "prepend"
      },
      slot: "prepend",
      model: {
        value: item.amountOfPaidUpShareCapital.currency,
        callback: function ($$v) {
          _vm.$set(item.amountOfPaidUpShareCapital, "currency", $$v);
        },
        expression: "item.amountOfPaidUpShareCapital.currency"
      }
    }, _vm._l(_vm.currencyOptions, function (single) {
      return _c('el-option', {
        key: single.value,
        attrs: {
          "label": single.title,
          "value": single.value
        }
      });
    }), 1)], 1), _c('el-form-item', {
      staticClass: "hidePrompt",
      attrs: {
        "prop": 'sharesInfo.' + idx + '.amountOfPaidUpShareCapital.currency',
        "rules": {
          required: true,
          message: 'Please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      staticStyle: {
        "display": "none"
      },
      model: {
        value: item.amountOfPaidUpShareCapital.currency,
        callback: function ($$v) {
          _vm.$set(item.amountOfPaidUpShareCapital, "currency", $$v);
        },
        expression: "item.amountOfPaidUpShareCapital.currency"
      }
    })], 1), _c('el-form-item', {
      staticClass: "ml100 hidePrompt",
      attrs: {
        "prop": 'sharesInfo.' + idx + '.amountOfPaidUpShareCapital.amount',
        "rules": {
          required: true,
          message: 'Please enter',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      staticStyle: {
        "display": "none"
      },
      model: {
        value: item.amountOfPaidUpShareCapital.amount,
        callback: function ($$v) {
          _vm.$set(item.amountOfPaidUpShareCapital, "amount", $$v);
        },
        expression: "item.amountOfPaidUpShareCapital.amount"
      }
    })], 1)], 1), _c('td', [_c('el-input', {
      directives: [{
        name: "positivePoint2",
        rawName: "v-positivePoint2"
      }],
      staticClass: "input-with-select-xs",
      model: {
        value: item.amountOfPaidInShareCapital.amount,
        callback: function ($$v) {
          _vm.$set(item.amountOfPaidInShareCapital, "amount", $$v);
        },
        expression: "item.amountOfPaidInShareCapital.amount"
      }
    }, [_c('el-select', {
      attrs: {
        "slot": "prepend"
      },
      slot: "prepend",
      model: {
        value: item.amountOfPaidInShareCapital.currency,
        callback: function ($$v) {
          _vm.$set(item.amountOfPaidInShareCapital, "currency", $$v);
        },
        expression: "item.amountOfPaidInShareCapital.currency"
      }
    }, _vm._l(_vm.currencyOptions, function (single) {
      return _c('el-option', {
        key: single.value,
        attrs: {
          "label": single.title,
          "value": single.value
        }
      });
    }), 1)], 1), _c('el-form-item', {
      staticClass: "hidePrompt",
      attrs: {
        "prop": 'sharesInfo.' + idx + '.amountOfPaidInShareCapital.currency',
        "rules": {
          required: true,
          message: 'Please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      staticStyle: {
        "display": "none"
      },
      model: {
        value: item.amountOfPaidInShareCapital.currency,
        callback: function ($$v) {
          _vm.$set(item.amountOfPaidInShareCapital, "currency", $$v);
        },
        expression: "item.amountOfPaidInShareCapital.currency"
      }
    })], 1), _c('el-form-item', {
      staticClass: "ml100 hidePrompt",
      attrs: {
        "prop": 'sharesInfo.' + idx + '.amountOfPaidInShareCapital.amount',
        "rules": {
          required: true,
          message: 'Please enter',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      staticStyle: {
        "display": "none"
      },
      model: {
        value: item.amountOfPaidInShareCapital.amount,
        callback: function ($$v) {
          _vm.$set(item.amountOfPaidInShareCapital, "amount", $$v);
        },
        expression: "item.amountOfPaidInShareCapital.amount"
      }
    })], 1)], 1), _c('td', {
      attrs: {
        "align": "center"
      }
    }, [_c('el-radio-group', {
      on: {
        "change": function () {
          if (!item.sharesHoldInTrust) {
            _vm.$refs.dataForm.clearValidate(['sharesInfo.' + idx + '.nameOfTheTrust']);
          }
        }
      },
      model: {
        value: item.sharesHoldInTrust,
        callback: function ($$v) {
          _vm.$set(item, "sharesHoldInTrust", $$v);
        },
        expression: "item.sharesHoldInTrust"
      }
    }, [_c('el-radio', {
      attrs: {
        "label": 1
      }
    }, [_vm._v("Yes")]), _c('el-radio', {
      attrs: {
        "label": 0
      }
    }, [_vm._v("No")])], 1)], 1), _c('td', {
      attrs: {
        "align": "center"
      }
    }, [_c('el-form-item', {
      staticClass: "mb0",
      attrs: {
        "prop": 'sharesInfo.' + idx + '.nameOfTheTrust',
        "rules": {
          required: item.sharesHoldInTrust ? true : false,
          message: 'Please enter',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      model: {
        value: item.nameOfTheTrust,
        callback: function ($$v) {
          _vm.$set(item, "nameOfTheTrust", $$v);
        },
        expression: "item.nameOfTheTrust"
      }
    })], 1)], 1), _c('td', {
      attrs: {
        "align": "center"
      }
    }, [_c('el-button', {
      attrs: {
        "type": "text",
        "size": "mini"
      },
      on: {
        "click": function ($event) {
          return _vm.delRow(idx);
        }
      }
    }, [_vm._v("Delete")])], 1)]);
  }), 0)])]), _c('div', {
    staticClass: "text-center"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-plus"
    },
    on: {
      "click": _vm.addRow
    }
  }, [_vm._v("Add More")])], 1)] : _vm._e()], 2), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }