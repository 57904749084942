var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt30 pd26 hg350 bg-white border-radius-10"
  }, [_vm._m(0), _c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "flex justify-center align-center"
  }, [_c('div', {
    staticClass: "hg170 flex justify-center flex-direction align-start"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/img/home/reviewed.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "ml20 hg170 flex justify-center flex-direction align-start"
  }, [_c('h3', {
    staticClass: "aurantia home-title"
  }, [_vm._v(" " + _vm._s(_vm.checkerObj.passCnt) + " [" + _vm._s(parseFloat(_vm.checkerObj.passCnt) && parseFloat(_vm.checkerObj.allCnt) ? _vm.toFixedDecimal(_vm.div(_vm.checkerObj.passCnt, _vm.checkerObj.allCnt) * 100) + "%" : "0.00%") + "] ")]), _c('p', {
    staticClass: "mt12 home-introduce"
  }, [_vm._v("Pass")])])])]), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "flex justify-center align-center"
  }, [_c('div', {
    staticClass: "hg170 flex justify-center flex-direction align-start"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/img/home/Fail.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "ml20 hg170 flex justify-center flex-direction align-start"
  }, [_c('h3', {
    staticClass: "darkBlue home-title"
  }, [_vm._v(" " + _vm._s(_vm.checkerObj.rejectCnt) + " [" + _vm._s(parseFloat(_vm.checkerObj.rejectCnt) && parseFloat(_vm.checkerObj.allCnt) ? _vm.toFixedDecimal(_vm.div(_vm.checkerObj.rejectCnt, _vm.checkerObj.allCnt) * 100) + "%" : "0.00%") + "] ")]), _c('p', {
    staticClass: "mt12 home-introduce"
  }, [_vm._v("Reject")])])])])], 1), _c('div', {
    staticClass: "progress-wrap"
  }, [_c('div', {
    staticClass: "pass-bar",
    class: _vm.checkerObj.rejectCnt ? '' : 'right-radius',
    style: {
      width: parseFloat(_vm.checkerObj.passCnt) && parseFloat(_vm.checkerObj.allCnt) ? _vm.toFixedDecimal(_vm.div(_vm.checkerObj.passCnt, _vm.checkerObj.allCnt) * 100) + '%' : 0
    }
  }), _c('div', {
    staticClass: "reject-bar",
    style: {
      width: parseFloat(_vm.checkerObj.rejectCnt) && parseFloat(_vm.checkerObj.allCnt) ? _vm.toFixedDecimal(_vm.div(_vm.checkerObj.rejectCnt, _vm.checkerObj.allCnt) * 100) + '%' : 0
    }
  })]), _c('p', {
    staticClass: "total-ratio"
  }, [_c('span', {
    staticClass: "fs20 mr6"
  }, [_vm._v("Total:")]), _c('span', {
    staticClass: "fwb fs30"
  }, [_vm._v(_vm._s(_vm.checkerObj.allCnt) + " [100%]")])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-direction-row align-center justify-between"
  }, [_c('h3', {
    staticClass: "home-title"
  }, [_vm._v("Checker")]), _c('p', {
    staticClass: "home-introduce"
  }, [_vm._v("Average over preceding 3 months")])]);

}]

export { render, staticRenderFns }