var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1200px",
      "title": "Edit Linked Transaction",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "pb26"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": _vm.addLinkedTransaction
    }
  }, [_vm._v("Add")])], 1), _c('div', {
    staticClass: "tableBorder"
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-width": "0px"
    }
  }, [_c('div', {
    staticClass: "flex fs14 fwb is-required customTh"
  }, [_c('p', {
    staticClass: "text-center wd220 mr18"
  }, [_vm._v(" Transaction No. "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("Data Source: \"Operation\" section of this system")]), _c('span', {
    staticClass: "pearl-blue fs16 ml4 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1), _c('p', {
    staticClass: "text-center wd240 mr18"
  }, [_vm._v("Owner")]), _c('p', {
    staticClass: "text-center wd220 mr18"
  }, [_vm._v("Related Transaction")]), _c('p', {
    staticClass: "text-center wd220 mr18"
  }, [_vm._v("Related Turnover")]), _c('p', {
    staticClass: "text-center wd220 mr18"
  }, [_c('span', {
    staticClass: "red"
  }, [_vm._v("*")]), _vm._v(" In-charge "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("Data Source: \"Settings-User\" section of this system")]), _c('span', {
    staticClass: "pearl-blue fs16 ml4 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1), _c('p', {
    staticClass: "text-center wd60"
  }, [_vm._v("Action")])]), _vm._l(_vm.dataForm.relatedUserTransactions, function (item, index) {
    return _c('div', {
      key: `relatedUserTransactions${index}`,
      staticClass: "flex customTbody"
    }, [_c('el-form-item', {
      ref: `relatedUserTransactions.${index}.transactionNo`,
      refInFor: true,
      staticClass: "fxRow wd220 mr18",
      attrs: {
        "prop": `relatedUserTransactions.${index}.transactionNo`
      }
    }, [_c('el-select', {
      staticClass: "fluid-width",
      attrs: {
        "placeholder": "Transaction No.",
        "clearable": "",
        "filterable": "",
        "remote": "",
        "remote-method": _vm.getTransactionNoRemote,
        "loading": _vm.transactionNoLoading,
        "disabled": _vm.isHasTransactionNo(_vm.dataForm.relatedUserTransactions[index].transactionNo),
        "title": _vm.isHasTransactionNo(_vm.dataForm.relatedUserTransactions[index].transactionNo) ? 'Have been used' : ''
      },
      on: {
        "clear": function ($event) {
          return _vm.getTransactionNoRemote('');
        },
        "focus": function ($event) {
          return _vm.getTransactionNoRemote('');
        },
        "change": function (val) {
          _vm.transactionNoChange(val, index);
        }
      },
      model: {
        value: _vm.dataForm.relatedUserTransactions[index].transactionNo,
        callback: function ($$v) {
          _vm.$set(_vm.dataForm.relatedUserTransactions[index], "transactionNo", $$v);
        },
        expression: "dataForm.relatedUserTransactions[index].transactionNo"
      }
    }, _vm._l(_vm.transactionNoList, function (item, xv) {
      return _c('el-option', {
        key: `${item.transactionNo}${xv}`,
        attrs: {
          "label": `${item.transactionNo}-${item.uen}-${item.companyName}`,
          "value": item.transactionNo,
          "disabled": _vm.isHasSelectedList(item.transactionNo)
        }
      });
    }), 1)], 1), _c('el-form-item', {
      staticClass: "fxRow wd240 mr18"
    }, [_c('div', {
      staticClass: "lh20 enbr"
    }, [_vm._v(_vm._s(_vm.dataForm.relatedUserTransactions[index].payee))])]), _c('el-form-item', {
      ref: `relatedUserTransactions.${index}.relatedTransaction`,
      refInFor: true,
      staticClass: "fxRow wd220 mr18",
      attrs: {
        "prop": `relatedUserTransactions.${index}.relatedTransaction`
      }
    }, [_c('el-input', {
      directives: [{
        name: "minusPoint2",
        rawName: "v-minusPoint2"
      }],
      staticClass: "fluid-width",
      attrs: {
        "clearable": "",
        "placeholder": "0.00"
      },
      model: {
        value: _vm.dataForm.relatedUserTransactions[index].relatedTransaction,
        callback: function ($$v) {
          _vm.$set(_vm.dataForm.relatedUserTransactions[index], "relatedTransaction", $$v);
        },
        expression: "dataForm.relatedUserTransactions[index].relatedTransaction"
      }
    }, [_c('template', {
      slot: "prefix"
    }, [_vm._v("S$")])], 2)], 1), _c('el-form-item', {
      ref: `relatedUserTransactions.${index}.relatedTurnover`,
      refInFor: true,
      staticClass: "fxRow wd220 mr18",
      attrs: {
        "prop": `relatedUserTransactions.${index}.relatedTurnover`
      }
    }, [_c('el-input', {
      directives: [{
        name: "minusPoint2",
        rawName: "v-minusPoint2"
      }],
      staticClass: "fluid-width",
      attrs: {
        "clearable": "",
        "placeholder": "0.00"
      },
      model: {
        value: _vm.dataForm.relatedUserTransactions[index].relatedTurnover,
        callback: function ($$v) {
          _vm.$set(_vm.dataForm.relatedUserTransactions[index], "relatedTurnover", $$v);
        },
        expression: "dataForm.relatedUserTransactions[index].relatedTurnover"
      }
    }, [_c('template', {
      slot: "prefix"
    }, [_vm._v("S$")])], 2)], 1), _c('el-form-item', {
      ref: `relatedUserTransactions.${index}.inChargeUserIds`,
      refInFor: true,
      staticClass: "fxRow wd220 mr18",
      attrs: {
        "prop": `relatedUserTransactions.${index}.inChargeUserIds`,
        "rules": {
          required: true,
          message: 'please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-select', {
      staticClass: "fluid-width",
      attrs: {
        "placeholder": "In-charge",
        "clearable": "",
        "filterable": "",
        "remote": "",
        "multiple": "",
        "remote-method": _vm.getInChargeRemote,
        "loading": _vm.inChargeLoading
      },
      on: {
        "clear": function ($event) {
          return _vm.getInChargeRemote('');
        },
        "focus": function ($event) {
          return _vm.getInChargeRemote('');
        }
      },
      model: {
        value: _vm.dataForm.relatedUserTransactions[index].inChargeUserIds,
        callback: function ($$v) {
          _vm.$set(_vm.dataForm.relatedUserTransactions[index], "inChargeUserIds", $$v);
        },
        expression: "dataForm.relatedUserTransactions[index].inChargeUserIds"
      }
    }, _vm._l(_vm.inChargeList, function (item, pm) {
      return _c('el-option', {
        key: `${item.id}${pm}`,
        attrs: {
          "label": `${item.nickname}-${item.staffNo}-${item.position}`,
          "value": item.id
        }
      });
    }), 1)], 1), _c('el-form-item', {
      staticClass: "fxRow wd60"
    }, [!_vm.isHasTransactionNo(_vm.dataForm.relatedUserTransactions[index].transactionNo) ? _c('span', {
      staticClass: "fs14 cursor-pointer red",
      on: {
        "click": function ($event) {
          return _vm.dataForm.relatedUserTransactions.splice(index, 1);
        }
      }
    }, [_vm._v(" Delete ")]) : _vm._e()])], 1);
  })], 2)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("Save")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }