<template>
  <el-dialog width="1200px" title="Edit Linked Transaction" :close-on-click-modal="false" :visible.sync="visible">
    <div class="pb26">
      <el-button type="primary" plain @click="addLinkedTransaction">Add</el-button>
    </div>
    <div class="tableBorder">
      <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="0px">
        <div class="flex fs14 fwb is-required customTh">
          <p class="text-center wd220 mr18">
            Transaction No.
            <el-popover placement="bottom" trigger="hover">
              <span class="fs12">Data Source: "Operation" section of this system</span>
              <span slot="reference" class="pearl-blue fs16 ml4 el-icon-warning"></span>
            </el-popover>
          </p>
          <p class="text-center wd240 mr18">Owner</p>
          <p class="text-center wd220 mr18">Related Transaction</p>
          <p class="text-center wd220 mr18">Related Turnover</p>
          <p class="text-center wd220 mr18">
            <span class="red">*</span>
            In-charge
            <el-popover placement="bottom" trigger="hover">
              <span class="fs12">Data Source: "Settings-User" section of this system</span>
              <span slot="reference" class="pearl-blue fs16 ml4 el-icon-warning"></span>
            </el-popover>
          </p>
          <p class="text-center wd60">Action</p>
        </div>

        <div class="flex customTbody" v-for="(item, index) in dataForm.relatedUserTransactions" :key="`relatedUserTransactions${index}`">
          <el-form-item
            :prop="`relatedUserTransactions.${index}.transactionNo`"
            :ref="`relatedUserTransactions.${index}.transactionNo`"
            class="fxRow wd220 mr18"
          >
            <el-select
              v-model="dataForm.relatedUserTransactions[index].transactionNo"
              placeholder="Transaction No."
              clearable
              filterable
              remote
              :remote-method="getTransactionNoRemote"
              @clear="getTransactionNoRemote('')"
              @focus="getTransactionNoRemote('')"
              :loading="transactionNoLoading"
              :disabled="isHasTransactionNo(dataForm.relatedUserTransactions[index].transactionNo)"
              :title="isHasTransactionNo(dataForm.relatedUserTransactions[index].transactionNo) ? 'Have been used' : ''"
              @change="
                val => {
                  transactionNoChange(val, index);
                }
              "
              class="fluid-width"
            >
              <el-option
                v-for="(item, xv) in transactionNoList"
                :label="`${item.transactionNo}-${item.uen}-${item.companyName}`"
                :key="`${item.transactionNo}${xv}`"
                :value="item.transactionNo"
                :disabled="isHasSelectedList(item.transactionNo)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="fxRow wd240 mr18">
            <div class="lh20 enbr">{{ dataForm.relatedUserTransactions[index].payee }}</div>
          </el-form-item>
          <el-form-item
            :prop="`relatedUserTransactions.${index}.relatedTransaction`"
            :ref="`relatedUserTransactions.${index}.relatedTransaction`"
            class="fxRow wd220 mr18"
          >
            <el-input v-model="dataForm.relatedUserTransactions[index].relatedTransaction" v-minusPoint2 clearable placeholder="0.00" class="fluid-width">
              <template slot="prefix">S$</template>
            </el-input>
          </el-form-item>
          <el-form-item
            :prop="`relatedUserTransactions.${index}.relatedTurnover`"
            :ref="`relatedUserTransactions.${index}.relatedTurnover`"
            class="fxRow wd220 mr18"
          >
            <el-input v-model="dataForm.relatedUserTransactions[index].relatedTurnover" v-minusPoint2 clearable placeholder="0.00" class="fluid-width">
              <template slot="prefix">S$</template>
            </el-input>
          </el-form-item>
          <el-form-item
            :prop="`relatedUserTransactions.${index}.inChargeUserIds`"
            :ref="`relatedUserTransactions.${index}.inChargeUserIds`"
            :rules="{
              required: true,
              message: 'please select',
              trigger: 'submit',
            }"
            class="fxRow wd220 mr18"
          >
            <el-select
              v-model="dataForm.relatedUserTransactions[index].inChargeUserIds"
              placeholder="In-charge"
              clearable
              filterable
              remote
              multiple
              :remote-method="getInChargeRemote"
              @clear="getInChargeRemote('')"
              @focus="getInChargeRemote('')"
              :loading="inChargeLoading"
              class="fluid-width"
            >
              <el-option
                v-for="(item, pm) in inChargeList"
                :label="`${item.nickname}-${item.staffNo}-${item.position}`"
                :key="`${item.id}${pm}`"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="fxRow wd60">
            <span
              class="fs14 cursor-pointer red"
              @click="dataForm.relatedUserTransactions.splice(index, 1)"
              v-if="!isHasTransactionNo(dataForm.relatedUserTransactions[index].transactionNo)"
            >
              Delete
            </span>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    engagementId: {
      type: String,
      require: true,
    },
    controlDataList: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        relatedUserTransactions: [],
      },
      transactionNoLoading: false,
      transactionNoList: [],
      inChargeLoading: false,
      inChargeList: [],
    };
  },
  computed: {
    // 判断Engagement Control列表中是否存在transaction No；如果存在，就不能修改或删除
    isHasTransactionNo: function () {
      return transactionNo => {
        if (transactionNo) {
          let transactionNoList = [];
          this.controlDataList.forEach(element => {
            if (element.linkedTransactionNo != null && element.linkedTransactionNo.length > 0) {
              transactionNoList.push(...element.linkedTransactionNo);
            }
          });
          return transactionNoList.includes(transactionNo);
        } else {
          return false;
        }
      };
    },
    // 判断选项(option)是否已经被选中
    isHasSelectedList: function () {
      return transactionNo => {
        if (transactionNo) {
          const newArr = this.dataForm.relatedUserTransactions.map(item => item.transactionNo);
          return newArr.includes(transactionNo);
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    // 获取Transaction No列表
    async getTransactionNoRemote(query) {
      this.transactionNoLoading = true;
      const { code, data } = await this.$http.get("/admin/uenMa/getList", {
        params: {
          keywords: query,
        },
      });
      this.transactionNoLoading = false;
      if (code === 200) {
        this.transactionNoList = data;
      } else {
        this.transactionNoList = [];
      }
    },
    // 获取in charge列表
    async getInChargeRemote(query) {
      this.inChargeLoading = true;
      const { code, data } = await this.$http.get("/admin/inChargeUserMa/getList", {
        params: {
          keywords: query,
        },
      });
      this.inChargeLoading = false;
      if (code === 200) {
        this.inChargeList = data;
      } else {
        this.inChargeList = [];
      }
    },
    async init(list) {
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.getTransactionNoRemote("");
      this.getInChargeRemote("");
      const listCopy = list.map(item => {
        return {
          transactionNo: item.transactionNo,
          payee: item.payee,
          inChargeUserIds: item?.inChargeUsers.map(ele => ele.id),
          relatedTransaction: item.relatedTransaction,
          relatedTurnover: item.relatedTurnover,
        };
      });
      this.dataForm = {
        relatedUserTransactions: listCopy,
      };
    },
    // add
    addLinkedTransaction() {
      this.dataForm.relatedUserTransactions.push({
        transactionNo: "",
        payee: null,
        relatedTransaction: null,
        relatedTurnover: null,
        inChargeUserIds: [],
      });
      this.$forceUpdate();
    },
    // Transaction No. - change
    transactionNoChange(nub, idx) {
      const resItem = this.transactionNoList.find(item => item.transactionNo == nub);
      this.dataForm.relatedUserTransactions[idx].payee = resItem?.payee;
      this.dataForm.relatedUserTransactions[idx].relatedTransaction = resItem?.relatedTransaction;
      this.dataForm.relatedUserTransactions[idx].relatedTurnover = resItem?.relatedTurnover;
      this.$forceUpdate();
    },
    // 保存
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          // 判断数组中是否存在重复的transactionNo
          const newArr = this.dataForm.relatedUserTransactions.map(item => item.transactionNo);
          const isRepeat = newArr.some((x, index, arr) => arr.findIndex(y => y == x) != index);
          if (isRepeat) {
            this.$message.warning("Duplicate Transaction No. Exist");
            return;
          }
          const { code, msg } = await this.$http.post("/admin/engagementManage/saveRelatedTransactions", {
            engagementId: this.engagementId,
            dataList: this.dataForm.relatedUserTransactions,
          });
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.$emit("linkedTransactionsFeedback");
                this.visible = false;
              },
            });
          } else {
            this.$message.error(msg);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.blue {
  color: $--color-primary;
}

.red {
  color: $--color-danger;
}

.tableBorder {
  .customTh {
    padding: 0 20px;
    background-color: #f4f8fe;
    border-bottom: 1px solid #ebeef5;
  }

  .customTbody {
    padding: 26px 20px 0;
    border-bottom: 1px solid #ebeef5;
  }
}
</style>
