<template>
  <el-dialog width="1000px" title="Config" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-position="left" label-width="168px">
      <el-form-item label="custom plan" prop="isCustomOpen">
        <el-radio-group v-model="dataForm.isCustomOpen">
          <el-radio v-for="item in options" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Exchange Rate" prop="forexRate">
        <span slot="label">
          <span>
            Exchange Rate
            <el-popover placement="bottom-start" width="220" trigger="hover" content="SGD => CNY">
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </span>
        </span>
        <el-input-number v-model="dataForm.forexRate" controls-position="right" :min="0" :step="0.01" label="FX Rate"></el-input-number>
      </el-form-item>
      <el-form-item label="Business Type" prop="businessType">
        <el-select
          v-model="dataForm.businessSpecificationObj.businessType"
          placeholder="Select businessType"
          class="fluid-width"
          @change="businessTypeChange"
        >
          <el-option v-for="item in businessTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Business Specification" prop="specification">
        <el-editor :height="500" v-model="dataForm.businessSpecificationObj.specification" />
      </el-form-item>
      <el-form-item label="Business Specification(CN)" prop="specificationCn">
        <el-editor :height="500" v-model="dataForm.businessSpecificationObj.specificationCn" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closePopover">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        isCustomOpen: 1,
        forexRate: 1,
        businessSpecificationObj: {
          businessType: "",
          specification: "",
          specificationCn: "",
        },
      },
      options: [
        {
          title: "Yes",
          value: 1,
        },
        {
          title: "No",
          value: 0,
        },
      ],
      businessSpecificationList: [],
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["businessTypes"]),
  },
  methods: {
    businessTypeChange(type) {
      let businessSpecificationList = JSON.parse(JSON.stringify(this.businessSpecificationList));
      const businessSpecificationObj = businessSpecificationList.find(item => item.businessType === type);
      this.$set(this.dataForm, "businessSpecificationObj", businessSpecificationObj);
    },
    async init() {
      this.businessSpecificationList = [];
      this.submitControl = true;
      this.visible = true;
      let businessTypesList = [];
      const businessTypes = this.businessTypes;
      businessTypes.forEach(element => {
        businessTypesList.push({
          businessType: element.value,
          specification: "",
          specificationCn: "",
        });
      });
      const { data } = await this.$http.get(`/admin/stPlanMetaManage/detail`);
      const resList = data.businessSpecificationList || [];
      for (let i in businessTypesList) {
        let fdObj = resList.find(item => item.businessType === businessTypesList[i].businessType);
        this.businessSpecificationList.push(fdObj || businessTypesList[i]);
      }
      const businessSpecificationList = JSON.parse(JSON.stringify(this.businessSpecificationList));
      const businessSpecificationObj = businessSpecificationList.find(item => item.businessType === this.businessTypes[0].value);
      this.dataForm = { isCustomOpen: data.isCustomOpen, forexRate: data.forexRate, businessSpecificationObj };
    },
    closePopover() {
      this.visible = false;
    },
    async dataFormSubmit() {
      if (this.submitControl) {
        this.submitControl = false;
        let businessSpecificationList = JSON.parse(JSON.stringify(this.businessSpecificationList));
        for (let i in businessSpecificationList) {
          if (businessSpecificationList[i].businessType === this.dataForm.businessSpecificationObj.businessType) {
            businessSpecificationList[i].specification = this.dataForm.businessSpecificationObj.specification;
            businessSpecificationList[i].specificationCn = this.dataForm.businessSpecificationObj.specificationCn;
          }
        }
        const { code, msg } = await this.$http.post(`/admin/stPlanMetaManage/update`, {
          isCustomOpen: this.dataForm.isCustomOpen,
          forexRate: this.dataForm.forexRate,
          businessSpecificationList,
        });
        if (code === 200) {
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.submitControl = true;
            },
          });
        } else {
          this.$message.error(msg);
          this.submitControl = true;
        }
      }
    },
  },
};
</script>
