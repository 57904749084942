<template>
  <el-dialog width="960px" :title="!dataForm.id ? 'Add Invoice Info' : 'Edit Invoice Info'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="160px">
      <el-form-item label="Entity type" prop="type">
        <el-select style="width: 100%" v-model="dataForm.type" placeholder="Select type">
          <el-option v-for="item in entityTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Title" prop="title">
        <el-input v-model="dataForm.title" placeholder="Invoice title (company name)" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Title(CN)" prop="titleCn">
        <el-input v-model="dataForm.titleCn" placeholder="Chinese company name"></el-input>
      </el-form-item>
      <el-form-item label="Address" prop="address">
        <el-input v-model="dataForm.address" placeholder="Address" :oninput="isEn"></el-input>
      </el-form-item>
      <el-row>
        <el-col :lg="20" :span="20">
          <el-row>
            <el-col :lg="13" :span="9">
              <el-form-item label="Country" prop="country">
                <el-select style="width: 100%" v-model="dataForm.country" placeholder="Select Country">
                  <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Postal Code" prop="postalCode">
            <el-input v-model="dataForm.postalCode" placeholder="Postal Code" :oninput="isEn"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Registration No." prop="registrationNumber">
        <el-input v-model="dataForm.registrationNumber" placeholder="Registration No." :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Tax Rate" prop="taxRate">
        <!-- <el-input type="number" v-model="dataForm.taxRate" placeholder="Tax Rate"></el-input> -->
        <el-input-number v-model="dataForm.taxRate" controls-position="right" :min="0" :step="0.01" label="Tax Rate"></el-input-number>
      </el-form-item>
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">Disable</el-radio>
          <el-radio :label="1">Enable</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        type: "",
        title: "",
        titleCn: "",
        address: "",
        country: "",
        postalCode: "",
        registrationNumber: "",
        taxRate: "",
        status: 1,
      },
      dataRule: {
        type: [{ required: true, message: "Entity type is required", trigger: "blur" }],
        title: [{ required: true, message: "title is required", trigger: "blur" }],
        titleCn: [{ required: true, message: "title(CN) is required", trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["countryList", "entityTypes"]),
  },
  methods: {
    init(id) {
      this.submitControl = true;
      this.visible = true;
      this.$nextTick().then(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          this.getDetail(id);
        } else {
          this.dataForm.id = undefined;
          this.dataForm.content = "";
        }
      });
    },
    //详情
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/stInvoiceInfoManage/${id}/detail`);
      this.dataForm = { ...data };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.$http.post(
            this.dataForm.id ? `/admin/stInvoiceInfoManage/${this.dataForm.id}/update` : `/admin/stInvoiceInfoManage/create`,
            this.dataForm,
          );
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
