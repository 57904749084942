<template>
  <el-dialog title="Update Refund Info" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="rules" ref="dataForm" autocomplete="off" label-width="140px">
      <el-form-item label="Apply Reason" v-if="orderInfo.orderRefund">{{ orderInfo.orderRefund.applyReason }}</el-form-item>
      <el-form-item label="Fail Reason" v-if="orderInfo.orderRefund">{{ orderInfo.orderRefund.failReason }}</el-form-item>
      <el-form-item label="Update Reason" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Update Reason"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        remark: "",
      },
      rules: {
        remark: [{ required: true, message: "Please Enter", trigger: "submit" }],
      },
      submitControl: true,
    };
  },
  props: {
    orderInfo: {
      type: Object,
      default: function () {
        return {
          orderRefund: {
            status: null,
          },
        };
      },
    },
  },
  computed: {},
  methods: {
    ...mapActions("applyment", ["doRefundReplenish"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          let orderInfoCopy = JSON.parse(JSON.stringify(this.orderInfo));
          orderInfoCopy.orderRefund.status = "REFUND_INFO_UPDATED";
          this.doRefundReplenish({ ...this.dataForm, orderInfo: orderInfoCopy })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
