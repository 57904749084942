var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp mt40"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Extra Information")]), _c('div', {
    staticClass: "opts"
  }, [(_vm.detail.applymentStatus === 'REVISING' || _vm.detail.applymentStatus === 'CORRECTED' || _vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'AUDIT_FAIL' || _vm.detail.applymentStatus === 'REFUND_APPLY_DENY' || _vm.detail.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.detail.applymentStatus === 'ACRA_REJECTED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    ref: "",
    on: {
      "click": function ($event) {
        return _vm.openEdit();
      }
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Registered Office")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self no-event"
  }, [_c('el-radio-group', {
    attrs: {
      "value": _vm.detail.registeredOfficeType
    }
  }, _vm._l(_vm.registerOfficeTypeList, function (item) {
    return _c('div', {
      key: item.value
    }, [_c('el-radio', {
      staticClass: "pt15 pb15",
      attrs: {
        "label": item.value
      }
    }, [_c('span', [_vm._v(_vm._s(item.title))]), item.value === 1 && _vm.detail.registeredOfficeType === 1 ? _c('div', {
      staticClass: "mt15 ml24"
    }, [_c('el-radio-group', {
      attrs: {
        "value": _vm.detail.registeredOfficeId
      }
    }, _vm._l(_vm.nativeRegisteredAddress, function (office) {
      return _c('el-radio', {
        key: office.id,
        attrs: {
          "label": office.id
        }
      }, [_vm._v(_vm._s(_vm.formatNativeAddress(office)))]);
    }), 1)], 1) : _vm._e(), item.value === 2 && _vm.detail.registeredOfficeType === 2 ? _c('div', {
      staticClass: "mt10 ml24"
    }, [_vm._v(" " + _vm._s(_vm.formatCustomAddress(_vm.detail)) + " ")]) : _vm._e()])], 1);
  }), 0)], 1)])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Working hours")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self no-event"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 16
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value": _vm.detail.workingHours
    }
  }, _vm._l(_vm.workingHoursOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1)])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Constitution")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self no-event"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 16
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value": _vm.detail.isCustomizedConstitution
    }
  }, _vm._l(_vm.customizedConstitutionOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1)])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Is the company's business special and needs to obtain approval(s) from specific government authorities?")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self no-event"
  }, [_c('el-radio-group', {
    model: {
      value: _vm.detail.hasObtainedApproval,
      callback: function ($$v) {
        _vm.$set(_vm.detail, "hasObtainedApproval", $$v);
      },
      expression: "detail.hasObtainedApproval"
    }
  }, _vm._l(_vm.checkboxOptions, function (item) {
    return _c('el-radio', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1), _vm.detail.hasObtainedApproval === 1 ? _c('el-checkbox-group', {
    attrs: {
      "value": _vm.detail.approvalAuthorities || []
    }
  }, _vm._l(_vm.authoritiesOptions, function (item) {
    return _c('el-checkbox', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1) : _vm._e()], 1)])]), _vm.isHasEPApplicant ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("EP Info")]), _c('div', {
    staticClass: "desp-item"
  }, [_vm.detail.epInfo ? _vm._l(_vm.detail.epInfo, function (detail, sy) {
    return _c('div', {
      key: `epInfo${sy}${detail.fullName}`,
      staticClass: "pt20 pb20 shareholder-wrap"
    }, [_c('div', {
      staticClass: "base-info"
    }, [_c('el-descriptions', {
      attrs: {
        "labelStyle": {
          color: '#667288',
          background: '#ECEFF9'
        },
        "contentStyle": {
          background: '#ECEFF9'
        },
        "column": 3,
        "direction": "vertical"
      }
    }, [_c('el-descriptions-item', {
      attrs: {
        "label": "Name (same as IC)"
      }
    }, [_vm._v(_vm._s(detail.fullName))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Nationality"
      }
    }, [_vm._v(_vm._s(detail.nationality))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Identification Type"
      }
    }, [_vm._v(" " + _vm._s(detail.identificationType) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Identification No."
      }
    }, [_vm._v(" " + _vm._s(detail.identificationNo) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Mobile"
      }
    }, [_vm._v(_vm._s(detail.phone))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Email"
      }
    }, [_vm._v(_vm._s(detail.email))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Residential address"
      }
    }, [_vm._v(" " + _vm._s(detail.residentialAddress) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Birth Date"
      }
    }, [_vm._v(" " + _vm._s(detail.birthDate) + " ")]), [_c('el-descriptions-item', {
      attrs: {
        "label": "Does the individual hold share interest of any entity in PRC inland?"
      }
    }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(detail.isHoldShareOfPrcInland)) + " ")]), detail.isHoldShareOfPrcInland ? _c('el-descriptions-item', {
      attrs: {
        "label": "Please provide the name of RPC entity (1 entity is required)"
      }
    }, [_vm._v(" " + _vm._s(detail.holdShareOfPrcEntity) + " ")]) : _vm._e()]], 2)], 1)]);
  }) : _vm._e()], 2)]) : _vm._e(), _vm.editVisible ? _c('edit-modal', {
    ref: "editModal",
    attrs: {
      "checkboxOptions": _vm.checkboxOptions,
      "workingHoursOptions": _vm.workingHoursOptions,
      "customizedConstitutionOptions": _vm.customizedConstitutionOptions,
      "authoritiesOptions": _vm.authoritiesOptions,
      "nativeRegisteredAddress": _vm.nativeRegisteredAddress,
      "registerOfficeTypeList": _vm.registerOfficeTypeList,
      "businessType": _vm.detail.businessType
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }