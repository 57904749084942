<template>
  <el-dialog
    :title="!dataForm.id ? 'Add Proposed Funds' : 'Edit Proposed Funds'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px"
    append-to-body
  >
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="176px">
      <el-form-item label="Proposed Fund Name" prop="fundName" ref="fundName">
        <el-input v-model="dataForm.fundName" placeholder="Please enter" class="full-width"></el-input>
      </el-form-item>
      <el-form-item label="Type" prop="fundType" ref="fundType">
        <el-select v-model="dataForm.fundType" placeholder="Please select" class="wd360">
          <el-option :label="item.title" :value="item.value" v-for="item of fundTypes" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Registration Country" prop="fundRegistrationCountry" ref="fundRegistrationCountry">
        <el-select v-model="dataForm.fundRegistrationCountry" placeholder="Please select" filterable class="wd360">
          <el-option :label="item.title" :value="item.value" v-for="item of countryList" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Registration address" prop="fundRegistration" ref="fundRegistration">
        <el-input v-model="dataForm.fundRegistration" placeholder="Please enter" class="full-width"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        fundName: null,
        fundType: null,
        fundRegistrationCountry: null,
        fundRegistration: null,
      },
      dataRule: {
        fundName: [{ required: true, message: "Proposed Fund Name is required", trigger: "blur" }],
        fundType: [{ required: true, message: "Type is required", trigger: "blur" }],
        fundRegistrationCountry: [{ required: true, message: "Registration Country is required", trigger: "blur" }],
        fundRegistration: [{ required: true, message: "Registration address is required", trigger: "blur" }],
      },
      idx: null,
      fundDataForm: {},
    };
  },
  mounted() {
    this.fundDataForm = JSON.parse(JSON.stringify(this.dataForm));
  },
  computed: {
    ...mapState("constant", ["fundTypes", "countryList"]),
  },
  methods: {
    async init(idx, data) {
      this.idx = idx;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      if (idx == "add") {
        this.dataForm = JSON.parse(JSON.stringify(this.fundDataForm));
      } else {
        const copy = JSON.parse(JSON.stringify(data));
        this.dataForm = copy;
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
          this.$emit("change", dataFormCopy, this.idx);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
