<template>
  <el-dialog width="960px" :title="!dataForm.id ? 'Add Bank Account' : 'Edit Bank Account'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="172px">
      <el-form-item label="Entity type" prop="type">
        <el-select style="width: 100%" v-model="dataForm.type" placeholder="Select type">
          <el-option v-for="item in entityTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Bank Name" prop="bankName">
        <el-input v-model="dataForm.bankName" placeholder="bank name" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Bank Address" prop="bankAddress">
        <el-input v-model="dataForm.bankAddress" placeholder="bank Address" :oninput="isEn"></el-input>
      </el-form-item>
      <el-row>
        <el-col :lg="20" :span="20">
          <el-form-item label="Swift Code" prop="swiftCode">
            <el-input v-model="dataForm.swiftCode" placeholder="swift code" :oninput="isEn"></el-input>
          </el-form-item>
          <el-form-item label="PayNow UEN" prop="payNowUen">
            <el-input v-model="dataForm.payNowUen" placeholder="PayNow UEN" :oninput="isEn"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="PayNow Qrcode" prop="payNowQrcodeUrl">
        <el-image-uploader v-model="dataForm.payNowQrcodeUrl"></el-image-uploader>
      </el-form-item>
      <el-row>
        <el-col :lg="20" :span="20">
          <el-form-item label="Account Number" prop="accountNumber">
            <el-input v-model="dataForm.accountNumber" placeholder="account number" :oninput="isEn"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Account Name" prop="accountName">
        <el-input v-model="dataForm.accountName" placeholder="account name" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Address of Beneficiary" prop="addressOfBeneficiary">
        <el-input v-model="dataForm.addressOfBeneficiary" placeholder="Address of Beneficiary" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Alipay Qrcode" prop="alipayQrcodeUrl">
        <el-image-uploader v-model="dataForm.alipayQrcodeUrl"></el-image-uploader>
      </el-form-item>
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">Disable</el-radio>
          <el-radio :label="1">Enable</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        type: "",
        bankName: "",
        bankCode: "",
        bankAddress: "",
        swiftCode: "",
        payNowUen: "",
        payNowQrcodeUrl: {
          name: "",
          url: "",
        },
        accountNumber: "",
        accountName: "",
        addressOfBeneficiary: "",
        status: 1,
        alipayQrcodeUrl: {
          name: "",
          url: "",
        },
      },
      dataRule: {
        type: [{ required: true, message: "Entity type is required", trigger: "blur" }],
        bankName: [{ required: true, message: "Bank Name is required", trigger: "blur" }],
        accountNumber: [{ required: true, message: "Account Number is required", trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["countryList", "entityTypes"]),
  },
  methods: {
    init(id) {
      this.submitControl = true;
      this.visible = true;
      this.$nextTick().then(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          this.getDetail(id);
        } else {
          this.dataForm.id = undefined;
          this.dataForm.content = "";
        }
      });
    },
    //详情
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/stBankAccountManage/${id}/detail`);
      this.dataForm = { ...data };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.$http.post(
            this.dataForm.id ? `/admin/stBankAccountManage/${this.dataForm.id}/update` : `/admin/stBankAccountManage/create`,
            this.dataForm,
          );
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
