var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": !_vm.dataForm.id ? '新增字典' : '编辑字典',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "100px"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.dataFormSubmit();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "类型",
      "prop": "type"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.type,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "type", $$v);
      },
      expression: "dataForm.type"
    }
  }, _vm._l(_vm.dataForm.typeList, function (type, index) {
    return _c('el-radio', {
      key: index,
      attrs: {
        "label": index
      }
    }, [_vm._v(_vm._s(type))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.dataForm.typeList[_vm.dataForm.type] + '名称',
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": _vm.dataForm.typeList[_vm.dataForm.type] + '名称'
    },
    model: {
      value: _vm.dataForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "name", $$v);
      },
      expression: "dataForm.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "上级目录",
      "prop": "parentName"
    }
  }, [_c('el-popover', {
    ref: "dictListPopover",
    attrs: {
      "placement": "bottom-start",
      "trigger": "click"
    },
    model: {
      value: _vm.popoverVisible,
      callback: function ($$v) {
        _vm.popoverVisible = $$v;
      },
      expression: "popoverVisible"
    }
  }, [_c('el-tree', {
    ref: "dictListTreeRef",
    attrs: {
      "data": _vm.dictList,
      "props": _vm.dictListTreeProps,
      "node-key": "id",
      "default-expand-all": true,
      "highlight-current": true,
      "expand-on-click-node": false
    },
    on: {
      "current-change": _vm.dictListTreeCurrentChangeHandle
    }
  })], 1), _c('el-input', {
    directives: [{
      name: "popover",
      rawName: "v-popover:dictListPopover",
      arg: "dictListPopover"
    }],
    staticClass: "dict-list__input",
    attrs: {
      "readonly": true,
      "placeholder": "点击选择上级字典"
    },
    model: {
      value: _vm.dataForm.parentName,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "parentName", $$v);
      },
      expression: "dataForm.parentName"
    }
  })], 1), _vm.dataForm.type === 1 ? [_c('el-form-item', {
    attrs: {
      "label": "字典码",
      "prop": "dictCode"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "字典码"
    },
    model: {
      value: _vm.dataForm.dictCode,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "dictCode", $$v);
      },
      expression: "dataForm.dictCode"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "字典值",
      "prop": "dictValue"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "字典值"
    },
    model: {
      value: _vm.dataForm.dictValue,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "dictValue", $$v);
      },
      expression: "dataForm.dictValue"
    }
  })], 1)] : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "排序号",
      "prop": "orderNum"
    }
  }, [_c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "min": 0,
      "label": "排序号"
    },
    model: {
      value: _vm.dataForm.orderNum,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "orderNum", $$v);
      },
      expression: "dataForm.orderNum"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "备注",
      "size": "mini",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "备注"
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1)], 2), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("确认")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }