<template>
  <el-dialog width="780px" :title="!engagementId ? 'Add Client' : 'Edit Client'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="220px">
      <el-form-item
        label="Client Code"
        prop="clientCode"
        ref="clientCode"
        :rules="{
          required: true,
          message: 'please select',
          trigger: 'submit',
        }"
        class="is-required"
      >
        <el-select
          v-model="dataForm.clientCode"
          placeholder="Client code"
          clearable
          filterable
          remote
          :remote-method="getClientCodeRemote"
          @clear="getClientCodeRemote('')"
          @focus="getClientCodeRemote('')"
          :loading="clientCodeLoading"
          @change="clientCodeChange"
          class="wd440"
        >
          <el-option v-for="(item, sy) in clientCodeList" :label="item.clientCode" :key="`${item.clientCode}${sy}`" :value="item.clientCode"></el-option>
        </el-select>
        <el-popover placement="bottom" trigger="hover">
          <span class="fs12">Data Source: "Client Code" section of this system</span>
          <span slot="reference" class="pearl-blue fs16 ml4 el-icon-warning"></span>
        </el-popover>
      </el-form-item>
      <el-form-item
        label="UEN"
        prop="uen"
        ref="uen"
        :rules="{
          required: true,
          message: 'please select',
          trigger: 'submit',
        }"
        class="is-required"
      >
        <el-select v-model="dataForm.uen" placeholder="UEN" filterable @change="uenChange" class="wd440">
          <el-option v-for="(item, xb) in atUenList" :label="`${item.uen}-${item.companyName}`" :key="`${item.uen}${xb}`" :value="item.uen"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Entity Name"
        prop="companyName"
        ref="companyName"
        :rules="{
          required: true,
          message: 'Please enter',
          trigger: 'submit',
        }"
      >
        <el-input v-model="dataForm.companyName" placeholder="Entity Name" clearable class="wd440"></el-input>
      </el-form-item>
      <el-form-item
        label="Entity Country"
        prop="companyCountry"
        ref="companyCountry"
        :rules="{
          required: true,
          message: 'please select',
          trigger: 'submit',
        }"
      >
        <el-select v-model="dataForm.companyCountry" placeholder="Select Entity Country" filterable class="wd440">
          <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Entity Type"
        prop="companyType"
        ref="companyType"
        :rules="{
          required: true,
          message: 'please select',
          trigger: 'submit',
        }"
      >
        <el-select v-model="dataForm.companyType" placeholder="Select Entity Type" filterable class="wd440">
          <el-option v-for="item in engagementCompanyTypeList" :key="item.title" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <div class="flex">
        <el-form-item
          label="Covered Period"
          prop="coveredPeriodStart"
          ref="coveredPeriodStart"
          :rules="{
            required: true,
            message: 'please select',
            trigger: 'submit',
          }"
        >
          <el-date-picker
            v-model="dataForm.coveredPeriodStart"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Start Date"
            class="wd200"
          ></el-date-picker>
        </el-form-item>
        <span class="lh36 pl15 pr15">~</span>
        <el-form-item
          label=""
          prop="coveredPeriodEnd"
          ref="coveredPeriodEnd"
          :rules="{
            required: true,
            message: 'please select',
            trigger: 'submit',
          }"
          class="labelNone"
        >
          <el-date-picker v-model="dataForm.coveredPeriodEnd" type="date" value-format="yyyy-MM-dd" placeholder="End Date" class="wd200"></el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="Incorp. date" prop="incorpDate" ref="incorpDate">
        <el-date-picker
          v-model="dataForm.incorpDate"
          type="date"
          placeholder="Incorp. date"
          :format="dataForm.incorpDate ? `dd [${monthList[parseInt(dataForm.incorpDate.split('-')[1]) - 1]}] yyyy` : 'dd MM yyyy'"
          value-format="yyyy-MM-dd"
          class="wd440"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="Previous Entity Name"
        prop="previousCompanyName"
        ref="previousCompanyName"
        :rules="{
          required: true,
          message: 'Please enter',
          trigger: 'submit',
        }"
      >
        <el-input v-model="dataForm.previousCompanyName" placeholder="Previous Entity Name" clearable class="wd440"></el-input>
      </el-form-item>
      <el-form-item label="Type" prop="clientType" ref="clientType">
        <el-input v-model="dataForm.clientType" placeholder="Type" clearable :oninput="isEn" class="wd440"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" v-if="isAuth('engagement:update')" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      engagementId: 0,
      dataForm: {},
      submitControl: true,
      atUenList: [],
      clientCodeLoading: false,
      clientCodeList: [],
      monthList: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    };
  },
  computed: {
    ...mapState("constant", ["countryList", "engagementCompanyTypeList"]),
  },
  methods: {
    // 获取client code列表
    async getClientCodeRemote(query) {
      this.clientCodeLoading = true;
      const { code, data } = await this.$http.get("/admin/clientCodeInfoMa/getList", {
        params: {
          keywords: query,
          status: 1,
        },
      });
      this.clientCodeLoading = false;
      if (code === 200) {
        this.clientCodeList = data;
      } else {
        this.clientCodeList = [];
      }
    },
    clientCodeChange() {
      this.atUenList = this.clientCodeList.find(ele => ele.clientCode == this.dataForm.clientCode)?.uens;
      this.$set(this.dataForm, "uen", "");
      this.$set(this.dataForm, "companyName", "");
    },
    uenChange() {
      const companyName = this.atUenList.find(ele => ele.uen == this.dataForm.uen)?.companyName;
      this.$set(this.dataForm, "companyName", companyName);
    },
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.getClientCodeRemote("");
      if (data) {
        // 修改
        this.engagementId = data.id;
        this.dataForm = {
          clientCode: data.clientCode,
          uen: data.uen,
          companyName: data.companyName,
          companyCountry: data.companyCountry,
          companyType: data.companyType,
          coveredPeriodStart: data.coveredPeriodStart,
          coveredPeriodEnd: data.coveredPeriodEnd,
          incorpDate: data.incorpDate,
          previousCompanyName: data.previousCompanyName,
          clientType: data.clientType,
        };
      } else {
        // 新建
        this.engagementId = 0;
        this.dataForm = {
          clientCode: "",
          uen: "",
          companyName: "",
          companyCountry: "",
          companyType: "",
          coveredPeriodStart: "",
          coveredPeriodEnd: "",
          incorpDate: "",
          previousCompanyName: "",
          clientType: "",
        };
      }
    },
    // 表单提交
    async formSubmitRequest() {
      if (this.engagementId) {
        return await this.$http.post(`/admin/engagementManage/${this.engagementId}/modify`, this.dataForm);
      } else {
        return await this.$http.post("/admin/engagementManage/create", this.dataForm);
      }
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.formSubmitRequest(this.dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.submitControl = true;
                this.$emit("refreshDataList");
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.blue {
  color: $--color-primary;
}

.red {
  color: $--color-danger;
}

.labelNone {
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
