var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1104px",
      "title": "Edit Service Type",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "tableBorder"
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-width": "0px"
    }
  }, [_c('div', {
    staticClass: "flex fs14 fwb customTh"
  }, [_c('p', {
    staticClass: "text-center wd180 mr20"
  }, [_vm._v("Service Type")]), _c('p', {
    staticClass: "text-center wd320 mr20"
  }, [_vm._v("Service Details")]), _c('p', {
    staticClass: "text-center wd220 mr20"
  }, [_vm._v("Related Transaction")]), _c('p', {
    staticClass: "text-center wd220"
  }, [_vm._v("Related Turnover")])]), _vm._l(_vm.dataForm.serviceList, function (item, index) {
    return _c('div', {
      key: `serviceList${index}`,
      staticClass: "flex customTbody"
    }, [_c('el-form-item', {
      staticClass: "fxRow wd180 mr20 mb20"
    }, [_vm._v(_vm._s(item.serviceType))]), _c('el-form-item', {
      staticClass: "fxRow wd320 mr20 mb20 enbr text-center"
    }, [_c('span', [_vm._v(_vm._s(item.serviceDetails.filter(function (item) {
      return item;
    }).join(", ")))])]), _c('el-form-item', {
      ref: `serviceList.${index}.relatedTransaction`,
      refInFor: true,
      staticClass: "fxRow wd220 mr20 mb20",
      attrs: {
        "prop": `serviceList.${index}.relatedTransaction`
      }
    }, [_c('el-input', {
      directives: [{
        name: "minusPoint2",
        rawName: "v-minusPoint2"
      }],
      attrs: {
        "clearable": "",
        "placeholder": "0.00"
      },
      model: {
        value: _vm.dataForm.serviceList[index].relatedTransaction,
        callback: function ($$v) {
          _vm.$set(_vm.dataForm.serviceList[index], "relatedTransaction", $$v);
        },
        expression: "dataForm.serviceList[index].relatedTransaction"
      }
    }, [_c('template', {
      slot: "prefix"
    }, [_vm._v("S$")])], 2)], 1), _c('el-form-item', {
      ref: `serviceList.${index}.relatedTurnover`,
      refInFor: true,
      staticClass: "fxRow wd220 mb20",
      attrs: {
        "prop": `serviceList.${index}.relatedTurnover`
      }
    }, [_c('el-input', {
      directives: [{
        name: "minusPoint2",
        rawName: "v-minusPoint2"
      }],
      attrs: {
        "clearable": "",
        "placeholder": "0.00"
      },
      model: {
        value: _vm.dataForm.serviceList[index].relatedTurnover,
        callback: function ($$v) {
          _vm.$set(_vm.dataForm.serviceList[index], "relatedTurnover", $$v);
        },
        expression: "dataForm.serviceList[index].relatedTurnover"
      }
    }, [_c('template', {
      slot: "prefix"
    }, [_vm._v("S$")])], 2)], 1)], 1);
  })], 2)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }