<template>
  <div class="page-container">
    <div class="mod-role">
      <el-form :inline="true" :model="dataForm" @submit.native.prevent="getDataList(1)">
        <div class="flex-row jcsb">
          <el-form-item>
            <el-button v-if="isAuth('sys:promotion:save')" type="primary" @click="addOrUpdateHandle()">Add Promotion</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table :data="dataList" v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="code" header-align="center" align="center" label="Promotion Code"></el-table-column>
        <el-table-column prop="enabled" header-align="center" align="center" label="Status">
          <template slot-scope="scope">
            <el-tag size="samll" type="danger" v-if="scope.row.enabled == 0">Disable</el-tag>
            <el-tag size="samll" v-if="scope.row.enabled == 1">Enable</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" header-align="center" align="center" label="Remark"></el-table-column>
        <el-table-column prop="createTime" header-align="center" align="center" label="Create Time"></el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="180" label="Action">
          <template slot-scope="scope">
            <el-button
              v-if="isAuth('sys:promotion:disable') && scope.row.enabled == 1"
              type="text"
              class="fwb"
              @click="enableOrDisableHandle(scope.row.id, 0)"
            >
              Disable
            </el-button>
            <el-button
              v-if="isAuth('sys:promotion:enable') && scope.row.enabled == 0"
              type="text"
              class="fwb"
              @click="enableOrDisableHandle(scope.row.id, 1)"
            >
              Enable
            </el-button>
            <el-button v-if="isAuth('sys:promotion:delete')" type="text" class="ml0 fwb" @click="deleteHandle(scope.row.id)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
  </div>
</template>

<script>
import AddOrUpdate from "./AddOrUpdate";
import paginationDelete from "@/mixins/paginationDelete.js";
export default {
  mixins: [paginationDelete],
  data() {
    return {
      dataForm: {
        name: "",
      },
      dataList: [],
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
      };
      const { code, data } = await this.$http.get("/admin/promotionManage/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    async deleteHandle(id) {
      await this.$confirm(`Delete this promotion code. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const { code, msg } = await this.$http.post(`/admin/promotionManage/${id}/delete`, {});
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => {
            // mixins
            this.singleDataDelete();
            this.getDataList();
          },
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 启用或关闭
    async enableOrDisableHandle(id, status) {
      const { code, msg } = await this.$http.post(`/admin/promotionManage/${id}/enable`, { enabled: status });
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => this.getDataList(),
        });
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>
