<template>
  <div class="plan-edit">
    <add-or-update />
  </div>
</template>

<script>
import AddOrUpdate from "../_partial";
export default {
  meta: {
    headName: "Edit Plan",
  },
  components: { AddOrUpdate },
};
</script>
