<template>
  <div class="page-container">
    <p class="fs18 fwb pt0 mt0 mb30">Personal Settings</p>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="102px">
      <el-form-item label="Nick Name" prop="nickname" class="wd550">
        <el-input v-model="dataForm.nickname" placeholder="Nick name" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Phone" prop="phone" class="wd550">
        <el-input v-model="dataForm.phone" placeholder="Phone">
          <el-select v-model="dataForm.prefix" slot="prepend" class="wd90">
            <el-option v-for="single in prefixOptions" :key="single.title" :value="single.value">
              {{ single.value + " " + single.title }}
            </el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item label="Email" class="wd550">
        <span class="ml10 mr12">{{ dataForm.email }}</span>
      </el-form-item>
      <el-form-item label="Password" class="wd550">
        <span class="ml10 mr12">********</span>
        <el-button type="text" icon="el-icon-thumb" @click="sendResetPwdEmail">Reset Password</el-button>

        <span class="ml20 green" v-if="sendEmailType == 1">
          <i class="el-icon-success"></i>
          Message sent successfully
        </span>
        <span class="ml20 red" v-if="sendEmailType == 0">
          <i class="el-icon-error"></i>
          Message sending failed
        </span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="dataFormSubmit">Submit</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value) {
        if (this.dataForm.prefix) {
          callback();
        } else {
          callback(new Error("Please select the prefix of the mobile phone number"));
        }
      } else {
        if (this.dataForm.prefix) {
          callback(new Error("Please enter your mobile phone number"));
        } else {
          callback();
        }
      }
    };
    return {
      dataForm: {
        nickname: "",
        prefix: "",
        phone: "",
        email: "",
      },
      dataRule: {
        nickname: [{ required: true, message: "Nick name is required", trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
      },
      sendEmailType: 2,
    };
  },
  computed: {
    ...mapState("constant", ["prefixOptions"]),
    nickName: {
      get() {
        return this.$store.state.user.name;
      },
      set(val) {
        this.$store.commit("user/updateName", val);
      },
    },
  },
  activated() {
    this.$refs.dataForm.resetFields();
    this.getDetail();
  },
  methods: {
    async getDetail(id) {
      const { data } = await this.$http.get("/admin/sysUser/profile");
      let phoneCopy = data.phone && data.phone.includes("-") ? data.phone.split("-") : ["", ""];
      this.dataForm = {
        nickname: data.nickname,
        prefix: phoneCopy[0],
        phone: phoneCopy[1],
        email: data.email,
      };
    },
    async dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          let phoneCopy = this.dataForm.prefix + "-" + this.dataForm.phone;
          const dataForm = {
            nickname: this.dataForm.nickname,
            phone: phoneCopy,
          };
          let { code, msg } = await this.$http.post("/admin/sysUser/updateProfile", dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1000,
              onClose: () => {
                let dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
                this.nickName = dataFormCopy.nickname;
              },
            });
          } else {
            this.$message.error(msg);
          }
        }
      });
    },
    // 发送重置密码邮件
    async sendResetPwdEmail() {
      await this.$confirm("Are you sure you want to send a password reset email?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      this.sendEmailType = 2;
      let { code, msg } = await this.$http.post("/resetSysPwdByEmail", { email: this.dataForm.email });
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1000,
          onClose: () => {
            this.sendEmailType = 1;
          },
        });
      } else {
        this.$message.error(msg);
        this.sendEmailType = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.green {
  color: $--color-success;
}

.red {
  color: $--color-danger;
}
</style>