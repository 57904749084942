<template>
  <div>
    <!-- 公司(基金管理公司)的直接股东/董事 -->
    <div class="shareholders-module">
      <div class="desp">
        <div class="desp-header">
          <div class="label">{{ headline }}</div>
          <div class="opts">
            <el-button
              v-if="
                (applymentStatus === 'REVISING' ||
                  applymentStatus === 'CORRECTED' ||
                  applymentStatus === 'ILLEGAL' ||
                  applymentStatus === 'AUDIT_FAIL' ||
                  applymentStatus === 'REFUND_APPLY_DENY' ||
                  applymentStatus === 'REFUND_APPLY_REJECT' ||
                  applymentStatus === 'ACRA_REJECTED') &&
                isApplymentAuth('company:coordinate')
              "
              @click="openEdit('company')"
            >
              Edit
            </el-button>
            <!-- 添加名义董事 -->
            <template v-if="(applymentStatus === 'CHECKING' || applymentStatus === 'REVISE_FINISH') && isApplymentAuth('company:check')">
              <template v-if="isNeedNomineeDirector">
                <el-button type="primary" plain @click="addNomineeDirectorHandle">
                  <svg-icon name="exchange" class="opt-icon"></svg-icon>
                  Add Nominee Director
                </el-button>
              </template>
            </template>
          </div>
        </div>
        <div class="desp-items-group">
          <div class="desp-item">
            <div class="desp-item-self">
              <div v-for="(detail, idx) in companyMemberListFormat" :key="detail.id" class="shareholder-wrap">
                <div class="member-type">
                  {{ memberTypeFormat(detail.memberType) }}
                  <span>{{ idx + 1 + "/" + companyMemberListFormat.length }}</span>
                </div>
                <div class="position-held">{{ positionHeldFormat(detail.positionHeld) }}</div>
                <div class="base-info mt20">
                  <el-descriptions
                    :labelStyle="{ color: '#667288', background: '#ECEFF9' }"
                    :contentStyle="{ background: '#ECEFF9' }"
                    :column="3"
                    direction="vertical"
                  >
                    <el-descriptions-item label="Name (same as business certificate)" v-if="detail.memberType === 'CORPORATE'">
                      {{ detail.fullName }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Name (same as IC)" v-if="detail.memberType === 'INDIVIDUAL'">{{ detail.fullName }}</el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Country">{{ detail.nationality }}</el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Nationality">{{ detail.nationality }}</el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Business No.">
                      {{ detail.businessLicenseNo }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Identification Type">
                      {{ detail.identificationType }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Identification No.">
                      {{ detail.identificationNo }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Mobile">{{ detail.phone }}</el-descriptions-item>
                    <el-descriptions-item label="Email">{{ detail.email }}</el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Registered Address">
                      {{ detail.registeredOfficeAddress }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Residential address">
                      {{ detail.residentialAddress }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Operation Address">
                      {{ detail.operationAddress }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Incorporation Date">
                      {{ detail.incorporationDate }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Birth Date">
                      {{ detail.birthDate }}
                    </el-descriptions-item>
                    <el-descriptions-item
                      v-if="
                        detail.memberType === 'CORPORATE' ||
                        (detail.memberType === 'INDIVIDUAL' &&
                          (detail.positionHeld === 'SHAREHOLDER' || detail.positionHeld === 'DIRECTOR_AND_SHAREHOLDER'))
                      "
                      label="Is the shareholder a Nominee Shareholder"
                    >
                      {{ getBooleanFormat(null, null, detail.isNominatedShareholder) }}
                    </el-descriptions-item>
                    <el-descriptions-item
                      v-if="
                        detail.memberType === 'INDIVIDUAL' && (detail.positionHeld === 'DIRECTOR' || detail.positionHeld === 'DIRECTOR_AND_SHAREHOLDER')
                      "
                      label="Is the director a Nominee Director"
                    >
                      {{ getBooleanFormat(null, null, detail.isNominatedDirector) }}
                    </el-descriptions-item>
                    <el-descriptions-item
                      v-if="
                        businessType === 'SERVICE_TRANSFER' &&
                        hasNomineeDirector &&
                        detail.memberType === 'INDIVIDUAL' &&
                        (detail.positionHeld === 'DIRECTOR' || detail.positionHeld === 'DIRECTOR_AND_SHAREHOLDER')
                      "
                      label="Will the director tender resignation"
                    >
                      {{ getBooleanFormat(null, null, detail.directorNeedResigned) }}
                    </el-descriptions-item>

                    <template v-if="detail.memberType === 'INDIVIDUAL' && detail.positionHeld != 'NOMINEE_DIRECTOR'">
                      <el-descriptions-item label="Does the individual hold share interest of any entity in PRC inland?">
                        {{ getBooleanFormat(null, null, detail.isHoldShareOfPrcInland) }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Please provide the name of RPC entity (1 entity is required)" v-if="detail.isHoldShareOfPrcInland">
                        {{ detail.holdShareOfPrcEntity }}
                      </el-descriptions-item>
                    </template>
                  </el-descriptions>
                </div>
                <!-- 公司类型下的股东 -->
                <template v-if="detail.memberType === 'CORPORATE' && detail.positionHeld === 'SHAREHOLDER'">
                  <!-- representative (主体代表) -->
                  <div class="mt25 member-type" v-if="detail.representativeInfo">
                    <div class="position-held">Representative</div>
                    <div class="base-info mt20">
                      <el-descriptions
                        :labelStyle="{ color: '#667288', background: '#ECEFF9' }"
                        :contentStyle="{ background: '#ECEFF9' }"
                        :column="3"
                        direction="vertical"
                      >
                        <el-descriptions-item label="Name (same as IC)">
                          {{ detail.representativeInfo.fullName }}
                        </el-descriptions-item>
                        <el-descriptions-item label="Nationality">{{ detail.representativeInfo.nationality }}</el-descriptions-item>
                        <el-descriptions-item label="Identification Type">
                          {{ detail.representativeInfo.identificationType }}
                        </el-descriptions-item>
                        <el-descriptions-item label="Identification No.">
                          {{ detail.representativeInfo.identificationNo }}
                        </el-descriptions-item>
                        <el-descriptions-item label="Mobile">{{ detail.representativeInfo.phone }}</el-descriptions-item>
                        <el-descriptions-item label="Email">{{ detail.representativeInfo.email }}</el-descriptions-item>
                        <el-descriptions-item label="Residential address">
                          {{ detail.representativeInfo.residentialAddress }}
                        </el-descriptions-item>
                        <el-descriptions-item label="Birth Date">
                          {{ detail.representativeInfo.birthDate }}
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                  </div>
                </template>
                <div class="shares-info mt20" v-if="detail.sharesInfo && detail.sharesInfo.length > 0">
                  <el-table :header-cell-style="{ color: '#011A45' }" border :data="detail.sharesInfo" style="width: 100%">
                    <el-table-column
                      align="center"
                      prop="typeOfShares"
                      label="Type Of Shares"
                      width="180"
                      :formatter="typeOfSharesFormat"
                    ></el-table-column>
                    <el-table-column align="center" prop="quantityOfSharesAllotted" label="No of shares allotted"></el-table-column>
                    <el-table-column align="center" prop="amountOfPaidUpShareCapital" label="Amount Of Registered Share Capital"></el-table-column>
                    <el-table-column align="center" prop="amountOfPaidInShareCapital" label="Paid-in capital"></el-table-column>
                    <el-table-column align="center" prop="sharesHoldInTrust" label="Shares held in trust" :formatter="getBooleanFormat"></el-table-column>
                    <el-table-column align="center" prop="nameOfTheTrust" label="Name of the trust"></el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 弹窗 -->
      <edit-modal
        :stepName="stepName"
        :applymentStatus="applymentStatus"
        :businessType="businessType"
        :hasNomineeDirector="hasNomineeDirector"
        :isNeedNomineeDirector="isNeedNomineeDirector"
        v-if="editVisible"
        ref="editModal"
      />
    </div>

    <!-- 基金的直接股东/董事 -->
    <div class="mt60 shareholders-module" v-if="businessType == 'FUND_ADMIN_CUSTOMIZED'">
      <div class="desp">
        <div class="desp-header">
          <div class="label">Information of the proposed fund's officer(s)</div>
          <div class="opts">
            <el-button
              v-if="
                (applymentStatus === 'REVISING' ||
                  applymentStatus === 'CORRECTED' ||
                  applymentStatus === 'ILLEGAL' ||
                  applymentStatus === 'AUDIT_FAIL' ||
                  applymentStatus === 'REFUND_APPLY_DENY' ||
                  applymentStatus === 'REFUND_APPLY_REJECT' ||
                  applymentStatus === 'ACRA_REJECTED') &&
                isApplymentAuth('company:coordinate')
              "
              @click="openEdit('fund')"
            >
              Edit
            </el-button>
          </div>
        </div>
        <div class="desp-items-group">
          <div class="desp-item">
            <div class="desp-item-self">
              <div v-for="(fundItem, sy) in companyFundMemberList" :key="`fundItem${fundItem.id}`" class="shareholder-wrap">
                <div class="member-type">
                  {{ memberTypeFormat(fundItem.memberType) }}
                  <span>{{ sy + 1 + "/" + companyFundMemberList.length }}</span>
                </div>
                <div class="position-held">{{ positionHeldFormat(fundItem.positionHeld) }}</div>
                <div class="base-info mt20">
                  <el-descriptions
                    :labelStyle="{ color: '#667288', background: '#ECEFF9' }"
                    :contentStyle="{ background: '#ECEFF9' }"
                    :column="3"
                    direction="vertical"
                  >
                    <el-descriptions-item label="Name (same as business certificate)" v-if="fundItem.memberType === 'CORPORATE'">
                      {{ fundItem.fullName }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Name (same as IC)" v-if="fundItem.memberType === 'INDIVIDUAL'">
                      {{ fundItem.fullName }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'CORPORATE'" label="Country">{{ fundItem.nationality }}</el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'INDIVIDUAL'" label="Nationality">{{ fundItem.nationality }}</el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'CORPORATE'" label="Business No.">
                      {{ fundItem.businessLicenseNo }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'INDIVIDUAL'" label="Identification Type">
                      {{ fundItem.identificationType }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'INDIVIDUAL'" label="Identification No.">
                      {{ fundItem.identificationNo }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Mobile">{{ fundItem.phone }}</el-descriptions-item>
                    <el-descriptions-item label="Email">{{ fundItem.email }}</el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'CORPORATE'" label="Registered Address">
                      {{ fundItem.registeredOfficeAddress }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'INDIVIDUAL'" label="Residential address">
                      {{ fundItem.residentialAddress }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'CORPORATE'" label="Operation Address">
                      {{ fundItem.operationAddress }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'CORPORATE'" label="Incorporation Date">
                      {{ fundItem.incorporationDate }}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="fundItem.memberType === 'INDIVIDUAL'" label="Birth Date">
                      {{ fundItem.birthDate }}
                    </el-descriptions-item>
                    <el-descriptions-item
                      v-if="
                        fundItem.memberType === 'CORPORATE' ||
                        (fundItem.memberType === 'INDIVIDUAL' &&
                          (fundItem.positionHeld === 'SHAREHOLDER' || fundItem.positionHeld === 'DIRECTOR_AND_SHAREHOLDER'))
                      "
                      label="Is the shareholder a Nominee Shareholder"
                    >
                      {{ getBooleanFormat(null, null, fundItem.isNominatedShareholder) }}
                    </el-descriptions-item>
                    <el-descriptions-item
                      v-if="
                        fundItem.memberType === 'INDIVIDUAL' &&
                        (fundItem.positionHeld === 'DIRECTOR' || fundItem.positionHeld === 'DIRECTOR_AND_SHAREHOLDER')
                      "
                      label="Is the director a Nominee Director"
                    >
                      {{ getBooleanFormat(null, null, fundItem.isNominatedDirector) }}
                    </el-descriptions-item>
                    <template v-if="fundItem.memberType === 'INDIVIDUAL' && fundItem.positionHeld != 'NOMINEE_DIRECTOR'">
                      <el-descriptions-item label="Does the individual hold share interest of any entity in PRC inland?">
                        {{ getBooleanFormat(null, null, fundItem.isHoldShareOfPrcInland) }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Please provide the name of RPC entity (1 entity is required)" v-if="fundItem.isHoldShareOfPrcInland">
                        {{ fundItem.holdShareOfPrcEntity }}
                      </el-descriptions-item>
                    </template>
                    <el-descriptions-item label="Related fund(s)">
                      <span v-for="(fund, fu) in fundItem.fundsInfo" :key="`fund${fu}`">
                        {{ fu != 0 ? "、" : "" }}
                        {{ fund.fundName }}
                      </span>
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
                <!-- 公司类型下的股东 -->
                <template v-if="fundItem.memberType === 'CORPORATE' && fundItem.positionHeld === 'SHAREHOLDER'">
                  <!-- representative (主体代表) -->
                  <div class="mt25 member-type" v-if="fundItem.representativeInfo">
                    <div class="position-held">Representative</div>
                    <div class="base-info mt20">
                      <el-descriptions
                        :labelStyle="{ color: '#667288', background: '#ECEFF9' }"
                        :contentStyle="{ background: '#ECEFF9' }"
                        :column="3"
                        direction="vertical"
                      >
                        <el-descriptions-item label="Name (same as IC)">
                          {{ fundItem.representativeInfo.fullName }}
                        </el-descriptions-item>
                        <el-descriptions-item label="Nationality">{{ fundItem.representativeInfo.nationality }}</el-descriptions-item>
                        <el-descriptions-item label="Identification Type">
                          {{ fundItem.representativeInfo.identificationType }}
                        </el-descriptions-item>
                        <el-descriptions-item label="Identification No.">
                          {{ fundItem.representativeInfo.identificationNo }}
                        </el-descriptions-item>
                        <el-descriptions-item label="Mobile">{{ fundItem.representativeInfo.phone }}</el-descriptions-item>
                        <el-descriptions-item label="Email">{{ fundItem.representativeInfo.email }}</el-descriptions-item>
                        <el-descriptions-item label="Residential address">
                          {{ fundItem.representativeInfo.residentialAddress }}
                        </el-descriptions-item>
                        <el-descriptions-item label="Birth Date">
                          {{ fundItem.representativeInfo.birthDate }}
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                  </div>
                </template>
                <div class="shares-info mt20" v-if="fundItem.sharesInfo && fundItem.sharesInfo.length > 0">
                  <el-table :header-cell-style="{ color: '#011A45' }" border :data="fundItem.sharesInfo" style="width: 100%">
                    <el-table-column align="center" prop="fundsName" label="Related fund(s)" width="180"></el-table-column>
                    <el-table-column
                      align="center"
                      prop="typeOfShares"
                      label="Type Of Shares"
                      width="180"
                      :formatter="typeOfSharesFormat"
                    ></el-table-column>
                    <el-table-column align="center" prop="quantityOfSharesAllotted" label="No of shares allotted"></el-table-column>
                    <el-table-column align="center" prop="amountOfPaidUpShareCapital" label="Amount Of Registered Share Capital"></el-table-column>
                    <el-table-column align="center" prop="amountOfPaidInShareCapital" label="Paid-in capital"></el-table-column>
                    <el-table-column align="center" prop="sharesHoldInTrust" label="Shares held in trust" :formatter="getBooleanFormat"></el-table-column>
                    <el-table-column align="center" prop="nameOfTheTrust" label="Name of the trust"></el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 弹窗 -->
      <update-modal
        :stepName="stepName"
        :applymentId="applymentId"
        :applymentStatus="applymentStatus"
        :businessType="businessType"
        :fundInfoList="fundInfoList"
        v-if="updateVisible"
        ref="updateModal"
      />
    </div>
  </div>
</template>

<script>
import EditModal from "./modal/Edit";
import UpdateModal from "./modal/Update";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    EditModal,
    UpdateModal,
  },
  props: {
    stepName: {
      type: String,
      default: function () {
        return "companyName";
      },
    },
    applymentId: {
      type: String,
    },
    applymentStatus: {
      type: String,
    },
    companyMemberList: {
      type: Array,
      default: function () {
        return [
          {
            id: undefined,
            memberType: "CORPORATE",
            positionHeld: "SHAREHOLDER",
            fullName: "lisi",
            nationality: "",
            identificationType: "",
            identificationNo: "",
            businessLicenseNo: "",
            email: "",
            phone: "",
            registeredOfficeAddress: "",
            operationAddress: "",
            residentialAddress: "",
            sharesInfo: [
              {
                typeOfShares: "Ordinary",
                quantityOfSharesAllotted: 100,
                amountOfPaidUpShareCapital: "$1000",
                sharesHoldInTrust: 1,
                nameOfTheTrust: "XinTuo MingCheng",
              },
            ],
          },
        ];
      },
    },
    hasNomineeDirector: {
      type: Number,
      default: 0,
    },
    businessType: {
      type: String,
    },
    headline: {
      type: String,
      default: "Officer Details - Shareholder(s)/Director(s)",
    },
    appointNomineeDirector: {
      type: Object,
      default: function () {
        return {
          type: null,
          name: null,
        };
      },
    },
    companyFundMemberList: {
      type: Array,
    },
    fundInfoList: {
      type: Array,
    },
    isNeedNomineeDirector: {
      type: Boolean,
    },
  },
  data() {
    return {
      dataListLoading: false,
      editVisible: false,
      updateVisible: false,
    };
  },
  computed: {
    ...mapState("constant", ["companyMemberTypes", "positionHelds", "typeOfShares"]),
    companyMemberListFormat() {
      const companyMemberListCopy = JSON.parse(JSON.stringify(this.companyMemberList));
      // return companyMemberListCopy.filter(
      //   item =>
      //     this.hasNomineeDirector ||
      //     (!this.hasNomineeDirector && item.positionHeld != "NOMINEE_DIRECTOR") ||
      //     (this.businessType == "COMPREHENSIVE_CUSTOMIZED" && this.appointNomineeDirector.type),
      // );
      // 需要名义董事，展示全部数据；不需要名义董事，就过滤掉名义董事
      return companyMemberListCopy.filter(item => this.isNeedNomineeDirector || (!this.isNeedNomineeDirector && item.positionHeld != "NOMINEE_DIRECTOR"));
    },
  },
  methods: {
    ...mapActions("applyment", ["initApplyment"]),
    memberTypeFormat(v) {
      const one = this.companyMemberTypes.find(i => i.value === v);
      return one?.title;
    },
    positionHeldFormat(v) {
      const one = this.positionHelds.find(i => i.value === v);
      return one?.title;
    },
    typeOfSharesFormat(r, c, v) {
      const one = this.typeOfShares.find(i => i.value === v);
      return one?.title;
    },
    getBooleanFormat(r, c, v) {
      if (v == 1) {
        return "Yes";
      } else if (v == 0) {
        return "No";
      } else {
        return null;
      }
    },
    async openEdit(type) {
      if (type == "company") {
        this.editVisible = true;
        await this.$nextTick();
        this.$refs.editModal.init(this.applymentId, this.companyMemberListFormat);
      } else {
        this.updateVisible = true;
        await this.$nextTick();
        this.$refs.updateModal.init(this.companyFundMemberList, this.fundInfoList);
      }
    },
    async addNomineeDirectorHandle() {
      // 判断是否勾选(我们同意聘用由星中商务推荐的名义董事1名，我们也理解该名义董事的行为造成的任何法律影响均与EStar无关。)
      this.editVisible = true;
      await this.$nextTick();
      this.$refs.editModal.init(this.applymentId, this.companyMemberListFormat);
    },
  },
};
</script>

<style lang="scss" scoped>
.shareholder-wrap {
  & + .shareholder-wrap {
    margin-top: 10px;
  }
  .member-type {
    color: #011a45;
    font-size: 16px;
    font-weight: bold;
    padding: 3px 0;
    margin-bottom: 5px;
  }

  .position-held {
    font-size: 16px;
    color: #0060ff;
    font-weight: bolder;
    padding: 3px 15px;
    background: #eceff9;
    margin-bottom: 15px;
    border-radius: 4px;
  }
}

.base-info {
  padding: 20px 30px;
  background-color: #eceff9;
  border-radius: 4px;
}

.opt-icon {
  vertical-align: middle;
  margin-top: -3px;
  font-size: 18px;
}

.agreement {
  line-height: 23px !important;
}
</style>
