<template>
  <div class="page-container">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="200px">
      <p class="fs18 fwb pt0 mt0 mb30">Custom Page</p>
      <el-form-item label="Beginner Guide Link" prop="beginnerGuideLink" class="wd550">
        <el-input v-model="dataForm.beginnerGuideLink" placeholder="Beginner Guide Link"></el-input>
      </el-form-item>
      <el-form-item label="Beginner Guide Link(CN)" prop="beginnerGuideLinkCn" class="wd550">
        <el-input v-model="dataForm.beginnerGuideLinkCn" placeholder="Beginner Guide Link(CN)"></el-input>
      </el-form-item>
      <el-form-item label="Wechat Qr Code" prop="kfWechatQrcodeUrl" class="wd550">
        <el-image-uploader v-model="dataForm.kfWechatQrcodeUrl"></el-image-uploader>
      </el-form-item>
      <el-form-item label="WhatsApp Qr Code" prop="kfWhatsappQrcodeUrl" class="wd550">
        <el-image-uploader v-model="dataForm.kfWhatsappQrcodeUrl"></el-image-uploader>
      </el-form-item>
      <el-form-item label="Guide Banners" prop="guideBanners">
        <span class="lightGray">recommend: 750*532(w*h), png(format)</span>
        <el-multiple-file-uploader v-model="dataForm.guideBanners" accept="image/*"></el-multiple-file-uploader>
      </el-form-item>
      <el-form-item label="Guide Banners(CN)" prop="guideBannersCn">
        <span class="lightGray">recommend: 750*532(w*h), png(format)</span>
        <el-multiple-file-uploader v-model="dataForm.guideBannersCn" accept="image/*"></el-multiple-file-uploader>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="dataFormSubmit">Submit</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dataForm: {
        beginnerGuideLink: "",
        beginnerGuideLinkCn: "",
        kfWechatQrcodeUrl: {},
        kfWhatsappQrcodeUrl: {},
        guideBanners: [],
        guideBannersCn: [],
      },
      dataRule: {
        beginnerGuideLink: [{ required: true, message: "Please Enter", trigger: "submit" }],
        beginnerGuideLinkCn: [{ required: true, message: "Please Enter", trigger: "submit" }],
        kfWechatQrcodeUrl: [{ required: true, message: "Please upload", trigger: "submit" }],
        kfWhatsappQrcodeUrl: [{ required: true, message: "Please upload", trigger: "submit" }],
        guideBanners: [{ type: "array", required: true, message: "Please upload", trigger: "submit" }],
        guideBannersCn: [{ type: "array", required: true, message: "Please upload", trigger: "submit" }],
      },
      dataFormLoading: false,
      submitControl: true,
    };
  },
  computed: {},
  activated() {
    this.getdataForm();
  },
  methods: {
    // 获取数据列表
    async getdataForm() {
      this.dataFormLoading = true;
      const { data } = await this.$http.get("/admin/stCustomPageManage/detail");
      this.dataForm = {
        beginnerGuideLink: (data && data.beginnerGuideLink) || "",
        beginnerGuideLinkCn: (data && data.beginnerGuideLinkCn) || "",
        kfWechatQrcodeUrl: (data && data.kfWechatQrcodeUrl) || null,
        kfWhatsappQrcodeUrl: (data && data.kfWhatsappQrcodeUrl) || null,
        guideBanners: (data && data.guideBanners) || [],
        guideBannersCn: (data && data.guideBannersCn) || [],
      };
      this.dataFormLoading = false;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.$http.post("/admin/stCustomPageManage/update", this.dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lightGray {
  color: #7987a0;
}
.protocols-wrapper {
  .relative {
    border: none;
    background: #f9fafd;
    .module-item {
      padding: 18px 20px 18px;
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-wrap: nowrap;
      border: 2px dashed #e9ecf1;
      border-bottom: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .module-desc {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
        font-size: 16px;

        .item-label {
          display: inline-block;
          width: 60px;
          color: #7987a0;
          font-size: 14px;
          margin-right: 10px;
        }
        .item-wrap {
          line-height: 38px;
          margin: 0px;
        }
        .item-content {
          font-size: 14px;
          font-weight: bold;
          color: #011a45;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          display: inline-block;
          width: calc(100% - 70px);
          vertical-align: middle;
        }
        .date {
          margin: 24px 0 10px;
          font-size: 14px;
        }
      }
    }
    .el-card-actions {
      border: 2px dashed #e9ecf1;
      border-top: 2px solid #e9ecf1;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: transparent;
    }

    .el-card-actions li:not(:last-child) {
      border-right: 2px solid #e9ecf1;
    }
  }

  .relative:hover {
    background: #e8f0ff;
    .module-item {
      border-color: #e8f0ff;
    }
    .el-card-actions {
      border-color: #e8f0ff;
      border-top: 2px solid #d8e3ff;
    }
    .el-card-actions li:not(:last-child) {
      border-right: 2px solid #d8e3ff;
    }
  }
}
</style>
