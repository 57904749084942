var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dateWrap"
  }, [_c('el-date-picker', {
    style: {
      width: `${_vm.width}px`
    },
    attrs: {
      "type": "date",
      "value-format": "yyyy-MM-dd",
      "placeholder": _vm.startPlaceholder
    },
    on: {
      "change": _vm.startChange
    },
    model: {
      value: _vm.start,
      callback: function ($$v) {
        _vm.start = $$v;
      },
      expression: "start"
    }
  }), _c('span', [_vm._v("~")]), _c('el-date-picker', {
    style: {
      width: `${_vm.width}px`
    },
    attrs: {
      "type": "date",
      "value-format": "yyyy-MM-dd",
      "placeholder": _vm.endPlaceholder
    },
    on: {
      "change": _vm.endChange
    },
    model: {
      value: _vm.end,
      callback: function ($$v) {
        _vm.end = $$v;
      },
      expression: "end"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }