<template>
  <div>
    <el-dialog title="Edit Engagement Control" :close-on-click-modal="false" :visible.sync="visible" width="1600px" append-to-body>
      <div class="mb30">
        <el-row :gutter="20">
          <el-col :span="5">
            <span class="fwb mr5">Client Code:</span>
            {{ engagementDetail.clientCode }}
          </el-col>
          <el-col :span="5">
            <span class="fwb mr5">UEN:</span>
            {{ engagementDetail.uen }}
          </el-col>
          <el-col :span="8">
            <span class="fwb mr5">Entity Name:</span>
            {{ engagementDetail.companyName }}
          </el-col>
          <el-col :span="6">
            <span class="fwb mr5">Covered Period:</span>
            <template>
              <span>{{ engagementDetail.coveredPeriodStart }}</span>
              <span class="pl5 pr5" v-if="engagementDetail.coveredPeriodEnd">~</span>
              <span>{{ engagementDetail.coveredPeriodEnd }}</span>
            </template>
          </el-col>
        </el-row>
      </div>
      <el-form :model="dataForm" ref="dataForm" label-width="0">
        <el-row :gutter="20">
          <el-col :span="8" class="flex">
            <span class="fwb mr10 lh36 required">FY Period:</span>
            <el-form-item
              prop="fyPeriod"
              ref="fyPeriod"
              :rules="{
                required: true,
                message: 'Please enter',
                trigger: 'submit',
              }"
            >
              <el-input v-model="dataForm.fyPeriod" :oninput="isEn" placeholder="12 month/52 weeks" clearable class="wd300"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="flex">
            <span class="fwb mr10 lh36 required">FY end:</span>
            <el-form-item
              prop="fyEnd"
              ref="fyEnd"
              :rules="{
                required: true,
                message: 'Please select',
                trigger: 'submit',
              }"
            >
              <el-date-picker
                v-model="dataForm.fyEnd"
                type="date"
                placeholder="FY end"
                :format="dataForm.fyEnd ? `dd [${monthList[parseInt(dataForm.fyEnd.split('-')[0]) - 1]}]` : 'dd MM'"
                value-format="MM-dd"
                class="wd300"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="fluid-width quotationWrap">
          <div class="mb20 shadeWrap">
            <!-- 表格 -->
            <div class="billingTitle">
              <el-row :gutter="5" class="pt20 pb20 fxRow borderTop">
                <el-col :span="1"></el-col>
                <el-col :span="2">
                  <p class="fs12 fwb text-center">Types</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 fwb text-center">Type Details</p>
                </el-col>
                <el-col :span="2">
                  <p class="fs12 fwb text-center">Linked Transaction</p>
                </el-col>
                <el-col :span="4">
                  <p class="fs12 fwb text-center">Covered Period</p>
                </el-col>
                <el-col :span="2">
                  <p class="fs12 fwb wbbw text-center">Deadline</p>
                </el-col>
                <el-col :span="2">
                  <p class="fs12 fwb text-center">Status</p>
                </el-col>
                <el-col :span="2">
                  <p class="fs12 fwb text-center">Linked Service Type</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 fwb text-center">Closure Checklist</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 fwb text-center">Remark</p>
                </el-col>
              </el-row>
            </div>

            <div class="billingContent" v-for="(child, idx) in dataForm.controlDataList" :key="`child${idx}`">
              <el-row :gutter="5" class="pt20 pb20 fxRow borderTop">
                <el-col :span="1" class="text-center">
                  <span
                    class="fs18 cursor-pointer blue el-icon-circle-plus-outline"
                    @click="
                      dataForm.controlDataList.splice(idx + 1, 0, {
                        controlType: child.controlType,
                        controlDesp: '',
                        coveredStart: '',
                        coveredEnd: '',
                        deadline: '',
                        status: '',
                        linkedServiceType: '',
                        closureChecklist: [],
                        remark: '',
                        isInternal: 0,
                      })
                    "
                  ></span>
                  <span
                    class="fs18 ml8 cursor-pointer red el-icon-remove-outline"
                    @click="dataForm.controlDataList.splice(idx, 1)"
                    v-if="child.isInternal == 0"
                  ></span>
                </el-col>
                <el-col :span="2">
                  <p class="fs12 text-center">{{ child.controlType }}</p>
                </el-col>
                <el-col :span="3">
                  <el-form-item
                    :prop="`controlDataList.${idx}.controlDesp`"
                    :ref="`controlDataList.${idx}.controlDesp`"
                    :rules="{
                      required: child.status != 'NONE',
                      message: 'please enter',
                      trigger: 'submit',
                    }"
                  >
                    <el-input v-model="child.controlDesp" placeholder="please enter" class="fluid-width"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item
                    :prop="`controlDataList.${idx}.linkedTransactionNo`"
                    :ref="`controlDataList.${idx}.linkedTransactionNo`"
                    :rules="{
                      required: child.status != 'NONE',
                      message: 'please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-select v-model="child.linkedTransactionNo" multiple filterable class="fluid-width">
                      <el-option label="Pending" value="Pending"></el-option>
                      <el-option
                        v-for="chunk in relatedUserTransactions"
                        :key="`controlDataList${idx}${chunk.transactionNo}`"
                        :label="chunk.transactionNo"
                        :value="chunk.transactionNo"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4" class="flex align-center justify-center">
                  <el-form-item
                    :prop="`controlDataList.${idx}.coveredStart`"
                    :ref="`controlDataList.${idx}.coveredStart`"
                    :rules="{
                      required: child.status != 'NONE',
                      message: 'please select',
                      trigger: 'submit',
                    }"
                    class="wd110"
                  >
                    <el-date-picker
                      v-model="child.coveredStart"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="Start Date"
                      class="wd110"
                    ></el-date-picker>
                  </el-form-item>
                  <span class="ml2 mr2">~</span>
                  <el-form-item
                    :prop="`controlDataList.${idx}.coveredEnd`"
                    :ref="`controlDataList.${idx}.coveredEnd`"
                    :rules="{
                      required: child.status != 'NONE',
                      message: 'please select',
                      trigger: 'submit',
                    }"
                    class="wd110"
                  >
                    <el-date-picker v-model="child.coveredEnd" type="date" value-format="yyyy-MM-dd" placeholder="End Date" class="wd110"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item
                    :prop="`controlDataList.${idx}.deadline`"
                    :ref="`controlDataList.${idx}.deadline`"
                    :rules="{
                      required: child.status != 'NONE',
                      message: 'please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-date-picker
                      v-model="child.deadline"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="Deadline"
                      class="fluid-width"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item
                    :prop="`controlDataList.${idx}.status`"
                    :ref="`controlDataList.${idx}.status`"
                    :rules="{
                      required: child.status != 'NONE',
                      message: 'please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-select v-model="child.status" @change="state => statusChange(state, idx)" :clearable="true" class="fluid-width">
                      <el-option v-for="alone in engagementControlStatusList" :key="alone.title" :label="alone.title" :value="alone.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item
                    :prop="`controlDataList.${idx}.linkedServiceType`"
                    :ref="`controlDataList.${idx}.linkedServiceType`"
                    :rules="{
                      required: child.status != 'NONE',
                      message: 'please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-select v-model="child.linkedServiceType" multiple filterable class="fluid-width">
                      <el-option
                        v-for="single in serviceTypeList"
                        :key="`controlDataList${idx}${single.value}`"
                        :label="single.title"
                        :value="single.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 wbbw text-center" v-for="(uniterm, xv) in child.closureChecklist" :key="`controlDataList${idx}${xv}`">
                    {{ uniterm.title }}
                    <span>{{ completedCheckFormat(uniterm.value) }}</span>
                  </p>
                </el-col>
                <el-col :span="3">
                  <!-- <p class="fs12 wbbw text-center">{{ child.remark }}</p> -->
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    resize="none"
                    v-model="child.remark"
                    placeholder="please enter"
                    class="fluid-width"
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">Cancel</el-button>
        <el-button type="primary" @click="dataFormSubmit">OK</el-button>
      </span>
    </el-dialog>
    <completed-check ref="completedCheckModal" @completedCheckFeedback="completedCheckFeedback"></completed-check>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validateLocation } from "@/utils";
import CompletedCheck from "./CompletedCheck.vue";
export default {
  props: {
    engagementDetail: {
      type: Object,
      default: function () {
        return {
          clientCode: null,
          uen: null,
          companyName: null,
          relatedUserTransactions: [],
        };
      },
    },
  },
  components: {
    CompletedCheck,
  },
  data() {
    return {
      visible: false,
      monthList: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      dataForm: {
        fyPeriod: "",
        fyEnd: "",
        controlDataList: [],
      },
      submitSwitch: true,
      linkedTransactionNoList: [],
    };
  },
  computed: {
    ...mapState("constant", ["serviceTypeList", "engagementControlStatusList", "completedCheckOptions"]),
    relatedUserTransactions() {
      return this.engagementDetail.relatedUserTransactions.filter(item => item.transactionNo);
    },
  },
  methods: {
    completedCheckFormat(v) {
      const one = this.completedCheckOptions.find(i => i.value === v);
      return one?.title;
    },
    async init(obj) {
      this.visible = true;
      this.submitSwitch = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();

      this.$set(this.dataForm, "fyPeriod", obj.fyPeriod);
      this.$set(this.dataForm, "fyEnd", obj.fyEnd);
      if (obj.controlDataList && obj.controlDataList.length > 0) {
        this.$set(this.dataForm, "controlDataList", obj.controlDataList);
      } else {
        const arr = [
          {
            controlType: "AGM/EGM",
            controlDesp: "AGM",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "Report Filing",
            controlDesp: "Annual Return",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "Report Filing",
            controlDesp: "XBRL",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "CIT filing",
            controlDesp: "ECI filing",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "CIT filing",
            controlDesp: "C-S|C filing",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "GST filing",
            controlDesp: "GST filing",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "Other tax filing",
            controlDesp: "Other tax filing",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "Secretary",
            controlDesp: "Basic secretary services",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "Account",
            controlDesp: "Book-keeping services",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "Account",
            controlDesp: "Unaudited financial statements",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "Advisory",
            controlDesp: "Tax advisory",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "HR",
            controlDesp: "EP services",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
          {
            controlType: "Others",
            controlDesp: "Others",
            linkedTransactionNo: [],
            coveredStart: "",
            coveredEnd: "",
            deadline: "",
            status: "",
            linkedServiceType: [],
            closureChecklist: [],
            remark: "",
            isInternal: 1,
          },
        ];
        this.$set(this.dataForm, "controlDataList", arr);
      }
    },
    // Status下拉框选择时
    statusChange(state, idx) {
      if (state == "COMPLETED") {
        this.$refs.completedCheckModal.init(idx);
      } else if (state == "NONE") {
        this.$refs["dataForm"].clearValidate([
          `controlDataList.${idx}.controlDesp`,
          `controlDataList.${idx}.linkedTransactionNo`,
          `controlDataList.${idx}.coveredStart`,
          `controlDataList.${idx}.coveredEnd`,
          `controlDataList.${idx}.deadline`,
          `controlDataList.${idx}.status`,
          `controlDataList.${idx}.linkedServiceType`,
        ]);
      } else {
        this.dataForm.controlDataList[idx].closureChecklist = [];
      }
    },
    completedCheckFeedback(fromObj, type) {
      if (type == "submit") {
        let res = fromObj.qaList.find(item => item.value == "NO");
        if (res) {
          this.$message.warning("Sorry, the tasks are not completed as certain works are pending to be completed.");
          this.dataForm.controlDataList[fromObj.idx].status = "";
        } else {
          console.log(fromObj.qaList);
          this.dataForm.controlDataList[fromObj.idx].closureChecklist = fromObj.qaList;
          this.dataForm.controlDataList[fromObj.idx].remark = fromObj.remark;
        }
      } else {
        this.dataForm.controlDataList[fromObj.idx].status = "";
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid, object) => {
        if (valid) {
          this.$emit("engagementControlFeedback", JSON.parse(JSON.stringify(this.dataForm)));
          this.visible = false;
        } else {
          validateLocation(object, this);
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.billingTo {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.required {
  &:before {
    content: "*";
    color: #ff6969;
    margin-right: 4px;
  }
}

.quotationWrap {
  ::v-deep .el-form-item {
    margin-bottom: 0px;
  }

  ::v-deep .el-form-item__content {
    line-height: 30px;
  }

  ::v-deep .el-form-item__error {
    padding-top: 1px !important;
    left: 50%;
    margin-left: -40px;
  }

  ::v-deep .el-input__inner {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 20px;
    border: none;
    height: 30px;
    line-height: 30px;
    background: #fff5f0;
    text-align: center;
  }

  ::v-deep .el-textarea__inner {
    font-size: 12px;
    border: none;
    background: #fff5f0;
    text-align: center;
    padding: 0 5px;
    overflow: hidden;
    word-break: break-word;
  }

  .amountInput {
    ::v-deep .el-input__inner {
      padding-right: 10px;
    }
  }

  .text-left {
    ::v-deep .el-input__inner {
      text-align: left;
    }
  }

  ::v-deep .el-input__prefix {
    display: none;
  }

  ::v-deep .el-input__suffix {
    right: 0px;
  }

  ::v-deep .el-input__icon {
    line-height: 30px;
  }

  ::v-deep .el-radio__label {
    font-size: 12px;
  }
}

.blackD8 {
  color: $navbar--color-dark;
}

.orange {
  color: $--color-warning;
  font-size: 20px;
  font-weight: bolder;
}

.blue {
  color: $--color-primary;
}

.red {
  color: $--color-danger;
}

p {
  line-height: 16px;
  margin: 0;
  color: #011a45;
}

.shadeWrap {
  width: 100%;
  padding: 0 5px;
  border-left: 1px solid #e4e7ed;
  border-right: 1px solid #e4e7ed;
  background-color: #fff5f0;
  margin-top: 6px;
}

.comboLeft {
  padding-right: 60px;
}

.comboRight {
  padding-left: 60px;
  border-left: 1px solid #e4e7ed;
}

.messageTitle {
  border-radius: 2px;
  font-size: 18px;
  line-height: 1em;
  font-weight: bold;
}

.mustTitle {
  border-radius: 2px;
  font-size: 16px;
  line-height: 1em;
  font-weight: 600;
}

.mustTitle:before {
  content: "*";
  margin-right: 4px;
  color: #f56c6c;
}

.singleTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 10px;
}

.singleText {
  font-size: 12px;
  line-height: 22px;
  margin-right: 30px;
  word-wrap: break-word;
  word-break: break-all;
}

.borderTop {
  border-top: 1px solid #e4e7ed;
}

.borderBottom {
  border-bottom: 1px solid #e4e7ed;
}
</style>
