var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('generate', {
    ref: "generateModalRef",
    attrs: {
      "generateData": _vm.generateData
    },
    on: {
      "updateGenerateData": _vm.updateGenerateData
    }
  }, [_vm.isAuth('budget:default') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.defaultHandle
    }
  }, [_vm._v(" Default "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("Reset all data and restore the default data")]), _c('span', {
    staticClass: "fs14 ml4 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1) : _vm._e(), _vm.isAuth('budget:update') ? _c('el-button', {
    staticClass: "mr30",
    on: {
      "click": _vm.updateHandle
    }
  }, [_vm._v(" Update "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("Update added or deleted data")]), _c('span', {
    staticClass: "fs14 ml4 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1) : _vm._e(), _vm.isAuth('budget:export') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": "",
      "icon": "el-icon-download",
      "disabled": _vm.totalCount == 0
    },
    on: {
      "click": _vm.handleExportExcel
    }
  }, [_vm._v(" Export Excel ")]) : _vm._e(), _vm.isAuth('budget:save') ? _c('el-button', {
    ref: "saveEnteredRef",
    attrs: {
      "type": "info",
      "plain": _vm.focusInputList.length == 0,
      "disabled": _vm.dataList.length == 0
    },
    on: {
      "click": function ($event) {
        return _vm.saveHandle($event);
      }
    }
  }, [_vm._v(" Save Entered "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs14"
  }, [_vm._v("Save the data entered in the table")]), _c('span', {
    staticClass: "fs14 ml4 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1) : _vm._e()], 1), _c('div', [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticClass: "tableDomClass",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-height": "750",
      "data": _vm.dataList,
      "row-class-name": _vm.tableRowDisable,
      "show-summary": "",
      "summary-method": _vm.getSummaries
    }
  }, [_c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Introduced By",
      "min-width": "300"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.commissionList ? _vm._l(scope.row.commissionList, function (item) {
          return _c('div', {
            key: `commissionList${item.id}${item.name}`
          }, [_vm._v(" " + _vm._s(item.name) + " - " + _vm._s(_vm.multiply(item.commissionRate)) + " ")]);
        }) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "clientCode",
      "header-align": "center",
      "align": "center",
      "label": "Client Code",
      "min-width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "companyName",
      "header-align": "center",
      "align": "center",
      "label": "Entity Name",
      "min-width": "200"
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Covered Period",
      "width": "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [_vm._v(_vm._s(scope.row.coveredPeriodStart))]), _c('div', [_vm._v(_vm._s(scope.row.coveredPeriodEnd))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Service Type",
      "min-width": "300"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.serviceTypeList ? _c('div', {
          staticClass: "enbr"
        }, [_vm._v(_vm._s(scope.row.serviceTypeList.join(", ")))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "actualTransaction",
      "header-align": "center",
      "align": "center",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.status ? [_c('div', [_vm._v(_vm._s(_vm.thousands(scope.row.actualTransaction)))])] : undefined;
      }
    }], null, true)
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "mediumBlue"
  }, [_vm._v(" Transaction "), _c('br'), _vm._v(" (Actual) ")])])], 2), _c('el-table-column', {
    attrs: {
      "prop": "actualTurnover",
      "header-align": "center",
      "align": "center",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.status ? [_c('div', [_vm._v(_vm._s(_vm.thousands(scope.row.actualTurnover)))])] : undefined;
      }
    }], null, true)
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "mediumBlue"
  }, [_vm._v(" Turnover "), _c('br'), _vm._v(" (Actual) ")])])], 2), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "min-width": "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.status ? [_vm.isAuth('budget:enter') ? [_c('div', {
          staticClass: "fxRow lh36 borderNone",
          class: {
            borderBold: _vm.focusInputList.includes(`${scope.row.id}Actual`)
          }
        }, [_c('el-input', {
          directives: [{
            name: "minusPoint2",
            rawName: "v-minusPoint2"
          }],
          staticClass: "fluid-width",
          attrs: {
            "clearable": "",
            "placeholder": "0.00"
          },
          on: {
            "input": function ($event) {
              return _vm.actualAdjustTurnoverInput(scope.row);
            },
            "focus": function ($event) {
              return _vm.actualAdjustTurnoverFocus(scope.row);
            }
          },
          model: {
            value: scope.row.actualAdjustTurnover,
            callback: function ($$v) {
              _vm.$set(scope.row, "actualAdjustTurnover", $$v);
            },
            expression: "scope.row.actualAdjustTurnover"
          }
        }, [_c('template', {
          slot: "prefix"
        }, [_vm._v("S$")])], 2)], 1)] : _c('div', [_vm._v(_vm._s(_vm.thousands(scope.row.actualAdjustTurnover)))])] : undefined;
      }
    }], null, true)
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "mediumBlue"
  }, [_vm._v(" Turnover "), _c('br'), _vm._v(" (Actual Adj.) ")])])], 2), _c('el-table-column', {
    attrs: {
      "prop": "budgetAutoTurnover",
      "header-align": "center",
      "align": "center",
      "min-width": "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.status ? [_c('div', [_vm._v(_vm._s(_vm.thousands(scope.row.budgetAutoTurnover)))])] : undefined;
      }
    }], null, true)
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "darkBlue"
  }, [_vm._v(" Turnover "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v(" (Turnover (actual)+Turnover (Actual adj.)) * days of budget period / days of covered period = Turnover (Auto Budget) ")]), _c('span', {
    staticClass: "pearl-blue fs16 ml4 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })]), _c('br'), _vm._v(" (Auto Budget) ")], 1)])], 2), _c('el-table-column', {
    attrs: {
      "prop": "budgetAutoCommissionTurnover",
      "header-align": "center",
      "align": "center",
      "min-width": "126"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.status ? [_c('div', [_vm._v(_vm._s(_vm.thousands(scope.row.budgetAutoCommissionTurnover)))])] : undefined;
      }
    }], null, true)
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "darkBlue"
  }, [_vm._v(" Commission "), _c('br'), _vm._v(" (Auto Budget) ")])])], 2), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "min-width": "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.status ? [_vm.isAuth('budget:enter') ? [_c('div', {
          staticClass: "fxRow lh36 borderNone",
          class: {
            borderBold: _vm.focusInputList.includes(`${scope.row.id}Budget`)
          }
        }, [_c('el-input', {
          directives: [{
            name: "minusPoint2",
            rawName: "v-minusPoint2"
          }],
          staticClass: "fluid-width",
          attrs: {
            "clearable": "",
            "placeholder": "0.00"
          },
          on: {
            "input": function ($event) {
              return _vm.budgeAdjustTurnoverInput(scope.row);
            },
            "focus": function ($event) {
              return _vm.budgeAdjustTurnoverFocus(scope.row);
            }
          },
          model: {
            value: scope.row.budgeAdjustTurnover,
            callback: function ($$v) {
              _vm.$set(scope.row, "budgeAdjustTurnover", $$v);
            },
            expression: "scope.row.budgeAdjustTurnover"
          }
        }, [_c('template', {
          slot: "prefix"
        }, [_vm._v("S$")])], 2)], 1)] : _c('div', [_vm._v(_vm._s(_vm.thousands(scope.row.budgeAdjustTurnover)))])] : undefined;
      }
    }], null, true)
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "darkBlue"
  }, [_vm._v(" Turnover "), _c('br'), _vm._v(" (Budget Adj.) ")])])], 2), _c('el-table-column', {
    attrs: {
      "prop": "budgeAdjustedTurnover",
      "header-align": "center",
      "align": "center",
      "min-width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.status ? [_c('div', [_vm._v(_vm._s(_vm.thousands(scope.row.budgeAdjustedTurnover)))])] : undefined;
      }
    }], null, true)
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "darkBlue"
  }, [_vm._v(" Turnover "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("Turnover (Auto Budget) - Commission (Auto Budget) + Turnover (Budget Adj.) = Turnover (Adjusted Budget)")]), _c('span', {
    staticClass: "pearl-blue fs16 ml4 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })]), _c('br'), _vm._v(" (Adjusted Budget) ")], 1)])], 2), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "width": "110",
      "label": "Action",
      "fixed": "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.isAuth('budget:enable') ? [!scope.row.status ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.enableHandle(scope.row);
            }
          }
        }, [_vm._v("Enable")]) : _vm._e()] : _vm._e(), _vm.isAuth('budget:disable') ? [scope.row.status ? _c('el-button', {
          staticClass: "fwb ml0",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.disableHandle(scope.row);
            }
          }
        }, [_vm._v("Disable")]) : _vm._e()] : _vm._e()];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('div', [_vm._v(" Action "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("\"Disabled\" does not participate in the calculation")]), _c('span', {
    staticClass: "pearl-blue fs16 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1)])], 2)], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.pageSize,
      "total": _vm.totalCount,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }