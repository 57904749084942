<template>
  <el-dialog title="Officer Details - Shareholder(s)/Director(s)" width="1100px" :close-on-click-modal="false" :visible.sync="visible">
    <div class="company-member-list pb60">
      <div class="pb20">
        <el-button type="primary" v-if="applymentStatus != 'CHECKING' && applymentStatus != 'REVISE_FINISH'" @click="addMember()" class="mr20">
          Add
        </el-button>
        <template v-if="(applymentStatus === 'CHECKING' || applymentStatus === 'REVISE_FINISH') && isApplymentAuth('company:check')">
          <template v-if="isNeedNomineeDirector">
            <template v-if="!tableHasNomineeDirector">
              <el-button type="primary" @click="addNomineeDirector">Add Nominee Director</el-button>
            </template>
          </template>
        </template>
      </div>
      <div>
        <el-table border :data="dataList" style="width: 100%">
          <el-table-column prop="fullName" header-align="center" align="center" width="250" label="Name"></el-table-column>
          <el-table-column prop="memberType" header-align="center" align="center" width="250" label="Type" :formatter="memberTypeFormat"></el-table-column>
          <el-table-column
            prop="positionHeld"
            header-align="center"
            align="center"
            width="250"
            label="Position Held"
            :formatter="positionHeldFormat"
          ></el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" min-width="200" label="Action">
            <template slot-scope="scope">
              <template v-if="scope.row.positionHeld === 'NOMINEE_DIRECTOR'">
                <template v-if="(applymentStatus === 'CHECKING' || applymentStatus === 'REVISE_FINISH') && isApplymentAuth('company:check')">
                  <el-button type="text" size="small" @click="adjustNDHandle(scope.row)">Adjust</el-button>
                  <el-button type="text" size="small" @click="doDeleteNDMember(scope.row.id)">Delete</el-button>
                </template>
              </template>
              <template
                v-if="
                  scope.row.positionHeld != 'NOMINEE_DIRECTOR' &&
                  isApplymentAuth('company:coordinate') &&
                  (applymentStatus === 'REVISING' ||
                    applymentStatus === 'CORRECTED' ||
                    applymentStatus === 'ILLEGAL' ||
                    applymentStatus === 'AUDIT_FAIL' ||
                    applymentStatus === 'REFUND_APPLY_DENY' ||
                    applymentStatus === 'REFUND_APPLY_REJECT' ||
                    applymentStatus === 'ACRA_REJECTED')
                "
              >
                <el-button type="text" size="small" @click="updateHandle(scope.row)">Edit</el-button>
                <el-button type="text" size="small" @click="doDeleteMember(scope.row.id)">Delete</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      :businessType="businessType"
      :isNeedNomineeDirector="isNeedNomineeDirector"
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @change="doUpdateMember"
    ></add-or-update>
    <!-- 名义董事 -->
    <nominee-director-selector v-if="nomineeDirectorSelectorVisible" ref="nomineeDirectorSelector" @change="doUpdateNDMember"></nominee-director-selector>
  </el-dialog>
</template>

<script>
import AddOrUpdate from "./AddOrUpdate";
import NomineeDirectorSelector from "./NomineeDirector.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    AddOrUpdate,
    NomineeDirectorSelector,
  },
  props: {
    stepName: {
      type: String,
    },
    applymentStatus: {
      type: String,
    },
    businessType: {
      type: String,
    },
    hasNomineeDirector: {
      type: Number,
      default: 0,
    },
    isNeedNomineeDirector: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: false,
      addOrUpdateVisible: false,
      addOrUpdateSubmitSwitch: true,
      nomineeDirectorSelectorVisible: false,
      applymentId: undefined,
      dataList: [],
    };
  },
  computed: {
    ...mapState("constant", ["companyMemberTypes", "positionHelds", "businessStepObj"]),
    tableHasNomineeDirector() {
      return this.dataList.findIndex(i => i.positionHeld === "NOMINEE_DIRECTOR") >= 0;
    },
  },
  methods: {
    ...mapActions("applyment", ["doSaveRevising", "onlyUpdateDetail"]),
    memberTypeFormat(row, column, v) {
      const one = this.companyMemberTypes.find(i => i.value === v);
      return one?.title;
    },
    positionHeldFormat(row, column, v) {
      const one = this.positionHelds.find(i => i.value === v);
      return one?.title;
    },
    async init(applymentId, data) {
      this.visible = true;
      this.addOrUpdateSubmitSwitch = true;
      await this.$nextTick();
      const copy = JSON.parse(JSON.stringify(data));
      this.applymentId = applymentId;
      this.dataList = copy;
    },
    async addMember() {
      this.addOrUpdateVisible = true;
      await this.$nextTick();
      this.$refs.addOrUpdate.init();
    },
    async addNomineeDirector() {
      // 判断是否勾选(我们同意聘用由星中商务推荐的名义董事1名，我们也理解该名义董事的行为造成的任何法律影响均与EStar无关。)
      this.nomineeDirectorSelectorVisible = true;
      await this.$nextTick();
      this.$refs.nomineeDirectorSelector.init();
    },
    async updateHandle(row) {
      this.addOrUpdateVisible = true;
      await this.$nextTick();
      this.$refs.addOrUpdate.init(row);
    },
    async adjustNDHandle(row) {
      this.nomineeDirectorSelectorVisible = true;
      await this.$nextTick();
      this.$refs.nomineeDirectorSelector.init(row.id);
    },
    //删除成员
    async doDeleteMember(id) {
      this.$confirm(`Remove this Member. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const { code, msg } = await this.$http.post(`/admin/companyMemberManage/${id}/deleteMember`);
        if (code === 200) {
          const one = this.dataList.find(i => i.id === id);
          this.dataList = this.dataList.filter(i => i !== one);
          this.doRevising();
        } else {
          this.$message.error(msg);
        }
      });
    },
    //更新成员列表数据
    async doUpdateMember(dataForm) {
      if (!this.addOrUpdateSubmitSwitch) {
        return;
      }
      this.addOrUpdateSubmitSwitch = false;
      const copyDataList = JSON.parse(JSON.stringify(this.dataList));
      dataForm.applymentId = this.applymentId;
      if (dataForm.id) {
        const { code, msg } = await this.$http.post(`/admin/companyMemberManage/${dataForm.id}/updateMember`, dataForm);
        if (code === 200) {
          const one = this.dataList.find(i => i.id === dataForm.id);
          const idx = this.dataList.indexOf(one);
          const copy = JSON.parse(JSON.stringify(dataForm));
          copyDataList[idx] = copy;
          this.dataList = copyDataList;
          this.doRevising();
        } else {
          this.addOrUpdateSubmitSwitch = true;
          this.$message.error(msg);
        }
      } else {
        const { code, msg, data } = await this.$http.post(`/admin/companyMemberManage/addMember`, dataForm);
        if (code === 200) {
          this.dataList = [...copyDataList, data];
          this.doRevising();
        } else {
          this.addOrUpdateSubmitSwitch = true;
          this.$message.error(msg);
        }
      }
    },
    //提交步骤修改数据
    async doRevising() {
      const submitData = {
        id: this.applymentId,
        companyMemberList: this.dataList,
        procedure: {
          step: this.businessStepObj[this.businessType][this.stepName],
        },
      };
      this.doSaveRevising(submitData)
        .then(() => {
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.addOrUpdateSubmitSwitch = true;
              this.addOrUpdateVisible = false;
            },
          });
        })
        .catch(msg => {
          this.addOrUpdateSubmitSwitch = true;
          this.$message.error(msg);
        });
    },
    //删除名义董事
    async doDeleteNDMember(id) {
      this.$confirm(`Remove this Nominee Director. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const { code, msg } = await this.$http.post(`/admin/companyMemberManage/${id}/deleteMember`);
        if (code === 200) {
          const one = this.dataList.find(i => i.id === id);
          this.dataList = this.dataList.filter(i => i !== one);
          this.onlyUpdateDetail({
            id: this.applymentId,
            companyMemberList: this.dataList,
          });
        } else {
          this.$message.error(msg);
        }
      });
    },
    //更新名义董事
    async doUpdateNDMember(dataForm) {
      const copyDataList = JSON.parse(JSON.stringify(this.dataList));
      const { code, msg, data } = await this.$http.post(`/admin/companyMemberManage/allocNomineeDirector`, {
        ...dataForm,
        applymentId: this.applymentId,
      });
      if (code === 200) {
        if (dataForm.id) {
          const one = this.dataList.find(i => i.id === data.id);
          const idx = this.dataList.indexOf(one);
          const copy = JSON.parse(JSON.stringify(data));
          copyDataList[idx] = copy;
          this.dataList = copyDataList;
        } else {
          this.dataList = [...copyDataList, data];
        }
        this.onlyUpdateDetail({
          id: this.applymentId,
          companyMemberList: this.dataList,
        }).then(() => {
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.nomineeDirectorSelectorVisible = false;
            },
          });
        });
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>
