<template>
  <el-checkbox-group :class="{ 'no-event': readonly }" v-model="multipleValue" @change="handleChange">
    <el-checkbox v-for="item in options" :key="item.value" :label="item.value">
      {{ item.title }}
      <el-row>
        <el-col :lg="24" :xl="24">
          <p v-for="(sub, index) in otherValue" :key="'sub' + index">
            <el-input
              v-if="item.other && item.other.type === 'textarea' && sub['k'] == item.value && multipleValue.includes(sub['k'])"
              type="textarea"
              placeholder="please input.."
              :rows="2"
              :autosize="{ minRows: 2 }"
              :readonly="readonly"
              @input="handleInput"
              v-model="otherValue[index]['v']"
              :oninput="isEn"
            />
          </p>
        </el-col>
      </el-row>
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  name: "MultipleBox",
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      multipleValue: [],
      otherValue: [],
    };
  },
  mounted() {
    this.initData(this.value);
  },
  watch: {
    value(newArr) {
      this.initData(newArr);
    },
  },
  computed: {
    composeValue() {
      const copy = [...this.multipleValue];
      this.otherValue.forEach(i => {
        const idx = copy.indexOf(i.k);
        if (idx != -1) {
          copy[idx] = i;
        }
      });
      return copy;
    },
  },
  methods: {
    initData(newArr) {
      if (newArr == null) newArr = [];
      // 给 otherValue 预赋值
      let otherValue = [];
      this.options.forEach(j => {
        if (j.other) {
          otherValue.push({
            k: j.value,
            v: "",
          });
        }
      });

      let multipleValue = [];
      newArr.forEach(i => {
        if (i && i.k) {
          multipleValue.push(i.k);
          // 给 otherValue 赋值
          for (let m in otherValue) {
            if (otherValue[m].k == i.k) {
              otherValue[m] = i;
            }
          }
        } else {
          multipleValue.push(i);
        }
      });
      // 给数组赋值
      this.multipleValue = multipleValue;
      this.otherValue = otherValue;
    },
    handleChange(v) {
      this.$emit("input", this.composeValue);
    },
    handleInput(v) {
      this.$emit("input", this.composeValue);
    },
  },
};
</script>

<style></style>
