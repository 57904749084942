import request from "@/utils/http";

export default {
  namespaced: true,
  state: {
    detail: {},
  },
  mutations: {
    INIT_DETAIL(state, detail) {
      state.detail = { ...detail };
    },
    SET_DETAIL(state, detail) {
      state.detail = { ...state.detail, ...detail };
    },
  },
  actions: {
    //订单详情
    async initOrder({ commit }, orderId) {
      const { data } = await request.get(`/admin/orderManage/${orderId}/detail`);
      commit("INIT_DETAIL", data);
      return Promise.resolve(data);
    },
    //确认转账 - 财务
    doReceipt({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        let applymentId = state.detail.id;
        request
          .post(`/admin/orderManage/${applymentId}/transferVerify`, {
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = {
                ...this.detail,
                status: payload.status,
                transferVerifyRemark: payload.remark,
                payType: payload.payType,
                isInvoiceRevised: payload.isInvoiceRevised,
                revisedInvoiceFile: payload.revisedInvoiceFile,
              };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //确认退款 - 财务
    doRefund({ commit }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/orderRefundManage/refundByOrderId?orderId=${payload.refundDetail.orderId}`, {
            status: payload.status,
            failReason: payload.failReason,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, refundDetail: payload.refundDetail };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //批量收款 - 财务
    doBatchReceipt(_, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/orderManage/batchTransferVerify`, {
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //批量退款 - 财务
    doBatchRefund(_, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/orderRefundManage/refundByOrderIds`, {
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    // 发起后付款 - 财务
    doApplyCredit({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const id = state.detail.id;
        request
          .post(`/admin/orderManage/${id}/applyCredit`, {
            applyReason: payload.applyReason,
            attachmentUrls: payload.attachmentUrls,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, credit: payload.credit, creditStatus: payload.creditStatus };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    // 后付款审批 - 财务复核
    doAuditCredit({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const id = state.detail.id;
        request
          .post(`/admin/orderManage/${id}/auditCredit`, {
            auditRemark: payload.auditRemark,
            creditStatus: payload.creditStatus,
            attachmentUrls: payload.attachmentUrls,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, credit: payload.credit, creditStatus: payload.creditStatus };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
  },
};
