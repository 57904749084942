<template>
  <el-dialog :title="!dataForm.id ? '新增字典' : '编辑字典'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="dataForm.type">
          <el-radio v-for="(type, index) in dataForm.typeList" :label="index" :key="index">{{ type }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="dataForm.typeList[dataForm.type] + '名称'" prop="name">
        <el-input v-model="dataForm.name" :placeholder="dataForm.typeList[dataForm.type] + '名称'"></el-input>
      </el-form-item>
      <el-form-item label="上级目录" prop="parentName">
        <el-popover ref="dictListPopover" v-model="popoverVisible" placement="bottom-start" trigger="click">
          <el-tree
            :data="dictList"
            :props="dictListTreeProps"
            node-key="id"
            ref="dictListTreeRef"
            @current-change="dictListTreeCurrentChangeHandle"
            :default-expand-all="true"
            :highlight-current="true"
            :expand-on-click-node="false"
          ></el-tree>
        </el-popover>
        <el-input
          v-model="dataForm.parentName"
          v-popover:dictListPopover
          :readonly="true"
          placeholder="点击选择上级字典"
          class="dict-list__input"
        ></el-input>
      </el-form-item>
      <template v-if="dataForm.type === 1">
        <el-form-item label="字典码" prop="dictCode">
          <el-input v-model="dataForm.dictCode" placeholder="字典码"></el-input>
        </el-form-item>
        <el-form-item label="字典值" prop="dictValue">
          <el-input v-model="dataForm.dictValue" placeholder="字典值"></el-input>
        </el-form-item>
      </template>
      <el-form-item label="排序号" prop="orderNum">
        <el-input-number v-model="dataForm.orderNum" controls-position="right" :min="0" label="排序号"></el-input-number>
      </el-form-item>
      <el-form-item label="备注" size="mini" prop="remark">
        <el-input v-model="dataForm.remark" type="textarea" :rows="5" :autosize="{ minRows: 4 }" resize="none" placeholder="备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      visible: false,
      popoverVisible: false,
      dataForm: {
        id: 0,
        type: 1,
        typeList: ["目录", "字典"],
        name: "",
        parentId: 0,
        parentName: "",
        dictCode: "",
        dictValue: "",
        orderNum: 0,
        remark: "",
      },
      dataRule: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        parentName: [{ required: true, message: "上级目录不能为空", trigger: "change" }],
      },
      dictList: [],
      dictListTreeProps: {
        label: "name",
        children: "children",
      },
    };
  },
  methods: {
    async init(id) {
      this.dataForm.id = id || 0;
      const { data: dictToBeSelect } = await this.$http.get("/admin/sys/dict/select");
      this.dictList = treeDataTranslate(dictToBeSelect, "id");
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      if (!this.dataForm.id) {
        // 新增
        this.dictListTreeSetCurrentNode();
      } else {
        // 修改
        const { data } = await this.$http.get(`/admin/sys/dict/${this.dataForm.id}`);
        this.dataForm.id = data.id;
        this.dataForm.type = data.type;
        this.dataForm.name = data.name;
        this.dataForm.parentId = data.parentId;
        this.dataForm.dictCode = data.dictCode;
        this.dataForm.dictValue = data.dictValue;
        this.dataForm.orderNum = data.orderNum;
        this.dataForm.remark = data.remark;
        this.dictListTreeSetCurrentNode();
      }
    },
    // 字典树选中
    dictListTreeCurrentChangeHandle(data, node) {
      this.dataForm.parentId = data.id;
      this.dataForm.parentName = data.name;
      this.popoverVisible = false;
    },
    // 字典树设置当前选中节点
    dictListTreeSetCurrentNode() {
      this.$refs.dictListTreeRef.setCurrentKey(this.dataForm.parentId);
      this.dataForm.parentName = (this.$refs.dictListTreeRef.getCurrentNode() || {})["name"];
    },
    // 表单提交
    async formSubmitRequest(dataForm) {
      if (dataForm.type != 1) {
        dataForm.dictCode = "";
        dataForm.dictValue = "";
      }
      let payload = {
        type: dataForm.type,
        name: dataForm.name,
        parentId: dataForm.parentId,
        orderNum: dataForm.orderNum,
        dictCode: dataForm.dictCode,
        dictValue: dataForm.dictValue,
        remark: dataForm.remark,
      };
      if (dataForm.id) {
        return await this.$http.put(`/admin/sys/dict/${dataForm.id}`, payload);
      } else {
        return await this.$http.post(`/admin/sys/dict`, payload);
      }
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const { code, msg } = await this.formSubmitRequest(this.dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
              },
            });
          } else {
            this.$message.error(msg);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.mod-dict {
  .dict-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }
  &__icon-popover {
    width: 458px;
    overflow: hidden;
  }
  &__icon-inner {
    width: 478px;
    max-height: 258px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__icon-list {
    width: 458px;
    padding: 0;
    margin: -8px 0 0 -8px;
    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;
      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
</style>
