<template>
  <el-select v-model="selectList" multiple clearable collapse-tags @change="selectChange" :placeholder="placeholder" :style="{ width: `${width}px` }">
    <el-checkbox v-model="isMultipleSelectAll" @change="selectAll" class="ml20 pt10 pb8">Select all</el-checkbox>
    <el-option v-for="item in optionList" :key="item.value" :label="item.title" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    optionList: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    placeholder: {
      type: String,
    },
    width: {
      type: Number,
      required: false,
      default: function () {
        return 340;
      },
    },
  },
  data() {
    return {
      isMultipleSelectAll: false, // 全选
      selectList: [],
    };
  },
  methods: {
    // 多选框change
    selectChange(list) {
      if (list.length === this.optionList.length) {
        this.isMultipleSelectAll = true;
      } else {
        this.isMultipleSelectAll = false;
      }
      this.$emit("elSelectAllChange", list);
    },
    // 多选框全选checkbox
    selectAll() {
      this.selectList = [];
      if (this.isMultipleSelectAll) {
        this.optionList.map(item => {
          this.selectList.push(item.value);
        });
      } else {
        this.selectList = [];
      }
      this.$emit("elSelectAllChange", this.selectList);
    },
  },
};
</script>

<style>
.icon-svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}
</style>
