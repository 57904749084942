<template>
  <el-card>
    <el-row>
      <el-col :span="15">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="174px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="Business Type" prop="businessType">
                <el-select v-model="dataForm.businessType" placeholder="Select BusinessType" class="fluid-width">
                  <el-option v-for="item in businessTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Country" prop="country">
                <el-select v-model="dataForm.country" placeholder="Select Country" class="fluid-width">
                  <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Name" prop="name">
                <el-input v-model="dataForm.name" placeholder="Plan Name" :oninput="isEn"></el-input>
              </el-form-item>
              <el-form-item label="Name(CN)" prop="nameCn">
                <el-input v-model="dataForm.nameCn" placeholder="Plan Name(CN)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Applicable Person" prop="brief">
            <el-input
              v-model="dataForm.summary"
              type="textarea"
              :rows="5"
              :autosize="{ minRows: 4 }"
              resize="none"
              placeholder="Applicable Person"
              :oninput="isEn"
            ></el-input>
          </el-form-item>
          <el-form-item label="Applicable Person(CN)" prop="brief">
            <el-input
              v-model="dataForm.summaryCn"
              type="textarea"
              :rows="5"
              :autosize="{ minRows: 4 }"
              resize="none"
              placeholder="Applicable Person(CN)"
            ></el-input>
          </el-form-item>
          <el-form-item label="Services" prop="moduleList">
            <span slot="label">
              <span>
                Services
                <el-popover placement="top-start" width="220" trigger="hover" content="Vary by business type and country">
                  <i slot="reference" class="el-icon-warning-outline"></i>
                </el-popover>
              </span>
            </span>
            <plan-module-picker v-model="dataForm.moduleList" :modules="planModules"></plan-module-picker>
          </el-form-item>
          <el-form-item label="Price" prop="price">
            <el-input type="number" v-model="dataForm.price" class="wd150">
              <template slot="prefix">S$</template>
            </el-input>

            <!-- <el-input type="number" v-model="dataForm.priceCn">
                  <template slot="prefix">￥</template>
                </el-input> -->
          </el-form-item>
          <el-form-item label="Status" prop="status">
            <el-radio-group v-model="dataForm.status">
              <el-radio :label="0">Disable</el-radio>
              <el-radio :label="1">Enable</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="dataFormSubmit">Submit</el-button>
            <el-button @click="() => $router.push('/sys/pkg/plan')">Back</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { mapState } from "vuex";
import PlanModulePicker from "@/components/PlanModulePicker";
export default {
  components: {
    PlanModulePicker,
  },
  data() {
    return {
      dataId: undefined,
      dataForm: {
        businessType: "",
        name: "",
        nameCn: "",
        country: "",
        summary: "",
        summaryCn: "",
        price: undefined,
        moduleList: [],
        status: 1,
      },
      dataRule: {
        businessType: [{ required: true, message: "Business Type is required", trigger: "blur" }],
        country: [{ required: true, message: "Country is required", trigger: "blur" }],
        name: [{ required: true, message: "Plan Name is required", trigger: "blur" }],
        nameCn: [{ required: true, message: "Plan Name(CN) is required", trigger: "blur" }],
      },
      allMPlanodules: [],
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["businessTypes", "countryList"]),
    planModules() {
      const { businessType, country } = this.dataForm;
      if (businessType && country) {
        return this.allMPlanodules.filter(i => i.country === country && i.businessTypeList.includes(businessType));
      } else {
        return [];
      }
    },
  },
  activated() {
    this.submitControl = true;
    this.$refs.dataForm.resetFields();
    this.getPlanModules();
    const id = this.$route.params.id;
    if (id) {
      this.dataId = id;
      this.getDetail(id);
    } else {
      this.dataId = undefined;
    }
  },
  methods: {
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/planManage/${id}/detail`);
      if (data.moduleList) {
        data.moduleList = data.moduleList.map(i => {
          i.checked = true;
          return i;
        });
      } else {
        data.moduleList = [];
      }
      this.dataForm = data;
    },
    async getPlanModules() {
      const { data } = await this.$http.get(`/admin/moduleManage/getList`);
      this.allMPlanodules = data;
    },
    async dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const dataId = this.dataId;
          const dataForm = { ...this.dataForm };
          let { code, msg } = await this.$http.post(dataId ? `/admin/planManage/${dataId}/update` : "/admin/planManage/create", dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.$router.push("/sys/pkg/plan");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
