var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-applyment-module"
  }, [_c('el-affix', {
    class: {
      'applyment-page-header-top': _vm.affixed,
      'applyment-page-header-shrink': _vm.sidebarFold,
      'applyment-page-header-unfold': !_vm.sidebarFold
    },
    attrs: {
      "offset": 104
    },
    on: {
      "change": _vm.handleAffixChange
    }
  }, [_c('el-card', [_c('div', {
    staticClass: "applyment-page-header"
  }, [_c('div', [_c('div', {
    staticClass: "status"
  }, [_vm._v(" " + _vm._s(_vm.statusFormatter(_vm.engagementDetail.status)) + " "), _vm.engagementDetail.reopenStatus != null ? _c('span', {
    staticClass: "fs12"
  }, [_vm._v(" (Reopen Status: " + _vm._s(_vm.reopenStatusFormatter(_vm.engagementDetail.reopenStatus)) + ") ")]) : _vm._e()])]), _c('div', {
    staticClass: "page-header-heading-right"
  }, [_c('div', {
    staticClass: "opts"
  }, [_vm.isAuth('engagement:charge') && _vm.isHasCurrUserId ? [_c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": _vm.openStatusHistoryModal
    }
  }, [_vm._v("History Track")]), _vm.engagementDetail.status == 'OPEN' ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.modifyHandle
    }
  }, [_vm._v("Modify Save")]) : _vm._e(), _vm.engagementDetail.status == 'CLOSE' && _vm.engagementDetail.reopenStatus != 0 ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.reOpenHandle
    }
  }, [_vm._v(" Reopen ")]) : _vm._e(), _vm.engagementDetail.status == 'OPEN' ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.closeHandle
    }
  }, [_vm._v("Close")]) : _vm._e()] : _vm._e(), _vm.activeRoleName == 'SUPER' && _vm.engagementDetail.status == 'REOPEN' && _vm.engagementDetail.reopenStatus == 0 ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.reOpenAuditHandle
    }
  }, [_vm._v(" Reopen Review ")]) : _vm._e()], 2)])])]), _c('div', {
    class: {
      mt26: !_vm.affixed,
      engagementFixed: _vm.affixed
    }
  }, [_c('el-card', [_c('div', {
    staticClass: "desp"
  }, [!_vm.affixed ? _c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Engagement")])]) : _vm._e(), _c('el-row', {
    attrs: {
      "gutter": 10
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "wd140 desp-item-label"
  }, [_vm._v("Client Code")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.engagementDetail.clientCode) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "wd140 desp-item-label"
  }, [_vm._v("Entity Type")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.companyTypeFormat(_vm.engagementDetail.companyType)) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "wd140 desp-item-label"
  }, [_vm._v("Entity Country")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.engagementDetail.companyCountry) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "wd140 desp-item-label"
  }, [_vm._v("UEN")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.engagementDetail.uen) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "wd140 desp-item-label"
  }, [_vm._v("Entity Name")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.engagementDetail.companyName) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "wd140 desp-item-label"
  }, [_vm._v("Covered Period")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_c('span', [_vm._v(_vm._s(_vm.engagementDetail.coveredPeriodStart))]), _vm.engagementDetail.coveredPeriodEnd ? _c('span', {
    staticClass: "pl5 pr5"
  }, [_vm._v("~")]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.engagementDetail.coveredPeriodEnd))])])])])], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "step mt46"
  }, [_c('el-card', [_c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Linked Transactions")])]), _c('div', [_vm.isAuth('engagement:linked') ? _c('div', {
    staticClass: "pt10 pb26"
  }, [_vm.engagementDetail.status == 'OPEN' ? _c('el-button', {
    on: {
      "click": _vm.editLinkedTransaction
    }
  }, [_vm._v("Edit Linked Transactions")]) : _vm._e()], 1) : _vm._e(), _c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.engagementDetail.relatedUserTransactions
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "transactionNo",
      "label": "Transaction No.",
      "header-align": "center",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "payee",
      "label": "Owner",
      "header-align": "center",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "relatedTransaction",
      "label": "Related Transaction",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(_vm._s(_vm._f("thousands")(scope.row.relatedTransaction)))];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "relatedTurnover",
      "label": "Related Turnover",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(_vm._s(_vm._f("thousands")(scope.row.relatedTurnover)))];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "In-charge",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.inChargeUsers, function (item, idx) {
          return _c('p', {
            key: `inChargeUsers${idx}${item.id}`,
            staticClass: "mt0 mb0"
          }, [_c('span', [_vm._v(_vm._s(item.nickname))]), _c('span', [_vm._v("(" + _vm._s(item.username) + ")")])]);
        });
      }
    }])
  })], 1)], 1)])])], 1), _c('el-form', {
    ref: "dataForm",
    staticClass: "feeLetterForm",
    attrs: {
      "model": _vm.dataForm,
      "label-position": "left"
    }
  }, [_c('div', {
    ref: "engagementScroll",
    staticClass: "step mt46"
  }, [_c('el-card', [_c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Engagement Control")])]), _vm.isAuth('engagement:charge') && _vm.isHasCurrUserId ? _c('div', {
    staticClass: "pt10 pb26"
  }, [_vm.engagementDetail.status == 'OPEN' ? _c('el-button', {
    on: {
      "click": _vm.editEngagementControlModal
    }
  }, [_vm._v("Edit Engagement Control")]) : _vm._e(), _vm.engagementDetail.status == 'OPEN' && _vm.dataForm.controlDataList.length > 0 ? _c('el-button', {
    staticClass: "ml16",
    attrs: {
      "type": "danger",
      "plain": ""
    },
    on: {
      "click": _vm.clearEngagementControl
    }
  }, [_vm._v(" Clear Engagement Control ")]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "dateModule"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('el-form-item', {
    ref: "fyPeriod",
    attrs: {
      "label": "FY Period:",
      "prop": "fyPeriod",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('span', {
    staticClass: "lh38"
  }, [_vm._v(_vm._s(_vm.dataForm.fyPeriod))])])], 1), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('el-form-item', {
    ref: "fyEnd",
    attrs: {
      "label": "FY end:",
      "prop": "fyEnd",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_vm.dataForm.fyEnd ? _c('el-date-picker', {
    staticClass: "datePicker",
    attrs: {
      "type": "date",
      "format": `dd [${_vm.monthList[parseInt(_vm.dataForm.fyEnd.split('-')[0]) - 1]}]`,
      "value-format": "MM-dd",
      "clearable": false,
      "readonly": true,
      "disabled": ""
    },
    model: {
      value: _vm.dataForm.fyEnd,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fyEnd", $$v);
      },
      expression: "dataForm.fyEnd"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('div', [_c('el-form-item', {
    ref: "controlDataList",
    attrs: {
      "prop": "controlDataList",
      "rules": {
        type: 'array',
        required: true,
        message: 'Please add',
        trigger: 'submit'
      }
    }
  }, [_c('engagement-control', {
    attrs: {
      "controlDataList": _vm.dataForm.controlDataList
    }
  })], 1)], 1)])])], 1)]), _c('div', {
    staticClass: "step mt40"
  }, [_c('el-card', [_c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Service Type")])]), _c('div', [_vm.isAuth('engagement:charge') && _vm.isHasCurrUserId ? _c('div', {
    staticClass: "pt10 pb26"
  }, [_vm.engagementDetail.status == 'OPEN' ? _c('el-button', {
    on: {
      "click": _vm.editServiceType
    }
  }, [_vm._v("Edit Service Type")]) : _vm._e()], 1) : _vm._e(), _c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.serviceTypeDataList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "serviceType",
      "header-align": "center",
      "align": "center",
      "label": "Service Type"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "serviceDetails",
      "header-align": "center",
      "align": "center",
      "label": "Service Details"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(scope.row.serviceDetails.filter(function (item) {
          return item;
        }).join(", ")))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "relatedTransaction",
      "label": "Related Transaction",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("thousands")(scope.row.relatedTransaction)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "relatedTurnover",
      "label": "Related Turnover",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("thousands")(scope.row.relatedTurnover)) + " ")];
      }
    }])
  })], 1)], 1)])])], 1), _c('transaction-and-turnover-difference', {
    attrs: {
      "relatedUserTransactions": _vm.engagementDetail.relatedUserTransactions,
      "serviceTypeDataList": _vm.serviceTypeDataList
    }
  }), _c('status-history', {
    ref: "statusHistoryModal"
  }), _c('re-open', {
    ref: "reOpenModal",
    attrs: {
      "engagementId": _vm.engagementId,
      "engagementDetail": _vm.engagementDetail
    },
    on: {
      "reOpenFeedback": _vm.getDetail
    }
  }), _c('re-open-audit', {
    ref: "reOpenAuditModal",
    attrs: {
      "engagementId": _vm.engagementId,
      "engagementDetail": _vm.engagementDetail
    },
    on: {
      "reOpenAuditFeedback": _vm.getDetail
    }
  }), _c('linked-transactions-modal', {
    ref: "linkedTransactionsModal",
    attrs: {
      "engagementId": _vm.engagementId,
      "controlDataList": _vm.dataForm.controlDataList
    },
    on: {
      "linkedTransactionsFeedback": _vm.getDetail
    }
  }), _c('engagement-control-modal', {
    ref: "engagementControlModal",
    attrs: {
      "engagementDetail": _vm.engagementDetail
    },
    on: {
      "engagementControlFeedback": _vm.engagementControlFeedback
    }
  }), _c('service-type-modal', {
    ref: "serviceTypeModal",
    on: {
      "serviceTypeFeedback": _vm.serviceTypeFeedback
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }