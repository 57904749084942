var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "ACRA Feedback",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.rules,
      "autocomplete": "off",
      "label-width": "246px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "applymentStatus"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.applymentStatus,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "applymentStatus", $$v);
      },
      expression: "dataForm.applymentStatus"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": "ACRA_REJECTED"
    }
  }, [_vm._v("Rejected")]), _c('el-radio', {
    attrs: {
      "label": "FINISH"
    }
  }, [_vm._v("Pass")])], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Remark",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Remark",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1), _vm.dataForm.applymentStatus == 'ACRA_REJECTED' ? _c('el-form-item', {
    attrs: {
      "label": "Attachments",
      "prop": "attachmentUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.attachmentUrls,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "attachmentUrls", $$v);
      },
      expression: "dataForm.attachmentUrls"
    }
  })], 1) : _vm._e(), _vm.dataForm.applymentStatus == 'FINISH' ? _c('div', [_c('el-form-item', {
    attrs: {
      "label": "Article of Association"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.articlesOfIncorporationUrls,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "articlesOfIncorporationUrls", $$v);
      },
      expression: "dataForm.articlesOfIncorporationUrls"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Biz/ACRA File"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.businessDocumentsUrls,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "businessDocumentsUrls", $$v);
      },
      expression: "dataForm.businessDocumentsUrls"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Other Documents",
      "prop": "otherDocumentsUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.otherDocumentsUrls,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "otherDocumentsUrls", $$v);
      },
      expression: "dataForm.otherDocumentsUrls"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Biz Reg. No. (UEN)",
      "prop": "businessLicenseNumber"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Business License Number",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.businessLicenseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "businessLicenseNumber", $$v);
      },
      expression: "dataForm.businessLicenseNumber"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Company/Fund Manager Name",
      "prop": "companyName"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Company/Fund Manager Name",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "companyName", $$v);
      },
      expression: "dataForm.companyName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Incorporation/Transfer Time"
    }
  }, [_c('el-date-picker', {
    staticClass: "fluid-width",
    attrs: {
      "type": "date",
      "placeholder": "Incorporation/Transfer Time",
      "value-format": "yyyy-MM-dd 00:00:00"
    },
    model: {
      value: _vm.dataForm.incorporateTime,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "incorporateTime", $$v);
      },
      expression: "dataForm.incorporateTime"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Supplemental/Renewal Time"
    }
  }, [_c('el-date-picker', {
    staticClass: "fluid-width",
    attrs: {
      "type": "date",
      "placeholder": "Supplemental/Renewal Time",
      "value-format": "yyyy-MM-dd 00:00:00"
    },
    model: {
      value: _vm.dataForm.supplementalTime,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "supplementalTime", $$v);
      },
      expression: "dataForm.supplementalTime"
    }
  })], 1)], 1) : _vm._e()], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit(true);
      }
    }
  }, [_vm._v("Save")]), _c('el-button', {
    staticClass: "ml20",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit(false);
      }
    }
  }, [_vm._v("OK")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }