<template>
  <div class="desp">
    <div class="desp-header">Confirmation:</div>
    <div class="desp-items-group">
      <div class="desp-items-group-title-icon">
        <svg-icon name="applicant" class="jump-icon"></svg-icon>
        Applicant information
      </div>
      <div class="desp-items-wrapper">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Applicant Name</div>
              <div class="desp-item-content">{{ detail.applicantName }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Mobile</div>
              <div class="desp-item-content">{{ detail.applicantPhone }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Email</div>
              <div class="desp-item-content">{{ detail.applicantEmail }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Residential Address</div>
              <div class="desp-item-content">{{ detail.residentialAddress }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="desp-item">
              <div class="desp-item-label">Applicant role</div>
              <div class="desp-item-content">{{ applicantPositionFormat(detail.applicantPosition) }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title-icon">
        <svg-icon name="country" class="jump-icon"></svg-icon>
        Location of the proposed company
      </div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.country }}</div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title-icon">
        <svg-icon name="planAndServices" class="jump-icon"></svg-icon>
        Choice of service(s)
      </div>
      <div class="desp-items-wrapper">
        <div class="desp-item">
          <div class="desp-item-label">Promotion code</div>
          <div class="desp-item-content">{{ detail.promotionCode }}</div>
        </div>
      </div>
    </div>
    <!-- 前台详情页链接 -->
    <div class="desp-items-group">
      <div class="desp-items-group-title">Front user website</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span class="mr40 to-link" @click="userDetailLink('details')">
            View details
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
        </div>
      </div>
    </div>
    <!-- 前台收费协议页链接 -->
    <div class="desp-items-group">
      <div class="desp-items-group-title">Front user Fee Letter</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span class="mr40 to-link" @click="userDetailLink('feeLetter', 'en')">
            View Fee Letter(EN)
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
          <span class="to-link" @click="userDetailLink('feeLetter', 'zh')">
            View Fee Letter(CN)
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
        </div>
      </div>
    </div>
    <!-- 服务报价书 -->
    <div class="desp-items-group">
      <div class="desp-items-group-title">Service Quotation</div>
      <div class="pt10 desp-item">
        <div
          class="desp-item-self"
          v-if="
            (detail.applymentStatus === 'INQUIRING' || detail.applymentStatus === 'QUOTING' || detail.applymentStatus === 'QUOTATION_AUDIT_FAIL') &&
            isApplymentAuth('quotation:coordinate')
          "
        >
          <el-multiple-file-uploader v-model="feeLetterUrls" @input="moduleChange"></el-multiple-file-uploader>
        </div>
        <div class="desp-item-self" v-else>
          <p v-for="item in feeLetterUrls" :key="item.url" class="m0 lh22 pb10">
            <a :href="item.url" target="_blank" class="fs14 blue">{{ item.name }}</a>
          </p>
        </div>
      </div>
    </div>
    <!-- 前台copy from -->
    <div class="desp-items-group">
      <div class="desp-items-group-title">Linked Transaction No. (Copy from)</div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.excApplymentNo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          id: null,
          applymentNo: null,
          applymentStatus: "",
          applicantName: "empty",
          applicantPhone: "empty",
          applicantEmail: "empty",
          country: "none",
          feeLetterUrls: [],
        };
      },
    },
  },
  data() {
    return {
      feeLetterUrls: [],
    };
  },
  computed: {
    ...mapState("constant", ["applicantPositionOptions"]),
  },
  methods: {
    // 初始化
    init() {
      this.feeLetterUrls = JSON.parse(JSON.stringify(this.detail.feeLetterUrls)) || [];
    },
    // 申请单角色格式化
    applicantPositionFormat(v) {
      const one = this.applicantPositionOptions.find(i => i.value === v);
      return one?.title;
    },
    // 前台详情页链接
    userDetailLink(type, language) {
      this.$http.post(`/admin/accessManage/acquireAccessToken?businessNo=${this.detail.applymentNo}`, {}).then(({ code, data, msg }) => {
        if (code === 200) {
          let voucher = data && data.token ? encodeURIComponent(data.token) : null;
          let newpage = null;
          if (type == "details") {
            newpage = `${process.env.VUE_APP_URL_INVOICE}/enroll/details?id=${this.detail.id}&voucher=${voucher}`;
          } else {
            newpage = `${process.env.VUE_APP_URL_INVOICE}/feeLetter?id=${this.detail.id}&voucher=${voucher}&lang=${language}`;
          }
          window.open(newpage, "_blank");
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 服务报价书
    moduleChange() {
      this.$http
        .post("/admin/companyApplymentStatusManage/updateFiles", {
          id: this.detail.id,
          feeLetterUrls: this.feeLetterUrls,
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.$message.success("success");
          } else {
            this.$message.error(msg);
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variables.scss";
.jump-icon {
  vertical-align: middle;
  font-size: 20px;
  margin-right: 6px;
  margin-top: -4px;
}
.to-link {
  cursor: pointer;
  color: #{$--color-primary};
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  .opt-icon {
    vertical-align: middle;
    font-size: 16px;
    margin-top: -2px;
    margin-left: 2px;
  }
}

.amendsTable {
  .price {
    color: #ff7a37;
    font-size: 20px;
    font-weight: bolder;
  }
  tr:hover {
    background: #fff5f0 !important;
  }
  .el-table__body tr:hover > td {
    background-color: #fff5f0 !important;
  }
}
</style>
