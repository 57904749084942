<template>
  <div class="desp">
    <div class="desp-header">Confirmation:</div>
    <div class="desp-items-group">
      <div class="desp-items-group-title-icon">
        <svg-icon name="applicant" class="jump-icon"></svg-icon>
        Applicant information
      </div>
      <div class="desp-items-wrapper">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Applicant Name</div>
              <div class="desp-item-content">{{ detail.applicantName }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Mobile</div>
              <div class="desp-item-content">{{ detail.applicantPhone }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Email</div>
              <div class="desp-item-content">{{ detail.applicantEmail }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Residential Address</div>
              <div class="desp-item-content">{{ detail.residentialAddress }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="desp-item">
              <div class="desp-item-label">Applicant role</div>
              <template
                v-if="
                  (detail.applymentStatus === 'REVISING' ||
                    detail.applymentStatus === 'CORRECTED' ||
                    detail.applymentStatus === 'ILLEGAL' ||
                    detail.applymentStatus === 'AUDIT_FAIL' ||
                    detail.applymentStatus === 'REFUND_APPLY_DENY' ||
                    detail.applymentStatus === 'REFUND_APPLY_REJECT' ||
                    detail.applymentStatus === 'ACRA_REJECTED') &&
                  isApplymentAuth('company:coordinate')
                "
              >
                <el-select :value="applicantRole" placeholder="Please Select" class="wd320" @change="applicantPositionChange">
                  <el-option
                    v-for="item in applicantPositionOptions"
                    :key="`applicantPosition${item.value}`"
                    :label="item.title"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
              <div class="desp-item-content" v-else>{{ applicantPositionFormat(detail.applicantPosition) }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title-icon">
        <svg-icon name="country" class="jump-icon"></svg-icon>
        Location of the proposed company/fund
      </div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.country }}</div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title-icon">
        <svg-icon name="planAndServices" class="jump-icon"></svg-icon>
        Choice of service(s)
      </div>
      <div class="desp-items-wrapper">
        <div class="desp-item">
          <div class="desp-item-label">Entity type</div>
          <div class="desp-item-content">{{ entityTypeFormat(detail.businessGenre) }}</div>
        </div>
        <div class="desp-item">
          <div class="desp-item-label">Business type</div>
          <div class="desp-item-content">{{ businessTypeFormat(detail.businessType) }}</div>
        </div>
        <template v-if="detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType == 'FUND_ADMIN_CUSTOMIZED'">
          <div class="desp-item">
            <div class="desp-item-label">Promotion code</div>
            <div class="desp-item-content">{{ detail.promotionCode }}</div>
          </div>
        </template>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="mt10" v-if="detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType == 'FUND_ADMIN_CUSTOMIZED'">
            <!-- 定制化服务 -->
            <quotation-detail :quotationList="detail.quotationList" :applymentStatus="detail.applymentStatus" />
          </div>
          <div class="mt10" v-else>
            <!-- 公司设立、服务转移 -->
            <plan-detail :businessType="detail.businessType" :goodsList="detail.goodsList" />
          </div>
        </div>
      </div>
    </div>
    <!-- 补款单 -->
    <div class="desp-items-group" v-if="invoiceObj.amendsList.length != 0">
      <div class="desp-items-group-title">Supplemental</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="mt10">
            <el-table
              :header-cell-style="{ color: '#011A45', background: '#fff5f0 !important', height: '62px' }"
              :row-style="{ background: '#fff5f0' }"
              :data="invoiceObj.amendsList"
              class="amendsTable"
            >
              <el-table-column align="center" label="Amount">
                <template slot-scope="scope">
                  <span class="price">{{ scope.row.amendsDetail.amount | thousands }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="Third party fee">
                <template slot-scope="scope">
                  <span class="price">{{ scope.row.amendsDetail.thirdPartyFee | thousands }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="amendsDetail.remark" label="Description (For External Disclosure)"></el-table-column>

              <el-table-column align="center" label="Other Fee">
                <template slot-scope="scope">
                  <span class="price" v-if="scope.row.amendsDetail">{{ scope.row.amendsDetail.otherFee | thousands }}</span>
                  <el-popover placement="bottom" trigger="hover" v-if="scope.row.amendsDetail.otherRemark">
                    <span class="fs12">{{ scope.row.amendsDetail.otherRemark }}</span>
                    <span slot="reference" class="ml8 fs14 blackD8 el-icon-question"></span>
                  </el-popover>
                </template>
              </el-table-column>

              <el-table-column align="center" label="Fee Letter">
                <template slot-scope="scope">
                  <a
                    :href="scope.row.amendsDetail.feeLetter.url"
                    target="_blank"
                    class="fs12 blue mr10"
                    v-if="scope.row.amendsDetail && scope.row.amendsDetail.feeLetter"
                  >
                    {{ scope.row.amendsDetail.feeLetter.name }}
                  </a>
                </template>
              </el-table-column>
              <el-table-column align="center" label="Supplemental Invoice">
                <template slot-scope="scope">
                  <span class="to-link" @click="invoiceLink('amends', scope.row.invoiceNumber)">Invoice</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="orderStatus" label="Status" :formatter="statusFormat"></el-table-column>
              <el-table-column align="center" label="Action">
                <template slot-scope="scope">
                  <el-button
                    @click="closeHandler(scope.row.orderId)"
                    type="text"
                    class="fwb"
                    v-if="
                      scope.row.orderStatus == 'UNPAID' &&
                      (detail.applymentStatus == 'ACRA_APPLYING' || detail.applymentStatus == 'REVISING') &&
                      isApplymentAuth('company:coordinate')
                    "
                  >
                    Close
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 普通的发票 -->
    <div class="desp-items-group" v-if="detail.businessType == 'COMPANY_INCORPORATION' || detail.businessType == 'SERVICE_TRANSFER'">
      <div class="desp-items-group-title">Generic Invoice</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span class="mr40 to-link" @click="invoiceLink('unpaid')" v-if="detail.applymentStatus != 'DRAFT' && invoiceObj.unpaId">
            Invoice
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
          <span
            class="to-link"
            @click="invoiceLink('refund')"
            v-if="(detail.applymentStatus == 'REFUND_APPLY_PASS' || detail.applymentStatus == 'CANCELED') && invoiceObj.refundId"
          >
            Debit Invoice
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
        </div>
      </div>
    </div>
    <!-- 前台详情页链接 -->
    <div class="desp-items-group" v-if="isAuth('company:coordinate')">
      <div class="desp-items-group-title">Front user website</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span class="mr40 to-link" @click="userDetailLink('details')">
            View details
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
        </div>
      </div>
    </div>
    <!-- 前台收费协议页链接 -->
    <div
      class="desp-items-group"
      v-if="isAuth('company:coordinate') && (detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType == 'FUND_ADMIN_CUSTOMIZED')"
    >
      <div class="desp-items-group-title">Front user Fee Letter</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span class="mr40 to-link" @click="userDetailLink('feeLetter', 'en')">
            View Fee Letter(EN)
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
          <span class="to-link" @click="userDetailLink('feeLetter', 'zh')">
            View Fee Letter(CN)
            <svg-icon name="jump" class="opt-icon"></svg-icon>
          </span>
        </div>
      </div>
    </div>
    <!-- 服务报价书 -->
    <div
      class="desp-items-group"
      v-if="
        isAuth('company:coordinate') &&
        (detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType == 'FUND_ADMIN_CUSTOMIZED') &&
        detail.feeLetterUrls &&
        detail.feeLetterUrls.length > 0
      "
    >
      <div class="desp-items-group-title">Service Quotation</div>
      <div class="pt10 desp-item">
        <div class="desp-item-self">
          <p v-for="item in detail.feeLetterUrls" :key="item.url" class="m0 lh22 pb10">
            <a :href="item.url" target="_blank" class="fs14 blue">{{ item.name }}</a>
          </p>
        </div>
      </div>
    </div>
    <!-- 续约申请单号码 -->
    <div class="desp-items-group" v-if="isAuth('company:coordinate') && detail.hasRenew">
      <div class="desp-items-group-title">Linked Transaction No. (Renewal)</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span>
            {{ detail.linkedApplymentNo }}
            {{ detail.linkedApplyment && detail.linkedApplyment.applymentStatus ? applymentStatusFormat(detail.linkedApplyment.applymentStatus) : "" }}
          </span>
        </div>
      </div>
    </div>
    <!-- 增加服务申请单号码 -->
    <div class="desp-items-group" v-if="isAuth('company:coordinate') && detail.hasLaterService">
      <div class="desp-items-group-title">Linked Transaction No. (Supplemental)</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span class="mr50" v-for="item in detail.laterServiceApplymentList" :key="item.id">
            {{ item.applymentNo }} {{ applymentStatusFormat(item.applymentStatus) }}
          </span>
        </div>
      </div>
    </div>
    <!-- 旧申请单号 -->
    <div class="desp-items-group" v-if="isAuth('company:coordinate') && detail.excApplymentNo">
      <div class="desp-items-group-title">
        Linked Transaction No.
        <span v-if="detail.isRenew">(Be Renewed)</span>
        <span v-if="detail.isLaterService">(Have Supplements)</span>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <span>{{ detail.excApplymentNo }}</span>
        </div>
      </div>
    </div>
    <!-- Quotation (V) -->
    <div class="desp-items-group" v-if="quotationVList.length != 0">
      <div class="desp-items-group-title">Quotation (V)</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="mt10">
            <el-table
              :header-cell-style="{ color: '#011A45', background: '#fff5f0 !important', height: '62px' }"
              :row-style="{ background: '#fff5f0' }"
              :data="quotationVList"
              class="amendsTable"
            >
              <el-table-column prop="supplement.supplementNo" header-align="center" align="center" label="Quotation (V) No."></el-table-column>
              <el-table-column prop="supplement.billingParty" header-align="center" align="center" label="Billing Party"></el-table-column>
              <el-table-column header-align="center" align="center" label="Exchange Rate" min-width="80">
                <template slot-scope="scope">
                  <template v-if="scope.row.supplement && scope.row.supplement.paymentDetail">
                    <p v-if="scope.row.supplement.paymentDetail.currency != 'SGD'">{{ scope.row.supplement.paymentDetail.exchangeRate }}</p>
                  </template>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" label="Amount">
                <template slot-scope="scope">
                  <span class="price" v-if="scope.row.supplement && scope.row.supplement.paymentDetail">
                    {{ scope.row.supplement.paymentDetail.amount | thousands(scope.row.supplement.paymentDetail.currencyUnit) }}
                  </span>
                  <el-popover placement="bottom" trigger="hover" v-if="scope.row.supplement.applyReason">
                    <span class="fs12">{{ scope.row.supplement.applyReason }}</span>
                    <span slot="reference" class="fs14 blackD8 el-icon-question"></span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" label="Third party fee">
                <template slot-scope="scope">
                  <span class="price" v-if="scope.row.supplement && scope.row.supplement.paymentDetail">
                    {{ scope.row.supplement.paymentDetail.thirdPartyFee | thousands(scope.row.supplement.paymentDetail.currencyUnit) }}
                  </span>
                  <el-popover placement="bottom" trigger="hover" v-if="scope.row.supplement.noteForThirdParty">
                    <span class="fs12">{{ scope.row.supplement.noteForThirdParty }}</span>
                    <span slot="reference" class="fs14 blackD8 el-icon-question"></span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" label="Other Fee">
                <template slot-scope="scope">
                  <span class="price" v-if="scope.row.supplement && scope.row.supplement.paymentDetail">
                    {{ scope.row.supplement.paymentDetail.otherFee | thousands(scope.row.supplement.paymentDetail.currencyUnit) }}
                  </span>
                  <el-popover placement="bottom" trigger="hover" v-if="scope.row.supplement.noteForOther">
                    <span class="fs12">{{ scope.row.supplement.noteForOther }}</span>
                    <span slot="reference" class="fs14 blackD8 el-icon-question"></span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" label="Attachment" min-width="96">
                <template slot-scope="scope">
                  <template v-if="scope.row.supplement">
                    <p v-for="item in scope.row.supplement.attachmentUrls" :key="item.url">
                      <a :href="item.url" target="_target">{{ item.name }}</a>
                    </p>
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="supplement.createTime" header-align="center" align="center" label="Create Time"></el-table-column>
              <el-table-column align="center" label="Generic Invoice">
                <template slot-scope="scope">
                  <span class="to-link" @click="invoiceLink('quotationV', scope.row.orderNo)">Invoice</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="supplement.status"
                header-align="center"
                align="center"
                label="Status"
                :formatter="supplementStatusFormat"
              ></el-table-column>
              <el-table-column
                prop="status"
                header-align="center"
                align="center"
                width="120"
                label="Order Status"
                :formatter="statusFormat"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fmoney } from "@/utils";
import { mapActions, mapState } from "vuex";
import PlanDetail from "@/components/PlanDetail";
import QuotationDetail from "@/components/QuotationDetail";
export default {
  components: {
    PlanDetail,
    QuotationDetail,
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          id: null,
          applymentNo: null,
          applymentStatus: "",
          applicantName: "empty",
          applicantPhone: "empty",
          applicantEmail: "empty",
          country: "none",
          goodsList: [],
          excApplymentNo: null,
          linkedApplymentNo: null,
          laterServiceApplymentList: [],
          applicantPosition: null,
        };
      },
    },
    invoiceObj: {
      type: Object,
      default: function () {
        return {
          unpaId: null,
          refundId: null,
          amendsList: [],
        };
      },
    },
    quotationVList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      dataListLoading: false,
      applicantRole: null,
    };
  },
  watch: {
    "detail.applicantPosition"(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.applicantRole = newVal;
    },
  },
  filters: {
    thousands(val, symbol = "S$") {
      if (val || val == 0) {
        return `${symbol}${fmoney(val, 2)}`;
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapState("constant", [
      "applymentStatusList",
      "orderStatusList",
      "applicantPositionOptions",
      "entityTypes",
      "businessTypes",
      "supplementStatusList",
    ]),
  },
  methods: {
    ...mapActions("applyment", ["doCloseAmends", "doSaveApplicantRole"]),
    //申请单状态格式化
    applymentStatusFormat(v) {
      const one = this.applymentStatusList.find(i => i.value === v);
      return one && one.title ? `(${one.title})` : "";
    },
    // 格式化
    statusFormat(row, column, cellValue) {
      const one = this.orderStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    // 申请单角色格式化
    applicantPositionFormat(v) {
      const one = this.applicantPositionOptions.find(i => i.value === v);
      return one?.title;
    },
    // 主体业务格式化
    entityTypeFormat(v) {
      const one = this.entityTypes.find(i => i.value === v);
      return one?.title;
    },
    // 业务类型格式化
    businessTypeFormat(v) {
      const one = this.businessTypes.find(i => i.value === v);
      return one?.title;
    },
    // 状态格式化
    supplementStatusFormat(row, column, cellValue) {
      const one = this.supplementStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    // 关闭补款单 - 业务
    async closeHandler(orderId) {
      await this.$confirm("Confirm closing order?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      this.doCloseAmends({
        orderId,
      })
        .then(() => {
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.submitControl = true;
              // 更新发票列表
              this.$emit("refreshInvoiceList");
            },
          });
        })
        .catch(msg => {
          this.$message.error(msg);
          this.submitControl = true;
        });
    },
    // 发票跳转
    invoiceLink(type, invoiceNumber) {
      let idRes = null;
      if (type == "unpaid") {
        idRes = this.invoiceObj.unpaId;
      } else if (type == "refund") {
        idRes = this.invoiceObj.refundId;
      } else if (type == "amends") {
        idRes = invoiceNumber;
      } else if (type == "quotationV") {
        idRes = invoiceNumber;
      }
      let newpage = `${process.env.VUE_APP_URL_INVOICE}/${type}?invoiceNo=${idRes}&lang=en`;
      window.open(newpage, "_blank");
    },
    // 前台详情页链接
    userDetailLink(type, language) {
      this.$http.post(`/admin/accessManage/acquireAccessToken?businessNo=${this.detail.applymentNo}`, {}).then(({ code, data, msg }) => {
        if (code === 200) {
          let voucher = data && data.token ? encodeURIComponent(data.token) : null;
          let newpage = null;
          if (type == "details") {
            newpage = `${process.env.VUE_APP_URL_INVOICE}/enroll/details?id=${this.detail.id}&voucher=${voucher}`;
          } else {
            newpage = `${process.env.VUE_APP_URL_INVOICE}/feeLetter?id=${this.detail.id}&voucher=${voucher}&lang=${language}`;
          }
          window.open(newpage, "_blank");
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 申请人角色
    applicantPositionChange(val) {
      this.$confirm("Confirm change of applicant role?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.doSaveApplicantRole(val)
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.applicantRole = val;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
            });
        })
        .catch(action => {
          this.applicantRole = this.detail.applicantPosition;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variables.scss";

.blackD8 {
  color: #bbc6d8;
}

.jump-icon {
  vertical-align: middle;
  font-size: 20px;
  margin-right: 6px;
  margin-top: -4px;
}
.to-link {
  cursor: pointer;
  color: #{$--color-primary};
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  .opt-icon {
    vertical-align: middle;
    font-size: 16px;
    margin-top: -2px;
    margin-left: 2px;
  }
}

.amendsTable {
  .price {
    color: #ff7a37;
    font-size: 16px;
    font-weight: bolder;
  }
  tr:hover {
    background: #fff5f0 !important;
  }
  .el-table__body tr:hover > td {
    background-color: #fff5f0 !important;
  }
}
</style>
