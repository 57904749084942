var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("KYC Questionnaire")]), _c('div', {
    staticClass: "opts"
  }, [(_vm.applymentStatus === 'REVISING' || _vm.applymentStatus === 'CORRECTED' || _vm.applymentStatus === 'ILLEGAL' || _vm.applymentStatus === 'AUDIT_FAIL' || _vm.applymentStatus === 'REFUND_APPLY_DENY' || _vm.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.applymentStatus === 'ACRA_REJECTED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    on: {
      "click": _vm.openEdit
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1)]), _c('el-form', {
    staticClass: "no-event",
    attrs: {
      "model": _vm.questionnaire,
      "label-position": "top"
    }
  }, [_vm._l(_vm.quJSON, function (data, idx) {
    return _c('div', {
      key: `questionnaire${idx}${data.title}`
    }, [_c('el-form-item', {
      attrs: {
        "label": idx + 1 + '. ' + data.title,
        "prop": data.model
      }
    }, [data.type === 'textarea' ? _c('el-row', [_c('el-col', {
      attrs: {
        "lg": 11,
        "xl": 8
      }
    }, [_c('el-input', {
      attrs: {
        "readonly": "",
        "value": _vm.questionnaire[data.model],
        "type": "textarea",
        "autosize": {
          minRows: 5
        },
        "resize": "none"
      }
    })], 1)], 1) : _vm._e(), data.type === 'input' ? _c('el-row', [_c('el-col', {
      attrs: {
        "lg": 11,
        "xl": 8
      }
    }, [_c('el-input', {
      attrs: {
        "readonly": "",
        "value": _vm.questionnaire[data.model],
        "type": "input"
      }
    })], 1)], 1) : _vm._e(), data.type === 'multiplebox' ? _c('multiple-box', {
      attrs: {
        "options": data.options,
        "value": _vm.questionnaire[data.model],
        "readonly": ""
      }
    }) : _vm._e(), data.type === 'radio' ? _c('el-radio-group', {
      attrs: {
        "value": _vm.questionnaire[data.model]
      }
    }, _vm._l(data.options, function (item) {
      return _c('el-radio', {
        key: `radio${item.value}`,
        attrs: {
          "label": item.value
        }
      }, [_vm._v(_vm._s(item.title))]);
    }), 1) : _vm._e(), _vm.questionnaire[data.model] && data.type === 'radio-input' ? _c('el-radio-group', {
      staticClass: "flex align-center",
      attrs: {
        "value": _vm.questionnaire[data.model][data.secondary]
      }
    }, _vm._l(data.options, function (item) {
      return _c('el-radio', {
        key: `radio-input${item.value}`,
        staticClass: "flex align-center",
        attrs: {
          "label": item.value
        }
      }, [_c('div', {
        staticClass: "flex align-center"
      }, [_vm._v(" " + _vm._s(item.title) + " "), item.other && item.other.type == 'input' && _vm.questionnaire[data.model][data.secondary] == item.value ? _c('div', {
        staticClass: "ml8"
      }, [_c('el-input', {
        attrs: {
          "readonly": "",
          "value": _vm.questionnaire[data.model][item.other.model],
          "type": "input"
        }
      })], 1) : _vm._e()])]);
    }), 1) : _vm._e(), _c('el-row', [_c('el-col', {
      attrs: {
        "lg": 10,
        "xl": 8
      }
    }, [data.type === 'country' ? _c('el-select', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "multiple": "",
        "value": _vm.questionnaire[data.model]
      }
    }, [_vm._l(_vm.nationalityList, function (item) {
      return _c('el-option', {
        key: `country${item.value}`,
        attrs: {
          "label": item.title,
          "value": item.value
        }
      }, [_vm._v(" " + _vm._s(item.title) + " ")]);
    }), _vm.businessGenre == 'COMPANY' ? _c('el-option', {
      attrs: {
        "label": "N/A",
        "value": "NONE"
      }
    }, [_vm._v("N/A")]) : _vm._e()], 2) : _vm._e()], 1)], 1), data.type === 'table' ? _c('div', _vm._l(_vm.questionnaire[data.model], function (detail, sy) {
      return _c('div', {
        key: `table${sy}${detail.fullName}`,
        staticClass: "pt20 pb20 shareholder-wrap"
      }, [_c('div', {
        staticClass: "position-held"
      }, [_vm._v(_vm._s(_vm.shareholdersFormat(detail.memberType)))]), _c('div', {
        staticClass: "base-info mt6"
      }, [_c('el-descriptions', {
        attrs: {
          "labelStyle": {
            color: '#667288',
            background: '#ECEFF9'
          },
          "contentStyle": {
            background: '#ECEFF9'
          },
          "column": 3,
          "direction": "vertical"
        }
      }, [detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Name (same as business certificate)"
        }
      }, [_vm._v(" " + _vm._s(detail.fullName) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Name (same as IC)"
        }
      }, [_vm._v(_vm._s(detail.fullName))]) : _vm._e(), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Country"
        }
      }, [_vm._v(_vm._s(detail.nationality))]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Nationality"
        }
      }, [_vm._v(_vm._s(detail.nationality))]) : _vm._e(), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Business No."
        }
      }, [_vm._v(" " + _vm._s(detail.businessLicenseNo) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Identification Type"
        }
      }, [_vm._v(" " + _vm._s(detail.identificationType) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Identification No."
        }
      }, [_vm._v(" " + _vm._s(detail.identificationNo) + " ")]) : _vm._e(), _c('el-descriptions-item', {
        attrs: {
          "label": "Mobile"
        }
      }, [_vm._v(_vm._s(detail.phone))]), _c('el-descriptions-item', {
        attrs: {
          "label": "Email"
        }
      }, [_vm._v(_vm._s(detail.email))]), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Registered Address"
        }
      }, [_vm._v(" " + _vm._s(detail.registeredOfficeAddress) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Residential address"
        }
      }, [_vm._v(" " + _vm._s(detail.residentialAddress) + " ")]) : _vm._e(), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Operation Address"
        }
      }, [_vm._v(" " + _vm._s(detail.operationAddress) + " ")]) : _vm._e(), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Incorporation Date"
        }
      }, [_vm._v(" " + _vm._s(detail.incorporationDate) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
        attrs: {
          "label": "Birth Date"
        }
      }, [_vm._v(" " + _vm._s(detail.birthDate) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? [_c('el-descriptions-item', {
        attrs: {
          "label": "Does the individual hold share interest of any entity in PRC inland?"
        }
      }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(detail.isHoldShareOfPrcInland)) + " ")]), detail.isHoldShareOfPrcInland ? _c('el-descriptions-item', {
        attrs: {
          "label": "Please provide the name of RPC entity (1 entity is required)"
        }
      }, [_vm._v(" " + _vm._s(detail.holdShareOfPrcEntity) + " ")]) : _vm._e()] : _vm._e()], 2)], 1), detail.memberType === 'CORPORATE' ? [detail.representativeInfo ? _c('div', {
        staticClass: "mt25"
      }, [_c('div', {
        staticClass: "position-held"
      }, [_vm._v("Representative")]), _c('div', {
        staticClass: "base-info mt20"
      }, [_c('el-descriptions', {
        attrs: {
          "labelStyle": {
            color: '#667288',
            background: '#ECEFF9'
          },
          "contentStyle": {
            background: '#ECEFF9'
          },
          "column": 3,
          "direction": "vertical"
        }
      }, [_c('el-descriptions-item', {
        attrs: {
          "label": "Name (same as IC)"
        }
      }, [_vm._v(" " + _vm._s(detail.representativeInfo.fullName) + " ")]), _c('el-descriptions-item', {
        attrs: {
          "label": "Nationality"
        }
      }, [_vm._v(_vm._s(detail.representativeInfo.nationality))]), _c('el-descriptions-item', {
        attrs: {
          "label": "Identification Type"
        }
      }, [_vm._v(" " + _vm._s(detail.representativeInfo.identificationType) + " ")]), _c('el-descriptions-item', {
        attrs: {
          "label": "Identification No."
        }
      }, [_vm._v(" " + _vm._s(detail.representativeInfo.identificationNo) + " ")]), _c('el-descriptions-item', {
        attrs: {
          "label": "Mobile"
        }
      }, [_vm._v(_vm._s(detail.representativeInfo.phone))]), _c('el-descriptions-item', {
        attrs: {
          "label": "Email"
        }
      }, [_vm._v(_vm._s(detail.representativeInfo.email))]), _c('el-descriptions-item', {
        attrs: {
          "label": "Residential address"
        }
      }, [_vm._v(" " + _vm._s(detail.representativeInfo.residentialAddress) + " ")]), _c('el-descriptions-item', {
        attrs: {
          "label": "Birth Date"
        }
      }, [_vm._v(" " + _vm._s(detail.representativeInfo.birthDate) + " ")])], 1)], 1)]) : _vm._e()] : _vm._e()], 2);
    }), 0) : _vm._e(), data.type === 'tableList' ? _c('div', [_c('el-table', {
      key: Math.random(),
      attrs: {
        "data": _vm.fundInfoList,
        "border": ""
      }
    }, [_c('el-table-column', {
      attrs: {
        "prop": "fundName",
        "label": "Proposed Fund Name",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "fundType",
        "label": "Type",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "fundRegistrationCountry",
        "label": "Registration Country",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "fundRegistration",
        "label": "Registration address",
        "align": "center"
      }
    })], 1)], 1) : _vm._e()], 1)], 1);
  }), _vm.businessType == 'SERVICE_TRANSFER' ? _c('div', [_c('el-form-item', {
    attrs: {
      "label": `${_vm.quJSON.length + 1}. Registered Office`
    }
  }, [_c('el-radio-group', {
    attrs: {
      "value": _vm.registeredOfficeType
    }
  }, _vm._l(_vm.registerOfficeTypeList, function (item) {
    return _c('div', {
      key: `Registered${item.value}`
    }, [_c('el-radio', {
      staticClass: "pt15 pb15",
      attrs: {
        "label": item.value
      }
    }, [_c('span', [_vm._v(_vm._s(item.title))]), item.value === 1 && _vm.registeredOfficeType === 1 ? _c('div', {
      staticClass: "mt15 ml24"
    }, [_c('el-radio-group', {
      attrs: {
        "value": _vm.registeredOfficeId
      }
    }, _vm._l(_vm.nativeRegisteredAddress, function (office) {
      return _c('el-radio', {
        key: `Office${office.id}`,
        attrs: {
          "label": office.id
        }
      }, [_vm._v(" " + _vm._s(_vm.formatNativeAddress(office)) + " ")]);
    }), 1)], 1) : _vm._e(), item.value === 2 && _vm.registeredOfficeType === 2 ? _c('div', {
      staticClass: "mt10 ml24"
    }, [_vm._v(" " + _vm._s(_vm.formatCustomAddress()) + " ")]) : _vm._e()])], 1);
  }), 0)], 1)], 1) : _vm._e(), _vm.businessType == 'SERVICE_TRANSFER' && _vm.isNeedNomineeDirector || _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('div', {
    staticClass: "no-event desp-items-group",
    attrs: {
      "id": "engageNomineeDirectorCheckbox"
    }
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Nominee Director Service")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('el-checkbox', {
    staticClass: "checkboxCustom",
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.hasNomineeDirector,
      callback: function ($$v) {
        _vm.hasNomineeDirector = $$v;
      },
      expression: "hasNomineeDirector"
    }
  }, [_vm._v(" We consent to appoint one Nominee Director recommended by EStar Business Services Pte. Ltd. (\"EStar\"), and we understand that any legal impact caused by the Nominee Director's action has nothing to do with EStar. ")])], 1)])]) : _vm._e()], 2), _vm.editVisible ? _c('edit-modal', {
    ref: "editModal",
    attrs: {
      "applymentId": _vm.applymentId,
      "quJSON": _vm.quJSON,
      "businessType": _vm.businessType,
      "businessGenre": _vm.businessGenre,
      "registerOfficeTypeList": _vm.registerOfficeTypeList,
      "nativeRegisteredAddress": _vm.nativeRegisteredAddress,
      "isNeedNomineeDirector": _vm.isNeedNomineeDirector
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }