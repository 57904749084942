<template>
  <el-dialog width="1200px" title="Associated Record" :close-on-click-modal="false" :visible.sync="visible">
    <div>
      <el-table border :data="dataList" v-loading="dataListLoading" style="width: 100%">
        <el-table-column label="Linked Transaction No." prop="linkedApplymentNo" align="center"></el-table-column>
        <el-table-column label="Company" prop="companyName" align="center"></el-table-column>
        <el-table-column label="Submit Time" prop="commitTime" align="center"></el-table-column>
        <el-table-column label="Time of application completion" prop="finishTime" align="center"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataList: [],
      current: 1,
      size: 10,
      total: 0,
      dataListLoading: false,
      id: null,
    };
  },
  methods: {
    async init(id) {
      this.id = id;
      this.visible = true;
      await this.$nextTick();
      this.getDataList(1);
    },
    // 每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 获取分页
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const { code, data } = await this.$http.get("/admin/stNomineeDirectorManage/getApplyInfoPage", {
        params: {
          current: this.current,
          size: this.size,
          id: this.id,
        },
      });
      if (code === 200) {
        this.dataList = data.records;
        this.total = data.total;
      } else {
        this.dataList = [];
        this.total = 0;
      }
      this.dataListLoading = false;
    },
  },
};
</script>
