<template>
  <div class="desp mt40">
    <div class="desp-header">
      <div class="label">Extra Information</div>
      <div class="opts">
        <el-button
          v-if="
            (detail.applymentStatus === 'REVISING' ||
              detail.applymentStatus === 'CORRECTED' ||
              detail.applymentStatus === 'ILLEGAL' ||
              detail.applymentStatus === 'AUDIT_FAIL' ||
              detail.applymentStatus === 'REFUND_APPLY_DENY' ||
              detail.applymentStatus === 'REFUND_APPLY_REJECT' ||
              detail.applymentStatus === 'ACRA_REJECTED') &&
            isApplymentAuth('company:coordinate')
          "
          @click="openEdit()"
          ref=""
        >
          Edit
        </el-button>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Registered Office</div>
      <div class="desp-item">
        <div class="desp-item-self no-event">
          <el-radio-group :value="detail.registeredOfficeType">
            <div v-for="item in registerOfficeTypeList" :key="item.value">
              <el-radio class="pt15 pb15" :label="item.value">
                <span>{{ item.title }}</span>
                <div class="mt15 ml24" v-if="item.value === 1 && detail.registeredOfficeType === 1">
                  <el-radio-group :value="detail.registeredOfficeId">
                    <el-radio v-for="office in nativeRegisteredAddress" :key="office.id" :label="office.id">{{ formatNativeAddress(office) }}</el-radio>
                  </el-radio-group>
                </div>
                <div class="mt10 ml24" v-if="item.value === 2 && detail.registeredOfficeType === 2">
                  {{ formatCustomAddress(detail) }}
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Working hours</div>
      <div class="desp-item">
        <div class="desp-item-self no-event">
          <el-row>
            <el-col :span="16">
              <el-select style="width: 100%" :value="detail.workingHours">
                <el-option v-for="item in workingHoursOptions" :key="item.value" :label="item.title" :value="item.value"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Constitution</div>
      <div class="desp-item">
        <div class="desp-item-self no-event">
          <el-row>
            <el-col :span="16">
              <el-select style="width: 100%" :value="detail.isCustomizedConstitution">
                <el-option v-for="item in customizedConstitutionOptions" :key="item.value" :label="item.title" :value="item.value"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Is the company's business special and needs to obtain approval(s) from specific government authorities?</div>
      <div class="desp-item">
        <div class="desp-item-self no-event">
          <el-radio-group v-model="detail.hasObtainedApproval">
            <el-radio v-for="item in checkboxOptions" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
          </el-radio-group>
          <el-checkbox-group v-if="detail.hasObtainedApproval === 1" :value="detail.approvalAuthorities || []">
            <el-checkbox v-for="item in authoritiesOptions" :key="item.value" :label="item.title" :value="item.value"></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <!-- EP签证信息 -->
    <div class="desp-items-group" v-if="isHasEPApplicant">
      <div class="desp-items-group-title">EP Info</div>
      <div class="desp-item">
        <template v-if="detail.epInfo">
          <div v-for="(detail, sy) in detail.epInfo" :key="`epInfo${sy}${detail.fullName}`" class="pt20 pb20 shareholder-wrap">
            <div class="base-info">
              <el-descriptions
                :labelStyle="{ color: '#667288', background: '#ECEFF9' }"
                :contentStyle="{ background: '#ECEFF9' }"
                :column="3"
                direction="vertical"
              >
                <el-descriptions-item label="Name (same as IC)">{{ detail.fullName }}</el-descriptions-item>
                <el-descriptions-item label="Nationality">{{ detail.nationality }}</el-descriptions-item>
                <el-descriptions-item label="Identification Type">
                  {{ detail.identificationType }}
                </el-descriptions-item>
                <el-descriptions-item label="Identification No.">
                  {{ detail.identificationNo }}
                </el-descriptions-item>
                <el-descriptions-item label="Mobile">{{ detail.phone }}</el-descriptions-item>
                <el-descriptions-item label="Email">{{ detail.email }}</el-descriptions-item>
                <el-descriptions-item label="Residential address">
                  {{ detail.residentialAddress }}
                </el-descriptions-item>
                <el-descriptions-item label="Birth Date">
                  {{ detail.birthDate }}
                </el-descriptions-item>
                <template>
                  <el-descriptions-item label="Does the individual hold share interest of any entity in PRC inland?">
                    {{ getBooleanFormat(detail.isHoldShareOfPrcInland) }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Please provide the name of RPC entity (1 entity is required)" v-if="detail.isHoldShareOfPrcInland">
                    {{ detail.holdShareOfPrcEntity }}
                  </el-descriptions-item>
                </template>
              </el-descriptions>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 弹窗 -->
    <edit-modal
      v-if="editVisible"
      ref="editModal"
      :checkboxOptions="checkboxOptions"
      :workingHoursOptions="workingHoursOptions"
      :customizedConstitutionOptions="customizedConstitutionOptions"
      :authoritiesOptions="authoritiesOptions"
      :nativeRegisteredAddress="nativeRegisteredAddress"
      :registerOfficeTypeList="registerOfficeTypeList"
      :businessType="detail.businessType"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditModal from "./modal/Edit";
export default {
  components: {
    EditModal,
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          registeredOfficeType: 1,
          registeredOfficeId: "",
          registeredOfficeAddress: "",
          registeredOfficeCity: "",
          registeredOfficeCountry: "",
          registeredOfficePostalCode: "",
          workingHours: "",
          hasObtainedApproval: 0,
          approvalAuthorities: [],
          businessType: "",
          businessGenre: "",
          goodsList: [],
          epInfo: [],
        };
      },
    },
    nativeRegisteredAddress: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      dataListLoading: false,
      editVisible: false,
      checkboxOptions: [
        {
          title: "Yes",
          value: 1,
        },
        {
          title: "Uncertain, need EStar's assistance",
          value: 0,
        },
      ],
      registerOfficeTypeList: [
        {
          title: `Consent to use EStar Business Services Pte. Ltd. ("EStar")'s registration address`,
          value: 1,
        },
        {
          title: "Use other registration address",
          value: 2,
        },
      ],
    };
  },
  computed: {
    ...mapState("constant", ["workingHoursOptions", "customizedConstitutionOptions", "authoritiesOptions"]),
    // 判断套餐和服务是否包含EP申请
    isHasEPApplicant() {
      let resBool = false;
      let goodsListCopy = this.detail?.goodsList || [];
      for (let a in goodsListCopy) {
        if (this.detail.goodsListType == 1) {
          let moduleListCopy = goodsListCopy[a].planDetail.moduleList;
          for (let b in moduleListCopy) {
            if (moduleListCopy[b].moduleCode == "SG-EP01") {
              resBool = true;
              break;
            }
          }
        } else {
          if (goodsListCopy[a].moduleDetail.moduleCode == "SG-EP01") {
            resBool = true;
            break;
          }
        }
      }
      return resBool;
    },
  },
  methods: {
    getBooleanFormat(v) {
      if (v == 1) {
        return "Yes";
      } else if (v == 0) {
        return "No";
      } else {
        return null;
      }
    },
    formatNativeAddress(office) {
      return office.address + " ," + office.city + " ," + office.country + " ," + office.postalCode;
    },
    formatCustomAddress(office) {
      return (
        office.registeredOfficeAddress +
        " ," +
        office.registeredOfficeCity +
        " ," +
        office.registeredOfficeCountry +
        " ," +
        office.registeredOfficePostalCode
      );
    },
    async openEdit() {
      this.editVisible = true;
      await this.$nextTick();
      this.$refs.editModal.init(this.detail);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-info {
  padding: 20px 30px;
  background-color: #eceff9;
  border-radius: 4px;
}
</style>
