var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-row', {
    attrs: {
      "gutter": 26
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "pd26 bg-white border-radius-10"
  }, [_c('h3', {
    staticClass: "home-title"
  }, [_vm._v("[Finance] Processing Time")]), _c('h5', {
    staticClass: "mb20 hg40 lh16 home-subhead"
  }, [_vm._v("(Order Commit -> Receipt Check)")]), _vm._l(_vm.businessTypes, function (item) {
    return _c('div', {
      key: 'toCompleteTime' + item.title,
      staticClass: "flex mb40"
    }, [_c('div', {
      staticClass: "flex justify-center flex-direction align-start"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/img/home/toComplete.png"),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "ml25 flex justify-center flex-direction align-start"
    }, [_c('h3', {
      staticClass: "home-title"
    }, [_vm._v(" " + _vm._s(_vm.toCompleteTime[item.value]) + " "), _vm.toCompleteTime[item.value] != '' ? _c('span', {
      staticClass: "fs16"
    }, [_vm._v("Days")]) : _vm._e()]), _c('p', {
      staticClass: "mt8 fs14 lh16 text-bold home-introduce aurantia"
    }, [_vm._v(_vm._s(item.title))]), _c('p', {
      staticClass: "mt6 fs14 lh16 home-introduce"
    }, [_vm._v("Average over preceding 3 months")])])]);
  })], 2)]), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "pd26 bg-white border-radius-10"
  }, [_c('h3', {
    staticClass: "home-title"
  }, [_vm._v("[Operation] Processing Time")]), _c('h5', {
    staticClass: "mb20 hg40 lh16 home-subhead"
  }, [_vm._v("(Order Submit (i.e. Receipt Confirmed)-> Order Complete)")]), _vm._l(_vm.businessTypes, function (item) {
    return _c('div', {
      key: 'toFinishTime' + item.title,
      staticClass: "flex mb40"
    }, [_c('div', {
      staticClass: "flex justify-center flex-direction align-start"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/img/home/toFinish.png"),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "ml25 flex justify-center flex-direction align-start"
    }, [_c('h3', {
      staticClass: "home-title"
    }, [_vm._v(" " + _vm._s(_vm.toFinishTime[item.value]) + " "), _vm.toFinishTime[item.value] != '' ? _c('span', {
      staticClass: "fs16"
    }, [_vm._v("Days")]) : _vm._e()]), _c('p', {
      staticClass: "mt8 fs14 lh16 text-bold home-introduce aurantia"
    }, [_vm._v(_vm._s(item.title))]), _c('p', {
      staticClass: "mt6 fs14 lh16 home-introduce"
    }, [_vm._v("Average over preceding 3 months")])])]);
  })], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }