var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "780px",
      "title": !_vm.engagementId ? 'Add Client' : 'Edit Client',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-width": "220px"
    }
  }, [_c('el-form-item', {
    ref: "clientCode",
    staticClass: "is-required",
    attrs: {
      "label": "Client Code",
      "prop": "clientCode",
      "rules": {
        required: true,
        message: 'please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticClass: "wd440",
    attrs: {
      "placeholder": "Client code",
      "clearable": "",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.getClientCodeRemote,
      "loading": _vm.clientCodeLoading
    },
    on: {
      "clear": function ($event) {
        return _vm.getClientCodeRemote('');
      },
      "focus": function ($event) {
        return _vm.getClientCodeRemote('');
      },
      "change": _vm.clientCodeChange
    },
    model: {
      value: _vm.dataForm.clientCode,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "clientCode", $$v);
      },
      expression: "dataForm.clientCode"
    }
  }, _vm._l(_vm.clientCodeList, function (item, sy) {
    return _c('el-option', {
      key: `${item.clientCode}${sy}`,
      attrs: {
        "label": item.clientCode,
        "value": item.clientCode
      }
    });
  }), 1), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('span', {
    staticClass: "fs12"
  }, [_vm._v("Data Source: \"Client Code\" section of this system")]), _c('span', {
    staticClass: "pearl-blue fs16 ml4 el-icon-warning",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1), _c('el-form-item', {
    ref: "uen",
    staticClass: "is-required",
    attrs: {
      "label": "UEN",
      "prop": "uen",
      "rules": {
        required: true,
        message: 'please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticClass: "wd440",
    attrs: {
      "placeholder": "UEN",
      "filterable": ""
    },
    on: {
      "change": _vm.uenChange
    },
    model: {
      value: _vm.dataForm.uen,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "uen", $$v);
      },
      expression: "dataForm.uen"
    }
  }, _vm._l(_vm.atUenList, function (item, xb) {
    return _c('el-option', {
      key: `${item.uen}${xb}`,
      attrs: {
        "label": `${item.uen}-${item.companyName}`,
        "value": item.uen
      }
    });
  }), 1)], 1), _c('el-form-item', {
    ref: "companyName",
    attrs: {
      "label": "Entity Name",
      "prop": "companyName",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd440",
    attrs: {
      "placeholder": "Entity Name",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "companyName", $$v);
      },
      expression: "dataForm.companyName"
    }
  })], 1), _c('el-form-item', {
    ref: "companyCountry",
    attrs: {
      "label": "Entity Country",
      "prop": "companyCountry",
      "rules": {
        required: true,
        message: 'please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticClass: "wd440",
    attrs: {
      "placeholder": "Select Entity Country",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.companyCountry,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "companyCountry", $$v);
      },
      expression: "dataForm.companyCountry"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    ref: "companyType",
    attrs: {
      "label": "Entity Type",
      "prop": "companyType",
      "rules": {
        required: true,
        message: 'please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticClass: "wd440",
    attrs: {
      "placeholder": "Select Entity Type",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.companyType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "companyType", $$v);
      },
      expression: "dataForm.companyType"
    }
  }, _vm._l(_vm.engagementCompanyTypeList, function (item) {
    return _c('el-option', {
      key: item.title,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "flex"
  }, [_c('el-form-item', {
    ref: "coveredPeriodStart",
    attrs: {
      "label": "Covered Period",
      "prop": "coveredPeriodStart",
      "rules": {
        required: true,
        message: 'please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-date-picker', {
    staticClass: "wd200",
    attrs: {
      "type": "date",
      "value-format": "yyyy-MM-dd",
      "placeholder": "Start Date"
    },
    model: {
      value: _vm.dataForm.coveredPeriodStart,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "coveredPeriodStart", $$v);
      },
      expression: "dataForm.coveredPeriodStart"
    }
  })], 1), _c('span', {
    staticClass: "lh36 pl15 pr15"
  }, [_vm._v("~")]), _c('el-form-item', {
    ref: "coveredPeriodEnd",
    staticClass: "labelNone",
    attrs: {
      "label": "",
      "prop": "coveredPeriodEnd",
      "rules": {
        required: true,
        message: 'please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-date-picker', {
    staticClass: "wd200",
    attrs: {
      "type": "date",
      "value-format": "yyyy-MM-dd",
      "placeholder": "End Date"
    },
    model: {
      value: _vm.dataForm.coveredPeriodEnd,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "coveredPeriodEnd", $$v);
      },
      expression: "dataForm.coveredPeriodEnd"
    }
  })], 1)], 1), _c('el-form-item', {
    ref: "incorpDate",
    attrs: {
      "label": "Incorp. date",
      "prop": "incorpDate"
    }
  }, [_c('el-date-picker', {
    staticClass: "wd440",
    attrs: {
      "type": "date",
      "placeholder": "Incorp. date",
      "format": _vm.dataForm.incorpDate ? `dd [${_vm.monthList[parseInt(_vm.dataForm.incorpDate.split('-')[1]) - 1]}] yyyy` : 'dd MM yyyy',
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.dataForm.incorpDate,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "incorpDate", $$v);
      },
      expression: "dataForm.incorpDate"
    }
  })], 1), _c('el-form-item', {
    ref: "previousCompanyName",
    attrs: {
      "label": "Previous Entity Name",
      "prop": "previousCompanyName",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd440",
    attrs: {
      "placeholder": "Previous Entity Name",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.previousCompanyName,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "previousCompanyName", $$v);
      },
      expression: "dataForm.previousCompanyName"
    }
  })], 1), _c('el-form-item', {
    ref: "clientType",
    attrs: {
      "label": "Type",
      "prop": "clientType"
    }
  }, [_c('el-input', {
    staticClass: "wd440",
    attrs: {
      "placeholder": "Type",
      "clearable": "",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.clientType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "clientType", $$v);
      },
      expression: "dataForm.clientType"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm.isAuth('engagement:update') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }