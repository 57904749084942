<template>
  <el-dialog title="Confirm Refund" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="100px">
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <template v-if="isAuth('finance:financial')">
            <el-radio label="REFUND_INFO_NOT_ENOUGH">Refund Failed due to Insufficient Info</el-radio>
            <el-radio label="REFUNDED">Refunded</el-radio>
            <el-radio label="REFUND_PENDING">Pending Refund</el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Reason" prop="failReason">
        <el-input
          v-model="dataForm.failReason"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Reason"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        status: "REFUNDED",
        failReason: "",
      },
      dataRule: {},
      submitControl: true,
    };
  },
  props: {
    refundDetail: {
      type: Object,
      default: function () {
        return {
          orderId: null,
        };
      },
    },
  },
  computed: {
    ...mapState("constant", ["stepStatusList"]),
    unselectableStatus() {
      return this.stepStatusList.find(i => i.unselectable);
    },
  },
  methods: {
    ...mapActions("finance", ["doRefund"]),
    async init() {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          let refundDetailCopy = JSON.parse(JSON.stringify(this.refundDetail));
          refundDetailCopy.status = this.dataForm.status;
          refundDetailCopy.failReason = this.dataForm.failReason;
          this.doRefund({ ...this.dataForm, refundDetail: refundDetailCopy })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
