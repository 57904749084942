<template>
  <div class="page-container">
    <div class="mod-user">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList(1)">
        <div class="clearfix">
          <div class="fl pb20">
            <el-button v-if="isAuth('quotationV:save')" type="primary" @click="addOrUpdateHandle()">Add Quotation (V)</el-button>
          </div>
          <div class="fr">
            <el-form-item>
              <el-input style="width: 500px" v-model="dataForm.keyword" :placeholder="kwPlaceHolder" class="input-with-select-lg">
                <el-select v-model="dataForm.kwType" slot="prepend" placeholder="please select">
                  <el-option v-for="item in kwTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.amount" v-minusPoint2 placeholder="Amount" clearable class="wd250">
                <el-select v-model="dataForm.currency" placeholder="Currency" clearable filterable slot="prepend" class="wd110">
                  <el-option v-for="item in currencyList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="dataForm.status" multiple clearable placeholder="Status">
                <el-option v-for="item in supplementStatusList" :key="item.value" :label="item.title" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="dataForm.businessNo"
                clearable
                filterable
                remote
                :remote-method="getBusinessNoRemote"
                @clear="getBusinessNoRemote('')"
                @focus="getBusinessNoRemote('')"
                :loading="businessNoLoading"
                placeholder="Transaction No."
              >
                <el-option
                  v-for="(item, index) in businessNoList"
                  :key="`${item.businessNo}${index}`"
                  :label="`${item.businessNo}-${item.companyName}`"
                  :value="item.businessNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <el-table
        max-height="640"
        :data="dataList"
        v-loading="dataListLoading"
        @sort-change="tableSortChange"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        style="width: 100%"
      >
        <el-table-column
          prop="supplementNo"
          header-align="center"
          align="center"
          label="Quotation (V) No."
          sortable="custom"
          width="175"
          fixed="left"
        ></el-table-column>
        <el-table-column prop="businessNo" header-align="center" align="center" label="Linked Transaction No." width="186"></el-table-column>
        <el-table-column prop="billingParty" header-align="center" align="center" label="Billing Party" min-width="110"></el-table-column>
        <el-table-column prop="address" header-align="center" align="center" label="Address" min-width="130"></el-table-column>
        <el-table-column prop="country" header-align="center" align="center" label="Country" min-width="120"></el-table-column>
        <el-table-column prop="postalCode" header-align="center" align="center" label="Code" min-width="110"></el-table-column>
        <el-table-column header-align="center" align="center" label="Currency" min-width="150">
          <template slot-scope="scope">
            <p class="enbr" v-if="scope.row.paymentDetail">{{ currencyFormat(scope.row.paymentDetail.currency) }}</p>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Exchange Rate" min-width="130">
          <template slot-scope="scope">
            <template v-if="scope.row.paymentDetail">
              <p v-if="scope.row.paymentDetail.currency != 'SGD'">
                {{ scope.row.paymentDetail.exchangeRate }}
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Amount" min-width="120">
          <template slot-scope="scope">
            <p v-if="scope.row.paymentDetail">{{ scope.row.paymentDetail.amount | thousands(scope.row.paymentDetail.currencyUnit) }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="applyReason" header-align="center" align="center" label="Description" min-width="150"></el-table-column>
        <el-table-column prop="thirdPartyFee" header-align="center" align="center" label="Third party fee" min-width="130">
          <template slot-scope="scope">
            <p v-if="scope.row.paymentDetail">{{ scope.row.paymentDetail.thirdPartyFee | thousands(scope.row.paymentDetail.currencyUnit) }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="noteForThirdParty" header-align="center" align="center" label="Note For Third Party" min-width="170"></el-table-column>
        <el-table-column prop="otherFee" header-align="center" align="center" label="Other Fee" min-width="120">
          <template slot-scope="scope">
            <p v-if="scope.row.paymentDetail">{{ scope.row.paymentDetail.otherFee | thousands(scope.row.paymentDetail.currencyUnit) }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="noteForOther" header-align="center" align="center" label="Note For Other Fee" min-width="160"></el-table-column>
        <el-table-column prop="attachmentUrls" header-align="center" align="center" label="Attachments" min-width="130">
          <template slot-scope="scope">
            <p v-for="item in scope.row.attachmentUrls" :key="item.url">
              <a :href="item.url" target="_target">{{ item.name }}</a>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" header-align="center" align="center" label="Create Time" sortable="custom" min-width="130"></el-table-column>
        <el-table-column prop="status" header-align="center" align="center" label="Status" min-width="144">
          <template slot-scope="scope">
            <div class="fxRow">
              <p class="mr4">{{ statusFormat(scope.row.status) }}</p>
              <el-link type="primary" :underline="false" @click="openStatusHistoryModal(scope.row.id)">(Track)</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerOrder"
          header-align="center"
          align="center"
          width="120"
          label="Order Status"
          :formatter="orderStatusFormat"
        ></el-table-column>
        <el-table-column header-align="center" align="center" width="120" label="Action" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              v-if="isAuth('quotationV:update') && (scope.row.status == 'PENDING' || scope.row.status == 'REJECTED')"
              type="text"
              class="fwb"
              @click="addOrUpdateHandle(scope.row)"
            >
              Edit
            </el-button>
            <!-- 审批 -->
            <el-button v-if="isAuth('quotationV:audit') && scope.row.status == 'PENDING'" type="text" class="fwb" @click="reviewHandle(scope.row)">
              Review
            </el-button>
            <!-- 删除 -->
            <el-button
              v-if="isAuth('quotationV:delete') && (scope.row.status == 'PENDING' || scope.row.status == 'REJECTED')"
              type="text"
              class="fwb"
              @click="deleteHandle(scope.row)"
            >
              Delete
            </el-button>
            <!-- 禁用 -->
            <el-button
              v-if="
                isAuth('quotationV:forfeit') &&
                scope.row.status == 'PASSED' &&
                scope.row.customerOrder &&
                (scope.row.customerOrder.status == 'UNPAID' || scope.row.customerOrder.status == 'PARTLY_PAID')
              "
              type="text"
              class="fwb"
              @click="statusUpdateHandle(scope.row, 'Forfeit')"
            >
              Forfeit
            </el-button>
            <!-- 启用 -->
            <el-button
              v-if="
                isAuth('quotationV:reActive') &&
                scope.row.status == 'FORFEITED' &&
                scope.row.customerOrder &&
                (scope.row.customerOrder.status == 'UNPAID' ||
                  scope.row.customerOrder.status == 'PARTLY_PAID' ||
                  scope.row.customerOrder.status == 'FORFEITED')
              "
              type="text"
              class="fwb"
              @click="statusUpdateHandle(scope.row, 'Re-active')"
            >
              Re-active
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
      <!-- 状态操作日志弹框 -->
      <status-history ref="statusHistoryModal"></status-history>
      <!-- client code 复核弹框 -->
      <supplement-review ref="supplementReviewModal" @refreshStatus="getDataList"></supplement-review>
    </div>
  </div>
</template>

<script>
import AddOrUpdate from "./modal/AddOrUpdate";
import StatusHistory from "./modal/StatusHistory";
import SupplementReview from "./modal/SupplementReview";
import paginationDelete from "@/mixins/paginationDelete.js";
import { fmoney } from "@/utils";
import { mapState } from "vuex";
export default {
  mixins: [paginationDelete],
  data() {
    return {
      kwTypes: [
        {
          title: "Quotation (V) No.",
          value: "supplementNo",
          placeholder: "Quotation (V) No.",
        },
        {
          title: "Billing Party",
          value: "billingParty",
          placeholder: "Billing Party",
        },
        {
          title: "Description",
          value: "applyReason",
          placeholder: "Description",
        },
      ],
      dataForm: {
        kwType: "supplementNo",
        keyword: "",
        amount: "",
        currency: "",
        status: "",
        supplementNo: "",
        businessNo: "",
        billingParty: "",
        applyReason: "",
        "orders[0].asc": false,
        "orders[0].column": "id",
      },
      businessNoList: [],
      dataList: [],
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      businessNoLoading: false,
    };
  },
  components: {
    AddOrUpdate,
    StatusHistory,
    SupplementReview,
  },
  filters: {
    thousands(val, symbol) {
      if (val || val == 0) {
        return `${symbol}${fmoney(val, 2)}`;
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapState("constant", ["supplementStatusList", "orderStatusList", "currencyList"]),
    kwPlaceHolder() {
      return this.kwTypes.find(i => i.value === this.dataForm.kwType).placeholder;
    },
  },
  activated() {
    this.getBusinessNoRemote("");
    this.getDataList();
  },
  methods: {
    // 状态格式化
    statusFormat(cellValue) {
      const one = this.supplementStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    currencyFormat(cellValue) {
      const one = this.currencyList.find(i => i.value === cellValue);
      return one?.title;
    },
    /* 订单状态格式化 */
    orderStatusFormat(row, column, cellValue) {
      const one = this.orderStatusList.find(i => i.value === cellValue?.status);
      return one?.title;
    },
    // 获取businessNo列表
    async getBusinessNoRemote(query) {
      this.businessNoLoading = true;
      const { code, data } = await this.$http.get("/admin/businessInfo/getList", {
        params: {
          keywords: query,
        },
      });
      this.businessNoLoading = false;
      if (code === 200) {
        this.businessNoList = data.filter(ele => ele != null && ele.businessNo != null);
      } else {
        this.businessNoList = [];
      }
    },
    // table - 排序
    tableSortChange(column) {
      if (column.prop == "createTime") {
        this.dataForm["orders[0].column"] = "id";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "supplementNo") {
        this.dataForm["orders[0].column"] = "t1.supplement_no";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      }
    },
    // 获取数据分页
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
        ...this.dataForm,
        [this.dataForm.kwType]: this.dataForm.keyword,
      };
      params.amount = params.amount ? Number(params.amount) : params.amount;
      const { code, data } = await this.$http.get("/admin/businessSupplement/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 状态操作日志
    openStatusHistoryModal(id) {
      this.$refs.statusHistoryModal.init(id);
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.$refs.addOrUpdate.init(row);
    },
    // 删除
    async deleteHandle(row) {
      await this.$confirm(`Delete this quotation (v). Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const { code, msg } = await this.$http.post(`/admin/businessSupplement/${row.id}/delete`, {});
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => {
            // mixins
            this.singleDataDelete();
            this.getDataList();
          },
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 审批
    reviewHandle(row) {
      this.$refs.supplementReviewModal.init(row);
    },
    // 禁用、启用
    async statusUpdateHandle(row, tipe) {
      await this.$confirm(`${tipe} This Quotation (V). Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      this.$http.post(`/admin/businessSupplement/${row.id}/${tipe == "Forfeit" ? "forfeit" : "reActive"}`, {}).then(({ code, msg }) => {
        if (code === 200) {
          this.$message.success("success");
          this.getDataList();
        } else {
          this.$message.error(msg);
        }
      });
    },
  },
};
</script>
