<template>
  <el-dialog title="Initiate Termination Application" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="150px">
      <el-form-item label="Terminate Time" prop="terminateInfo.terminateTime">
        <el-date-picker
          v-model="dataForm.terminateInfo.terminateTime"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="Payment due date"
          class="fluid-width"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
      <el-form-item label="Attachments" prop="attachmentUrls">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { fmoney } from "@/utils";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        terminateInfo: { terminateTime: null },
        remark: "",
        attachmentUrls: [],
      },
      dataRule: {
        "terminateInfo.terminateTime": [{ required: true, message: "please select", trigger: "submit" }],
      },
      submitControl: true, // 防止重复提交
    };
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("applyment", ["doApplyTerminate"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doApplyTerminate({
            applymentId: this.detail.id,
            ...this.dataForm,
          })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.item-text {
  line-height: 40px;
  color: #41464b;
}
.price {
  color: #ff8653;
}
</style>
