<template>
  <div class="assets-file-picker">
    <div class="file-preview">
      <div v-if="value && value.name" class="left mr20">
        <div class="preview-file">
          <a :href="value.url" target="_blank">{{ value.name }}</a>
        </div>
      </div>
      <div @click="openUpload()" class="right">
        <slot><el-button>Upload</el-button></slot>
      </div>
    </div>
    <input class="hidden" type="text" :value="value" />
    <upload v-if="uploadVisible" ref="Upload" @uploadFinish="handleFinish"></upload>
  </div>
</template>

<script>
import Upload from "./upload";
export default {
  name: "ElFileUploader",
  components: {
    Upload,
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          name: "",
          url: "",
        };
      },
    },
  },
  data() {
    return {
      uploadVisible: false,
    };
  },
  methods: {
    openUpload() {
      this.uploadVisible = true;
      this.$nextTick(() => {
        this.$refs.Upload.init();
      });
    },
    handleFinish(urls) {
      this.$emit("input", urls[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.assets-file-picker {
  .file-preview {
    height: 40px;
    line-height: 40px;
    display: flex;
    width: fit-content;

    .preview-file {
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
