export default {
  namespaced: true,
  state: {
    activeRoleName: "",
    roleAuthorityList: [],
  },
  getters: {
    activeRoleAuthority: state => {
      return state.roleAuthorityList.find(i => i.roleName === state.activeRoleName) || { permissionList: [] };
    },
    optionalRoleList: state => {
      return state.roleAuthorityList.filter(i => i.roleName != state.activeRoleName) || [];
    },
  },
  mutations: {
    SET_AUTHORITIES(state, list) {
      state.roleAuthorityList = list;
      if (!state.activeRoleName) {
        if (list.length > 0) {
          state.activeRoleName = list[0].roleName;
        }
      }
    },
    TOGGLE_ROLE(state, roleName) {
      state.activeRoleName = roleName;
    },
  },
  actions: {
    initAuthorities({ dispatch, commit, state: { activeRoleName } }, list) {
      commit("SET_AUTHORITIES", list);
      if(list.length > 0){
        if (!activeRoleName ) {
          dispatch("toggleRole", list[0].roleName);
        } else {
          const one = list.find(i => i.roleName === activeRoleName);
          if (one) {
            dispatch("toggleRole", activeRoleName);
          } else {
            dispatch("toggleRole", list[0].roleName);
          }
        }
      }
    },
    toggleRole({ commit, getters }, payload) {
      commit("TOGGLE_ROLE", payload);
      const { activeRoleAuthority } = getters;
      if (activeRoleAuthority) {
        commit("menu/updateMenuList", activeRoleAuthority.menuList, { root: true });
      }
    },
  },
};
