<template>
  <el-dialog :title="!dataForm.id ? 'Add Menu' : 'Edit Menu'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="dataForm.type">
          <el-radio v-for="(type, index) in dataForm.typeList" :label="index" :key="index">{{ type }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="dataForm.typeList[dataForm.type] + '名称'" prop="name">
        <el-input v-model="dataForm.name" :placeholder="dataForm.typeList[dataForm.type] + '名称'"></el-input>
      </el-form-item>
      <el-form-item label="上级菜单" prop="parentName">
        <el-popover v-model="popoverVisible" placement="right" trigger="click">
          <div style="max-height: 80vh; min-width: 200px; padding-right: 5px" class="scrollbar-hidden">
            <el-tree
              :data="menuList"
              :props="menuListTreeProps"
              node-key="id"
              ref="menuListTreeRef"
              @current-change="menuListTreeCurrentChangeHandle"
              :default-expand-all="true"
              :highlight-current="true"
              :expand-on-click-node="false"
            ></el-tree>
          </div>
          <el-input
            style="width: 280px"
            slot="reference"
            v-model="dataForm.parentName"
            :readonly="true"
            placeholder="点击选择上级菜单"
            class="menu-list__input"
          ></el-input>
        </el-popover>
      </el-form-item>
      <el-form-item v-if="dataForm.type === 1" label="菜单路由" prop="url">
        <el-input v-model="dataForm.url" placeholder="菜单路由"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.type !== 0" label="授权标识" prop="perms">
        <el-input v-model="dataForm.perms" placeholder="多个用逗号分隔, 如: user:list,user:create"></el-input>
      </el-form-item>

      <el-form-item v-if="dataForm.type !== 2" label="菜单图标" prop="icon">
        <el-row>
          <el-col :span="12">
            <el-input v-model="dataForm.icon" placeholder="菜单图标名称" class="icon-list__input"></el-input>
          </el-col>
          <el-col :span="12" class="icon-list__tips">
            <el-form-item v-if="dataForm.type !== 2" label="排序号" prop="orderNum">
              <el-input-number v-model="dataForm.orderNum" controls-position="right" :min="0" label="排序号"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item v-if="dataForm.type !== 2" label="侧边栏隐藏" prop="hidden">
        <el-switch v-model="dataForm.hidden" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    var validateUrl = (rule, value, callback) => {
      if (this.dataForm.type === 1 && !/\S/.test(value)) {
        callback(new Error("菜单URL不能为空"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      popoverVisible: false,
      dataForm: {
        id: 0,
        type: 1,
        typeList: ["目录", "菜单", "按钮"],
        name: "",
        parentId: 0,
        parentName: "",
        url: "",
        perms: "",
        orderNum: 0,
        icon: "",
        hidden: 0,
      },
      dataRule: {
        name: [{ required: true, message: "菜单名称不能为空", trigger: "blur" }],
        parentName: [{ required: true, message: "上级菜单不能为空", trigger: "change" }],
        url: [{ validator: validateUrl, trigger: "blur" }],
      },
      menuList: [],
      menuListTreeProps: {
        label: "name",
        children: "children",
      },
      submitControl: true,
    };
  },
  methods: {
    async init(id) {
      this.submitControl = true;
      this.dataForm.id = id || 0;
      const { data: menuToBeSelect } = await this.$http.get("/admin/sysMenu/select");
      this.menuList = treeDataTranslate(menuToBeSelect, "id");
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      if (!this.dataForm.id) {
        // 新增
        this.menuListTreeSetCurrentNode();
      } else {
        // 修改
        const { data } = await this.$http.get(`/admin/sysMenu/${this.dataForm.id}/detail`);
        this.dataForm.id = data.id;
        this.dataForm.type = data.type;
        this.dataForm.name = data.name;
        this.dataForm.parentId = data.parentId;
        this.dataForm.url = data.url;
        this.dataForm.perms = data.perms;
        this.dataForm.orderNum = data.orderNum;
        this.dataForm.icon = data.icon;
        this.dataForm.hidden = data.hidden;
        this.menuListTreeSetCurrentNode();
      }
    },
    // 菜单树选中
    menuListTreeCurrentChangeHandle(data, node) {
      this.dataForm.parentId = data.id;
      this.dataForm.parentName = data.name;
      this.popoverVisible = false;
    },
    // 菜单树设置当前选中节点
    menuListTreeSetCurrentNode() {
      this.$refs.menuListTreeRef.setCurrentKey(this.dataForm.parentId);
      this.dataForm.parentName = (this.$refs.menuListTreeRef.getCurrentNode() || {})["name"];
    },
    // 表单提交
    async formSubmitRequest(dataForm) {
      const payload = {
        type: dataForm.type,
        name: dataForm.name,
        parentId: dataForm.parentId,
        url: dataForm.url,
        perms: dataForm.perms,
        orderNum: dataForm.orderNum,
        icon: dataForm.icon,
        hidden: dataForm.hidden,
      };
      if (dataForm.id) {
        return await this.$http.post(`/admin/sysMenu/${dataForm.id}/update`, payload);
      } else {
        return await this.$http.post(`/admin/sysMenu/create`, payload);
      }
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.formSubmitRequest(this.dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.mod-menu {
  .menu-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }
  &__icon-popover {
    width: 458px;
    overflow: hidden;
  }
  &__icon-inner {
    width: 478px;
    max-height: 258px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__icon-list {
    width: 458px;
    padding: 0;
    margin: -8px 0 0 -8px;
    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;
      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
</style>
