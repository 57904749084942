<template>
  <el-dialog append-to-body width="670px" title="Upload Picture" :close-on-click-modal="false" :visible.sync="visible">
    <div class="upload-container" :class="{ preview: preview }">
      <div class="upload-button">
        <div>
          <svg-icon class="upload-icon" name="upload" />
          <div class="upload-text">Upload picture, support jpg, png</div>
        </div>
      </div>
      <div class="img-preview" v-loading="uploading">
        <img :src="previewImgUrl" />
      </div>
      <input
        type="file"
        ref="fileupload"
        accept="image/*"
        style="opacity: 0; width: 100%; height: 100%; position: absolute; left: 0; top: 0"
        @change="onFileChange"
      />
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button
      style="margin-left:20px"
        type="primary"
        @click="dataFormSubmit()"
        :disabled="uploading || !checked"
        >上传</el-button
      >
    </span> -->
    <div class="pb20"></div>
  </el-dialog>
</template>

<script>
import uploader from "@/utils/uploader";
export default {
  data() {
    return {
      uploading: false,
      checked: false,
      visible: false,
      file: undefined,
      preview: false,
      previewImgUrl: "",
    };
  },
  methods: {
    init() {
      this.file = undefined;
      this.visible = true;
      this.preview = false;
      this.checked = false;
      this.previewImgUrl = "";
      //将原生 FileList 清空
      if (this.$refs.fileupload) {
        this.$refs.fileupload.value = null;
      }
    },
    onFileChange(e) {
      this.preview = true;
      this.checked = false;
      let file = e.currentTarget.files[0];
      if (file.size > 5 * 1024 * 1024) {
        this.$message.error("fail, maximum file size is 5M");
        this.file = undefined;
        this.preview = false;
        this.previewImgUrl = "";
        return;
      }
      this.file = file;
      this.previewImgUrl = URL.createObjectURL(file);
      this.checked = true;
      this.dataFormSubmit();
    },
    // 表单提交
    async dataFormSubmit() {
      if (!this.file) {
        this.$message.error("please select File");
        return;
      }
      this.uploading = true;
      uploader
        .upload(this.file)
        .then(res => {
          this.$message({
            message: "upload success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.$emit("uploadFinish", res.data.urls);
            },
          });
          this.uploading = false;
          this.checked = false;
        })
        .catch(err => {
          this.$message.error(err);
          this.uploading = false;
          this.checked = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-container {
  margin: 0 auto;
  background-color: #fff;
  width: 495px;
  height: 235.5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type="file"] {
    cursor: pointer;
  }

  .img-preview {
    display: none;
  }
}
.upload-container.preview {
  border: none;
  .img-preview {
    display: flex;
  }
  .upload-button {
    display: none;
  }
}
.upload-button {
  border: 1px dashed #d9d9d9 !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .upload-icon {
    font-size: 67px;
    color: #c0c4cc;
    line-height: 50px;
    text-align: center;
    margin: 0 auto 16px;
    display: block;
  }
  .upload-text {
    color: #606266;
    font-size: 14px;
    text-align: center;
  }
}
.img-preview {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    height: 90%;
  }
}
</style>
