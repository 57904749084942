export default {
  namespaced: true,
  state: {
    id: 0,
    name: "",
    avatar: "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
    isAssistant: 0,
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateName(state, name) {
      state.name = name;
    },
    updateIsAssistant(state, isAssistant) {
      state.isAssistant = isAssistant;
    }
  },
};
