<template>
  <el-dialog :title="dataForm.step" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="140px">
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <template v-if="isApplymentAuth('company:check') || isApplymentAuth('company:audit')">
            <el-radio v-for="item in stepStatusList" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
          </template>
          <template v-else>
            <el-radio :label="unselectableStatus.value">
              {{ unselectableStatus.title }}
            </el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
      <el-form-item label="attachments" prop="attachmentUrls">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        applymentId: 0,
        step: "",
        status: 1,
        remark: "",
        attachmentUrls: [],
      },
      dataRule: {},
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["stepStatusList"]),
    unselectableStatus() {
      return this.stepStatusList.find(i => i.unselectable);
    },
  },
  methods: {
    ...mapActions("applyment", ["doSaveProcedures"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      if (data) {
        this.dataForm = { ...this.dataForm, ...data };
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doSaveProcedures(this.dataForm)
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
