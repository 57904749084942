<template>
  <div class="desp">
    <div class="desp-header">
      <div class="label">Company Name</div>
      <div class="opts">
        <el-button
          v-if="
            (detail.applymentStatus === 'REVISING' ||
              detail.applymentStatus === 'CORRECTED' ||
              detail.applymentStatus === 'ILLEGAL' ||
              detail.applymentStatus === 'AUDIT_FAIL' ||
              detail.applymentStatus === 'REFUND_APPLY_DENY' ||
              detail.applymentStatus === 'REFUND_APPLY_REJECT' ||
              detail.applymentStatus === 'ACRA_REJECTED') &&
            isApplymentAuth('company:coordinate')
          "
          @click="openEdit()"
        >
          Edit
        </el-button>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Company type</div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.companyType || "empty" }}</div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Proposed Company Name</div>
      <div class="desp-items-wrapper">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="desp-item">
              <!-- <div class="desp-item-label">Name1</div> -->
              <div class="desp-item-self">
                <span class="company-name">{{ detail.proposedCompanyName1 }}</span>
                <span class="company-type">{{ detail.proposedCompanyType1 }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <!-- <div class="desp-item-label">Name2</div> -->
              <div class="desp-item-self">
                <span class="company-name">{{ detail.proposedCompanyName2 }}</span>
                <span class="company-type">{{ detail.proposedCompanyType2 }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <!-- <div class="desp-item-label">Name3</div> -->
              <div class="desp-item-self">
                <span class="company-name">{{ detail.proposedCompanyName3 }}</span>
                <span class="company-type">{{ detail.proposedCompanyType3 }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Primary Activity</div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.primaryActivity }}</div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Primary User-Described Activity Description</div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.primaryActivityDescription }}</div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Secondary Activity</div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.secondaryActivity }}</div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Secondary User-Described Activity Description</div>
      <div class="desp-item">
        <div class="desp-item-self">{{ detail.secondaryActivityDescription }}</div>
      </div>
    </div>
    <!-- 我们同意聘用由星中商务推荐的名义董事1名，我们也理解该名义董事的行为造成的任何法律影响均与EStar无关。 -->
    <div class="no-event desp-items-group" id="engageNomineeDirectorCheckbox" v-if="isNeedNomineeDirector">
      <div class="desp-items-group-title">Nominee Director Service</div>
      <div class="desp-item">
        <div class="desp-item-self">
          <el-checkbox class="checkboxCustom" :true-label="1" :false-label="0" v-model="detail.hasNomineeDirector">
            We consent to appoint one Nominee Director recommended by EStar Business Services Pte. Ltd. ("EStar"), and we understand that any legal impact
            caused by the Nominee Director's action has nothing to do with EStar.
          </el-checkbox>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <edit-modal v-if="editVisible" ref="editModal" :businessType="detail.businessType" :isNeedNomineeDirector="isNeedNomineeDirector" />
  </div>
</template>

<script>
import EditModal from "./modal/Edit.vue";
export default {
  components: {
    EditModal,
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          companyType: "empty",
          proposedCompanyName1: "empty",
          proposedCompanyType1: "empty",
          proposedCompanyName2: "empty",
          proposedCompanyType2: "empty",
          proposedCompanyName3: "empty",
          proposedCompanyType3: "empty",
          primaryActivity: "empty",
          primaryActivityDescription: "empty",
          secondaryActivity: "empty",
          secondaryActivityDescription: "empty",
          businessType: "",
          hasNomineeDirector: null,
        };
      },
    },
    isNeedNomineeDirector: {
      type: Boolean,
    },
  },
  data() {
    return {
      dataListLoading: false,
      editVisible: false,
    };
  },
  methods: {
    async openEdit() {
      this.editVisible = true;
      await this.$nextTick();
      this.$refs.editModal.init(this.detail);
    },
  },
};
</script>

<style lang="scss" scoped>
.company-name {
  margin-right: 10px;
  font-weight: 500;
  font-size: 17px;
}

.company-type {
  font-weight: 400;
}

.checkboxCustom {
  ::v-deep .el-checkbox__label {
    /* 这两个在技术上是一样的, 为了兼容了浏览器两个都加上 */
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;

    -ms-word-break: break-all !important;
    /* 这个的使用在web-kit中有些危险，他可能会阶段所有东西 */
    word-break: break-all !important;
    /* Instead use this non-standard one: */
    word-break: break-word !important;

    /* 如果浏览器支持的话增加一个连接符(Blink不支持) */
    hyphens: auto !important;

    display: inline-grid;
    white-space: pre-line;
    line-height: 20px;
  }
}
</style>
