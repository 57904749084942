var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', {
    staticClass: "mod-menu"
  }, [_c('el-form', {
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    }
  }, [_c('el-form-item', [_vm.isAuth('sys:menu:save') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addOrUpdateHandle();
      }
    }
  }, [_vm._v("Add Menu")]) : _vm._e()], 1)], 1), _c('el-card', {
    attrs: {
      "shadow": "nerver"
    }
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.dataList,
      "row-key": "id"
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "name",
      "header-align": "center",
      "min-width": "150",
      "label": "Name"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "parentName",
      "header-align": "center",
      "align": "center",
      "width": "120",
      "label": "Parent"
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Icon"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('i', {
          class: scope.row.icon
        }), _c('i', {
          staticClass: "el-icon"
        }, [_c('svg-icon', {
          attrs: {
            "name": scope.row.icon || ''
          }
        })], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "type",
      "header-align": "center",
      "align": "center",
      "label": "Type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.type === 0 ? _c('el-tag', {
          attrs: {
            "size": "small"
          }
        }, [_vm._v("CATALOG")]) : scope.row.type === 1 ? _c('el-tag', {
          attrs: {
            "size": "small",
            "type": "success"
          }
        }, [_vm._v("MENU")]) : scope.row.type === 2 ? _c('el-tag', {
          attrs: {
            "size": "small",
            "type": "info"
          }
        }, [_vm._v("BUTTON")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "orderNum",
      "header-align": "center",
      "align": "center",
      "label": "Sort"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "url",
      "header-align": "center",
      "align": "center",
      "min-width": "150",
      "show-overflow-tooltip": true,
      "label": "Menu URL"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "perms",
      "header-align": "center",
      "align": "center",
      "width": "150",
      "show-overflow-tooltip": true,
      "label": "Permissons"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "hidden",
      "header-align": "center",
      "align": "center",
      "width": "100",
      "formatter": _vm.hiddenFormat,
      "label": "Hidden"
    }
  }), _c('el-table-column', {
    attrs: {
      "fixed": "right",
      "header-align": "center",
      "align": "center",
      "width": "180",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.isAuth('sys:menu:update') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.addOrUpdateHandle(scope.row.id);
            }
          }
        }, [_vm._v("Edit")]) : _vm._e(), _vm.isAuth('sys:menu:delete') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteHandle(scope.row.id);
            }
          }
        }, [_vm._v("Delete")]) : _vm._e()];
      }
    }])
  })], 1)], 1), _vm.addOrUpdateVisible ? _c('add-or-update', {
    ref: "addOrUpdate",
    on: {
      "refreshDataList": _vm.getDataList
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }