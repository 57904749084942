<template>
  <div class="desp">
    <div class="desp-header">Share Capital Details</div>
    <div class="desp-item">
      <div class="desp-item-self" style="width: 100%">
        <el-table :header-cell-style="{ color: '#011A45' }" border :data="companyMembers" style="width: 100%">
          <el-table-column align="center" prop="fullName" label="Name Of Shareholders"></el-table-column>
          <el-table-column align="center" prop="typeOfShares" label="Type Of Shares" width="180" :formatter="typeOfSharesFormat"></el-table-column>
          <el-table-column align="center" prop="quantityOfSharesAllotted" label="No of shares allotted"></el-table-column>
          <el-table-column align="center" prop="amountOfPaidUpShareCapital" label="Amount Of Registered Share Capital"></el-table-column>
          <el-table-column align="center" prop="amountOfPaidInShareCapital" label="Paid-in capital"></el-table-column>
          <el-table-column align="center" prop="sharesHoldInTrust" label="Shares held in trust" :formatter="sharesHoldInTrustFormat"></el-table-column>
          <el-table-column align="center" prop="nameOfTheTrust" label="Name of the trust"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  props: {
    companyMemberList: {
      type: Array,
      default: function () {
        return [
          {
            id: undefined,
            memberType: "CORPORATE",
            positionHeld: "SHAREHOLDER",
            fullName: "lisi",
            nationality: "",
            identificationType: "",
            identificationNo: "",
            businessLicenseNo: "",
            email: "",
            phone: "",
            registeredOfficeAddress: "",
            operationAddress: "",
            residentialAddress: "",
            sharesInfo: [
              {
                typeOfShares: "Ordinary",
                quantityOfSharesAllotted: 0,
                amountOfPaidUpShareCapital: "",
                amountOfPaidInShareCapital: "",
                sharesHoldInTrust: 1,
                nameOfTheTrust: "",
              },
            ],
          },
        ];
      },
    },
  },

  data() {
    return {
      dataListLoading: false,
    };
  },
  computed: {
    ...mapState("constant", ["companyMemberTypes", "positionHelds", "typeOfShares"]),
    companyMembers() {
      return this.companyMemberList.flatMap(member => {
        const { fullName } = member;
        if (!member.sharesInfo) {
          return [];
        }
        return member.sharesInfo.map(shares => {
          return {
            fullName,
            ...shares,
          };
        });
      });
    },
  },
  methods: {
    memberTypeFormat(v) {
      const one = this.companyMemberTypes.find(i => i.value === v);
      return one?.title;
    },
    positionHeldFormat(v) {
      const one = this.positionHelds.find(i => i.value === v);
      return one?.title;
    },
    typeOfSharesFormat(r, c, v) {
      const one = this.typeOfShares.find(i => i.value === v);
      return one?.title;
    },
    sharesHoldInTrustFormat(r, c, v) {
      return v === 1 ? "Yes" : "No";
    },
  },
};
</script>
