<template>
  <div class="mt30 pd26 hg350 bg-white border-radius-10">
    <div class="flex flex-direction-row align-center justify-between">
      <h3 class="home-title">Checker</h3>
      <p class="home-introduce">Average over preceding 3 months</p>
    </div>
    <el-row :gutter="30">
      <el-col :span="12">
        <div class="flex justify-center align-center">
          <div class="hg170 flex justify-center flex-direction align-start">
            <img src="@/assets/img/home/reviewed.png" alt="" />
          </div>
          <div class="ml20 hg170 flex justify-center flex-direction align-start">
            <h3 class="aurantia home-title">
              {{ checkerObj.passCnt }}
              [{{
                parseFloat(checkerObj.passCnt) && parseFloat(checkerObj.allCnt)
                  ? toFixedDecimal(div(checkerObj.passCnt, checkerObj.allCnt) * 100) + "%"
                  : "0.00%"
              }}]
            </h3>
            <p class="mt12 home-introduce">Pass</p>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="flex justify-center align-center">
          <div class="hg170 flex justify-center flex-direction align-start">
            <img src="@/assets/img/home/Fail.png" alt="" />
          </div>
          <div class="ml20 hg170 flex justify-center flex-direction align-start">
            <h3 class="darkBlue home-title">
              {{ checkerObj.rejectCnt }}
              [{{
                parseFloat(checkerObj.rejectCnt) && parseFloat(checkerObj.allCnt)
                  ? toFixedDecimal(div(checkerObj.rejectCnt, checkerObj.allCnt) * 100) + "%"
                  : "0.00%"
              }}]
            </h3>
            <p class="mt12 home-introduce">Reject</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 进度条 -->
    <div class="progress-wrap">
      <div
        class="pass-bar"
        :class="checkerObj.rejectCnt ? '' : 'right-radius'"
        :style="{
          width:
            parseFloat(checkerObj.passCnt) && parseFloat(checkerObj.allCnt) ? toFixedDecimal(div(checkerObj.passCnt, checkerObj.allCnt) * 100) + '%' : 0,
        }"
      ></div>
      <div
        class="reject-bar"
        :style="{
          width:
            parseFloat(checkerObj.rejectCnt) && parseFloat(checkerObj.allCnt)
              ? toFixedDecimal(div(checkerObj.rejectCnt, checkerObj.allCnt) * 100) + '%'
              : 0,
        }"
      ></div>
    </div>
    <p class="total-ratio">
      <span class="fs20 mr6">Total:</span>
      <span class="fwb fs30">{{ checkerObj.allCnt }} [100%]</span>
    </p>
  </div>
</template>

<script>
import { toFixedDecimal, div, get3MonthBefor, getNowFormatDate } from "@/utils";
export default {
  data() {
    return {
      checkerObj: {},
    };
  },
  computed: {
    toFixedDecimal() {
      return val => {
        return toFixedDecimal(val);
      };
    },
    div() {
      return (...arg) => {
        return div(...arg);
      };
    },
  },
  methods: {
    // 一级审核未通过的笔数和占一级总处理笔数的比例
    getCheckerRejectInTotal() {
      this.$http
        .get("/admin/statistics/getCheckerRejectInTotal", {
          params: {
            startTime: get3MonthBefor(),
            endTime: getNowFormatDate(),
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.checkerObj = data;
          } else {
            this.$message.error(msg);
          }
        });
    },
  },
  activated() {
    // 一级审核未通过的笔数和占一级总处理笔数的比例
    this.getCheckerRejectInTotal();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.home-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: $form-item-font-color;
}

.home-subhead {
  margin: 0;
  padding: 0;
  margin-top: 6px;
  font-size: 14px;
  font-weight: bold;
  color: $form-item-font-color;
  line-height: 20px;
}

.home-introduce {
  font-size: 16px;
  color: #7987a0;
  padding: 0;
  margin: 0;
  line-height: 22px;
}

.progress-wrap {
  width: 100%;
  height: 10px;
  background-color: #ebeefd;
  border-radius: 10px;
  font-size: 0;
  overflow: hidden;
  .pass-bar {
    display: inline-block;
    height: 100%;
    background-color: #ff7e69;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .reject-bar {
    display: inline-block;
    height: 100%;
    background-color: #0072ff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .right-radius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.total-ratio {
  margin: 0;
  margin-top: 30px;
  padding: 0;
  text-align: center;
  font-size: 20px;
  color: #011a45;
  line-height: 28px;
  span {
    vertical-align: middle;
  }
}

.aurantia {
  color: #ff7e69 !important;
}

.cyan {
  color: #52d4ff !important;
}

.darkBlue {
  color: #0072ff !important;
}
</style>
