<template>
  <div class="mt30 pd26 hg308 bg-white border-radius-10">
    <div class="flex flex-direction-row align-center justify-between">
      <h3 class="home-title">Reviewer</h3>
      <p class="home-introduce">Average over preceding 3 months</p>
    </div>
    <el-row class="mt30">
      <el-col :span="14" class="hg198 flex justify-center align-center">
        <div class="ratio-wrap">
          <el-progress
            type="circle"
            :percentage="
              parseFloat(auditorObj.passCnt) && parseFloat(auditorObj.allCnt)
                ? parseFloat(toFixedDecimal(div(auditorObj.passCnt, auditorObj.allCnt) * 100))
                : 0
            "
            color="#FF7E69"
            :stroke-width="14"
            :width="170"
            class="ratio-pass"
            :show-text="false"
          ></el-progress>
          <el-progress
            type="circle"
            :percentage="
              parseFloat(auditorObj.rejectCnt) && parseFloat(auditorObj.allCnt)
                ? parseFloat(toFixedDecimal(div(auditorObj.rejectCnt, auditorObj.allCnt) * 100))
                : 0
            "
            color="#0072FF"
            :stroke-width="14"
            :width="110"
            class="ratio-reject"
            :show-text="false"
          ></el-progress>
        </div>
      </el-col>
      <el-col :span="10" class="hg198 flex justify-center flex-direction align-start">
        <p class="dot-all">Total: {{ auditorObj.allCnt }} [100%]</p>
        <p class="dot-pass">
          Pass: {{ auditorObj.passCnt }} [{{
            parseFloat(auditorObj.passCnt) && parseFloat(auditorObj.allCnt)
              ? toFixedDecimal(div(auditorObj.passCnt, auditorObj.allCnt) * 100) + "%"
              : "0.00%"
          }}]
        </p>
        <p class="dot-reject">
          Reject: {{ auditorObj.rejectCnt }} [{{
            parseFloat(auditorObj.rejectCnt) && parseFloat(auditorObj.allCnt)
              ? toFixedDecimal(div(auditorObj.rejectCnt, auditorObj.allCnt) * 100) + "%"
              : "0.00%"
          }}]
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { toFixedDecimal, div, get3MonthBefor, getNowFormatDate } from "@/utils";
export default {
  data() {
    return {
      auditorObj: {},
    };
  },
  computed: {
    toFixedDecimal() {
      return val => {
        return toFixedDecimal(val);
      };
    },
    div() {
      return (...arg) => {
        return div(...arg);
      };
    },
  },
  methods: {
    // 二级审核未通过的笔数和占一级审核提交二级审核总笔数的比例
    getAuditorRejectInTotal() {
      this.$http
        .get("/admin/statistics/getAuditorRejectInTotal", {
          params: {
            startTime: get3MonthBefor(),
            endTime: getNowFormatDate(),
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.auditorObj = data;
          } else {
            this.$message.error(msg);
          }
        });
    },
  },
  activated() {
    // 二级审核未通过的笔数和占一级审核提交二级审核总笔数的比例
    this.getAuditorRejectInTotal();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.home-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: $form-item-font-color;
}

.home-subhead {
  margin: 0;
  padding: 0;
  margin-top: 6px;
  font-size: 14px;
  font-weight: bold;
  color: $form-item-font-color;
  line-height: 20px;
}

.home-introduce {
  font-size: 16px;
  color: #7987a0;
  padding: 0;
  margin: 0;
  line-height: 22px;
}

.ratio-wrap {
  position: relative;
  .ratio-reject {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 100;
  }
}

.dot-all,
.dot-pass,
.dot-reject {
  color: #7987a0;
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 30px;
}

.dot-all::before {
  display: inline-block;
  content: "";
  background: #ebeef5;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 8px;
}

.dot-pass::before {
  display: inline-block;
  content: "";
  background: #ff7e69;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 8px;
}

.dot-reject::before {
  display: inline-block;
  content: "";
  background: #0072ff;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 8px;
}
</style>
