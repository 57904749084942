<template>
  <div class="assets-image-picker">
    <div class="image-preview" @click="openUpload()">
      <div v-if="value && value.url" class="left mr20">
        <el-image :src="value.url" :alt="value.name" :style="{ width: width, height: height }" class="preview-image" fit="fill"></el-image>
      </div>
      <div class="right">
        <slot><el-button>Upload</el-button></slot>
      </div>
    </div>
    <input class="hidden" type="text" :value="value" />
    <upload v-if="uploadVisible" ref="Upload" @uploadFinish="handleFinish"></upload>
  </div>
</template>

<script>
import Upload from "./upload";
export default {
  name: "ElImageUploader",
  components: {
    Upload,
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          name: "",
          url: "",
        };
      },
    },
    width: {
      type: String,
      default: "140px",
    },
    height: {
      type: String,
      default: "140px",
    },
  },
  data() {
    return {
      uploadVisible: false,
      currValue: this.value,
    };
  },
  methods: {
    openUpload() {
      this.uploadVisible = true;
      this.$nextTick(() => {
        this.$refs.Upload.init();
      });
    },
    handleFinish(urls) {
      this.$emit("input", urls[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.assets-image-picker {
  .image-preview {
    display: flex;
    width: fit-content;

    .preview-image {
      border-radius: 3px;
    }
  }
}
</style>
>
