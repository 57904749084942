var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sys-user-toggle-wrapper"
  }, [_c('el-dropdown', {
    attrs: {
      "trigger": "click",
      "placement": "bottom-start",
      "disabled": !_vm.canSelect
    },
    on: {
      "command": _vm.handleCommand
    }
  }, [_c('span', {
    staticClass: "cursor-pointer el-dropdown-link"
  }, [_vm._v(" " + _vm._s(_vm.formatName(this.currVal)) + " "), _vm.canSelect ? _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  }) : _vm._e()]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    attrs: {
      "disabled": _vm.hasUndefinedOption,
      "command": {
        id: -1
      }
    }
  }, [_vm._v("unassigned")]), _vm._l(_vm.userListFilter, function (user) {
    return _c('el-dropdown-item', {
      key: user.id,
      attrs: {
        "disabled": _vm.hasSelOption(user),
        "command": user
      }
    }, [_vm._v(" " + _vm._s(_vm.formatName(user)) + " ")]);
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }