<template>
  <el-dialog width="1200px" title="Step Operation History" :close-on-click-modal="false" :visible.sync="visible">
    <div>
      <el-form class="el-form-filter" :inline="true" :model="dataForm" @submit.native.prevent="getDataList(1)">
        <el-form-item>
          <el-date-picker
            value-format="timestamp"
            v-model="tradeTimeRange"
            type="daterange"
            range-separator="~"
            start-placeholder="Start Date"
            end-placeholder="Ending Date"
            @change="getDataList(1)"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="mt20">
      <el-table border :data="dataList" v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="handleAt" header-align="center" align="center" label="Handling Time" :formatter="localDateFormat" />
        <el-table-column prop="handler.nickname" align="center" header-align="center" label="Handler" />
        <el-table-column prop="remark" header-align="center" align="center" label="Remark" />
        <el-table-column prop="attachmentUrls" header-align="center" align="center" label="Attachment">
          <template slot-scope="scope">
            <p v-for="item in scope.row.attachmentUrls" :key="item.url">
              <a :href="item.url" target="_target">{{ item.name }}</a>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="status" header-align="center" align="center" label="Status">
          <template slot-scope="scope">
            <p class="dot-Red" v-if="scope.row.status == 0">Not Pass</p>
            <p class="dot-Green" v-if="scope.row.status == 1">Pass</p>
            <p class="dot-Orange" v-if="scope.row.status == 2">Modified</p>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { dateFormat } from "@/utils";
import { mapState, mapActions } from "vuex";
export default {
  props: {
    applymentId: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      visible: false,
      dataList: [],
      current: 1,
      size: 10,
      total: 0,
      dataListLoading: false,
      dataForm: {
        step: "",
        startTime: "",
        endTime: "",
      },
      tradeTimeRange: null,
      orderStatus: [
        {
          title: "Not Pass",
          value: 0,
        },
        {
          title: "Pass",
          value: 1,
        },
        {
          title: "Modified",
          value: 2,
        },
      ],
    };
  },
  computed: {},
  watch: {
    tradeTimeRange(newValue, oldValue) {
      if (newValue) {
        this.dataForm.startTime = newValue[0] / 1000;
        this.dataForm.endTime = newValue[1] / 1000;
      } else {
        this.dataForm.startTime = undefined;
        this.dataForm.endTime = undefined;
      }
    },
  },
  methods: {
    async init(data) {
      this.visible = true;
      await this.$nextTick();
      this.dataForm = {
        step: data,
        startTime: "",
        endTime: "",
      };
      this.getDataList(1);
    },
    // 日期格式化
    localDateFormat(row) {
      if (row.handleAt) {
        return dateFormat(new Date(row.handleAt * 1000));
      }
      return "";
    },
    // 每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 获取分页
    getDataList(current) {
      setTimeout(async () => {
        this.dataListLoading = true;
        if (current) {
          this.current = current;
        }
        const { code, data } = await this.$http.get("/admin/companyApplymentStatusProcedureLog/paging", {
          params: {
            current: this.current,
            size: this.size,
            "orders[0].asc": false,
            "orders[0].column": "id",
            applymentId: this.applymentId,
            step: this.dataForm.step,
            startTime: this.dataForm.startTime,
            endTime: this.dataForm.endTime,
          },
        });
        if (code === 200) {
          this.dataList = data.records;
          this.total = data.total;
        } else {
          this.dataList = [];
          this.total = 0;
        }
        this.dataListLoading = false;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
