<template>
  <el-dialog width="1200px" title="Ownership Role History" :close-on-click-modal="false" :visible.sync="visible">
    <div>
      <el-table border :data="dataList" v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="createAt" header-align="center" align="center" label="Handling Time" :formatter="localDateFormat" />
        <el-table-column align="center" header-align="center" label="From">
          <template slot-scope="scope">
            <p v-if="scope.row.from">{{ scope.row.from.nickname || scope.row.from.username }}</p>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="To">
          <template slot-scope="scope">
            <p v-if="scope.row.to">{{ scope.row.to.nickname || scope.row.to.username }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="type" header-align="center" align="center" label="Type" :formatter="ownershipTypeFormat" />
        <el-table-column prop="ownershipRole" header-align="center" align="center" label="Ownership Role" :formatter="ownershipRoleFormat" />
        <el-table-column header-align="center" align="center" label="Operator">
          <template slot-scope="scope">
            <p>{{ scope.row.operator.nickname || scope.row.operator.username }}</p>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { dateFormat } from "@/utils";
import { mapState, mapActions } from "vuex";
export default {
  props: {
    applymentId: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      visible: false,
      dataList: [],
      current: 1,
      size: 10,
      total: 0,
      dataListLoading: false,
    };
  },
  computed: {
    ...mapState("constant", ["ownershipRoleOptions", "ownershipTypeOptions"]),
  },
  watch: {},
  methods: {
    async init() {
      this.visible = true;
      await this.$nextTick();
      this.getDataList(1);
    },
    // 日期格式化
    localDateFormat(row) {
      if (row.createAt) {
        return dateFormat(new Date(row.createAt * 1000));
      }
      return "";
    },
    ownershipTypeFormat(row, column, cellValue) {
      const one = this.ownershipTypeOptions.find(i => i.value === cellValue);
      return one?.title;
    },
    ownershipRoleFormat(row, column, cellValue) {
      const one = this.ownershipRoleOptions.find(i => i.value === cellValue);
      return one?.title;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 获取分页
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const { code, data } = await this.$http.get("/admin/companyApplymentOwnershipLog/paging", {
        params: {
          current: this.current,
          size: this.size,
          "orders[0].asc": false,
          "orders[0].column": "id",
          applymentId: this.applymentId,
        },
      });
      if (code === 200) {
        this.dataList = data.records;
        this.total = data.total;
      } else {
        this.dataList = [];
        this.total = 0;
      }
      this.dataListLoading = false;
    },
  },
};
</script>