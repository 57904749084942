var data = [
  {
    name: "a",
    key: "a",
    value: "aV",
    hidden: false,
    children: [
      {
        name: "a1",
        key: "a1",
        value: "a1V",
        hidden: false,
        children: [
          {
            name: "a11",
            key: "a11",
            value: "a11V",
            hidden: true,
          },
          {
            name: "a12",
            key: "a12",
            value: "a12V",
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    name: "b",
    key: "b",
    value: "bV",
    hidden: false,
    children: [
      {
        name: "b1",
        key: "b1",
        value: "b1V",
        hidden: false,
        children: [
          {
            name: "b11",
            key: "b11",
            value: "b11V",
            hidden: false,
          },
          {
            name: "b12",
            key: "b12",
            value: "b12V",
            hidden: false,
          },
        ],
      },
    ],
  },
  {
    name: "c",
    key: "c",
    value: "cV",
    hidden: false,
    children: [
      {
        name: "c1",
        key: "c1",
        value: "c1V",
        hidden: false,
        children: [
          {
            name: "c11",
            key: "c11",
            value: "c11V",
            hidden: false,
            children: [
              {
                name: "c111",
                key: "c111",
                value: "c111V",
                hidden: true,
              },
              {
                name: "c112",
                key: "c112",
                value: "c112V",
                hidden: false,
              },
            ],
          },
          {
            name: "c12",
            key: "c12",
            value: "c12V",
            hidden: true,
          },
        ],
      },
      {
        name: "c2",
        key: "c2",
        value: "c2V",
        hidden: true,
      },
    ],
  },
];

export function removeHiddenMenus(menuList) {
  const menus = [...menuList];
  function go(menus) {
    return menus.flatMap(x => {
      const c = { ...x };
      if (!c.hidden) {
        if (c.children) {
          c.children = go(c.children);
        }
        return [c];
      }
      return [];
    });
  }
  return go(menus);
}

export function getFullPath(menuList, target, byKey) {
  let t = [];
  function go(menuList, result = []) {
    for (const menu of menuList) {
      if (menu[byKey] === target) {
        t = [...result, menu];
        break;
      } else if (menu.children) {
        go(menu.children, [...result, menu]);
      }
    }
  }
  go(menuList);
  return t;
}

export function getAllActiveMenu(menuList, currMenuId) {
  if (currMenuId === "undefined") {
    return "dashboard";
  }
  const fullMenus = getFullPath(menuList, currMenuId, "menuId");
  const menuIds = fullMenus.flatMap(menu => {
    if (!menu.hidden) {
      return [menu.menuId];
    } else {
      return [];
    }
  });
  return menuIds[menuIds.length - 1];
}
