<template>
  <el-dialog title="Edit Company Info" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-position="top">
      <el-row>
        <el-col :lg="16" :xl="13">
          <el-form-item label="Company Type" prop="companyType">
            <el-select style="width: 100%" v-model="dataForm.companyType" clearable placeholder="company type" @change="typeChange">
              <el-option v-for="item in companyTypeList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="18" :xl="15">
          <el-form-item label="Company Name (Option 1)" prop="proposedCompanyName1">
            <el-input v-model="dataForm.proposedCompanyName1" placeholder="Proposed Company Name (Option 1)" class="input-with-select-lg" :oninput="isEn">
              <el-select v-model="dataForm.proposedCompanyType1" slot="append">
                <el-option v-for="item in companyTypeSuffixList[dataForm.companyType]" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="18" :xl="15">
          <el-form-item label="Company Name (Option 2)" prop="proposedCompanyName2">
            <el-input v-model="dataForm.proposedCompanyName2" placeholder="Proposed Company Name (Option 2)" class="input-with-select-lg" :oninput="isEn">
              <el-select v-model="dataForm.proposedCompanyType2" slot="append">
                <el-option v-for="item in companyTypeSuffixList[dataForm.companyType]" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="18" :xl="15">
          <el-form-item label="Company Name (Option 3)" prop="proposedCompanyName3">
            <el-input v-model="dataForm.proposedCompanyName3" placeholder="Proposed Company Name (Option 3)" class="input-with-select-lg" :oninput="isEn">
              <el-select v-model="dataForm.proposedCompanyType3" slot="append">
                <el-option v-for="item in companyTypeSuffixList[dataForm.companyType]" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :lg="16" :xl="13">
          <el-form-item label="Primary Activity" prop="primaryActivity">
            <el-select style="width: 100%" v-model="dataForm.primaryActivity" clearable placeholder="primary activity" popper-class="tradeWrap" filterable>
              <el-option v-for="item in activityList" :key="'Primary' + item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Primary User-Described Activity Description" prop="primaryActivityDescription">
        <el-input
          v-model="dataForm.primaryActivityDescription"
          placeholder="primary activity descption"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          maxlength="78"
          show-word-limit
          resize="none"
          :oninput="isEn"
        />
      </el-form-item>

      <el-row>
        <el-col :lg="16" :xl="13">
          <el-form-item label="Secondary Activity" prop="secondaryActivity">
            <el-select
              style="width: 100%"
              v-model="dataForm.secondaryActivity"
              clearable
              placeholder="secondary activity"
              popper-class="tradeWrap"
              filterable
            >
              <el-option v-for="item in activityList" :key="'Secondary' + item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Secondary User-Described Activity Description" prop="secondaryActivityDescription">
        <el-input
          v-model="dataForm.secondaryActivityDescription"
          placeholder="secondary activity descption"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          maxlength="78"
          show-word-limit
          resize="none"
          :oninput="isEn"
        />
      </el-form-item>
      <!-- 我们同意聘用由星中商务推荐的名义董事1名，我们也理解该名义董事的行为造成的任何法律影响均与EStar无关。 -->
      <el-form-item label="Nominee Director Service" prop="hasNomineeDirector" v-if="isNeedNomineeDirector">
        <el-checkbox class="checkboxCustom" :true-label="1" :false-label="0" v-model="dataForm.hasNomineeDirector">
          We consent to appoint one Nominee Director recommended by EStar Business Services Pte. Ltd. ("EStar"), and we understand that any legal impact
          caused by the Nominee Director's action has nothing to do with EStar.
        </el-checkbox>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import activityList from "../activity.json";
export default {
  props: {
    businessType: {
      type: String,
    },
    isNeedNomineeDirector: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: false,
      activityList: activityList,
      dataForm: {
        id: 0,
        companyType: "",
        proposedCompanyName1: "",
        proposedCompanyType1: "",
        proposedCompanyName2: "",
        proposedCompanyType2: "",
        proposedCompanyName3: "",
        proposedCompanyType3: "",
        primaryActivity: "",
        primaryActivityDescription: "",
        secondaryActivity: "",
        secondaryActivityDescription: "",
        hasNomineeDirector: null,
      },
      dataRule: {
        companyType: [{ required: true, message: "Company Type is required", trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["companyTypeList", "companyTypeSuffixList", "businessStepObj"]),
  },
  methods: {
    ...mapActions("applyment", ["doSaveRevising"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      const copy = JSON.parse(JSON.stringify(data));
      this.dataForm = copy;
    },
    // 公司类型改变时
    typeChange() {
      this.$set(this.dataForm, "proposedCompanyType1", null);
      this.$set(this.dataForm, "proposedCompanyType2", null);
      this.$set(this.dataForm, "proposedCompanyType3", null);
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doSaveRevising({
            ...this.dataForm,
            procedure: {
              step: this.businessStepObj[this.businessType]["companyName"],
            },
          })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tradeWrap .el-select-dropdown__item {
  max-width: 500px;
  white-space: initial;
  line-height: 18px;
  height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
}

.checkboxCustom {
  ::v-deep .el-checkbox__label {
    /* 这两个在技术上是一样的, 为了兼容了浏览器两个都加上 */
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;

    -ms-word-break: break-all !important;
    /* 这个的使用在web-kit中有些危险，他可能会阶段所有东西 */
    word-break: break-all !important;
    /* Instead use this non-standard one: */
    word-break: break-word !important;

    /* 如果浏览器支持的话增加一个连接符(Blink不支持) */
    hyphens: auto !important;

    display: inline-grid;
    white-space: pre-line;
    line-height: 20px;
  }
}
</style>
