var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1200px",
      "title": "Status Track",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_c('el-form', {
    staticClass: "el-form-filter",
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.getDataList(1);
      }
    }
  }, [_c('el-form-item', [_c('el-date-picker', {
    attrs: {
      "value-format": "timestamp",
      "type": "daterange",
      "range-separator": "~",
      "start-placeholder": "Start Date",
      "end-placeholder": "Ending Date"
    },
    on: {
      "change": function ($event) {
        return _vm.getDataList(1);
      }
    },
    model: {
      value: _vm.tradeTimeRange,
      callback: function ($$v) {
        _vm.tradeTimeRange = $$v;
      },
      expression: "tradeTimeRange"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "mt20 tableWrap"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    attrs: {
      "border": "",
      "data": _vm.dataList,
      "row-class-name": _vm.isShowExpend
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "expand"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('el-table', {
          staticClass: "shrink",
          attrs: {
            "data": props.row.extraInfo
          }
        }, [_c('el-table-column', {
          attrs: {
            "label": "Subtotal Description",
            "prop": "subTotalDesp",
            "align": "center"
          }
        }), _c('el-table-column', {
          attrs: {
            "label": "Remark",
            "prop": "remark",
            "align": "center"
          }
        }), _c('el-table-column', {
          attrs: {
            "label": "Attachment",
            "prop": "attachmentUrls",
            "align": "center"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var row = _ref.row;
              return _vm._l(row.attachmentUrls, function (item) {
                return _c('p', {
                  key: item.url,
                  staticClass: "fs12 m0"
                }, [_c('a', {
                  attrs: {
                    "href": item.url,
                    "target": "_target"
                  }
                }, [_vm._v(_vm._s(item.name))])]);
              });
            }
          }], null, true)
        })], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "createAt",
      "header-align": "center",
      "align": "center",
      "label": "Handling Time",
      "formatter": _vm.localDateFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "header-align": "center",
      "label": "Handler"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('p', [_vm._v(" " + _vm._s(scope.row.userInfo.nickname) + " "), _c('span', [_vm._v("(" + _vm._s(scope.row.type) + ")")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "applymentStatus",
      "header-align": "center",
      "align": "center",
      "label": "Applyment Status",
      "formatter": _vm.statusFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "remark",
      "header-align": "center",
      "align": "center",
      "label": "Remark"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "attachmentUrls",
      "header-align": "center",
      "align": "center",
      "label": "Attachment"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.attachmentUrls, function (item) {
          return _c('p', {
            key: item.url
          }, [_c('a', {
            attrs: {
              "href": item.url,
              "target": "_target"
            }
          }, [_vm._v(_vm._s(item.name))])]);
        });
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.size,
      "total": _vm.total,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }