<template>
  <div class="dateWrap">
    <el-date-picker
      v-model="start"
      type="date"
      value-format="yyyy-MM-dd"
      :placeholder="startPlaceholder"
      :style="{ width: `${width}px` }"
      @change="startChange"
    ></el-date-picker>
    <span>~</span>
    <el-date-picker
      v-model="end"
      type="date"
      value-format="yyyy-MM-dd"
      :placeholder="endPlaceholder"
      :style="{ width: `${width}px` }"
      @change="endChange"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    startDate: {
      type: String,
      default: function () {
        return "";
      },
    },
    endDate: {
      type: String,
      default: function () {
        return "";
      },
    },
    startPlaceholder: {
      type: String,
      required: false,
      default: function () {
        return "Start Date";
      },
    },
    endPlaceholder: {
      type: String,
      required: false,
      default: function () {
        return "End Date";
      },
    },
    width: {
      type: Number,
      required: false,
      default: function () {
        return 200;
      },
    },
  },
  data() {
    return {
      start: "",
      end: "",
    };
  },
  watch: {
    startDate: {
      handler: function (data) {
        this.start = data;
      },
      deep: true,
      immediate: true,
    },
    endDate: {
      handler: function (data) {
        this.end = data;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    startChange() {
      this.$emit("startSelectChange", this.start);
    },
    endChange() {
      this.$emit("endSelectChange", this.end);
    },
  },
};
</script>

<style lang="scss" scoped>
.dateWrap {
  display: inline-block;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  height: 36px;
  line-height: 36px;

  ::v-deep .el-input {
    .el-input__inner {
      border: none;
      height: 32px;
      line-height: 32px;
      text-align: center;
    }
  }
}
</style>
