<template>
  <el-dialog width="1100px" :title="!dataForm.id ? 'New Protocol' : 'Edit Protocol'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="122px">
      <el-form-item label="Protocol Type" prop="type">
        <el-select style="width: 450px" v-model="dataForm.type" placeholder="Select type">
          <el-option v-for="item in protocolTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Content" prop="content">
        <el-editor :height="500" type="pure" v-model="dataForm.content" />
      </el-form-item>
      <el-form-item label="File" prop="payNowUen">
        <el-file-uploader v-model="dataForm.fileUrl"></el-file-uploader>
      </el-form-item>
      <el-form-item label="Content(CN)" prop="contentCn">
        <el-editor :height="500" type="pure" v-model="dataForm.contentCn" />
      </el-form-item>
      <el-form-item label="File(CN)" prop="payNowUen">
        <el-file-uploader v-model="dataForm.fileUrlCn"></el-file-uploader>
      </el-form-item>
      <!-- <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">Disable</el-radio>
          <el-radio :label="1">Enable</el-radio>
        </el-radio-group>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    protocolTypes: {
      type: Array,
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        type: "",
        content: undefined,
        contentCn: undefined,
        fileUrl: undefined,
        fileUrlCn: undefined,
        status: 1,
      },
      dataRule: {
        type: [{ required: true, message: "protocol type is required", trigger: "blur" }],
        // content: [{ required: true, message: "content is required", trigger: "blur" }],
        // contentCn: [{ required: true, message: "content(CN) is required", trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  methods: {
    init(id) {
      this.submitControl = true;
      this.visible = true;
      this.$nextTick().then(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          this.getDetail(id);
        } else {
          this.dataForm = {
            id: 0,
            type: "",
            content: undefined,
            contentCn: undefined,
            fileUrl: undefined,
            fileUrlCn: undefined,
            status: 1,
          };
        }
      });
    },
    //详情
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/stProtocolManage/${id}/detail`);
      this.dataForm = { ...data };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.$http.post(
            this.dataForm.id ? `/admin/stProtocolManage/${this.dataForm.id}/update` : `/admin/stProtocolManage/create`,
            this.dataForm,
          );
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
