<template>
  <div class="pl-module-item">
    <el-checkbox class="pl20" @change="changeHandler" v-model="data.checked"></el-checkbox>
    <div class="content" @click="boxClickHandler">
      <span>{{ data.name }}</span>
    </div>
    <div class="right">
      <xmeta v-if="!selectable && metas.length > 0" :metas="metas" :data="data" @update="updateHandler">
        <i class="el-icon-setting r-opts"></i>
      </xmeta>
    </div>
  </div>
</template>

<script>
import Xmeta from "./xmeta";
export default {
  components: {
    Xmeta,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          checked: false,
          id: undefined,
          name: "",
          imgUrl: "",
          storage: undefined,
          bandwidth: undefined,
          quantity: undefined,
        };
      },
    },
    metas: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 是否直接选择
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.data = newValue;
    },
  },
  methods: {
    changeHandler() {
      this.$emit("input", this.data);
    },
    boxClickHandler() {
      if (this.selectable) {
        // 直接选择
        const copy = { ...this.data };
        copy.checked = !copy.checked;
        this.data = copy;
        this.$emit("input", this.data);
      }
    },
    updateHandler(data) {
      this.data = data;
      this.$emit("input", this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.pl-module-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 92px;
  margin-bottom: 15px;
  background: #fbfcff;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 0%);
  border-radius: 4px;
  border: 1px solid #f0f5fa;

  &.selected {
    border: 1px solid #2878f0;
  }

  .left {
    margin: 0;
    padding: 0;
    margin-left: 15px;
    width: 60px;
    height: 60px;
  }

  .content {
    flex: 1;
    height: 100%;
    margin: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .right {
    margin: 0;
    padding: 0;
    width: 120px;
    display: flex;
    align-items: center;
  }

  .r-opts {
    transform: rotate(90deg);
    position: absolute;
    right: 8px;
    top: 12px;
    color: #303133;
    cursor: pointer;
  }
}
</style>
