<template>
  <div class="flex align-center justify-center login-wrap">
    <div class="flex align-center justify-center firm-bg-img">
      <img src="@/assets/img/logo.png" alt="" class="logo" />
      <img src="@/assets/img/firm.png" alt="" />
    </div>
    <div class="flex align-center justify-center ms-login">
      <div class="from-wrap">
        <div class="ms-title">
          <span class="black45 fwb">EStar</span>
        </div>
        <el-form :model="accountForm" :rules="accountRule" ref="accountFormRef" @submit.native="accountFormSubmit" hide-required-asterisk>
          <el-form-item prop="username">
            <el-input type="text" v-model="accountForm.username" placeholder="Email" />
          </el-form-item>
          <el-form-item prop="password" style="margin-bottom: 50px">
            <el-input type="password" v-model="accountForm.password" placeholder="password" />
          </el-form-item>
          <el-button :disabled="accountBtnDisabled" @click="accountFormSubmit" class="login-btn" native-type="submit" type="primary">Login</el-button>
        </el-form>
        <div class="pt12">
          <router-link to="/forget">Forgot password?</router-link>
        </div>
      </div>
    </div>
    <div class="footBox">
      <p class="fs12">
        Copyright© 2021 星中(广州)商务咨询有限公司
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021165700号</a>
      </p>
    </div>
  </div>
</template>
<script>
import qs from "querystring";
import { checkIsLogin, saveLoginInfo } from "@/utils/auth";
export default {
  data() {
    return {
      accountForm: {
        username: "", // lisi@qq.com
        password: "", // abc123
      },
      accountRule: {
        username: [{ required: true, message: "Email is required" }],
        password: [{ required: true, message: "password is required" }],
      },
      accountBtnDisabled: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (checkIsLogin()) {
      next(vm => {
        vm.$router.push({ name: "home" });
      });
    } else {
      next();
    }
  },
  methods: {
    accountFormSubmit(e) {
      e.preventDefault();
      this.$refs["accountFormRef"].validate(async valid => {
        if (valid) {
          this.accountBtnDisabled = true;
          // 登录
          const { code, msg, data } = await this.$http.post(
            "/login",
            qs.stringify({
              username: "admin:" + this.accountForm.username,
              password: this.accountForm.password,
            }),
            { headers: { "Content-Type": "application/x-www-form-urlencoded" } },
          );
          if (code === 401 || code === 400) {
            this.$notify({
              title: "Login Fail",
              message: msg,
              type: "error",
              position: "top-right",
              offset: 100,
            });
            this.accountBtnDisabled = false;
          } else {
            saveLoginInfo(data);
            this.$router.push({ name: "home" });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  min-height: 800px;
  background: #f1f5fc;
  position: relative;

  .firm-bg-img {
    width: 60%;
    height: 100%;
    background: #f1f5fc;
    position: relative;

    .logo {
      position: absolute;
      top: 26px;
      left: 30px;
      z-index: 100;
      height: 50px;
    }
  }

  .ms-login {
    width: 40%;
    height: 100%;
    background: #fafcff;
    box-shadow: 0px 21px 40px 0px rgba(208, 229, 255, 0.26);
    .from-wrap {
      width: 430px;
      margin: -60px auto 0px;
      .ms-title {
        width: 100%;
        text-align: center;
        padding-bottom: 60px;
        font-size: 22px;
      }
    }
    ::v-deep .el-input__inner {
      background: #ffffff;
      border-color: #e1ecff;
      border-radius: 6px;
      height: 50px;
      line-height: 50px;
    }
  }

  .login-btn {
    text-align: center;
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
  }

  .footBox {
    position: absolute;
    bottom: 14px;
    left: 0;
    width: 60%;
    text-align: center;
    line-height: 0em;
    color: #667288;
    a {
      color: #667288;
    }
  }
}
</style>
