<template>
  <div class="page-container">
    <div class="mod-user">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList(1)">
        <div class="pb20">
          <el-button v-if="isAuth('sys:client:save')" type="primary" @click="addOrUpdateHandle()">Add Client code</el-button>
        </div>
        <div>
          <el-form-item>
            <el-select
              v-model="dataForm.clientCode"
              clearable
              filterable
              remote
              :remote-method="getClientCodeRemote"
              @clear="getClientCodeRemote('')"
              @focus="getClientCodeRemote('')"
              :loading="clientCodeLoading"
              placeholder="Client code"
            >
              <el-option
                v-for="(item, sy) in clientCodeList"
                :label="item.clientCode"
                :key="`${item.clientCode}${sy}`"
                :value="item.clientCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="dataForm.uen"
              clearable
              filterable
              remote
              :remote-method="getUenRemote"
              @clear="getUenRemote('')"
              @focus="getUenRemote('')"
              :loading="uenLoading"
              placeholder="UEN"
            >
              <el-option v-for="(item, index) in uenList" :key="`${item.uen}${index}`" :label="item.uen" :value="item.uen"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="dataForm.country" clearable filterable placeholder="Country">
              <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker-range
              :startDate="dataForm.createTimeStart"
              :endDate="dataForm.createTimeEnd"
              startPlaceholder="Create (Start Date)"
              endPlaceholder="Create (End Date)"
              @startSelectChange="
                val => {
                  dataForm.createTimeStart = val;
                }
              "
              @endSelectChange="
                val => {
                  dataForm.createTimeEnd = val;
                }
              "
            ></el-date-picker-range>
          </el-form-item>
          <el-form-item>
            <el-date-picker-range
              :startDate="dataForm.passTimeStart"
              :endDate="dataForm.passTimeEnd"
              startPlaceholder="Pass (Start Date)"
              endPlaceholder="Pass (End Date)"
              @startSelectChange="
                val => {
                  dataForm.passTimeStart = val;
                }
              "
              @endSelectChange="
                val => {
                  dataForm.passTimeEnd = val;
                }
              "
            ></el-date-picker-range>
          </el-form-item>
          <el-form-item>
            <el-select v-model="dataForm.status" multiple clearable placeholder="Status">
              <el-option v-for="item in clientCodeStatusList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <div style="display: inline-block">
            <el-form-item>
              <el-select v-model="dataForm.introducedBy" clearable placeholder="Introduced By" class="controlLeft" @change="introducedByChange">
                <el-option v-for="item in introducedByList" :label="item.title" :value="item.value" :key="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="fml10" v-if="dataForm.introducedBy">
              <el-select-all
                class="controlRight"
                :width="220"
                :optionList="commissionRequiredArr"
                placeholder="Commission"
                @elSelectAllChange="
                  list => {
                    dataForm.commission = list;
                  }
                "
              ></el-select-all>
            </el-form-item>
          </div>
          <el-form-item>
            <el-input v-model="dataForm.contact" placeholder="Contact Info" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.introducer" placeholder="Introducer" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.remark" placeholder="Description" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        max-height="640"
        :data="dataList"
        v-loading="dataListLoading"
        @sort-change="tableSortChange"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
        style="width: 100%"
      >
        <el-table-column
          prop="clientCode"
          header-align="center"
          align="center"
          label="Client Code"
          width="125"
          fixed="left"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="uens" header-align="center" align="center" label="UEN" min-width="120">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.uens" :key="`uens${item.uen}`">{{ index > 0 ? ", " : "" }}{{ item.uen }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="country" header-align="center" align="center" label="Country" min-width="120"></el-table-column>
        <el-table-column prop="createTime" header-align="center" align="center" label="Create Time" min-width="130" sortable="custom"></el-table-column>
        <el-table-column prop="passTime" header-align="center" align="center" label="Pass Time" min-width="120" sortable="custom"></el-table-column>
        <el-table-column prop="status" header-align="center" align="center" label="Status" min-width="126">
          <template slot-scope="scope">
            <p class="lh20 mt0 mb0">{{ statusFormat(scope.row.status) }}</p>
            <el-link type="primary" :underline="false" @click="openStatusHistoryModal(scope.row.id)">(Track)</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="introducedBy"
          header-align="center"
          align="center"
          label="Introduced By"
          min-width="120"
          :formatter="introducedByFormat"
        ></el-table-column>
        <el-table-column header-align="center" align="center" label="Commission" min-width="130">
          <template slot-scope="scope">
            <p v-for="item in scope.row.commissionList" :key="`commission${item.id}`" class="mt0 mb0">
              {{ item.name }} - {{ multiply(item.commissionRate) }}
              <el-popover placement="bottom" trigger="hover" v-if="item.remark">
                <div class="fs12 lh20 enbr" style="max-width: 300px">{{ item.remark }}</div>
                <span slot="reference" class="fs14 lightGray el-icon-info"></span>
              </el-popover>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="contact" header-align="center" align="center" label="Contact Info" min-width="200"></el-table-column>
        <el-table-column prop="introducer" header-align="center" align="center" label="Introducer" min-width="120"></el-table-column>
        <el-table-column prop="remark" header-align="center" align="center" label="Description" min-width="120"></el-table-column>
        <el-table-column header-align="center" align="center" width="120" fixed="right" label="Action">
          <template slot-scope="scope">
            <el-button
              v-if="isAuth('sys:client:update') && (scope.row.status == 0 || scope.row.status == 2)"
              type="text"
              class="fwb"
              @click="addOrUpdateHandle(scope.row)"
            >
              Edit
            </el-button>
            <el-button
              v-if="isAuth('sys:client:audit') && (scope.row.status == 0 || scope.row.status == 2)"
              type="text"
              class="fwb"
              @click="reviewHandle(scope.row)"
            >
              Review
            </el-button>
            <el-button v-if="isAuth('sys:client:delete')" type="text" class="fwb" @click="deleteHandle(scope.row)">Delete</el-button>
            <!-- 审核通过后的修改 -->
            <el-button
              v-if="isAuth('sys:client:revise') && scope.row.status == 1"
              type="text"
              class="fwb"
              @click="statusUpdateHandle(scope.row, 0, 'Revise')"
            >
              Revise
            </el-button>
            <!-- 禁用 -->
            <el-button
              v-if="isAuth('sys:client:inactive') && scope.row.status != 3"
              type="text"
              class="fwb"
              @click="statusUpdateHandle(scope.row, 3, 'Inactive')"
            >
              Inactive
            </el-button>
            <!-- 禁用后，再启用 -->
            <el-button
              v-if="isAuth('sys:client:reActive') && scope.row.status == 3"
              type="text"
              class="fwb"
              @click="statusUpdateHandle(scope.row, 0, 'Re-active')"
            >
              Re-active
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
      <!-- 状态操作日志弹框 -->
      <status-history ref="statusHistoryModal"></status-history>
      <!-- client code 复核弹框 -->
      <client-review ref="clientReviewModal" :commissionRequiredList="commissionRequiredList" @refreshStatus="getDataList"></client-review>
    </div>
  </div>
</template>

<script>
import AddOrUpdate from "./modal/AddOrUpdate";
import StatusHistory from "./modal/StatusHistory";
import ClientReview from "./modal/ClientReview";
import { multiply } from "@/utils";
import ElSelectAll from "@/components/ElSelectAll";
import ElDatePickerRange from "@/components/ElDatePickerRange";
import paginationDelete from "@/mixins/paginationDelete.js";
import { mapState } from "vuex";
export default {
  mixins: [paginationDelete],
  data() {
    return {
      dataForm: {
        country: "",
        status: "",
        clientCode: "",
        uen: "",
        remark: "",
        contact: "",
        createTimeStart: "",
        createTimeEnd: "",
        passTimeStart: "",
        passTimeEnd: "",
        introducedBy: null,
        introducer: "",
        commission: "",
        "orders[0].asc": false,
        "orders[0].column": "id",
      },
      uenList: [],
      dataList: [],
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      uenLoading: false,
      clientCodeLoading: false,
      clientCodeList: [],
      commissionRequiredList: [],
    };
  },
  components: {
    AddOrUpdate,
    StatusHistory,
    ClientReview,
    ElSelectAll,
    ElDatePickerRange,
  },
  computed: {
    ...mapState("constant", ["clientCodeStatusList", "countryList", "introducedByList"]),
    multiply() {
      return val => {
        return multiply(val, 100) + "%";
      };
    },
    commissionRequiredArr() {
      const itemData = this.commissionRequiredList.map(item => {
        return { title: `${item.name} - ${this.multiply(item.commissionRate)}`, value: item.id };
      });
      return itemData;
    },
  },
  activated() {
    this.getUenRemote("");
    this.getDataList();
    this.getCommissionRequiredList();
  },
  methods: {
    // 状态格式化
    statusFormat(cellValue) {
      const one = this.clientCodeStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    introducedByFormat(row, column, cellValue) {
      const one = this.introducedByList.find(i => i.value === cellValue);
      return one?.title;
    },
    // 获取client code列表
    async getClientCodeRemote(query) {
      this.clientCodeLoading = true;
      const { code, data } = await this.$http.get("/admin/clientCodeInfoMa/getList", {
        params: {
          keywords: query,
        },
      });
      this.clientCodeLoading = false;
      if (code === 200) {
        this.clientCodeList = data;
      } else {
        this.clientCodeList = [];
      }
    },
    // 获取uen列表
    async getUenRemote(query) {
      this.uenLoading = true;
      const { code, data } = await this.$http.get("/admin/uenMa/getList", {
        params: {
          uen: query,
        },
      });
      this.uenLoading = false;
      if (code === 200) {
        this.uenList = data.filter(ele => ele != null && ele.uen != null);
        // 首位添加N/A
        const na = "N/A";
        if (na.includes(query)) {
          this.uenList.unshift({
            companyName: "N/A",
            transactionNo: "N/A",
            uen: "N/A",
          });
        }
      } else {
        this.uenList = [];
      }
    },
    // Introduced By - Change
    introducedByChange() {
      if (!this.dataForm.introducedBy) {
        this.$set(this.dataForm, "commission", "");
      }
    },
    // table - 排序
    tableSortChange(column) {
      if (column.prop == "createTime") {
        this.dataForm["orders[0].column"] = "id";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "passTime") {
        this.dataForm["orders[0].column"] = "t1.pass_time";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "clientCode") {
        this.dataForm["orders[0].column"] = "t1.client_code";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      }
    },
    // 获取数据分页
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
        ...this.dataForm,
      };
      const { code, data } = await this.$http.get("/admin/clientCodeInfoManage/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 状态操作日志
    openStatusHistoryModal(id) {
      this.$refs.statusHistoryModal.init(id);
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.$refs.addOrUpdate.init(row);
    },
    // 删除
    async deleteHandle(row) {
      if (row.hasLinkedTransactions) {
        this.$message({
          type: "warning",
          dangerouslyUseHTMLString: true,
          message: `<div>
          <strong>Delete failed.</strong>
          <p class="mt4 lh16">Please check with linked transactions at "Engagement" section, and delete those linked transactions before this action.</p>
          </div>`,
        });
        return;
      }
      await this.$confirm(`Delete this Client code. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const { code, msg } = await this.$http.post(`/admin/clientCodeInfoManage/${row.id}/delete`, {});
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => {
            // mixins
            this.singleDataDelete();
            this.getDataList();
          },
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 复核
    reviewHandle(row) {
      this.$refs.clientReviewModal.init(row);
    },
    // 审核通过后的修改、禁用、禁用后启用
    async statusUpdateHandle(row, status, tipe) {
      await this.$confirm(`${tipe} this Client code. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      this.$http.post(`/admin/clientCodeInfoManage/${row.id}/audit`, { status }).then(({ code, msg }) => {
        if (code === 200) {
          this.getDataList();
          this.$message.success("success");
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 获取渠道商列表
    async getCommissionRequiredList() {
      const { data } = await this.$http.get("/admin/stCommission/getList");
      this.commissionRequiredList = data || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.fml10 {
  margin-left: -10px;
}

.controlLeft {
  ::v-deep input {
    // border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ::v-deep .el-input__inner:focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input__inner.is-focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input:focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }

  ::v-deep .el-input.is-focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }
}

.controlRight {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  ::v-deep input {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.lightGray {
  color: #bfcbd9;
}
</style>
