import VueCookie from "vue-cookie";
import router from "@/router";

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  localStorage.removeItem("authToken");
  const hostname = window.location.hostname;
  const splits = hostname.split(".");
  const crossDomain = "." + splits[splits.length - 2] + "." + splits[splits.length - 1];
  VueCookie.delete("is_auth", { domain: crossDomain });
  router.options.isFill = false;
  router.options.isAddDynamicMenuRoutes = false;
}

/**
 * 检查登录信息
 */
export function checkIsLogin() {
  return localStorage.getItem("authToken") !== null;
}

/**
 * 设置登录信息
 */
export function saveLoginInfo(access_token) {
  localStorage.setItem("authToken", access_token);
  const hostname = window.location.hostname;
  const splits = hostname.split(".");
  const crossDomain = "." + splits[splits.length - 2] + "." + splits[splits.length - 1];
  VueCookie.set("is_auth", "1", { domain: crossDomain });
}
