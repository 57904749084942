<template>
  <el-dialog title="Fund's Officer Details - Shareholder(s)/Director(s)" width="1100px" :close-on-click-modal="false" :visible.sync="visible">
    <div class="company-member-list pb60">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="addMember()">Add</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table border :data="dataList" style="width: 100%">
          <el-table-column prop="fullName" header-align="center" align="center" width="250" label="Name"></el-table-column>
          <el-table-column prop="memberType" header-align="center" align="center" width="250" label="Type" :formatter="memberTypeFormat"></el-table-column>
          <el-table-column
            prop="positionHeld"
            header-align="center"
            align="center"
            width="250"
            label="Position Held"
            :formatter="positionHeldFormat"
          ></el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" min-width="200" label="Action">
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.positionHeld != 'NOMINEE_DIRECTOR' &&
                  isApplymentAuth('company:coordinate') &&
                  (applymentStatus === 'REVISING' ||
                    applymentStatus === 'CORRECTED' ||
                    applymentStatus === 'ILLEGAL' ||
                    applymentStatus === 'AUDIT_FAIL' ||
                    applymentStatus === 'REFUND_APPLY_DENY' ||
                    applymentStatus === 'REFUND_APPLY_REJECT' ||
                    applymentStatus === 'ACRA_REJECTED')
                "
              >
                <el-button type="text" size="small" @click="updateHandle(scope.row)">Edit</el-button>
                <el-button type="text" size="small" @click="deleteMember(scope.row.id)">Delete</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- (拟设)基金的直接股东/董事信息 -->
    <update-officer
      :businessType="businessType"
      :fundInfoList="fundInfoList"
      v-if="updateOfficerVisible"
      ref="updateOfficer"
      @change="doUpdateMember"
    ></update-officer>
  </el-dialog>
</template>

<script>
import UpdateOfficer from "./UpdateOfficer";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    UpdateOfficer,
  },
  props: {
    stepName: {
      type: String,
    },
    applymentId: {
      type: String,
    },
    applymentStatus: {
      type: String,
    },
    businessType: {
      type: String,
    },
    fundInfoList: {
      type: Array,
    },
  },
  data() {
    return {
      visible: false,
      updateOfficerVisible: false,
      dataList: [],
      companyFundMemberList: [],
    };
  },
  computed: {
    ...mapState("constant", ["companyMemberTypes", "positionHelds", "businessStepObj"]),
  },
  methods: {
    ...mapActions("applyment", ["doSaveRevising", "onlyUpdateDetail"]),
    memberTypeFormat(row, column, v) {
      const one = this.companyMemberTypes.find(i => i.value === v);
      return one?.title;
    },
    positionHeldFormat(row, column, v) {
      const one = this.positionHelds.find(i => i.value === v);
      return one?.title;
    },
    // 初始化
    async init(companyFundMemberList) {
      this.visible = true;
      await this.$nextTick();
      const copy = JSON.parse(JSON.stringify(companyFundMemberList));
      this.dataList = copy;
    },
    async addMember() {
      this.updateOfficerVisible = true;
      await this.$nextTick();
      this.$refs.updateOfficer.init();
    },
    async updateHandle(row) {
      this.updateOfficerVisible = true;
      await this.$nextTick();
      this.$refs.updateOfficer.init(row);
    },
    //删除成员
    async deleteMember(id) {
      this.$confirm(`Remove this Member. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const { code, msg } = await this.$http.post(`/admin/companyMemberManage/${id}/deleteMember`);
        if (code === 200) {
          const one = this.dataList.find(i => i.id === id);
          this.dataList = this.dataList.filter(i => i !== one);
          this.doRevising();
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 更新成员列表数据 - 子组件触发
    async doUpdateMember(dataForm) {
      const copyDataList = JSON.parse(JSON.stringify(this.dataList));
      dataForm.applymentId = this.applymentId;
      if (dataForm.id) {
        const { code, msg } = await this.$http.post(`/admin/companyMemberManage/${dataForm.id}/updateMember`, dataForm);
        if (code === 200) {
          const one = this.dataList.find(i => i.id === dataForm.id);
          const idx = this.dataList.indexOf(one);
          const copy = JSON.parse(JSON.stringify(dataForm));
          copyDataList[idx] = copy;
          this.dataList = copyDataList;
          this.doRevising();
        } else {
          this.$message.error(msg);
        }
      } else {
        const { code, msg, data } = await this.$http.post(`/admin/companyMemberManage/addMember`, dataForm);
        if (code === 200) {
          this.dataList = [...copyDataList, data];
          this.doRevising();
        } else {
          this.$message.error(msg);
        }
      }
    },
    //提交步骤修改数据
    async doRevising() {
      const submitData = {
        id: this.applymentId,
        companyFundMemberList: this.dataList,
        procedure: {
          step: this.businessStepObj[this.businessType][this.stepName],
        },
      };
      this.doSaveRevising(submitData)
        .then(() => {
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.updateOfficerVisible = false;
            },
          });
        })
        .catch(msg => {
          this.$message.error(msg);
        });
    },
  },
};
</script>
