<template>
  <div class="company-applyment-module">
    <!-- 状态 -->
    <el-affix
      :class="{ 'applyment-page-header-top': affixed, 'applyment-page-header-shrink': sidebarFold, 'applyment-page-header-unfold': !sidebarFold }"
      @change="handleAffixChange"
      :offset="104"
    >
      <el-card>
        <div class="applyment-page-header">
          <div>
            <div class="status">
              {{ applymentStatusFormat(detail.applymentStatus) }}
              <span v-if="detail.applymentStatus == 'REFUND_HANDING'" class="fs12">({{ refundStatusFormat(detail.orderInfo.orderRefund.status) }})</span>
              <span v-if="detail.amends" class="fs12">(Supplemental: {{ amendsStatusFormat(detail.amends) }})</span>
              <span v-if="detail.hasRenew" class="fs14">{{ renewStatusFormat(detail.linkedRenewStatus) }}</span>
              <span v-if="detail.laterPay" class="fs12">
                (Credit:
                <template v-if="detail.orderInfo">
                  {{ orderStatusFormat(detail.orderInfo.status) }}
                </template>
                )
              </span>
            </div>
            <div class="page-header-heading-left">
              <div class="heading-title">Transaction No.: {{ detail.applymentNo }}</div>
            </div>
            <div class="page-header-heading-content">
              <el-descriptions :column="4" direction="vertical">
                <el-descriptions-item label="Transaction No.">{{ detail.applymentNo }}</el-descriptions-item>
                <el-descriptions-item label="Checker">
                  <user-toggle :userList="userList" :user="detail.checker" ownershipRole="checker" />
                </el-descriptions-item>
                <el-descriptions-item label="Reviewer">
                  <user-toggle :userList="userList" :user="detail.auditor" ownershipRole="auditor" />
                </el-descriptions-item>
                <el-descriptions-item label="Coordinator">
                  <user-toggle :userList="userList" :user="detail.coordinator" ownershipRole="coordinator" />
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div class="page-header-heading-right">
            <div class="opts">
              <!-- 认领日志 -->
              <el-button type="text" icon="el-icon-tickets" @click="openOwnershipHistoryModal">Ownership History</el-button>
              <!-- 状态操作日志 -->
              <el-button type="text" icon="el-icon-tickets" @click="openStatusHistoryModal">Status Track</el-button>
              <!-- 发起退款 - 业务 -->
              <el-button
                v-if="
                  (detail.applymentStatus === 'ILLEGAL' ||
                    detail.applymentStatus === 'AUDIT_FAIL' ||
                    detail.applymentStatus === 'ACRA_REJECTED' ||
                    detail.applymentStatus === 'REFUND_APPLY_DENY' ||
                    detail.applymentStatus === 'REFUND_APPLY_REJECT') &&
                  detail.orderInfo &&
                  detail.orderInfo.status == 'COMPLETE' &&
                  isApplymentAuth('company:coordinate')
                "
                type="primary"
                size="medium"
                @click="handleInitiateRefund"
              >
                Apply for Refund
              </el-button>
              <!-- 退款审核 - 初审 -->
              <el-button
                v-if="detail.applymentStatus === 'REFUND_APPLYING' && isApplymentAuth('company:check')"
                type="primary"
                size="medium"
                @click="handleRefundJudge('check')"
              >
                Refund Check
              </el-button>
              <!-- 退款审核 - 复核 -->
              <el-button
                v-if="detail.applymentStatus === 'REFUND_APPLY_AGREED' && isApplymentAuth('company:audit')"
                type="primary"
                size="medium"
                @click="handleRefundJudge('audit')"
              >
                Refund Review
              </el-button>
              <!-- 退款信息补充 - 业务 -->
              <el-button
                v-if="
                  detail.applymentStatus === 'REFUND_HANDING' &&
                  detail.orderInfo &&
                  detail.orderInfo.orderRefund &&
                  detail.orderInfo.orderRefund.status == 'REFUND_INFO_NOT_ENOUGH' &&
                  isApplymentAuth('company:coordinate')
                "
                type="primary"
                size="medium"
                @click="handleRefundInfoUpdate"
              >
                Refund Info Update
              </el-button>
              <!-- ACRA申请 - 业务 -->
              <el-button
                v-if="detail.applymentStatus === 'AUDIT_SUCCESS' && isApplymentAuth('company:coordinate')"
                type="primary"
                size="medium"
                @click="handleAcraApply"
              >
                ACRA Processing
              </el-button>
              <!-- ACRA反馈 - 业务 -->
              <el-button
                v-if="detail.applymentStatus === 'ACRA_APPLYING' && isApplymentAuth('company:coordinate')"
                type="primary"
                size="medium"
                @click="handleAcraFeedback"
              >
                ACRA Feedback
              </el-button>
              <!-- 下补款单 - 业务 -->
              <el-button
                v-if="
                  detail.amends != 1 &&
                  (detail.applymentStatus === 'ACRA_APPLYING' || detail.applymentStatus === 'REVISING') &&
                  isApplymentAuth('company:coordinate')
                "
                type="primary"
                size="medium"
                @click="handleMakeAmends"
              >
                Make Supplemental
              </el-button>
              <!-- 初审 -->
              <el-button
                v-if="(detail.applymentStatus === 'CHECKING' || detail.applymentStatus === 'REVISE_FINISH') && isApplymentAuth('company:check')"
                type="primary"
                size="medium"
                @click="handleCheckFinish"
              >
                Finish Check
              </el-button>
              <!-- 复核 -->
              <el-button
                v-if="detail.applymentStatus === 'AUDITING' && isApplymentAuth('company:audit')"
                type="primary"
                size="medium"
                @click="handleAuditFinish"
              >
                Finish Review
              </el-button>
              <!-- 驳回 -->
              <el-button
                v-if="
                  (detail.applymentStatus === 'ILLEGAL' ||
                    detail.applymentStatus === 'AUDIT_FAIL' ||
                    detail.applymentStatus === 'ACRA_REJECTED' ||
                    detail.applymentStatus === 'REVISING' ||
                    detail.applymentStatus === 'CORRECTED' ||
                    detail.applymentStatus === 'REFUND_APPLY_DENY' ||
                    detail.applymentStatus === 'REFUND_APPLY_REJECT') &&
                  isApplymentAuth('company:coordinate')
                "
                type="danger"
                size="medium"
                @click="handleReject"
              >
                Reject to Client
              </el-button>
              <!-- 提交修改内容 -->
              <el-button
                v-if="
                  (detail.applymentStatus === 'REVISING' ||
                    detail.applymentStatus === 'REFUND_APPLY_DENY' ||
                    detail.applymentStatus === 'REFUND_APPLY_REJECT' ||
                    detail.applymentStatus === 'CORRECTED') &&
                  isApplymentAuth('company:coordinate')
                "
                type="primary"
                size="medium"
                @click="handleReviseFinish"
              >
                Revision Submit
              </el-button>
              <!-- 申请终止交易 -->
              <el-button
                v-if="
                  (detail.applymentStatus === 'FINISH' || detail.applymentStatus === 'SUSPEND_TRANSACTION_APPLY_REJECT') &&
                  (detail.businessType === 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType === 'FUND_ADMIN_CUSTOMIZED') &&
                  isApplymentAuth('company:coordinate')
                "
                type="primary"
                size="medium"
                @click="handleTerminationApply"
              >
                Apply for Termination
              </el-button>
              <!-- 申请终止交易 - 复核 -->
              <el-button
                v-if="
                  detail.applymentStatus === 'SUSPEND_TRANSACTION_APPLYING' &&
                  (detail.businessType === 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType === 'FUND_ADMIN_CUSTOMIZED') &&
                  isApplymentAuth('company:audit')
                "
                type="primary"
                size="medium"
                @click="handleAuditTerminated"
              >
                Termination Review
              </el-button>
            </div>
          </div>
        </div>
      </el-card>
    </el-affix>

    <!-- 步骤条 -->
    <div
      class="steps-bar-wrapper"
      v-if="!(detail.isLaterService && (detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType == 'FUND_ADMIN_CUSTOMIZED'))"
    >
      <el-steps direction="vertical" align-center :space="80">
        <el-step
          :status="getStepStatus(getStepName('companyName'))"
          @click.native="jumpStep(getStepName('companyName'))"
          v-if="getStepName('companyName')"
        ></el-step>
        <el-step
          :status="getStepStatus(getStepName('kycQuestionnaire'))"
          @click.native="jumpStep(getStepName('kycQuestionnaire'))"
          v-if="getStepName('kycQuestionnaire')"
        ></el-step>
        <el-step
          :status="getStepStatus(getStepName('companyDetails'))"
          @click.native="jumpStep(getStepName('companyDetails'))"
          v-if="getStepName('companyDetails')"
        ></el-step>
        <el-step :status="getStepStatus(getStepName('upload'))" @click.native="jumpStep(getStepName('upload'))" v-if="getStepName('upload')"></el-step>
      </el-steps>
    </div>

    <!-- Step0: 基础信息 -->
    <div class="step mt50">
      <el-card shadow="hover">
        <base-info :detail="detail" :invoiceObj="invoiceObj" :quotationVList="quotationVList" @refreshInvoiceList="getInvoiceList" />
      </el-card>
    </div>

    <div v-if="!(detail.isLaterService && (detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || detail.businessType == 'FUND_ADMIN_CUSTOMIZED'))">
      <!-- Step1: 公司设立 & 股东信息 -->
      <div class="pt40" :ref="getStepName('companyName')" v-if="getStepName('companyName')"></div>
      <div id="Step1" class="step mt40" v-if="getStepName('companyName')">
        {{ void (Step1 = stepsTree[getStepName("companyName")]) }}
        <step-header :label="getStepName('companyName')">
          <div class="state mr20">
            <!-- 步骤操作日志 -->
            <el-button type="text" icon="el-icon-tickets" class="mr20" @click="openStepsHistoryModal(getStepName('companyName'))">Steps History</el-button>
            <div
              class="border-type"
              :class="{
                'no-pass-button': Step1 && Step1.status == 0,
                'pass-button': Step1 && Step1.status == 1,
                'revised-button': Step1 && Step1.status == 2,
                'no-checking-button': Step1 && Step1.status == 3,
              }"
              v-if="Step1"
            >
              <span class="el-icon-error" v-if="Step1 && Step1.status == 0"></span>
              <span class="el-icon-success" v-if="Step1 && Step1.status == 1"></span>
              <span class="el-icon-warning" v-if="Step1 && Step1.status == 2"></span>
              <span class="el-icon-mouse" v-if="Step1 && Step1.status == 3"></span>
              {{ stepStatusFormat(Step1) }}
            </div>
          </div>
          <div class="opts">
            <el-button type="primary" plain v-if="checkStepVisible(getStepName('companyName'))" @click="openStepModal(getStepName('companyName'))">
              Review
            </el-button>
          </div>
        </step-header>
        <el-card shadow="hover">
          <company-info :detail="detail" :isNeedNomineeDirector="isNeedNomineeDirector" />
          <shareholders-detail
            class="mt40"
            stepName="companyName"
            :applymentId="detail.id"
            :applymentStatus="detail.applymentStatus"
            :companyMemberList="detail.companyMemberList"
            :hasNomineeDirector="detail.hasNomineeDirector"
            :businessType="detail.businessType"
            :isNeedNomineeDirector="isNeedNomineeDirector"
          />
        </el-card>
      </div>

      <!-- Step2: 问卷调查 -->
      <div class="pt40" :ref="getStepName('kycQuestionnaire')" v-if="getStepName('kycQuestionnaire')"></div>
      <div id="Step2" class="step mt40" v-if="detail.questionnaire && getStepName('kycQuestionnaire')">
        {{ void (Step2 = stepsTree[getStepName("kycQuestionnaire")]) }}
        <step-header :label="getStepName('kycQuestionnaire')">
          <div class="state mr20">
            <!-- 步骤操作日志 -->
            <el-button type="text" icon="el-icon-tickets" class="mr20" @click="openStepsHistoryModal(getStepName('kycQuestionnaire'))">
              Steps History
            </el-button>
            <!-- 风险评估 -->
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              class="mr20"
              @click="openRiskAssessmentModal"
              v-if="isApplymentAuth('company:coordinate') || isApplymentAuth('company:check') || isApplymentAuth('company:audit')"
            >
              Risk Assessment
              <span style="color: #02cc90" v-if="detail.isRiskAssessmentFinished === 1 && riskAssessmentObj.suggest == 1">(RA-passed)</span>
              <span style="color: #ff7a37" v-if="detail.isRiskAssessmentFinished === 1 && riskAssessmentObj.suggest == 0">(RA-failed)</span>
            </el-button>
            <div
              class="border-type"
              :class="{
                'no-pass-button': Step2 && Step2.status == 0,
                'pass-button': Step2 && Step2.status == 1,
                'revised-button': Step2 && Step2.status == 2,
                'no-checking-button': Step2 && Step2.status == 3,
              }"
              v-if="Step2"
            >
              <span class="el-icon-error" v-if="Step2 && Step2.status == 0"></span>
              <span class="el-icon-success" v-if="Step2 && Step2.status == 1"></span>
              <span class="el-icon-warning" v-if="Step2 && Step2.status == 2"></span>
              <span class="el-icon-mouse" v-if="Step2 && Step2.status == 3"></span>
              {{ stepStatusFormat(Step2) }}
            </div>
          </div>
          <div class="opts">
            <el-button
              type="primary"
              plain
              v-if="checkStepVisible(getStepName('kycQuestionnaire'))"
              @click="openStepModal(getStepName('kycQuestionnaire'))"
            >
              Review
            </el-button>
          </div>
        </step-header>
        <el-card v-if="detail.questionnaire">
          <questionnaire
            :key="new Date().getTime()"
            :applymentId="detail.id"
            :applymentStatus="detail.applymentStatus"
            :questionnaire="detail.questionnaire"
            :businessType="detail.businessType"
            :fundInfoList="detail.fundInfoList || []"
            :businessGenre="detail.businessGenre"
            :registeredOfficeType="detail.registeredOfficeType"
            :registeredOfficeAddress="detail.registeredOfficeAddress"
            :registeredOfficeCity="detail.registeredOfficeCity"
            :registeredOfficeCountry="detail.registeredOfficeCountry"
            :registeredOfficePostalCode="detail.registeredOfficePostalCode"
            :registeredOfficeId="detail.registeredOfficeId"
            :nativeRegisteredAddress="nativeRegisteredAddress"
            :isNeedNomineeDirector="isNeedNomineeDirector"
            :hasNomineeDirector="detail.hasNomineeDirector"
          />
          <shareholders-detail
            class="mt40"
            stepName="kycQuestionnaire"
            :applymentId="detail.id"
            :applymentStatus="detail.applymentStatus"
            :fundInfoList="detail.fundInfoList || []"
            :companyMemberList="detail.companyMemberList"
            :companyFundMemberList="detail.companyFundMemberList"
            :hasNomineeDirector="detail.hasNomineeDirector"
            :businessType="detail.businessType"
            :appointNomineeDirector="detail.questionnaire.appointNomineeDirector || { type: null, name: null }"
            :headline="
              detail.businessType === 'FUND_ADMIN_CUSTOMIZED'
                ? `Information of the proposed fund manager's officer(s)`
                : `Information of the proposed company's officer(s)`
            "
            :isNeedNomineeDirector="isNeedNomineeDirector"
            v-if="
              detail.businessType === 'SERVICE_TRANSFER' ||
              detail.businessType === 'COMPREHENSIVE_CUSTOMIZED' ||
              detail.businessType === 'FUND_ADMIN_CUSTOMIZED'
            "
          />
        </el-card>
      </div>

      <!-- Step3: 财务信息 & 额外的信息 -->
      <div class="pt40" :ref="getStepName('companyDetails')" v-if="getStepName('companyDetails')"></div>
      <div id="Step3" class="step mt40" v-if="getStepName('companyDetails')">
        {{ void (Step3 = stepsTree[getStepName("companyDetails")]) }}
        <step-header :label="getStepName('companyDetails')">
          <div class="state mr20">
            <!-- 步骤操作日志 -->
            <el-button type="text" icon="el-icon-tickets" class="mr20" @click="openStepsHistoryModal(getStepName('companyDetails'))">
              Steps History
            </el-button>
            <div
              class="border-type"
              :class="{
                'no-pass-button': Step3 && Step3.status == 0,
                'pass-button': Step3 && Step3.status == 1,
                'revised-button': Step3 && Step3.status == 2,
                'no-checking-button': Step3 && Step3.status == 3,
              }"
              v-if="Step3"
            >
              <span class="el-icon-error" v-if="Step3 && Step3.status == 0"></span>
              <span class="el-icon-success" v-if="Step3 && Step3.status == 1"></span>
              <span class="el-icon-warning" v-if="Step3 && Step3.status == 2"></span>
              <span class="el-icon-mouse" v-if="Step3 && Step3.status == 3"></span>
              {{ stepStatusFormat(Step3) }}
            </div>
          </div>
          <div class="opts">
            <el-button type="primary" plain v-if="checkStepVisible(getStepName('companyDetails'))" @click="openStepModal(getStepName('companyDetails'))">
              Review
            </el-button>
          </div>
        </step-header>
        <el-card shadow="hover">
          <finance-info :detail="detail" />
          <shareholders-info class="mt40" :companyMemberList="detail.companyMemberList" :businessType="detail.businessType" />
          <extra-info :detail="detail" :nativeRegisteredAddress="nativeRegisteredAddress" />
        </el-card>
      </div>

      <!-- Step4: 上传资料 -->
      <div class="pt40" :ref="getStepName('upload')" v-if="getStepName('upload')"></div>
      <div id="Step4" class="step mt40" v-if="getStepName('upload')">
        {{ void (Step4 = stepsTree[getStepName("upload")]) }}
        <step-header :label="getStepName('upload')">
          <div class="state mr20">
            <!-- 步骤操作日志 -->
            <el-button type="text" icon="el-icon-tickets" class="mr20" @click="openStepsHistoryModal(getStepName('upload'))">Steps History</el-button>
            <div
              class="border-type"
              :class="{
                'no-pass-button': Step4 && Step4.status == 0,
                'pass-button': Step4 && Step4.status == 1,
                'revised-button': Step4 && Step4.status == 2,
                'no-checking-button': Step4 && Step4.status == 3,
              }"
              v-if="Step4"
            >
              <span class="el-icon-error" v-if="Step4 && Step4.status == 0"></span>
              <span class="el-icon-success" v-if="Step4 && Step4.status == 1"></span>
              <span class="el-icon-warning" v-if="Step4 && Step4.status == 2"></span>
              <span class="el-icon-mouse" v-if="Step4 && Step4.status == 3"></span>
              {{ stepStatusFormat(Step4) }}
            </div>
          </div>
          <div class="opts">
            <el-button type="primary" plain v-if="checkStepVisible(getStepName('upload'))" @click="openStepModal(getStepName('upload'))">Review</el-button>
          </div>
        </step-header>
        <el-card shadow="hover">
          <materials :detail="detail" />
        </el-card>
      </div>
    </div>

    <!-- 弹窗 -->
    <step-modal v-if="stepModalVisible" ref="stepModal"></step-modal>
    <!-- 认领日志弹框 -->
    <ownership-history :applymentId="detail.id" ref="ownershipModal"></ownership-history>
    <!-- 步骤操作日志弹框 -->
    <step-history :applymentId="detail.id" ref="stepHistoryModal"></step-history>
    <!-- 状态操作日志弹框 -->
    <status-history :applymentId="detail.id" ref="statusHistoryModal"></status-history>
    <!-- 发起退款申请 -->
    <initiate-refund :detail="detail" ref="initiateRefundModal"></initiate-refund>
    <!-- 发起退款申请列表 -->
    <initiate-refund-list :detail="detail" ref="initiateRefundListModal"></initiate-refund-list>
    <!-- 退款审核 - 初审和复审 -->
    <refund-judge ref="refundJudgeModal"></refund-judge>
    <!-- ACRA反馈 - 业务 -->
    <acra-feedback :detail="detail" ref="acraFeedbackModal"></acra-feedback>
    <!-- 退款信息补充 - 业务 -->
    <refund-info-update ref="refundInfoUpdateModal" :orderInfo="detail.orderInfo"></refund-info-update>
    <!-- 补款单 - 业务 -->
    <make-amends
      ref="makeAmendsModal"
      :businessGenre="detail.businessGenre"
      :businessType="detail.businessType"
      :applymentNo="detail.applymentNo"
      @upInvoiceList="getInvoiceList"
    ></make-amends>
    <!-- 发起终止交易申请 -->
    <termination-apply :detail="detail" ref="terminationApplyModal"></termination-apply>
    <!-- 终止交易审核 - 复核 -->
    <termination-judge :detail="detail" ref="terminationJudgeModal"></termination-judge>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StepHeader from "../components/StepHeader";
import UserToggle from "../components/UserToggle";
import BaseInfo from "../modules/baseInfo";
import CompanyInfo from "../modules/companyInfo";
import ExtraInfo from "../modules/extraInfo";
import FinanceInfo from "../modules/financeInfo";
import Materials from "../modules/materials";
import Questionnaire from "../modules/questionnaire";
import ShareholdersDetail from "../modules/shareholdersDetail";
import ShareholdersInfo from "../modules/shareholdersInfo";
import StepModal from "./modal/Step.vue";
import OwnershipHistory from "./modal/OwnershipHistory.vue";
import StepHistory from "./modal/StepHistory.vue";
import StatusHistory from "./modal/StatusHistory.vue";
import InitiateRefund from "./modal/InitiateRefund.vue";
import InitiateRefundList from "./modal/InitiateRefundList.vue";
import RefundJudge from "./modal/RefundJudge.vue";
import AcraFeedback from "./modal/AcraFeedback.vue";
import RefundInfoUpdate from "./modal/RefundInfoUpdate.vue";
import MakeAmends from "./modal/MakeAmends.vue";
import TerminationApply from "./modal/TerminationApply.vue";
import TerminationJudge from "./modal/TerminationJudge.vue";
export default {
  components: {
    BaseInfo,
    Questionnaire,
    CompanyInfo,
    ShareholdersDetail,
    FinanceInfo,
    ExtraInfo,
    ShareholdersInfo,
    Materials,
    StepHeader,
    StepModal,
    UserToggle,
    StepHistory,
    StatusHistory,
    InitiateRefund,
    InitiateRefundList,
    RefundJudge,
    AcraFeedback,
    RefundInfoUpdate,
    MakeAmends,
    OwnershipHistory,
    TerminationApply,
    TerminationJudge,
  },
  data() {
    return {
      stepModalVisible: false,
      affixed: false,
      invoiceObj: {
        unpaId: null,
        refundId: null,
        amendsList: [],
      },
      riskAssessmentObj: {},
      nativeRegisteredAddress: [],
      userList: [],
      quotationVList: [],
    };
  },
  computed: {
    ...mapState("constant", [
      "applymentStatusList",
      "stepStatusList",
      "refundStatusList",
      "amendsStatusList",
      "renewStatusList",
      "businessStepObj",
      "orderStatusList",
    ]),
    ...mapState("applyment", ["detail"]),
    ...mapState("authority", ["activeRoleName"]),
    ...mapState({
      userAt: state => {
        return { id: state.user.id, nickname: state.user.name, username: state.user.name };
      },
    }),
    stepsTree() {
      const procedures = this.detail.procedures;
      if (!procedures) {
        return {};
      }
      const r = procedures.reduce((xs, z) => {
        xs[z.step] = z;
        return xs;
      }, {});
      return r;
    },
    orderStatus() {
      const { orderInfo } = this.detail;
      return orderInfo?.status;
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
    },
    getStepName() {
      return function (val) {
        if (this.detail && this.detail.businessType) {
          return this.businessStepObj[this.detail.businessType][val];
        } else {
          return false;
        }
      };
    },
    // 判断申请单是否需要名义董事
    isNeedNomineeDirector() {
      let resBool = false;
      if (this.detail.hasNomineeDirector) {
        // 勾选了(我们同意聘用由星中商务推荐的名义董事1名，我们也理解该名义董事的行为造成的任何法律影响均与EStar无关。)
        resBool = true;
      } else {
        // 没勾选(需要检查所选服务是否有“名义董事”的需求)
        // 公司设立、公司服务的转移
        if (this.detail.businessType === "COMPANY_INCORPORATION" || this.detail.businessType === "SERVICE_TRANSFER") {
          // 判断套餐或服务中是否含有名义董事(SG-N1是“名义董事”服务的code)
          let goodsListCopy = this.detail.goodsList || [];
          for (let a in goodsListCopy) {
            if (this.detail.goodsListType == 1) {
              let moduleListCopy = goodsListCopy[a].planDetail.moduleList;
              for (let b in moduleListCopy) {
                if (moduleListCopy[b].moduleCode == "SG-N1") {
                  resBool = true;
                  break;
                }
              }
            } else {
              if (goodsListCopy[a].moduleDetail.moduleCode == "SG-N1") {
                resBool = true;
                break;
              }
            }
          }
        }

        // 综合性公司服务 – 定制化
        if (this.detail.businessType === "COMPREHENSIVE_CUSTOMIZED") {
          // 是否已聘用EStar提供的名义董事: 1是、0否
          if (this.detail.questionnaire && this.detail.questionnaire.appointNomineeDirector && this.detail.questionnaire.appointNomineeDirector.type) {
            resBool = true;
          }
        }
      }
      return resBool;
    },
  },
  watch: {
    activeRoleName(newVal) {
      // 权限认领弹框
      this.getTheTask();
    },
  },
  activated() {
    this.id = this.$route.params.id;
    this.getDetail();
    // 获取风险评估明细
    this.getRiskDetailHttp();
  },
  mounted() {},
  methods: {
    ...mapActions("applyment", ["initApplyment", "doCheck", "doAudit", "doReject", "doRevise", "doAcraApply", "doClaimOwnership"]),
    handleAffixChange(fixed) {
      this.affixed = fixed;
    },
    // 获取公司注册地址
    async getOfficeAddressHttp() {
      const { data } = await this.$http.get(`/admin/stRegisteredOfficeAddressManage/getList`);
      this.nativeRegisteredAddress = data.filter(item => item.type == this.detail.businessGenre);
    },
    //步骤按钮可视
    checkStepVisible(stepName) {
      const { applymentStatus } = this.detail;
      if (this.isApplymentAuth("company:check")) {
        if (applymentStatus === "CHECKING" || applymentStatus === "REVISE_FINISH") {
          return true;
        }
      }
      if (this.isApplymentAuth("company:audit")) {
        if (applymentStatus === "AUDITING") {
          return true;
        }
      }
      if (this.isApplymentAuth("company:coordinate")) {
        if (applymentStatus === "REVISING") {
          return this.stepsTree[stepName]?.status === 2;
        }
      }
      return false;
    },
    checkStepsList() {
      let flag = 1;
      let keys = [];
      const stepList = this.businessStepObj[this.detail.businessType];
      Object.keys(stepList).forEach(function (key) {
        keys.push(stepList[key]);
      });
      for (let i in keys) {
        let key = keys[i];
        if (!this.stepsTree[key]) {
          this.$message({
            message: key + " hadn't reviewed!",
            type: "warning",
            duration: 1000,
            onClose: () => {
              this.jumpStep(key);
            },
          });
          flag = -1;
          break;
        } else if (this.stepsTree[key].status === 2 || this.stepsTree[key].status === 3) {
          this.$message({
            message: key + " hadn't reviewed!",
            type: "warning",
            duration: 1000,
            onClose: () => {
              this.jumpStep(key);
            },
          });
          flag = -1;
          break;
        }
      }

      if (flag === 1) {
        for (let j in keys) {
          let key = keys[j];
          if (this.stepsTree[key].status === 0) {
            flag = 0;
            break;
          }
        }
      }
      return flag;
    },
    //当前步骤条状态
    getStepStatus(stepName) {
      const step = this.stepsTree[stepName];
      if (step) {
        if (step.status === undefined) {
          return "wait";
        } else {
          if (step.status === 0) {
            return "error";
          } else if (step.status === 1) {
            return "success";
          } else {
            return "process";
          }
        }
      } else {
        return "wait";
      }
    },
    //步骤锚点
    jumpStep(domId) {
      this.$refs[domId].scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
    //申请单状态格式化
    applymentStatusFormat(v) {
      const one = this.applymentStatusList.find(i => i.value === v);
      return one?.title;
    },
    //步骤状态格式化
    stepStatusFormat(v) {
      if (v) {
        const one = this.stepStatusList.find(i => i.value === v.status);
        return one?.title;
      }
    },
    //退款信息补充格式化
    refundStatusFormat(v) {
      const one = this.refundStatusList.find(i => i.value === v);
      return one?.title;
    },
    // 补款单状态格式化
    amendsStatusFormat(v) {
      const one = this.amendsStatusList.find(i => i.value === v);
      return one?.title;
    },
    // 续约状态格式化
    renewStatusFormat(v) {
      const one = this.renewStatusList.find(i => i.value === v);
      return one && one.title ? `& ${one.title}` : "";
    },
    // 订单状态格式化
    orderStatusFormat(v) {
      const one = this.orderStatusList.find(i => i.value === v);
      return one?.title;
    },
    // 详情
    async getDetail() {
      await this.initApplyment(this.id);
      // 获取后台管理员列表
      await this.getSysUserList();
      // 获取发票id
      this.getInvoiceList();
      // 权限认领弹框
      this.getTheTask();
      // 获取公司注册地址
      this.getOfficeAddressHttp();
      // 获取Quotation (V)列表
      this.getQuotationVListHttp();
    },
    // 获取后台管理员列表
    getSysUserList() {
      return new Promise((resolve, reject) => {
        this.$http.get("/admin/sysUser/getList").then(({ code, data, msg }) => {
          if (code === 200) {
            this.userList = data;
            resolve();
          } else {
            this.$message.error(msg);
            reject(msg);
          }
        });
      });
    },
    //弹窗逻辑
    //步骤弹窗
    async openStepModal(stepName) {
      this.stepModalVisible = true;
      await this.$nextTick();
      const stepData = this.stepsTree[stepName];
      if (stepData) {
        const copy = { ...stepData };
        this.$refs.stepModal.init(copy);
      } else {
        this.$refs.stepModal.init({
          applymentId: this.detail.id,
          step: stepName,
        });
      }
    },
    //完成初审
    async handleCheckFinish() {
      //检查风险评估表
      if (this.getStepName("kycQuestionnaire") && this.detail.isRiskAssessmentFinished !== 1) {
        // 更新detail
        await this.initApplyment(this.id);
        // 获取风险评估明细
        await this.getRiskDetailHttp();
        if (this.detail.isRiskAssessmentFinished !== 1) {
          this.$message.warning("Risk Assessment hadn't Completed!");
          return;
        }
      }
      //检查名义董事
      // if (
      //   ((((this.detail.businessType === "COMPANY_INCORPORATION" && this.getStepName("companyName")) ||
      //     (this.detail.businessType === "SERVICE_TRANSFER" && this.getStepName("kycQuestionnaire"))) &&
      //     this.detail.hasNomineeDirector) ||
      //     (this.detail.businessType === "COMPREHENSIVE_CUSTOMIZED" &&
      //       this.getStepName("kycQuestionnaire") &&
      //       this.detail.questionnaire.appointNomineeDirector &&
      //       this.detail.questionnaire.appointNomineeDirector.type)) &&
      //   this.detail.applymentStatus === "CHECKING"
      // ) {
      //   const idx = this.detail.companyMemberList.findIndex(element => element.positionHeld === "NOMINEE_DIRECTOR");
      //   if (idx === -1) {
      //     this.$message.warning("Nominee Director hadn't Added!");
      //     return;
      //   }
      // }
      if (this.detail.applymentStatus === "CHECKING" || this.detail.applymentStatus === "REVISE_FINISH") {
        // 检查名义董事所在的步骤是否存在
        if (
          (this.detail.businessType === "COMPANY_INCORPORATION" && this.getStepName("companyName")) ||
          (this.detail.businessType === "SERVICE_TRANSFER" && this.getStepName("kycQuestionnaire")) ||
          (this.detail.businessType === "COMPREHENSIVE_CUSTOMIZED" && this.getStepName("kycQuestionnaire"))
        ) {
          // 检查是否需要添加名义董事
          if (this.isNeedNomineeDirector) {
            // 判断是否设置了名义董事
            const idx = this.detail.companyMemberList.findIndex(element => element.positionHeld === "NOMINEE_DIRECTOR");
            if (idx === -1) {
              this.$message.warning("Nominee Director hadn't Added!");
              return;
            }
          }
        }
      }

      //检查步骤是否都已完成
      const isAllPass = this.checkStepsList();
      if (isAllPass === -1) {
        return;
      }

      this.$confirm(`Finish this checking. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const applymentStatus = isAllPass ? "AUDITING" : "ILLEGAL";
        this.doCheck(applymentStatus)
          .then(() => {
            this.$message.success("success");
          })
          .catch(msg => {
            this.$message.error(msg);
          });
      });
    },
    //完成复核
    handleAuditFinish() {
      const isAllPass = this.checkStepsList();
      if (isAllPass === -1) {
        return;
      }

      this.$confirm(`Finish this review. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const applymentStatus = isAllPass ? "AUDIT_SUCCESS" : "AUDIT_FAIL";
        this.doAudit(applymentStatus)
          .then(() => {
            this.$message.success("success");
          })
          .catch(msg => {
            this.$message.error(msg);
          });
      });
    },
    //资料驳回
    handleReject() {
      this.$confirm(`Reject this applyment. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        this.doReject()
          .then(() => {
            this.$message.success("success");
          })
          .catch(msg => {
            this.$message.error(msg);
          });
      });
    },
    //完成修订
    handleReviseFinish() {
      this.$confirm(`Commit this revision. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        this.doRevise()
          .then(() => {
            this.$message.success("success");
          })
          .catch(msg => {
            this.$message.error(msg);
          });
      });
    },
    // 步骤二 - 风险评估弹框
    openRiskAssessmentModal() {
      let newpage = this.$router.resolve(`/riskAssessment/${this.detail.id}`);
      window.open(newpage.href, "_blank");
    },
    // 步骤操作日志
    openStepsHistoryModal(data) {
      this.$refs.stepHistoryModal.init(data);
    },
    // 认领日志
    openOwnershipHistoryModal() {
      this.$refs.ownershipModal.init();
    },
    // 状态操作日志
    openStatusHistoryModal() {
      this.$refs.statusHistoryModal.init();
    },
    // 获取发票号码
    getInvoiceList() {
      this.$http
        .get("/customerInvoice/getListByApplymentNo", {
          params: {
            applymentNo: this.detail.applymentNo,
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            let resData = data || [];
            let amendsList = [];
            for (let i in resData) {
              if (resData[i].invoiceType == 1) {
                let unpaId = resData[i].invoiceNumber;
                this.$set(this.invoiceObj, "unpaId", unpaId);
              } else if (resData[i].invoiceType == 2) {
                let refundId = resData[i].invoiceNumber;
                this.$set(this.invoiceObj, "refundId", refundId);
              } else if (resData[i].invoiceType == 3) {
                amendsList.push(resData[i]);
              }
            }
            this.$set(this.invoiceObj, "amendsList", amendsList);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 发起退款
    handleInitiateRefund() {
      if (this.detail.businessType == "COMPREHENSIVE_CUSTOMIZED" || this.detail.businessType == "FUND_ADMIN_CUSTOMIZED") {
        this.$refs.initiateRefundListModal.init();
      } else {
        this.$refs.initiateRefundModal.init();
      }
    },
    // 退款审核 - 初审 或 复核
    handleRefundJudge(type) {
      this.$refs.refundJudgeModal.init(type);
    },
    // ACRA申请 - 业务
    handleAcraApply() {
      this.doAcraApply()
        .then(() => {
          this.$message.success("success");
        })
        .catch(msg => {
          this.$message.error(msg);
        });
    },
    // ACRA反馈 - 业务
    handleAcraFeedback() {
      this.$refs.acraFeedbackModal.init();
    },
    // 退款信息补充 - 业务
    handleRefundInfoUpdate() {
      this.$refs.refundInfoUpdateModal.init();
    },
    // 下补款单 - 业务
    handleMakeAmends() {
      this.$refs.makeAmendsModal.init();
    },
    // 认领
    claim(type) {
      return this.doClaimOwnership({
        ownershipRole: type,
        toUser: this.userAt,
      }).then(() => {
        this.$message.success(`${type == "auditor" ? "reviewer" : type} success`);
      });
    },
    // 权限认领弹框
    async getTheTask() {
      if (
        !this.isAuth("company:adjust") &&
        ((this.isAuth("company:coordinate") && (!this.detail.coordinator || (this.detail.coordinator && this.detail.coordinator.id === -1))) ||
          (this.isAuth("company:check") && (!this.detail.checker || (this.detail.checker && this.detail.checker.id === -1))) ||
          (this.isAuth("company:audit") && (!this.detail.auditor || (this.detail.auditor && this.detail.auditor.id === -1))))
      ) {
        let roleName = "";
        if (this.isAuth("company:coordinate") && (!this.detail.coordinator || (this.detail.coordinator && this.detail.coordinator.id === -1))) {
          roleName = "Coordinator";
        } else if (this.isAuth("company:check") && (!this.detail.checker || (this.detail.checker && this.detail.checker.id === -1))) {
          roleName = "Checker";
        } else if (this.isAuth("company:audit") && (!this.detail.auditor || (this.detail.auditor && this.detail.auditor.id === -1))) {
          roleName = "Reviewer";
        }
        await this.$confirm("Whether to pick up the work task of the application?", `Tasks Of ${roleName}`, {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "info",
        });
        if (this.isAuth("company:coordinate") && (!this.detail.coordinator || (this.detail.coordinator && this.detail.coordinator.id === -1))) {
          await this.claim("coordinator");
        }
        if (this.isAuth("company:check") && (!this.detail.checker || (this.detail.checker && this.detail.checker.id === -1))) {
          await this.claim("checker");
        }
        if (this.isAuth("company:audit") && (!this.detail.auditor || (this.detail.auditor && this.detail.auditor.id === -1))) {
          await this.claim("auditor");
        }
      }
    },
    // 获取风险评估明细
    getRiskDetailHttp() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/admin/companyApplymentRiskAssessment/detail", {
            params: {
              applymentId: this.id,
            },
          })
          .then(({ code, data, msg }) => {
            if (code === 200) {
              this.riskAssessmentObj = data && data.content ? data.content : {};
              resolve();
            } else {
              this.$message.error(msg);
              reject(msg);
            }
          });
      });
    },
    // 申请终止交易 - 业务
    handleTerminationApply() {
      this.$refs.terminationApplyModal.init();
    },
    // 申请终止交易 - 复核
    handleAuditTerminated() {
      this.$refs.terminationJudgeModal.init();
    },
    // 获取Quotation (V)列表
    async getQuotationVListHttp() {
      const { data } = await this.$http.get("/admin/orderManage/getSupplementOrderTradeInfoListByBusiness", {
        params: { businessType: this.detail.businessType, businessId: this.detail.id },
      });
      this.quotationVList = data;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/variables.scss";

.company-applyment-module {
  .applyment-page-header {
    background-color: #fff;
    // padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .page-header-heading-left {
      display: none;
    }

    .page-header-heading-content {
      width: 620px;
      padding-top: 12px;
    }

    .page-header-heading-right {
      .opts {
        display: inline-block;
      }
    }

    .status {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
      color: #12a3ff;
      .fs12 {
        font-size: 12px;
      }
    }

    .status::before {
      display: inline-block;
      content: "";
      background: #12a3ff;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      vertical-align: middle;
      margin-right: 4px;
      margin-top: -4px;
    }
  }
  .applyment-page-header-top {
    .affix {
      z-index: 1030 !important;
    }
    .page-header-heading-left {
      display: inline-block;
    }
    .page-header-heading-content {
      display: none;
    }
    .status-label {
      display: none;
    }
  }

  .applyment-page-header-shrink {
    .affix {
      left: $sidebar-width !important;
      width: calc(100% - #{$sidebar-width} - 26px) !important;
    }
  }

  .applyment-page-header-unfold {
    .affix {
      left: $sidebar-full-width !important;
      width: calc(100% - #{$sidebar-full-width} - 26px) !important;
    }
  }

  .steps-bar-wrapper {
    position: fixed;
    right: 6px;
    top: 38vh;
    z-index: 1000;
  }

  .desp {
    color: rgb(53, 55, 68);

    .desp-header {
      font-size: 18px;
      padding: 10px 5px 10px;
      border-bottom: 1px solid #ebeef0;
      margin-bottom: 15px;
      color: #011a45;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
    }

    .desp-items-group {
      & + .desp-items-group {
        margin-top: 24px;
      }
    }

    .desp-items-group-title {
      padding: 1px 14px;
      font-size: 16px;
      margin-bottom: 10px;
      width: fit-content;
      border-left: 3px solid #eee;
      color: #011a45;
      font-weight: bold;
    }

    .desp-items-group-title-icon {
      padding: 1px 14px;
      font-size: 16px;
      margin-bottom: 10px;
      width: fit-content;
      color: #011a45;
      font-weight: bold;
    }

    .desp-items-group-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      color: #0060ff;
      // border-left: 3px solid #eee;
      background: #eceff9;
      margin-bottom: 15px;

      .title {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .desp-item {
      overflow: hidden;
    }

    .desp-item-label {
      width: 166px;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #667288;
      line-height: 40px;
      padding: 0 12px 0 0;
      box-sizing: border-box;
    }

    .desp-item-label:after {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }

    .desp-item-content {
      margin-left: 100px;
      line-height: 40px;
      position: relative;
      font-size: 14px;
      color: #011a45;
    }

    .desp-item-self {
      width: 100%;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0;
      box-sizing: border-box;
    }

    .desp-item-form {
      width: 100%;
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0;
      box-sizing: border-box;
    }
  }
}

.border-type {
  display: inline-block;
  padding: 10px 20px;
  line-height: 1em;
  border-radius: 4px;
}

.pass-button {
  border: 1px solid $--color-success;
  background: #e0fff6;
  color: $--color-success;
}

.no-pass-button {
  border: 1px solid $--color-danger;
  background: #ffe4e4;
  color: $--color-danger;
}

.revised-button {
  border: 1px solid $--color-warning;
  background: #fff0e8;
  color: $--color-warning;
}

.no-checking-button {
  border: 1px solid $navbar--color-dark;
  background: transparent;
  color: $navbar--color-dark;
}
</style>
