<template>
  <div class="pd26 mb30 min-hg460 bg-white border-radius-10">
    <h3 class="flex flex-direction-row align-center justify-between home-title">
      <span>Client Numbers</span>
    </h3>
    <div class="mt10 mb16">
      <div class="pt14 pb20 flex justify-between align-center">
        <div class="flex justify-center capsule-wrap">
          <div class="capsule-item" :class="atAmountType == 1 ? 'capsule-active' : ''" @click="(atAmountType = 1), getMonthSum()">Month</div>
          <div class="capsule-item" :class="atAmountType == 2 ? 'capsule-active' : ''" @click="(atAmountType = 2), getQuarterlySum()">Quarterly</div>
          <div class="capsule-item" :class="atAmountType == 0 ? 'capsule-active' : ''" @click="(atAmountType = 0), getYearSum()">Year</div>
        </div>
        <div>
          <!-- 月 -->
          <div>
            <el-date-picker
              v-model="accordingMonth"
              type="month"
              value-format="yyyy-MM-dd"
              @change="accordingMonthChange"
              v-if="atAmountType == 1"
              size="small"
              class="wd120"
            ></el-date-picker>
          </div>
          <!-- 年 -->
          <div>
            <el-date-picker
              v-model="accordingYear"
              type="year"
              value-format="yyyy-MM-dd"
              @change="accordingYearChange"
              v-if="atAmountType == 0 || atAmountType == 2"
              size="small"
              class="wd120"
            ></el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="fluid-width hg284" id="client-quantity-line"></div>
  </div>
</template>

<script>
import { fmoney, accountAdd } from "@/utils";
export default {
  data() {
    return {
      // Total Transaction / Total Turnover
      atAmountType: 1, // 1 按月， 0 按年，2 按季
      accordingMonth: "", // 月份
      accordingYear: "", // 年份
      monthObj: {
        xList: [],
        acceptedList: [],
        pendingList: [],
        potentialList: [],
        lostList: [],
      },
      quarterlyObj: {
        xList: [],
        acceptedList: [],
        pendingList: [],
        potentialList: [],
        lostList: [],
      },
      yearObj: {
        xList: [],
        acceptedList: [],
        pendingList: [],
        potentialList: [],
        lostList: [],
      },
    };
  },
  methods: {
    // 按年的用户端数量
    getYearSum() {
      const params = {
        startTime: this.accordingYear,
        endTime: this.$moment(this.accordingYear).endOf("year").format("YYYY-MM-DD"),
      };
      this.$http
        .get("/admin/statistics/getClientsPerMonth", {
          params,
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            let xList = [];
            let acceptedList = [];
            let pendingList = [];
            let potentialList = [];
            let lostList = [];
            data.forEach(item => {
              xList.push(item.currMonth);
              acceptedList.push(parseFloat(item.acceptedCnt));
              pendingList.push(parseFloat(item.pendingCnt));
              potentialList.push(parseFloat(item.potentialCnt));
              lostList.push(parseFloat(item.lostCnt));
            });
            const dataItem = {
              xList,
              acceptedList,
              pendingList,
              potentialList,
              lostList,
            };

            this.yearObj = dataItem;

            // 按月或按年的用户端数量 - 折线图
            this.setTransactionAmount(dataItem);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 按月的用户端数量
    getMonthSum() {
      const params = {
        startTime: this.accordingMonth,
        endTime: this.$moment(this.accordingMonth).endOf("month").format("YYYY-MM-DD"),
      };
      this.$http
        .get("/admin/statistics/getClientsPerDay", {
          params,
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            let xList = [];
            let acceptedList = [];
            let pendingList = [];
            let potentialList = [];
            let lostList = [];
            data.forEach(item => {
              xList.push(item.currDay);
              acceptedList.push(parseFloat(item.acceptedCnt));
              pendingList.push(parseFloat(item.pendingCnt));
              potentialList.push(parseFloat(item.potentialCnt));
              lostList.push(parseFloat(item.lostCnt));
            });
            const dataItem = {
              xList,
              acceptedList,
              pendingList,
              potentialList,
              lostList,
            };

            this.monthObj = dataItem;
            // 按月或按年的用户端数量 - 折线图
            this.setTransactionAmount(dataItem);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 按季的用户端数量
    getQuarterlySum() {
      const params = {
        startTime: this.accordingYear,
        endTime: this.$moment(this.accordingYear).endOf("year").format("YYYY-MM-DD"),
      };

      this.$http
        .get("/admin/statistics/getClientsPerQuarter", {
          params,
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            let xList = [];
            let acceptedList = [];
            let pendingList = [];
            let potentialList = [];
            let lostList = [];
            data.forEach(item => {
              xList.push(item.currQuarter);
              acceptedList.push(parseFloat(item.acceptedCnt));
              pendingList.push(parseFloat(item.pendingCnt));
              potentialList.push(parseFloat(item.potentialCnt));
              lostList.push(parseFloat(item.lostCnt));
            });
            const dataItem = {
              xList,
              acceptedList,
              pendingList,
              potentialList,
              lostList,
            };

            this.quarterlyObj = dataItem;
            // 按月或按年的用户端数量 - 折线图
            this.setTransactionAmount(dataItem);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 按月或按年的用户端数量 - 折线图
    setTransactionAmount(obj) {
      let myChart = this.$echarts.init(document.getElementById("client-quantity-line"));
      let option = {
        color: ["#ff7e69", "#52D4FF", "#67C23A", "#FFFF00"],
        tooltip: {
          trigger: "axis",
          // formatter: function (params) {
          //   params = params[0];
          //   return `S$${fmoney(params.data, 2)}<br />${params.axisValue}`;
          // },
        },
        legend: {
          data: ["Accepted Client", "Pending Modified Client", "Potential Client", "Lost Client"],
        },
        grid: {
          // left: "12%",
          // right: 0,
          top: 80,
          bottom: 20,
        },
        xAxis: {
          type: "category",
          data: obj.xList,
          axisLine: {
            lineStyle: {
              color: "#939DB0",
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          // axisLabel: {
          //   formatter: "{value}(S$)",
          // },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#939DB0",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: ["#EEF0F9"],
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: "Accepted Client",
            data: obj.acceptedList,
            type: "line",
          },
          {
            name: "Pending Modified Client",
            data: obj.pendingList,
            type: "line",
          },
          {
            name: "Potential Client",
            data: obj.potentialList,
            type: "line",
          },
          {
            name: "Lost Client",
            data: obj.lostList,
            type: "line",
          },
        ],
      };
      myChart.setOption(option);
    },
    // 选择月份 - 日历
    accordingMonthChange(e) {
      // 按月的用户端数量
      this.getMonthSum();
    },
    // 选择年 - 日历
    accordingYearChange(e) {
      if (this.atAmountType == 0) {
        // 按年的用户端数量
        this.getYearSum();
      } else if (this.atAmountType == 2) {
        // 按季的用户端数量
        this.getQuarterlySum();
      }
    },
  },
  activated() {
    this.atAmountType = 1;
    this.accordingMonth = this.$moment().startOf("month").format("YYYY-MM-DD");
    this.accordingYear = this.$moment().startOf("year").format("YYYY-MM-DD");
    // 按月的用户端数量
    this.getMonthSum();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.min-hg460 {
  min-height: 460px;
}

.capsule-wrap {
  width: 210px;
  border-radius: 26px;
  overflow: hidden;
  .capsule-item {
    width: 70px;
    text-align: center;
    height: 30px;
    line-height: 28px;
    background-color: #ebeefd;
    color: #7987a0;
    font-size: 12px;
    cursor: pointer;
  }

  .capsule-active {
    background-color: #0072ff !important;
    color: #fff !important;
  }
}

.aurantia {
  color: #ff7e69 !important;
}

.cyan {
  color: #52d4ff !important;
}

.home-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: $form-item-font-color;
}

.home-subhead {
  margin: 0;
  padding: 0;
  margin-top: 6px;
  font-size: 14px;
  font-weight: bold;
  color: $form-item-font-color;
  line-height: 20px;
}

.symbol-price {
  font-size: 14px;
  font-weight: bold;
  color: $--color-primary;
  line-height: 1em;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  word-break: break-all;
  width: 50%;

  .total-price {
    font-size: 20px;
    font-weight: bold;
    line-height: 1em;
  }
}
</style>
