<template>
  <el-dialog title="Edit Finance Info" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-position="top">
      <el-row>
        <el-col :lg="16" :xl="13">
          <el-form-item label="Financial Year End (e.g. 31 December)">
            <!-- <el-cascader
              :options="monthOptions"
              clearable
              v-model="dataForm.financialYearEnd"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%"
            ></el-cascader> -->
            <div class="mb10">
              <span>Month</span>
              <el-select v-model="dataForm.month" placeholder="Select" style="width: 100%">
                <el-option v-for="nub in 12" :key="'month' + nub" :label="nub" :value="nub"></el-option>
              </el-select>
            </div>
            <div>
              <span>Date</span>
              <el-select v-model="dataForm.date" placeholder="Select" style="width: 100%">
                <el-option v-for="nub in 31" :key="'date' + nub" :label="nub" :value="nub"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="Financial Year Period" prop="financialYearPeriod">
            <el-select style="width: 100%" v-model="dataForm.financialYearPeriod" clearable placeholder="Select">
              <el-option v-for="item in financialYearPeriods" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    businessType: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        month: null,
        date: null,
        financialYearEnd: "",
        financialYearPeriod: "",
      },
      dataRule: {},
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["financialYearPeriods", "businessStepObj"]),
    monthOptions() {
      let yearArr = [];
      for (let i = 1; i <= 31; i++) {
        let childrenArr = [];
        for (let j = 1; j <= 12; j++) {
          childrenArr.push({ value: `Month ${j}`, label: `Month ${j}` });
        }
        yearArr.push({ value: `Date ${i}`, label: `Date ${i}`, children: childrenArr });
      }
      return yearArr;
    },
  },
  methods: {
    ...mapActions("applyment", ["doSaveRevising"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      const copy = JSON.parse(JSON.stringify(data));
      const financialYearEndCopy = copy.financialYearEnd;
      const financialYearEndSplit = financialYearEndCopy && financialYearEndCopy.includes("/") ? financialYearEndCopy.split("/") : null;
      copy.month = financialYearEndSplit ? parseInt(financialYearEndSplit[0]) : null;
      copy.date = financialYearEndSplit ? parseInt(financialYearEndSplit[1]) : null;
      this.dataForm = copy;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          let dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
          let financialYearEndRes = this.dataForm.month && this.dataForm.date ? `${this.dataForm.month}/${this.dataForm.date}` : "";
          dataFormCopy.financialYearEnd = financialYearEndRes;
          this.doSaveRevising({
            ...dataFormCopy,
            procedure: {
              step: this.businessStepObj[this.businessType]["companyDetails"],
            },
          })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
