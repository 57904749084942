<template>
  <div class="fluid-width plan-detail-module" v-if="quotationList.length > 0">
    <!-- 支付方式 -->
    <div class="fluid-width pb0 singleWrap">
      <!-- <p class="fluid-width singleTitle">Payment Schedule</p> -->
      <div class="fluid-width">
        <div class="mb20 shadeWrap" v-for="(parent, index) in quotationList" :key="`parent${index}`">
          <el-row :gutter="10" class="pt10 pb10 fxRow borderTop">
            <el-col :span="8">
              <p class="fs14 fwb billingTo">
                Billing to:
                <span class="fw400 fs12">{{ parent.companyTitle }}</span>
              </p>
            </el-col>
            <el-col :span="8">
              <p class="fs14 fwb billingTo">
                Address:
                <span class="fw400 fs12">{{ parent.companyAddress }}</span>
              </p>
            </el-col>
            <el-col :span="5">
              <p class="fs14 fwb billingTo">
                Country:
                <span class="fw400 fs12">{{ parent.companyCountry }}</span>
              </p>
            </el-col>
            <el-col :span="3">
              <p class="fs14 fwb billingTo">
                Code:
                <span class="fw400 fs12">{{ parent.companyPostalCode }}</span>
              </p>
            </el-col>
          </el-row>
          <div class="billingTitle">
            <el-row :gutter="10" class="pt10 pb10 fxRow borderTop">
              <el-col :span="5">
                <p class="fs12 fwb text-center">Fee Schedule</p>
                <!-- <p class="fs12 fwb text-center">收费安排</p> -->
              </el-col>
              <el-col :span="3">
                <p class="fs12 fwb text-center">Covered Period</p>
                <!-- <p class="fs12 fwb text-center">涵盖的服务期间</p> -->
              </el-col>
              <el-col :span="3">
                <p class="fs12 fwb text-center">Amount</p>
                <!-- <p class="fs12 fwb text-center">金额</p> -->
              </el-col>
              <el-col :span="3">
                <p class="fs12 fwb text-center">Inclusive of third party fee</p>
                <!-- <p class="fs12 fwb text-center">第三方金额</p> -->
              </el-col>
              <el-col :span="3">
                <p class="fs12 fwb text-center">Inclusive of other fee</p>
              </el-col>
              <el-col :span="3">
                <p class="fs12 fwb text-center">Payment Due Date</p>
                <!-- <p class="fs12 fwb text-center">支付到期日</p> -->
              </el-col>
              <el-col :span="2">
                <p class="fs12 fwb text-center">Generic Invoice</p>
                <!-- <p class="fs12 fwb text-center">普通发票</p> -->
              </el-col>
              <el-col :span="2">
                <p class="fs12 fwb text-center">Status</p>
                <!-- <p class="fs12 fwb text-center">状态</p> -->
              </el-col>
            </el-row>
          </div>

          <div v-for="(sub, idx) in parent.partList" :key="`sub${idx}`">
            <!-- 内容 -->
            <div class="billingContent" v-for="(grand, ids) in sub.itemList" :key="`grand${ids}`">
              <el-row :gutter="10" class="pt10 pb10 fxRow borderTop">
                <el-col :span="5">
                  <p class="fs12 text-center lh16">{{ grand.title }}</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 text-center lh16">{{ grand.startDate }} ~ {{ grand.endDate }}</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 text-center lh16 orange newline">{{ grand.amount | thousands }}</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 text-center lh16 orange newline">
                    {{ grand.thirdPartyFee | thousands }}
                    <el-popover placement="bottom" trigger="hover" v-if="grand.noteForThirdParty">
                      <p class="fs12">{{ grand.noteForThirdParty }}</p>
                      <span slot="reference" class="fs13 blackD8 el-icon-question"></span>
                    </el-popover>
                  </p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 text-center lh16 orange newline">
                    {{ grand.otherFee | thousands }}
                    <el-popover placement="bottom" trigger="hover" v-if="grand.noteForOther">
                      <p class="fs12">{{ grand.noteForOther }}</p>
                      <span slot="reference" class="fs13 blackD8 el-icon-question"></span>
                    </el-popover>
                  </p>
                </el-col>
                <el-col :span="3"></el-col>
                <el-col :span="2"></el-col>
                <el-col :span="2"></el-col>
              </el-row>
            </div>
            <!-- 小结 -->
            <div class="billingBrief">
              <el-row :gutter="10" class="pt10 pb10 fxRow borderTop borderBottom">
                <el-col :span="5">
                  <p class="fs12 text-center lh16">{{ sub.subTotalDesp }}</p>
                </el-col>
                <el-col :span="3"></el-col>
                <el-col :span="3">
                  <p class="fs12 text-center lh16 pb2">Subtotal</p>
                  <p class="fs12 text-center lh16 orange newline">{{ sub.subTotalPrice | thousands }}</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 text-center lh16 pb2">Subtotal</p>
                  <p class="fs12 text-center lh16 orange newline">{{ sub.subTotalThirdPartyFee | thousands }}</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 text-center lh16 pb2">Subtotal</p>
                  <p class="fs12 text-center lh16 orange newline">{{ sub.subTotalOtherFee | thousands }}</p>
                </el-col>
                <el-col :span="3">
                  <p class="fs12 text-center lh16" v-if="sub.isDownPayment == 1">Payable upon submission</p>
                  <p class="fs12 text-center lh16" v-if="sub.isDownPayment == 0">{{ sub.paymentDueTime }}</p>
                </el-col>
                <el-col :span="2">
                  <p
                    class="fs12 text-center lh16 cursor-pointer blue"
                    v-if="
                      applymentStatus != 'DRAFT' &&
                      applymentStatus != 'INQUIRING' &&
                      applymentStatus != 'QUOTING' &&
                      applymentStatus != 'QUOTATION_AUDITING' &&
                      applymentStatus != 'QUOTATION_AUDIT_PASS' &&
                      applymentStatus != 'QUOTATION_AUDIT_FAIL' &&
                      hasInvoice(sub.invoiceList, 1)
                    "
                    @click="invoiceLink('unpaid', hasInvoice(sub.invoiceList, 1))"
                  >
                    Invoice
                  </p>
                  <p
                    class="pt6 fs12 text-center lh16 cursor-pointer blue"
                    v-if="(applymentStatus == 'REFUND_APPLY_PASS' || applymentStatus == 'CANCELED') && hasInvoice(sub.invoiceList, 2)"
                    @click="invoiceLink('refund', hasInvoice(sub.invoiceList, 2))"
                  >
                    Debit Invoice
                  </p>
                </el-col>
                <el-col :span="2">
                  <p class="fs12 text-center lh16">{{ statusFormat(sub.orderStatus) }}</p>
                </el-col>
              </el-row>
              <!-- 空格 -->
              <el-row :gutter="10" class="pt20 pb20 fxRow"></el-row>
            </div>
          </div>

          <!-- 总结 -->
          <div class="billingBottom">
            <el-row :gutter="10" class="pt10 pb10 fxRow borderTop borderBottom">
              <el-col :span="5">
                <p class="fs12 text-center lh16">{{ parent.sumTotalDesp }}</p>
              </el-col>
              <el-col :span="3"></el-col>
              <el-col :span="3">
                <p class="fs12 text-center lh16 pb2">Subtotal</p>
                <p class="fs12 text-center lh16 orange newline">{{ parent.sumTotalPrice | thousands }}</p>
              </el-col>
              <el-col :span="3">
                <p class="fs12 text-center lh16 pb2">Subtotal</p>
                <p class="fs12 text-center lh16 orange newline">{{ parent.sumTotalThirdPartyFee | thousands }}</p>
              </el-col>
              <el-col :span="3">
                <p class="fs12 text-center lh16 pb2">Subtotal</p>
                <p class="fs12 text-center lh16 orange newline">{{ parent.sumTotalOtherFee | thousands }}</p>
              </el-col>
              <el-col :span="3"></el-col>
              <el-col :span="2"></el-col>
              <el-col :span="2"></el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fmoney } from "@/utils";
import { mapState } from "vuex";
export default {
  props: {
    quotationList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    applymentStatus: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("constant", ["orderStatusList"]),
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  methods: {
    // 格式化
    statusFormat(status) {
      const one = this.orderStatusList.find(i => i.value === status);
      return one?.title;
    },
    hasInvoice(list, type) {
      let backRes = false;
      for (let i in list) {
        if (list[i].invoiceType == type) {
          backRes = list[i].invoiceNumber;
        }
      }
      return backRes;
    },
    // 发票跳转
    invoiceLink(type, idRes) {
      let newpage = `${process.env.VUE_APP_URL_INVOICE}/${type}?invoiceNo=${idRes}&lang=en`;
      window.open(newpage, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.blackD8 {
  color: #bbc6d8;
}

.orange {
  color: $--color-warning;
  font-size: 20px;
  font-weight: bolder;
}

.blue {
  color: $--color-primary;
}

p {
  line-height: 16px;
  margin: 0;
  color: #011a45;
}

.shadeWrap {
  width: 100%;
  padding: 20px;
  background-color: #fff5f0;
  margin-top: 6px;
}

.comboLeft {
  padding-right: 60px;
}

.comboRight {
  padding-left: 60px;
  border-left: 1px solid #e4e7ed;
}

.messageTitle {
  border-radius: 2px;
  font-size: 18px;
  line-height: 1em;
  font-weight: bold;
}

.mustTitle {
  border-radius: 2px;
  font-size: 16px;
  line-height: 1em;
  font-weight: 600;
}

.mustTitle:before {
  content: "*";
  margin-right: 4px;
  color: #f56c6c;
}

.singleTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 10px;
}

.singleText {
  font-size: 12px;
  line-height: 22px;
  margin-right: 30px;
  word-wrap: break-word;
  word-break: break-all;
}

.borderTop {
  border-top: 1px solid #e4e7ed;
}

.borderBottom {
  border-bottom: 1px solid #e4e7ed;
}
</style>
