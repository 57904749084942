<template>
  <div class="company-materials-module">
    <div class="desp">
      <div class="desp-header">Upload</div>
      <div>
        <!-- 公司设立 -->
        <Incorporation :detail="detail" v-if="detail.businessType == 'COMPANY_INCORPORATION'"></Incorporation>
        <!-- 服务转移 -->
        <Transfer :detail="detail" v-if="detail.businessType == 'SERVICE_TRANSFER'"></Transfer>
        <!-- 综合性公司服务 -->
        <Quotation :detail="detail" v-if="detail.businessType == 'COMPREHENSIVE_CUSTOMIZED'"></Quotation>
        <!-- 基金托管服务 -->
        <FundAdmin :detail="detail" v-if="detail.businessType == 'FUND_ADMIN_CUSTOMIZED'"></FundAdmin>
      </div>
    </div>
  </div>
</template>

<script>
import Incorporation from "./Incorporation";
import Transfer from "./Transfer";
import Quotation from "./Quotation";
import FundAdmin from "./FundAdmin";
export default {
  components: { Incorporation, Transfer, Quotation, FundAdmin },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          businessType: "",
          applymentStatus: "",
          registeredOfficeAddressCertificateUrls: [],
          registeredOfficeShareholdingStructureUrls: [],
          authoritiesApprovalFileUrls: [],
          companyMemberList: [],
          applicantIdentificationUrls: [],
          authorizationUrls: [],
          applicantPosition: "",
          fundInfoList: [],
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.company-materials-module {
  .files-wrapper {
    width: 100%;
  }

  .company-files-wrapper {
    .el-col {
      margin-bottom: 20px;
    }
  }

  .shareholder-file-wrapper {
    .member-info {
      color: #353744;
      font-weight: normal;
      // margin-bottom: 15px;

      .name {
        width: fit-content;
        font-size: 16px;
        line-height: 16px;
        margin-right: 10px;
      }
      .held {
        font-size: 13px;
      }
    }
  }

  .desp-items-group-header {
    .title {
      padding: 10px 0;
    }
  }
}
</style>
