<template>
  <div class="assets-file-picker">
    <div class="file-preview">
      <div @click="openUpload()" class="right">
        <slot><el-button>Upload</el-button></slot>
      </div>
      <div v-if="value && value.length > 0" class="left mr20">
        <div class="preview-file">
          <div class="preview-file-item" v-for="(item, idx) in value" :key="item.url">
            <div class="name">
              <a :href="item.url" target="_blank">{{ item.name }}</a>
            </div>
            <div class="btn" @click="handleDelete(idx)"><i class="el-icon-close"></i></div>
          </div>
        </div>
      </div>
    </div>
    <input class="hidden" type="text" :value="value" />
    <upload v-if="uploadVisible" ref="Upload" :accept="accept" @uploadFinish="handleFinish"></upload>
  </div>
</template>

<script>
import Upload from "./upload";
export default {
  name: "ElMultipleFileUploader",
  components: {
    Upload,
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [
          {
            name: "",
            url: "",
            size: 0,
          },
        ];
      },
    },
    accept: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      uploadVisible: false,
    };
  },
  methods: {
    openUpload() {
      this.uploadVisible = true;
      this.$nextTick(() => {
        const copy = [...this.value];
        this.$refs.Upload.init(copy);
      });
    },
    handleFinish(urls) {
      this.$emit("input", urls);
    },
    handleDelete(index) {
      const one = this.value[index];
      const urls = this.value.filter(i => i !== one);
      this.$emit("input", urls);
    },
  },
};
</script>

<style lang="scss" scoped>
.assets-file-picker {
  .file-preview {
    min-height: 40px;
    line-height: 40px;
    width: fit-content;
    display: flex;
    flex-direction: column;

    .preview-file-item {
      line-height: 36px;
      .name {
        display: inline-block;
        vertical-align: middle;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .btn {
        vertical-align: middle;
        display: none;
        margin-left: 10px;
        // color: red;
        cursor: pointer;

        i {
          vertical-align: middle;
        }
      }

      &:hover {
        .btn {
          display: inline-block;
        }
      }
    }
  }
}
</style>
>
