<template>
  <div class="company-applyment-module">
    <!-- 状态 -->
    <el-affix
      :class="{ 'applyment-page-header-top': affixed, 'applyment-page-header-shrink': sidebarFold, 'applyment-page-header-unfold': !sidebarFold }"
      @change="handleAffixChange"
      :offset="104"
    >
      <el-card>
        <div class="applyment-page-header">
          <div class="status">
            <template v-if="detail.refundDetail && detail.refundDetail.status">
              {{ refundStatusFormat(detail.refundDetail.status) }}
            </template>
            <template v-else>
              {{ applymentStatusFormat(detail.status) }}
            </template>
            <span class="fs12" v-if="detail.creditStatus">(Credit: {{ creditStatusFormat(detail.creditStatus) }})</span>
          </div>
          <div class="page-header-heading-right">
            <!-- 后付款审批历史记录 -->
            <el-button type="text" icon="el-icon-tickets" @click="openCreditHistoryModal">Credit History</el-button>

            <div class="ml10 opts">
              <!-- 确认收款 - 财务 -->
              <el-button
                v-if="(detail.status === 'UNPAID' || detail.status === 'PARTLY_PAID') && isAuth('finance:financial')"
                type="primary"
                size="medium"
                @click="handleConfirmReceipt"
              >
                Confirm Receipt
              </el-button>
              <!-- 确认退款 - 财务 -->
              <el-button
                v-if="
                  detail.refundDetail &&
                  detail.refundDetail.status &&
                  (detail.refundDetail.status === 'REFUNDING' ||
                    detail.refundDetail.status === 'REFUND_INFO_UPDATED' ||
                    detail.refundDetail.status === 'REFUND_PENDING') &&
                  isAuth('finance:financial')
                "
                type="primary"
                size="medium"
                @click="handleConfirmRefund"
              >
                Confirm Refund
              </el-button>
            </div>
            <div class="ml10 opts" v-if="detail.status === 'UNPAID' || detail.status === 'PARTLY_PAID'">
              <!-- 发起后付款 - 财务 -->
              <el-button
                v-if="(!detail.creditStatus || detail.creditStatus == 'REJECTED') && isAuth('finance:financial')"
                type="primary"
                size="medium"
                @click="handleCreditApply"
              >
                {{ detail.creditStatus == "REJECTED" ? "Credit Re-apply" : "Credit Apply" }}
              </el-button>
              <!-- 后付款审批 - 财务复核 -->
              <el-button v-if="detail.creditStatus == 'PENDING' && isAuth('finance:audit')" type="primary" size="medium" @click="handleCreditReview">
                Credit Review
              </el-button>
            </div>
          </div>
        </div>
      </el-card>
    </el-affix>

    <!-- 退款信息 - 普通订单、补款单 -->
    <template v-if="detail.orderType == 'generic' || detail.orderType == 'amends'">
      <div class="step mt50" v-if="detail.refundDetail">
        <el-card shadow="hover">
          <refund-info :detail="detail" />
        </el-card>
      </div>
    </template>

    <!-- 基础信息 -->
    <div class="step mt40">
      <el-card shadow="hover">
        <base-info :detail="detail" :amendsList="amendsList" />
      </el-card>
    </div>

    <!-- 确认收款 - 弹窗 -->
    <confirm-receipt ref="confirmReceiptModal"></confirm-receipt>
    <!-- 确认退款 - 弹窗 -->
    <confirm-refund ref="confirmRefundModal" :refundDetail="detail.refundDetail"></confirm-refund>
    <!-- 发起后付款申请 - 弹窗 -->
    <credit-apply ref="creditApplyModal"></credit-apply>
    <!-- 审批后付款申请 - 弹窗 -->
    <credit-review ref="creditReviewModal"></credit-review>
    <!-- 后付款审批历史记录 - 弹窗 -->
    <credit-history ref="creditHistoryModal" :orderId="detail.id"></credit-history>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseInfo from "../modules/baseInfo";
import RefundInfo from "../modules/refundInfo";
import ConfirmReceipt from "./modal/ConfirmReceipt.vue";
import ConfirmRefund from "./modal/ConfirmRefund.vue";
import CreditApply from "./modal/CreditApply.vue";
import CreditReview from "./modal/CreditReview.vue";
import CreditHistory from "./modal/CreditHistory.vue";
export default {
  components: {
    BaseInfo,
    RefundInfo,
    ConfirmReceipt,
    ConfirmRefund,
    CreditApply,
    CreditReview,
    CreditHistory,
  },
  data() {
    return {
      affixed: false,
      amendsList: [],
    };
  },
  computed: {
    ...mapState("constant", ["orderStatusList", "refundStatusList", "creditStatusList"]),
    ...mapState("finance", ["detail"]),
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
    },
  },
  activated() {
    this.id = this.$route.params.id;
    this.getDetail();
  },
  mounted() {},
  methods: {
    ...mapActions("finance", ["initOrder"]),
    handleAffixChange(fixed) {
      this.affixed = fixed;
    },
    //申请单状态格式化
    applymentStatusFormat(v) {
      const one = this.orderStatusList.find(i => i.value === v);
      return one?.title;
    },
    refundStatusFormat(v) {
      const one = this.refundStatusList.find(i => i.value === v);
      return one?.title;
    },
    creditStatusFormat(v) {
      const one = this.creditStatusList.find(i => i.value === v);
      return one?.title;
    },
    //详情
    async getDetail() {
      await this.initOrder(this.id);
      if (this.detail.orderType == "amends") {
        // 补款单
        this.amendsList = [
          {
            priceSg: this.detail.priceSg,
            thirdPartyFee: this.detail.thirdPartyFee,
            description: this.detail.description,
            feeLetter: this.detail.feeLetter,
            status: this.detail.status,
            otherFee: this.detail.otherFee,
            otherRemark: this.detail.otherRemark,
          },
        ];
      }
    },
    // 确认收款 - 财务
    handleConfirmReceipt() {
      this.$refs.confirmReceiptModal.init();
    },
    // 确认退款 - 财务
    handleConfirmRefund() {
      this.$refs.confirmRefundModal.init();
    },
    // 发起后付款 - 财务
    handleCreditApply() {
      this.$refs.creditApplyModal.init();
    },
    // 后付款审批 - 财务复核
    handleCreditReview() {
      this.$refs.creditReviewModal.init();
    },
    // 后付款审批历史记录
    openCreditHistoryModal() {
      this.$refs.creditHistoryModal.init();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/variables.scss";

.company-applyment-module {
  .applyment-page-header {
    background-color: #fff;
    // padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .page-header-heading-left {
      display: none;
    }

    .page-header-heading-content {
      width: 620px;
    }

    .page-header-heading-right {
      .opts {
        display: inline-block;
      }
    }
    .status {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
      color: #12a3ff;
    }
    .status::before {
      display: inline-block;
      content: "";
      background: #12a3ff;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      vertical-align: middle;
      margin-right: 4px;
      margin-top: -4px;
    }
  }
  .applyment-page-header-top {
    .affix {
      z-index: 1030 !important;
    }
    .page-header-heading-left {
      display: inline-block;
    }
    .page-header-heading-content {
      display: none;
    }
    .status-label {
      display: none;
    }
  }

  .applyment-page-header-shrink {
    .affix {
      left: $sidebar-width !important;
      width: calc(100% - #{$sidebar-width} - 26px) !important;
    }
  }

  .applyment-page-header-unfold {
    .affix {
      left: $sidebar-full-width !important;
      width: calc(100% - #{$sidebar-full-width} - 26px) !important;
    }
  }

  .steps-bar-wrapper {
    position: fixed;
    right: 6px;
    top: 38vh;
    z-index: 1000;
  }

  .desp {
    color: rgb(53, 55, 68);

    .desp-header {
      font-size: 18px;
      padding: 10px 5px 10px;
      border-bottom: 1px solid #ebeef0;
      margin-bottom: 15px;
      color: #011a45;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
    }

    .desp-items-group {
      & + .desp-items-group {
        margin-top: 24px;
      }
    }

    .desp-items-group-title {
      padding: 1px 14px;
      font-size: 16px;
      margin-bottom: 10px;
      width: fit-content;
      border-left: 3px solid #eee;
      color: #011a45;
      font-weight: bold;
    }

    .desp-items-group-title-icon {
      padding: 1px 14px;
      font-size: 16px;
      margin-bottom: 10px;
      width: fit-content;
      color: #011a45;
      font-weight: bold;
    }

    .desp-items-group-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      color: #0060ff;
      // border-left: 3px solid #eee;
      background: #eceff9;
      margin-bottom: 15px;

      .title {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .desp-item {
      overflow: hidden;
    }

    .desp-item-label {
      width: 166px;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #667288;
      line-height: 40px;
      padding: 0 12px 0 0;
      box-sizing: border-box;
    }

    .desp-item-label:after {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }

    .desp-item-content {
      margin-left: 136px;
      line-height: 40px;
      position: relative;
      font-size: 14px;
      color: #011a45;
    }

    .desp-item-self {
      width: 100%;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0;
      box-sizing: border-box;
    }

    .desp-item-form {
      width: 100%;
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0;
      box-sizing: border-box;
    }
  }
}
</style>
