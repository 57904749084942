<template>
  <div class="page-container">
    <div class="mod-role">
      <el-form :inline="true" :model="dataForm" @submit.native.prevent="getDataList(1)">
        <div class="flex-row jcsb">
          <el-form-item>
            <el-button v-if="isAuth('sys:role:save')" type="primary" @click="addOrUpdateHandle()">Add Role</el-button>
          </el-form-item>
          <div>
            <el-form-item>
              <el-input v-model="dataForm.name" placeholder="Role Name" clearable :oninput="isEn"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="mb26">
        <el-button
          v-if="isAuth('sys:role:delete')"
          type="danger"
          plain
          size="small"
          icon="el-icon-delete"
          @click="deleteHandle('multiple')"
          :disabled="dataListSelections.length <= 0"
        >
          Delete
        </el-button>
      </div>
      <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="code" header-align="center" align="center" min-width="100" label="Role Code"></el-table-column>
        <el-table-column prop="name" header-align="center" align="center" min-width="100" label="Role Name"></el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="180" label="Action">
          <template slot-scope="scope">
            <el-button v-if="isAuth('sys:role:update') && scope.row.id > 1" type="text" class="fwb" @click="addOrUpdateHandle(scope.row.id)">
              Edit
            </el-button>
            <el-button v-if="isAuth('sys:role:delete') && scope.row.id > 1" type="text" class="fwb" @click="deleteHandle(scope.row.id)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
  </div>
</template>

<script>
import AddOrUpdate from "./AddOrUpdate";
import paginationDelete from "@/mixins/paginationDelete.js";
import { mapMutations } from "vuex";
export default {
  mixins: [paginationDelete],
  data() {
    return {
      dataForm: {
        name: "",
      },
      dataList: [],
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    ...mapMutations("role", ["deleteOne"]),
    // 获取数据列表
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
        roleName: this.dataForm.name,
      };
      const { code, data } = await this.$http.get("/admin/sysRole/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    async deleteHandle(id) {
      var ids = id == "multiple" ? this.dataListSelections.map(item => item.id) : [id];
      await this.$confirm(`Delete ${id == "multiple" ? "these Roles" : "this Role"}. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const { code, msg } = await this.$http.post(
        "/admin/sysRole/delete",
        {},
        {
          params: {
            ids: ids.join(","),
          },
        },
      );
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => {
            // mixins
            this.singleOrMultipleDataDelete(id);
            this.getDataList();
          },
        });
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>
