<template>
  <div class="pl-module">
    <div class="mb20" @click="openSelector()">
      <slot>
        <el-button>Add Service</el-button>
      </slot>
    </div>
    <input class="hidden" type="text" :value="value" />
    <ul>
      <li v-for="(module, idx) in currValue" :key="module.id">
        <module-item :metas="getAvaiableMetas(module.id)" @input="moduleChange()" v-model="currValue[idx]" />
      </li>
    </ul>
    <module-panel v-if="selectorVisible" ref="selector" :moduleList="remaningModules" @selectFinish="selectFinish"></module-panel>
  </div>
</template>

<script>
import ModulePanel from "./components/module-panel";
import ModuleItem from "./components/module-item";
export default {
  components: { ModuleItem, ModulePanel },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modules: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectorVisible: false,
      currValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.currValue = newValue;
    },
  },
  computed: {
    remaningModules() {
      const ids = this.currValue.map(i => i.id);
      return this.modules.filter(item => !ids.includes(item.id));
    },
  },
  methods: {
    getAvaiableMetas(moduleId) {
      return this.modules.find(i => i.id === moduleId)?.metaInfo || [];
    },
    openSelector() {
      this.selectorVisible = true;
      this.$nextTick(() => {
        this.$refs.selector.init();
      });
    },
    //回传数据
    selectFinish(data) {
      //处理数据
      this.currValue = this.currValue.concat(data);
      this.commitInput(this.currValue);
    },
    moduleChange() {
      this.commitInput(this.currValue);
    },
    commitInput(data) {
      const result = data.filter(i => i.checked);
      this.$emit("input", result);
    },
  },
};
</script>

<style lang="scss" scoped>
.pl-module {
  width: fit-content;

  ul,
  li {
    margin: 0;
    list-style-type: none;
    padding: 0;
  }
}
.pl-module-item {
  margin-bottom: 15px;

  &.selected {
    border: 1px solid #2878f0;
  }

  .left {
    margin: 0;
    padding: 0;
    margin-left: 15px;
    width: 60px;
    height: 60px;
  }
  .right {
    margin: 0;
    padding: 0;
    width: 120px;
    display: flex;
    align-items: center;
  }
  .content {
    flex: 1;
    margin: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
}
</style>
