<template>
  <el-card>
    <el-row>
      <el-col :span="15">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="200px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="Business Type" prop="businessTypeList">
                <el-select multiple v-model="dataForm.businessTypeList" placeholder="Select BusinessType" class="fluid-width">
                  <el-option v-for="item in businessTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="Country" prop="country">
                <el-select v-model="dataForm.country" placeholder="Select Country" class="fluid-width">
                  <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="Name" prop="name">
                <el-input v-model="dataForm.name" placeholder="Name" :oninput="isEn"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="Name(CN)" prop="nameCn">
                <el-input v-model="dataForm.nameCn" placeholder="Name(CN)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="16">
              <el-form-item label="Code" prop="moduleCode">
                <el-input v-model="dataForm.moduleCode" placeholder="Code" :oninput="isEn" :disabled="dataId != undefined ? true : false"></el-input>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-form-item label="Summary" prop="summary">
            <el-input v-model="dataForm.summary" type="textarea" :rows="5" :autosize="{ minRows: 4 }" resize="none" placeholder="Summary"></el-input>
          </el-form-item>
          <el-form-item label="Summary(CN)" prop="summaryCn">
            <el-input v-model="dataForm.summaryCn" type="textarea" :rows="5" :autosize="{ minRows: 4 }" resize="none" placeholder="Summary(CN)"></el-input>
          </el-form-item>
          <el-form-item label="Price" prop="price">
            <el-input type="number" v-model="dataForm.price" class="wd150 mr20">
              <template slot="prefix">S$</template>
            </el-input>
            <el-input type="number" v-model="dataForm.originalPrice" class="wd300">
              <template slot="prepend">original price</template>
              <template slot="prefix">S$</template>
            </el-input>
          </el-form-item>
          <el-form-item label="Third party fee" prop="thirdPartyFee">
            <el-input type="number" v-model="dataForm.thirdPartyFee" class="wd150 mr20">
              <template slot="prefix">S$</template>
            </el-input>
          </el-form-item>
          <el-row>
            <el-col :span="20">
              <el-form-item label="Note For Third Party" prop="noteForThirdParty">
                <el-input v-model="dataForm.noteForThirdParty" placeholder="Note For Third Party" :oninput="isEn"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="Note For Third Party(CN)" prop="noteForThirdPartyCn">
                <el-input v-model="dataForm.noteForThirdPartyCn" placeholder="Note For Third Party(CN)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Other Fee" prop="otherFee">
            <el-input type="number" v-model="dataForm.otherFee" class="wd150 mr20">
              <template slot="prefix">S$</template>
            </el-input>
          </el-form-item>
          <el-row>
            <el-col :span="20">
              <el-form-item label="Note For Other Fee" prop="noteForOther">
                <el-input v-model="dataForm.noteForOther" placeholder="Note For Other Fee" :oninput="isEn"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="Note For Other Fee (CN)" prop="noteForOtherCn">
                <el-input v-model="dataForm.noteForOtherCn" placeholder="Note For Other Fee (CN)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Status" prop="status">
            <el-radio-group v-model="dataForm.status">
              <el-radio :label="0">Disable</el-radio>
              <el-radio :label="1">Enable</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="dataFormSubmit">Submit</el-button>
            <el-button @click="() => $router.push('/sys/pkg/module')">Back</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dataId: undefined,
      dataForm: {
        businessTypeList: [],
        country: "",
        name: "",
        nameCn: "",
        moduleCode: "",
        summary: "",
        summaryCn: "",
        price: undefined,
        originalPrice: undefined,
        thirdPartyFee: undefined,
        noteForThirdParty: "",
        noteForThirdPartyCn: "",
        otherFee: undefined,
        noteForOther: "",
        noteForOtherCn: "",
        status: 1,
      },
      dataRule: {
        businessTypeList: [{ required: true, message: "Business Type is required", trigger: "blur" }],
        country: [{ required: true, message: "Country is required", trigger: "blur" }],
        name: [{ required: true, message: "Name is required", trigger: "blur" }],
        nameCn: [{ required: true, message: "Name(CN) is required", trigger: "blur" }],
        moduleCode: [{ required: true, message: "code is required", trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["businessTypes", "countryList"]),
  },
  activated() {
    this.submitControl = true;
    this.$refs.dataForm.resetFields();
    const id = this.$route.params.id;
    if (id) {
      this.dataId = id;
      this.getDetail(id);
    } else {
      this.dataId = undefined;
    }
  },
  methods: {
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/moduleManage/${id}/detail`);
      if (data.configData) {
        data.configData = JSON.stringify(data.configData, null, 4);
      }
      this.dataForm = data;
    },
    async dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const dataId = this.dataId;
          const dataForm = { ...this.dataForm };
          if (dataForm.price == "" || dataForm.price == undefined) {
            dataForm.price = null;
          }
          if (dataForm.originalPrice == "" || dataForm.originalPrice == undefined) {
            dataForm.originalPrice = null;
          }
          if (dataForm.thirdPartyFee == "" || dataForm.thirdPartyFee == undefined) {
            dataForm.thirdPartyFee = null;
          }
          if (dataForm.otherFee == "" || dataForm.otherFee == undefined) {
            dataForm.otherFee = null;
          }
          let { code, msg } = await this.$http.post(dataId ? `/admin/moduleManage/${dataId}/update` : "/admin/moduleManage/create", dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.$router.push("/sys/pkg/module");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
