<template>
  <el-dialog :title="!dataForm.id ? 'Add Role' : 'Edit Role'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-form-item label="Role Code" prop="code">
        <el-input v-model="dataForm.code" placeholder="Role Code" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Role Name" prop="name">
        <el-input v-model="dataForm.name" placeholder="Role Name" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item size="mini" label="Permissons">
        <el-tree :data="menuList" :props="menuListTreeProps" node-key="id" ref="menuListTree" :default-expand-all="true" show-checkbox></el-tree>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      visible: false,
      menuList: [],
      menuListTreeProps: {
        label: "name",
        children: "children",
      },
      dataForm: {
        id: 0,
        code: "",
        name: "",
      },
      dataRule: {
        code: [{ required: true, message: "Role Code is required", trigger: "blur" }],
        name: [{ required: true, message: "Role Name is required", trigger: "blur" }],
      },
      tempKey: -666666, // 临时key, 用于解决tree半选中状态项不能传给后台接口问题. # 待优化
      submitControl: true,
    };
  },
  methods: {
    ...mapMutations("role", ["addOne", "updateOne"]),
    async init(id) {
      this.submitControl = true;
      this.dataForm.id = id || 0;
      const { data } = await this.$http.get("/admin/sysMenu/getList");
      this.menuList = treeDataTranslate(data, "id");
      this.visible = true;
      await this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.$refs.menuListTree.setCheckedKeys([]);
      });
      if (this.dataForm.id) {
        const { data } = await this.$http.get(`/admin/sysRole/${this.dataForm.id}/detail`);
        this.dataForm.code = data.code;
        this.dataForm.name = data.name;
        var idx = data.menuIdList.indexOf(this.tempKey);
        if (idx !== -1) {
          data.menuIdList.splice(idx, data.menuIdList.length - idx);
        }
        this.$refs.menuListTree.setCheckedKeys(data.menuIdList);
      }
    },
    // 表单提交
    async formSubmitRequest(params) {
      if (params.id) {
        return await this.$http.post(`/admin/sysRole/${params.id}/update`, params);
      } else {
        return await this.$http.post(`/admin/sysRole/create`, params);
      }
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const params = {
            id: this.dataForm.id,
            code: this.dataForm.code,
            name: this.dataForm.name,
            menuIdList: [].concat(this.$refs.menuListTree.getCheckedKeys(), [this.tempKey], this.$refs.menuListTree.getHalfCheckedKeys()),
          };
          const { code, msg } = await this.formSubmitRequest(params);
          if (code === 200) {
            if (this.dataForm.id) {
              this.updateOne(params);
            } else {
              this.addOne(params);
            }
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
