var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pl-module-item"
  }, [_c('el-checkbox', {
    staticClass: "pl20",
    on: {
      "change": _vm.changeHandler
    },
    model: {
      value: _vm.data.checked,
      callback: function ($$v) {
        _vm.$set(_vm.data, "checked", $$v);
      },
      expression: "data.checked"
    }
  }), _c('div', {
    staticClass: "content",
    on: {
      "click": _vm.boxClickHandler
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.data.name))])]), _c('div', {
    staticClass: "right"
  }, [!_vm.selectable && _vm.metas.length > 0 ? _c('xmeta', {
    attrs: {
      "metas": _vm.metas,
      "data": _vm.data
    },
    on: {
      "update": _vm.updateHandler
    }
  }, [_c('i', {
    staticClass: "el-icon-setting r-opts"
  })]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }