<template>
  <div class="pd26 bg-white border-radius-10">
    <div class="flex flex-direction-row align-center justify-between">
      <h3 class="home-title">Statistics of Transaction Status at Each Stage</h3>
      <el-button type="text" icon="el-icon-download" @click="handleExportExcel">Export Excel</el-button>
    </div>
    <p class="home-introduce">Average over preceding 3 months</p>
    <el-row class="mt40">
      <el-col :span="8" class="flex flex-direction align-center">
        <div class="wd125 hg125">
          <el-progress
            type="circle"
            :percentage="majorStatusRatioFormat('SUBMITTED')"
            color="#FF7E69"
            :stroke-width="12"
            :width="125"
            class="ratio-bold"
          ></el-progress>
        </div>
        <h3 class="mt26 major-status">Submitted</h3>
        <p class="dot-aurantia">{{ majorStatusFormat("SUBMITTED") }}</p>
      </el-col>
      <el-col :span="8" class="flex flex-direction align-center">
        <div class="wd125 hg125">
          <el-progress
            type="circle"
            :percentage="majorStatusRatioFormat('REVISING')"
            color="#0072FF"
            :stroke-width="12"
            :width="125"
            class="ratio-bold"
          ></el-progress>
        </div>
        <h3 class="mt26 major-status">Revising</h3>
        <p class="dot-blue">{{ majorStatusFormat("REVISING") }}</p>
      </el-col>
      <el-col :span="8" class="flex flex-direction align-center">
        <div class="wd125 hg125">
          <el-progress
            type="circle"
            :percentage="majorStatusRatioFormat('FINISH')"
            color="#52D4FF"
            :stroke-width="12"
            :width="125"
            class="ratio-bold"
          ></el-progress>
        </div>
        <h3 class="mt26 major-status">Order Completed</h3>
        <p class="dot-indigo">{{ majorStatusFormat("FINISH") }}</p>
      </el-col>
    </el-row>

    <el-row :gutter="70" class="mt43 pl46 pr46">
      <el-col :span="12" class="mb40" v-for="item in statusRatioList" :key="'statusRatioList' + item.applymentStatus">
        <div class="hg70 flex flex-direction-row align-center justify-between">
          <h3 class="fs16 home-title">{{ applymentStatusFormat(item.applymentStatus) }}</h3>
          <p class="fs16 home-proportion">
            {{ item.cnt }}
            <span>/{{ statusRatioSum }}</span>
          </p>
        </div>
        <el-progress
          :percentage="parseFloat(item.cnt) && statusRatioSum ? div(item.cnt, statusRatioSum) * 100 : 0"
          :show-text="false"
          :stroke-width="5"
          color="#0072FF"
        ></el-progress>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { open, toFixedDecimal, div, get3MonthBefor, getNowFormatDate } from "@/utils";
import axiosInstance from "@/utils/http";
import { mapState } from "vuex";
export default {
  data() {
    return {
      statusRatioList: [],
      statusRatioSum: 0,
    };
  },
  computed: {
    ...mapState("constant", ["applymentStatusList"]),
    toFixedDecimal() {
      return val => {
        return toFixedDecimal(val);
      };
    },
    div() {
      return (...arg) => {
        return div(...arg);
      };
    },
  },
  methods: {
    // 申请单状态格式化
    applymentStatusFormat(v) {
      const one = this.applymentStatusList.find(i => i.value === v);
      return one?.title;
    },
    // 导出 Excel
    async handleExportExcel() {
      open(
        `${
          axiosInstance.defaults.baseURL
        }/admin/statisticsXlsx/downloadApplymentStatusDailyDistribution?startTime=${get3MonthBefor()}&endTime=${getNowFormatDate()}`,
      );
    },
    // 百分比
    majorStatusRatioFormat(v) {
      const one = this.statusRatioList.find(i => i.applymentStatus === v);
      if (one) {
        return parseFloat(one.cnt) && this.statusRatioSum ? parseFloat(toFixedDecimal(div(one.cnt, this.statusRatioSum) * 100)) : 0;
      } else {
        return 0;
      }
    },
    // CHECKING、REVISING、REFUND_APPLY_REJECT 数量
    majorStatusFormat(v) {
      const one = this.statusRatioList.find(i => i.applymentStatus === v);
      return one?.cnt || 0;
    },
    // 系统交易里面的不同状态的交易单数比例
    getStatusRatio() {
      this.$http
        .get("/admin/statistics/getApplymentStatusDistribution", {
          params: {
            startTime: get3MonthBefor(),
            endTime: getNowFormatDate(),
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.statusRatioList = data;
            let summation = 0;
            this.statusRatioList.forEach(item => {
              summation = summation + parseFloat(item.cnt);
            });
            this.statusRatioSum = summation;
          } else {
            this.$message.error(msg);
          }
        });
    },
  },
  activated() {
    // 系统交易里面的不同状态的交易单数比例
    this.getStatusRatio();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.home-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: $form-item-font-color;
}

.home-subhead {
  margin: 0;
  padding: 0;
  margin-top: 6px;
  font-size: 14px;
  font-weight: bold;
  color: $form-item-font-color;
  line-height: 20px;
}

.home-introduce {
  font-size: 16px;
  color: #7987a0;
  padding: 0;
  margin: 0;
  line-height: 22px;
}

.home-proportion {
  font-size: 20px;
  font-weight: 500;
  color: $form-item-font-color;
  margin: 0;
  padding: 0;
  span {
    color: #939db0;
  }
}

.major-status {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  color: $form-item-font-color;
  height: 50px;
  text-align: center;
}

.dot-aurantia {
  color: #ff7e69;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.dot-aurantia::before {
  display: inline-block;
  content: "";
  background: #ff7e69;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  vertical-align: middle;
  margin-right: 8px;
}

.darkBlue {
  color: #0072ff !important;
}

.dot-blue {
  color: #0072ff;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.dot-blue::before {
  display: inline-block;
  content: "";
  background: #0072ff;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  vertical-align: middle;
  margin-right: 8px;
}

.dot-indigo {
  color: #52d4ff;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.dot-indigo::before {
  display: inline-block;
  content: "";
  background: #52d4ff;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  vertical-align: middle;
  margin-right: 8px;
}
</style>
