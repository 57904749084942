<template>
  <div class="page-container">
    <div class="mod-user">
      <div class="mb20">
        <el-button v-if="isAuth('engagement:save')" type="primary" @click="addOrUpdateHandle()">Add Client</el-button>
      </div>
      <div>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList(1)">
          <el-form-item>
            <el-input v-model="dataForm.keyword" :placeholder="kwPlaceHolder" class="wd416 input-with-select-lg" :oninput="isEn" clearable>
              <el-select v-model="dataForm.kwType" slot="prepend" placeholder="please select">
                <el-option v-for="item in kwTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="dataForm.inChargeUserName"
              placeholder="In-charge"
              clearable
              filterable
              remote
              :remote-method="getInChargeRemote"
              @clear="getInChargeRemote('')"
              @focus="getInChargeRemote('')"
              :loading="inChargeLoading"
            >
              <el-option
                v-for="(item, pm) in inChargeList"
                :label="`${item.nickname}-${item.staffNo}-${item.position}`"
                :key="`${item.id}${pm}`"
                :value="item.username"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="dataForm.status" multiple clearable placeholder="Status">
              <el-option v-for="item in engagementStatusList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker-range
              :startDate="dataForm.coveredPeriodStart"
              :endDate="dataForm.coveredPeriodEnd"
              startPlaceholder="Covered (Start Date)"
              endPlaceholder="Covered (End Date)"
              @startSelectChange="
                val => {
                  dataForm.coveredPeriodStart = val;
                }
              "
              @endSelectChange="
                val => {
                  dataForm.coveredPeriodEnd = val;
                }
              "
            ></el-date-picker-range>
          </el-form-item>
          <el-form-item>
            <!-- <el-date-picker
              v-model="dataForm.fyEnd"
              type="date"
              placeholder="FY end"
              :format="dataForm.fyEnd ? `dd [${monthList[parseInt(dataForm.fyEnd.split('-')[0]) - 1]}]` : 'dd MM'"
              value-format="MM-dd"
            ></el-date-picker> -->
            <el-select-all
              :width="200"
              :optionList="monthOptions"
              placeholder="FY end"
              @elSelectAllChange="
                list => {
                  dataForm.fyEndMonth = list;
                }
              "
            ></el-select-all>
          </el-form-item>
          <el-form-item>
            <el-select v-model="dataForm.companyType" placeholder="Entity Type" filterable clearable>
              <el-option v-for="item in engagementCompanyTypeList" :key="item.title" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="dataForm.companyCountry" placeholder="Entity Country" filterable clearable>
              <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="dataForm.uen"
              clearable
              filterable
              remote
              :remote-method="getUenRemote"
              @clear="getUenRemote('')"
              @focus="getUenRemote('')"
              :loading="uenLoading"
              placeholder="UEN"
            >
              <el-option v-for="(item, index) in uenList" :key="`${item.uen}${index}`" :label="item.uen" :value="item.uen"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="pt1">
            <el-select-all
              class="controlLeft"
              :width="202"
              :optionList="controlTypeObjList"
              placeholder="Service Type"
              @elSelectAllChange="
                list => {
                  dataForm.controlType = list;
                }
              "
            ></el-select-all>
            <el-select v-model="dataForm.isBlank" placeholder="Select Status Or Blank" class="wd180 controlCentre" @change="isBlankChange">
              <el-option label="Select Status" :value="0"></el-option>
              <el-option label="Select Blank" :value="1"></el-option>
            </el-select>
            <template v-if="dataForm.isBlank == 0">
              <el-select-all
                class="controlCentre"
                :width="200"
                :optionList="engagementControlStatusList"
                placeholder="Detailed Status"
                @elSelectAllChange="
                  list => {
                    dataForm.controlStatus = list;
                  }
                "
              ></el-select-all>
              <el-date-picker-range
                :startDate="dataForm.controlDeadlineStart"
                :endDate="dataForm.controlDeadlineEnd"
                :width="150"
                @startSelectChange="
                  val => {
                    dataForm.controlDeadlineStart = val;
                  }
                "
                @endSelectChange="
                  val => {
                    dataForm.controlDeadlineEnd = val;
                  }
                "
                class="controlRight"
              ></el-date-picker-range>
            </template>
          </el-form-item>
          <el-form-item>
            <el-date-picker-range
              :startDate="dataForm.incorpDateStart"
              :endDate="dataForm.incorpDateEnd"
              startPlaceholder="Incorp. (Start Date)"
              endPlaceholder="Incorp. (End Date)"
              @startSelectChange="
                val => {
                  dataForm.incorpDateStart = val;
                }
              "
              @endSelectChange="
                val => {
                  dataForm.incorpDateEnd = val;
                }
              "
            ></el-date-picker-range>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="mb20">
        <!-- 导出 excel -->
        <el-button
          v-if="isAuth('engagement:export')"
          type="primary"
          plain
          size="small"
          icon="el-icon-download"
          :disabled="totalCount == 0"
          @click="handleExportExcel"
        >
          Export Excel
        </el-button>
      </div>
      <el-table
        max-height="640"
        :data="dataList"
        v-loading="dataListLoading"
        @sort-change="tableSortChange"
        :default-sort="{ prop: 'clientCode', order: 'descending' }"
        style="width: 100%"
      >
        <el-table-column
          prop="clientCode"
          header-align="center"
          align="center"
          label="Client Code"
          fixed="left"
          width="126"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="clientType" header-align="center" align="center" label="Type" fixed="left" width="100"></el-table-column>
        <el-table-column prop="relatedUserTransactions" header-align="center" align="center" label="Transaction No." fixed="left" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.relatedUserTransactions">
              <span v-for="(item, idx) in scope.row.relatedUserTransactions" :key="`transactionNo${idx}${item.transactionNo}`">
                {{ idx == 0 ? "" : ", " }} {{ item.transactionNo }}
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="inChargeUser" header-align="center" align="center" label="In-charge" fixed="left" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.relatedUserTransactions">
              <p v-for="(item, idx) in scope.row.relatedUserTransactions" :key="`username${idx}${item.transactionNo}`">
                <span v-for="(child, xv) in item.inChargeUsers" :key="`inChargeUsers${idx}${xv}${child.id}`">
                  {{ xv == 0 ? "" : ", " }} {{ child.nickname }}
                </span>
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="uen" header-align="center" align="center" label="UEN" fixed="left" width="120"></el-table-column>
        <el-table-column prop="companyName" header-align="center" align="center" label="Entity Name" fixed="left" width="150"></el-table-column>
        <el-table-column prop="coveredPeriodStart" header-align="center" align="center" label="Covered Period" fixed="left" width="154" sortable="custom">
          <template slot-scope="scope">
            <p v-if="scope.row.coveredPeriodStart">{{ scope.row.coveredPeriodStart }}</p>
            <p v-if="scope.row.coveredPeriodEnd">{{ scope.row.coveredPeriodEnd }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          header-align="center"
          align="center"
          label="Status"
          :formatter="statusFormatter"
          fixed="left"
          width="150"
        ></el-table-column>
        <el-table-column prop="previousCompanyName" header-align="center" align="center" label="Previous Entity Name" width="180"></el-table-column>
        <el-table-column prop="incorpDate" header-align="center" align="center" label="Incorp. date" width="150" sortable="custom">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.incorpDate"
              type="date"
              :format="`dd [${monthList[parseInt(scope.row.incorpDate.split('-')[1]) - 1]}] yyyy`"
              value-format="yyyy-MM-dd"
              v-if="scope.row.incorpDate"
              :clearable="false"
              :readonly="true"
              disabled
              class="fluid-width datePicker"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column prop="fyPeriod" header-align="center" align="center" label="FY Period" width="150"></el-table-column>
        <el-table-column prop="fyEnd" header-align="center" align="center" label="FY End" width="150">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.fyEnd"
              type="date"
              :format="`dd [${monthList[parseInt(scope.row.fyEnd.split('-')[0]) - 1]}]`"
              value-format="MM-dd"
              v-if="scope.row.fyEnd"
              :clearable="false"
              :readonly="true"
              disabled
              class="fluid-width datePicker"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column prop="companyCountry" header-align="center" align="center" label="Entity Country" width="150"></el-table-column>
        <el-table-column
          prop="companyType"
          header-align="center"
          align="center"
          label="Entity Type"
          width="150"
          :formatter="companyTypeFormatter"
        ></el-table-column>
        <el-table-column
          prop="controlDataList"
          header-align="center"
          align="center"
          label="Service Type"
          :formatter="serviceTypeFormat"
          min-width="150"
        ></el-table-column>
        <el-table-column header-align="center" align="center" label="AGM/EGM" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'AGM/EGM'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Report Filing" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'Report Filing'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="CIT filing" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'CIT filing'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="GST filing" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'GST filing'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Other tax filing" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'Other tax filing'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Secretary" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'Secretary'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Account" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'Account'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Advisory" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'Advisory'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="HR" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'HR'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Others" width="210">
          <template slot-scope="scope">
            <div v-for="dead in scope.row.controlDataList" :key="`dead${scope.row.id}${dead.id}`">
              <p v-if="dead.controlType && dead.controlType == 'Others'">
                <span>{{ dead.deadline }}</span>
                <span :class="statusDeadColor(dead.status)">, {{ statusDeadFormatter(dead.status) }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="120" label="Action">
          <template slot-scope="scope">
            <el-button v-if="isAuth('engagement:update')" type="text" class="fwb" @click="addOrUpdateHandle(scope.row)">Edit</el-button>
            <el-button v-if="isAuth('engagement:delete')" type="text" class="fwb" @click="deleteHandle(scope.row)">Delete</el-button>
            <el-button v-if="isAuth('engagement:charge') || isAuth('engagement:linked')" type="text" class="fwb" @click="detailHandler(scope.row.id)">
              Detail
            </el-button>
            <el-button v-if="isAuth('engagement:copy') && scope.row.status == 'CLOSE'" type="text" class="fwb" @click="copyFromHandle(scope.row)">
              Copy from
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
  </div>
</template>

<script>
import AddOrUpdate from "./modal/AddOrUpdate";
import { mapState } from "vuex";
import axiosInstance from "@/utils/http";
import { open } from "@/utils";
import ElSelectAll from "@/components/ElSelectAll";
import ElDatePickerRange from "@/components/ElDatePickerRange";
import paginationDelete from "@/mixins/paginationDelete.js";
import qs from "querystring";
export default {
  mixins: [paginationDelete],
  data() {
    return {
      monthList: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      kwTypes: [
        {
          title: "Client Code",
          value: "clientCode",
          placeholder: "Client Code",
        },
        {
          title: "Entity Name",
          value: "companyName",
          placeholder: "Entity Name",
        },
        {
          title: "Transaction No.",
          value: "transactionNo",
          placeholder: "Transaction No.",
        },
        {
          title: "Type",
          value: "clientType",
          placeholder: "Type",
        },
      ],
      dataForm: {
        kwType: "clientCode",
        keyword: "",
        companyName: "",
        clientCode: "",
        transactionNo: "",
        inChargeUserName: "",
        status: [],
        fyEndMonth: [],
        companyType: "",
        companyCountry: "",
        uen: "",
        controlType: [],
        controlStatus: [],
        isBlank: 0,
        controlDeadlineStart: "",
        controlDeadlineEnd: "",
        coveredPeriodStart: "",
        coveredPeriodEnd: "",
        incorpDateStart: "",
        incorpDateEnd: "",
        "orders[0].asc": false,
        "orders[0].column": "t1.client_code",
      },
      dataList: [],
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      inChargeList: [],
      inChargeLoading: false,
      dataListSelections: [],
      uenList: [],
      uenLoading: false,
    };
  },
  components: {
    AddOrUpdate,
    ElSelectAll,
    ElDatePickerRange,
  },
  activated() {
    this.getDataList();
    // 获取uen分页，默认展示前20条
    this.getUenRemote("");
    this.getInChargeRemote("");
  },
  computed: {
    ...mapState("constant", [
      "countryList",
      "engagementCompanyTypeList",
      "engagementStatusList",
      "serviceTypeList",
      "engagementControlStatusList",
      "controlTypeList",
      "monthOptions",
    ]),
    kwPlaceHolder() {
      return this.kwTypes.find(i => i.value === this.dataForm.kwType).placeholder;
    },
    controlTypeObjList() {
      // 将[""]格式改变成[title: "", value: ""]
      return this.controlTypeList.map(item => {
        return { title: item, value: item };
      });
    },
  },
  methods: {
    statusDeadFormatter(cellValue) {
      const one = this.engagementControlStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    statusDeadColor(cellValue) {
      const one = this.engagementControlStatusList.find(i => i.value === cellValue);
      if (one.value == "NOT_STARTED") {
        return "red";
      } else if (one.value == "IN_PROGRESS") {
        return "green";
      }
    },
    stateFormat(row, column, cellValue) {
      if (cellValue === false) {
        return (
          <el-tag size="samll" type="dange">
            Disable
          </el-tag>
        );
      } else {
        return <el-tag size="samll">Enable</el-tag>;
      }
    },
    statusFormatter(row, column, cellValue) {
      const one = this.engagementStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    companyTypeFormatter(row, column, cellValue) {
      const one = this.engagementCompanyTypeList.find(i => i.value === cellValue);
      return one?.title;
    },
    // Service Type table格式化
    serviceTypeFormat(row, column, list) {
      if (list && list.length == 0) return [];
      let arrRes = [];
      this.serviceTypeList.forEach(ele => {
        let serviceDetails = [];
        list.forEach(item => {
          if (item.linkedServiceType.includes(ele.value)) {
            serviceDetails.push(item.controlDesp);
          }
        });
        if (serviceDetails.length > 0) {
          arrRes.push(ele.title);
        }
      });
      return arrRes.join(", ");
    },
    // isBlank change
    isBlankChange() {
      if (this.dataForm.isBlank) {
        this.$set(this.dataForm, "controlStatus", []);
        this.$set(this.dataForm, "controlDeadlineStart", "");
        this.$set(this.dataForm, "controlDeadlineEnd", "");
      }
    },
    // 获取uen列表
    async getUenRemote(query) {
      this.uenLoading = true;
      const { code, data } = await this.$http.get("/admin/uenMa/getList", {
        params: {
          uen: query,
        },
      });
      this.uenLoading = false;
      if (code === 200) {
        this.uenList = data.filter(ele => ele != null && ele.uen != null);
        // 首位添加N/A
        const na = "N/A";
        if (na.includes(query)) {
          this.uenList.unshift({
            companyName: "N/A",
            transactionNo: "N/A",
            uen: "N/A",
          });
        }
      } else {
        this.uenList = [];
      }
    },
    // table - 排序
    tableSortChange(column) {
      if (column.prop == "incorpDate") {
        this.dataForm["orders[0].column"] = "t1.incorp_date";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "clientCode") {
        this.dataForm["orders[0].column"] = "t1.client_code";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "coveredPeriodStart") {
        this.dataForm["orders[0].column"] = "t1.covered_period_start";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else {
        this.dataForm["orders[0].column"] = "id";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      }
    },
    // 获取数据分页
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
        ...this.dataForm,
        [this.dataForm.kwType]: this.dataForm.keyword,
      };
      const { code, data } = await this.$http.get("/admin/engagementManage/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.$refs.addOrUpdate.init(row);
    },
    // 删除
    async deleteHandle(row) {
      console.log(row);
      if (row.fyPeriod || row.fyEnd || row.controlDataList.length > 0 || row.relatedUserTransactions.length > 0 || row.status == "CLOSE") {
        this.$message({
          type: "warning",
          dangerouslyUseHTMLString: true,
          message: `<div>
          <strong>Delete failed.</strong>
          <p class="mt4 lh16">Please check with this transaction details, and delete filled information in transaction details before this action.</p>
          </div>`,
        });
        return;
      }
      await this.$confirm(`Delete this Engagement. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const { code, msg } = await this.$http.post(`/admin/engagementManage/${row.id}/delete`, {});
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => {
            // mixins
            this.singleDataDelete();
            this.getDataList();
          },
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 详情
    detailHandler(id) {
      this.$router.push(`/engagement/${id}`);
    },
    // copy from
    async copyFromHandle(row) {
      await this.$confirm(
        `<p>- This action should only be performed if you want to roll forward it to another new period;</p>
        <p>- You need to update the covered period, transaction number and other relevant information of this client after you confirm to proceed this action;</p>`,
        "Please read the Reminder carefully before action, and will you confirm to proceed?",
        {
          customClass: "closureCheckCustom",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "confirmButtonCopyFrom",
          cancelButtonClass: "cancelButtonCopyFrom",
          type: "warning",
        },
      );
      const { code, msg } = await this.$http.post("/admin/engagementManage/copyFrom", {
        id: row.id,
      });
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => this.getDataList(),
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 获取in charge列表
    async getInChargeRemote(query) {
      this.inChargeLoading = true;
      const { code, data } = await this.$http.get("/admin/inChargeUserMa/getList", {
        params: {
          keywords: query,
        },
      });
      this.inChargeLoading = false;
      if (code === 200) {
        this.inChargeList = data;
      } else {
        this.inChargeList = [];
      }
    },
    // 导出所有Excel
    async handleExportExcel() {
      await this.$confirm(`Export excel. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
      const params = {
        ...dataFormCopy,
        [dataFormCopy.kwType]: dataFormCopy.keyword,
      };
      // 删除之前的排序
      delete params["orders[0].asc"];
      delete params["orders[0].column"];
      const paramsQuery = qs.stringify(params);
      const downloadPath = `${axiosInstance.defaults.baseURL}/admin/engagementXlsxManage/download?${paramsQuery}`;
      console.log(downloadPath);
      open(downloadPath);
    },
  },
};
</script>
<style lang="scss" scoped>
.red {
  color: #e54d42;
}

.green {
  color: #8dc63f;
}

.el-form--inline {
  .el-form-item {
    vertical-align: middle;
  }
}

.datePicker {
  ::v-deep .el-input__inner {
    border: none;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    background-color: transparent !important;
    cursor: text;
  }

  ::v-deep .el-input__prefix {
    display: none !important;
    ::v-deep .el-input__icon {
      display: none !important;
      width: 0 !important;
    }
  }

  ::v-deep .el-input__suffix {
    display: none !important;
  }
}

.controlLeft {
  ::v-deep input {
    // border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ::v-deep .el-input__inner:focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input__inner.is-focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input:focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }

  ::v-deep .el-input.is-focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }
}

.controlCentre {
  ::v-deep input {
    border-left: none;
    border-radius: 0;
  }
  ::v-deep .el-input__inner:focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input__inner.is-focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input:focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }

  ::v-deep .el-input.is-focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }
}

.controlRight {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  ::v-deep input {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.closureCheckCustom {
  .el-message-box__header {
    .el-message-box__title {
      font-size: 16px;
      width: 320px;
      line-height: 22px;
    }
  }

  .el-message-box__content {
    .el-message-box__status {
      display: none;
    }
    .el-message-box__message {
      padding-left: 0;
    }
  }
}
</style>

<style lang="scss">
.confirmButtonCopyFrom {
  background-color: #fff !important;
  color: #606266 !important;
  border-color: #dcdfe6 !important;
}

.confirmButtonCopyFrom:hover {
  color: #0072ff !important;
  border-color: #b3d5ff !important;
  background-color: #e6f1ff !important;
}

.cancelButtonCopyFrom {
  color: #ffffff !important;
  background-color: #0072ff !important;
  border-color: #0072ff !important;
}

.cancelButtonCopyFrom:hover,
.cancelButtonCopyFrom:focus {
  background: #338eff !important;
  border-color: #338eff !important;
  color: #ffffff !important;
}
</style>
