<template>
  <div class="step-header">
    <div class="t">{{ label }}</div>
    <div class="r">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  line-height: 46px;

  .t {
    font-size: 18px;
    color: #011A45;
    font-weight: bold;
  }
  .r {
    display: flex;
    align-items: center;
  }
}
</style>
