<template>
  <div class="page-container">
    <div class="mod-dict">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <el-button type="primary" @click="addOrUpdateHandle()">新增字典</el-button>
        </el-form-item>
      </el-form>

      <el-card shadow="nerver">
        <el-table :data="dataList" row-key="id" style="width: 100%">
          <el-table-column prop="name" header-align="center" width="240" label="名称"></el-table-column>
          <el-table-column prop="parentName" header-align="center" align="center" width="240" label="上级目录"></el-table-column>
          <el-table-column prop="type" header-align="center" align="center" width="150" label="类型">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type === 0" size="small">目录</el-tag>
              <el-tag v-else-if="scope.row.type === 1" size="small" type="success">字典</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="orderNum" header-align="center" align="center" width="150" label="排序号"></el-table-column>
          <el-table-column prop="dictCode" header-align="center" align="center" width="220" :show-overflow-tooltip="true" label="字典码"></el-table-column>
          <el-table-column prop="dictValue" header-align="center" align="center" width="220" label="字典值"></el-table-column>
          <el-table-column prop="remark" header-align="center" align="center" label="备注"></el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" width="250" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
              <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
  </div>
</template>

<script>
import AddOrUpdate from "./AddOrUpdate";
import { treeDataTranslate } from "@/utils";
export default {
  meta: {},
  data() {
    return {
      dataForm: {},
      dataList: [],
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    /* 格式化 */
    hiddenFormat(row, column, cellValue) {
      return cellValue === 1 ? "是" : "";
    },
    /* 格式化 */
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      const { data } = await this.$http.get("/admin/sys/dict");
      this.dataList = treeDataTranslate(data, "id");
      this.dataListLoading = false;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    async deleteHandle(id) {
      await this.$confirm(`确认删除？`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      });
      const { code, msg } = await this.$http.delete(`/admin/sys/dict/${id}`);
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => this.getDataList(),
        });
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>
