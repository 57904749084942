<template>
  <el-popover v-model="visible" popper-class="popover-modal" placement="right" width="400" trigger="click" @show="init">
    <div class="module-detail-box">
      <el-form :model="dataForm" ref="dataForm" label-width="100px">
        <el-form-item label="模块名称" prop="name">
          <el-input readonly v-model="data.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="存储" prop="storage" v-if="checkVisiable('storage')">
          <el-input type="number" v-model="dataForm.storage" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="流量" prop="bandwidth" v-if="checkVisiable('bandwidth')">
          <el-input type="number" v-model="dataForm.bandwidth" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="quantity" v-if="checkVisiable('quantity')">
          <el-input type="number" v-model="dataForm.quantity" placeholder=""></el-input>
        </el-form-item>
        <div class="footer text-right">
          <el-button size="mini" type="primary" @click="dataFormSubmit">确认</el-button>
          <el-button size="mini" @click="closePopover">取消</el-button>
        </div>
      </el-form>
    </div>
    <template slot="reference">
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    metas: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        storage: undefined,
        bandwidth: undefined,
        quantity: undefined,
      },
    };
  },
  methods: {
    init() {
      this.visible = true;
      this.dataForm = this.data;
    },
    checkVisiable(propName) {
      return this.metas.includes(propName);
    },
    closePopover() {
      this.visible = false;
    },
    async dataFormSubmit() {
      this.$emit("update", { ...this.data, ...this.dataForm });
      this.visible = false;
    },
  },
};
</script>

<style></style>
