<template>
  <div class="step mt40">
    <el-card>
      <div class="desp">
        <div class="desp-header">
          <div class="label">Fee difference between service type and linked transaction</div>
        </div>
        <div class="pt14">
          <el-row :gutter="20" class="customTh">
            <el-col :span="6">
              <p class="text-center">Type</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center">Linked Transactions</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center">Service Type</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center">
                Difference
                <el-popover placement="bottom" trigger="hover">
                  <p class="fs12">Difference = Related Transaction (Total) (Linked Transactions) - Related Transaction (Total) (Service Type)</p>
                  <p class="fs12">Difference = Related Turnover (Total) (Linked Transactions) - Related Turnover (Total) (Service Type)</p>
                  <span slot="reference" class="pearl-blue fs16 ml4 el-icon-question"></span>
                </el-popover>
              </p>
            </el-col>
          </el-row>

          <el-row :gutter="20" class="customTbody">
            <el-col :span="6">
              <p class="text-center">Related Transaction (Total)</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center">{{ setDifferenceData.linkedTransactionsRelatedTransactionTotal | thousands }}</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center">{{ setDifferenceData.serviceTypeRelatedTransactionTotal | thousands }}</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center red fwb">{{ setDifferenceData.relatedTransactionTotalDifference | thousands }}</p>
            </el-col>
          </el-row>

          <el-row :gutter="20" class="customTbody">
            <el-col :span="6">
              <p class="text-center">Related Turnover (Total)</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center">{{ setDifferenceData.linkedTransactionsRelatedTurnoverTotal | thousands }}</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center">{{ setDifferenceData.serviceTypeRelatedTurnoverTotal | thousands }}</p>
            </el-col>
            <el-col :span="6">
              <p class="text-center red fwb">{{ setDifferenceData.RelatedTurnoverTotalDifference | thousands }}</p>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { fmoney, accountAdd, sub } from "@/utils";
export default {
  props: {
    relatedUserTransactions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    serviceTypeDataList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    setDifferenceData() {
      const resData = {
        // 第一行
        linkedTransactionsRelatedTransactionTotal: 0,
        serviceTypeRelatedTransactionTotal: 0,
        relatedTransactionTotalDifference: 0,
        // 第二行
        linkedTransactionsRelatedTurnoverTotal: 0,
        serviceTypeRelatedTurnoverTotal: 0,
        RelatedTurnoverTotalDifference: 0,
      };

      const relatedUserTransactions = this.relatedUserTransactions;
      relatedUserTransactions.forEach(item => {
        const relatedTransaction = item.relatedTransaction;
        const relatedTurnover = item.relatedTurnover;
        resData.linkedTransactionsRelatedTransactionTotal = accountAdd(resData.linkedTransactionsRelatedTransactionTotal, relatedTransaction);
        resData.linkedTransactionsRelatedTurnoverTotal = accountAdd(resData.linkedTransactionsRelatedTurnoverTotal, relatedTurnover);
      });

      const serviceTypeDataList = this.serviceTypeDataList;
      serviceTypeDataList.forEach(item => {
        const relatedTransaction = item.relatedTransaction;
        const relatedTurnover = item.relatedTurnover;
        resData.serviceTypeRelatedTransactionTotal = accountAdd(resData.serviceTypeRelatedTransactionTotal, relatedTransaction);
        resData.serviceTypeRelatedTurnoverTotal = accountAdd(resData.serviceTypeRelatedTurnoverTotal, relatedTurnover);
      });

      resData.relatedTransactionTotalDifference = sub(resData.linkedTransactionsRelatedTransactionTotal, resData.serviceTypeRelatedTransactionTotal);
      resData.RelatedTurnoverTotalDifference = sub(resData.linkedTransactionsRelatedTurnoverTotal, resData.serviceTypeRelatedTurnoverTotal);

      return resData;
    },
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.blue {
  color: $--color-primary;
}

.red {
  color: $--color-danger;
}

.fwb {
  font-weight: bold;
}

.customTh {
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  background-color: #f4f8fe;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.customTbody {
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  font-size: 14px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
