<template>
  <el-dialog width="1104px" title="Edit Service Type" :close-on-click-modal="false" :visible.sync="visible">
    <div class="tableBorder">
      <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="0px">
        <div class="flex fs14 fwb customTh">
          <p class="text-center wd180 mr20">Service Type</p>
          <p class="text-center wd320 mr20">Service Details</p>
          <p class="text-center wd220 mr20">Related Transaction</p>
          <p class="text-center wd220">Related Turnover</p>
        </div>

        <div class="flex customTbody" v-for="(item, index) in dataForm.serviceList" :key="`serviceList${index}`">
          <el-form-item class="fxRow wd180 mr20 mb20">{{ item.serviceType }}</el-form-item>
          <el-form-item class="fxRow wd320 mr20 mb20 enbr text-center">
            <span>{{ item.serviceDetails.filter(item => item).join(", ") }}</span>
          </el-form-item>
          <el-form-item :prop="`serviceList.${index}.relatedTransaction`" :ref="`serviceList.${index}.relatedTransaction`" class="fxRow wd220 mr20 mb20">
            <el-input v-model="dataForm.serviceList[index].relatedTransaction" v-minusPoint2 clearable placeholder="0.00">
              <template slot="prefix">S$</template>
            </el-input>
          </el-form-item>
          <el-form-item :prop="`serviceList.${index}.relatedTurnover`" :ref="`serviceList.${index}.relatedTurnover`" class="fxRow wd220 mb20">
            <el-input v-model="dataForm.serviceList[index].relatedTurnover" v-minusPoint2 clearable placeholder="0.00">
              <template slot="prefix">S$</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        serviceList: [],
      },
    };
  },
  methods: {
    async init(list) {
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.dataForm = {
        serviceList: list,
      };
    },
    // 保存
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const dataForm = JSON.parse(JSON.stringify(this.dataForm));
          this.$emit("serviceTypeFeedback", dataForm.serviceList);
          this.visible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.blue {
  color: $--color-primary;
}

.red {
  color: $--color-danger;
}

.tableBorder {
  .customTh {
    padding: 0 20px;
    background-color: #f4f8fe;
    border-bottom: 1px solid #ebeef5;
  }

  .customTbody {
    padding: 20px 20px 0;
    border-bottom: 1px solid #ebeef5;
  }
}
</style>
