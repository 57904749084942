<template>
  <div class="page-container">
    <div class="mod-user">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList(1)">
        <div class="flex-row jcsb">
          <!-- <div>
            <el-form-item>
              <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle()">Add User</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-if="isAuth('sys:user:config')" @click="setConfig">Config</el-button>
            </el-form-item>
          </div> -->
          <div>
            <el-form-item>
              <el-select v-model="dataForm.roleId" clearable placeholder="Role Name" class="wd150">
                <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.nickname" placeholder="Nick Name" clearable :oninput="isEn" class="wd150"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.staffNo" placeholder="Staff No." clearable :oninput="isEn"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.position" placeholder="Position" clearable :oninput="isEn"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
            </el-form-item>
            <el-form-item class="sync">
              <el-button type="primary" class="fs14" @click="synchronous">Sync</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="mb26">
        <!-- <el-button
          v-if="isAuth('sys:user:delete')"
          type="danger"
          plain
          size="small"
          @click="deleteHandle('multiple')"
          :disabled="dataListSelections.length <= 0"
          icon="el-icon-delete"
        >
          Delete
        </el-button> -->
      </div>
      <el-table max-height="600" :data="dataList" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%">
        <el-table-column type="selection" header-align="center" align="center" width="50" fixed="left"></el-table-column>
        <el-table-column prop="email" header-align="center" align="center" width="200" label="Email" fixed="left"></el-table-column>
        <el-table-column prop="nickname" header-align="center" align="center" width="120" label="Nick Name" fixed="left"></el-table-column>

        <el-table-column prop="staffNo" header-align="center" align="center" width="120" label="Staff No." fixed="left"></el-table-column>
        <el-table-column prop="position" header-align="center" align="center" width="150" label="Position"></el-table-column>
        <el-table-column prop="employers" header-align="center" align="center" width="150" label="Employer">
          <template slot-scope="scope">
            <p v-if="scope.row.employers">{{ scope.row.employers.join(", ") }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="phone" header-align="center" align="center" width="150" label="Phone"></el-table-column>
        <el-table-column prop="roleList" header-align="center" align="center" width="180" label="Role" :formatter="roleListFormatter"></el-table-column>
        <el-table-column prop="createTime" header-align="center" align="center" width="180" label="Register Date"></el-table-column>
        <!-- <el-table-column prop="lastLoginTime" header-align="center" align="center" width="180" label="Login Date"></el-table-column>
        <el-table-column prop="lastLoginIp" header-align="center" min-width="150" align="center" label="IP" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="enabled" header-align="center" align="center" width="100" label="Status" :formatter="stateFormat"></el-table-column>
        <el-table-column prop="remark" header-align="center" align="center" min-width="150" label="Remark" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="120" label="Action">
          <template slot-scope="scope">
            <el-button v-if="isAuth('sys:user:update')" type="text" class="fwb" @click="addOrUpdateHandle(scope.row.id)">Roles</el-button>
            <!-- <el-button v-if="isAuth('sys:user:delete') && scope.row.id > 1" type="text" class="fwb" @click="deleteHandle(scope.row.id)">Delete</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- Config 弹框 -->
    <config-modal ref="configModal" @getEmployerList="getEmployerList"></config-modal>
  </div>
</template>

<script>
import AddOrUpdate from "./modal/AddOrUpdate";
import ConfigModal from "./modal/config";
import paginationDelete from "@/mixins/paginationDelete.js";
import { mapGetters, mapMutations } from "vuex";
export default {
  mixins: [paginationDelete],
  data() {
    return {
      dataForm: {
        roleId: "",
        nickname: "",
        staffNo: "",
        position: "",
      },
      dataList: [],
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      employersList: [],
    };
  },
  components: {
    AddOrUpdate,
    ConfigModal,
  },
  activated() {
    this.getEmployerList();
    this.getDataList();
  },
  mounted() {
    this.fetchRoles();
  },
  computed: {
    ...mapGetters("role", ["roleList"]),
  },
  methods: {
    ...mapMutations("role", ["initRoles"]),
    synchronous() {
      this.$http.post("/admin/sysUser/pull").then(({ code, msg }) => {
          if (code === 200) {
            this.$message.success("Successful")
            this.getDataList(1)
          } else {
            this.$message.error(msg);
          }
        });
    },
    stateFormat(row, column, cellValue) {
      if (cellValue === false) {
        return (
          <el-tag size="samll" type="dange">
            Disable
          </el-tag>
        );
      } else {
        return <el-tag size="samll">Enable</el-tag>;
      }
    },
    // 获取角色列表
    async fetchRoles() {
      const { code, data } = await this.$http.get("/admin/sysRole/getList");
      if (code === 200) {
        this.initRoles(data);
      }
    },
    // 获取数据列表
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
        roleId: this.dataForm.roleId,
        nickname: this.dataForm.nickname,
        staffNo: this.dataForm.staffNo,
        position: this.dataForm.position,
        "orders[0].asc": false,
        "orders[0].column": "t1.id",
      };
      const { code, data } = await this.$http.get("/admin/sysUser/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    roleListFormatter(row, column, cellValue) {
      return cellValue.map(i => i.name).join(", ");
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, this.employersList);
      });
    },
    // 删除
    async deleteHandle(id) {
      var userIds = id == "multiple" ? this.dataListSelections.map(item => item.id) : [id];
      await this.$confirm(`Delete ${id == "multiple" ? "these Users" : "this User"}. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      const { code, msg } = await this.$http.post(
        "/admin/sysUser/delete",
        {},
        {
          params: {
            ids: userIds.join(","),
          },
        },
      );
      if (code === 200) {
        this.$message({
          message: "success",
          type: "success",
          duration: 1500,
          onClose: () => {
            // mixins
            this.singleOrMultipleDataDelete(id);
            this.getDataList();
          },
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 获取Employer列表
    async getEmployerList() {
      const { code, data } = await this.$http.get("/admin/employers/detail", {});
      if (code === 200) {
        if (data && data.employersList && data.employersList.length > 0) {
          this.employersList = data.employersList;
        } else {
          this.employersList = [""];
        }
      } else {
        this.employersList = [""];
      }
    },
    setConfig() {
      this.$refs.configModal.init(this.employersList);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-row{
  .sync{
    margin-left: auto;
    margin-right: 0px;
  }
}

</style>