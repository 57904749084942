var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-dialog', {
    attrs: {
      "title": "Edit Engagement Control",
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "width": "1600px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "mb30"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 5
    }
  }, [_c('span', {
    staticClass: "fwb mr5"
  }, [_vm._v("Client Code:")]), _vm._v(" " + _vm._s(_vm.engagementDetail.clientCode) + " ")]), _c('el-col', {
    attrs: {
      "span": 5
    }
  }, [_c('span', {
    staticClass: "fwb mr5"
  }, [_vm._v("UEN:")]), _vm._v(" " + _vm._s(_vm.engagementDetail.uen) + " ")]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('span', {
    staticClass: "fwb mr5"
  }, [_vm._v("Entity Name:")]), _vm._v(" " + _vm._s(_vm.engagementDetail.companyName) + " ")]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('span', {
    staticClass: "fwb mr5"
  }, [_vm._v("Covered Period:")]), [_c('span', [_vm._v(_vm._s(_vm.engagementDetail.coveredPeriodStart))]), _vm.engagementDetail.coveredPeriodEnd ? _c('span', {
    staticClass: "pl5 pr5"
  }, [_vm._v("~")]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.engagementDetail.coveredPeriodEnd))])]], 2)], 1)], 1), _c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "label-width": "0"
    }
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "span": 8
    }
  }, [_c('span', {
    staticClass: "fwb mr10 lh36 required"
  }, [_vm._v("FY Period:")]), _c('el-form-item', {
    ref: "fyPeriod",
    attrs: {
      "prop": "fyPeriod",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd300",
    attrs: {
      "oninput": _vm.isEn,
      "placeholder": "12 month/52 weeks",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.fyPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fyPeriod", $$v);
      },
      expression: "dataForm.fyPeriod"
    }
  })], 1)], 1), _c('el-col', {
    staticClass: "flex",
    attrs: {
      "span": 8
    }
  }, [_c('span', {
    staticClass: "fwb mr10 lh36 required"
  }, [_vm._v("FY end:")]), _c('el-form-item', {
    ref: "fyEnd",
    attrs: {
      "prop": "fyEnd",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-date-picker', {
    staticClass: "wd300",
    attrs: {
      "type": "date",
      "placeholder": "FY end",
      "format": _vm.dataForm.fyEnd ? `dd [${_vm.monthList[parseInt(_vm.dataForm.fyEnd.split('-')[0]) - 1]}]` : 'dd MM',
      "value-format": "MM-dd"
    },
    model: {
      value: _vm.dataForm.fyEnd,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "fyEnd", $$v);
      },
      expression: "dataForm.fyEnd"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "fluid-width quotationWrap"
  }, [_c('div', {
    staticClass: "mb20 shadeWrap"
  }, [_c('div', {
    staticClass: "billingTitle"
  }, [_c('el-row', {
    staticClass: "pt20 pb20 fxRow borderTop",
    attrs: {
      "gutter": 5
    }
  }, [_c('el-col', {
    attrs: {
      "span": 1
    }
  }), _c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Types")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Type Details")])]), _c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Linked Transaction")])]), _c('el-col', {
    attrs: {
      "span": 4
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Covered Period")])]), _c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_c('p', {
    staticClass: "fs12 fwb wbbw text-center"
  }, [_vm._v("Deadline")])]), _c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Status")])]), _c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Linked Service Type")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Closure Checklist")])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('p', {
    staticClass: "fs12 fwb text-center"
  }, [_vm._v("Remark")])])], 1)], 1), _vm._l(_vm.dataForm.controlDataList, function (child, idx) {
    return _c('div', {
      key: `child${idx}`,
      staticClass: "billingContent"
    }, [_c('el-row', {
      staticClass: "pt20 pb20 fxRow borderTop",
      attrs: {
        "gutter": 5
      }
    }, [_c('el-col', {
      staticClass: "text-center",
      attrs: {
        "span": 1
      }
    }, [_c('span', {
      staticClass: "fs18 cursor-pointer blue el-icon-circle-plus-outline",
      on: {
        "click": function ($event) {
          return _vm.dataForm.controlDataList.splice(idx + 1, 0, {
            controlType: child.controlType,
            controlDesp: '',
            coveredStart: '',
            coveredEnd: '',
            deadline: '',
            status: '',
            linkedServiceType: '',
            closureChecklist: [],
            remark: '',
            isInternal: 0
          });
        }
      }
    }), child.isInternal == 0 ? _c('span', {
      staticClass: "fs18 ml8 cursor-pointer red el-icon-remove-outline",
      on: {
        "click": function ($event) {
          return _vm.dataForm.controlDataList.splice(idx, 1);
        }
      }
    }) : _vm._e()]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 text-center"
    }, [_vm._v(_vm._s(child.controlType))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('el-form-item', {
      ref: `controlDataList.${idx}.controlDesp`,
      refInFor: true,
      attrs: {
        "prop": `controlDataList.${idx}.controlDesp`,
        "rules": {
          required: child.status != 'NONE',
          message: 'please enter',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      staticClass: "fluid-width",
      attrs: {
        "placeholder": "please enter"
      },
      model: {
        value: child.controlDesp,
        callback: function ($$v) {
          _vm.$set(child, "controlDesp", $$v);
        },
        expression: "child.controlDesp"
      }
    })], 1)], 1), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('el-form-item', {
      ref: `controlDataList.${idx}.linkedTransactionNo`,
      refInFor: true,
      attrs: {
        "prop": `controlDataList.${idx}.linkedTransactionNo`,
        "rules": {
          required: child.status != 'NONE',
          message: 'please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-select', {
      staticClass: "fluid-width",
      attrs: {
        "multiple": "",
        "filterable": ""
      },
      model: {
        value: child.linkedTransactionNo,
        callback: function ($$v) {
          _vm.$set(child, "linkedTransactionNo", $$v);
        },
        expression: "child.linkedTransactionNo"
      }
    }, [_c('el-option', {
      attrs: {
        "label": "Pending",
        "value": "Pending"
      }
    }), _vm._l(_vm.relatedUserTransactions, function (chunk) {
      return _c('el-option', {
        key: `controlDataList${idx}${chunk.transactionNo}`,
        attrs: {
          "label": chunk.transactionNo,
          "value": chunk.transactionNo
        }
      });
    })], 2)], 1)], 1), _c('el-col', {
      staticClass: "flex align-center justify-center",
      attrs: {
        "span": 4
      }
    }, [_c('el-form-item', {
      ref: `controlDataList.${idx}.coveredStart`,
      refInFor: true,
      staticClass: "wd110",
      attrs: {
        "prop": `controlDataList.${idx}.coveredStart`,
        "rules": {
          required: child.status != 'NONE',
          message: 'please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-date-picker', {
      staticClass: "wd110",
      attrs: {
        "type": "date",
        "value-format": "yyyy-MM-dd",
        "placeholder": "Start Date"
      },
      model: {
        value: child.coveredStart,
        callback: function ($$v) {
          _vm.$set(child, "coveredStart", $$v);
        },
        expression: "child.coveredStart"
      }
    })], 1), _c('span', {
      staticClass: "ml2 mr2"
    }, [_vm._v("~")]), _c('el-form-item', {
      ref: `controlDataList.${idx}.coveredEnd`,
      refInFor: true,
      staticClass: "wd110",
      attrs: {
        "prop": `controlDataList.${idx}.coveredEnd`,
        "rules": {
          required: child.status != 'NONE',
          message: 'please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-date-picker', {
      staticClass: "wd110",
      attrs: {
        "type": "date",
        "value-format": "yyyy-MM-dd",
        "placeholder": "End Date"
      },
      model: {
        value: child.coveredEnd,
        callback: function ($$v) {
          _vm.$set(child, "coveredEnd", $$v);
        },
        expression: "child.coveredEnd"
      }
    })], 1)], 1), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('el-form-item', {
      ref: `controlDataList.${idx}.deadline`,
      refInFor: true,
      attrs: {
        "prop": `controlDataList.${idx}.deadline`,
        "rules": {
          required: child.status != 'NONE',
          message: 'please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-date-picker', {
      staticClass: "fluid-width",
      attrs: {
        "type": "date",
        "value-format": "yyyy-MM-dd",
        "placeholder": "Deadline"
      },
      model: {
        value: child.deadline,
        callback: function ($$v) {
          _vm.$set(child, "deadline", $$v);
        },
        expression: "child.deadline"
      }
    })], 1)], 1), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('el-form-item', {
      ref: `controlDataList.${idx}.status`,
      refInFor: true,
      attrs: {
        "prop": `controlDataList.${idx}.status`,
        "rules": {
          required: child.status != 'NONE',
          message: 'please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-select', {
      staticClass: "fluid-width",
      attrs: {
        "clearable": true
      },
      on: {
        "change": function (state) {
          return _vm.statusChange(state, idx);
        }
      },
      model: {
        value: child.status,
        callback: function ($$v) {
          _vm.$set(child, "status", $$v);
        },
        expression: "child.status"
      }
    }, _vm._l(_vm.engagementControlStatusList, function (alone) {
      return _c('el-option', {
        key: alone.title,
        attrs: {
          "label": alone.title,
          "value": alone.value
        }
      });
    }), 1)], 1)], 1), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('el-form-item', {
      ref: `controlDataList.${idx}.linkedServiceType`,
      refInFor: true,
      attrs: {
        "prop": `controlDataList.${idx}.linkedServiceType`,
        "rules": {
          required: child.status != 'NONE',
          message: 'please select',
          trigger: 'submit'
        }
      }
    }, [_c('el-select', {
      staticClass: "fluid-width",
      attrs: {
        "multiple": "",
        "filterable": ""
      },
      model: {
        value: child.linkedServiceType,
        callback: function ($$v) {
          _vm.$set(child, "linkedServiceType", $$v);
        },
        expression: "child.linkedServiceType"
      }
    }, _vm._l(_vm.serviceTypeList, function (single) {
      return _c('el-option', {
        key: `controlDataList${idx}${single.value}`,
        attrs: {
          "label": single.title,
          "value": single.value
        }
      });
    }), 1)], 1)], 1), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, _vm._l(child.closureChecklist, function (uniterm, xv) {
      return _c('p', {
        key: `controlDataList${idx}${xv}`,
        staticClass: "fs12 wbbw text-center"
      }, [_vm._v(" " + _vm._s(uniterm.title) + " "), _c('span', [_vm._v(_vm._s(_vm.completedCheckFormat(uniterm.value)))])]);
    }), 0), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('el-input', {
      staticClass: "fluid-width",
      attrs: {
        "type": "textarea",
        "autosize": {
          minRows: 2
        },
        "resize": "none",
        "placeholder": "please enter"
      },
      model: {
        value: child.remark,
        callback: function ($$v) {
          _vm.$set(child, "remark", $$v);
        },
        expression: "child.remark"
      }
    })], 1)], 1)], 1);
  })], 2)])], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")])], 1)], 1), _c('completed-check', {
    ref: "completedCheckModal",
    on: {
      "completedCheckFeedback": _vm.completedCheckFeedback
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }