<template>
  <el-dialog width="650px" :title="!dataForm.id ? 'Add Client code' : 'Edit Client code'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="110px">
      <el-form-item label="Client code" prop="clientCode" class="is-required">
        <el-input
          v-model="dataForm.clientCode"
          oninput="value=value.replace(/[^a-zA-Z0-9]/g, '')"
          placeholder="Client code (Please enter the number)"
          maxlength="30"
          show-word-limit
        >
          <template slot="prepend">c</template>
        </el-input>
      </el-form-item>
      <el-form-item label="UEN" prop="uens">
        <el-select
          v-model="dataForm.uens"
          multiple
          placeholder="UEN"
          filterable
          remote
          :remote-method="getUenRemote"
          @clear="getUenRemote('')"
          @focus="getUenRemote('')"
          :loading="uenLoading"
          value-key="uen"
          class="fluid-width"
        >
          <el-option v-for="(item, index) in uenList" :key="`${item.uen}${index}`" :label="item.uen" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Country" prop="country">
        <el-select style="width: 100%" v-model="dataForm.country" filterable placeholder="Select Country">
          <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Contact Info" prop="contact">
        <el-input v-model="dataForm.contact" type="textarea" :rows="5" :autosize="{ minRows: 4 }" resize="none" placeholder="Contact Info"></el-input>
      </el-form-item>
      <el-form-item label="Introducer" prop="introducer">
        <el-input v-model="dataForm.introducer" type="textarea" :rows="5" :autosize="{ minRows: 4 }" resize="none" placeholder="Introducer"></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="remark">
        <el-input v-model="dataForm.remark" type="textarea" :rows="5" :autosize="{ minRows: 4 }" resize="none" placeholder="Description"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" v-if="isAuth('sys:client:update')" @click="dataFormSubmit">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        clientCode: "",
        uens: [],
        country: "",
        contact: "",
        introducer: "",
        remark: "",
      },
      dataRule: {
        clientCode: [{ required: true, message: "Client code is required", trigger: "submit" }],
      },
      submitControl: true,
      uenList: [],
      uenLoading: false,
    };
  },
  computed: {
    ...mapState("constant", ["countryList"]),
  },
  methods: {
    async init(data) {
      this.visible = true;
      this.submitControl = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.dataForm = {
        id: data ? data.id : 0,
        clientCode: data ? data.clientCode.slice(1) : "",
        uens: data ? data.uens : [],
        country: data ? data.country : "",
        contact: data ? data.contact : "",
        introducer: data ? data.introducer : "",
        remark: data ? data.remark : "",
      };
      await this.getUenRemote("");
      // 解决远程搜索无法回显的bug
      const uenNewArr = [];
      this.dataForm.uens.forEach(ele => {
        const uenHas = this.uenList.some(item => ele.uen == item.uen);
        if (!uenHas) {
          uenNewArr.push(ele);
        }
      });
      this.uenList = this.uenList.concat(uenNewArr);
    },
    // 获取uen列表
    async getUenRemote(query) {
      this.uenLoading = true;
      const { code, data } = await this.$http.get("/admin/uenMa/getList", {
        params: {
          uen: query,
        },
      });
      this.uenLoading = false;
      if (code === 200) {
        this.uenList = data.filter(ele => ele != null && ele.uen != null);
        // 首位添加N/A
        const na = "N/A";
        if (na.includes(query)) {
          this.uenList.unshift({
            companyName: "N/A",
            transactionNo: "N/A",
            uen: "N/A",
          });
        }
      } else {
        this.uenList = [];
      }
    },
    // 表单提交
    async formSubmitRequest(dataForm) {
      if (dataForm.id) {
        return await this.$http.post(`/admin/clientCodeInfoManage/${this.dataForm.id}/update`, {
          clientCode: `c${dataForm.clientCode}`,
          uens: dataForm.uens,
          country: dataForm.country,
          contact: dataForm.contact,
          introducer: dataForm.introducer,
          remark: dataForm.remark,
        });
      } else {
        return await this.$http.post("/admin/clientCodeInfoManage/create", {
          clientCode: `c${dataForm.clientCode}`,
          uens: dataForm.uens,
          country: dataForm.country,
          contact: dataForm.contact,
          introducer: dataForm.introducer,
          remark: dataForm.remark,
        });
      }
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.formSubmitRequest(this.dataForm);
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.submitControl = true;
                this.$emit("refreshDataList");
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
