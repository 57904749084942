<template>
  <el-popover title="Feedback" v-model="visible" popper-class="popover-modal" width="400" trigger="click" @show="init">
    <el-form :model="dataForm" ref="dataForm">
      <el-form-item prop="remark">
        <el-input
          resize="none"
          type="textarea"
          rows="4"
          v-model="dataForm.remark"
          placeholder="If there is no problem, please leave it blank"
          :disabled="
            (detail.applymentStatus === 'CHECKING' ||
              detail.applymentStatus === 'ILLEGAL' ||
              detail.applymentStatus === 'REJECTED' ||
              detail.applymentStatus === 'CORRECTED' ||
              detail.applymentStatus === 'REVISING') &&
            (isApplymentAuth('company:coordinate') || isApplymentAuth('company:check'))
              ? false
              : true
          "
        ></el-input>
      </el-form-item>
      <div
        class="footer"
        v-if="
          (detail.applymentStatus === 'CHECKING' ||
            detail.applymentStatus === 'ILLEGAL' ||
            detail.applymentStatus === 'REJECTED' ||
            detail.applymentStatus === 'CORRECTED' ||
            detail.applymentStatus === 'REVISING') &&
          (isApplymentAuth('company:coordinate') || isApplymentAuth('company:check'))
        "
      >
        <el-button size="mini" type="primary" @click="dataFormSubmit">OK</el-button>
        <el-button size="mini" @click="closePopover">Cancel</el-button>
      </div>
    </el-form>
    <template slot="reference">
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    detail: {
      type: Object,
      default: function () {
        return {
          applymentStatus: "",
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        remark: "",
      },
    };
  },
  methods: {
    init() {
      this.dataForm = { ...this.dataForm, ...this.data };
      console.log(this.detail);
    },
    closePopover() {
      this.visible = false;
    },
    async dataFormSubmit() {
      this.$emit("submit", { ...this.dataForm, status: this.dataForm.remark ? 1 : 0 });
    },
  },
};
</script>
