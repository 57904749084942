<template>
  <div>
    <div class="desp-items-group" v-if="passportIcMerge.length > 0">
      <div class="desp-items-group-title"></div>
      <div class="desp-items-group-header">
        <div class="title">Passport / IC of Individual Shareholder(s) and Director(s) with Individual's Signature</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="shareholder-files">
            <div class="shareholder-file-wrapper" v-for="(item, idx) in passportIcMerge" :key="idx">
              <template v-if="item.fileUrls && item.fileUrls.length > 0">
                <div class="member-info">
                  <span class="name">{{ item.fullName }}</span>
                  <span class="held">{{ positionHeldFormat(item.positionHeld) }}</span>
                </div>
                <div class="file-urls">
                  <company-file-list :fileUrls="item.fileUrls" :detail="detail" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="desp-items-group" v-if="companyMemberFiles && companyMemberFiles.addressFiles">
      <div class="desp-items-group-title"></div>
      <div class="desp-items-group-header">
        <div class="title">Proof of Resident/Company Address – Scanned Copy</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="shareholder-files">
            <div class="shareholder-file-wrapper" v-for="(item, idx) in companyMemberFiles.addressFiles" :key="idx">
              <template v-if="item.fileUrls && item.fileUrls.length > 0">
                <div class="member-info">
                  <span class="name">{{ item.fullName }}</span>
                  <span class="held">{{ positionHeldFormat(item.positionHeld) }}</span>
                </div>
                <div class="file-urls">
                  <company-file-list :fileUrls="item.fileUrls" :detail="detail" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="desp-items-group" v-if="companyMemberFiles && companyMemberFiles.businessLicenseFiles">
      <div class="desp-items-group-title"></div>
      <div class="desp-items-group-header">
        <div class="title">Corporate Shareholder's Business Certificate & Articles of Association – Scanned Copy</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <div class="shareholder-files">
            <div class="shareholder-file-wrapper" v-for="(item, idx) in companyMemberFiles.businessLicenseFiles" :key="idx">
              <template v-if="item.fileUrls && item.fileUrls.length > 0">
                <div class="member-info">
                  <span class="name">{{ item.fullName }}</span>
                  <span class="held">{{ positionHeldFormat(item.positionHeld) }}</span>
                </div>
                <div class="file-urls">
                  <company-file-list :fileUrls="item.fileUrls" :detail="detail" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="desp-items-group" v-if="detail.transferBusinessProfileAndArticleUrls && detail.transferBusinessProfileAndArticleUrls.length > 0">
      <div class="desp-items-group-header">
        <div class="title">Business Profile & Articles of Association of the Proposed Company</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <company-file-list :fileUrls="detail.transferBusinessProfileAndArticleUrls" :detail="detail" />
        </div>
      </div>
    </div>

    <div class="desp-items-group" v-if="detail.transferBusinessActivityDocUrls && detail.transferBusinessActivityDocUrls.length > 0">
      <div class="desp-items-group-header">
        <div class="title">Business Activities of the Proposed Company</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <company-file-list :fileUrls="detail.transferBusinessActivityDocUrls" :detail="detail" />
        </div>
      </div>
    </div>

    <div class="desp-items-group" v-if="detail.transferShareholdingStructureUrls && detail.transferShareholdingStructureUrls.length > 0">
      <div class="desp-items-group-header">
        <div class="title">Shareholder Structure of the Proposed Company</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <company-file-list :fileUrls="detail.transferShareholdingStructureUrls" :detail="detail" />
        </div>
      </div>
    </div>

    <div class="desp-items-group" v-if="detail.transferAnnualFinancialReportUrls && detail.transferAnnualFinancialReportUrls.length > 0">
      <div class="desp-items-group-title"></div>
      <div class="desp-items-group-header">
        <div class="title">Latest Annual Financial Report of the Proposed Company (if applicable)</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <company-file-list :fileUrls="detail.transferAnnualFinancialReportUrls" :detail="detail" />
        </div>
      </div>
    </div>

    <div
      class="desp-items-group"
      v-if="detail.transferRegisteredOfficeAddressCertificateUrls && detail.transferRegisteredOfficeAddressCertificateUrls.length > 0"
    >
      <div class="desp-items-group-title"></div>
      <div class="desp-items-group-header">
        <div class="title">Proof of Registered Office Address of the Proposed Company – Scanned Copy</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <company-file-list :fileUrls="detail.transferRegisteredOfficeAddressCertificateUrls" :detail="detail" />
        </div>
      </div>
    </div>

    <div class="desp-items-group" v-if="detail.transferSecretaryParticularsUrls && detail.transferSecretaryParticularsUrls.length > 0">
      <div class="desp-items-group-title"></div>
      <div class="desp-items-group-header">
        <div class="title">Particulars of the Current Company Secretary</div>
      </div>
      <div class="desp-item">
        <div class="desp-item-self">
          <company-file-list :fileUrls="detail.transferSecretaryParticularsUrls" :detail="detail" />
        </div>
      </div>
    </div>

    <template v-if="detail.applicantPosition === 'INDIRECT_SHAREHOLDER' || detail.applicantPosition === 'OTHER'">
      <div
        class="desp-items-group"
        v-if="
          (detail.authorizationUrls && detail.authorizationUrls.length > 0) ||
          (detail.applicantIdentificationUrls && detail.applicantIdentificationUrls.length > 0)
        "
      >
        <div class="desp-items-group-header">
          <div class="title">Authorization of applicant – Scanned copy</div>
        </div>
        <div class="desp-item">
          <div class="desp-item-self" v-if="detail.applicantPosition === 'OTHER' && detail.authorizationUrls && detail.authorizationUrls.length > 0">
            <company-file-list :fileUrls="detail.authorizationUrls" :detail="detail" />
          </div>
          <div
            class="desp-item-self"
            v-if="
              (detail.applicantPosition === 'INDIRECT_SHAREHOLDER' || detail.applicantPosition === 'OTHER') &&
              detail.applicantIdentificationUrls &&
              detail.applicantIdentificationUrls.length > 0
            "
          >
            <company-file-list :fileUrls="detail.applicantIdentificationUrls" :detail="detail" />
          </div>
        </div>
      </div>
    </template>

    <!-- 最终受益人的身份证件/营业执照和地址证明 -->
    <template v-if="detail.questionnaire && detail.questionnaire.ultimateBeneficiaryInfo && detail.questionnaire.ultimateBeneficiaryInfo.length > 0">
      <div class="desp-items-group" v-if="isUltimateBeneficiaryShow">
        <div class="desp-items-group-title"></div>
        <div class="desp-items-group-header">
          <div class="title">
            Ultimate Beneficial Owner(s)' ID/Business Certificate and Proof of Resident/Company Address (lf the requested documents were uploaded elsewhere
            on this page, please upload a text file named "N/A" instead)
          </div>
        </div>
        <div class="desp-item">
          <div class="desp-item-self">
            <div class="shareholder-files">
              <div
                class="shareholder-file-wrapper"
                v-for="(item, idx) in detail.questionnaire.ultimateBeneficiaryInfo"
                :key="`ultimateBeneficiaryInfo${idx}`"
              >
                <template v-if="item.ultimateBeneficiaryBusinessLicenseUrls && item.ultimateBeneficiaryBusinessLicenseUrls.length > 0">
                  <div class="member-info">
                    <span class="name">{{ item.fullName }}</span>
                    <span class="held">{{ memberTypeFormat(item.memberType) }}</span>
                  </div>
                  <div class="file-urls" v-if="item.ultimateBeneficiaryBusinessLicenseUrls">
                    <company-file-list :fileUrls="item.ultimateBeneficiaryBusinessLicenseUrls" :detail="detail" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CompanyFileList from "./FileList";
export default {
  components: { CompanyFileList },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          applymentStatus: "",
          transferBusinessProfileAndArticleUrls: [],
          transferBusinessActivityDocUrls: [],
          transferShareholdingStructureUrls: [], // 拟转移公司的股权架构图
          transferAnnualFinancialReportUrls: [], // 拟转移公司的最新的年度财务报告(若适用)
          transferRegisteredOfficeAddressCertificateUrls: [], // 拟转移公司的注册地址证明原件扫描件
          transferSecretaryParticularsUrls: [], // 拟转移公司的现任公司秘书的信息
          applicantIdentificationUrls: [],
        };
      },
    },
  },
  data() {
    return {
      dataListLoading: false,
    };
  },
  computed: {
    ...mapState("constant", ["positionHelds", "companyMemberTypes"]),
    ...mapGetters("applyment", ["companyMemberFiles"]),
    passportIcMerge() {
      let identificationFilesArr =
        this.companyMemberFiles && this.companyMemberFiles.identificationFiles ? this.companyMemberFiles.identificationFiles : [];
      let holdingIdentificationFilesArr =
        this.companyMemberFiles && this.companyMemberFiles.holdingIdentificationFiles ? this.companyMemberFiles.holdingIdentificationFiles : [];
      let mergeList = [];
      for (let i in identificationFilesArr) {
        for (let j in holdingIdentificationFilesArr) {
          if (identificationFilesArr[i].id == holdingIdentificationFilesArr[j].id) {
            mergeList.push({
              positionHeld: identificationFilesArr[i].positionHeld,
              fullName: identificationFilesArr[i].fullName,
              fileUrls: identificationFilesArr[i].fileUrls.concat(holdingIdentificationFilesArr[j].fileUrls),
            });
          }
        }
      }
      return mergeList;
    },
    // 判断“最终受益人的身份证件/营业执照和地址证明”是否需要显示
    isUltimateBeneficiaryShow() {
      let isShow = false;
      const ultimateBeneficiaryInfo = this.detail?.questionnaire?.ultimateBeneficiaryInfo || [];
      for (let i in ultimateBeneficiaryInfo) {
        const atItem = ultimateBeneficiaryInfo[i];
        if (atItem.ultimateBeneficiaryBusinessLicenseUrls && atItem.ultimateBeneficiaryBusinessLicenseUrls.length > 0) {
          // 如果存在ultimateBeneficiaryBusinessLicenseUrls，就显示
          isShow = true;
          break;
        }
      }
      return isShow;
    },
  },
  methods: {
    positionHeldFormat(positionHeld) {
      const one = this.positionHelds.find(i => i.value === positionHeld);
      return one?.title;
    },
    memberTypeFormat(memberType) {
      const one = this.companyMemberTypes.find(i => i.value === memberType);
      return one?.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.company-materials-module {
  .files-wrapper {
    width: 100%;
  }

  .company-files-wrapper {
    .el-col {
      margin-bottom: 20px;
    }
  }

  .shareholder-file-wrapper {
    .member-info {
      color: #353744;
      font-weight: normal;
      // margin-bottom: 15px;

      .name {
        width: fit-content;
        font-size: 16px;
        line-height: 16px;
        margin-right: 10px;
      }
      .held {
        font-size: 13px;
      }
    }
  }

  .desp-items-group-header {
    .title {
      padding: 10px 0;
    }
  }
}
</style>
