var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('p', {
    staticClass: "fs18 fwb pt0 mt0 mb30"
  }, [_vm._v("Personal Settings")]), _c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "102px"
    }
  }, [_c('el-form-item', {
    staticClass: "wd550",
    attrs: {
      "label": "Nick Name",
      "prop": "nickname"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Nick name",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "nickname", $$v);
      },
      expression: "dataForm.nickname"
    }
  })], 1), _c('el-form-item', {
    staticClass: "wd550",
    attrs: {
      "label": "Phone",
      "prop": "phone"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Phone"
    },
    model: {
      value: _vm.dataForm.phone,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "phone", $$v);
      },
      expression: "dataForm.phone"
    }
  }, [_c('el-select', {
    staticClass: "wd90",
    attrs: {
      "slot": "prepend"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.prefix,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "prefix", $$v);
      },
      expression: "dataForm.prefix"
    }
  }, _vm._l(_vm.prefixOptions, function (single) {
    return _c('el-option', {
      key: single.title,
      attrs: {
        "value": single.value
      }
    }, [_vm._v(" " + _vm._s(single.value + " " + single.title) + " ")]);
  }), 1)], 1)], 1), _c('el-form-item', {
    staticClass: "wd550",
    attrs: {
      "label": "Email"
    }
  }, [_c('span', {
    staticClass: "ml10 mr12"
  }, [_vm._v(_vm._s(_vm.dataForm.email))])]), _c('el-form-item', {
    staticClass: "wd550",
    attrs: {
      "label": "Password"
    }
  }, [_c('span', {
    staticClass: "ml10 mr12"
  }, [_vm._v("********")]), _c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-thumb"
    },
    on: {
      "click": _vm.sendResetPwdEmail
    }
  }, [_vm._v("Reset Password")]), _vm.sendEmailType == 1 ? _c('span', {
    staticClass: "ml20 green"
  }, [_c('i', {
    staticClass: "el-icon-success"
  }), _vm._v(" Message sent successfully ")]) : _vm._e(), _vm.sendEmailType == 0 ? _c('span', {
    staticClass: "ml20 red"
  }, [_c('i', {
    staticClass: "el-icon-error"
  }), _vm._v(" Message sending failed ")]) : _vm._e()], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }