<template>
  <el-row class="company-files-wrapper" :gutter="40">
    <el-col :span="6" v-for="(file, idx) in fileUrls" :key="idx">
      <div class="list-item">
        <div class="item-wrapper">
          <div class="body">
            <div v-if="isImage(file)" class="image-wrapper">
              <el-image class="img" :src="file.url" :preview-src-list="[file.url]" fit="contain"></el-image>
            </div>
            <div v-else class="file">
              <div class="file-type">
                <img src="@/assets/img/file/txt.png" alt="" width="40" v-if="file.url.toLowerCase().includes('.txt')" />
                <img src="@/assets/img/file/pdf.png" alt="" v-if="file.url.toLowerCase().includes('.pdf')" />
                <img src="@/assets/img/file/ppt.png" alt="" v-if="file.url.toLowerCase().includes('.ppt') || file.url.toLowerCase().includes('.pptx')" />
                <img src="@/assets/img/file/doc.png" alt="" v-if="file.url.toLowerCase().includes('.doc') || file.url.toLowerCase().includes('.docx')" />
              </div>
              <a :href="file.url" target="_blank" class="file-name">{{ file.name }}</a>
            </div>
          </div>
          <ul class="actions">
            <li v-if="file.status && file.status > 0">
              <span class="el-icon-error t"></span>
              <span class="red-status ml10">{{ statusFormat(file.status) }}</span>
            </li>
            <li>
              <remark-modal ref="remarkModal" :data="file" :detail="detail" @submit="file => handleSubmit(idx, file)">
                <!-- <el-button type="text"> -->
                <div>
                  <svg-icon name="feedback" class="opt-icon"></svg-icon>
                  <svg-icon name="feedbackActive" class="opt-icon-active"></svg-icon>
                  <span class="feedback-color ml10">Feedback</span>
                </div>
                <!-- </el-button> -->
              </remark-modal>
            </li>
          </ul>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions } from "vuex";
import RemarkModal from "./modal/remark.vue";
export default {
  components: {
    RemarkModal,
  },
  props: {
    fileUrls: {
      type: Array,
      default: function () {
        return [
          {
            status: 1,
            name: "",
            url: "",
          },
        ];
      },
    },
    detail: {
      type: Object,
      default: function () {
        return {
          applymentStatus: "",
        };
      },
    },
  },
  methods: {
    ...mapActions("applyment", ["doUpdateFiles"]),
    isImage(file) {
      const allowedExtension = ["jpeg", "jpg", "png", "gif", "bmp"];
      const dotIndex = file.url.lastIndexOf(".");
      const ext = file.url.substring(dotIndex + 1);
      return allowedExtension.includes(ext);
    },
    statusFormat(v) {
      if (v) {
        if (v === 1) {
          return "Wrong";
        } else if (v === 2) {
          return "Corrected";
        }
      }
    },
    handleSubmit(idx, file) {
      console.log(idx);
      console.log(file);
      this.doUpdateFiles(file)
        .then(() => {
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.$refs["remarkModal"][idx].closePopover();
            },
          });
        })
        .catch(msg => {
          console.log(msg);
          this.$message.error(msg);
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/variables.scss";

.company-files-wrapper {
  padding-left: 20px;
  padding-right: 20px;

  .list-item {
    margin-top: 10px;
    display: block;
    max-width: 100%;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .item-wrapper:hover {
      .actions {
        background-color: #c8daff;
      }

      .opt-icon {
        display: none;
      }

      .opt-icon-active {
        display: inline-block;
      }
    }

    .item-wrapper {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: "tnum", "tnum";
      position: relative;
      background: #fff;

      .file {
        height: 172px;
        .file-type {
          text-align: center;
          padding-bottom: 10px;
        }
        .file-name {
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
    .body {
      padding: 24px;
      background: #f9fafd;
      border: 2px dashed #e9ecf1;
      border-bottom: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      .image-wrapper {
        font-size: 0;
        .img {
          height: 172px;
          width: 100%;
        }
      }
    }
    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      background: #e4e5e8;
      outline: none;
      list-style: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      .opt-icon {
        font-size: 16px;
        vertical-align: middle;
      }

      .opt-icon-active {
        display: none;
        font-size: 16px;
        vertical-align: middle;
      }

      li + li {
        border-left: 1px solid #ccced2;
      }

      li {
        flex: 1;
        margin: 6px 0;
        text-align: center;
        cursor: pointer;
        height: 22px;

        .t {
          // position: relative;
          // display: block;
          // min-width: 32px;
          font-size: 16px;
          line-height: 1em;
          cursor: default;
          color: $--color-danger;

          a {
            cursor: pointer;
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #2c2c2c;
            line-height: 22px;
            transition: color 0.3s;
          }
        }

        .red-status {
          color: $--color-danger;
        }

        .feedback-color {
          color: #011a45;
        }
      }
    }
  }
}
</style>
