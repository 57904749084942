<template>
  <el-dialog width="600px" title="Config" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-position="left" label-width="110px">
      <el-form-item
        v-for="(item, index) in dataForm.employersList"
        :label="`Employer(${index + 1})`"
        :key="`Employer${index}`"
        :prop="'employersList.' + index"
        :rules="{
          required: true,
          message: 'Please Enter',
          trigger: 'submit',
        }"
      >
        <div class="flex align-center">
          <el-input v-model="dataForm.employersList[index]" :oninput="isEn" class="mr20 wd350"></el-input>
          <span class="fs22 cursor-pointer blue el-icon-circle-plus-outline" @click="dataForm.employersList.splice(index + 1, 0, '')"></span>
          <span class="fs22 ml12 cursor-pointer red el-icon-remove-outline" @click="dataForm.employersList.splice(index, 1)" v-if="index > 0"></span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        employersList: [""],
      },
      submitControl: true,
    };
  },
  methods: {
    async init(list) {
      if (list.length == 0) {
        this.$set(this.dataForm, "employersList", [""]);
      } else {
        this.$set(this.dataForm, "employersList", list);
      }
      this.visible = true;
      this.submitControl = true;
    },
    cancel() {
      this.visible = false;
    },
    async dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.$http.post("/admin/employers/save", {
            employersList: this.dataForm.employersList,
          });
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.submitControl = true;
                this.$emit("getEmployerList");
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.blue {
  color: $--color-primary;
}

.red {
  color: $--color-danger;
}
</style>
