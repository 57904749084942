<template>
  <div>
    <el-row :gutter="26">
      <el-col :span="12">
        <div class="pd26 bg-white border-radius-10">
          <h3 class="home-title">[Finance] Processing Time</h3>
          <h5 class="mb20 hg40 lh16 home-subhead">(Order Commit -> Receipt Check)</h5>
          <div class="flex mb40" v-for="item in businessTypes" :key="'toCompleteTime' + item.title">
            <div class="flex justify-center flex-direction align-start">
              <img src="@/assets/img/home/toComplete.png" alt="" />
            </div>
            <div class="ml25 flex justify-center flex-direction align-start">
              <h3 class="home-title">
                {{ toCompleteTime[item.value] }}
                <span class="fs16" v-if="toCompleteTime[item.value] != ''">Days</span>
              </h3>
              <p class="mt8 fs14 lh16 text-bold home-introduce aurantia">{{ item.title }}</p>
              <p class="mt6 fs14 lh16 home-introduce">Average over preceding 3 months</p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="pd26 bg-white border-radius-10">
          <h3 class="home-title">[Operation] Processing Time</h3>
          <h5 class="mb20 hg40 lh16 home-subhead">(Order Submit (i.e. Receipt Confirmed)-> Order Complete)</h5>
          <div class="flex mb40" v-for="item in businessTypes" :key="'toFinishTime' + item.title">
            <div class="flex justify-center flex-direction align-start">
              <img src="@/assets/img/home/toFinish.png" alt="" />
            </div>
            <div class="ml25 flex justify-center flex-direction align-start">
              <h3 class="home-title">
                {{ toFinishTime[item.value] }}
                <span class="fs16" v-if="toFinishTime[item.value] != ''">Days</span>
              </h3>
              <p class="mt8 fs14 lh16 text-bold home-introduce aurantia">{{ item.title }}</p>
              <p class="mt6 fs14 lh16 home-introduce">Average over preceding 3 months</p>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { toFixedDecimal, get3MonthBefor, getNowFormatDate } from "@/utils";
import { mapState } from "vuex";
export default {
  data() {
    return {
      toCompleteTime: {
        COMPANY_INCORPORATION: "",
        SERVICE_TRANSFER: "",
      },
      toFinishTime: {
        COMPANY_INCORPORATION: "",
        SERVICE_TRANSFER: "",
      },
    };
  },
  computed: {
    ...mapState("constant", ["businessTypes"]),
  },
  methods: {
    // 处理时间－收款确认的耗时
    getOrderComplete(type) {
      this.$http
        .get("/admin/statistics/getTimeFromCommitToOrderComplete", {
          params: {
            startTime: get3MonthBefor(),
            endTime: getNowFormatDate(),
            businessType: type,
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            const toCompleteTime = data && data.consumingTime ? toFixedDecimal(parseFloat(data.consumingTime) / 60 / 60 / 24) : "";
            this.$set(this.toCompleteTime, type, toCompleteTime);
          } else {
            this.$message.error(msg);
          }
        });
    },
    // 收款确认－交易完成的耗时
    getOrderCompleteToFinish(type) {
      this.$http
        .get("/admin/statistics/getTimeFromOrderCompleteToFinish", {
          params: {
            startTime: get3MonthBefor(),
            endTime: getNowFormatDate(),
            businessType: type,
          },
        })
        .then(({ code, data, msg }) => {
          if (code === 200) {
            const toFinishTime = data && data.consumingTime ? toFixedDecimal(parseFloat(data.consumingTime) / 60 / 60 / 24) : "";
            this.$set(this.toFinishTime, type, toFinishTime);
          } else {
            this.$message.error(msg);
          }
        });
    },
  },
  activated() {
    this.businessTypes.forEach(item => {
      // 处理时间－收款确认的耗时
      this.getOrderComplete(item.value);
      // 收款确认－交易完成的耗时
      this.getOrderCompleteToFinish(item.value);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.home-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  color: $form-item-font-color;
}

.home-subhead {
  margin: 0;
  padding: 0;
  margin-top: 6px;
  font-size: 14px;
  font-weight: bold;
  color: $form-item-font-color;
  line-height: 20px;
}

.home-introduce {
  font-size: 16px;
  color: #7987a0;
  padding: 0;
  margin: 0;
  line-height: 22px;
}

.home-proportion {
  font-size: 20px;
  font-weight: 500;
  color: $form-item-font-color;
  margin: 0;
  padding: 0;
  span {
    color: #939db0;
  }
}

.aurantia {
  color: #ff7e69 !important;
}

.cyan {
  color: #52d4ff !important;
}
</style>
