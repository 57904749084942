var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1000px",
      "title": "Config",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "label-position": "left",
      "label-width": "168px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "custom plan",
      "prop": "isCustomOpen"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.isCustomOpen,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "isCustomOpen", $$v);
      },
      expression: "dataForm.isCustomOpen"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c('el-radio', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Exchange Rate",
      "prop": "forexRate"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('span', [_vm._v(" Exchange Rate "), _c('el-popover', {
    attrs: {
      "placement": "bottom-start",
      "width": "220",
      "trigger": "hover",
      "content": "SGD => CNY"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1)]), _c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "min": 0,
      "step": 0.01,
      "label": "FX Rate"
    },
    model: {
      value: _vm.dataForm.forexRate,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "forexRate", $$v);
      },
      expression: "dataForm.forexRate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Business Type",
      "prop": "businessType"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width",
    attrs: {
      "placeholder": "Select businessType"
    },
    on: {
      "change": _vm.businessTypeChange
    },
    model: {
      value: _vm.dataForm.businessSpecificationObj.businessType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.businessSpecificationObj, "businessType", $$v);
      },
      expression: "dataForm.businessSpecificationObj.businessType"
    }
  }, _vm._l(_vm.businessTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Business Specification",
      "prop": "specification"
    }
  }, [_c('el-editor', {
    attrs: {
      "height": 500
    },
    model: {
      value: _vm.dataForm.businessSpecificationObj.specification,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.businessSpecificationObj, "specification", $$v);
      },
      expression: "dataForm.businessSpecificationObj.specification"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Business Specification(CN)",
      "prop": "specificationCn"
    }
  }, [_c('el-editor', {
    attrs: {
      "height": 500
    },
    model: {
      value: _vm.dataForm.businessSpecificationObj.specificationCn,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm.businessSpecificationObj, "specificationCn", $$v);
      },
      expression: "dataForm.businessSpecificationObj.specificationCn"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.closePopover
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }