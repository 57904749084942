var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt30 pd26 hg308 bg-white border-radius-10"
  }, [_vm._m(0), _c('el-row', {
    staticClass: "mt30"
  }, [_c('el-col', {
    staticClass: "hg198 flex justify-center align-center",
    attrs: {
      "span": 14
    }
  }, [_c('div', {
    staticClass: "ratio-wrap"
  }, [_c('el-progress', {
    staticClass: "ratio-pass",
    attrs: {
      "type": "circle",
      "percentage": parseFloat(_vm.auditorObj.passCnt) && parseFloat(_vm.auditorObj.allCnt) ? parseFloat(_vm.toFixedDecimal(_vm.div(_vm.auditorObj.passCnt, _vm.auditorObj.allCnt) * 100)) : 0,
      "color": "#FF7E69",
      "stroke-width": 14,
      "width": 170,
      "show-text": false
    }
  }), _c('el-progress', {
    staticClass: "ratio-reject",
    attrs: {
      "type": "circle",
      "percentage": parseFloat(_vm.auditorObj.rejectCnt) && parseFloat(_vm.auditorObj.allCnt) ? parseFloat(_vm.toFixedDecimal(_vm.div(_vm.auditorObj.rejectCnt, _vm.auditorObj.allCnt) * 100)) : 0,
      "color": "#0072FF",
      "stroke-width": 14,
      "width": 110,
      "show-text": false
    }
  })], 1)]), _c('el-col', {
    staticClass: "hg198 flex justify-center flex-direction align-start",
    attrs: {
      "span": 10
    }
  }, [_c('p', {
    staticClass: "dot-all"
  }, [_vm._v("Total: " + _vm._s(_vm.auditorObj.allCnt) + " [100%]")]), _c('p', {
    staticClass: "dot-pass"
  }, [_vm._v(" Pass: " + _vm._s(_vm.auditorObj.passCnt) + " [" + _vm._s(parseFloat(_vm.auditorObj.passCnt) && parseFloat(_vm.auditorObj.allCnt) ? _vm.toFixedDecimal(_vm.div(_vm.auditorObj.passCnt, _vm.auditorObj.allCnt) * 100) + "%" : "0.00%") + "] ")]), _c('p', {
    staticClass: "dot-reject"
  }, [_vm._v(" Reject: " + _vm._s(_vm.auditorObj.rejectCnt) + " [" + _vm._s(parseFloat(_vm.auditorObj.rejectCnt) && parseFloat(_vm.auditorObj.allCnt) ? _vm.toFixedDecimal(_vm.div(_vm.auditorObj.rejectCnt, _vm.auditorObj.allCnt) * 100) + "%" : "0.00%") + "] ")])])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-direction-row align-center justify-between"
  }, [_c('h3', {
    staticClass: "home-title"
  }, [_vm._v("Reviewer")]), _c('p', {
    staticClass: "home-introduce"
  }, [_vm._v("Average over preceding 3 months")])]);

}]

export { render, staticRenderFns }