<template>
  <el-dialog :title="type == 'Finish' ? 'Batch Finish Review' : 'Batch Refund Review'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-width="60px">
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status" v-if="type == 'Finish'">
          <el-radio label="AUDIT_FAIL">Rejected</el-radio>
          <el-radio label="AUDIT_SUCCESS">Pass</el-radio>
        </el-radio-group>
        <el-radio-group v-model="dataForm.status" v-if="type == 'Refund'">
          <el-radio label="REFUND_APPLY_REJECT">Rejected</el-radio>
          <el-radio label="REFUND_APPLY_PASS">Pass</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        status: "",
      },
      applymentIdsList: [],
      dataRule: {},
      submitControl: true,
      type: "",
    };
  },
  computed: {
    ...mapState("constant", ["stepStatusList"]),
  },
  methods: {
    ...mapActions("applyment", ["doAuditBatch"]),
    async init(data, type) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.applymentIdsList = data;
      this.type = type;
      if (type == "Finish") {
        this.dataForm = {
          status: "AUDIT_SUCCESS",
        };
      } else {
        this.dataForm = {
          status: "REFUND_APPLY_PASS",
        };
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doAuditBatch({ applymentIds: this.applymentIdsList, applymentStatus: this.dataForm.status })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>