// 只能输入正负数字(包括0)或正负小数，小数只保留2位
export default {
  bind(el, binding, vnode) {
    el.keyupHandler = event => {
      const currentValue = event.target.value;
      let newValue = "";
      // 移除非数字字符、多余的小数点、多于的负号
      for (let i = 0; i < currentValue.length; i++) {
        if (currentValue[i].match(/[0-9.-]/)) {
          if (currentValue[i] === ".") {
            if (newValue.indexOf(".") === -1) {
              newValue += ".";
            }
          } else if (currentValue[i] === "-") {
            // 负号只保留第一位
            if (i == 0) {
              newValue += "-";
            }
          } else {
            newValue += currentValue[i];
          }
        }
      }

      let startIdx = 0;
      const whipptreeIdx = newValue.indexOf("-");
      if (whipptreeIdx == 0) {
        startIdx = 1;
      }

      // 如果第一位(或第二位)是小数点，就设为空字符串
      const len1 = newValue.substr(0 + startIdx, 1);
      if (len1 === ".") {
        const list = Array.from(newValue);
        list.splice(startIdx, 1);
        newValue = list.join("");
      }

      // 如果第一位(或第二位)是0，第二位(或第三位)不是小数点，就用第二位(或第三位)的数字把第一位(或第二位)的0替换掉
      const len2 = newValue.substr(1 + startIdx, 1);
      if (newValue.length > 1 + startIdx && len1 == 0 && len2 != ".") {
        const list = Array.from(newValue);
        list.splice(startIdx, 1);
        newValue = list.join("");
      }

      // 如果小数点后面超过2位，就只保留2位
      const pointIndex = newValue.indexOf(".");
      if (pointIndex != -1) {
        newValue = newValue.substr(0, pointIndex + 3);
      }

      event.target.value = newValue;
      vnode.context.inputValue = newValue;

      // 更新v-model
      if (vnode.componentInstance) {
        // 如果是自定义组件就触发自定义组件的input事件
        vnode.componentInstance.$emit("input", event.target.value);
      } else {
        // 如果是原生组件就触发原生组件的input事件
        el.dispatchEvent(new Event("input"));
      }
    };

    // 添加监听键盘输入
    el.addEventListener("keyup", el.keyupHandler);
  },
  unbind(el) {
    // 解除键盘监听
    el.removeEventListener("keyup", el.keyupHandler);
  },
};
