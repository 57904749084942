var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "shareholders-module"
  }, [_c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.headline))]), _c('div', {
    staticClass: "opts"
  }, [(_vm.applymentStatus === 'REVISING' || _vm.applymentStatus === 'CORRECTED' || _vm.applymentStatus === 'ILLEGAL' || _vm.applymentStatus === 'AUDIT_FAIL' || _vm.applymentStatus === 'REFUND_APPLY_DENY' || _vm.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.applymentStatus === 'ACRA_REJECTED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.openEdit('company');
      }
    }
  }, [_vm._v(" Edit ")]) : _vm._e(), (_vm.applymentStatus === 'CHECKING' || _vm.applymentStatus === 'REVISE_FINISH') && _vm.isApplymentAuth('company:check') ? [_vm.isNeedNomineeDirector ? [_c('el-button', {
    attrs: {
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": _vm.addNomineeDirectorHandle
    }
  }, [_c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "exchange"
    }
  }), _vm._v(" Add Nominee Director ")], 1)] : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, _vm._l(_vm.companyMemberListFormat, function (detail, idx) {
    return _c('div', {
      key: detail.id,
      staticClass: "shareholder-wrap"
    }, [_c('div', {
      staticClass: "member-type"
    }, [_vm._v(" " + _vm._s(_vm.memberTypeFormat(detail.memberType)) + " "), _c('span', [_vm._v(_vm._s(idx + 1 + "/" + _vm.companyMemberListFormat.length))])]), _c('div', {
      staticClass: "position-held"
    }, [_vm._v(_vm._s(_vm.positionHeldFormat(detail.positionHeld)))]), _c('div', {
      staticClass: "base-info mt20"
    }, [_c('el-descriptions', {
      attrs: {
        "labelStyle": {
          color: '#667288',
          background: '#ECEFF9'
        },
        "contentStyle": {
          background: '#ECEFF9'
        },
        "column": 3,
        "direction": "vertical"
      }
    }, [detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Name (same as business certificate)"
      }
    }, [_vm._v(" " + _vm._s(detail.fullName) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Name (same as IC)"
      }
    }, [_vm._v(_vm._s(detail.fullName))]) : _vm._e(), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Country"
      }
    }, [_vm._v(_vm._s(detail.nationality))]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Nationality"
      }
    }, [_vm._v(_vm._s(detail.nationality))]) : _vm._e(), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Business No."
      }
    }, [_vm._v(" " + _vm._s(detail.businessLicenseNo) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Identification Type"
      }
    }, [_vm._v(" " + _vm._s(detail.identificationType) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Identification No."
      }
    }, [_vm._v(" " + _vm._s(detail.identificationNo) + " ")]) : _vm._e(), _c('el-descriptions-item', {
      attrs: {
        "label": "Mobile"
      }
    }, [_vm._v(_vm._s(detail.phone))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Email"
      }
    }, [_vm._v(_vm._s(detail.email))]), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Registered Address"
      }
    }, [_vm._v(" " + _vm._s(detail.registeredOfficeAddress) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Residential address"
      }
    }, [_vm._v(" " + _vm._s(detail.residentialAddress) + " ")]) : _vm._e(), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Operation Address"
      }
    }, [_vm._v(" " + _vm._s(detail.operationAddress) + " ")]) : _vm._e(), detail.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Incorporation Date"
      }
    }, [_vm._v(" " + _vm._s(detail.incorporationDate) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Birth Date"
      }
    }, [_vm._v(" " + _vm._s(detail.birthDate) + " ")]) : _vm._e(), detail.memberType === 'CORPORATE' || detail.memberType === 'INDIVIDUAL' && (detail.positionHeld === 'SHAREHOLDER' || detail.positionHeld === 'DIRECTOR_AND_SHAREHOLDER') ? _c('el-descriptions-item', {
      attrs: {
        "label": "Is the shareholder a Nominee Shareholder"
      }
    }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(null, null, detail.isNominatedShareholder)) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' && (detail.positionHeld === 'DIRECTOR' || detail.positionHeld === 'DIRECTOR_AND_SHAREHOLDER') ? _c('el-descriptions-item', {
      attrs: {
        "label": "Is the director a Nominee Director"
      }
    }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(null, null, detail.isNominatedDirector)) + " ")]) : _vm._e(), _vm.businessType === 'SERVICE_TRANSFER' && _vm.hasNomineeDirector && detail.memberType === 'INDIVIDUAL' && (detail.positionHeld === 'DIRECTOR' || detail.positionHeld === 'DIRECTOR_AND_SHAREHOLDER') ? _c('el-descriptions-item', {
      attrs: {
        "label": "Will the director tender resignation"
      }
    }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(null, null, detail.directorNeedResigned)) + " ")]) : _vm._e(), detail.memberType === 'INDIVIDUAL' && detail.positionHeld != 'NOMINEE_DIRECTOR' ? [_c('el-descriptions-item', {
      attrs: {
        "label": "Does the individual hold share interest of any entity in PRC inland?"
      }
    }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(null, null, detail.isHoldShareOfPrcInland)) + " ")]), detail.isHoldShareOfPrcInland ? _c('el-descriptions-item', {
      attrs: {
        "label": "Please provide the name of RPC entity (1 entity is required)"
      }
    }, [_vm._v(" " + _vm._s(detail.holdShareOfPrcEntity) + " ")]) : _vm._e()] : _vm._e()], 2)], 1), detail.memberType === 'CORPORATE' && detail.positionHeld === 'SHAREHOLDER' ? [detail.representativeInfo ? _c('div', {
      staticClass: "mt25 member-type"
    }, [_c('div', {
      staticClass: "position-held"
    }, [_vm._v("Representative")]), _c('div', {
      staticClass: "base-info mt20"
    }, [_c('el-descriptions', {
      attrs: {
        "labelStyle": {
          color: '#667288',
          background: '#ECEFF9'
        },
        "contentStyle": {
          background: '#ECEFF9'
        },
        "column": 3,
        "direction": "vertical"
      }
    }, [_c('el-descriptions-item', {
      attrs: {
        "label": "Name (same as IC)"
      }
    }, [_vm._v(" " + _vm._s(detail.representativeInfo.fullName) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Nationality"
      }
    }, [_vm._v(_vm._s(detail.representativeInfo.nationality))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Identification Type"
      }
    }, [_vm._v(" " + _vm._s(detail.representativeInfo.identificationType) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Identification No."
      }
    }, [_vm._v(" " + _vm._s(detail.representativeInfo.identificationNo) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Mobile"
      }
    }, [_vm._v(_vm._s(detail.representativeInfo.phone))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Email"
      }
    }, [_vm._v(_vm._s(detail.representativeInfo.email))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Residential address"
      }
    }, [_vm._v(" " + _vm._s(detail.representativeInfo.residentialAddress) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Birth Date"
      }
    }, [_vm._v(" " + _vm._s(detail.representativeInfo.birthDate) + " ")])], 1)], 1)]) : _vm._e()] : _vm._e(), detail.sharesInfo && detail.sharesInfo.length > 0 ? _c('div', {
      staticClass: "shares-info mt20"
    }, [_c('el-table', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "header-cell-style": {
          color: '#011A45'
        },
        "border": "",
        "data": detail.sharesInfo
      }
    }, [_c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "typeOfShares",
        "label": "Type Of Shares",
        "width": "180",
        "formatter": _vm.typeOfSharesFormat
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "quantityOfSharesAllotted",
        "label": "No of shares allotted"
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "amountOfPaidUpShareCapital",
        "label": "Amount Of Registered Share Capital"
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "amountOfPaidInShareCapital",
        "label": "Paid-in capital"
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "sharesHoldInTrust",
        "label": "Shares held in trust",
        "formatter": _vm.getBooleanFormat
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "nameOfTheTrust",
        "label": "Name of the trust"
      }
    })], 1)], 1) : _vm._e()], 2);
  }), 0)])])]), _vm.editVisible ? _c('edit-modal', {
    ref: "editModal",
    attrs: {
      "stepName": _vm.stepName,
      "applymentStatus": _vm.applymentStatus,
      "businessType": _vm.businessType,
      "hasNomineeDirector": _vm.hasNomineeDirector,
      "isNeedNomineeDirector": _vm.isNeedNomineeDirector
    }
  }) : _vm._e()], 1), _vm.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('div', {
    staticClass: "mt60 shareholders-module"
  }, [_c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Information of the proposed fund's officer(s)")]), _c('div', {
    staticClass: "opts"
  }, [(_vm.applymentStatus === 'REVISING' || _vm.applymentStatus === 'CORRECTED' || _vm.applymentStatus === 'ILLEGAL' || _vm.applymentStatus === 'AUDIT_FAIL' || _vm.applymentStatus === 'REFUND_APPLY_DENY' || _vm.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.applymentStatus === 'ACRA_REJECTED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.openEdit('fund');
      }
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, _vm._l(_vm.companyFundMemberList, function (fundItem, sy) {
    return _c('div', {
      key: `fundItem${fundItem.id}`,
      staticClass: "shareholder-wrap"
    }, [_c('div', {
      staticClass: "member-type"
    }, [_vm._v(" " + _vm._s(_vm.memberTypeFormat(fundItem.memberType)) + " "), _c('span', [_vm._v(_vm._s(sy + 1 + "/" + _vm.companyFundMemberList.length))])]), _c('div', {
      staticClass: "position-held"
    }, [_vm._v(_vm._s(_vm.positionHeldFormat(fundItem.positionHeld)))]), _c('div', {
      staticClass: "base-info mt20"
    }, [_c('el-descriptions', {
      attrs: {
        "labelStyle": {
          color: '#667288',
          background: '#ECEFF9'
        },
        "contentStyle": {
          background: '#ECEFF9'
        },
        "column": 3,
        "direction": "vertical"
      }
    }, [fundItem.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Name (same as business certificate)"
      }
    }, [_vm._v(" " + _vm._s(fundItem.fullName) + " ")]) : _vm._e(), fundItem.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Name (same as IC)"
      }
    }, [_vm._v(" " + _vm._s(fundItem.fullName) + " ")]) : _vm._e(), fundItem.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Country"
      }
    }, [_vm._v(_vm._s(fundItem.nationality))]) : _vm._e(), fundItem.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Nationality"
      }
    }, [_vm._v(_vm._s(fundItem.nationality))]) : _vm._e(), fundItem.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Business No."
      }
    }, [_vm._v(" " + _vm._s(fundItem.businessLicenseNo) + " ")]) : _vm._e(), fundItem.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Identification Type"
      }
    }, [_vm._v(" " + _vm._s(fundItem.identificationType) + " ")]) : _vm._e(), fundItem.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Identification No."
      }
    }, [_vm._v(" " + _vm._s(fundItem.identificationNo) + " ")]) : _vm._e(), _c('el-descriptions-item', {
      attrs: {
        "label": "Mobile"
      }
    }, [_vm._v(_vm._s(fundItem.phone))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Email"
      }
    }, [_vm._v(_vm._s(fundItem.email))]), fundItem.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Registered Address"
      }
    }, [_vm._v(" " + _vm._s(fundItem.registeredOfficeAddress) + " ")]) : _vm._e(), fundItem.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Residential address"
      }
    }, [_vm._v(" " + _vm._s(fundItem.residentialAddress) + " ")]) : _vm._e(), fundItem.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Operation Address"
      }
    }, [_vm._v(" " + _vm._s(fundItem.operationAddress) + " ")]) : _vm._e(), fundItem.memberType === 'CORPORATE' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Incorporation Date"
      }
    }, [_vm._v(" " + _vm._s(fundItem.incorporationDate) + " ")]) : _vm._e(), fundItem.memberType === 'INDIVIDUAL' ? _c('el-descriptions-item', {
      attrs: {
        "label": "Birth Date"
      }
    }, [_vm._v(" " + _vm._s(fundItem.birthDate) + " ")]) : _vm._e(), fundItem.memberType === 'CORPORATE' || fundItem.memberType === 'INDIVIDUAL' && (fundItem.positionHeld === 'SHAREHOLDER' || fundItem.positionHeld === 'DIRECTOR_AND_SHAREHOLDER') ? _c('el-descriptions-item', {
      attrs: {
        "label": "Is the shareholder a Nominee Shareholder"
      }
    }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(null, null, fundItem.isNominatedShareholder)) + " ")]) : _vm._e(), fundItem.memberType === 'INDIVIDUAL' && (fundItem.positionHeld === 'DIRECTOR' || fundItem.positionHeld === 'DIRECTOR_AND_SHAREHOLDER') ? _c('el-descriptions-item', {
      attrs: {
        "label": "Is the director a Nominee Director"
      }
    }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(null, null, fundItem.isNominatedDirector)) + " ")]) : _vm._e(), fundItem.memberType === 'INDIVIDUAL' && fundItem.positionHeld != 'NOMINEE_DIRECTOR' ? [_c('el-descriptions-item', {
      attrs: {
        "label": "Does the individual hold share interest of any entity in PRC inland?"
      }
    }, [_vm._v(" " + _vm._s(_vm.getBooleanFormat(null, null, fundItem.isHoldShareOfPrcInland)) + " ")]), fundItem.isHoldShareOfPrcInland ? _c('el-descriptions-item', {
      attrs: {
        "label": "Please provide the name of RPC entity (1 entity is required)"
      }
    }, [_vm._v(" " + _vm._s(fundItem.holdShareOfPrcEntity) + " ")]) : _vm._e()] : _vm._e(), _c('el-descriptions-item', {
      attrs: {
        "label": "Related fund(s)"
      }
    }, _vm._l(fundItem.fundsInfo, function (fund, fu) {
      return _c('span', {
        key: `fund${fu}`
      }, [_vm._v(" " + _vm._s(fu != 0 ? "、" : "") + " " + _vm._s(fund.fundName) + " ")]);
    }), 0)], 2)], 1), fundItem.memberType === 'CORPORATE' && fundItem.positionHeld === 'SHAREHOLDER' ? [fundItem.representativeInfo ? _c('div', {
      staticClass: "mt25 member-type"
    }, [_c('div', {
      staticClass: "position-held"
    }, [_vm._v("Representative")]), _c('div', {
      staticClass: "base-info mt20"
    }, [_c('el-descriptions', {
      attrs: {
        "labelStyle": {
          color: '#667288',
          background: '#ECEFF9'
        },
        "contentStyle": {
          background: '#ECEFF9'
        },
        "column": 3,
        "direction": "vertical"
      }
    }, [_c('el-descriptions-item', {
      attrs: {
        "label": "Name (same as IC)"
      }
    }, [_vm._v(" " + _vm._s(fundItem.representativeInfo.fullName) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Nationality"
      }
    }, [_vm._v(_vm._s(fundItem.representativeInfo.nationality))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Identification Type"
      }
    }, [_vm._v(" " + _vm._s(fundItem.representativeInfo.identificationType) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Identification No."
      }
    }, [_vm._v(" " + _vm._s(fundItem.representativeInfo.identificationNo) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Mobile"
      }
    }, [_vm._v(_vm._s(fundItem.representativeInfo.phone))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Email"
      }
    }, [_vm._v(_vm._s(fundItem.representativeInfo.email))]), _c('el-descriptions-item', {
      attrs: {
        "label": "Residential address"
      }
    }, [_vm._v(" " + _vm._s(fundItem.representativeInfo.residentialAddress) + " ")]), _c('el-descriptions-item', {
      attrs: {
        "label": "Birth Date"
      }
    }, [_vm._v(" " + _vm._s(fundItem.representativeInfo.birthDate) + " ")])], 1)], 1)]) : _vm._e()] : _vm._e(), fundItem.sharesInfo && fundItem.sharesInfo.length > 0 ? _c('div', {
      staticClass: "shares-info mt20"
    }, [_c('el-table', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "header-cell-style": {
          color: '#011A45'
        },
        "border": "",
        "data": fundItem.sharesInfo
      }
    }, [_c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "fundsName",
        "label": "Related fund(s)",
        "width": "180"
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "typeOfShares",
        "label": "Type Of Shares",
        "width": "180",
        "formatter": _vm.typeOfSharesFormat
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "quantityOfSharesAllotted",
        "label": "No of shares allotted"
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "amountOfPaidUpShareCapital",
        "label": "Amount Of Registered Share Capital"
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "amountOfPaidInShareCapital",
        "label": "Paid-in capital"
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "sharesHoldInTrust",
        "label": "Shares held in trust",
        "formatter": _vm.getBooleanFormat
      }
    }), _c('el-table-column', {
      attrs: {
        "align": "center",
        "prop": "nameOfTheTrust",
        "label": "Name of the trust"
      }
    })], 1)], 1) : _vm._e()], 2);
  }), 0)])])]), _vm.updateVisible ? _c('update-modal', {
    ref: "updateModal",
    attrs: {
      "stepName": _vm.stepName,
      "applymentId": _vm.applymentId,
      "applymentStatus": _vm.applymentStatus,
      "businessType": _vm.businessType,
      "fundInfoList": _vm.fundInfoList
    }
  }) : _vm._e()], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }