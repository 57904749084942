<template>
  <div class="page-container">
    <div>
      <el-form class="el-form-filter" :inline="true" :model="dataForm" @keyup.enter.native="getDataList(1)">
        <el-form-item>
          <el-input style="width: 500px" v-model="dataForm.keyword" :placeholder="kwPlaceHolder" class="input-with-select-lg" :oninput="isEn">
            <el-select v-model="dataForm.kwType" slot="prepend" placeholder="Please Select">
              <el-option v-for="item in kwTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.payType" multiple clearable placeholder="Pay Type">
            <el-option v-for="item in payTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.orderStatus" multiple clearable placeholder="Oder Status">
            <el-option v-for="item in orderStatusList" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.noCredit" placeholder="Select Credit Status Or Blank" class="wd180 controlLeft" @change="noCreditChange">
            <el-option label="Select Credit Status" :value="false"></el-option>
            <el-option label="Select Credit Blank" :value="true"></el-option>
          </el-select>
          <template v-if="dataForm.noCredit == false">
            <el-select v-model="dataForm.creditStatus" multiple clearable placeholder="Credit Status" class="wd300 controlRight">
              <el-option v-for="item in creditStatusList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.refundStatus" multiple clearable placeholder="Refund Status">
            <el-option v-for="item in refundStatusList" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.orderType" multiple clearable placeholder="Order Type">
            <el-option v-for="item in orderTypeList" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.payCurrency" clearable placeholder="Pay Currency">
            <el-option v-for="item in currencyList" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            value-format="timestamp"
            v-model="tradeTimeRange"
            type="daterange"
            range-separator="~"
            start-placeholder="Start Time"
            end-placeholder="End Time"
            style="width: 384px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 批量操作 -->
    <div class="mt20">
      <!-- 批量收款 -->
      <el-button
        v-if="isAuth('finance:financial')"
        type="primary"
        plain
        size="small"
        icon="el-icon-thumb"
        :disabled="dataListSelections.length <= 0"
        @click="handleRecepit"
      >
        Batch Confirm Recepit
      </el-button>
      <!-- 批量退款 -->
      <el-button
        v-if="isAuth('finance:financial')"
        type="primary"
        plain
        size="small"
        icon="el-icon-thumb"
        :disabled="dataListSelections.length <= 0"
        @click="handleBatchRefund"
      >
        Batch Refund
      </el-button>
      <!-- 导出 excel -->
      <el-button
        v-if="isAuth('finance:financial')"
        type="primary"
        plain
        size="small"
        icon="el-icon-download"
        @click="handleExportExcel"
        :disabled="dataListSelections.length <= 0"
      >
        Export Excel
      </el-button>
    </div>
    <div class="mt20">
      <el-table
        max-height="600"
        :data="dataList"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%"
        @sort-change="tableSortChange"
        :default-sort="{ prop: 'createTime', order: 'descending' }"
      >
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column fixed="left" prop="businessNo" header-align="center" align="center" min-width="160" label="Transaction No."></el-table-column>
        <el-table-column prop="supplementNo" header-align="center" align="center" min-width="156" label="Quotation (V) No."></el-table-column>
        <el-table-column prop="orderNo" header-align="center" align="center" min-width="180" label="Invoice No."></el-table-column>
        <el-table-column prop="description" header-align="center" align="center" width="180" label="Description"></el-table-column>
        <el-table-column prop="billingParty" header-align="center" align="center" label="Billing Party" width="180" />
        <el-table-column header-align="center" align="center" width="120" label="Price">
          <template slot-scope="scope">
            <p v-if="scope.row.priceSg">{{ scope.row.priceSg | priceSgFormat }}</p>
            <p v-if="scope.row.priceCn">{{ scope.row.priceCn | priceCnFormat }}</p>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="Pay Currency" min-width="150">
          <template slot-scope="scope">
            <p class="enbr">{{ currencyFormat(scope.row.payCurrency) }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          header-align="center"
          align="center"
          width="120"
          label="Order Status"
          :formatter="orderStatusFormat"
        ></el-table-column>
        <el-table-column
          prop="creditStatus"
          header-align="center"
          align="center"
          width="120"
          label="Credit Status"
          :formatter="creditStatusFormat"
        ></el-table-column>
        <el-table-column prop="createTime" header-align="center" align="center" width="200" label="Create Time" sortable="custom"></el-table-column>
        <el-table-column
          prop="paymentDueTime"
          header-align="center"
          align="center"
          width="200"
          label="Payment Due Date"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="finishTime" header-align="center" align="center" width="200" label="Finish Time" sortable="custom"></el-table-column>
        <el-table-column
          prop="refundDetail.status"
          header-align="center"
          align="center"
          width="240"
          label="Refund Status"
          :formatter="refundStatusFormat"
        ></el-table-column>
        <el-table-column
          prop="refundDetail.applyTime"
          header-align="center"
          align="center"
          width="260"
          label="Refund Create Time"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="payType" header-align="center" align="center" width="200" label="Pay Type" :formatter="payTypesFormat"></el-table-column>
        <el-table-column
          prop="orderType"
          header-align="center"
          align="center"
          width="200"
          label="Order Type"
          :formatter="orderTypeFormat"
        ></el-table-column>
        <el-table-column header-align="center" align="center" width="260" label="Personnel">
          <template slot-scope="scope">
            <div v-if="scope.row.companyApplyment">
              <p v-if="scope.row.companyApplyment.coordinator">
                Coordinator: {{ scope.row.companyApplyment.coordinator.nickname || scope.row.companyApplyment.coordinator.username }}
              </p>
              <p v-if="scope.row.companyApplyment.checker">
                Checker: {{ scope.row.companyApplyment.checker.nickname || scope.row.companyApplyment.checker.username }}
              </p>
              <p v-if="scope.row.companyApplyment.auditor">
                Reviewer: {{ scope.row.companyApplyment.auditor.nickname || scope.row.companyApplyment.auditor.username }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" label="Action" width="150">
          <template slot-scope="scope">
            <el-button @click="detailHandler(scope.row.id)" type="text" class="fwb">Detail</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <!-- 批量退款 - 弹框 -->
    <batch-refund ref="batchRefundRef" @refreshDataList="getDataList()"></batch-refund>
    <!-- 批量收款 - 弹框 -->
    <batch-confirm-receipt ref="batchConfirmReceiptRef" @refreshDataList="getDataList()"></batch-confirm-receipt>
  </div>
</template>
<script>
import { open, fmoney } from "@/utils";
import axiosInstance from "@/utils/http";
import { mapActions, mapState } from "vuex";
import BatchRefund from "./modal/BatchRefund.vue";
import BatchConfirmReceipt from "./modal/BatchConfirmReceipt.vue";
export default {
  components: {
    BatchRefund,
    BatchConfirmReceipt,
  },
  data() {
    return {
      dataList: [],
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      kwTypes: [
        {
          title: "Transaction No.",
          value: "businessNo",
          placeholder: "Transaction No.",
        },
        {
          title: "Quotation (V) No.",
          value: "supplementNo",
          placeholder: "Quotation (V) No.",
        },
        {
          title: "Invoice No.",
          value: "orderNo",
          placeholder: "Invoice No.",
        },
        {
          title: "Billing Party",
          value: "billingParty",
          placeholder: "Billing Party",
        },
        {
          title: "Price",
          value: "price",
          placeholder: "Price",
        },
        {
          title: "Description",
          value: "description",
          placeholder: "Description",
        },
      ],
      tradeTimeRange: null,
      dataForm: {
        payType: "",
        orderStatus: "",
        noCredit: false,
        creditStatus: "",
        refundStatus: "",
        startTime: null,
        endTime: null,
        orderType: null,
        payCurrency: null,
        kwType: "businessNo",
        keyword: "",
        "orders[0].asc": false,
        "orders[0].column": "id",
      },
    };
  },
  computed: {
    ...mapState("constant", [
      "applymentStatusList",
      "orderStatusList",
      "refundStatusList",
      "payTypes",
      "orderTypeList",
      "creditStatusList",
      "currencyList",
    ]),
    kwPlaceHolder() {
      return this.kwTypes.find(i => i.value === this.dataForm.kwType).placeholder;
    },
  },
  watch: {
    tradeTimeRange(newValue, oldValue) {
      if (newValue) {
        this.dataForm.startTime = newValue[0];
        this.dataForm.endTime = newValue[1];
      } else {
        this.dataForm.startTime = undefined;
        this.dataForm.endTime = undefined;
      }
    },
  },
  filters: {
    priceSgFormat(data) {
      if (data) {
        return "S$" + fmoney(data, 2);
      } else {
        return "";
      }
    },
    priceCnFormat(data) {
      if (data) {
        return "¥" + fmoney(data, 2);
      } else {
        return "";
      }
    },
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // table - 排序
    tableSortChange(column) {
      console.log(column);
      if (column.prop == "createTime") {
        this.dataForm["orders[0].column"] = "id";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "refundDetail.applyTime") {
        this.dataForm["orders[0].column"] = "t2.apply_time";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "paymentDueTime") {
        this.dataForm["orders[0].column"] = "t1.payment_due_time";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else if (column.prop == "finishTime") {
        this.dataForm["orders[0].column"] = "t1.finish_time";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      }
    },
    /* 格式化 */
    orderStatusFormat(row, column, cellValue) {
      const one = this.orderStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    creditStatusFormat(row, column, cellValue) {
      const one = this.creditStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    refundStatusFormat(row, column, cellValue) {
      const one = this.refundStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    payTypesFormat(row, column, cellValue) {
      const one = this.payTypes.find(i => i.value === cellValue);
      return one?.title;
    },
    orderTypeFormat(row, column, cellValue) {
      const one = this.orderTypeList.find(i => i.value === cellValue);
      return one?.title;
    },
    currencyFormat(cellValue) {
      const one = this.currencyList.find(i => i.value === cellValue);
      return one?.title;
    },
    /* 格式化 */
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    //
    noCreditChange() {
      if (this.dataForm.noCredit) {
        this.$set(this.dataForm, "creditStatus", "");
      }
    },
    //详情
    detailHandler(id) {
      this.$router.push(`/order/${id}`);
    },
    //批量收款
    handleRecepit() {
      let orderIds = [];
      this.dataListSelections.forEach(item => {
        if (item.status && (item.status === "UNPAID" || item.status === "PARTLY_PAID")) {
          orderIds.push(item.id);
        }
      });
      if (orderIds.length == 0) {
        this.$message.warning("You can't operate it until the filter of Order Status is 'Unpaid' or 'Partly Paid'.");
        return false;
      }
      this.$refs.batchConfirmReceiptRef.init(orderIds);
    },
    //批量退款
    handleBatchRefund() {
      let orderIds = [];
      this.dataListSelections.forEach(item => {
        if (
          item.refundDetail &&
          item.refundDetail.status &&
          (item.refundDetail.status === "REFUNDING" || item.refundDetail.status === "REFUND_PENDING" || item.refundDetail.status === "REFUND_INFO_UPDATED")
        )
          orderIds.push(item.refundDetail.orderId);
      });
      if (orderIds.length == 0) {
        this.$message.warning("You can't operate it until the filter of Refund Status is 'Refunding', 'REFUND_PENDING' or 'Refund Info Updated'.");
        return;
      }
      this.$refs.batchRefundRef.init(orderIds);
    },
    //导出 Excel
    async handleExportExcel() {
      const ids = this.dataListSelections.map(item => item.id).join(",");
      open(`${axiosInstance.defaults.baseURL}/admin/orderXlsxManage/download?orderIds=${ids}`);
    },
    //数据请求
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
        // "orders[0].asc": false,
        // "orders[0].column": "id",
        ...this.dataForm,
        [this.dataForm.kwType]: this.dataForm.keyword,
      };
      const { code, data } = await this.$http.get("/admin/orderManage/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.controlLeft {
  ::v-deep input {
    // border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ::v-deep .el-input__inner:focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input__inner.is-focus {
    border-color: #dcdfe6 !important;
  }

  ::v-deep .el-input:focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }

  ::v-deep .el-input.is-focus {
    .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }
}

.controlRight {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  ::v-deep input {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
