<template>
  <el-dialog width="900px" :title="!dataForm.id ? 'Add Office Address' : 'Edit Office Address'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px">
      <el-form-item label="Entity type" prop="type">
        <el-select style="width: 100%" v-model="dataForm.type" placeholder="Select type">
          <el-option v-for="item in entityTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Country" prop="country">
        <el-select style="width: 100%" v-model="dataForm.country" placeholder="Select Country">
          <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="City" prop="city">
        <el-input v-model="dataForm.city" placeholder="City" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Address" prop="address">
        <el-input
          v-model="dataForm.address"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Address"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
      <el-form-item label="PostalCode" prop="postalCode">
        <el-input v-model="dataForm.postalCode" placeholder="postalCode" :oninput="isEn"></el-input>
      </el-form-item>
      <el-form-item label="Status" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">Disable</el-radio>
          <el-radio :label="1">Enable</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        type: "",
        country: "",
        city: "",
        address: "",
        postalCode: "",
        status: 1,
      },
      dataRule: {
        type: [{ required: true, message: "Entity type is required", trigger: "blur" }],
        country: [{ required: true, message: "country is required", trigger: "blur" }],
        city: [{ required: true, message: "city is required", trigger: "blur" }],
        address: [{ required: true, message: "address is required", trigger: "blur" }],
      },
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["countryList", "entityTypes"]),
  },
  methods: {
    init(id) {
      this.submitControl = true;
      this.visible = true;
      this.$nextTick().then(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          this.getDetail(id);
        } else {
          this.dataForm.id = undefined;
          this.dataForm.content = "";
        }
      });
    },
    //详情
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/stRegisteredOfficeAddressManage/${id}/detail`);
      this.dataForm = { ...data };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.$http.post(
            this.dataForm.id ? `/admin/stRegisteredOfficeAddressManage/${this.dataForm.id}/update` : `/admin/stRegisteredOfficeAddressManage/create`,
            this.dataForm,
          );
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
