<template>
  <el-dialog width="700px" :close-on-click-modal="false" :visible.sync="visible">
    <div slot="title" class="headline">
      {{ detail.creditStatus == "REJECTED" ? "Credit Re-apply" : "Credit Apply" }}
      <span>(This is to apply approval for paying later instead of in advance.)</span>
    </div>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="146px">
      <el-form-item label="Reject Reason" v-if="failReasonShow || detail.creditStatus == 'REJECTED'">
        <template v-if="detail.credit">
          {{ detail.credit.auditRemark }}
        </template>
      </el-form-item>
      <el-form-item :label="detail.creditStatus == 'REJECTED' ? 'Re-apply Reason' : 'Apply Reason'" prop="applyReason">
        <el-input
          v-model="dataForm.applyReason"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Apply Reason"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
      <el-form-item label="Attachments" prop="attachmentUrls">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit" v-if="isAuth('finance:financial')">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        applyReason: "",
        attachmentUrls: [],
      },
      dataRule: {
        applyReason: [{ required: true, message: "please enter", trigger: "submit" }],
      },
      failReasonShow: false,
      submitControl: true,
    };
  },
  computed: {
    ...mapState("finance", ["detail"]),
  },
  methods: {
    ...mapActions("finance", ["doApplyCredit"]),
    async init() {
      this.failReasonShow = this.detail.creditStatus == "REJECTED";
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          let credit = JSON.parse(JSON.stringify(this.detail.credit || {}));
          credit.applyReason = this.dataForm.applyReason;
          credit.attachmentUrls = this.dataForm.attachmentUrls;
          // creditStatus: 1 待审核、2 通过、3 驳回
          this.doApplyCredit({ ...this.dataForm, credit, creditStatus: "PENDING" })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  // this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  font-size: 16px;
  font-weight: 500;

  span {
    font-size: 14px;
    color: #667288;
  }
}
</style>
