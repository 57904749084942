<template>
  <div class="desp">
    <div class="desp-header">Refund Information</div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Apply Reason</div>
      <div class="desp-item">
        <div class="desp-item-self highlight" v-if="detail.refundDetail">{{ detail.refundDetail.applyReason }}</div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Amount</div>
      <div class="desp-items-wrapper">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Amount</div>
              <div class="desp-item-content" v-if="detail.refundDetail && detail.refundDetail.refundDetail">
                <span class="price" v-if="detail.refundDetail.refundDetail.amountSg">
                  {{ detail.refundDetail.refundDetail.amountSg | thousands }}
                </span>
                <span class="price" v-if="detail.refundDetail.refundDetail.amountCn">
                  {{ detail.refundDetail.refundDetail.amountCn | thousandsRMB }}
                </span>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Acra Fee</div>
              <div class="desp-item-content" v-if="detail.refundDetail && detail.refundDetail.refundDetail">
                <span class="price">{{ detail.refundDetail.refundDetail.acraFee | thousands }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Handling Fee</div>
              <div class="desp-item-content" v-if="detail.refundDetail && detail.refundDetail.refundDetail">
                <span class="price">{{ detail.refundDetail.refundDetail.handlingFee | thousands }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Information</div>
      <div class="desp-items-wrapper">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Refund Status</div>
              <div class="desp-item-content" v-if="detail.refundDetail">
                {{ refundStatusFormat(detail.refundDetail.status) }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Refund No</div>
              <div class="desp-item-content" v-if="detail.refundDetail">
                {{ detail.refundDetail.refundNo }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Apply Time</div>
              <div class="desp-item-content" v-if="detail.refundDetail">
                {{ detail.refundDetail.applyTime }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Fail Reason</div>
      <div class="desp-item">
        <div class="desp-item-self highlight" v-if="detail.refundDetail">{{ detail.refundDetail.failReason }}</div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Operator</div>
      <div class="desp-items-wrapper">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Refund Teller</div>
              <div class="desp-item-content" v-if="detail.refundDetail && detail.refundDetail.refundingTeller">
                {{ detail.refundDetail.refundingTeller.nickname || detail.refundDetail.refundingTeller.username }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="desp-item">
              <div class="desp-item-label">Finish Time</div>
              <div class="desp-item-content" v-if="detail.refundDetail">
                {{ detail.refundDetail.finishTime }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import PlanDetail from "@/components/PlanDetail";
import { mapState } from "vuex";
import { fmoney } from "@/utils";
export default {
  components: {
    PlanDetail,
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          companyApplyment: {
            refundDetail: {
              refundingTeller: {},
              refundDetail: {},
            },
          },
        };
      },
    },
  },
  data() {
    return {
      dataListLoading: false,
    };
  },
  computed: {
    ...mapState("constant", ["applymentStatusList", "orderStatusList", "refundStatusList", "payTypes"]),
  },
  filters: {
    thousands(val) {
      if (val || val == 0) {
        return "S$" + fmoney(val, 2);
      } else {
        return "";
      }
    },
    thousandsRMB(val) {
      if (val || val == 0) {
        return "¥" + fmoney(val, 2);
      } else {
        return "";
      }
    },
  },
  methods: {
    //退款状态格式化
    refundStatusFormat(v) {
      const one = this.refundStatusList.find(i => i.value === v);
      return one?.title;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.to-link {
  cursor: pointer;
  color: #{$--color-primary};
}

.price {
  color: #{$--color-warning} !important;
}

.highlight {
  color: #{$--color-danger} !important;
}
</style>
