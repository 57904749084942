<template>
  <el-dialog width="800px" title="Confirm Receipt" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="250px">
      <el-form-item label="" prop="status">
        <el-radio-group v-model="dataForm.status">
          <template>
            <el-radio label="COMPLETE">Fully received</el-radio>
            <el-radio label="PARTLY_PAID">Partially received</el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Pay Type" prop="payType">
        <el-radio-group v-model="dataForm.payType">
          <template>
            <el-radio label="ALIPAY">Alipay</el-radio>
            <el-radio label="BANK_TRANSFER">Bank Transfer</el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="isInvoiceRevised" class="is-required" v-if="dataForm.status == 'COMPLETE'">
        <span slot="label">
          <span>Revision Invoice Information</span>
          <el-popover placement="bottom" trigger="hover" class="vam">
            <span class="fs12">Was the information of the invoice (except the price amount) subsequently revised after the system issurance?</span>
            <span slot="reference" class="blackD8 fs16 ml4 el-icon-question"></span>
          </el-popover>
        </span>
        <el-radio-group v-model="dataForm.isInvoiceRevised">
          <template>
            <el-radio :label="1">Yes</el-radio>
            <el-radio :label="0">No</el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="revisedInvoiceFile" class="is-required" v-if="dataForm.isInvoiceRevised">
        <span slot="label">
          <span>Upload Revised Invoice</span>
          <el-popover placement="bottom" trigger="hover" class="vam">
            <span class="fs12">To upload the revised invoice</span>
            <span slot="reference" class="blackD8 fs16 ml4 el-icon-question"></span>
          </el-popover>
        </span>
        <el-file-uploader v-model="dataForm.revisedInvoiceFile"></el-file-uploader>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
          :oninput="isEn"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        status: "",
        payType: "",
        isInvoiceRevised: null,
        revisedInvoiceFile: null,
        remark: "",
      },
      dataRule: {
        status: [{ required: true, message: "please select", trigger: "submit" }],
        payType: [{ required: true, message: "please select", trigger: "submit" }],
        isInvoiceRevised: [{ required: true, message: "please select", trigger: "submit" }],
        revisedInvoiceFile: [{ required: true, message: "please upload", trigger: "submit" }],
      },
      submitControl: true,
    };
  },
  methods: {
    ...mapActions("finance", ["doReceipt"]),
    async init() {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const dataForm = { ...this.dataForm };
          if (dataForm.status == "PARTLY_PAID") {
            dataForm.isInvoiceRevised = 0;
          }
          if (!dataForm.isInvoiceRevised) {
            dataForm.revisedInvoiceFile = null;
          }
          this.doReceipt(dataForm)
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
