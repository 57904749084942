var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "700px",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "headline",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.detail.creditStatus == "REJECTED" ? "Credit Re-apply" : "Credit Apply") + " "), _c('span', [_vm._v("(This is to apply approval for paying later instead of in advance.)")])]), _c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "146px"
    }
  }, [_vm.failReasonShow || _vm.detail.creditStatus == 'REJECTED' ? _c('el-form-item', {
    attrs: {
      "label": "Reject Reason"
    }
  }, [_vm.detail.credit ? [_vm._v(" " + _vm._s(_vm.detail.credit.auditRemark) + " ")] : _vm._e()], 2) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": _vm.detail.creditStatus == 'REJECTED' ? 'Re-apply Reason' : 'Apply Reason',
      "prop": "applyReason"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Apply Reason",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.applyReason,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "applyReason", $$v);
      },
      expression: "dataForm.applyReason"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Attachments",
      "prop": "attachmentUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.attachmentUrls,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "attachmentUrls", $$v);
      },
      expression: "dataForm.attachmentUrls"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm.isAuth('finance:financial') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }